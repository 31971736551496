import { PersonnelListItem } from '../../models/business/personnels/personnel-list-item.model';
import { PersonnelsListFilterData } from '../../models/filters/personnels-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class PersonnelsAction {
    static readonly type = '[Personnels Page] GetPersonnels';

    constructor(public payload: PaginationRequest) { }
}

export class PersonnelsSuccessAction {
    static readonly type = '[Personnels API] GetPersonnels Success';

    constructor(public payload: { list: PaginationResult<PersonnelListItem>, pagination: PaginationRequest }) { }
}

export class PersonnelsFailAction {
    static readonly type = '[Personnels API] GetPersonnels Fail';

    constructor(public payload: ErrorObject) { }
}

export class PersonnelsResetAction {
    static readonly type = '[Personnels Page] GetPersonnels Reset';
}

export class PersonnelsSetFilterAction {
    static readonly type = '[Personnels API] SetFilter';

    constructor(public payload: PersonnelsListFilterData) { }
}
