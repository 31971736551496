import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { PosCompanySearchAction, PosCompanySearchFailAction, PosCompanySearchResetAction, PosCompanySearchSuccessAction } from './pos-company-search.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PosListFilter } from '../../models/filters/pos-list-filter.model';
import { PosLookupItem } from '../../models/business/pos/pos-lookup-item.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface PosCompanySearchStateModel extends PaginationStateModel<PosLookupItem> {
    uids: string[];
    entities: { [uid: string]: PosLookupItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<PosCompanySearchStateModel>({
    name: 'posCompanySearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class PosCompanySearchState extends PaginationStateBase {
    constructor(private posService: PosService) {
        super();
    }

    @Action([PosCompanySearchAction]) posGet(ctx: StateContext<PosCompanySearchStateModel>, action: PosCompanySearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new PosListFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.posService.getPosLookup(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PosCompanySearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosCompanySearchFailAction(err)), 0));
    }

    @Action(PosCompanySearchSuccessAction) posGetSuccess(ctx: StateContext<PosCompanySearchStateModel>, action: PosCompanySearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.posAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(PosCompanySearchFailAction) posGetFail(ctx: StateContext<PosCompanySearchStateModel>, action: PosCompanySearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosCompanySearchResetAction) posGetReset(ctx: StateContext<PosCompanySearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
