import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantTemplateListGetFilterData } from '../../models/filters/restaurant-template-list-get-filter.model';
import { RestaurantTemplateListItem } from '../../models/business/restaurant-templates/restaurant-template-list-item.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class RestaurantTemplatesAction {
    static readonly type = '[RestaurantTemplatess Page] GetRestaurantTemplates';

    constructor(public payload: PaginationRequest) { }
}

export class RestaurantTemplatesSuccessAction {
    static readonly type = '[RestaurantTemplates API] GetRestaurantTemplates Success';

    constructor(public payload: { list: PaginationResult<RestaurantTemplateListItem>, pagination: PaginationRequest }) { }
}

export class RestaurantTemplatesFailAction {
    static readonly type = '[RestaurantTemplates API] GetRestaurantTemplates Fail';

    constructor(public payload: ErrorObject) { }
}

export class RestaurantTemplatesResetAction {
    static readonly type = '[RestaurantTemplates Page] GetRestaurantTemplates Reset';
}

export class RestaurantTemplatesSetFilterAction {
    static readonly type = '[RestaurantTemplates API] SetFilter';

    constructor(public payload: RestaurantTemplateListGetFilterData) { }
}
