import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseReturnsService } from '../../services/business/purchase-returns.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseReturnCreateOrUpdateRequest } from '../../models/business/purchase-returns/purchase-return-create-or-update-request.model';

import { PurchaseReturnUpdateAction, PurchaseReturnUpdateFailAction, PurchaseReturnUpdateResetAction, PurchaseReturnUpdateSuccessAction } from './purchase-return-update.actions';

export interface PurchaseReturnUpdateStateModel {
    pending: boolean;
    updated: PurchaseReturnCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<PurchaseReturnUpdateStateModel>({
    name: 'purchaseReturnUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class PurchaseReturnUpdateState {
    constructor(private purchaseReturnsService: PurchaseReturnsService) { }

    @Action(PurchaseReturnUpdateAction) async purchaseReturnUpdate(ctx: StateContext<PurchaseReturnUpdateStateModel>, action: PurchaseReturnUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.purchaseReturnsService.updatePurchaseReturn(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PurchaseReturnUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseReturnUpdateFailAction(err)), 0));
    }

    @Action(PurchaseReturnUpdateSuccessAction) purchaseReturnUpdateSuccess(ctx: StateContext<PurchaseReturnUpdateStateModel>, action: PurchaseReturnUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(PurchaseReturnUpdateFailAction) purchaseReturnUpdateFail(ctx: StateContext<PurchaseReturnUpdateStateModel>, action: PurchaseReturnUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(PurchaseReturnUpdateResetAction) purchaseReturnUpdateReset(ctx: StateContext<PurchaseReturnUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
