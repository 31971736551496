import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProductUpdateAction, ProductUpdateFailAction, ProductUpdateResetAction, ProductUpdateSuccessAction } from './product-update.actions';
import { ModifiersService } from '../../services/business/modifiers.service';

export interface ProductUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<ProductUpdateStateModel>({
    name: 'productUpdate',
    defaults: { pending: false, updated: false, error: null },
})
@Injectable()
export class ProductUpdateState {
    constructor(private catalogService: CatalogService, private modifiersService: ModifiersService) {}

    @Action(ProductUpdateAction) async productUpdate(ctx: StateContext<ProductUpdateStateModel>, action: ProductUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        const { uid, modifiers, request } = action.payload;

        try {
            await this.catalogService.updateProduct(uid, request);

            if (modifiers) {
                if (modifiers.length) {
                    await this.modifiersService.createUpdateModifiers(modifiers);
                } else {
                    await this.modifiersService.deleteOrderModifiers(uid);
                }
            }

            ctx.dispatch(new ProductUpdateSuccessAction(action.payload));
        } catch (err) {
            ctx.dispatch(new ProductUpdateFailAction(err));
        }
    }

    @Action(ProductUpdateSuccessAction) productUpdateSuccess(ctx: StateContext<ProductUpdateStateModel>, _: ProductUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(ProductUpdateFailAction) productUpdateFail(ctx: StateContext<ProductUpdateStateModel>, action: ProductUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(ProductUpdateResetAction) productUpdateReset(ctx: StateContext<ProductUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
