import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResultWithAmount } from '../../models/business/common/pagination-result-with-amount';
import { PurchaseOrderCreateOrUpdateRequest } from '../../models/business/purchase-orders/purchase-order-create-or-update-request.model';
import { PurchaseOrderListGetFilter } from '../../models/filters/purchase-order-list-get-filter.model';
import { PurchaseOrderListItem } from '../../models/business/purchase-orders/purchase-order-list-item.model';
import { PurchaseOrder } from '../../models/business/purchase-orders/purchase-order.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class PurchaseOrdersService extends BaseSingletonService {

    private settings: {
        service: {
            getPurchaseOrder: string;
            getWorkbook: string;
            getPurchaseOrderList: string;
            createPurchaseOrder: string;
            updatePurchaseOrder: string;
            deletePurchaseOrder: string;
        }
    };

    constructor(private http: HttpService) {
        super('PurchaseOrdersService');
        this.settings = {
            service: {
                getPurchaseOrder: '/purchases-aggregation/v1/orders/{0}',
                getWorkbook: '/purchases/v1/orders/{0}/workbook',
                getPurchaseOrderList: '/purchases-aggregation/v1/orders?countryCodes={0}&citiesIds={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}&term={6}',
                createPurchaseOrder: '/purchases-aggregation/v1/orders',
                updatePurchaseOrder: '/purchases-aggregation/v1/orders/{0}',
                deletePurchaseOrder: '/purchases/v1/orders/{0}'
            }
        };
    }

    public async getPurchaseOrder(uid: string): Promise<PurchaseOrder> {
        return this.http.get<{ data: PurchaseOrder }>(String.format(this.settings.service.getPurchaseOrder, uid))
            .then(res => res.data);
    }

    public async getWorkbook(uid: string, fileName: string): Promise<Blob> {
        return this.http.download(String.format(this.settings.service.getWorkbook, uid), fileName);
    }

    public async getPurchaseOrderList(filter: PurchaseOrderListGetFilter): Promise<PaginationResultWithAmount<PurchaseOrderListItem>> {
        return this.http.get<PaginationResultWithAmount<PurchaseOrderListItem>>(String.format(this.settings.service.getPurchaseOrderList, filter.countryCodes, filter.citiesUids, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createPurchaseOrder(request: PurchaseOrderCreateOrUpdateRequest): Promise<PurchaseOrder> {
        return this.http.post<{ data: PurchaseOrder }>(String.format(this.settings.service.createPurchaseOrder), request)
            .then(res => res.data);
    }

    public async updatePurchaseOrder(uid: string, request: PurchaseOrderCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updatePurchaseOrder, uid), request);
    }

    public async deletePurchaseOrder(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePurchaseOrder, uid));
    }
}
