import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { OrganizationPosPaymentTypesAction, OrganizationPosPaymentTypesFailAction, OrganizationPosPaymentTypesResetAction, OrganizationPosPaymentTypesSuccessAction } from './organization-pos-payment-types.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosPaymentType } from '../../models/business/organization-pos/pos-payment-type.model';

export interface OrganizationPosPaymentTypesStateModel {
    entities: PosPaymentType[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosPaymentTypesStateModel>({
    name: 'posPaymentTypes',
    defaults: {
        pending: false, entities: [], retrieved: false, error: null
    }
})
@Injectable()
export class OrganizationPosPaymentTypesState {
    constructor(private orgPosService: OrganizationPosService) { }

    @Action([OrganizationPosPaymentTypesAction]) organizationPosesGet(ctx: StateContext<OrganizationPosPaymentTypesStateModel>, action: OrganizationPosPaymentTypesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosService.getPosPaymentTypes(action.payload.organizationPosUid)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosPaymentTypesSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosPaymentTypesFailAction(err)), 0));
    }

    @Action(OrganizationPosPaymentTypesSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosPaymentTypesStateModel>, action: OrganizationPosPaymentTypesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(OrganizationPosPaymentTypesFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosPaymentTypesStateModel>, action: OrganizationPosPaymentTypesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosPaymentTypesResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosPaymentTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
