import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContactDeleteAction, ContactDeleteFailAction, ContactDeleteResetAction, ContactDeleteSuccessAction } from './contact-delete.actions';

export interface ContactDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<ContactDeleteStateModel>({
    name: 'contactDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class ContactDeleteState {
    constructor(private contactsService: ContactsService) { }

    @Action(ContactDeleteAction) async contactDelete(ctx: StateContext<ContactDeleteStateModel>, action: ContactDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.contactsService.deleteContact(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new ContactDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ContactDeleteFailAction(error)), 0));
    }

    @Action(ContactDeleteSuccessAction) contactDeleteSuccess(ctx: StateContext<ContactDeleteStateModel>, _: ContactDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(ContactDeleteFailAction) contactDeleteFail(ctx: StateContext<ContactDeleteStateModel>, action: ContactDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContactDeleteResetAction) contactDeleteReset(ctx: StateContext<ContactDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
