import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FranchiseeCreateOrUpdateRequest } from '../../models/business/franchisees/franchisee-create-or-update.model';

export class FranchiseeUpdateAction {
    static readonly type = '[Franchisee Page] UpdateFranchisee';

    constructor(public payload: { uid: string, request: FranchiseeCreateOrUpdateRequest, defaultOrganizationUid: string }) { }
}

export class FranchiseeUpdateSuccessAction {
    static readonly type = '[Franchisee API] UpdateFranchisee Success';

    constructor(public payload: { uid: string, request: FranchiseeCreateOrUpdateRequest }) { }
}

export class FranchiseeUpdateFailAction {
    static readonly type = '[Franchisee API] UpdateFranchisee Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeUpdateResetAction {
    static readonly type = '[Franchisee Page] UpdateFranchisee Reset';
}
