
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CompanyDeleteAction {
    static readonly type = '[My Company Page] DeleteCompany';

    constructor(public payload: { uid: string }) { }
}

export class CompanyDeleteSuccessAction {
    static readonly type = '[My Companys API] DeleteCompany Success';

    constructor(public payload: { uid: string }) { }
}

export class CompanyDeleteFailAction {
    static readonly type = '[My Companys API] DeleteCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyDeleteResetAction {
    static readonly type = '[My Company Page] DeleteCompany Reset';
}
