
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class RestaurantCategoryDeleteAction {
    static readonly type = '[My RestaurantCategory Page] DeleteRestaurantCategory';

    constructor(public payload: { uid: string }) { }
}

export class RestaurantCategoryDeleteSuccessAction {
    static readonly type = '[My RestaurantCategory API] DeleteRestaurantCategory Success';

    constructor(public payload: { uid: string }) { }
}

export class RestaurantCategoryDeleteFailAction {
    static readonly type = '[My RestaurantCategory API] DeleteRestaurantCategory Fail';

    constructor(public payload: ErrorObject) { }
}

export class RestaurantCategoryDeleteResetAction {
    static readonly type = '[My RestaurantCategory Page] DeleteRestaurantCategory Reset';
}
