import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Order } from '../../models/business/orders/order.model';

export class OrderGetAction {
    static readonly type = '[Order Page] GetOrder Get';

    constructor(public payload: string) { }
}

export class OrderGetSuccessAction {
    static readonly type = '[Order API] GetOrder Success';

    constructor(public payload: Order) { }
}

export class OrderFailAction {
    static readonly type = '[Order API] GetOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderResetAction {
    static readonly type = '[Order Page] GetOrder Reset';
}

export class OrderRepeatAction {
    static readonly type = '[Order Page] GetOrder Repeat';

    constructor(public payload: Order) { }
}
