import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WriteOffCreateOrUpdateRequest } from '../../models/business/write-offs/write-off-create-or-update-request.model';
import { WriteOff } from '../../models/business/write-offs/write-off.model';

export class WriteOffCreateAction {
    static readonly type = '[WriteOffs Page] CreateWriteOff';

    constructor(public payload: WriteOffCreateOrUpdateRequest) { }
}

export class WriteOffCreateSuccessAction {
    static readonly type = '[WriteOff API] CreateWriteOff Success';

    constructor(public payload: WriteOff) { }
}

export class WriteOffCreateFailAction {
    static readonly type = '[WriteOff API] CreateWriteOff Fail';

    constructor(public payload: ErrorObject) { }
}

export class WriteOffCreateResetAction {
    static readonly type = '[WriteOffs Page] CreateWriteOff Reset';
}
