import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LanguageService } from '../../../../burns-ui-framework/shared/services/common/language-service.service';

import { GeocodingDispatchers } from '../../store/geocoding.dispatchers';
import { GeocodingSelectors } from '../../store/geocoding.selectors';

import { ComponentType } from '../../models/component-type.enum';
import { Geocoding } from '../../models/geocoding.model';

import { LocaleUtils } from '../../../../burns-ui-framework/shared/utils/locale-utils';

@Component({
    selector: 'exg-address-autocomplete',
    templateUrl: './exg-address-autocomplete.component.html',
    styleUrls: ['./exg-address-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExgAddressAutocompleteComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExgAddressAutocompleteComponent implements ControlValueAccessor, OnChanges {
    @Input() value: string;
    @Input() maxlength: number;
    @Input() placeholder: string;
    @Input() validate: boolean;
    @Input() controlsToValidate: FormControl[];
    @Input() countryCode: string;
    @Input() readonly: boolean;
    @Input() styleType: 'common' | 'inline' | 'empty' | 'readonly' = 'common';
    @Input() allowNull: boolean;
    @Input() emptyDisplayValue = '-';
    @Input() parentPlaceId: string = null;
    @Input() level: ComponentType = null;
    @Input() fullNameLevelFrom: ComponentType = null;
    @Input() useHierarchy: boolean;

    @Output() readonly selectionChange = new EventEmitter<Geocoding>();

    public internalValue: any;
    public isDisabled: boolean;

    public places$ = this.geocodingSelectors.places$;

    private propagateChange: (_) => void;
    private propagateTouch: () => void;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private languageService: LanguageService,
                private geocodingDispatchers: GeocodingDispatchers,
                private geocodingSelectors: GeocodingSelectors) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && this.value !== this.internalValue) {
            this.writeValue(this.value);
        }
    }

    public addressDisplayValueFunction(item: Geocoding) {
        return `${item.fullName || item.name || ''} ${item.text || ''}`;
    }

    public onSearchPlace($event: string) {
        const name = this.internalValue?.fullName || this.internalValue?.name || '';
        const isNeedToCleanPlaceId = (name.replace(($event || ''), '')).length > 0;

        this.internalValue = !isNeedToCleanPlaceId
        ? {
            ...(this.internalValue || {}),
            text: this.internalValue && this.internalValue.id ? $event?.replace(this.internalValue.fullName, '').replace(this.internalValue.name, '').trim() : $event?.trim()
        }
        : {
            text: $event?.trim()
        };

        const countryCode = this.countryCode || LocaleUtils.parseCountryFromCulture(this.languageService.retrieveLanguage());
        this.geocodingDispatchers.dispatchGeocodingAction(countryCode, $event, this.parentPlaceId, this.level, this.fullNameLevelFrom);

        if (isNeedToCleanPlaceId) {
            if (this.propagateChange) {
                this.propagateChange(null);
            }
        }
    }

    public onSelectionChange($event: Geocoding) {
        let result = $event;
        if (this.useHierarchy && $event.components && $event.components.length > 0) {
            const sortedComponents = $event.components.sort((c1, c2) => c1.componentType - c2.componentType);
            result = {
                id: sortedComponents[0].id,
                fullName: sortedComponents[0].name,
                zip: $event.zip,
                text: null,
                name: sortedComponents[0].name,
                longitude: $event.longitude,
                latitude: $event.latitude,
                componentType: sortedComponents[0].componentType,
                parentPlaceId: $event.parentPlaceId
            };
        }

        this.internalValue = result;
        this.geocodingDispatchers.dispatchGeocodingResetAction();

        if (this.propagateTouch) {
            this.propagateTouch();
        }
        if (this.propagateChange) {
            this.propagateChange(this.internalValue);
        }

        this.selectionChange.emit(result);
    }

    public writeValue(value: string) {
        this.internalValue = value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn) {
        this.propagateTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
