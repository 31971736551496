import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderStatusUpdateRequest } from '../../models/business/orders/order-status-update-request.model';

import { OrderUpdateStatusAction, OrderUpdateStatusFailAction, OrderUpdateStatusResetAction, OrderUpdateStatusSuccessAction } from './order-update-status.actions';

export interface OrderUpdateStatusStateModel {
    pending: boolean;
    updated: OrderStatusUpdateRequest;
    error: ErrorObject;
}

@State<OrderUpdateStatusStateModel>({
    name: 'orderUpdateStatus',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class OrderUpdateStatusState {
    constructor(private ordersService: OrdersService) { }

    @Action(OrderUpdateStatusAction) orderUpdateStatus(ctx: StateContext<OrderUpdateStatusStateModel>, action: OrderUpdateStatusAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.ordersService.updateOrderStatus(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new OrderUpdateStatusSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrderUpdateStatusFailAction(err)), 0));
    }

    @Action(OrderUpdateStatusSuccessAction) orderUpdateStatusSuccess(ctx: StateContext<OrderUpdateStatusStateModel>, action: OrderUpdateStatusSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(OrderUpdateStatusFailAction) orderUpdateStatusFail(ctx: StateContext<OrderUpdateStatusStateModel>, action: OrderUpdateStatusFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(OrderUpdateStatusResetAction) orderUpdateStatusReset(ctx: StateContext<OrderUpdateStatusStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
