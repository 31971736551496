import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrderCreateAction, OrderCreateFailAction, OrderCreateResetAction, OrderCreateSuccessAction } from './order-create.actions';
import { Order } from '../../models/business/orders/order.model';

export interface OrderCreateStateModel {
    pending: boolean;
    created: Order;
    error: ErrorObject;
}

@State<OrderCreateStateModel>({
    name: 'orderCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class OrderCreateState {
    constructor(private ordersService: OrdersService) { }

    @Action(OrderCreateAction) async orderCreate(ctx: StateContext<OrderCreateStateModel>, action: OrderCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.ordersService.createOrder({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new OrderCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrderCreateFailAction(err)), 0));
    }

    @Action(OrderCreateSuccessAction) orderCreateSuccess(ctx: StateContext<OrderCreateStateModel>, action: OrderCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(OrderCreateFailAction) orderCreateFail(ctx: StateContext<OrderCreateStateModel>, action: OrderCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(OrderCreateResetAction) orderCreateReset(ctx: StateContext<OrderCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
