import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { Task } from '../../models/business/tasks/task.model';
import { TasksListFilterData } from '../../models/filters/tasks-list-filter.model';

export class TasksAction {
    static readonly type = '[Tasks Page] GetTasks';

    constructor(public payload: PaginationRequest) { }
}

export class TasksSuccessAction {
    static readonly type = '[Tasks API] GetTasks Success';

    constructor(public payload: { list: PaginationResult<Task>, pagination: PaginationRequest }) { }
}

export class TasksFailAction {
    static readonly type = '[Tasks API] GetTasks Fail';

    constructor(public payload: ErrorObject) { }
}

export class TasksResetAction {
    static readonly type = '[Tasks Page] GetTasks Reset';
}

export class TasksSetFilterAction {
    static readonly type = '[Tasks API] SetFilter';

    constructor(public payload: TasksListFilterData) { }
}
