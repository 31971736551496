import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseInvoicesService } from '../../services/business/purchase-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseInvoice } from '../../models/business/purchase-invoices/purchase-invoice.model';

import { PurchaseInvoiceFailAction, PurchaseInvoiceGetAction, PurchaseInvoiceGetSuccessAction, PurchaseInvoiceResetAction } from './purchase-invoice.actions';

export interface PurchaseInvoiceStateModel {
    entity: PurchaseInvoice;
    pending: boolean;
    error: ErrorObject;
}

@State<PurchaseInvoiceStateModel>({
    name: 'purchaseInvoice',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PurchaseInvoiceState {
    constructor(private purchaseInvoicesService: PurchaseInvoicesService) { }

    @Action(PurchaseInvoiceGetAction) purchaseInvoiceGet(ctx: StateContext<PurchaseInvoiceStateModel>, action: PurchaseInvoiceGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.purchaseInvoicesService.getPurchaseInvoice(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PurchaseInvoiceGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseInvoiceFailAction(err)), 0));
    }

    @Action(PurchaseInvoiceGetSuccessAction) purchaseInvoiceGetSuccess(ctx: StateContext<PurchaseInvoiceStateModel>, action: PurchaseInvoiceGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PurchaseInvoiceFailAction) purchaseInvoiceGetFail(ctx: StateContext<PurchaseInvoiceStateModel>, action: PurchaseInvoiceFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseInvoiceResetAction) purchaseInvoiceGetReset(ctx: StateContext<PurchaseInvoiceStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
