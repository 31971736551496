import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CookingsService } from '../../services/cookings.service';

import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CookingUpdateAction, CookingUpdateFailAction, CookingUpdateResetAction, CookingUpdateSuccessAction } from './cooking-update.actions';

export interface CookingUpdateStateModel {
    pending: boolean;
    updated: DCRCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<CookingUpdateStateModel>({
    name: 'cookingUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class CookingUpdateState {
    constructor(private cookingsService: CookingsService) { }

    @Action(CookingUpdateAction) async cookingUpdate(ctx: StateContext<CookingUpdateStateModel>, action: CookingUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.cookingsService.updateCooking(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new CookingUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CookingUpdateFailAction(err)), 0));
    }

    @Action(CookingUpdateSuccessAction) cookingUpdateSuccess(ctx: StateContext<CookingUpdateStateModel>, action: CookingUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(CookingUpdateFailAction) cookingUpdateFail(ctx: StateContext<CookingUpdateStateModel>, action: CookingUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(CookingUpdateResetAction) cookingUpdateReset(ctx: StateContext<CookingUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
