import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PostingsService } from '../../services/business/postings.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PostingCreateAction, PostingCreateFailAction, PostingCreateResetAction, PostingCreateSuccessAction } from './posting-create.actions';
import { Posting } from '../../models/business/postings/posting.model';

export interface PostingCreateStateModel {
    pending: boolean;
    created: Posting;
    error: ErrorObject;
}

@State<PostingCreateStateModel>({
    name: 'postingCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PostingCreateState {
    constructor(private postingsService: PostingsService) { }

    @Action(PostingCreateAction) async postingCreate(ctx: StateContext<PostingCreateStateModel>, action: PostingCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.postingsService.createPosting({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PostingCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PostingCreateFailAction(err)), 0));
    }

    @Action(PostingCreateSuccessAction) postingCreateSuccess(ctx: StateContext<PostingCreateStateModel>, action: PostingCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PostingCreateFailAction) postingCreateFail(ctx: StateContext<PostingCreateStateModel>, action: PostingCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PostingCreateResetAction) postingCreateReset(ctx: StateContext<PostingCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
