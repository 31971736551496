import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { UserSearchAction, UserSearchFailAction, UserSearchResetAction, UserSearchSuccessAction } from './users-search.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';
import { UserForTyping } from '../../models/business/user/user-for-typing.model';
import { UsersForTypingFilter } from '../../models/filters/users-for-typing.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface UserSearchStateModel extends PaginationStateModel<UserForTyping> {
    pending: boolean;
    uids: string[];
    entities: { [uid: string]: UserForTyping };
    retrieved: boolean;
    error: ErrorObject;
    allItemsLoaded: boolean;
    total: number;
    index: number;
}

@State<UserSearchStateModel>({
    name: 'usersSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0 }
})
@Injectable()
export class UserSearchState extends PaginationStateBase {
    constructor(private usersService: UsersService) {
        super();
    }

    @Action([UserSearchAction]) async usersGet(ctx: StateContext<UserSearchStateModel>, _: UserSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new UsersForTypingFilter({ pageIndex: state.index, filterData: { includeCurrentUserInSelection: true } });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.usersService.getUsersForTyping(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new UserSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UserSearchFailAction(err)), 0));
    }

    @Action(UserSearchSuccessAction) usersGetSuccess(ctx: StateContext<UserSearchStateModel>, action: UserSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadFullList, ExgParamsConfig.pagination.usersAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(UserSearchFailAction) usersGetFail(ctx: StateContext<UserSearchStateModel>, action: UserSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(UserSearchResetAction) usersGetReset(ctx: StateContext<UserSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0 });
    }
}
