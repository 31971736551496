import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class UserDeleteAction {
    static readonly type = '[Users API] GetUserDelete';

    constructor(public payload: string) { }
}

export class UserDeleteSuccessAction {
    static readonly type = '[Users API] GetUserDelete Success';
}

export class UserDeleteFailAction {
    static readonly type = '[Users API] GetUserDelete Fail';

    constructor(public payload: ErrorObject) { }
}

export class UserDeleteResetAction {
    static readonly type = '[Users API] GetUserDelete Reset';
}
