import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FeedListGetFilterData } from '../../models/filters/feed-list-get-filter.model';
import { Feed } from '../../models/business/feed/feed.model';

import { CompanyFeedState, CompanyFeedStateModel } from './company-feed.reducer';

@Injectable({
    providedIn: 'root'
})
export class CompanyFeedSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.pending);

    static readonly getCompanyFeed = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.retrieved);

    static readonly getError = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.error);

    static readonly getAllCompanyFeedLoaded = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.filter);

    static readonly getTotal = createSelector([CompanyFeedState], (state: CompanyFeedStateModel) => state.total);

    @Select(CompanyFeedSelectors.getPending) pending$: Observable<boolean>;

    @Select(CompanyFeedSelectors.getCompanyFeed) feed$: Observable<Feed[]>;

    @Select(CompanyFeedSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(CompanyFeedSelectors.getError) error$: Observable<ErrorObject>;

    @Select(CompanyFeedSelectors.getAllCompanyFeedLoaded) allCompanyFeedLoaded$: Observable<boolean>;

    @Select(CompanyFeedSelectors.getFilter) filter$: Observable<FeedListGetFilterData>;

    @Select(CompanyFeedSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('CompanyFeedSelectors');
    }
}
