import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { DCRCreateOrUpdateRequest } from '../models/business/dcr/dcr-create-or-update-request.model';
import { DCRItem } from '../models/business/dcr/dcr-item.model';
import { DcrListGetFilter } from '../models/filters/dcr-list-get-filter.model';
import { DCRListItem } from '../models/business/dcr/dcr-list-item.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class RecyclingsService extends BaseSingletonService {

    private settings: {
        service: {
            getRecycling: string;
            getRecyclings: string;
            createRecycling: string;
            updateRecycling: string;
            deleteRecycling: string;
        }
    };

    constructor(private http: HttpService) {
        super('RecyclingsService');
        this.settings = {
            service: {
                getRecycling: '/restaurants-aggregation/v1/recyclings/{0}',
                getRecyclings: '/restaurants-aggregation/v1/recyclings?organizationUid={0}&warehouseUid={1}&status={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createRecycling: '/restaurants/v1/recyclings',
                updateRecycling: '/restaurants/v1/recyclings/{0}',
                deleteRecycling: '/restaurants/v1/recyclings/{0}',
            }
        };
    }

    public async getRecycling(uid: string): Promise<DCRItem> {
        return this.http.get<{ data: DCRItem }>(String.format(this.settings.service.getRecycling, uid))
            .then(res => res.data);
    }

    public async getRecyclings(filter: DcrListGetFilter): Promise<PaginationResult<DCRListItem>> {
        return this.http.get<PaginationResult<DCRListItem>>(String.format(this.settings.service.getRecyclings, filter.organizationUid, filter.warehouseUid, filter.status, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createRecycling(request: DCRCreateOrUpdateRequest): Promise<DCRItem> {
        return this.http.post<{ data: DCRItem }>(String.format(this.settings.service.createRecycling), request)
            .then(res => res.data);
    }

    public async updateRecycling(uid: string, request: DCRCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateRecycling, uid), request);
    }

    public async deleteRecycling(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRecycling, uid));
    }
}
