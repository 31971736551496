import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SalesPlanService } from '../../services/business/sales-plan.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { SalesPlanUpdateListAction, SalesPlanUpdateListFailAction, SalesPlanUpdateListResetAction, SalesPlanUpdateListSuccessAction } from './sales-plan-update-list.actions';

export interface SalesPlanUpdateListStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<SalesPlanUpdateListStateModel>({
    name: 'salesPlanUpdateList',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class SalesPlanUpdateListState {
    constructor(private salesPlanService: SalesPlanService) { }

    @Action(SalesPlanUpdateListAction) async salesPlanUpdateList(ctx: StateContext<SalesPlanUpdateListStateModel>, action: SalesPlanUpdateListAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.salesPlanService.updateSalesPlanList(action.payload.year, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new SalesPlanUpdateListSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SalesPlanUpdateListFailAction(err)), 0));
    }

    @Action(SalesPlanUpdateListSuccessAction) salesPlanUpdateListSuccess(ctx: StateContext<SalesPlanUpdateListStateModel>, _: SalesPlanUpdateListSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(SalesPlanUpdateListFailAction) salesPlanUpdateListFail(ctx: StateContext<SalesPlanUpdateListStateModel>, action: SalesPlanUpdateListFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(SalesPlanUpdateListResetAction) salesPlanUpdateListReset(ctx: StateContext<SalesPlanUpdateListStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
