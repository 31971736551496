import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderListGetFilterData } from '../../models/filters/order-list-get-filter.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrderSummary } from '../../models/business/orders/order-summary.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrdersAction {
    static readonly type = '[Orderss Page] GetOrders';

    constructor(public payload: PaginationRequest) { }
}

export class OrdersSuccessAction {
    static readonly type = '[Orders API] GetOrders Success';

    constructor(public payload: { list: PaginationResult<OrderListItem>, pagination: PaginationRequest, summary: OrderSummary }) { }
}

export class OrdersFailAction {
    static readonly type = '[Orders API] GetOrders Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrdersResetAction {
    static readonly type = '[Orders Page] GetOrders Reset';
}

export class OrdersSetFilterAction {
    static readonly type = '[Orders API] SetFilter';

    constructor(public payload: OrderListGetFilterData) { }
}

export class OrdersCreateFromSocketAction {
    static readonly type = '[Orders hub] GetOrder Socket Create';

    constructor(public payload: OrderListItem) { }
}

export class OrdersUpdateFromSocketAction {
    static readonly type = '[Orders hub] GetOrder Socket Update';

    constructor(public payload: OrderListItem) { }
}

export class OrdersDeleteFromSocketAction {
    static readonly type = '[Orders hub] GetOrder Socket Delete';

    constructor(public payload: { uid: string }) { }
}
