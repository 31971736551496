<div class="select">
    <exg-checkbox class="checkbox" [value]="checked" (valueChange)="onCheckItem()"></exg-checkbox>
</div>
<button class="btn" type="button" (click)="onOpenClick()">
    <div class="number" *ngIf="listConfig[drcSortingFieldsEnum.Number]" [class.active]="filter.sortField === drcSortingFieldsEnum.Number">
        <span title="{{entity.number}}">{{entity.number}}</span>
    </div>
    <div class="date" *ngIf="listConfig[drcSortingFieldsEnum.Date]" [class.active]="filter.sortField === drcSortingFieldsEnum.Date">
        <span title="{{entity.date | exgDate : 'dd.MM.y HH:mm' : timeZone }}">{{entity.date| exgDate : 'dd.MM.y HH:mm' : timeZone }}</span>
    </div>
    <div class="products" *ngIf="listConfig[drcSortingFieldsEnum.Products]" [class.active]="filter.sortField === drcSortingFieldsEnum.Products">
        <span title="{{productsNames}}">{{productsNames}}</span>
    </div>
    <div class="sum" *ngIf="listConfig[drcSortingFieldsEnum.Sum]" [class.active]="filter.sortField === drcSortingFieldsEnum.Sum">
        <span title="{{entity.sum | exgNumber: '1.2-2'}}">{{entity.sum | exgNumber: '1.2-2'}}</span>
    </div>
    <div class="difference-amount" *ngIf="listConfig[drcSortingFieldsEnum.DifferenceAmount]" [class.active]="filter.sortField === drcSortingFieldsEnum.DifferenceAmount">
        <span title="{{entity.differenceAmount}}">{{entity.differenceAmount}}</span>
    </div>
    <div class="unit-type" *ngIf="listConfig[drcSortingFieldsEnum.UnitType]" [class.active]="filter.sortField === drcSortingFieldsEnum.UnitType">
        <span title="{{entity.unitType?.shortName}}">{{entity.unitType?.shortName}}</span>
    </div>
    <div class="warehouse-from" *ngIf="listConfig[drcSortingFieldsEnum.WarehouseFrom]" [class.active]="filter.sortField === drcSortingFieldsEnum.WarehouseFrom">
        <span title="{{entity.warehouseFrom?.name}}">{{entity.warehouseFrom?.name}}</span>
    </div>
    <div class="warehouse-to" *ngIf="listConfig[drcSortingFieldsEnum.WarehouseTo]" [class.active]="filter.sortField === drcSortingFieldsEnum.WarehouseTo">
        <span title="{{entity.warehouseTo?.name}}">{{entity.warehouseTo?.name}}</span>
    </div>
    <div class="notes" *ngIf="listConfig[drcSortingFieldsEnum.Notes]" [class.active]="filter.sortField === drcSortingFieldsEnum.Notes">
        <span title="{{entity.notes}}">{{entity.notes}}</span>
    </div>
</button>
<div class="menu">
</div>