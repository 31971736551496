<section class="row-header">
    <bvc-filter-field class="full-name header"
                      [class.active]="filter.sortField === companySortingFields.FullName"
                      [name]="'Name1'"
                      [fieldName]="companySortingFields.FullName"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="contact header"
                      [class.active]="filter.sortField === companySortingFields.Contact"
                      [name]="'Main Contact'"
                      [fieldName]="companySortingFields.Contact"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="phone header"
                      [class.active]="filter.sortField === companySortingFields.Phone"
                      [name]="'Phone'"
                      [fieldName]="companySortingFields.Phone"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="city header"
                      [class.active]="filter.sortField === companySortingFields.City"
                      [name]="'City'"
                      [fieldName]="companySortingFields.City"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="type header"
                      [class.active]="filter.sortField === companySortingFields.Type"
                      [name]="'Type'"
                      [fieldName]="companySortingFields.Type"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="company-status header"
                      [class.active]="filter.sortField === companySortingFields.Status"
                      [name]="'Status'"
                      [fieldName]="companySortingFields.Status"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>
</section>
<section class="row-header shadow">
    <div class="full-name header"></div>
    <div class="contact header"></div>
    <div class="phone header"></div>
    <div class="city header"></div>
    <div class="type header"></div>
    <div class="company-status header"></div>
</section>