<exg-autocomplete class="control"
                  [maxlength]="maxlength" 
                  [data]="companyForSearc$ | async"
                  [placeholder]="placeholder"
                  [value]="value"
                  [readonly]="readonly"
                  [styleType]="styleType"
                  [displayValueFunction]="countryDisplayValueFunction"
                  [validate]="validate"
                  [allowNull]="allowNull"
                  [emptyDisplayValue]="emptyDisplayValue"
                  [controlsToValidate]="controlsToValidate"
                  (valueChange)="onSearchPlace($event)"
                  (selectionChange)="onSelectionChange($event)"></exg-autocomplete>