import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CustomersService } from '../../services/business/customers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerCreateAction, CustomerCreateFailAction, CustomerCreateResetAction, CustomerCreateSuccessAction } from './customer-create.actions';
import { Customer } from '../../models/business/customers/customer.model';

export interface CustomerCreateStateModel {
    pending: boolean;
    created: Customer;
    error: ErrorObject;
}

@State<CustomerCreateStateModel>({
    name: 'customerCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class CustomerCreateState {
    constructor(private customersService: CustomersService) { }

    @Action(CustomerCreateAction) async customerCreate(ctx: StateContext<CustomerCreateStateModel>, action: CustomerCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.customersService.createCustomer({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new CustomerCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomerCreateFailAction(err)), 0));
    }

    @Action(CustomerCreateSuccessAction) customerCreateSuccess(ctx: StateContext<CustomerCreateStateModel>, action: CustomerCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(CustomerCreateFailAction) customerCreateFail(ctx: StateContext<CustomerCreateStateModel>, action: CustomerCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(CustomerCreateResetAction) customerCreateReset(ctx: StateContext<CustomerCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
