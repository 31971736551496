import { PersonnelCreateOrUpdateRequest } from '../../models/business/personnels/personnel-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PersonnelUpdateAction {
    static readonly type = '[Booking Page] UpdatePersonnel';

    constructor(public payload: { uid: string, request: PersonnelCreateOrUpdateRequest }) { }
}

export class PersonnelUpdateSuccessAction {
    static readonly type = '[Personnel API] UpdatePersonnel Success';

    constructor(public payload: { uid: string, request: PersonnelCreateOrUpdateRequest }) { }
}

export class PersonnelUpdateFailAction {
    static readonly type = '[Personnel API] UpdatePersonnel Fail';

    constructor(public payload: ErrorObject) { }
}

export class PersonnelUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePersonnel Reset';
}
