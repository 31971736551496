export class ExgParamsConfig {
    public static readonly logos = {
        emptyUserAvatar: '/assets/images/no_photo.png',
        emptyProductPhoto: '/assets/images/no_photo.png'
    };

    public static readonly photosUpload = {
        avatarSize: 400,
        productImageWidth: 1024,
        productImageHeight: 768,
        brandWidth: 240,
        brandHeight: 80
    };

    public static readonly signalR = {
        identity: {
            OnNameChanged: { hub: '/identity/identityhub', method: 'OnNameChanged' },
            OnEmailConfirmed: { hub: '/identity/identityhub', method: 'OnEmailConfirmed' },
            OnGroupMemberAdded: { hub: '/identity/identityhub', method: 'OnGroupMemberAdded' }
        }
    };

    public static readonly timeouts = {
        registerSuccessRedirecrion: 5000,
        resendEmailReactivateButton: 60,
        resendEmailReactivateButtonTimeoutTick: 1000
    };

    public static readonly pagination = {
        pagesSizesArray: [25, 50, 100],
        defaultPageSize: 25,
        usersAllListPageSize: 25,
        posAllListPageSize: 25,
        companiesAllListPageSize: 25,
        contactsAllListPageSize: 25,
        contragentsAllListPageSize: 25,
        requisitesAllListPageSize: 25,
        contractsAllListPageSize: 25,
        ordersAllListPageSize: 25,
        brandsAllListPageSize: 100,
        categoriesAllListPageSize: 25,
        productsAllListPageSize: 25,
        vehicleBrandsListPageSize: 25,
        salesPlanAllListPageSize: 25,
        warehousesAllListPageSize: 25,
        pricelistAllListPageSize: 25,
        notesAllListPageSize: 25,
        characteristicsAllListPageSize: 25,
        tasksAllListPageSize: 25,
        ingredientsAllListPageSize: 25,
        recipesAllListPageSize: 25,
        workPlacesAllListPageSize: 25,
        organizationsAllListPageSize: 25,
        productAllListPageSize: 25,
        posesAllListPageSize: 25,
        taxsAllListPageSize: 25,
        orderHistoryListPageSize: 25,
        franchiseesAllListPageSize: 25,
        personnelsAllListPageSize: 25,
        corporationRequisitesAllListPageSize: 25,
    };

    public static readonly costPriceType = 'abc20850-d72c-4154-9148-c756521e1159';
    public static readonly retailCustomerUid = '5de727a9-932e-4d88-9205-5c480a641bed';
    public static readonly yobiOrganization = {
        uid: '00000000-0000-0000-0000-000000000000',
        name: 'ООО Ёбидоёби',
        franchiseeType: '00000000-0000-0000-0000-000000000000',
        legalAddress: null,
        inn: null,
    };
}
