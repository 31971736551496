import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContactListItem } from '../../../../models/business/contacts/contact-list-item.model';
import { ContactSortingFieldsEnum } from '../../../../models/business/contacts/contact-sorting-fields.enum';
import { ContactsListFilterData } from '../../../../models/filters/contacts-list-filter.model';

@Component({
    selector: 'contact-list-item',
    templateUrl: './contact-list-item.component.html',
    styleUrls: ['./contact-list-item.component.scss', '../contact-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListItemComponent {

    @Input() contact: ContactListItem;
    @Input() filter: ContactsListFilterData;
    @Input() listConfig: any;

    @Output() readonly openContact = new EventEmitter<string>();

    public contactSortingFields = ContactSortingFieldsEnum;

    public onOpenContactClick() {
        this.openContact.emit(this.contact.uid);
    }
}
