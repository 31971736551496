import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';
import { TechnologicalMapProductHierarchy } from '../../models/business/technological-maps/technological-map-product-hierarchy.model';

export class TechnologicalMapByProductAction {
    static readonly type = '[TechnologicalMapByProduct Page] GetTechnologicalMapByProduct';

    constructor(public payload: PaginationRequest) { }
}

export class TechnologicalMapByProductSuccessAction {
    static readonly type = '[TechnologicalMapByProduct API] GetTechnologicalMapByProduct Success';

    constructor(public payload: { list: PaginationResult<TechnologicalMapProductHierarchy>, pagination: PaginationRequest }) { }
}

export class TechnologicalMapByProductFailAction {
    static readonly type = '[TechnologicalMapByProduct API] GetTechnologicalMapByProduct Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapByProductResetAction {
    static readonly type = '[TechnologicalMapByProduct Page] GetTechnologicalMapByProduct Reset';
}

export class TechnologicalMapByProductSetFilterAction {
    static readonly type = '[TechnologicalMapByProduct API] SetFilter';

    constructor(public payload: TechnologicalMapListGetFilterData) { }
}
