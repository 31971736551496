import { ChangeDetectionStrategy, Component, Injector, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';

import { OrganizationTreeDispatchers } from '../../../store/organization-tree/organization-tree.dispatchers';
import { OrganizationTreeSelectors } from '../../../store/organization-tree/organization-tree.selectors';

import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogButton } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-button.model';
import { ExgDialogResultEvent } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { IExgDialogable } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialogable.interface';
import { OrganizationTreeFilterData } from '../../../../../evasys/shared/components/business/organizations-tree/organization-tree-filter-data.model';
import { OrganizationTreeFilterItemData } from '../../../../../evasys/shared/components/business/organizations-tree/organization-tree-filter-item-data.model';
import { OrganizationTree } from '../../../../../evasys/shared/models/business/organization-tree/organization-tree.model';

@Component({
    templateUrl: './bvc-organization-filter.component.html',
    styleUrls: ['./bvc-organization-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BvcOrganizationFilterComponent implements IExgDialogable, OnDestroy {
    public name: FormControl;
    public isContractor: FormControl;
    public country: FormControl;
    public organizationTree: OrganizationTree = null;
    public treeFilterData: OrganizationTreeFilterData;
    public selectedUids: OrganizationTreeFilterItemData[] = [];

    public previourseState$ = new BehaviorSubject(null);
    public editState$ = new Subject();
    public unsubscribe$ = new Subject();

    private proceedClose: (_: ExgDialogResultEvent) => void;

    constructor(private injector: Injector,
                private organizationTreeDispatchers: OrganizationTreeDispatchers,
                private organizationTreeSelectors: OrganizationTreeSelectors) {
        this.selectedUids = this.injector.get('selectedUids');
        this.organizationTree = this.injector.get('organizationTree');
        if (!this.organizationTree) {
            this.organizationTreeDispatchers.dispatchOrganizationTreeAction({});
        }
        this.organizationTreeSelectors.organizationTree$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(organizationTree => (this.organizationTree = organizationTree));
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public exgDialogClose(_: ExgDialogButton): any {
        /** no need */
    }

    public onCloseClick(): void {
        this.proceedClose({ dialogResult: DialogResult.Close });
    }

    public registerOnDialogClose(fn: (_: ExgDialogResultEvent) => void): void {
        this.proceedClose = fn;
    }

    public isDefaultChanged($event): void {
        this.isContractor.patchValue($event);
    }

    public productDisplayValueFunction(item: any) {
        return item && item.code ? `${item.code} — ${item?.name}` : item?.name;
    }

    public onTextValueChange(term): void {
        this.organizationTreeDispatchers.dispatchOrganizationTreeAction({ term });
    }

    public onValueChange(treeFilterData: OrganizationTreeFilterData): void {
        this.treeFilterData = treeFilterData;
        this.selectedUids = [...treeFilterData.franchiseeUids, ...treeFilterData.organizationPosUids, ...treeFilterData.organizationUids];
    }

    public toArrayOfUids(data: OrganizationTreeFilterItemData[]) {
        return data.map(v => v.uid);
    }

    public apply() {
        const filter = {
            organizationUids: this.treeFilterData?.organizationUids.map(v => v.uid) ?? [],
            organizationPosUids: this.treeFilterData?.organizationPosUids.map(v => v.uid) ?? [],
            franchiseeUids: this.treeFilterData?.franchiseeUids.map(v => v.uid) ?? [],
        };

        this.proceedClose({ dialogResult: DialogResult.Ok, dataFromComponent: { selectedUids: this.selectedUids, filter } });
    }
}
