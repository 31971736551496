import { CompanyStatus } from '../business/companies/company-status.enum';
import { CompanyType } from '../business/companies/company-type.enum';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class CompaniesListFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public userUid?: string;
    public statuses?: CompanyStatus[];
    public types?: CompanyType[];
    public citiesIds?: string[];
    public responsibleUsersUids?: string[];

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: CompaniesListFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.userUid = (args && args.filterData) ? args.filterData.userUid : null;
        this.statuses = (args && args.filterData) ? args.filterData.statuses : null;
        this.types = (args && args.filterData) ? args.filterData.types : null;
        this.responsibleUsersUids = (args && args.filterData) ? args.filterData.responsibleUsersUids : null;
        this.citiesIds = (args && args.filterData) ? args.filterData.citiesIds : null;
    }
}

export class CompaniesListFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public userUid?: string;
    public statuses?: CompanyStatus[];
    public types?: CompanyType[];
    public citiesIds?: string[];
    public responsibleUsersUids?: string[];
}
