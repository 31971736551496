import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Product } from '../../models/business/catalog/product.model';

import { ProductAction, ProductFailAction, ProductResetAction, ProductSuccessAction, ProductUpdateImagesAction } from './product.actions';
import { MediaLight } from '../../../../burns-ui-framework/shared/models/business/media/media-light.model';

export interface ProductStateModel {
    pending: boolean;
    entity: Product;
    error: ErrorObject;
}

@State<ProductStateModel>({
    name: 'product',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ProductState {
    constructor(private catalogService: CatalogService) { }

    @Action(ProductAction) async product(ctx: StateContext<ProductStateModel>, action: ProductAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.catalogService.getProduct(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new ProductSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductFailAction(err)), 0));
    }

    @Action(ProductSuccessAction) productSuccess(ctx: StateContext<ProductStateModel>, action: ProductSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ProductFailAction) productFail(ctx: StateContext<ProductStateModel>, action: ProductFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductResetAction) productReset(ctx: StateContext<ProductStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(ProductUpdateImagesAction) productUpdateMedia(ctx: StateContext<ProductStateModel>, action: { payload: { addedPhotos: MediaLight[], deletedPhotos: string[], productUid: string }}) {
        const state = ctx.getState();

        const updatedProduct = {...state.entity};

        action.payload.deletedPhotos?.forEach((uid) => {
            updatedProduct.medias = [...updatedProduct.medias.filter((v) => v.uid !== uid)];
        });

        action.payload.addedPhotos?.forEach((image) => {
            updatedProduct.medias = [...updatedProduct.medias, image];
        });

        ctx.setState({ ...state, pending: false, entity: updatedProduct, error: null });
    }
}
