import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { Modifier } from '../../models/business/modifiers/modifier.model';
import { ModifiersListGetFilterData } from '../../models/filters/modifiers-list-get-filter.model';

export class ModifiersAction {
    static readonly type = '[Modifiers Page] GetModifiers';

    constructor(public payload: PaginationRequest) { }
}

export class ModifiersSuccessAction {
    static readonly type = '[Modifiers API] GetModifiers Success';

    constructor(public payload: { list: PaginationResult<Modifier>, pagination: PaginationRequest }) { }
}

export class ModifiersFailAction {
    static readonly type = '[Modifiers API] GetModifiers Fail';

    constructor(public payload: ErrorObject) { }
}

export class ModifiersResetAction {
    static readonly type = '[Modifiers Page] GetModifiers Reset';
}

export class ModifiersSetFilterAction {
    static readonly type = '[Modifiers API] SetFilter';

    constructor(public payload: ModifiersListGetFilterData) { }
}
