import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { AcceptanceGetAction, AcceptanceResetAction } from './acceptance.actions';

@Injectable({
    providedIn: 'root'
})
export class AcceptanceDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('AcceptanceDispatchers');
    }

    public dispatchAcceptanceAction(uid: string) {
        this.store.dispatch(new AcceptanceGetAction(uid));
    }

    public dispatchAcceptanceResetAction() {
        this.store.dispatch(new AcceptanceResetAction());
    }
}
