import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProfileUpdateSettingsAction, ProfileUpdateSettingsFailAction, ProfileUpdateSettingsResetAction, ProfileUpdateSettingsSuccessAction } from './profile-update-settings.actions';

export interface ProfileUpdateSettingsStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<ProfileUpdateSettingsStateModel>({
    name: 'profileUpdateSettings',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class ProfileUpdateSettingsState {
    constructor(private userService: UsersService) { }

    @Action(ProfileUpdateSettingsAction) async profileSettingsUpdate(ctx: StateContext<ProfileUpdateSettingsStateModel>, action: ProfileUpdateSettingsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.userService.updateUserSettings(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new ProfileUpdateSettingsSuccessAction({ culture: action.payload.request.culture })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProfileUpdateSettingsFailAction(err)), 0));
    }

    @Action(ProfileUpdateSettingsSuccessAction) profileUpdateSuccess(ctx: StateContext<ProfileUpdateSettingsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(ProfileUpdateSettingsFailAction) profileUpdateFail(ctx: StateContext<ProfileUpdateSettingsStateModel>, action: ProfileUpdateSettingsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(ProfileUpdateSettingsResetAction) profileUpdateReset(ctx: StateContext<ProfileUpdateSettingsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
