import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TaskCreateOrUpdateRequest } from '../../models/business/tasks/task-create-or-update.model';
import { Task } from '../../models/business/tasks/task.model';

export class TaskCreateAction {
    static readonly type = '[Tasks Page] CreateTask';

    constructor(public payload: TaskCreateOrUpdateRequest) { }
}

export class TaskCreateSuccessAction {
    static readonly type = '[Task API] CreateTask Success';

    constructor(public payload: Task) { }
}

export class TaskCreateFailAction {
    static readonly type = '[Task API] CreateTask Fail';

    constructor(public payload: ErrorObject) { }
}

export class TaskCreateResetAction {
    static readonly type = '[Tasks Page] CreateTask Reset';
}
