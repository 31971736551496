import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TaskDeleteAction, TaskDeleteResetAction } from './task-delete.actions';

@Injectable({
    providedIn: 'root'
})
export class TaskDeleteDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TaskDeleteDispatchers');
    }

    public dispatchTaskDeleteAction(uid: string) {
        this.store.dispatch(new TaskDeleteAction({ uid }));
    }

    public dispatchTaskDeleteResetAction() {
        this.store.dispatch(new TaskDeleteResetAction());
    }
}
