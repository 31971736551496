import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NotesService } from '../../services/business/note.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { NoteDeleteAction, NoteDeleteFailAction, NoteDeleteResetAction, NoteDeleteSuccessAction } from './note-delete.actions';

export interface NoteDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<NoteDeleteStateModel>({
    name: 'noteDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class NoteDeleteState {
    constructor(private notesService: NotesService) { }

    @Action(NoteDeleteAction) async noteDelete(ctx: StateContext<NoteDeleteStateModel>, action: NoteDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.notesService.deleteNote(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new NoteDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new NoteDeleteFailAction(error)), 0));
    }

    @Action(NoteDeleteSuccessAction) noteDeleteSuccess(ctx: StateContext<NoteDeleteStateModel>, _: NoteDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(NoteDeleteFailAction) noteDeleteFail(ctx: StateContext<NoteDeleteStateModel>, action: NoteDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(NoteDeleteResetAction) noteDeleteReset(ctx: StateContext<NoteDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
