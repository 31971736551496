import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationRequisitesService } from '../../services/business/corporation-requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CorporationRequisiteUpdateAction, CorporationRequisiteUpdateFailAction, CorporationRequisiteUpdateResetAction, CorporationRequisiteUpdateSuccessAction } from './corporation-requisite-update.actions';

export interface CorporationRequisiteUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<CorporationRequisiteUpdateStateModel>({
    name: 'corporationRequisiteUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class CorporationRequisiteUpdateState {
    constructor(private corporationRequisitesService: CorporationRequisitesService) { }

    @Action(CorporationRequisiteUpdateAction) async corporationCorporationCorporationRequisiteUpdate(ctx: StateContext<CorporationRequisiteUpdateStateModel>, action: CorporationRequisiteUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });
        
        return this.corporationRequisitesService.updateRequisite(action.payload.uid, action.payload.organizationUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new CorporationRequisiteUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CorporationRequisiteUpdateFailAction(err)), 0));
    }

    @Action(CorporationRequisiteUpdateSuccessAction) corporationCorporationCorporationRequisiteUpdateSuccess(ctx: StateContext<CorporationRequisiteUpdateStateModel>, _: CorporationRequisiteUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(CorporationRequisiteUpdateFailAction) corporationCorporationCorporationRequisiteUpdateFail(ctx: StateContext<CorporationRequisiteUpdateStateModel>, action: CorporationRequisiteUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(CorporationRequisiteUpdateResetAction) corporationCorporationCorporationRequisiteUpdateReset(ctx: StateContext<CorporationRequisiteUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
