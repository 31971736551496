import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PlanListFilterData } from '../../models/filters/plan-list-filter.model';
import { Plan } from '../../models/business/sales-plan/plan.model';

export class SalesPlansAction {
    static readonly type = '[SalesPlans Page] GetSalesPlans';

    constructor(public payload: { request: PaginationRequest, year: number }) { }
}

export class SalesPlansSuccessAction {
    static readonly type = '[SalesPlans API] GetSalesPlans Success';

    constructor(public payload: { list: PaginationResult<Plan>, pagination: PaginationRequest }) { }
}

export class SalesPlansFailAction {
    static readonly type = '[SalesPlans API] GetSalesPlans Fail';

    constructor(public payload: ErrorObject) { }
}

export class SalesPlansResetAction {
    static readonly type = '[SalesPlans Page] GetSalesPlans Reset';
}

export class SalesPlansSetFilterAction {
    static readonly type = '[SalesPlans API] SetFilter';

    constructor(public payload: { filter: PlanListFilterData, year: number }) { }
}
