import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WriteOffsService } from '../../services/business/write-offs.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WriteOffCreateOrUpdateRequest } from '../../models/business/write-offs/write-off-create-or-update-request.model';

import { WriteOffUpdateAction, WriteOffUpdateFailAction, WriteOffUpdateResetAction, WriteOffUpdateSuccessAction } from './write-off-update.actions';

export interface WriteOffUpdateStateModel {
    pending: boolean;
    updated: WriteOffCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<WriteOffUpdateStateModel>({
    name: 'writeoffUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class WriteOffUpdateState {
    constructor(private writeoffsService: WriteOffsService) { }

    @Action(WriteOffUpdateAction) async writeoffUpdate(ctx: StateContext<WriteOffUpdateStateModel>, action: WriteOffUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.writeoffsService.updateWriteOff(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new WriteOffUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WriteOffUpdateFailAction(err)), 0));
    }

    @Action(WriteOffUpdateSuccessAction) writeoffUpdateSuccess(ctx: StateContext<WriteOffUpdateStateModel>, action: WriteOffUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(WriteOffUpdateFailAction) writeoffUpdateFail(ctx: StateContext<WriteOffUpdateStateModel>, action: WriteOffUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(WriteOffUpdateResetAction) writeoffUpdateReset(ctx: StateContext<WriteOffUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
