import { ChangeDetectionStrategy, Component, Injector, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { BehaviorSubject, combineLatest, filter, map, merge, Subject, takeUntil } from 'rxjs';

import { ExgFormComponent } from '../../../../../burns-ui-framework/shared/components/abstract/exg-form.component';

import { AcceptanceSandbox } from '../../../../acceptances/shared/acceptance.sandbox';
import { SnackbarService } from '../../../../../burns-ui-framework/shared/services/common/snackbar.service';
import { TechnologicalMapsSandbox } from '../../../../../evasys/product-edit/revert-technological-maps/shared/technological-maps.sandbox';

import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogButton } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-button.model';
import { ExgDialogResultEvent } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { IExgDialogable } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialogable.interface';
import { LinkedMapProductCreateUpdate } from '../../../models/business/technological-maps/linked-map-product-create-update.model';
import { PaginationRequest } from '../../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductListItemAggregation } from '../../../../shared/models/business/products/product-list-item-aggregation.model';
import { ProductTypeEnum } from '../../../models/business/catalog/product-type.enum';
import { TechnologicalMapListItemAggregation } from '../../../models/business/technological-maps/technological-map-list-item-aggregation.model';
import { TechnologicalMapUpdateRequest } from '../../../models/business/technological-maps/technological-map-update-request.model';
import { WriteOffMethods } from '../../../models/business/write-offs/write-off-methods.enum';

import { DateUtils } from '../../../../../burns-ui-framework/shared/utils/date-utils';

@Component({
    templateUrl: './technological-map-position-form.component.html',
    styleUrls: ['./technological-map-position-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnologicalMapPositionFormComponent extends ExgFormComponent implements IExgDialogable, OnDestroy {
    public position: any = null;
    public productType: ProductTypeEnum = null;
    public map: TechnologicalMapListItemAggregation = null;
    public productUid: string;
    public today = DateUtils.convertStringToEpoc(DateUtils.currentDate);
    public isRevertTTK = false;
    public products$;

    // position parameters
    public product: FormControl;
    public article: FormControl;
    public unit: FormControl;
    public type: FormControl;
    public grossUnit: FormControl;
    public grossKilogram: FormControl;
    public coldLosses: FormControl;
    public hotLosses: FormControl;
    public netKilogram: FormControl;
    public finishedKilogram: FormControl;
    public quantityPerPackage: FormControl;

    // map parameters
    public dateFrom: FormControl;
    public dateTo: FormControl;
    public number: FormControl;

    public editState$ = new Subject<boolean>();
    public previourseState$ = new BehaviorSubject(null);

    private organizationPosUid: string = null;
    private unsubscribe$ = new Subject();
    private mapIndex = null;
    private positionIndex = null;

    private proceedClose: (_: ExgDialogResultEvent) => void;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly injector: Injector,
        private readonly acceptanceSandbox: AcceptanceSandbox,
        private readonly technologicalMapsSandbox: TechnologicalMapsSandbox,
        private readonly snackbar: SnackbarService
    ) {
        super();
        this.position = this.injector.get('position', this.position);
        this.map = this.injector.get('map', this.map);
        this.productType = this.injector.get('productType', this.productType);
        this.productUid = this.injector.get('productUid', null);
        this.organizationPosUid = this.injector.get('organizationPosUid', this.organizationPosUid);
        this.mapIndex = this.injector.get('mapIndex', this.mapIndex);
        this.isRevertTTK = this.injector.get('isRevertTTK', this.isRevertTTK);
        this.positionIndex = this.injector.get('positionIndex', this.positionIndex);

        this.products$ = this.acceptanceSandbox.products$.pipe(map(products => products.filter(product => product.uid !== this.productUid)));

        this.createForm();
        this.onSearchProducts('');

        if (this.position) {
            this.setFormData();
        }

        combineLatest([this.mainForm.valueChanges, this.previourseState$])
            .pipe(map(x => x[0] !== x[1]))
            .subscribe(x => this.editState$.next(x));

        this.technologicalMapsSandbox.technologicalMapCreated$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(() => {
                this.technologicalMapsSandbox.dispatchTechnologicalMapCreateReset();
                this.technologicalMapsSandbox.dispatchTechnologicalMapByContentAction(PaginationRequest.ReloadCurrentList);
                this.successRequestCloseModal();
                this.snackbar.showInfo('Technological map successfully saved.');
            });

        this.technologicalMapsSandbox.technologicalMapUpdated$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(() => {
                if (this.isRevertTTK) {
                    const technologyMap = { ...this.map };
                    const products = Object.assign([], technologyMap.products[this.positionIndex]);
                    const position = { ...technologyMap.products[this.positionIndex] };
                    position.quantityPerPackage = +this.quantityPerPackage.value;
                    position.finishedKilogram = +this.finishedKilogram.value;
                    products[this.positionIndex] = position;
                    technologyMap.products = products;
                    this.technologicalMapsSandbox.dispatchTechnologicalMapsByContentUpdateListItem(technologyMap);
                    this.snackbar.showInfo('Technological map successfully saved.');
                    this.successRequestCloseModal();
                }
            });

        merge(this.technologicalMapsSandbox.technologicalMapCreateError$, this.technologicalMapsSandbox.technologicalMapUpdateError$)
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(err => !!err)
            )
            .subscribe(error => {
                this.technologicalMapsSandbox.dispatchTechnologicalMapCreateReset();
                this.technologicalMapsSandbox.dispatchTechnologicalMapUpdateReset();
                this.snackbar.showError(error);
                // if (error.apiErrorCode === ErrorCodes.IntersectionTTK) {
                //     this.dateTo.setErrors({ incorrect: true });
                //     this.dateFrom.setErrors({ incorrect: true });
                //     this.showValidationErrors();
                // }
            });
    }

    public onCloseClick() {
        this.proceedClose({ dialogResult: DialogResult.Close });
    }

    public registerOnDialogClose(fn: (_: ExgDialogResultEvent) => void): void {
        this.proceedClose = fn;
    }

    public exgDialogClose(_: ExgDialogButton): any {
        /** no need */
    }

    public productDisplayValueFunction(item: any) {
        return item && item.code ? `${item.code} — ${item?.name}` : item?.name;
    }

    public onSearchProducts($event: string): void {
        const dishTypeUids = [ProductTypeEnum.Dish, ProductTypeEnum.Blank];
        const blankTypeUids = [ProductTypeEnum.Blank, ProductTypeEnum.Commodity];

        this.acceptanceSandbox.dispatchProductsSetFilter({
            term: $event,
            typeUids: this.productType === ProductTypeEnum.Dish ? dishTypeUids : this.productType === ProductTypeEnum.Blank ? blankTypeUids : null,
            isArchived: false,
            organizationPosUids: this.organizationPosUid ? [this.organizationPosUid] : null,
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    protected processSubmit() {
        if (this.isRevertTTK) {
            this.createUpdateRevertTTK();
        } else {
            this.proceedClose({
                dialogResult: DialogResult.Ok,
                text: '',
                dataFromComponent: {
                    position: this.mainForm.value,
                    mapIndex: this.mapIndex,
                    positionIndex: this.positionIndex,
                    componentName: 'position',
                },
            });
        }
    }

    private createUpdateRevertTTK(): void {
        const mapItem = this.mainForm.value;

        const currentPositionProduct: LinkedMapProductCreateUpdate = {
            productUid: mapItem?.product?.uid,
            characteristicUid: null,
            grossUnit: mapItem?.grossUnit,
            grossKilogram: mapItem?.grossKilogram,
            coldLosses: mapItem?.coldLosses,
            hotLosses: mapItem?.hotLosses,
            netKilogram: mapItem?.netKilogram,
            finishedKilogram: mapItem?.finishedKilogram,
            quantityPerPackage: mapItem?.quantityPerPackage,
        };

        const request: TechnologicalMapUpdateRequest = {
            dateFrom: mapItem.dateFrom,
            dateTo: mapItem.dateTo,
            writeOffMethod: WriteOffMethods.WritteOffProduct,
            organizationPosUid: this.organizationPosUid || this.map?.organizationPos?.uid,
            products: [currentPositionProduct],
        };

        if (this.position && this.map) {
            const products = this.map.products.map((v, index) => {
                const product: LinkedMapProductCreateUpdate = {
                    productUid: v?.product?.uid,
                    characteristicUid: null,
                    grossUnit: v?.grossUnit,
                    grossKilogram: v?.grossKilogram,
                    coldLosses: v?.coldLosses,
                    hotLosses: v?.hotLosses,
                    netKilogram: v?.netKilogram,
                    finishedKilogram: v?.finishedKilogram,
                    quantityPerPackage: v?.quantityPerPackage,
                };
                return index === this.positionIndex ? currentPositionProduct : product;
            });

            request.products = products;

            this.technologicalMapsSandbox.dispatchTechnologicalMapUpdate(this.map?.uid, request);

            return;
        }

        const createRequest = {
            ...request,
            productUid: this.productUid,
            number: mapItem.number,
            cityId: null,
            cityName: null
        };

        this.technologicalMapsSandbox.dispatchTechnologicalMapCreate(createRequest);
    }

    private createForm() {
        this.product = this.formBuilder.control(null, Validators.required);
        this.article = this.formBuilder.control(null);
        this.unit = this.formBuilder.control(null);
        this.type = this.formBuilder.control(null);
        this.grossUnit = this.formBuilder.control(null, Validators.required);
        this.grossKilogram = this.formBuilder.control(null, Validators.required);
        this.coldLosses = this.formBuilder.control(null, Validators.required);
        this.hotLosses = this.formBuilder.control(null, Validators.required);
        this.netKilogram = this.formBuilder.control(null, Validators.required);
        this.finishedKilogram = this.formBuilder.control(null, Validators.required);
        this.quantityPerPackage = this.formBuilder.control(null, Validators.required);
        this.dateFrom = this.formBuilder.control(null, this.isRevertTTK ? [Validators.required] : []);
        this.dateTo = this.formBuilder.control(null, this.isRevertTTK ? [Validators.required] : []);
        this.number = this.formBuilder.control(1, Validators.required);

        this.mainForm = this.formBuilder.group({
            product: this.product,
            article: this.article,
            unit: this.unit,
            type: this.type,
            grossUnit: this.grossUnit,
            grossKilogram: this.grossKilogram,
            coldLosses: this.coldLosses,
            hotLosses: this.hotLosses,
            netKilogram: this.netKilogram,
            finishedKilogram: this.finishedKilogram,
            quantityPerPackage: this.quantityPerPackage,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            number: this.number,
        });

        if (!this.position) {
            this.calculateValue();
        }
    }

    private successRequestCloseModal(): void {
        this.proceedClose({
            dialogResult: DialogResult.Ok,
            text: '',
            dataFromComponent: {
                position: this.mainForm.value,
                mapIndex: this.mapIndex,
                positionIndex: this.positionIndex,
                componentName: 'position',
            },
        });
    }

    private setFormData(): void {
        this.product.setValue(this.position.product);
        this.article.setValue(this.position.article);
        this.unit.setValue(this.position.unit);
        this.type.setValue(this.position.type);
        this.grossUnit.setValue(this.position.grossUnit);
        this.grossKilogram.setValue(this.position.grossKilogram);
        this.coldLosses.setValue(this.position.coldLosses);
        this.hotLosses.setValue(this.position.hotLosses);
        this.netKilogram.setValue(this.position.netKilogram);
        this.finishedKilogram.setValue(this.position.finishedKilogram);
        this.quantityPerPackage.setValue(this.position.quantityPerPackage);

        if (this.map) {
            this.dateFrom.setValue(this.map?.dateFrom);
            this.dateTo.setValue(this.map?.dateTo);
            this.number.setValue(this.map?.number);
            this.article.setValue(this.map?.product?.code);
            this.type.setValue(this.map?.product?.type);
        }
        this.calculateValue();
    }

    private calculateValue(): void {
        this.product.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe((value: ProductListItemAggregation) => {
                this.article.setValue(value?.code || '');
                this.type.setValue(value?.type?.name || '');
                this.unit.setValue(value?.unitType?.shortName || '');
            });

        this.quantityPerPackage.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(value => {
                this.finishedKilogram.setValue(value);
                this.netKilogram.setValue(value);
                this.grossKilogram.setValue(value);
                this.coldLosses.setValue(0);
                this.hotLosses.setValue(0);
            });

        merge(this.grossKilogram.valueChanges, this.coldLosses.valueChanges, this.hotLosses.valueChanges)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                const grossKilogram = this.grossKilogram.value || 0;
                const coldLosses = this.coldLosses.value || 0;
                const hotLosses = this.hotLosses.value || 0;

                const netKilogram = grossKilogram * (1 - coldLosses / 100);
                const finishedKilogramRaw = netKilogram * (1 - hotLosses / 100);
                const finishedKilogram = parseFloat(finishedKilogramRaw.toFixed(2));

                this.netKilogram.setValue(netKilogram);
                this.finishedKilogram.setValue(finishedKilogram);
            });
    }
}
