import { CompaniesListFilterData } from '../../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../../models/business/companies/company-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CompaniesSearchAction {
    static readonly type = '[CompaniesSearch Page] GetCompaniesSearch';

    constructor(public payload: CompaniesListFilterData) { }
}

export class CompaniesSearchSuccessAction {
    static readonly type = '[CompaniesSearch API] GetCompaniesSearch Success';

    constructor(public payload: PaginationResult<CompanyListItem>) { }
}

export class CompaniesSearchFailAction {
    static readonly type = '[CompaniesSearch API] GetCompaniesSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompaniesSearchResetAction {
    static readonly type = '[CompaniesSearch Page] GetCompaniesSearch Reset';
}
