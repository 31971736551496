import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProductImageDeleteAction, ProductImageDeleteFailAction, ProductImageDeleteResetAction, ProductImageDeleteSuccessAction } from './product-image-delete.actions';

export interface ProductImageDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<ProductImageDeleteStateModel>({
    name: 'productImageDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class ProductImageDeleteState {
    constructor(private catalogService: CatalogService) { }

    @Action(ProductImageDeleteAction) async productImageDelete(ctx: StateContext<ProductImageDeleteStateModel>, action: ProductImageDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.catalogService.deleteProductPhoto(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new ProductImageDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ProductImageDeleteFailAction(error)), 0));
    }

    @Action(ProductImageDeleteSuccessAction) productImageDeleteSuccess(ctx: StateContext<ProductImageDeleteStateModel>, _: ProductImageDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(ProductImageDeleteFailAction) productImageDeleteFail(ctx: StateContext<ProductImageDeleteStateModel>, action: ProductImageDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductImageDeleteResetAction) productImageDeleteReset(ctx: StateContext<ProductImageDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
