import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';
import { TechnologicalMapRestoreRequest } from '../../models/business/technological-maps/technological-map-restore-request.model';

export class TechnologicalMapRestoreAction {
    static readonly type = '[TechnologicalMaps Page] RestoreTechnologicalMap';

    constructor(public payload: TechnologicalMapRestoreRequest) { }
}

export class TechnologicalMapRestoreSuccessAction {
    static readonly type = '[TechnologicalMap API] RestoreTechnologicalMap Success';

    constructor(public payload: TechnologicalMapListItemAggregation) { }
}

export class TechnologicalMapRestoreFailAction {
    static readonly type = '[TechnologicalMap API] RestoreTechnologicalMap Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapRestoreResetAction {
    static readonly type = '[TechnologicalMaps Page] RestoreTechnologicalMap Reset';
}
