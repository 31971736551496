import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Pos } from '../../models/business/pos/pos.model';

export class CustomerPosGetAction {
    static readonly type = '[CustomerPos Page] GetPos Get';

    constructor(public payload: string) { }
}

export class CustomerPosGetSuccessAction {
    static readonly type = '[CustomerPos API] GetPos Success';

    constructor(public payload: Pos) { }
}

export class CustomerPosFailAction {
    static readonly type = '[CustomerPos API] GetPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerPosResetAction {
    static readonly type = '[CustomerPos Page] GetPos Reset';
}
