import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { CharacteristicGroup } from '../../models/business/characteristics/characteristic-group.model';
import { CharacteristicListItem } from '../../models/business/characteristics/characteristic-list-item.model';
import { CharacteristicsListGetFilter } from '../../models/filters/characteristics-list-get-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CharacteristicsService extends BaseSingletonService {

    private settings: {
        service: {
            getCharacteristicsGroups: string;
            getCharacteristics: string;
        }
    };

    constructor(private http: HttpService) {
        super('CharacteristicsService');
        this.settings = {
            service: {
                getCharacteristicsGroups: '/nomenclature/v1/characteristics/groups?isArchived={0}',
                getCharacteristics: '/nomenclature/v1/characteristics?term={0}&characteristicGroupUid={1}&isArchived={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}'
            }
        };
    }

    public async getCharacteristicsGroups(isArchived: boolean): Promise<CharacteristicGroup[]> {
        return this.http.get<{ list: CharacteristicGroup[] }>(String.format(this.settings.service.getCharacteristicsGroups, isArchived))
            .then(res => res.list);
    }

    public async getCharacteristics(filter: CharacteristicsListGetFilter): Promise<PaginationResult<CharacteristicListItem>> {
        return this.http.get<PaginationResult<CharacteristicListItem>>(String.format(this.settings.service.getCharacteristics, filter.term, filter.characteristicGroupUid, filter.isArchived, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }
}
