import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AcceptancesService } from '../../services/business/acceptances.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Acceptance } from '../../models/business/acceptances/acceptance.model';

import { AcceptanceFailAction, AcceptanceGetAction, AcceptanceGetSuccessAction, AcceptanceResetAction } from './acceptance.actions';

export interface AcceptanceStateModel {
    entity: Acceptance;
    pending: boolean;
    error: ErrorObject;
}

@State<AcceptanceStateModel>({
    name: 'acceptance',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class AcceptanceState {
    constructor(private acceptancesService: AcceptancesService) { }

    @Action(AcceptanceGetAction) acceptanceGet(ctx: StateContext<AcceptanceStateModel>, action: AcceptanceGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.acceptancesService.getAcceptance(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new AcceptanceGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AcceptanceFailAction(err)), 0));
    }

    @Action(AcceptanceGetSuccessAction) acceptanceGetSuccess(ctx: StateContext<AcceptanceStateModel>, action: AcceptanceGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(AcceptanceFailAction) acceptanceGetFail(ctx: StateContext<AcceptanceStateModel>, action: AcceptanceFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AcceptanceResetAction) acceptanceGetReset(ctx: StateContext<AcceptanceStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
