import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ExgParamsConfig } from '../../exg-params.config';

import { ContactsSearchAction, ContactsSearchFailAction, ContactsSearchResetAction, ContactsSearchSuccessAction } from './contacts-search.actions';

import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilter } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface ContactsSearchStateModel extends PaginationStateModel<ContactListItem> {
    uids: string[];
    entities: { [uid: string]: ContactListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<ContactsSearchStateModel>({
    name: 'contactsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class ContactsSearchState extends PaginationStateBase {
    constructor(private contactsService: ContactsService) {
        super();
    }

    @Action([ContactsSearchAction]) contactsGet(ctx: StateContext<ContactsSearchStateModel>, action: ContactsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContactsListFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contactsService.getContactList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ContactsSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContactsSearchFailAction(err)), 0));
    }

    @Action(ContactsSearchSuccessAction) contactsGetSuccess(ctx: StateContext<ContactsSearchStateModel>, action: ContactsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ContactsSearchFailAction) contactsGetFail(ctx: StateContext<ContactsSearchStateModel>, action: ContactsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContactsSearchResetAction) contactsGetReset(ctx: StateContext<ContactsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
