import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { MotivationsService } from '../../services/business/motivations.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { MotivationBonusesCompaniesAction, MotivationBonusesCompaniesFailAction, MotivationBonusesCompaniesResetAction, MotivationBonusesCompaniesSetFilterAction, MotivationBonusesCompaniesSuccessAction } from './motivation-bonuses-companies.actions';

import { CompaniesMotivationBonus } from '../../models/business/contacts/companies-motivation-bonus.model';
import { CompaniesMotivationBonusesListGetFilter, CompaniesMotivationBonusesListGetFilterData } from '../../models/filters/companies-motivation-bonuses-list-get-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface MotivationBonusesCompaniesStateModel extends PaginationStateModel<CompaniesMotivationBonus> {
    uids: string[];
    entities: { [uid: string]: CompaniesMotivationBonus };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: CompaniesMotivationBonusesListGetFilterData;
    total: number;
}

@State<MotivationBonusesCompaniesStateModel>({
    name: 'motivationBonusesCompanies',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class MotivationBonusesCompaniesState extends PaginationStateBase {
    constructor(private motivationsService: MotivationsService) {
        super();
    }

    @Action([MotivationBonusesCompaniesAction]) contactsGet(ctx: StateContext<MotivationBonusesCompaniesStateModel>, action: MotivationBonusesCompaniesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CompaniesMotivationBonusesListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.motivationsService.getMotivationCompanies(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new MotivationBonusesCompaniesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusesCompaniesFailAction(err)), 0));
    }

    @Action(MotivationBonusesCompaniesSuccessAction) contactsGetSuccess(ctx: StateContext<MotivationBonusesCompaniesStateModel>, action: MotivationBonusesCompaniesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(MotivationBonusesCompaniesFailAction) contactsGetFail(ctx: StateContext<MotivationBonusesCompaniesStateModel>, action: MotivationBonusesCompaniesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(MotivationBonusesCompaniesResetAction) contactsGetReset(ctx: StateContext<MotivationBonusesCompaniesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(MotivationBonusesCompaniesSetFilterAction) usersSetFilter(ctx: StateContext<MotivationBonusesCompaniesStateModel>, action: MotivationBonusesCompaniesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new MotivationBonusesCompaniesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
