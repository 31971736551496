import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { ContragentCreateSuccessAction } from './contragent-create.actions';
import { ContragentDeleteSuccessAction } from './contragent-delete.actions';
import { ContragentUpdateSuccessAction } from './contragent-update.actions';
import { ContragentsAction, ContragentsFailAction, ContragentsResetAction, ContragentsSetFilterAction, ContragentsSuccessAction } from './contragents.actions';

import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ContragentStatus } from '../../models/business/contragents/contragent-status.enum';
import { ContragentsListFilter, ContragentsListFilterData } from '../../models/filters/contragents-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface ContragentsStateModel extends PaginationStateModel<ContragentListItem> {
    uids: string[];
    entities: { [uid: string]: ContragentListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ContragentsListFilterData;
    total: number;
}

@State<ContragentsStateModel>({
    name: 'contragents',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {
            statuses: [ContragentStatus.Active, ContragentStatus.Liquidating, ContragentStatus.Reorganizing, ContragentStatus.Unknown]
        }
    }
})
@Injectable()
export class ContragentsState extends PaginationStateBase {
    constructor(private contragentsService: ContragentsService) {
        super();
    }

    @Action([ContragentsAction]) contragentsGet(ctx: StateContext<ContragentsStateModel>, action: ContragentsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContragentsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contragentsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contragentsService.getContragentList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ContragentsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContragentsFailAction(err)), 0));
    }

    @Action(ContragentsSuccessAction) contragentsGetSuccess(ctx: StateContext<ContragentsStateModel>, action: ContragentsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contragentsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(ContragentsFailAction) contragentsGetFail(ctx: StateContext<ContragentsStateModel>, action: ContragentsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContragentsResetAction) contragentsGetReset(ctx: StateContext<ContragentsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ContragentsSetFilterAction) usersSetFilter(ctx: StateContext<ContragentsStateModel>, action: ContragentsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ContragentsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(ContragentCreateSuccessAction) contragentCreateSuccess(ctx: StateContext<ContragentsStateModel>, _: ContragentCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new ContragentsAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(ContragentUpdateSuccessAction) contragentUpdateSuccess(ctx: StateContext<ContragentsStateModel>, action: ContragentUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(ContragentDeleteSuccessAction) contragentDeleteSuccess(ctx: StateContext<ContragentsStateModel>, action: ContragentDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
