import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TasksService } from '../../services/business/tasks.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TaskCreateAction, TaskCreateFailAction, TaskCreateResetAction, TaskCreateSuccessAction } from './task-create.actions';
import { Task } from '../../models/business/tasks/task.model';

export interface TaskCreateStateModel {
    pending: boolean;
    created: Task;
    error: ErrorObject;
}

@State<TaskCreateStateModel>({
    name: 'taskCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TaskCreateState {
    constructor(private tasksService: TasksService) { }

    @Action(TaskCreateAction) async taskCreate(ctx: StateContext<TaskCreateStateModel>, action: TaskCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.tasksService.createTask({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new TaskCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TaskCreateFailAction(err)), 0));
    }

    @Action(TaskCreateSuccessAction) taskCreateSuccess(ctx: StateContext<TaskCreateStateModel>, action: TaskCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TaskCreateFailAction) taskCreateFail(ctx: StateContext<TaskCreateStateModel>, action: TaskCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(TaskCreateResetAction) taskCreateReset(ctx: StateContext<TaskCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
