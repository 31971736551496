import { CustomerCreateOrUpdateRequest } from '../../models/business/customers/customer-create-or-update.model';
import { Customer } from '../../models/business/customers/customer.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CustomerCreateAction {
    static readonly type = '[Customers Page] CreateCustomer';

    constructor(public payload: CustomerCreateOrUpdateRequest) { }
}

export class CustomerCreateSuccessAction {
    static readonly type = '[Customer API] CreateCustomer Success';

    constructor(public payload: Customer) { }
}

export class CustomerCreateFailAction {
    static readonly type = '[Customer API] CreateCustomer Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerCreateResetAction {
    static readonly type = '[Customers Page] CreateCustomer Reset';
}
