import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { ProductsService } from '../../services/business/products.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilter, ProductsListGetFilterData } from '../../models/filters/products-list-get-filter.model';

import { ProductsSearchAction, ProductsSearchFailAction, ProductsSearchResetAction, ProductsSearchSetFilterAction, ProductsSearchSuccessAction } from './products-search.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface ProductsSearchStateModel extends PaginationFilteredStateModel<ProductListItemAggregation, ProductsListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: ProductListItemAggregation };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ProductsListGetFilterData;
    total: number;
}

@State<ProductsSearchStateModel>({
    name: 'productsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { pageSize: null } }
})
@Injectable()
export class ProductsSearchState extends PaginationFilteredStateBase {
    constructor(private productsService: ProductsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([ProductsSearchAction]) productsSearchGet(ctx: StateContext<ProductsSearchStateModel>, action: ProductsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'ProductsSearch');
        ctx.setState({ ...state, filter: filterData });

        const filter = new ProductsListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.productsService.getProductList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ProductsSearchSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductsSearchFailAction(err)), 0));
    }

    @Action(ProductsSearchSuccessAction) ProductsSearchGetSuccess(ctx: StateContext<ProductsSearchStateModel>, action: ProductsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ProductsSearchFailAction) ProductsSearchGetFail(ctx: StateContext<ProductsSearchStateModel>, action: ProductsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductsSearchResetAction) ProductsSearchGetReset(ctx: StateContext<ProductsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ProductsSearchSetFilterAction) ProductsSearchSetFilter(ctx: StateContext<ProductsSearchStateModel>, action: ProductsSearchSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'ProductsSearch');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ProductsSearchAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
