import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IntervalsService } from '../../services/business/intervals.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetFilter, IntervalGetFilterData } from '../../models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../models/business/intervals/interval-list-item.model';

import { IntervalUpdateSuccessAction } from './interval-update.actions';
import { IntervalsFailAction, IntervalsGetAction, IntervalsGetSuccessAction, IntervalsResetAction, IntervalsUpdateFromSocketAction } from './intervals.actions';

import { DateUtils } from '../../../../burns-ui-framework/shared/utils/date-utils';
import { Utils } from '../../../../burns-ui-framework/shared/utils/utils';

export interface IntervalsStateModel {
    uids: number[];
    entities: { [uid: number]: IntervalListItem };
    pending: boolean;
    error: ErrorObject;
    filter: IntervalGetFilterData;
}

@State<IntervalsStateModel>({
    name: 'intervals',
    defaults: { pending: false, uids: [], entities: {}, error: null, filter: null }
})
@Injectable()
export class IntervalsState {

    constructor(private intervalsService: IntervalsService) { }

    @Action(IntervalsGetAction) intervalGet(ctx: StateContext<IntervalsStateModel>, action: IntervalsGetAction) {

        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        ctx.setState({ ...state, pending: true, error: null, filter: filterData });

        const filter = new IntervalGetFilter({ pageIndex: 0, pageSize: 0, filterData });

        return this.intervalsService.getIntervals(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new IntervalsGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new IntervalsFailAction(err)), 0));
    }

    @Action(IntervalsGetSuccessAction) intervalsGetSuccess(ctx: StateContext<IntervalsStateModel>, action: IntervalsGetSuccessAction) {
        const state = ctx.getState();
        const uids = action.payload.map(p => p.dateFrom);
        const entities = action.payload.reduce((items: { [uid: string]: IntervalListItem }, item: IntervalListItem) => ({ ...items, [item.dateFrom]: item }), {});
        ctx.setState({ ...state, pending: false, uids, entities, error: null });
    }

    @Action(IntervalsFailAction) intervalsGetFail(ctx: StateContext<IntervalsStateModel>, action: IntervalsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(IntervalsResetAction) intervalsGetReset(ctx: StateContext<IntervalsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, error: null });
    }

    @Action(IntervalUpdateSuccessAction) intervalsUpdateSuccess(ctx: StateContext<IntervalsStateModel>, action: IntervalUpdateSuccessAction) {
        const state = ctx.getState();

        const { uids, entities } = this.getNewState(state, action.payload);
        ctx.setState({ ...state, uids, entities });
    }

    @Action(IntervalsUpdateFromSocketAction) intervalsUpdateFromSocketAction(ctx: StateContext<IntervalsStateModel>, action: IntervalsUpdateFromSocketAction) {
        const state = ctx.getState();

        if (!state.filter || !state.filter.organizationPosUid) {
            return;
        }

        const newList = action.payload.filter(x => x.organizationPosUid === state.filter.organizationPosUid && state.uids.some(u => u === x.dateFrom));
        if (newList.length > 0) {
            const { uids, entities } = this.getNewState(state, newList);
            ctx.setState({ ...state, uids, entities });
        }
    }

    public getNewState(state, list): { uids: number[], entities: {} } {
        const newIds = list.map(p => p.dateFrom);
        const uids = Utils.arrayUnique<number>(state.uids.concat(newIds));
        const entitiesList = uids.map(uid => list.find(ent => ent.dateFrom === uid) || state.entities[uid]);
        const entities = entitiesList.reduce((items: { [uid: string]: IntervalListItem }, item: IntervalListItem) => ({ ...items, [item.dateFrom]: item }), {});

        return { uids, entities, };
    }
}
