import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ClientsStatisticGetFilter } from '../../models/filters/clients-statistic-get-filter.model';
import { CompaniesListFilter } from '../../models/filters/companies-list-filter.model';
import { CompaniesStatisticLite } from '../../models/business/companies/companies-statistic-lite.model';
import { CompanyCreateOrUpdateRequest } from '../../models/business/companies/company-create-or-update.model';
import { CompanyListItem } from '../../models/business/companies/company-list-item.model';
import { Company } from '../../models/business/companies/company.model';
import { FeedListGetFilter } from '../../models/filters/feed-list-get-filter.model';
import { Feed } from '../../models/business/feed/feed.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService extends BaseSingletonService {

    private settings: {
        service: {
            getCompany: string;
            getCompanyList: string;
            getStatistic: string;
            createCompany: string;
            updateCompany: string;
            deleteCompany: string;

            getCompanyFeed: string;
        }
    };

    constructor(private http: HttpService) {
        super('CompaniesService');
        this.settings = {
            service: {
                getCompany: '/crm/v1/companies/{0}',
                getCompanyList: '/crm/v1/companies?term={0}&statuses={1}&types={2}&responsibleUsersUids={3}&citiesIds={4}&userUid={5}&sortField={6}&sortOrder={7}&pageIndex={8}&pageSize={9}',
                getStatistic: '/crm/v1/companies/statistic?dateFrom={0}&dateTo={1}&groupBy={2}&period={3}&countryCodes={4}&citiesIds={5}&responsibleUsersUids={6}',
                createCompany: '/crm/v1/companies',
                updateCompany: '/crm/v1/companies/{0}',
                deleteCompany: '/crm/v1/companies/{0}',

                getCompanyFeed: '/crm/v1/companies/{0}/feed?term={1}&userUid={2}&types={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}'
            }
        };
    }

    public async getCompany(uid: string): Promise<Company> {
        return this.http.get<{ data: Company }>(String.format(this.settings.service.getCompany, uid))
            .then(res => res.data);
    }

    public async getCompanyList(filter: CompaniesListFilter): Promise<PaginationResult<CompanyListItem>> {
        const statuses = (filter.statuses || []).join(',');
        const types = (filter.types || []).join(',');
        const responsibleUsersUids = (filter.responsibleUsersUids || []).join(',');
        const citiesIds = (filter.citiesIds || []).join(',');
        return this.http.get<PaginationResult<CompanyListItem>>(String.format(this.settings.service.getCompanyList, filter.term, statuses, types, responsibleUsersUids, citiesIds, filter.userUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getStatistic(filter: ClientsStatisticGetFilter): Promise<CompaniesStatisticLite> {
        const countryCodes = (filter.countryCodes || []).join(',');
        const citiesIds = (filter.citiesIds || []).join(',');
        const responsibleUsersUids = (filter.responsibleUsersUids || []).join(',');
        return this.http.get<{ data: CompaniesStatisticLite }>(String.format(this.settings.service.getStatistic, filter.dateFrom, filter.dateTo, filter.groupBy, filter.period, countryCodes, citiesIds, responsibleUsersUids))
            .then(res => res.data);
    }

    public async createCompany(request: CompanyCreateOrUpdateRequest): Promise<Company> {
        return this.http.post<{ data: Company }>(String.format(this.settings.service.createCompany), request)
            .then(res => res.data);
    }

    public async updateCompany(uid: string, request: CompanyCreateOrUpdateRequest): Promise<Company> {
        return this.http.put(String.format(this.settings.service.updateCompany, uid), request);
    }

    public async deleteCompany(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteCompany, uid));
    }

    public async getCompanyFeed(filter: FeedListGetFilter): Promise<PaginationResult<Feed>> {
        const types = (filter.types || []).join(',');
        return this.http.get<PaginationResult<Feed>>(String.format(this.settings.service.getCompanyFeed, filter.companyUid, filter.term, filter.userUid, types, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }
}
