import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FranchiseeCreateOrUpdateRequest } from '../../models/business/franchisees/franchisee-create-or-update.model';
import { Franchisee } from '../../models/business/franchisees/franchisee.model';

export class FranchiseeCreateAction {
    static readonly type = '[Franchisees Page] CreateFranchisee';

    constructor(public payload: { request: FranchiseeCreateOrUpdateRequest }) { }
}

export class FranchiseeCreateSuccessAction {
    static readonly type = '[Franchisee API] CreateFranchisee Success';

    constructor(public payload: Franchisee) { }
}

export class FranchiseeCreateFailAction {
    static readonly type = '[Franchisee API] CreateFranchisee Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeCreateResetAction {
    static readonly type = '[Franchisees Page] CreateFranchisee Reset';
}
