export enum DcrStatus {
    /// <summary>
    /// Draft
    /// </summary>
    Draft = 1,

    /// <summary>
    /// Applied
    /// </summary>
    Applied = 10
}
