import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { IntervalsGetAction, IntervalsResetAction, IntervalsUpdateFromSocketAction } from './intervals.actions';

import { IntervalGetFilterData } from '../../models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../models/business/intervals/interval-list-item.model';

@Injectable({
    providedIn: 'root'
})
export class IntervalsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('IntervalsDispatchers');
    }

    public dispatchIntervalsAction(filterData?: IntervalGetFilterData) {
        this.store.dispatch(new IntervalsGetAction(filterData));
    }

    public dispatchIntervalsUpdateFromSocketAction(list: IntervalListItem[]) {
        this.store.dispatch(new IntervalsUpdateFromSocketAction(list));
    }

    public dispatchIntervalsResetAction() {
        this.store.dispatch(new IntervalsResetAction());
    }
}
