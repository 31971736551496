<ng-container [ngSwitch]="geocodingType">
    <exg-address-autocomplete *ngSwitchCase="geocodingTypeEnum.FiasMapbox"
                              class="form-control column-1"
                              [value]="value"
                              [maxlength]="maxlength"
                              [placeholder]="placeholder"
                              [validate]="validate"
                              [controlsToValidate]="controlsToValidate"
                              [countryCode]="countryCode"
                              [readonly]="readonly"
                              [styleType]="styleType"
                              [allowNull]="allowNull"
                              [emptyDisplayValue]="emptyDisplayValue"
                              [parentPlaceId]="parentPlaceId"
                              [level]="level"
                              [fullNameLevelFrom]="fullNameLevelFrom"
                              [useHierarchy]="useHierarchy"
                              (selectionChange)="onFiasSelectionChange($event)"></exg-address-autocomplete>

    <ye-yandex-geocoding *ngSwitchCase="geocodingTypeEnum.Yandex"
                         [value]="value"
                         [maxlength]="maxlength"
                         [placeholder]="placeholder"
                         [validate]="validate"
                         [controlsToValidate]="controlsToValidate"
                         [countryCode]="countryCode"
                         [readonly]="readonly"
                         [styleType]="styleType"
                         [allowNull]="allowNull"
                         [displayKey]="displayKey"
                         [emptyDisplayValue]="emptyDisplayValue"
                         [parentPlaceId]="parentPlaceId"
                         [level]="level | exgYandexComponentInputType"
                         [fullNameLevelFrom]="fullNameLevelFrom | exgYandexComponentInputType"
                         [lowerCornerLatitude]="lowerCornerLatitude"
                         [lowerCornerLongitude]="lowerCornerLongitude"
                         [upperCornerLatitude]="upperCornerLatitude"
                         [upperCornerLongitude]="upperCornerLongitude"
                         (selectionChange)="onYandexSelectionChange($event)"></ye-yandex-geocoding>
</ng-container>