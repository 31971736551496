import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseReturnCreateOrUpdateRequest } from '../../models/business/purchase-returns/purchase-return-create-or-update-request.model';
import { PurchaseReturn } from '../../models/business/purchase-returns/purchase-return.model';

export class PurchaseReturnCreateAction {
    static readonly type = '[PurchaseReturns Page] CreatePurchaseReturn';

    constructor(public payload: PurchaseReturnCreateOrUpdateRequest) { }
}

export class PurchaseReturnCreateSuccessAction {
    static readonly type = '[PurchaseReturn API] CreatePurchaseReturn Success';

    constructor(public payload: PurchaseReturn) { }
}

export class PurchaseReturnCreateFailAction {
    static readonly type = '[PurchaseReturn API] CreatePurchaseReturn Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseReturnCreateResetAction {
    static readonly type = '[PurchaseReturns Page] CreatePurchaseReturn Reset';
}
