import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PostingDeleteAction {
    static readonly type = '[My Posting Page] DeletePosting';

    constructor(public payload: { uid: string }) { }
}

export class PostingDeleteSuccessAction {
    static readonly type = '[My Postings API] DeletePosting Success';

    constructor(public payload: { uid: string }) { }
}

export class PostingDeleteFailAction {
    static readonly type = '[My Postings API] DeletePosting Fail';

    constructor(public payload: ErrorObject) { }
}

export class PostingDeleteResetAction {
    static readonly type = '[My Posting Page] DeletePosting Reset';
}
