import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { ExgFormComponent } from '../../../../../../burns-ui-framework/shared/components/abstract/exg-form.component';

import { DocumentModel } from '../../../../models/business/feed/document.model';
import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Feed } from '../../../../models/business/feed/feed.model';
import { NoteCreateRequest } from '../../../../models/business/notes/note-create-request.model';
import { NoteUpdateRequest } from '../../../../models/business/notes/note-update-request.model';
import { Note } from '../../../../models/business/notes/note.model';

@Component({
    selector: 'bvc-clients-activity-form',
    templateUrl: './clients-activity-form.component.html',
    styleUrls: ['./clients-activity-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityFormComponent extends ExgFormComponent implements OnChanges {
    @Input() note: Feed;
    @Input() error: ErrorObject;
    @Input() companyUid: string;
    @Input() created: Note;

    @Output() readonly noteCreate = new EventEmitter<{ request: NoteCreateRequest, documentsToAdd: File[] }>();
    @Output() readonly noteUpdate = new EventEmitter<{ uid: string, request: NoteUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }>();
    @Output() readonly cancelClick = new EventEmitter<void>();

    public text: FormControl;

    public noteFiles$ = new BehaviorSubject<DocumentModel[]>([]);
    public filesToAdd = [];

    constructor(private formBuilder: FormBuilder) {
        super();
        this.createForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.note && this.note) {
            this.setFormData();
        }

        if (changes.created && this.created) {
            this.onCancelClick();
        }
    }

    public onCancelClick() {
        if (this.note && this.note.uid) {
            this.setFormData();
        } else {
            this.text.patchValue(null);
            this.filesToAdd = [];
        }

        this.cancelClick.emit();
    }

    public onUploadedFiles($event) {
        this.filesToAdd.push(...$event);
    }

    public onDeleteDocument($event: string) {
        this.noteFiles$.next(this.noteFiles$.value.filter(x => x.uid !== $event));
    }

    public onDeleteDocumentDownloadable($event: number) {
        this.filesToAdd.splice($event, 1);
    }

    public trackByDocuments(_, item: DocumentModel) {
        return item.uid;
    }

    protected processSubmit() {
        const documentsToAdd = this.filesToAdd;

        if (this.note && this.note.uid) {
            const request = {
                text: this.text.value
            };

            const newNoteDocuments = this.noteFiles$.value;
            const documentsToRemove = this.note.documents.filter(x => !newNoteDocuments.some(d => d.uid === x.uid)).map(x => x.uid);

            this.noteUpdate.emit({ uid: this.note.uid, request, documentsToAdd, documentsToRemove });
        } else {
            const request = {
                text: this.text.value,
                companyUid: this.companyUid
            };

            this.noteCreate.emit({ request, documentsToAdd });
        }
    }

    private createForm() {
        this.text = this.formBuilder.control(null, [Validators.required, Validators.maxLength(3000)]);
        this.mainForm = this.formBuilder.group({
            text: this.text
        });
    }

    private setFormData() {
        this.text.patchValue(this.note.text);
        this.noteFiles$.next(this.note.documents);
    }
}
