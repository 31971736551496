import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransfersService } from '../../services/business/transfers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Transfer } from '../../models/business/transfers/transfer.model';

import { TransferFailAction, TransferGetAction, TransferGetSuccessAction, TransferResetAction } from './transfer.actions';

export interface TransferStateModel {
    entity: Transfer;
    pending: boolean;
    error: ErrorObject;
}

@State<TransferStateModel>({
    name: 'transfer',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class TransferState {
    constructor(private transfersService: TransfersService) { }

    @Action(TransferGetAction) transferGet(ctx: StateContext<TransferStateModel>, action: TransferGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.transfersService.getTransfer(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new TransferGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransferFailAction(err)), 0));
    }

    @Action(TransferGetSuccessAction) transferGetSuccess(ctx: StateContext<TransferStateModel>, action: TransferGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(TransferFailAction) transferGetFail(ctx: StateContext<TransferStateModel>, action: TransferFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransferResetAction) transferGetReset(ctx: StateContext<TransferStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
