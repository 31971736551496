import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DecompositionsService } from '../../services/decompositions.service';

import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DecompositionUpdateAction, DecompositionUpdateFailAction, DecompositionUpdateResetAction, DecompositionUpdateSuccessAction } from './decomposition-update.actions';

export interface DecompositionUpdateStateModel {
    pending: boolean;
    updated: DCRCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<DecompositionUpdateStateModel>({
    name: 'decompositionUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class DecompositionUpdateState {
    constructor(private decompositionsService: DecompositionsService) { }

    @Action(DecompositionUpdateAction) async decompositionUpdate(ctx: StateContext<DecompositionUpdateStateModel>, action: DecompositionUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.decompositionsService.updateDecomposition(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DecompositionUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DecompositionUpdateFailAction(err)), 0));
    }

    @Action(DecompositionUpdateSuccessAction) decompositionUpdateSuccess(ctx: StateContext<DecompositionUpdateStateModel>, action: DecompositionUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(DecompositionUpdateFailAction) decompositionUpdateFail(ctx: StateContext<DecompositionUpdateStateModel>, action: DecompositionUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(DecompositionUpdateResetAction) decompositionUpdateReset(ctx: StateContext<DecompositionUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
