import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PostingsService } from '../../services/business/postings.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Posting } from '../../models/business/postings/posting.model';

import { PostingFailAction, PostingGetAction, PostingGetSuccessAction, PostingResetAction } from './posting.actions';

export interface PostingStateModel {
    entity: Posting;
    pending: boolean;
    error: ErrorObject;
}

@State<PostingStateModel>({
    name: 'posting',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PostingState {
    constructor(private postingsService: PostingsService) { }

    @Action(PostingGetAction) postingGet(ctx: StateContext<PostingStateModel>, action: PostingGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.postingsService.getPosting(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PostingGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PostingFailAction(err)), 0));
    }

    @Action(PostingGetSuccessAction) postingGetSuccess(ctx: StateContext<PostingStateModel>, action: PostingGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PostingFailAction) postingGetFail(ctx: StateContext<PostingStateModel>, action: PostingFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PostingResetAction) postingGetReset(ctx: StateContext<PostingStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
