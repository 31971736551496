import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { CharacteristicListItem } from '../../models/business/characteristics/characteristic-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CharacteristicsSearchState, CharacteristicsSearchStateModel } from './characteristics-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class CharacteristicsSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([CharacteristicsSearchState], (state: CharacteristicsSearchStateModel) => state.pending);

    static readonly getCharacteristicsSearch = createSelector([CharacteristicsSearchState], (state: CharacteristicsSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([CharacteristicsSearchState], (state: CharacteristicsSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([CharacteristicsSearchState], (state: CharacteristicsSearchStateModel) => state.error);

    static readonly getAllCharacteristicsSearchLoaded = createSelector([CharacteristicsSearchState], (state: CharacteristicsSearchStateModel) => state.allItemsLoaded);

    @Select(CharacteristicsSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(CharacteristicsSearchSelectors.getCharacteristicsSearch) characteristics$: Observable<CharacteristicListItem[]>;

    @Select(CharacteristicsSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(CharacteristicsSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(CharacteristicsSearchSelectors.getAllCharacteristicsSearchLoaded) allCharacteristicsSearchLoaded$: Observable<boolean>;

    constructor() {
        super('CharacteristicsSearchSelectors');
    }
}
