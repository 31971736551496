import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProductDeleteAction, ProductDeleteFailAction, ProductDeleteResetAction, ProductDeleteSuccessAction } from './product-delete.actions';

export interface ProductDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<ProductDeleteStateModel>({
    name: 'productDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class ProductDeleteState {
    constructor(private catalogService: CatalogService) { }

    @Action(ProductDeleteAction) async productDelete(ctx: StateContext<ProductDeleteStateModel>, action: ProductDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.catalogService.deleteProduct(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new ProductDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ProductDeleteFailAction(error)), 0));
    }

    @Action(ProductDeleteSuccessAction) productDeleteSuccess(ctx: StateContext<ProductDeleteStateModel>, _: ProductDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(ProductDeleteFailAction) productDeleteFail(ctx: StateContext<ProductDeleteStateModel>, action: ProductDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductDeleteResetAction) productDeleteReset(ctx: StateContext<ProductDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
