import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ManagerAssistantsService } from '../../services/business/manager-assistants.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ManagerAssistant } from '../../models/business/manager-assistants/manager-assistant.model';

import { ManagerAssistantCreateAction, ManagerAssistantCreateFailAction, ManagerAssistantCreateResetAction, ManagerAssistantCreateSuccessAction } from './manager-assistant-create.actions';

export interface ManagerAssistantsCreateStateModel {
    pending: boolean;
    created: ManagerAssistant;
    error: ErrorObject;
}

@State<ManagerAssistantsCreateStateModel>({
    name: 'managerAssistantsCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class ManagerAssistantsCreateState {
    constructor(private managerAssistantsService: ManagerAssistantsService) { }

    @Action(ManagerAssistantCreateAction) async managerAssistantsCreate(ctx: StateContext<ManagerAssistantsCreateStateModel>, action: ManagerAssistantCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.managerAssistantsService.createAssistant(action.payload)
            .then(res => setTimeout(() => ctx.dispatch(new ManagerAssistantCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ManagerAssistantCreateFailAction(err)), 0));
    }

    @Action(ManagerAssistantCreateSuccessAction) managerAssistantsCreateSuccess(ctx: StateContext<ManagerAssistantsCreateStateModel>, action: ManagerAssistantCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ManagerAssistantCreateFailAction) managerAssistantsCreateFail(ctx: StateContext<ManagerAssistantsCreateStateModel>, action: ManagerAssistantCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ManagerAssistantCreateResetAction) managerAssistantsCreateReset(ctx: StateContext<ManagerAssistantsCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
