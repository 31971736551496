import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosSettingsUpdateRequest } from '../../models/business/intervals/organization-pos-settings-update-request.model';

export class TimeIntervalsUpdateAction {
    static readonly type = '[TimeIntervals Page] UpdateTimeIntervals';

    constructor(public payload: { request: OrganizationPosSettingsUpdateRequest }) { }
}

export class TimeIntervalsUpdateSuccessAction {
    static readonly type = '[TimeIntervals API] UpdateTimeIntervals Success';

    constructor(public payload: { request: OrganizationPosSettingsUpdateRequest }) { }
}

export class TimeIntervalsUpdateFailAction {
    static readonly type = '[TimeIntervals API] UpdateTimeIntervals Fail';

    constructor(public payload: ErrorObject) { }
}

export class TimeIntervalsUpdateResetAction {
    static readonly type = '[TimeIntervals Page] UpdateTimeIntervals Reset';
}

