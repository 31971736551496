import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PostingCreateOrUpdateRequest } from '../../models/business/postings/posting-create-or-update-request.model';

export class PostingUpdateAction {
    static readonly type = '[Booking Page] UpdatePosting';

    constructor(public payload: { uid: string, request: PostingCreateOrUpdateRequest }) { }
}

export class PostingUpdateSuccessAction {
    static readonly type = '[Posting API] UpdatePosting Success';

    constructor(public payload: { uid: string, request: PostingCreateOrUpdateRequest }) { }
}

export class PostingUpdateFailAction {
    static readonly type = '[Posting API] UpdatePosting Fail';

    constructor(public payload: ErrorObject) { }
}

export class PostingUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePosting Reset';
}
