import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DocumentModel } from '../../../../models/business/feed/document.model';
import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Feed } from '../../../../models/business/feed/feed.model';
import { TaskStatus } from '../../../../models/business/tasks/task-status.enum';

import { ExgParamsConfig } from '../../../../exg-params.config';

@Component({
    selector: 'bvc-clients-activity-task-meeting',
    templateUrl: './clients-activity-task-meeting.component.html',
    styleUrls: ['./clients-activity-task-meeting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityTaskMeetingComponent {

    @Input() feedItem: Feed;
    @Input() error: ErrorObject;

    public emptyAvatar = ExgParamsConfig.logos.emptyUserAvatar;
    public taskStatusEnum = TaskStatus;

    public trackByDocuments(_, item: DocumentModel) {
        return item.uid;
    }
}
