import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TimeIntervalsUpdateAction, TimeIntervalsUpdateResetAction } from './time-intervals-update.actions';

import { OrganizationPosSettingsUpdateRequest } from '../../models/business/intervals/organization-pos-settings-update-request.model';

@Injectable({
    providedIn: 'root'
})
export class TimeIntervalsUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TimeIntervalsUpdateDispatchers');
    }

    public dispatchTimeIntervalsUpdateAction(request: OrganizationPosSettingsUpdateRequest) {
        this.store.dispatch(new TimeIntervalsUpdateAction({ request }));
    }

    public dispatchTimeIntervalsUpdateResetAction() {
        this.store.dispatch(new TimeIntervalsUpdateResetAction());
    }
}
