import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

import { OrganizationTreeListGetFilter } from '../../models/filters/organization-tree-list-get-filter.model';
import { OrganizationTree } from '../../models/business/organization-tree/organization-tree.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTreeService extends BaseSingletonService {

    private settings: {
        service: {
            getTree: string;
        }
    };

    constructor(private http: HttpService) {
        super('OrganizationTreeService');
        this.settings = {
            service: {
                getTree: '/corporation/v1/tree?organizationUid={0}&term={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
            }
        };
    }

    public async getTree(filter: OrganizationTreeListGetFilter): Promise<OrganizationTree> {
        return this.http.get(String.format(this.settings.service.getTree, filter.organizationUid, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
        .then((res: {data: OrganizationTree }) => {
            return res.data;
        });
    }

}
