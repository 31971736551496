import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';
import { LanguageService } from '../../../../burns-ui-framework/shared/services/common/language-service.service';

import { ExgCultureEnum } from '../../../../burns-ui-framework/shared/models/common/exg-culture.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { UserChangePasswordRequest } from '../../models/business/user/user-change-password-request.model';
import { UserFilter } from '../../models/filters/user-filter.model';
import { UserForTyping } from '../../models/business/user/user-for-typing.model';
import { UserGroup, UserGroupUpdateRequest } from '../../models/business/user/user-group.model';
import { UserRegistrationRequest } from '../../models/business/user/user-registration-request.model';
import { UpdateUserForVerificationRequest, UserVerificationDetails } from '../../models/business/user/user-verification-details.model';
import { User, UserListItem, UserPincodeUpdateRequest, UserTypeDataGuard, UserUpdateEmail, UserUpdateSettings } from '../../models/business/user/user.model';
import { UsersForTypingFilter } from '../../models/filters/users-for-typing.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseSingletonService {

    private settings: {
        service: {
            getUser: string;
            getUserSettings: string;
            getUsers: string;
            getUsersForTyping: string;
            getUserGroups: string;
            registerUser: string;
            updateUser: string;
            updateUserPassword: string;
            updateUserGroup: string;
            updateUserEmail: string;
            updateUserSetting: string;
            deleteUserAvatar: string;
            updateUserPinCode: string;
            deleteUser: string,
            getVerificationDetails: string;
            updateVerificationDetails: string;
            getIdentificationDocuments: string;
            updateIdentificationDocuments: string;
            deleteIdentificationDocuments: string;
        }
    };

    constructor(private http: HttpService, private languageService: LanguageService) {
        super('UsersService');
        this.settings = {
            service: {
                getUser: '/corporation/v1/users/{0}',
                getUserSettings: '/crm/v1/users/{0}/settings',
                getUsers: '/corporation/v1/users?term={0}&termField={1}&culture={2}&startDate={3}&endDate={4}&pageIndex={5}&pageSize={6}&sortField={7}&sortOrder={8}&groups={9}&isFired={10}',
                getUsersForTyping: '/crm/v1/users/linked-managers?term={0}&includeCurrentUserInSelection={1}&pageIndex={2}&pageSize={3}&sortField={4}&sortOrder={5}',
                getUserGroups: '/identity/v1/groups?userUid={0}',
                registerUser : '/corporation/v1/users',
                updateUser: '/corporation/v1/users/{0}',
                updateUserGroup: '/identity/v1/users/{0}/groups',
                updateUserEmail: '/identity/v1/users/{0}/email',
                updateUserSetting: '/crm/v1/users/{0}/settings',
                updateUserPinCode: '/identity/v1/users/{0}/pincode',
                updateUserPassword: '/identity/v1/users/{0}/password',
                deleteUserAvatar: '/identity/v1/users/{0}/avatar',
                deleteUser: '/corporation/v1/users/{0}',

                getVerificationDetails: '/identity/v1/users/{0}/verification-details',
                updateVerificationDetails: '/identity/v1/users/{0}/verification-details',
                getIdentificationDocuments: '/identity/v1/users/{0}/identification-documents',
                updateIdentificationDocuments: '/identity/v1/users/{0}/identification-documents',
                deleteIdentificationDocuments: '/identity/v1/users/{0}/identification-documents/{1}'
            }
        };
    }

    /**
     * Get users list
     * @param filter search pagination filter
     */
    public async getUsers(filter: UserFilter): Promise<PaginationResult<UserListItem>> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<PaginationResult<UserListItem>>(String.format(this.settings.service.getUsers, filter.term, filter.termField, culture, filter.startDate, filter.endDate, filter.pageIndex, filter.pageSize, filter.sortField, filter.sortOrder, filter.groups[0], filter.isFired))
            .then((res) => {
                res.pageIndex = filter.pageIndex;
                res.pageSize = filter.pageSize;
                return res;
            });
    }

    public async getUsersForTyping(filter: UsersForTypingFilter): Promise<PaginationResult<UserForTyping>> {
        return this.http.get<PaginationResult<UserForTyping>>(String.format(this.settings.service.getUsersForTyping, filter.term, filter.includeCurrentUserInSelection || true, filter.pageIndex, filter.pageSize, filter.sortField, filter.sortOrder))
            .then((res) => {
                res.pageIndex = filter.pageIndex;
                res.pageSize = filter.pageSize;
                return res;
            });
    }

    /**
     * Get user data
     */
    public async getUser(userUid: string): Promise<User> {
        return this.http.get<{ data: User }>(String.format(this.settings.service.getUser, userUid), new UserTypeDataGuard())
            .then(res => res.data);
    }

    public async getUserSettings(userUid: string): Promise<UserUpdateSettings> {
        return this.http.get<{ data: UserUpdateSettings }>(String.format(this.settings.service.getUserSettings, userUid))
            .then(res => res.data);
    }

    /**
     * Get user roles/permissions
     */
    public async getUserGroups(userUid: string): Promise<{ list: UserGroup[] }> {
        return this.http.get<{ list: UserGroup[] }>(String.format(this.settings.service.getUserGroups, userUid));
    }

    /**
     * Update user settings (culture only)
     * @param userUid user uid
     * @param request user update request
     */
    public async updateUserSettings(userUid: string, request: UserUpdateSettings): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateUserSetting, userUid), request);
    }

    /**
     * Update user email
     */
    public async getUserEmail(userUid: string, request: UserUpdateEmail): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateUserEmail, userUid), request);
    }

    /**
     * Proceed user registration, then do login after successful registration
     */
    public async register(registrationRequest: UserRegistrationRequest): Promise<User> {
        const culture = ExgCultureEnum.Russian; // TODO this.languageService.retrieveLanguage();
        return this.http.post<{ data: User }>(String.format(this.settings.service.registerUser, culture), { ...registrationRequest, culture })
            .then(d => d.data);
    }

    /**
     * Update user req
     */
    public async updateUser(uid: string, req: UserRegistrationRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateUser, uid), req);
    }

    /**
     * Update user pincode
     */
    public async updateUserPinCode(userUid: string, request: UserPincodeUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateUserPinCode, userUid), request);
    }

    /**
     * Set user group
     */
    public async updateUserGroup(userUid: string, req: UserGroupUpdateRequest): Promise<boolean> {
        return this.http.put<boolean>(String.format(this.settings.service.updateUserGroup, userUid), req);
    }

    public async deleteUser(userUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteUser, userUid));
    }

    /**
     * Delete user's avatar
     */
    public async deleteUserAvatar(userUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteUserAvatar, userUid));
    }

    /**
     * get user verification details
     */
    public async getVerificationDetails(userUid: string) {
        return this.http.get<{ data: UserVerificationDetails }>(String.format(this.settings.service.getVerificationDetails, userUid))
            .then(res => res.data);
    }

    /**
     * Update user verification details
     */
    public async updateVerificationDetails(userUid: string, request: UpdateUserForVerificationRequest) {
        return this.http.put<{ data: UserVerificationDetails }>(String.format(this.settings.service.updateVerificationDetails, userUid), request)
            .then(res => res.data);
    }

    /**
     * Get user documents
     */
    public getIdentificationDocuments(userUid: string): Promise<string[]> {
        return this.http.get<{ list: string[] }>(String.format(this.settings.service.getIdentificationDocuments, userUid))
            .then(res => res.list);
    }

    /**
     * Сreate user documents
     */
    public updateIdentificationDocuments(userUid: string, photos: string[]) {
        const promises = photos.map(request => this.http.post(String.format(this.settings.service.updateIdentificationDocuments, userUid), request));
        return Promise.all(promises);
    }

    public deleteIdentificationDocuments(userUid: string, fileName: string) {
        return this.http.delete(String.format(this.settings.service.deleteIdentificationDocuments, userUid, fileName));
    }

    public async changeUserPassword(userUid: string, change: UserChangePasswordRequest): Promise<boolean> {
        return this.http.put<boolean>(String.format(this.settings.service.updateUserPassword, userUid), change);
    }
}
