import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerContragentFailAction, CustomerContragentGetAction, CustomerContragentGetSuccessAction, CustomerContragentResetAction } from './customer-contragent.actions';

export interface CustomerContragentStateModel {
    entity: Contragent;
    pending: boolean;
    error: ErrorObject;
}

@State<CustomerContragentStateModel>({
    name: 'customerContragent',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CustomerContragentState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(CustomerContragentGetAction) contragentGet(ctx: StateContext<CustomerContragentStateModel>, action: CustomerContragentGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contragentsService.getContragent(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CustomerContragentGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomerContragentFailAction(err)), 0));
    }

    @Action(CustomerContragentGetSuccessAction) contragentGetSuccess(ctx: StateContext<CustomerContragentStateModel>, action: CustomerContragentGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CustomerContragentFailAction) contragentGetFail(ctx: StateContext<CustomerContragentStateModel>, action: CustomerContragentFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomerContragentResetAction) contragentGetReset(ctx: StateContext<CustomerContragentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
