import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PersonnelsService } from '../../services/business/personnels.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { PersonnelCreateSuccessAction } from './personnel-create.actions';
import { PersonnelDeleteSuccessAction } from './personnel-delete.actions';
import { PersonnelUpdateSuccessAction } from './personnel-update.actions';
import { PersonnelsAction, PersonnelsFailAction, PersonnelsResetAction, PersonnelsSetFilterAction, PersonnelsSuccessAction } from './personnels.actions';

import { PersonnelListItem } from '../../models/business/personnels/personnel-list-item.model';
import { PersonnelsListFilter, PersonnelsListFilterData } from '../../models/filters/personnels-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface PersonnelsStateModel extends PaginationStateModel<PersonnelListItem> {
    uids: string[];
    entities: { [uid: string]: PersonnelListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PersonnelsListFilterData;
    total: number;
}

@State<PersonnelsStateModel>({
    name: 'personnels',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class PersonnelsState extends PaginationStateBase {
    constructor(private personnelsService: PersonnelsService) {
        super();
    }

    @Action([PersonnelsAction]) personnelsGet(ctx: StateContext<PersonnelsStateModel>, action: PersonnelsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new PersonnelsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.personnelsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.personnelsService.getPersonnelList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PersonnelsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PersonnelsFailAction(err)), 0));
    }

    @Action(PersonnelsSuccessAction) personnelsGetSuccess(ctx: StateContext<PersonnelsStateModel>, action: PersonnelsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.personnelsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(PersonnelsFailAction) personnelsGetFail(ctx: StateContext<PersonnelsStateModel>, action: PersonnelsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PersonnelsResetAction) personnelsGetReset(ctx: StateContext<PersonnelsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(PersonnelsSetFilterAction) usersSetFilter(ctx: StateContext<PersonnelsStateModel>, action: PersonnelsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PersonnelsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PersonnelCreateSuccessAction) personnelCreateSuccess(ctx: StateContext<PersonnelsStateModel>, _: PersonnelCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new PersonnelsAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(PersonnelUpdateSuccessAction) personnelUpdateSuccess(ctx: StateContext<PersonnelsStateModel>, action: PersonnelUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PersonnelDeleteSuccessAction) personnelDeleteSuccess(ctx: StateContext<PersonnelsStateModel>, action: PersonnelDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
