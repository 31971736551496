import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { UserFilterData } from '../../models/filters/user-filter.model';
import { UserListItem } from '../../models/business/user/user.model';

export class CompanyUsersAction {
    static readonly type = '[CompanyUsers API] GetCompanyUsers';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyUsersSuccessAction {
    static readonly type = '[CompanyUsers API] GetCompanyUsers Success';

    constructor(public payload: { users: PaginationResult<UserListItem>, pagination: PaginationRequest }) { }
}

export class CompanyUsersFailAction {
    static readonly type = '[CompanyUsers API] GetCompanyUsers Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyUsersResetAction {
    static readonly type = '[CompanyUsers API] GetCompanyUsers Reset';
}

export class CompanyUsersSetFilterAction {
    static readonly type = '[CompanyUsers API] SetFilter';

    constructor(public payload: UserFilterData) { }
}
