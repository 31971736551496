import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class CookingCreateAction {
    static readonly type = '[Cookings Page] CreateCooking';

    constructor(public payload: DCRCreateOrUpdateRequest) { }
}

export class CookingCreateSuccessAction {
    static readonly type = '[Cooking API] CreateCooking Success';

    constructor(public payload: DCRItem) { }
}

export class CookingCreateFailAction {
    static readonly type = '[Cooking API] CreateCooking Fail';

    constructor(public payload: ErrorObject) { }
}

export class CookingCreateResetAction {
    static readonly type = '[Cookings Page] CreateCooking Reset';
}
