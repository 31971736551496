<section class="form">
    <div class="form-header">
        <div class="title-line theme-text-color">{{ 'Add comment' | translate }}</div>
        <exg-button styleType="custom" color="primary" (btnClick)="onCloseClick()">
            <exg-icon class="close-icon" color="primary" iconName="close"></exg-icon>
        </exg-button>
    </div>
    <div class="content">
        <div class="form">
            <div class="box">
                <exg-textbox class="select"
                            [maxlength]="3000"
                            [value]="notes"
                            [isMultiLine]="true"
                            [multilineMinRows]="1"
                            [multilineMaxRows]="5"
                            placeholder="{{'Comment' | translate}}"
                            (valueChange)="onValueChanged($event)"></exg-textbox>
            </div>
            <div class="btn-box">
                <exg-button class="submit" (btnClick)="onAddClick()">{{ 'Add' | translate }}</exg-button>
            </div>
        </div>
    </div>
</section>
