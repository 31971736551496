import { FranchiseeListGetFilterData } from '../../models/filters/franchisee-list-get-filter.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FranchiseeListItem } from '../../models/business/franchisees/franchisee-list-item.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';


export class FranchiseesAction {
    static readonly type = '[Franchisees Page] GetFranchisees';
}

export class FranchiseesSuccessAction {
    static readonly type = '[Franchisees API] GetFranchisees Success';

    constructor(public payload: { list: FranchiseeListItem[] }) { }
}

export class FranchiseesOrganizationsAction {
    static readonly type = '[Franchisees Page] GetFranchiseesOrganizations';

    constructor(public payload: { parentUids: string[] }) { }
}

export class FranchiseesOrganizationsSuccessAction {
    static readonly type = '[Franchisees API] GetFranchiseesOrganizations Success';

    constructor(public payload: { list: OrganizationListItem[], uid: string; }) { }
}

export class FranchiseesFailAction {
    static readonly type = '[Franchisees API] GetFranchisees Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseesResetAction {
    static readonly type = '[Franchisees Page] GetFranchisees Reset';
}

export class FranchiseesSetFilterAction {
    static readonly type = '[Franchisees API] SetFilter';

    constructor(public payload: FranchiseeListGetFilterData) { }
}

export class FranchiseesExpandAction {
    static readonly type = '[Franchisees Page] GetFranchiseesExpand';

    constructor(public payload: { uid: string }) { }
}

export class FranchiseesPosesAction {
    static readonly type = '[Franchisees poses Page] GetFranchiseesPoses';

    constructor(public payload: { organizationUids: string[] }) { }
}

export class FranchiseesPosesSuccessAction {
    static readonly type = '[Franchisees poses API] GetFranchiseesPoses Success';

    constructor(public payload: { list: OrganizationPosListItem[], uid: string; }) { }
}
