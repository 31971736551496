import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BvcFormMenuEnum } from './bvc-form-menu.enum';

@Component({
    selector: 'bvc-form-menu',
    templateUrl: './bvc-form-menu.component.html',
    styleUrls: ['./bvc-form-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcFormMenuComponent {

    @Input() addBtnTitle = 'Create';
    @Input() hiddenMenuItems: BvcFormMenuEnum[] = [];

    @Output() readonly menuClicked = new EventEmitter<BvcFormMenuEnum>();
    @Output() readonly onAdd = new EventEmitter();

    public menuItems = [
        { id: BvcFormMenuEnum.Download, title: 'Download', icon: 'export' },
        { id: BvcFormMenuEnum.Print, title: 'Print', icon: 'print' },
        { id: BvcFormMenuEnum.Send, title: 'Send', icon: 'mail' }
    ].filter((menu) => !this.hiddenMenuItems.includes(menu.id));

    public onMenuItemClick($event): void {
        this.menuClicked.emit($event.id);
    }

    public onAddButton() {
        this.onAdd.emit();
    }

}
