import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { Subject } from 'rxjs';

import { OrganizationPosesSelectors } from '../../../evasys/shared/store/organization-pos/organization-poses.selectors';
import { OrganizationPosesDispatchers } from '../../../evasys/shared/store/organization-pos/organization-poses.dispatchers';
import { OrganizationPosesListGetFilterData } from '../../../evasys/shared/models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../../evasys/shared/models/business/organization-pos/organization-pos-list-item.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

@Component({
    selector: 'organizations-pos-select-tree',
    templateUrl: './organizations-pos-select-tree.component.html',
    styleUrls: ['./organizations-pos-select-tree.component.scss'],
})
export class OrganizationsPosSelectTreeComponent implements OnDestroy {
    @Output() readonly valueChange = new EventEmitter<OrganizationPosListItem | null>();
    @Output() readonly searchChanged = new EventEmitter<string>();

    public term: string = '';
    public selectedItem: OrganizationPosListItem;
    public organizationPoses$ = this.organizationPosSelectors.organizationPoses$;
    public organizationPosesPending$ = this.organizationPosSelectors.pending$;
    public organizationPosesRetrieved$ = this.organizationPosSelectors.retrieved$;

    private unsubscribe$ = new Subject();

    constructor(
        private organizationPosSelectors: OrganizationPosesSelectors,
        private organizationPosDispatchers: OrganizationPosesDispatchers
    ) {
        this.organizationPosDispatchers.dispatchOrganizationPosesSetFilterAction({sortField: 'Name'})
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
        this.organizationPosDispatchers.dispatchOrganizationPosesResetAction();
    }

    public onPosTitleClick(pos: OrganizationPosListItem): void {
        this.selectedItem = this.selectedItem?.uid === pos.uid ? null : pos;
        this.valueChange.emit(this.selectedItem);
    }

    public trackByItem(index: number, _): number {
        return index;
    }

    public onSearchChanged(term: string): void {
        this.dispatchOrganizationPosFilter({ term });
    }

    public loadMore(): void {
        this.organizationPosDispatchers.dispatchOrganizationPosesAction(PaginationRequest.LoadMore);
    }

    private dispatchOrganizationPosFilter(filterData: OrganizationPosesListGetFilterData): void {
        this.organizationPosDispatchers.dispatchOrganizationPosesSetFilterAction(filterData);
    }


}
