import { PostingStatus } from '../business/postings/posting-status.enum';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class PostingListGetFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public organizationUid?: string;
    public warehouseUid?: string;
    public status?: PostingStatus;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: PostingListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.organizationUid = (args && args.filterData && args.filterData.organizationUid != null) ? args.filterData.organizationUid : null;
        this.warehouseUid = (args && args.filterData && args.filterData.warehouseUid != null) ? args.filterData.warehouseUid : null;
        this.status = (args && args.filterData && args.filterData.status != null) ? args.filterData.status : null;
    }
}

export class PostingListGetFilterData extends SearchFilterData {
    public organizationUid?: string;
    public warehouseUid?: string;
    public status?: PostingStatus;
    public sortField?: string;
    public sortOrder?: SortOrder;
    public pageSize?: number;
}
