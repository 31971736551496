import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrdersAnalytics } from '../../models/business/orders-analytics/orders-analytics.model';
import { OrdersStatisticGetFilterData } from '../../models/filters/orders-statistic-get-filter.model';

export class OrdersAnalyticsAction {
    static readonly type = '[OrdersAnalyticss Page] GetOrdersAnalytics';

    constructor(public payload: OrdersStatisticGetFilterData) { }
}

export class OrdersAnalyticsSuccessAction {
    static readonly type = '[OrdersAnalytics API] GetOrdersAnalytics Success';

    constructor(public payload: OrdersAnalytics) { }
}

export class OrdersAnalyticsFailAction {
    static readonly type = '[OrdersAnalytics API] GetOrdersAnalytics Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrdersAnalyticsResetAction {
    static readonly type = '[OrdersAnalytics Page] GetOrdersAnalytics Reset';
}
