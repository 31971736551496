import { InjectionToken } from '@angular/core';
import { GeocodingStrategy } from './models/geocoding-strategy.model';
import { GeocodingType } from './models/geocoding-type.enum';

export const DEFAULT_COUNTRY_TOKEN = new InjectionToken<string>('DEFAULT_COUNTRY', {
    providedIn: 'root',
    factory: () => 'RU'
});

export const DEFAULT_GEOCODING_TOKEN = new InjectionToken<GeocodingType>('DEFAULT_GEOCODING_TOKEN', {
    providedIn: 'root',
    factory: () => GeocodingType.FiasMapbox
});

export const COUNTRY_GEOCODE_STRATEGIES_TOKEN = new InjectionToken<GeocodingStrategy[]>('COUNTRY_GEOCODE_STRATEGIES_TOKEN', {
    providedIn: 'root',
    factory: () => []
});
