import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PostingsService } from '../../services/business/postings.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PostingDeleteAction, PostingDeleteFailAction, PostingDeleteResetAction, PostingDeleteSuccessAction } from './posting-delete.actions';

export interface PostingDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PostingDeleteStateModel>({
    name: 'postingDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PostingDeleteState {
    constructor(private postingsService: PostingsService) { }

    @Action(PostingDeleteAction) async postingDelete(ctx: StateContext<PostingDeleteStateModel>, action: PostingDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.postingsService.deletePosting(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PostingDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PostingDeleteFailAction(error)), 0));
    }

    @Action(PostingDeleteSuccessAction) postingDeleteSuccess(ctx: StateContext<PostingDeleteStateModel>, _: PostingDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PostingDeleteFailAction) postingDeleteFail(ctx: StateContext<PostingDeleteStateModel>, action: PostingDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PostingDeleteResetAction) postingDeleteReset(ctx: StateContext<PostingDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
