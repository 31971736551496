import { CharacteristicGroup } from '../../models/business/characteristics/characteristic-group.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CharacteristicsGroupsAction {
    static readonly type = '[CharacteristicsGroups Page] GetCharacteristicsGroups';

    constructor(public payload: { isArchived: boolean }) { }
}

export class CharacteristicsGroupsSuccessAction {
    static readonly type = '[CharacteristicsGroups API] GetCharacteristicsGroups Success';

    constructor(public payload: CharacteristicGroup[]) { }
}

export class CharacteristicsGroupsFailAction {
    static readonly type = '[CharacteristicsGroups API] GetCharacteristicsGroups Fail';

    constructor(public payload: ErrorObject) { }
}

export class CharacteristicsGroupsResetAction {
    static readonly type = '[CharacteristicsGroups Page] GetCharacteristicsGroups Reset';
}
