export enum Period {
    Year = 6,
    Month = 3,
    Week = 2,
    Custom = 10
}

export enum PeriodExtended {
    Day = 1,
    Year = 6,
    Month = 3,
    Week = 2,
    Custom = 10
}
