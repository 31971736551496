import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PersonnelCreateOrUpdateRequest } from '../../models/business/personnels/personnel-create-or-update.model';
import { PersonnelListItem } from '../../models/business/personnels/personnel-list-item.model';
import { Personnel } from '../../models/business/personnels/personnel.model';
import { PersonnelsListFilter } from '../../models/filters/personnels-list-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { GuidUtils } from '../../../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root',
})
export class PersonnelsService extends BaseSingletonService {
    private settings: {
        service: {
            getPersonnel: string;
            getPersonnelList: string;
            createPersonnel: string;
            updatePersonnel: string;
            deletePersonnel: string;
        };
    };

    constructor(private http: HttpService) {
        super('PersonnelsService');
        this.settings = {
            service: {
                getPersonnel: '/coproration/v1/personnels/{0}',
                getPersonnelList: '/coproration/v1/personnels?term={0}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                createPersonnel: '/coproration/v1/personnels',
                updatePersonnel: '/coproration/v1/personnels/{0}',
                deletePersonnel: '/coproration/v1/personnels/{0}',
            },
        };
    }

    public async getPersonnel(uid: string): Promise<Personnel> {
        return this.http.get<{ data: Personnel }>(String.format(this.settings.service.getPersonnel, uid)).then(res => res.data);
    }

    public async getPersonnelList(filter: PersonnelsListFilter): Promise<PaginationResult<PersonnelListItem>> {
        const data = [
            {
                uid: '1',
                fullName: 'John Doe',
                position: 'Manager',
                number: '12345',
                phone: '555-123-4567',
            },
            {
                uid: '2',
                fullName: 'Jane Smith',
                position: 'Developer',
                number: '54321',
                phone: '555-987-6543',
            },
            {
                uid: '3',
                fullName: 'Alice Johnson',
                position: 'Designer',
                number: '67890',
                phone: '555-321-7890',
            },
            {
                uid: '4',
                fullName: 'Bob Brown',
                position: 'Engineer',
                number: '98765',
                phone: '555-456-1234',
            },
            {
                uid: '5',
                fullName: 'Emily Davis',
                position: 'Analyst',
                number: '23456',
                phone: '555-789-0123',
            },
        ];

        return Promise.resolve({
            list: data,
            total: data.length,
            pageIndex: 0,
            pageSize: data.length,
        });
        return this.http
            .get<PaginationResult<PersonnelListItem>>(
                String.format(this.settings.service.getPersonnelList, filter.term, filter.sortField, filter.pageIndex, filter.pageSize)
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createPersonnel(request: PersonnelCreateOrUpdateRequest): Promise<Personnel> {
        return this.http.post<{ data: Personnel }>(String.format(this.settings.service.createPersonnel), request).then(res => res.data);
    }

    public async updatePersonnel(uid: string, request: PersonnelCreateOrUpdateRequest): Promise<Personnel> {
        return this.http.put(String.format(this.settings.service.updatePersonnel, uid), request);
    }

    public async deletePersonnel(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePersonnel, uid));
    }
}
