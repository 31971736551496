import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class RecyclingGetAction {
    static readonly type = '[Recycling Page] GetRecycling Get';

    constructor(public payload: string) { }
}

export class RecyclingGetSuccessAction {
    static readonly type = '[Recycling API] GetRecycling Success';

    constructor(public payload: DCRItem) { }
}

export class RecyclingFailAction {
    static readonly type = '[Recycling API] GetRecycling Fail';

    constructor(public payload: ErrorObject) { }
}

export class RecyclingResetAction {
    static readonly type = '[Recycling Page] GetRecycling Reset';
}
