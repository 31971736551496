
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContactDeleteAction {
    static readonly type = '[My Contact Page] DeleteContact';

    constructor(public payload: { uid: string }) { }
}

export class ContactDeleteSuccessAction {
    static readonly type = '[My Contacts API] DeleteContact Success';

    constructor(public payload: { uid: string }) { }
}

export class ContactDeleteFailAction {
    static readonly type = '[My Contacts API] DeleteContact Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactDeleteResetAction {
    static readonly type = '[My Contact Page] DeleteContact Reset';
}
