import { Company } from '../../models/business/companies/company.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContragentCompanyGetAction {
    static readonly type = '[ContragentCompany Page] GetCompany Get';

    constructor(public payload: string) { }
}

export class ContragentCompanyGetSuccessAction {
    static readonly type = '[ContragentCompany API] GetCompany Success';

    constructor(public payload: Company) { }
}

export class ContragentCompanyFailAction {
    static readonly type = '[ContragentCompany API] GetCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentCompanyResetAction {
    static readonly type = '[ContragentCompany Page] GetCompany Reset';
}
