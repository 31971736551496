import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { NoteUpdateRequest } from '../../models/business/notes/note-update-request.model';

export class NoteUpdateAction {
    static readonly type = '[Booking Page] UpdateNote';

    constructor(public payload: { uid: string, request: NoteUpdateRequest }) { }
}

export class NoteUpdateSuccessAction {
    static readonly type = '[Note API] UpdateNote Success';

    constructor(public payload: { uid: string, request: NoteUpdateRequest }) { }
}

export class NoteUpdateFailAction {
    static readonly type = '[Note API] UpdateNote Fail';

    constructor(public payload: ErrorObject) { }
}

export class NoteUpdateResetAction {
    static readonly type = '[Booking Page] UpdateNote Reset';
}
