import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { NoteCreateRequest } from '../../models/business/notes/note-create-request.model';
import { NoteUpdateRequest } from '../../models/business/notes/note-update-request.model';
import { Note } from '../../models/business/notes/note.model';
import { NotesListGetFilter } from '../../models/filters/notes-list-get-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class NotesService extends BaseSingletonService {

    private settings: {
        service: {
            getNote: string;
            getNoteList: string;
            createNote: string;
            updateNote: string;
            deleteNote: string;

            addNoteDocuments: string;
            deleteNoteDocuments: string;
        }
    };

    constructor(private http: HttpService) {
        super('NotesService');
        this.settings = {
            service: {
                getNote: '/crm/v1/notes/{0}',
                getNoteList: '/crm/v1/notes?companyUid={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                createNote: '/crm/v1/notes',
                updateNote: '/crm/v1/notes/{0}',
                deleteNote: '/crm/v1/notes/{0}',

                addNoteDocuments: '/crm/v1/notes/{0}/documents',
                deleteNoteDocuments: '/crm/v1/notes/{0}/documents?documentUids={1}'
            }
        };
    }

    public async getNote(uid: string): Promise<Note> {
        return this.http.get<{ data: Note }>(String.format(this.settings.service.getNote, uid))
            .then(res => res.data);
    }

    public async getNoteList(filter: NotesListGetFilter): Promise<PaginationResult<Note>> {
        return this.http.get<PaginationResult<Note>>(String.format(this.settings.service.getNoteList, filter.companyUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createNote(request: NoteCreateRequest): Promise<Note> {
        return this.http.post<{ data: Note }>(String.format(this.settings.service.createNote), request)
            .then(res => res.data);
    }

    public async updateNote(uid: string, request: NoteUpdateRequest): Promise<Note> {
        return this.http.put(String.format(this.settings.service.updateNote, uid), request);
    }

    public async deleteNote(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteNote, uid));
    }

    public async addNoteDocuments(uid: string, request: File[]): Promise<Note> {
        const formData = new FormData();

        for (const file of request) {
            formData.append(file.name, file);
        }

        return this.http.upload<{ data: Note }>(String.format(this.settings.service.addNoteDocuments, uid), formData)
            .then(x => x.data);
    }

    public async deleteNoteDocuments(uid: string, documentUids: string[]): Promise<boolean> {
        const uids = (documentUids || []).join(',');
        return this.http.delete(String.format(this.settings.service.deleteNoteDocuments, uid, uids));
    }
}
