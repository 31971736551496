import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { NoteUpdateDocumentsState, NoteUpdateDocumentsStateModel } from './note-update-documents.reducer';

@Injectable({
    providedIn: 'root'
})
export class NoteUpdateDocumentsSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([NoteUpdateDocumentsState], (state: NoteUpdateDocumentsStateModel) => state.pending);

    static readonly getUpdated = createSelector([NoteUpdateDocumentsState], (state: NoteUpdateDocumentsStateModel) => state.updated);

    static readonly getError = createSelector([NoteUpdateDocumentsState], (state: NoteUpdateDocumentsStateModel) => state.error);

    @Select(NoteUpdateDocumentsSelectors.getPending) pending$: Observable<boolean>;

    @Select(NoteUpdateDocumentsSelectors.getUpdated) updated$: Observable<boolean>;

    @Select(NoteUpdateDocumentsSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('NoteUpdateDocumentsSelectors');
    }
}
