import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransfersService } from '../../services/business/transfers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TransferCreateOrUpdateRequest } from '../../models/business/transfers/transfer-create-or-update-request.model';

import { TransferUpdateAction, TransferUpdateFailAction, TransferUpdateResetAction, TransferUpdateSuccessAction } from './transfer-update.actions';

export interface TransferUpdateStateModel {
    pending: boolean;
    updated: TransferCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<TransferUpdateStateModel>({
    name: 'transferUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class TransferUpdateState {
    constructor(private transfersService: TransfersService) { }

    @Action(TransferUpdateAction) async transferUpdate(ctx: StateContext<TransferUpdateStateModel>, action: TransferUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.transfersService.updateTransfer(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TransferUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransferUpdateFailAction(err)), 0));
    }

    @Action(TransferUpdateSuccessAction) transferUpdateSuccess(ctx: StateContext<TransferUpdateStateModel>, action: TransferUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(TransferUpdateFailAction) transferUpdateFail(ctx: StateContext<TransferUpdateStateModel>, action: TransferUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(TransferUpdateResetAction) transferUpdateReset(ctx: StateContext<TransferUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
