import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderCreateOrUpdateRequest } from '../../models/business/orders/order-create-or-update-request.model';
import { Order } from '../../models/business/orders/order.model';

export class OrderCreateAction {
    static readonly type = '[Orders Page] CreateOrder';

    constructor(public payload: OrderCreateOrUpdateRequest) { }
}

export class OrderCreateSuccessAction {
    static readonly type = '[Order API] CreateOrder Success';

    constructor(public payload: Order) { }
}

export class OrderCreateFailAction {
    static readonly type = '[Order API] CreateOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderCreateResetAction {
    static readonly type = '[Orders Page] CreateOrder Reset';
}
