import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PersonnelsService } from '../../services/business/personnels.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PersonnelCreateAction, PersonnelCreateFailAction, PersonnelCreateResetAction, PersonnelCreateSuccessAction } from './personnel-create.actions';
import { Personnel } from '../../models/business/personnels/personnel.model';

export interface PersonnelCreateStateModel {
    pending: boolean;
    created: Personnel;
    error: ErrorObject;
}

@State<PersonnelCreateStateModel>({
    name: 'personnelCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PersonnelCreateState {
    constructor(private personnelsService: PersonnelsService) { }

    @Action(PersonnelCreateAction) async personnelCreate(ctx: StateContext<PersonnelCreateStateModel>, action: PersonnelCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.personnelsService.createPersonnel({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PersonnelCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PersonnelCreateFailAction(err)), 0));
    }

    @Action(PersonnelCreateSuccessAction) personnelCreateSuccess(ctx: StateContext<PersonnelCreateStateModel>, action: PersonnelCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PersonnelCreateFailAction) personnelCreateFail(ctx: StateContext<PersonnelCreateStateModel>, action: PersonnelCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PersonnelCreateResetAction) personnelCreateReset(ctx: StateContext<PersonnelCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
