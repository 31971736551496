import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { AcceptancesService } from '../../services/business/acceptances.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { AcceptanceCreateSuccessAction } from './acceptance-create.actions';
import { AcceptanceDeleteSuccessAction } from './acceptance-delete.actions';
import { AcceptanceUpdateSuccessAction } from './acceptance-update.actions';
import { AcceptancesAction, AcceptancesFailAction, AcceptancesResetAction, AcceptancesSetFilterAction, AcceptancesSuccessAction } from './acceptances.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { AcceptanceListGetFilter, AcceptanceListGetFilterData } from '../../models/filters/acceptance-list-get-filter.model';
import { AcceptanceListItem } from '../../models/business/acceptances/acceptance-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface AcceptancesStateModel extends PaginationFilteredStateModel<AcceptanceListItem, AcceptanceListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: AcceptanceListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: AcceptanceListGetFilterData;
    total: number;
    totalAmount: number;
    totalPaidAmount: number;
}

@State<AcceptancesStateModel>({
    name: 'acceptances',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, totalAmount: 0, totalPaidAmount: 0, filter: {} }
})
@Injectable()
export class AcceptancesState extends PaginationFilteredStateBase {
    constructor(private acceptancesService: AcceptancesService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([AcceptancesAction]) productsGet(ctx: StateContext<AcceptancesStateModel>, action: AcceptancesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'acceptance');
        ctx.setState({ ...state, filter: filterData });

        const filter = new AcceptanceListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.acceptancesService.getAcceptanceList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new AcceptancesSuccessAction({ list: resp, pagination: action.payload, totalAmount: resp.totalAmount, totalPaidAmount: resp.totalPaidAmount })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AcceptancesFailAction(err)), 0));
    }

    @Action(AcceptancesSuccessAction) acceptanceGetSuccess(ctx: StateContext<AcceptancesStateModel>, action: AcceptancesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, totalPaidAmount: action.payload.totalPaidAmount, totalAmount: action.payload.totalAmount, allItemsLoaded, index });
    }

    @Action(AcceptancesFailAction) acceptanceGetFail(ctx: StateContext<AcceptancesStateModel>, action: AcceptancesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AcceptancesResetAction) acceptanceGetReset(ctx: StateContext<AcceptancesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(AcceptancesSetFilterAction) usersSetFilter(ctx: StateContext<AcceptancesStateModel>, action: AcceptancesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'acceptance');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new AcceptancesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(AcceptanceCreateSuccessAction) acceptanceCreateSuccess(ctx: StateContext<AcceptancesStateModel>, action: AcceptanceCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(AcceptanceUpdateSuccessAction) acceptanceUpdateSuccess(ctx: StateContext<AcceptancesStateModel>, action: AcceptanceUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(AcceptanceDeleteSuccessAction) acceptanceDeleteSuccess(ctx: StateContext<AcceptancesStateModel>, action: AcceptanceDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
