import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CustomerContragentGetAction {
    static readonly type = '[CustomerContragent Page] GetContragent Get';

    constructor(public payload: string) { }
}

export class CustomerContragentGetSuccessAction {
    static readonly type = '[CustomerContragent API] GetContragent Success';

    constructor(public payload: Contragent) { }
}

export class CustomerContragentFailAction {
    static readonly type = '[CustomerContragent API] GetContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerContragentResetAction {
    static readonly type = '[CustomerContragent Page] GetContragent Reset';
}
