import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'contracts-add',
    templateUrl: './contracts-add.component.html',
    styleUrls: ['./contracts-add.component.scss', '../contracts-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractAddComponent {

    @Output() readonly addBtnClick = new EventEmitter<void>();

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }
}
