import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersErrorsService } from '../../services/business/orders-errors.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrdersErrorsUnreadCountAction, OrdersErrorsUnreadCountFailAction, OrdersErrorsUnreadCountResetAction, OrdersErrorsUnreadCountSuccessAction } from './orders-errors-unread-count.actions';

export interface OrdersErrorsUnreadCountStateModel {
    pending: boolean;
    unreadCount: number;
    error: ErrorObject;
}

@State<OrdersErrorsUnreadCountStateModel>({
    name: 'ordersErrorsUnreadCount',
    defaults: { pending: false, unreadCount: 0, error: null }
})
@Injectable()
export class OrdersErrorsUnreadCountState {
    constructor(private notificationsService: OrdersErrorsService) { }

    @Action([OrdersErrorsUnreadCountAction.FromHeaderComponent, OrdersErrorsUnreadCountAction.OnNotificationSuccessUpdate]) async notificationsUnreadCountGet(ctx: StateContext<OrdersErrorsUnreadCountStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.notificationsService.getUnreadCount()
            .then(resp => setTimeout(() => ctx.dispatch(new OrdersErrorsUnreadCountSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrdersErrorsUnreadCountFailAction(err)), 0));
    }

    @Action(OrdersErrorsUnreadCountSuccessAction) notificationsUnreadCountGetSuccess(ctx: StateContext<OrdersErrorsUnreadCountStateModel>, action: OrdersErrorsUnreadCountSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, unreadCount: action.payload, error: null });
    }

    @Action(OrdersErrorsUnreadCountFailAction) notificationsUnreadCountGetFail(ctx: StateContext<OrdersErrorsUnreadCountStateModel>, action: OrdersErrorsUnreadCountFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrdersErrorsUnreadCountResetAction) notificationsUnreadCountGetReset(ctx: StateContext<OrdersErrorsUnreadCountStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, unreadCount: 0, error: null });
    }
}
