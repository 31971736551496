<section class="form">
    <div class="header">
        <div class="title-line">{{ (isRevertTTK ? 'Add to technological map' : 'Add position') | translate }}</div>
        <exg-button styleType="custom" color="primary" (btnClick)="onCloseClick()">
            <exg-icon class="close-icon" color="primary" iconName="close"></exg-icon>
        </exg-button>
    </div>

    <form [formGroup]="mainForm" [exgDisabled]="false" class="form buttons-active" [autocomplete]="true" (submit)="$event.preventDefault(); onSubmit()">
        <perfect-scrollbar class="scroll">
            <div class="box">
                <exg-autocomplete
                    class="single-row-item empty-bottom"
                    [maxlength]="255"
                    [data]="products$ | async"
                    formControlName="product"
                    placeholder="{{ 'Name1' | translate }}"
                    [displayValueFunction]="productDisplayValueFunction"
                    [selectedDisplayFunction]="productDisplayValueFunction"
                    [displayValueTemplate]="productDisplayValueTemplate"
                    [controlsToValidate]="[product]"
                    (valueChange)="onSearchProducts($event)"
                    [validate]="validate$ | async"></exg-autocomplete>
                <ng-template #productDisplayValueTemplate let-val>
                    <div class="product-box">
                        <span>{{ val.code || '' }}{{ val.code ? ' — ' : '' }}{{ val.name }}</span>
                    </div>
                </ng-template>
            </div>

            <div class="box">
                <div class="row">
                    <exg-textbox
                        class="third-row-item"
                        formControlName="article"
                        placeholder="{{ 'Article' | translate }}"
                        [readonly]="true"
                        [controlsToValidate]="[article]"
                        [validate]="validate$ | async"></exg-textbox>
                    <exg-textbox
                        class="third-row-item"
                        formControlName="unit"
                        placeholder="{{ 'UnitOfMeasure' | translate }}"
                        [readonly]="true"
                        [controlsToValidate]="[unit]"
                        [validate]="validate$ | async"></exg-textbox>
                    <exg-textbox
                        class="third-row-item"
                        formControlName="type"
                        placeholder="{{ 'Type' | translate }}"
                        [readonly]="true"
                        [controlsToValidate]="[type]"
                        [validate]="validate$ | async"></exg-textbox>
                </div>
            </div>

            <div *ngIf="isRevertTTK && !this.position" class="box bottom-form">
                <div class="note">
                    <div class="row">
                        <div class="form-title exg-color-primary">{{ 'Note' | translate }}</div>
                        <exg-icon class="info-icon exg-color-primary" iconName="information-line"></exg-icon>
                    </div>
                    <div class="form-title exg-color-primary">
                        {{ 'Restore card warning2' | translate }}
                    </div>
                </div>

                <div class="info-title-line">{{ 'Validity2' | translate }}</div>

                <div class="row">
                    <exg-datepicker
                        class="third-row-item"
                        formControlName="dateFrom"
                        [minDate]="today"
                        placeholder="{{ 'From1' | translate }}"
                        [controlsToValidate]="[dateFrom]"
                        [validate]="validate$ | async"></exg-datepicker>
                    <exg-datepicker
                        class="third-row-item"
                        formControlName="dateTo"
                        [minDate]="today"
                        placeholder="{{ 'to' | translate }}"
                        [controlsToValidate]="[dateTo]"
                        [validate]="validate$ | async"></exg-datepicker>
                    <div class="third-row-item"></div>
                </div>
            </div>

            <div class="box">
                <div class="info-title-line">{{ 'Count of procurement' | translate }}</div>
                <div class="row">
                    <exg-textbox
                        class="third-row-item"
                        formControlName="quantityPerPackage"
                        placeholder="{{ 'Qty Packed' | translate }}"
                        [controlsToValidate]="[quantityPerPackage]"
                        inputType="number"
                        [validate]="validate$ | async"></exg-textbox>

                    <exg-textbox
                        class="third-row-item"
                        formControlName="grossUnit"
                        placeholder="{{ 'GrossQuantity' | translate }}"
                        [controlsToValidate]="[grossUnit]"
                        [validate]="validate$ | async"></exg-textbox>
                    <exg-textbox
                        class="third-row-item"
                        formControlName="grossKilogram"
                        placeholder="{{ 'GrossWeight' | translate }}"
                        [controlsToValidate]="[grossKilogram]"
                        [validate]="validate$ | async"></exg-textbox>
                </div>
                <div class="row">
                    <exg-textbox
                        class="third-row-item"
                        formControlName="hotLosses"
                        [inputMask]="{ type: 'number', min: 0, max: 100 }"
                        placeholder="{{ 'LossHotPercent' | translate }}"
                        [controlsToValidate]="[hotLosses]"
                        [validate]="validate$ | async"></exg-textbox>

                    <exg-textbox
                        class="third-row-item"
                        formControlName="coldLosses"
                        [inputMask]="{ type: 'number', min: 0, max: 100 }"
                        placeholder="{{ 'LossColdPercent' | translate }}"
                        [controlsToValidate]="[coldLosses]"
                        [validate]="validate$ | async"></exg-textbox>

                    <exg-textbox
                        class="third-row-item"
                        formControlName="netKilogram"
                        placeholder="{{ 'NetWeight' | translate }}"
                        [controlsToValidate]="[netKilogram]"
                        [validate]="validate$ | async"></exg-textbox>
                </div>
                <div class="row">
                    <exg-textbox
                        class="single-row-item"
                        formControlName="finishedKilogram"
                        placeholder="{{ 'Output product in kg' | translate }}"
                        [controlsToValidate]="[finishedKilogram]"
                        [validate]="validate$ | async"></exg-textbox>
                </div>
            </div>
        </perfect-scrollbar>

        <div class="navigation-bottom">
            <div *ngIf="position" class="btn-box">
                <exg-button class="cancel" styleType="outlined" (btnClick)="onCloseClick()">{{ 'Cancel' | translate }}</exg-button>
                <exg-button class="submit" [disabled]="!(editState$ | async)" type="submit">{{ 'Save' | translate }}</exg-button>
            </div>
            <div *ngIf="!position" class="btn-box">
                <exg-button class="add" type="submit">{{ 'Add' | translate }}</exg-button>
            </div>
        </div>
    </form>
</section>
