import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { CategoryListItem } from '../../models/business/corporation-catalog/category-list-item.model';
import { ProductCategoriesListGetAggregationFilter } from '../../models/filters/product-categories-list-get-aggregation-filter.model';
import { PublicProductListGetAggregationFilterData } from '../../models/filters/public-product-list-get-aggregation-filter.model';
import { PublicProductListItemAggregation } from '../../models/business/products/public-product-list-item-aggregation.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CorporationCatalogService extends BaseSingletonService {

    private settings: {
        service: {
            getPublicCategories: string;

            getPublicProducts: string;
        }
    };

    constructor(private http: HttpService) {
        super('CorporationCatalogService');
        this.settings = {
            service: {
                getPublicCategories: '/corporation-aggregation/v1/public-catalog/categories?parentUid={0}&organizationPosUid={1}',

                getPublicProducts: '/corporation-aggregation/v1/public-catalog/products?term={0}&typeUids={1}&organizationUid={2}&organizationPosUid={3}&warehouseUid={4}&categoryUids={5}',
            }
        };
    }

    public async getPublicCategories(filter: ProductCategoriesListGetAggregationFilter): Promise<CategoryListItem[]> {
        return this.http.get<{ list: CategoryListItem[] }>(String.format(this.settings.service.getPublicCategories, filter.parentUid, filter.organizationPosUid))
            .then(res => res.list);
    }

    public async getPublicProducts(filter: PublicProductListGetAggregationFilterData): Promise<PublicProductListItemAggregation[]> {
        const typeUids = filter.typeUids?.join(',');
        const categoryUids = filter.categoryUids?.join(',');
        return this.http.get<{ list: PublicProductListItemAggregation[] }>(String.format(this.settings.service.getPublicProducts, filter.term, typeUids, filter.organizationUid, filter.organizationPosUid, filter.warehouseUid, categoryUids))
            .then(res => res.list);
    }
}
