import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WarehouseListItem } from '../../models/business/warehouses/warehouse-list-item.model';
import { WarehousesListFilterData } from '../../models/filters/warehouses-list-filter.model';

export class WarehousesAction {
    static readonly type = '[Warehouses Page] GetWarehouses';

    constructor(public payload: PaginationRequest) { }
}

export class WarehousesSuccessAction {
    static readonly type = '[Warehouses API] GetWarehouses Success';

    constructor(public payload: { list: PaginationResult<WarehouseListItem>, pagination: PaginationRequest }) { }
}

export class WarehousesFailAction {
    static readonly type = '[Warehouses API] GetWarehouses Fail';

    constructor(public payload: ErrorObject) { }
}

export class WarehousesResetAction {
    static readonly type = '[Warehouses Page] GetWarehouses Reset';
}

export class WarehousesSetFilterAction {
    static readonly type = '[Warehouses API] SetFilter';

    constructor(public payload: WarehousesListFilterData) { }
}
