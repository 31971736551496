import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilterData } from '../../models/filters/products-list-get-filter.model';

export class ProductsSearchAction {
    static readonly type = '[ProductsSearch Page] GetProductsSearch';

    constructor(public payload: PaginationRequest) { }
}

export class ProductsSearchSuccessAction {
    static readonly type = '[ProductsSearch API] GetProductsSearch Success';

    constructor(public payload: { list: PaginationResult<ProductListItemAggregation>, pagination: PaginationRequest }) { }
}

export class ProductsSearchFailAction {
    static readonly type = '[ProductsSearch API] GetProductsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductsSearchResetAction {
    static readonly type = '[ProductsSearch Page] GetProductsSearch Reset';
}

export class ProductsSearchSetFilterAction {
    static readonly type = '[ProductsSearch API] SetFilter';

    constructor(public payload: ProductsListGetFilterData) { }
}
