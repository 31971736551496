import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IntervalsService } from '../../services/business/intervals.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetAvailableListFilter, IntervalGetAvailableListFilterData } from '../../models/filters/interval-get-available-list-filter.model';
import { IntervalShort } from '../../models/business/intervals/interval-short.model';

import { IntervalsAvailableListFailAction, IntervalsAvailableListGetAction, IntervalsAvailableListGetSuccessAction, IntervalsAvailableListResetAction } from './intervals-available-list.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface IntervalsAvailableListStateModel {
    uids: number[];
    entities: { [uid: number]: IntervalShort };
    pending: boolean;
    error: ErrorObject;
    filter: IntervalGetAvailableListFilterData;
    retrieved: boolean;
}

@State<IntervalsAvailableListStateModel>({
    name: 'intervalsAvailableList',
    defaults: { pending: false, uids: [], entities: {}, error: null, filter: null, retrieved: false }
})
@Injectable()
export class IntervalsAvailableListState {

    constructor(private intervalsService: IntervalsService) { }

    @Action(IntervalsAvailableListGetAction) intervalGet(ctx: StateContext<IntervalsAvailableListStateModel>, action: IntervalsAvailableListGetAction) {

        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };

        if (!!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            return;
        }

        ctx.setState({ ...state, pending: true, error: null, filter: filterData });

        const filter = new IntervalGetAvailableListFilter({ pageIndex: 0, pageSize: 0, filterData });
        return this.intervalsService.getIntervalsAvailableList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new IntervalsAvailableListGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new IntervalsAvailableListFailAction(err)), 0));
    }

    @Action(IntervalsAvailableListGetSuccessAction) intervalsGetSuccess(ctx: StateContext<IntervalsAvailableListStateModel>, action: IntervalsAvailableListGetSuccessAction) {
        const state = ctx.getState();
        const uids = action.payload.map(p => p.dateFrom);
        const entities = action.payload.reduce((items: { [uid: string]: IntervalShort }, item: IntervalShort) => ({ ...items, [item.dateFrom]: item }), {});
        ctx.setState({ ...state, pending: false, uids, entities, error: null, retrieved: true });
    }

    @Action(IntervalsAvailableListFailAction) intervalsGetFail(ctx: StateContext<IntervalsAvailableListStateModel>, action: IntervalsAvailableListFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload, retrieved: false });
    }

    @Action(IntervalsAvailableListResetAction) intervalsGetReset(ctx: StateContext<IntervalsAvailableListStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, error: null, retrieved: false });
    }
}
