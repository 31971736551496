import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationTree } from '../../models/business/organization-tree/organization-tree.model';

import { OrganizationTreeState, OrganizationTreeStateModel } from './organization-tree.reducer';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTreeSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([OrganizationTreeState], (state: OrganizationTreeStateModel) => state.pending);

    static readonly getOrganizationTree = createSelector([OrganizationTreeState], (state: OrganizationTreeStateModel) => state.entities);

    static readonly getRetrieved = createSelector([OrganizationTreeState], (state: OrganizationTreeStateModel) => state.retrieved);

    static readonly getError = createSelector([OrganizationTreeState], (state: OrganizationTreeStateModel) => state.error);

    static readonly getAllOrganizationTreeLoaded = createSelector([OrganizationTreeState], (state: OrganizationTreeStateModel) => state.allItemsLoaded);

    @Select(OrganizationTreeSelectors.getPending) pending$: Observable<boolean>;

    @Select(OrganizationTreeSelectors.getOrganizationTree) organizationTree$: Observable<OrganizationTree>;

    @Select(OrganizationTreeSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(OrganizationTreeSelectors.getError) error$: Observable<ErrorObject>;

    @Select(OrganizationTreeSelectors.getAllOrganizationTreeLoaded) allOrganizationTreeLoaded$: Observable<boolean>;

    constructor() {
        super('OrganizationTreeSelectors');
    }
}
