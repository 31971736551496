export class ProductCategoriesListGetAggregationFilter {
    public parentUid?: string;
    public organizationPosUid?: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: ProductCategoriesListGetAggregationFilterData }) {
        this.parentUid = (args && args.filterData) ? args.filterData.parentUid : null;
        this.organizationPosUid = (args && args.filterData) ? args.filterData.organizationPosUid : null;
    }
}

export class ProductCategoriesListGetAggregationFilterData {
    public parentUid?: string;
    public organizationPosUid?: string;
}
