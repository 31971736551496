import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCreateOrUpdateRequest } from '../../models/business/catalog/product-create-or-update.model';
import { ModifierCreateOrUpdateRequest } from '../../models/business/modifiers/modifier-create-or-update-request.model';

export class ProductUpdateAction {
    static readonly type = '[Product Page] UpdateProduct';

    constructor(public payload: { uid: string, request: ProductCreateOrUpdateRequest, modifiers: ModifierCreateOrUpdateRequest[] }) { }
}

export class ProductUpdateSuccessAction {
    static readonly type = '[Product API] UpdateProduct Success';

    constructor(public payload: { uid: string, request: ProductCreateOrUpdateRequest }) { }
}

export class ProductUpdateFailAction {
    static readonly type = '[Product API] UpdateProduct Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductUpdateResetAction {
    static readonly type = '[Product Page] UpdateProduct Reset';
}
