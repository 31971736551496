import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { AcceptanceListGetFilterData } from '../../models/filters/acceptance-list-get-filter.model';
import { AcceptanceListItem } from '../../models/business/acceptances/acceptance-list-item.model';

import { AcceptancesState, AcceptancesStateModel } from './acceptances.reducer';

@Injectable({
    providedIn: 'root'
})
export class AcceptancesSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.pending);

    static readonly getAcceptance = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.retrieved);

    static readonly getError = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.error);

    static readonly getAllAcceptanceLoaded = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.filter);

    static readonly getTotal = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.total);

    static readonly getTotalAmount = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.totalAmount);

    static readonly getTotalPaidAmount = createSelector([AcceptancesState], (state: AcceptancesStateModel) => state.totalPaidAmount);

    @Select(AcceptancesSelectors.getPending) pending$: Observable<boolean>;

    @Select(AcceptancesSelectors.getAcceptance) acceptances$: Observable<AcceptanceListItem[]>;

    @Select(AcceptancesSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(AcceptancesSelectors.getError) error$: Observable<ErrorObject>;

    @Select(AcceptancesSelectors.getAllAcceptanceLoaded) allAcceptanceLoaded$: Observable<boolean>;

    @Select(AcceptancesSelectors.getFilter) filter$: Observable<AcceptanceListGetFilterData>;

    @Select(AcceptancesSelectors.getTotal) total$: Observable<number>;

    @Select(AcceptancesSelectors.getTotalAmount) totalAmount$: Observable<number>;

    @Select(AcceptancesSelectors.getTotalPaidAmount) totalPaidAmount$: Observable<number>;

    constructor() {
        super('AcceptancesSelectors');
    }
}
