import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { InventoryCreateOrUpdateRequest } from '../../models/business/inventories/inventory-create-or-update-request.model';
import { Inventory } from '../../models/business/inventories/inventory.model';

export class InventoryCreateAction {
    static readonly type = '[Inventories Page] CreateInventory';

    constructor(public payload: InventoryCreateOrUpdateRequest) { }
}

export class InventoryCreateSuccessAction {
    static readonly type = '[Inventory API] CreateInventory Success';

    constructor(public payload: Inventory) { }
}

export class InventoryCreateFailAction {
    static readonly type = '[Inventory API] CreateInventory Fail';

    constructor(public payload: ErrorObject) { }
}

export class InventoryCreateResetAction {
    static readonly type = '[Inventories Page] CreateInventory Reset';
}
