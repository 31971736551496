import { RoutingConfig } from './routing.config';

export class EvaSettings {
    public static readonly ordersSettings = {
        ignorelist: ['ShippingDate'],
        ignoreProductsList: ['Amount'],
        enableProductCompletedRedirected: {
            route: null
        }
    };

    public static readonly headerMenu = [
        { route: RoutingConfig.routes.home.fullUrl, title: 'Home', icon: 'dashboard' },
        { route: RoutingConfig.routes.callCenter.fullUrl, title: 'Orders', icon: 'orders' },
        { route: RoutingConfig.routes.clients.fullUrl, title: 'Clients', icon: 'clients' },
        { route: RoutingConfig.routes.warehouse.fullUrl, title: 'Warehouse', icon: 'warehouse' },
        { route: RoutingConfig.routes.finance.fullUrl, title: 'Finance', icon: 'finance' },
        { route: RoutingConfig.routes.purchases.fullUrl, title: 'Purchases', icon: 'purchases' },
        { route: RoutingConfig.routes.users.fullUrl, title: 'Users', icon: 'user', bottom: true },
        { route: RoutingConfig.routes.corporation.fullUrl, title: 'Franchisee', icon: 'clients', bottom: true },
        { route: RoutingConfig.routes.settings.fullUrl, title: 'Settings', icon: 'settings', bottom: true }
    ];

    public static readonly mobileMenu = {
        bottom: [
            { route: RoutingConfig.routes.home.fullUrl, title: 'Home', icon: 'dashboard' },
            { route: RoutingConfig.routes.clients.fullUrl, title: 'Clients', icon: 'clients' },
            { route: RoutingConfig.routes.callCenter.fullUrl, title: 'Orders', icon: 'orders' },
            { route: RoutingConfig.routes.warehouse.fullUrl, title: 'Warehouse', icon: 'warehouse' }
        ],
        dropdown: [
            { route: RoutingConfig.routes.finance.fullUrl, title: 'Finance', icon: 'finance' },
            { route: RoutingConfig.routes.purchases.fullUrl, title: 'Purchases', icon: 'purchases' },
            { route: RoutingConfig.routes.corporation.fullUrl, title: 'Franchisee', icon: 'clients' },
            { route: RoutingConfig.routes.settings.fullUrl, title: 'Settings', icon: 'settings' },
            { id: 'logout', route: null, title: 'Logout', icon: 'exit' }
        ]
    };

    public static readonly headerSettings = {
        logoSquare: false,
        logoVertical: true
    };

    public static readonly restrictedRoutes = {
        [150]: [RoutingConfig.routes.clients.fullUrl, RoutingConfig.routes.settings.fullUrl, RoutingConfig.routes.warehouse.fullUrl, RoutingConfig.routes.finance.fullUrl, RoutingConfig.routes.purchases.fullUrl, RoutingConfig.routes.users.fullUrl, RoutingConfig.routes.corporation.fullUrl, RoutingConfig.routes.settings.fullUrl],
    };

    public static readonly organizationsTabs = [
        { route: RoutingConfig.routes.clients.companies.fullUrl, name: 'Companies' },
        { route: RoutingConfig.routes.clients.contragents.fullUrl, name: 'Contragents' },
      //  { route: RoutingConfig.routes.clients.pos.fullUrl, name: 'Point of Sales' },
        { route: RoutingConfig.routes.clients.contacts.fullUrl, name: 'Contacts' },
       // { route: RoutingConfig.routes.clients.motivation.fullUrl, name: 'Motivation' },
      //  { route: RoutingConfig.routes.clients.organizations.fullUrl, name: 'Organizations' }
        { route: RoutingConfig.routes.clients.customers.fullUrl, name: 'Customers' },
    ];

    public static readonly warehouseTabs = [
        { route: RoutingConfig.routes.warehouse.products.fullUrl, name: 'Goods and services', icon: 'goods-and-services' },
        { route: RoutingConfig.routes.warehouse.postings.fullUrl, name: 'Postings', icon: 'postings' },
        { route: RoutingConfig.routes.warehouse.dcr.fullUrl, name: 'DCR', icon: 'dcr' },
        { route: RoutingConfig.routes.warehouse.writeOffs.fullUrl, name: 'Write offs', icon: 'write-offs' },
        { route: RoutingConfig.routes.warehouse.inventories.fullUrl, name: 'Inventory', icon: 'inventory' },
        { route: RoutingConfig.routes.warehouse.transfers.fullUrl, name: 'Transfer', icon: 'transfer' },
        { route: RoutingConfig.routes.warehouse.priceList.fullUrl, name: 'Price-lists', icon: 'price-list' }
    ];

    public static readonly financeTabs = [
        { route: RoutingConfig.routes.finance.transactions.fullUrl, name: 'Transactions', icon: 'transaction' },
    ];

    public static readonly purchasesTabs = [
        { route: RoutingConfig.routes.purchases.orders.fullUrl, name: 'Purchase Orders', icon: 'add_business' },
        { route: RoutingConfig.routes.purchases.supplierInvoices.fullUrl, name: 'Purchase supplier invoices', icon: 'bill-line' },
        { route: RoutingConfig.routes.purchases.acceptance.fullUrl, name: 'Purchase acceptance', icon: 'barcode-line' },
        { route: RoutingConfig.routes.purchases.returns.fullUrl, name: 'Purchase returns', icon: 'inbox-unarchive-line' },
        { route: RoutingConfig.routes.purchases.invoices.fullUrl, name: 'Purchase invoices', icon: 'receipt_long' },
        { route: RoutingConfig.routes.purchases.management.fullUrl, name: 'Purchasing management', icon: 'purchases' },
    ];

    public static readonly orderTabs = [
        { route: RoutingConfig.routes.orders.orders.fullUrl, name: 'Customer Orders', icon: 'shopping-cart' },
        { route: RoutingConfig.routes.orders.invoices.fullUrl, name: 'Customer invoices', icon: 'request_quite' },
        { route: RoutingConfig.routes.orders.loads.fullUrl, name: 'Loads', icon: 'unarchive' },
        { route: RoutingConfig.routes.orders.returns.fullUrl, name: 'Order returns', icon: 'refund' },
        { route: RoutingConfig.routes.orders.invoices.fullUrl, name: 'Order invoices given', icon: 'file-list' },
        { route: RoutingConfig.routes.orders.salesFunnel.fullUrl, name: 'Sales funnel', icon: 'tenancy' },
    ];

    public static readonly callCenterTabs = [
        { route: RoutingConfig.routes.callCenter.orders.fullUrl, name: 'Customer Orders', icon: 'shopping-cart' },
    ];

    public static readonly userSettings = {
        disableByPermission: false
    };

    public static readonly companiesTabs = [
        { tab: 'Company', formClass: true },
        { tab: 'Activity' },
        { tab: 'Analytics' },
        { tab: 'Contragents' },
        { tab: 'Pos' },
        { tab: 'Contacts' },
        { tab: 'Orders' }
    ];

    public static readonly contragentsTabs = [
        { tab: 'Analytics' },
        { tab: 'Pos' },
        { tab: 'Contacts' },
        { tab: 'Requisites' },
        { tab: 'Contracts' },
        { tab: 'Orders' }
    ];
}
