import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Media } from '../../../../burns-ui-framework/shared/models/business/media/media.model';
import { MediasFilterData } from '../../models/filters/medias-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class PosMediaListAction {
    static readonly type = '[PosMediaList Page] GetPosMediaList';

    constructor(public payload: { uid: string, request: PaginationRequest }) { }
}

export class PosMediaListSuccessAction {
    static readonly type = '[PosMediaList API] GetPosMediaList Success';

    constructor(public payload: { list: PaginationResult<Media>, pagination: PaginationRequest }) { }
}

export class PosMediaListFailAction {
    static readonly type = '[PosMediaList API] GetPosMediaList Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosMediaListResetAction {
    static readonly type = '[PosMediaList Page] GetPosMediaList Reset';
}

export class PosMediaListSetFilterAction {
    static readonly type = '[PosMediaList API] SetFilter';

    constructor(public payload: { uid: string, filter: MediasFilterData }) { }
}
