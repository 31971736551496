import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class BrandDeleteAction {
    static readonly type = '[My Brand Page] DeleteBrand';

    constructor(public payload: { uid: string }) { }
}

export class BrandDeleteSuccessAction {
    static readonly type = '[My Brands API] DeleteBrand Success';

    constructor(public payload: { uid: string }) { }
}

export class BrandDeleteFailAction {
    static readonly type = '[My Brands API] DeleteBrand Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandDeleteResetAction {
    static readonly type = '[My Brand Page] DeleteBrand Reset';
}
