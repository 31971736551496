import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Reservation } from '../../models/business/reservations/reservation.model';

export class ReservationGetAction {
    static readonly type = '[Reservations Page] GetInterval Get';

    constructor(public payload: { contactUid: string, organizationPosUid: string }) { }
}

export class ReservationGetSuccessAction {
    static readonly type = '[Reservations API] GetInterval Success';

    constructor(public payload: Reservation) { }
}

export class ReservationFailAction {
    static readonly type = '[Reservations API] GetInterval Fail';

    constructor(public payload: ErrorObject) { }
}

export class ReservationResetAction {
    static readonly type = '[Reservations Page] GetInterval Reset';
}
