import { Pipe, PipeTransform } from '@angular/core';

import { GeocodingMapper } from '../mappers/geocoding-mapper';

import { ComponentType } from '../models/component-type.enum';
import { YandexPlaceInputComponentType } from '../../yandex-geocoding/models/yandex-place-input-component-type.enum';

@Pipe({ name: 'exgYandexComponentInputType' })
export class ExgYandexComponentInputTypePipe implements PipeTransform {
    public transform(value: ComponentType): YandexPlaceInputComponentType {
        return GeocodingMapper.MapInputComponentType(value);
    }
}
