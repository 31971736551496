import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CustomersService } from '../../services/business/customers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerUpdateSuccessAction } from './customer-update.actions';
import { CustomerFailAction, CustomerGetAction, CustomerGetSuccessAction, CustomerResetAction } from './customer.actions';
import { Customer } from '../../models/business/customers/customer.model';

export interface CustomerStateModel {
    entity: Customer;
    pending: boolean;
    error: ErrorObject;
}

@State<CustomerStateModel>({
    name: 'customer',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CustomerState {
    constructor(private customersService: CustomersService) { }

    @Action(CustomerGetAction) customerGet(ctx: StateContext<CustomerStateModel>, action: CustomerGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.customersService.getCustomer(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CustomerGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomerFailAction(err)), 0));
    }

    @Action(CustomerGetSuccessAction) customerGetSuccess(ctx: StateContext<CustomerStateModel>, action: CustomerGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CustomerFailAction) customerGetFail(ctx: StateContext<CustomerStateModel>, action: CustomerFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomerResetAction) customerGetReset(ctx: StateContext<CustomerStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(CustomerUpdateSuccessAction) customerUpdateSuccess(ctx: StateContext<CustomerStateModel>, action: CustomerUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
