import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductType } from '../../models/business/orders/order-product-type.model';

import { ProductTypeAction, ProductTypeFailAction, ProductTypeResetAction, ProductTypeSuccessAction } from './product-types.actions';
import { ProductsService } from '../../services/business/products.service';


export interface ProductTypesStateModel {
    pending: boolean;
    entity: ProductType[];
    error: ErrorObject;
}

@State<ProductTypesStateModel>({
    name: 'productType',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ProductTypesState {
    constructor(private productsService: ProductsService) { }

    @Action(ProductTypeAction) async order(ctx: StateContext<ProductTypesStateModel>, _: ProductTypeAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.productsService.getProductTypes()
            .then(res => setTimeout(() => ctx.dispatch(new ProductTypeSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductTypeFailAction(err)), 0));
    }

    @Action(ProductTypeSuccessAction) orderSuccess(ctx: StateContext<ProductTypesStateModel>, action: ProductTypeSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ProductTypeFailAction) orderFail(ctx: StateContext<ProductTypesStateModel>, action: ProductTypeFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductTypeResetAction) orderReset(ctx: StateContext<ProductTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
