export enum ComponentType {
    /// <summary>
    /// Country
    /// </summary>
    Country = 1,

    /// <summary>
    /// Region
    /// </summary>
    Region = 3,

    /// <summary>
    /// City
    /// </summary>
    City = 5,

    /// <summary>
    /// Locality
    /// </summary>
    Locality = 7,

    /// <summary>
    /// Street
    /// </summary>
    Street = 8,

    /// <summary>
    /// Building
    /// </summary>
    Building = 9
}
