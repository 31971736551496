import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantCategoryCreateOrUpdateRequest } from '../../models/business/restaurant-category/restaurant-category-create-or-update-request.model';
import { WarehouseCreateOrUpdateRequest } from '../../models/business/warehouses/warehouse-create-or-update-request.model';

export class RestaurantCategoryUpdateAction {
    static readonly type = '[Booking Page] UpdateRestaurantCategory';

    constructor(public payload: { uid: string, request: RestaurantCategoryCreateOrUpdateRequest, warehouses: WarehouseCreateOrUpdateRequest[] }) { }
}

export class RestaurantCategoryUpdateSuccessAction {
    static readonly type = '[RestaurantCategory API] UpdateRestaurantCategory Success';

    constructor(public payload: { uid: string, request: RestaurantCategoryCreateOrUpdateRequest }) { }
}

export class RestaurantCategoryUpdateFailAction {
    static readonly type = '[RestaurantCategory API] UpdateRestaurantCategory Fail';

    constructor(public payload: ErrorObject) { }
}

export class RestaurantCategoryUpdateResetAction {
    static readonly type = '[Booking Page] UpdateRestaurantCategory Reset';
}
