import { CustomerListItem } from '../../models/business/customers/customer-list-item.model';
import { CustomersListFilterData } from '../../models/filters/customers-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CustomersAction {
    static readonly type = '[Customers Page] GetCustomers';

    constructor(public payload: PaginationRequest) { }
}

export class CustomersSuccessAction {
    static readonly type = '[Customers API] GetCustomers Success';

    constructor(public payload: { list: PaginationResult<CustomerListItem>, pagination: PaginationRequest }) { }
}

export class CustomersFailAction {
    static readonly type = '[Customers API] GetCustomers Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomersResetAction {
    static readonly type = '[Customers Page] GetCustomers Reset';
}

export class CustomersSetFilterAction {
    static readonly type = '[Customers API] SetFilter';

    constructor(public payload: CustomersListFilterData) { }
}
