import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Task } from '../../models/business/tasks/task.model';

import { TaskCreateState, TaskCreateStateModel } from './task-create.reducer';

@Injectable({
    providedIn: 'root'
})
export class TaskCreateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([TaskCreateState], (state: TaskCreateStateModel) => state.pending);

    static readonly getUpdated = createSelector([TaskCreateState], (state: TaskCreateStateModel) => state.created);

    static readonly getError = createSelector([TaskCreateState], (state: TaskCreateStateModel) => state.error);

    @Select(TaskCreateSelectors.getPending) pending$: Observable<boolean>;

    @Select(TaskCreateSelectors.getUpdated) created$: Observable<Task>;

    @Select(TaskCreateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('TaskCreateSelectors');
    }
}
