import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class FranchiseeDeleteAction {
    static readonly type = '[My Franchisee Page] DeleteFranchisee';

    constructor(public payload: { uid: string }) { }
}

export class FranchiseeDeleteSuccessAction {
    static readonly type = '[My Franchisees API] DeleteFranchisee Success';

    constructor(public payload: { uid: string }) { }
}

export class FranchiseeDeleteFailAction {
    static readonly type = '[My Franchisees API] DeleteFranchisee Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeDeleteResetAction {
    static readonly type = '[My Franchisee Page] DeleteFranchisee Reset';
}
