export class ExgParamsConfig {
    public static readonly logos = {
        emptyUserAvatar: '/assets/images/no_photo.png',
        emptyProductPhoto: '/assets/images/no_photo.png'
    };

    public static readonly photosUpload = {
        avatarSize: 400,
    };

    public static readonly signalR = {
        identity: {
            OnNameChanged: { hub: '/identity/identityhub', method: 'OnNameChanged' },
            OnEmailConfirmed: { hub: '/identity/identityhub', method: 'OnEmailConfirmed' },
            OnGroupMemberAdded: { hub: '/identity/identityhub', method: 'OnGroupMemberAdded' }
        },
        orders: {
            OnOrderCreated: { hub: '/orders-aggregation/orderhub', method: 'OnOrderCreated' },
            OnOrderStatusChanged: { hub: '/orders-aggregation/orderhub', method: 'OnOrderStatusChanged' },
            OnOrderDeleted: { hub: '/orders-aggregation/orderhub', method: 'OnOrderDeleted' },
            OnOrderProductCreated: { hub: '/orders-aggregation/orderhub', method: 'OnOrderProductCreated' },
            OnOrderProductStatusChanged: { hub: '/orders-aggregation/orderhub', method: 'OnOrderProductStatusChanged' },
            OnOrderProductDeleted: { hub: '/orders-aggregation/orderhub', method: 'OnOrderProductDeleted' },
            OrErrorCreated: { hub: '/orders-aggregation/orderhub', method: 'OrErrorCreated' }
        },
        callCenter: {
            OnClientCallStarted: { hub: '/call-center/hub', method: 'OnClientCallStarted' },
            OnClientCallFinished: { hub: '/call-center/hub', method: 'OnClientCallFinished' },
        },
        productivity: {
            OnIntervalsUpdated: { hub: '/productivity/hub', method: 'OnIntervalsUpdated' }
        }
    };

    public static readonly pagination = {
        defaultPageSize: 20,
        employeesAllListPageSize: 20,
        workingAreaAllListPageSize: 20,
        routesAllListPageSize: 20,
        notificationAllListPageSize: 25,
        notificationHeaderDropdownPageSize: 6,
    };
}
