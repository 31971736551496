import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { MotivationBonusDeleteAction, MotivationBonusDeleteFailAction, MotivationBonusDeleteResetAction, MotivationBonusDeleteSuccessAction } from './motivation-bonus-delete.actions';

export interface MotivationBonusDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<MotivationBonusDeleteStateModel>({
    name: 'motivationBonusDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class MotivationBonusDeleteState {
    constructor(private contactsService: ContactsService) { }

    @Action(MotivationBonusDeleteAction) async motivationBonusDelete(ctx: StateContext<MotivationBonusDeleteStateModel>, action: MotivationBonusDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.contactsService.deleteMotivation(action.payload.contactUid, action.payload.motivationUid)
            .then(() => setTimeout(() => ctx.dispatch(new MotivationBonusDeleteSuccessAction({ contactUid: action.payload.contactUid, motivationUid: action.payload.motivationUid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new MotivationBonusDeleteFailAction(error)), 0));
    }

    @Action(MotivationBonusDeleteSuccessAction) motivationBonusDeleteSuccess(ctx: StateContext<MotivationBonusDeleteStateModel>, _: MotivationBonusDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(MotivationBonusDeleteFailAction) motivationBonusDeleteFail(ctx: StateContext<MotivationBonusDeleteStateModel>, action: MotivationBonusDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(MotivationBonusDeleteResetAction) motivationBonusDeleteReset(ctx: StateContext<MotivationBonusDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
