import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { VehicleBrandsService } from '../../services/business/vehicle-brands.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { VehicleBrandsAction, VehicleBrandsFailAction, VehicleBrandsResetAction, VehicleBrandsSuccessAction } from './vehicle-brands.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { VehicleBrand } from '../../models/business/pos/vehicle-brand.model';
import { VehicleBrandsListFilter } from '../../models/filters/vehicle-brands-list-filter.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface VehicleBrandsStateModel extends PaginationStateModel<VehicleBrand> {
    uids: string[];
    entities: { [uid: string]: VehicleBrand };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<VehicleBrandsStateModel>({
    name: 'vehicleBrand',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class VehicleBrandsState extends PaginationStateBase {
    constructor(private vehicleBrandsService: VehicleBrandsService) {
        super();
    }

    @Action([VehicleBrandsAction]) companiesGet(ctx: StateContext<VehicleBrandsStateModel>, action: VehicleBrandsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new VehicleBrandsListFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.vehicleBrandsService.getVehicleBrands(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new VehicleBrandsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleBrandsFailAction(err)), 0));
    }

    @Action(VehicleBrandsSuccessAction) companiesGetSuccess(ctx: StateContext<VehicleBrandsStateModel>, action: VehicleBrandsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.vehicleBrandsListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(VehicleBrandsFailAction) companiesGetFail(ctx: StateContext<VehicleBrandsStateModel>, action: VehicleBrandsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VehicleBrandsResetAction) companiesGetReset(ctx: StateContext<VehicleBrandsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
