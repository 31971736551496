import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PostingListGetFilterData } from '../../models/filters/posting-list-get-filter.model';
import { PostingListItem } from '../../models/business/postings/posting-list-item.model';

export class PostingsAction {
    static readonly type = '[Postingss Page] GetPostings';

    constructor(public payload: PaginationRequest) { }
}

export class PostingsSuccessAction {
    static readonly type = '[Postings API] GetPostings Success';

    constructor(public payload: { list: PaginationResult<PostingListItem>, pagination: PaginationRequest }) { }
}

export class PostingsFailAction {
    static readonly type = '[Postings API] GetPostings Fail';

    constructor(public payload: ErrorObject) { }
}

export class PostingsResetAction {
    static readonly type = '[Postings Page] GetPostings Reset';
}

export class PostingsSetFilterAction {
    static readonly type = '[Postings API] SetFilter';

    constructor(public payload: PostingListGetFilterData) { }
}
