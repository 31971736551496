import { ContactCreateOrUpdateRequest } from '../../models/business/contacts/contact-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContactUpdateAction {
    static readonly type = '[Booking Page] UpdateContact';

    constructor(public payload: { uid: string, request: ContactCreateOrUpdateRequest }) { }
}

export class ContactUpdateSuccessAction {
    static readonly type = '[Contact API] UpdateContact Success';

    constructor(public payload: { uid: string, request: ContactCreateOrUpdateRequest }) { }
}

export class ContactUpdateFailAction {
    static readonly type = '[Contact API] UpdateContact Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactUpdateResetAction {
    static readonly type = '[Booking Page] UpdateContact Reset';
}
