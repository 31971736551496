import { Personnel } from '../../models/business/personnels/personnel.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PersonnelGetAction {
    static readonly type = '[Personnel Page] GetPersonnel Get';

    constructor(public payload: string) { }
}

export class PersonnelGetSuccessAction {
    static readonly type = '[Personnel API] GetPersonnel Success';

    constructor(public payload: Personnel) { }
}

export class PersonnelFailAction {
    static readonly type = '[Personnel API] GetPersonnel Fail';

    constructor(public payload: ErrorObject) { }
}

export class PersonnelResetAction {
    static readonly type = '[Personnel Page] GetPersonnel Reset';
}
