import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { UserDeleteAction, UserDeleteFailAction, UserDeleteResetAction, UserDeleteSuccessAction } from './user-delete.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface UserDeleteStateModel {
    pending: boolean;
    deleted: boolean;
    error: ErrorObject;
}

@State<UserDeleteStateModel>({
    name: 'userDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class UserDeleteState {
    constructor(private usersService: UsersService) {
    }

    @Action([UserDeleteAction]) async usersGet(ctx: StateContext<UserDeleteStateModel>, action: UserDeleteAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.usersService.deleteUser(action.payload)
            .then(() => setTimeout(() => ctx.dispatch(new UserDeleteSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UserDeleteFailAction(err)), 0));
    }

    @Action(UserDeleteSuccessAction) usersGetSuccess(ctx: StateContext<UserDeleteStateModel>, _: UserDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, deleted: true, error: null });
    }

    @Action(UserDeleteFailAction) usersGetFail(ctx: StateContext<UserDeleteStateModel>, action: UserDeleteFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, deleted: false, error: action.payload });
    }

    @Action(UserDeleteResetAction) usersGetReset(ctx: StateContext<UserDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, deleted: false, error: null });
    }
}
