import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { NoteUpdateDocumentsAction, NoteUpdateDocumentsResetAction } from './note-update-documents.actions';

@Injectable({
    providedIn: 'root'
})
export class NoteUpdateDocumentsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('NoteUpdateDocumentsDispatchers');
    }

    public dispatchNoteUpdateDocumentsAction(uid: string, documentsToAdd: File[], documentsToRemove: string[]) {
        this.store.dispatch(new NoteUpdateDocumentsAction({ uid, documentsToAdd, documentsToRemove }));
    }

    public dispatchNoteUpdateDocumentsResetAction() {
        this.store.dispatch(new NoteUpdateDocumentsResetAction());
    }
}
