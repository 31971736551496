import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPos } from '../../models/business/organization-pos/pos.model';

export class OrganizationPosGetAction {
    static readonly type = '[OrganizationPos Page] GetOrganizationPos Get';

    constructor(public payload: string) { }
}

export class OrganizationPosGetSuccessAction {
    static readonly type = '[OrganizationPos API] GetOrganizationPos Success';

    constructor(public payload: OrganizationPos) { }
}

export class OrganizationPosFailAction {
    static readonly type = '[OrganizationPos API] GetOrganizationPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosResetAction {
    static readonly type = '[OrganizationPos Page] GetOrganizationPos Reset';
}
