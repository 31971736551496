import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Acceptance } from '../../models/business/acceptances/acceptance.model';

import { AcceptanceCreateState, AcceptanceCreateStateModel } from './acceptance-create.reducer';

@Injectable({
    providedIn: 'root'
})
export class AcceptanceCreateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([AcceptanceCreateState], (state: AcceptanceCreateStateModel) => state.pending);

    static readonly getUpdated = createSelector([AcceptanceCreateState], (state: AcceptanceCreateStateModel) => state.created);

    static readonly getError = createSelector([AcceptanceCreateState], (state: AcceptanceCreateStateModel) => state.error);

    @Select(AcceptanceCreateSelectors.getPending) pending$: Observable<boolean>;

    @Select(AcceptanceCreateSelectors.getUpdated) created$: Observable<Acceptance>;

    @Select(AcceptanceCreateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('AcceptanceCreateSelectors');
    }
}
