// tslint:disable:no-namespace
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContractEditDocumentАction {
    static readonly type = '[My Contracts Page] ContractEditDocument';

    constructor(public payload: { uid: string, contragentUid: string, request: FileList }) { }
}

export class ContractEditDocumentSuccessAction {
    static readonly type = '[My Contracts API] ContractEditDocument Success';

    constructor(public payload: { uid: string, contragentUid: string, url: string }) { }
}

export class ContractEditDocumentFailAction {
    static readonly type = '[My Contracts API] ContractEditDocument Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContractEditDocumentResetAction {
    static readonly type = '[My Contracts Page] ContractEditDocument Reset';
}
