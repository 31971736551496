import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { CorporationRequisitesListFilterData } from '../../models/filters/corporation-requisites-list-filter.model';
import { CorporationRequisiteListItem } from '../../models/business/corporation-requisites/corporation-requisite-list-item.model';

export class CorporationRequisitesAction {
    static readonly type = '[CorporationRequisites Page] GetCorporationRequisites';

    constructor(public payload: PaginationRequest) { }
}

export class CorporationRequisitesSuccessAction {
    static readonly type = '[CorporationRequisites API] GetCorporationRequisites Success';

    constructor(public payload: { list: PaginationResult<CorporationRequisiteListItem>, pagination: PaginationRequest }) { }
}

export class CorporationRequisitesFailAction {
    static readonly type = '[CorporationRequisites API] GetCorporationRequisites Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationRequisitesResetAction {
    static readonly type = '[CorporationRequisites Page] GetCorporationRequisites Reset';
}

export class CorporationRequisitesSetFilterAction {
    static readonly type = '[CorporationRequisites API] SetFilter';

    constructor(public payload: CorporationRequisitesListFilterData) { }
}
