import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { ContactCreateSuccessAction } from './contact-create.actions';
import { ContactDeleteSuccessAction } from './contact-delete.actions';
import { ContactUpdateSuccessAction } from './contact-update.actions';
import { ContactsAction, ContactsFailAction, ContactsResetAction, ContactsSetFilterAction, ContactsSuccessAction } from './contacts.actions';

import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilter, ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface ContactsStateModel extends PaginationStateModel<ContactListItem> {
    uids: string[];
    entities: { [uid: string]: ContactListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ContactsListFilterData;
    total: number;
}

@State<ContactsStateModel>({
    name: 'contacts',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class ContactsState extends PaginationStateBase {
    constructor(private contactsService: ContactsService) {
        super();
    }

    @Action([ContactsAction]) contactsGet(ctx: StateContext<ContactsStateModel>, action: ContactsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContactsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contactsService.getContactList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ContactsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContactsFailAction(err)), 0));
    }

    @Action(ContactsSuccessAction) contactsGetSuccess(ctx: StateContext<ContactsStateModel>, action: ContactsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ContactsFailAction) contactsGetFail(ctx: StateContext<ContactsStateModel>, action: ContactsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContactsResetAction) contactsGetReset(ctx: StateContext<ContactsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ContactsSetFilterAction) usersSetFilter(ctx: StateContext<ContactsStateModel>, action: ContactsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ContactsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(ContactCreateSuccessAction) contactCreateSuccess(ctx: StateContext<ContactsStateModel>, _: ContactCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new ContactsAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(ContactUpdateSuccessAction) contactUpdateSuccess(ctx: StateContext<ContactsStateModel>, action: ContactUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(ContactDeleteSuccessAction) contactDeleteSuccess(ctx: StateContext<ContactsStateModel>, action: ContactDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
