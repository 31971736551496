import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { NoteDeleteState, NoteDeleteStateModel } from './note-delete.reducer';

@Injectable({
    providedIn: 'root'
})
export class NoteDeleteSelectors extends BaseSingletonService {
    static readonly getPendings = createSelector([NoteDeleteState], (state: NoteDeleteStateModel) => state.pending);

    static readonly getErrors = createSelector([NoteDeleteState], (state: NoteDeleteStateModel) => state.error);

    static readonly getDeleted = createSelector([NoteDeleteState], (state: NoteDeleteStateModel) => state.deleted);

    @Select(NoteDeleteSelectors.getPendings) pending$: Observable<boolean>;

    @Select(NoteDeleteSelectors.getErrors) error$: Observable<ErrorObject>;

    @Select(NoteDeleteSelectors.getDeleted) deleted$: Observable<boolean>;

    constructor() {
        super('NoteDeleteSelectors');
    }
}
