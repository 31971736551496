import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { OrderListItem } from '../../../models/business/orders/order-list-item.model';
import { OrderSortingFieldsEnum } from '../../../models/business/orders/order-sorting-fields.enum';
import { OrdersListFilterData } from '../../../models/filters/orders-list-filter.model';

import { ExgListParams } from '../../../exg-list-params.config';

@Component({
    selector: 'orders-list',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersListComponent implements OnChanges {

    @Input() orders: OrderListItem[];
    @Input() allOrdersLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: OrdersListFilterData;
    @Input() enableAddLine = false;
    @Input() ignoreRows: OrderSortingFieldsEnum[] = [];
    @Input() shortList = false;

    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openOrders = new EventEmitter<string>();
    @Output() readonly sortingChange = new EventEmitter<OrdersListFilterData>();
    @Output() readonly addBtnClick = new EventEmitter<void>();

    public listConfig = ExgListParams.orderList();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.ignoreRows && this.ignoreRows && this.ignoreRows.length > 0) {
            this.listConfig = ExgListParams.orderList(this.ignoreRows);
        }
    }

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onSortingChange($event: OrdersListFilterData) {
        this.sortingChange.emit($event);
    }

    public trackByOrders(_, item: OrderListItem) {
        return item.uid;
    }

    public onOpenOrders(uid: string) {
        this.openOrders.emit(uid);
    }
}
