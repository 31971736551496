import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { RoutingConfig } from '../../../routing.config';

@Component({
    selector: 'bvc-dcr-add-line',
    templateUrl: './dcr-add-line.component.html',
    styleUrls: ['./dcr-add-line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcDcrAddLineComponent {

    @Input() addBtnTitle = 'Add';

    @Output() readonly menuClicked = new EventEmitter<string>();
    @Output() readonly addEntity = new EventEmitter();

    public routes = RoutingConfig.routes;

    public createMenuItems = [
        { id: 'decompositions', title: 'Decompositions', color: 'primary' as const },
        { id: 'cooking', title: 'Cooking', color: 'primary' as const },
        { id: 'recycling', title: 'Recycling', color: 'primary' as const }
    ];

    public menuItems = [
        { id: 'download', title: 'Download', icon: 'export', color: 'primary'  as const },
        { id: 'print', title: 'Print', icon: 'print', color: 'primary' as const },
        { id: 'send', title: 'Send', icon: 'mail', color: 'primary' as const },
        { id: 'delete', title: 'Delete', icon: 'delete', color: 'warn' as const }
    ];

    constructor(private router: Router) { /** */ }

    public onMenuItemClick($event): void {
        this.menuClicked.emit($event.id);
    }

    public getColor(linkUrl: string) {
        return linkUrl === this.router.url ? 'primary' : 'common';
    }

    public onCreateMenuItemClick(event: { id: string }) {
        switch (event.id) {
            case 'decompositions':
                this.router.navigate([RoutingConfig.routes.warehouse.dcr.decompositions.edit.fullUrl]);
                break;
            case 'cooking':
                this.router.navigate([RoutingConfig.routes.warehouse.dcr.cookings.edit.fullUrl]);
                break;
            case 'recycling':
                this.router.navigate([RoutingConfig.routes.warehouse.dcr.recyclings.edit.fullUrl]);
                break;
        }
    }
}
