import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransfersService } from '../../services/business/transfers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransferDeleteAction, TransferDeleteFailAction, TransferDeleteResetAction, TransferDeleteSuccessAction } from './transfer-delete.actions';

export interface TransferDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<TransferDeleteStateModel>({
    name: 'transferDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class TransferDeleteState {
    constructor(private transfersService: TransfersService) { }

    @Action(TransferDeleteAction) async transferDelete(ctx: StateContext<TransferDeleteStateModel>, action: TransferDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.transfersService.deleteTransfer(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new TransferDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new TransferDeleteFailAction(error)), 0));
    }

    @Action(TransferDeleteSuccessAction) transferDeleteSuccess(ctx: StateContext<TransferDeleteStateModel>, _: TransferDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(TransferDeleteFailAction) transferDeleteFail(ctx: StateContext<TransferDeleteStateModel>, action: TransferDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransferDeleteResetAction) transferDeleteReset(ctx: StateContext<TransferDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
