// tslint:disable:no-namespace
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MediaCreateRequest } from '../../../../burns-ui-framework/shared/models/business/media/media-light-request.model';
import { MediaLight } from '../../../../burns-ui-framework/shared/models/business/media/media-light.model';

export class PosMediaEditАction {
    static readonly type = '[PosMediaEdit Page] Edit';

    constructor(public payload: { posUid: string, addedPhotos: MediaCreateRequest[], deletedPhotos: string[] }) { }
}

export class PosMediaEditSuccessAction {
    static readonly type = '[PosMediaEdit API] PosMediaEdit Success';

    constructor(public payload: { addedPhotos: MediaLight[], deletedPhotos: string[] }) { }
}

export class PosMediaEditFailAction {
    static readonly type = '[PosMediaEdit API] PosMediaEdit Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosMediaEditResetAction {
    static readonly type = '[PosMediaEdit Page] PosMediaEdit Reset';
}
