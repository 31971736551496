import { AfterViewChecked, ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface ITab {
    route: string;
    name: string;
    disableByPermission?: boolean;
}

@Component({
    selector: 'bvc-tabs',
    templateUrl: './bvc-tabs.component.html',
    styleUrls: ['./bvc-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcTabsComponent implements AfterViewChecked {
    @Input() tabs: ITab[];

    public ngAfterViewChecked() {
        this.scrollTo();
    }

    public trackByTabs(_, item: ITab) {
        return item.route;
    }

    public scrollTo() {
        if (document) {
            const cardElement = document.querySelector('.card.active');
            if (cardElement) {
                cardElement.scrollIntoView({ behavior: 'auto' });
            }
        }
    }
}
