import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapCreateRequest } from '../../models/business/technological-maps/technological-map-create-request.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export class TechnologicalMapCreateAction {
    static readonly type = '[TechnologicalMaps Page] CreateTechnologicalMap';

    constructor(public payload: TechnologicalMapCreateRequest) { }
}

export class TechnologicalMapCreateSuccessAction {
    static readonly type = '[TechnologicalMap API] CreateTechnologicalMap Success';

    constructor(public payload: TechnologicalMapListItemAggregation) { }
}

export class TechnologicalMapCreateFailAction {
    static readonly type = '[TechnologicalMap API] CreateTechnologicalMap Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapCreateResetAction {
    static readonly type = '[TechnologicalMaps Page] CreateTechnologicalMap Reset';
}
