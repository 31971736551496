import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilterData } from '../../models/filters/products-list-get-filter.model';

import { ProductsSearchState, ProductsSearchStateModel } from './products-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class ProductsSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.pending);

    static readonly getProductsSearch = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.error);

    static readonly getAllProductsSearchLoaded = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.filter);

    static readonly getTotal = createSelector([ProductsSearchState], (state: ProductsSearchStateModel) => state.total);

    @Select(ProductsSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(ProductsSearchSelectors.getProductsSearch) products$: Observable<ProductListItemAggregation[]>;

    @Select(ProductsSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(ProductsSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(ProductsSearchSelectors.getAllProductsSearchLoaded) allProductsSearchLoaded$: Observable<boolean>;

    @Select(ProductsSearchSelectors.getFilter) filter$: Observable<ProductsListGetFilterData>;

    @Select(ProductsSearchSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('ProductsSearchSelectors');
    }
}
