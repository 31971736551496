<section class="row-header">
    <div class="select">
        <exg-checkbox class="checkbox" (valueChange)="onCheckAll($event)"></exg-checkbox>
    </div>
    <bvc-filter-field class="number header"
                      *ngIf="listConfig[drcSortingFieldsEnum.Number]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.Number"
                      [name]="translations[drcSortingFieldsEnum.Number]"
                      [fieldName]="drcSortingFieldsEnum.Number"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="date header"
                      *ngIf="listConfig[drcSortingFieldsEnum.Date]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.Date"
                      [name]="translations[drcSortingFieldsEnum.Date]"
                      [fieldName]="drcSortingFieldsEnum.Date"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="products header"
                      *ngIf="listConfig[drcSortingFieldsEnum.Products]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.Products"
                      [name]="translations[drcSortingFieldsEnum.Products]"
                      [fieldName]="drcSortingFieldsEnum.Products"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="sum header"
                      *ngIf="listConfig[drcSortingFieldsEnum.Sum]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.Sum"
                      [name]="translations[drcSortingFieldsEnum.Sum]"
                      [fieldName]="drcSortingFieldsEnum.Sum"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="difference-amount header"
                      *ngIf="listConfig[drcSortingFieldsEnum.DifferenceAmount]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.DifferenceAmount"
                      [name]="translations[drcSortingFieldsEnum.DifferenceAmount]"
                      [fieldName]="drcSortingFieldsEnum.DifferenceAmount"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="unit-type header"
                      *ngIf="listConfig[drcSortingFieldsEnum.UnitType]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.UnitType"
                      [name]="translations[drcSortingFieldsEnum.UnitType]"
                      [fieldName]="drcSortingFieldsEnum.UnitType"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="warehouse-from header"
                      *ngIf="listConfig[drcSortingFieldsEnum.WarehouseFrom]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.WarehouseFrom"
                      [name]="translations[drcSortingFieldsEnum.WarehouseFrom]"
                      [fieldName]="drcSortingFieldsEnum.WarehouseFrom"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="warehouse-to header"
                      *ngIf="listConfig[drcSortingFieldsEnum.WarehouseTo]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.WarehouseTo"
                      [name]="translations[drcSortingFieldsEnum.WarehouseTo]"
                      [fieldName]="drcSortingFieldsEnum.WarehouseTo"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="notes header"
                      *ngIf="listConfig[drcSortingFieldsEnum.Notes]"
                      [class.active]="filter.sortField === drcSortingFieldsEnum.Notes"
                      [name]="translations[drcSortingFieldsEnum.Notes]"
                      [fieldName]="drcSortingFieldsEnum.Notes"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <div class="menu header">
        <exg-button styleType="icon" width="4.8rem" height="4.8rem" class="arrow-up" (btnClick)="openedMenu = !openedMenu">
            <exg-icon class="gear_icon" iconName="gear"></exg-icon>
        </exg-button>
    </div> 

</section>
<exg-drop-menu class="drop-menu" [active]="openedMenu" (closeMenu)="openedMenu = false">
    <bvc-paging-menu [listConfig]="listConfig"
                     [enumArray]="enumArray"
                     [pageSize]="filter.pageSize"
                     [translations]="translations"
                     (pageSizeChanged)="onPageSizeChanged($event)"
                     (listConfigChanged)="onListConfigChanged($event)"></bvc-paging-menu>
</exg-drop-menu>