import { DocumentModel } from './document.model';
import { FeedType } from './feed-type.enum';
import { LinkedUser } from '../user/linked-user.model';
import { TaskStatus } from '../tasks/task-status.enum';

export class Feed {
    public uid: string;
    public title: string;
    public text: string;
    public type: FeedType;
    public taskStatus?: TaskStatus;
    public responsibleUser: LinkedUser;
    public dueDate: number;
    public details: any;
    public documents: DocumentModel[];
    public createDate: number;
}
