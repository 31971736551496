import { ErrorListGetFilterData } from '../../models/filters/error-list-get-filter.model';
import { ErrorListItem } from '../../models/business/orders-errors/error-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrdersErrorsHeaderAction {
    static readonly type = '[Header Component] GetOrdersErrorsHeader';

    constructor(public payload: ErrorListGetFilterData) { }
}

export class OrdersErrorsHeaderSuccessAction {
    static readonly type = '[OrdersErrors API] GetOrdersErrorsHeader Success';

    constructor(public payload: ErrorListItem[]) { }
}

export class OrdersErrorsHeaderFailAction {
    static readonly type = '[OrdersErrors API] GetOrdersErrorsHeader Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrdersErrorsHeaderResetAction {
    static readonly type = '[Header Component] GetOrdersErrorsHeader Reset';
}
