import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FeedListGetFilterData } from '../../models/filters/feed-list-get-filter.model';
import { Feed } from '../../models/business/feed/feed.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CompanyFeedAction {
    static readonly type = '[CompanyFeed Page] GetCompanyFeed';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyFeedSuccessAction {
    static readonly type = '[CompanyFeed API] GetCompanyFeed Success';

    constructor(public payload: { list: PaginationResult<Feed>, pagination: PaginationRequest }) { }
}

export class CompanyFeedFailAction {
    static readonly type = '[CompanyFeed API] GetCompanyFeed Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyFeedResetAction {
    static readonly type = '[CompanyFeed Page] GetCompanyFeed Reset';
}

export class CompanyFeedSetFilterAction {
    static readonly type = '[CompanyFeed API] SetFilter';

    constructor(public payload: FeedListGetFilterData) { }
}
