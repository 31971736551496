import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Franchisee } from '../../models/business/franchisees/franchisee.model';

export class FranchiseeAction {
    static readonly type = '[Franchisee Page] Franchisee';

    constructor(public payload: { uid: string }) { }
}

export class FranchiseeSuccessAction {
    static readonly type = '[Franchisee API] Franchisee Success';

    constructor(public payload: Franchisee) { }
}

export class FranchiseeFailAction {
    static readonly type = '[Franchisee API] Franchisee Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeResetAction {
    static readonly type = '[Franchisee Page] Franchisee Reset';
}
