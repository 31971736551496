import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PriceTypeListGetFilter } from '../../models/filters/price-type-list-get-filter.model';
import { PriceType } from '../../models/business/pricelist/price-type.model';
import { PricelistCreateRequest } from '../../models/business/pricelist/pricelist-create-request.model';
import { PricelistListGetFilter } from '../../models/filters/pricelist-list-get-filter.model';
import { PricelistListItem } from '../../models/business/pricelist/pricelist-list-item.model';
import { PricelistStatusUpdateRequest } from '../../models/business/pricelist/pricelist-status-update-request.model';
import { PricelistUpdateRequest } from '../../models/business/pricelist/pricelist-update-request.model';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

import { GuidUtils } from '../../../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../../../burns-ui-framework/shared/utils/string';


@Injectable({
    providedIn: 'root'
})
export class PricelistService extends BaseSingletonService {

    private settings: {
        service: {
            getPricelist: string;
            getWorkbook: string;
            getActivePriceList: string;
            getPricelistList: string;
            getPriceTypeList: string;
            createPricelist: string;
            updatePricelist: string;
            updatePricelistStatus: string;
            deletePricelist: string;
        }
    };

    constructor(private http: HttpService) {
        super('PricelistService');
        this.settings = {
            service: {
                getPricelist: '/warehouses-aggregation/v1/price-lists/{0}',
                getWorkbook: '/warehouses-aggregation/v1/price-lists/{0}/workbook',
                getActivePriceList: '/warehouses-aggregation/v1/price-lists/active',
                getPricelistList: '/warehouses-aggregation/v1/price-lists?pricelistType={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                getPriceTypeList: '/warehouses/v1/price-lists/price-types?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                createPricelist: '/warehouses-aggregation/v1/price-lists',
                updatePricelist: '/warehouses/v1/price-lists/{0}',
                updatePricelistStatus: '/warehouses/v1/price-lists/{0}/status',
                deletePricelist: '/warehouses/v1/price-lists/{0}'
            }
        };
    }

    public async getPricelist(uid: string): Promise<Pricelist> {
        return this.http.get<{ data: Pricelist }>(String.format(this.settings.service.getPricelist, uid))
            .then((res) => ({ ...res.data, productPrices: res.data.productPrices.map(x => ({ ...x, uid: GuidUtils.newGuid })) }));
    }

    public async getWorkbook(uid: string, fileName: string): Promise<Blob> {
        return this.http.download(String.format(this.settings.service.getWorkbook, uid), fileName);
    }

    public async getActivePriceList(): Promise<Pricelist> {
        return this.http.get<{ data: Pricelist }>(String.format(this.settings.service.getActivePriceList))
            .then((res) => ({ ...res.data, productPrices: res.data.productPrices.map(x => ({ ...x, uid: GuidUtils.newGuid })) }));
    }

    public async getPricelistList(filter: PricelistListGetFilter): Promise<PaginationResult<PricelistListItem>> {
        return this.http.get<PaginationResult<PricelistListItem>>(String.format(this.settings.service.getPricelistList, filter.pricelistType, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getPriceTypeList(filter: PriceTypeListGetFilter): Promise<PaginationResult<PriceType>> {
        return this.http.get<PaginationResult<PriceType>>(String.format(this.settings.service.getPriceTypeList, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createPricelist(request: PricelistCreateRequest): Promise<Pricelist> {
        return this.http.post<{ data: Pricelist }>(String.format(this.settings.service.createPricelist), request)
            .then(res => res.data);
    }

    public async updatePricelist(uid: string, request: PricelistUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updatePricelist, uid), request);
    }

    public async updatePricelistStatus(uid: string, request: PricelistStatusUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updatePricelistStatus, uid), request);
    }

    public async deletePricelist(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePricelist, uid));
    }
}
