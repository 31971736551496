import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { CorporationRequisitesCreateOrUpdateRequest } from '../../models/business/corporation-requisites/corporation-requisites-create-or-update.model';

export class CorporationRequisiteUpdateAction {
    static readonly type = '[CorporationRequisite Page] UpdateCorporationRequisite';

    constructor(public payload: { uid: string, organizationUid: string, request: CorporationRequisitesCreateOrUpdateRequest }) { }
}

export class CorporationRequisiteUpdateSuccessAction {
    static readonly type = '[CorporationRequisite API] UpdateCorporationRequisite Success';

    constructor(public payload: { uid: string, organizationUid: string, request: CorporationRequisitesCreateOrUpdateRequest }) { }
}

export class CorporationRequisiteUpdateFailAction {
    static readonly type = '[CorporationRequisite API] UpdateCorporationRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationRequisiteUpdateResetAction {
    static readonly type = '[CorporationRequisite Page] UpdateCorporationRequisite Reset';
}
