import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContragentDeleteAction, ContragentDeleteFailAction, ContragentDeleteResetAction, ContragentDeleteSuccessAction } from './contragent-delete.actions';

export interface ContragentDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<ContragentDeleteStateModel>({
    name: 'contragentDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class ContragentDeleteState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(ContragentDeleteAction) async contragentDelete(ctx: StateContext<ContragentDeleteStateModel>, action: ContragentDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.contragentsService.deleteContragent(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new ContragentDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ContragentDeleteFailAction(error)), 0));
    }

    @Action(ContragentDeleteSuccessAction) contragentDeleteSuccess(ctx: StateContext<ContragentDeleteStateModel>, _: ContragentDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(ContragentDeleteFailAction) contragentDeleteFail(ctx: StateContext<ContragentDeleteStateModel>, action: ContragentDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContragentDeleteResetAction) contragentDeleteReset(ctx: StateContext<ContragentDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
