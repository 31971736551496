
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PersonnelDeleteAction {
    static readonly type = '[My Personnel Page] DeletePersonnel';

    constructor(public payload: { uid: string }) { }
}

export class PersonnelDeleteSuccessAction {
    static readonly type = '[My Personnels API] DeletePersonnel Success';

    constructor(public payload: { uid: string }) { }
}

export class PersonnelDeleteFailAction {
    static readonly type = '[My Personnels API] DeletePersonnel Fail';

    constructor(public payload: ErrorObject) { }
}

export class PersonnelDeleteResetAction {
    static readonly type = '[My Personnel Page] DeletePersonnel Reset';
}
