import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerPosFailAction, CustomerPosGetAction, CustomerPosGetSuccessAction, CustomerPosResetAction } from './customer-pos.actions';
import { Pos } from '../../models/business/pos/pos.model';

export interface CustomerPosStateModel {
    entity: Pos;
    pending: boolean;
    error: ErrorObject;
}

@State<CustomerPosStateModel>({
    name: 'customerPos',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CustomerPosState {
    constructor(private posService: PosService) { }

    @Action(CustomerPosGetAction) posGet(ctx: StateContext<CustomerPosStateModel>, action: CustomerPosGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.posService.getPos(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CustomerPosGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomerPosFailAction(err)), 0));
    }

    @Action(CustomerPosGetSuccessAction) posGetSuccess(ctx: StateContext<CustomerPosStateModel>, action: CustomerPosGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CustomerPosFailAction) posGetFail(ctx: StateContext<CustomerPosStateModel>, action: CustomerPosFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomerPosResetAction) posGetReset(ctx: StateContext<CustomerPosStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
