import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RestaurantProductService } from '../../services/business/restaurant-product.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { RestaurantProductsAction, RestaurantProductsFailAction, RestaurantProductsResetAction, RestaurantProductsSetFilterAction, RestaurantProductsSuccessAction } from './restaurant-products.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantProductListItem } from '../../models/business/restaurant-products/restaurant-product-list-item.model';
import { RestaurantProductListGetFilter, RestaurantProductListGetFilterData } from '../../models/filters/restaurant-product-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface RestaurantProductsStateModel extends PaginationStateModel<RestaurantProductListItem> {
    uids: string[];
    entities: { [uid: string]: RestaurantProductListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: RestaurantProductListGetFilterData;
    total: number;
}

@State<RestaurantProductsStateModel>({
    name: 'restaurantProducts',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { }
    }
})
@Injectable()
export class RestaurantProductsState extends PaginationStateBase {
    constructor(private productService: RestaurantProductService) {
        super();
    }

    @Action([RestaurantProductsAction]) restaurantProductsGet(ctx: StateContext<RestaurantProductsStateModel>, action: RestaurantProductsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new RestaurantProductListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.posesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.productService.getRestaurantProductList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new RestaurantProductsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RestaurantProductsFailAction(err)), 0));
    }

    @Action(RestaurantProductsSuccessAction) restaurantProductsGetSuccess(ctx: StateContext<RestaurantProductsStateModel>, action: RestaurantProductsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.posesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(RestaurantProductsFailAction) restaurantProductsGetFail(ctx: StateContext<RestaurantProductsStateModel>, action: RestaurantProductsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RestaurantProductsResetAction) restaurantProductsGetReset(ctx: StateContext<RestaurantProductsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(RestaurantProductsSetFilterAction) usersSetFilter(ctx: StateContext<RestaurantProductsStateModel>, action: RestaurantProductsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new RestaurantProductsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
