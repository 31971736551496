import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';

import { DialogResult } from '../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogButton } from '../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-button.model';
import { ExgDialogResultEvent } from '../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { IExgDialogable } from '../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialogable.interface';

@Component({
    selector: 'dcr-notes-from',
    templateUrl: './dcr-notes-from.component.html',
    styleUrls: ['./dcr-notes-from.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DcrNotesFormComponent implements IExgDialogable {

    public notes: string;

    private proceedClose: (_: ExgDialogResultEvent) => void;

    constructor(private injector: Injector) {
        this.notes = this.injector.get('notes');
    }

    public exgDialogClose(_: ExgDialogButton): any {
        /** no need */
    }

    public onCloseClick() {
        this.proceedClose({ dialogResult: DialogResult.Close });
    }

    public registerOnDialogClose(fn: (_: ExgDialogResultEvent) => void): void {
        this.proceedClose = fn;
    }

    public onValueChanged($event) {
        this.notes = $event;
    }

    public onAddClick() {
        this.proceedClose({ dialogResult: DialogResult.Ok, dataFromComponent: { notes: this.notes } });
    }
}
