import { WeekDay } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { WorkSchedule } from '../../../models/business/pos/work-schedule.model';

@Component({
    selector: 'bvc-work-schedule',
    templateUrl: './bvc-work-schedule.component.html',
    styleUrls: ['./bvc-work-schedule.component.scss']
})
export class BvcWorkScheduleComponent implements OnChanges {

    @Input() workSchedule: WorkSchedule;

    @Output() readonly valueChanged = new EventEmitter<WorkSchedule>();

    public days = [
        { name: 'Mon', wkDay: WeekDay.Monday },
        { name: 'Tue', wkDay: WeekDay.Tuesday },
        { name: 'Wed', wkDay: WeekDay.Wednesday },
        { name: 'Thu', wkDay: WeekDay.Thursday },
        { name: 'Fri', wkDay: WeekDay.Friday },
        { name: 'Sat', wkDay: WeekDay.Saturday },
        { name: 'Sun', wkDay: WeekDay.Sunday }
    ];

    public weekdays = false;
    public weekends = false;
    public daily = false;

    public weekDays: WeekDay[];

    public weekdaysArray: WeekDay[];
    public weekendsArray: WeekDay[];
    public dailyArray: WeekDay[] = [];

    public work: { begin: number, end: number };
    public break: { begin: number, end: number };

    constructor() {
        this.weekdaysArray = [WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday];
        this.weekendsArray = [WeekDay.Saturday, WeekDay.Sunday];
        this.dailyArray = this.weekdaysArray.concat(this.weekendsArray);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.workSchedule) {
            this.weekDays = this.workSchedule && this.workSchedule.days ? [...this.workSchedule.days] : [];
            this.work = this.workSchedule && this.workSchedule.work ? { ...this.workSchedule.work } : { begin: null, end: null };
            this.break = this.workSchedule && this.workSchedule.break ? { ...this.workSchedule.break } : { begin: null, end: null };
            this.setWeekState();
        }
    }

    public isWeekActive(weekDay: WeekDay) {
        return this.weekDays ? this.weekDays.findIndex(day => day === weekDay) !== -1 : false;
    }

    public pickOneDay(dayNumber: number) {
        const weekDayIndex = this.weekDays.findIndex(day => day === this.days[dayNumber].wkDay);
        if (weekDayIndex === -1) {
            this.weekDays.push(this.days[dayNumber].wkDay);
        } else {
            this.weekDays.splice(weekDayIndex, 1);
        }

        this.setWeekState();
        this.onValueChanges();
    }

    public pickDays(days: 'Weekdays' | 'Weekends' | 'Daily') {
        this.resetDays();
        if (days === 'Weekdays') {
            this.weekdaysArray.forEach(item => this.weekDays.push(item));
            this.weekdays = true;
        }
        if (days === 'Weekends') {
            this.weekendsArray.forEach(item => this.weekDays.push(item));
            this.weekends = true;
        }
        if (days === 'Daily') {
            this.dailyArray.forEach(item => this.weekDays.push(item));
            this.daily = true;
        }

        this.onValueChanges();
    }

    public resetDays() {
        this.weekDays.length = 0;

        this.weekdays = false;
        this.weekends = false;
        this.daily = false;
    }

    public onTimeChanged(timeRange, timePostion, time) {
        if (timeRange === 'work') {
            this.work[timePostion] = time;
        } else if (timeRange === 'break') {
            this.break[timePostion] = time;
        }

        this.onValueChanges();
    }

    public onValueChanges() {
        const request = {
            days: this.weekDays,
            work: this.work,
            break: this.break
        };

        this.valueChanged.emit(request);
    }

    private isWeekDaysIn(weekDaysArray: WeekDay[]) {
        return this.weekDays.length === weekDaysArray.length && this.weekDays.every(weekday => weekDaysArray.indexOf(weekday) !== -1);
    }

    private setWeekState() {
        this.weekdays = this.isWeekDaysIn(this.weekdaysArray);
        this.weekends = this.isWeekDaysIn(this.weekendsArray);
        this.daily = this.weekDays.length === 7;
    }
}
