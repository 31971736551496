export enum CompanyType {
    Dealership = 1,

    InstallationCenter = 2,

    InternetShop = 4,

    Representation = 5,

    Dealer = 7,

    Corporate = 8,

    RetailTrade = 9,

    Supplier = 10,

    WholesaleClient = 11,

    Retail = 12
}
