import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { InventoriesService } from '../../services/business/inventories.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { InventoryDeleteAction, InventoryDeleteFailAction, InventoryDeleteResetAction, InventoryDeleteSuccessAction } from './inventory-delete.actions';

export interface InventoryDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<InventoryDeleteStateModel>({
    name: 'inventoryDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class InventoryDeleteState {
    constructor(private inventoriesService: InventoriesService) { }

    @Action(InventoryDeleteAction) async inventoryDelete(ctx: StateContext<InventoryDeleteStateModel>, action: InventoryDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.inventoriesService.deleteInventory(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new InventoryDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new InventoryDeleteFailAction(error)), 0));
    }

    @Action(InventoryDeleteSuccessAction) inventoryDeleteSuccess(ctx: StateContext<InventoryDeleteStateModel>, _: InventoryDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(InventoryDeleteFailAction) inventoryDeleteFail(ctx: StateContext<InventoryDeleteStateModel>, action: InventoryDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(InventoryDeleteResetAction) inventoryDeleteReset(ctx: StateContext<InventoryDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
