import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ContactCreateOrUpdateRequest } from '../../models/business/contacts/contact-create-or-update.model';
import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { Contact } from '../../models/business/contacts/contact.model';
import { ContactsListFilter } from '../../models/filters/contacts-list-filter.model';
import { MotivationBonusListItem } from '../../models/business/contacts/motivation-bonus-list-item.model';
import { MotivationBonusProduct } from '../../models/business/contacts/motivation-bonus-product.model';
import { MotivationBonus } from '../../models/business/contacts/motivation-bonus.model';
import { MotivationBonusesCreateOrUpdateRequest } from '../../models/business/contacts/motivation-bonuses-create-or-update-request.model';
import { MotivationBonusesListGetFilter } from '../../models/filters/motivation-bonuses-list-get-filter.model';
import { MotivationBonusesProductsListGetFilter } from '../../models/filters/motivation-bonuses-products-list-get-filter.model';
import { MotivationBonusesUpdateListRequest } from '../../models/business/contacts/motivation-bonuses-update-list-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { GuidUtils } from '../../../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ContactsService extends BaseSingletonService {

    private settings: {
        service: {
            getContact: string;
            getContactList: string;
            createContact: string;
            updateContact: string;
            deleteContact: string;

            getMotivation: string;
            getMotivationList: string;
            getMotivationProductsList: string;
            createMotivation: string;
            updateMotivation: string;
            updateMotivationDocument: string;
            updateMotivationList: string;
            deleteMotivation: string;
        }
    };

    constructor(private http: HttpService) {
        super('ContactsService');
        this.settings = {
            service: {
                getContact: '/crm/v1/contacts/{0}',
                getContactList: '/crm/v1/contacts?term={0}&companyUid={1}&contragentUid={2}&posUid={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                createContact: '/crm/v1/contacts',
                updateContact: '/crm/v1/contacts/{0}',
                deleteContact: '/crm/v1/contacts/{0}',

                getMotivation: '/crm/v1/contacts/{0}/motivation-bonuses/{1}',
                getMotivationList: '/crm/v1/contacts/motivation-bonuses?companyUid={0}&contragentUid={1}&posUid={2}&contactUid={3}&userUid={4}&sortField={5}&sortOrder={6}&pageIndex={7}&pageSize={8}',
                getMotivationProductsList: '/crm/v1/contacts/motivation-bonuses/products?contactUid={0}&bonusesDate={1}&contragentUid={2}&posUid={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                createMotivation: '/crm/v1/contacts/{0}/motivation-bonuses',
                updateMotivation: '/crm/v1/contacts/{0}/motivation-bonuses/{1}',
                updateMotivationDocument: '/crm/v1/contacts/{0}/motivation-bonuses/{1}/documents',
                updateMotivationList: '/crm/v1/contacts/{0}/motivation-bonuses',
                deleteMotivation: '/crm/v1/contacts/{0}/motivation-bonuses/{1}'
            }
        };
    }

    public async getContact(uid: string): Promise<Contact> {
        return this.http.get<{ data: Contact }>(String.format(this.settings.service.getContact, uid))
            .then(res => res.data);
    }

    public async getContactList(filter: ContactsListFilter): Promise<PaginationResult<ContactListItem>> {
        return this.http.get<PaginationResult<ContactListItem>>(String.format(this.settings.service.getContactList, filter.term, filter.companyUid, filter.contragentUid, filter.posUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createContact(request: ContactCreateOrUpdateRequest): Promise<Contact> {
        return this.http.post<{ data: Contact }>(String.format(this.settings.service.createContact), request)
            .then(res => res.data);
    }

    public async updateContact(uid: string, request: ContactCreateOrUpdateRequest): Promise<Contact> {
        return this.http.put(String.format(this.settings.service.updateContact, uid), request);
    }

    public async deleteContact(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteContact, uid));
    }

    public async getMotivation(contactUid: string, motivationUid: string): Promise<MotivationBonus> {
        return this.http.get<{ data: MotivationBonus }>(String.format(this.settings.service.getMotivation, contactUid, motivationUid))
            .then(res => res.data);
    }

    public async getMotivationList(filter: MotivationBonusesListGetFilter): Promise<PaginationResult<MotivationBonusListItem>> {
        return this.http.get<PaginationResult<MotivationBonusListItem>>(String.format(this.settings.service.getMotivationList, filter.companyUid, filter.contragentUid, filter.posUid, filter.contactUid, filter.userUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getMotivationProductsList(filter: MotivationBonusesProductsListGetFilter): Promise<PaginationResult<MotivationBonusProduct>> {
        return this.http.get<PaginationResult<MotivationBonusProduct>>(String.format(this.settings.service.getMotivationProductsList, filter.contactUid, filter.bonusesDate, filter.contragentUid, filter.posUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                res.list = res.list.map(x => ({ ...x, uid: GuidUtils.newGuid }));
                return res;
            });
    }

    public async createMotivation(contactUid: string, request: MotivationBonusesCreateOrUpdateRequest): Promise<MotivationBonus> {
        return this.http.post<{ data: MotivationBonus }>(String.format(this.settings.service.createMotivation, contactUid), request)
            .then(res => res.data);
    }

    public async updateMotivation(contactUid: string, motivationUid: string, request: MotivationBonusesCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateMotivation, contactUid, motivationUid), request);
    }

    public async updateMotivationList(contactUid: string, request: MotivationBonusesUpdateListRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateMotivationList, contactUid), request);
    }

    public async deleteMotivation(contactUid: string, motivationUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteMotivation, contactUid, motivationUid));
    }

    public async updateMotivationDocument(contactUid: string, motivationUid: string, request: any): Promise<string> {
        const formData = new FormData();

        for (const file of request) {
            formData.append(file.name, file);
        }

        return this.http.upload<{ data: string }>(String.format(this.settings.service.updateMotivationDocument, contactUid, motivationUid), formData)
            .then(x => x.data);
    }
}
