export enum YeOrderSortingFieldsEnum {
    Number = 'Number',
    CreateDate = 'CreateDate',
    PrepareDateTo = 'PrepareDateTo',
    ContactFullName = 'ContactFullName',
    ShippingMethod = 'ShippingMethod',
    ShippingAddress = 'ShippingAddress',
    Amount = 'Amount',
    PaymentType = 'PaymentType',
    ShippingCity = 'ShippingCity',
    Source = 'Source',
    Status = 'Status',
}
