import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export class TechnologicalMapArchivesAction {
    static readonly type = '[TechnologicalMapArchives Page] GetTechnologicalMapArchives';

    constructor(public payload: PaginationRequest) { }
}

export class TechnologicalMapArchivesSuccessAction {
    static readonly type = '[TechnologicalMapArchives API] GetTechnologicalMapArchives Success';

    constructor(public payload: { list: PaginationResult<TechnologicalMapListItemAggregation>, pagination: PaginationRequest }) { }
}

export class TechnologicalMapArchivesFailAction {
    static readonly type = '[TechnologicalMapArchives API] GetTechnologicalMapArchives Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapArchivesResetAction {
    static readonly type = '[TechnologicalMapArchives Page] GetTechnologicalMapArchives Reset';
}

export class TechnologicalMapArchivesSetFilterAction {
    static readonly type = '[TechnologicalMapArchives API] SetFilter';

    constructor(public payload: TechnologicalMapListGetFilterData) { }
}
