import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Task } from '../../models/business/tasks/task.model';

export class TaskGetAction {
    static readonly type = '[Task Page] GetTask Get';

    constructor(public payload: string) { }
}

export class TaskGetSuccessAction {
    static readonly type = '[Task API] GetTask Success';

    constructor(public payload: Task) { }
}

export class TaskFailAction {
    static readonly type = '[Task API] GetTask Fail';

    constructor(public payload: ErrorObject) { }
}

export class TaskResetAction {
    static readonly type = '[Task Page] GetTask Reset';
}
