import { AcceptanceCreateState } from './acceptances/acceptance-create.reducer';
import { AcceptanceDeleteState } from './acceptances/acceptance-delete.reducer';
import { AcceptanceUpdateState } from './acceptances/acceptance-update.reducer';
import { AcceptanceState } from './acceptances/acceptance.reducer';
import { AcceptancesState } from './acceptances/acceptances.reducer';
import { ActivePricelistState } from './pricelist/active-pricelist.reducer';
import { AttributeGroupsState } from './nomenclature/attribute-groups.reducer';
import { BillingTransactionsState } from './billing-transactions/billing-transactions.reducer';
import { BrandCreateState } from './brands/brand-create.reducer';
import { BrandDeleteImageState } from './brands/brand-delete-image.reducer';
import { BrandDeleteState } from './brands/brand-delete.reducer';
import { BrandUpdateState } from './brands/brand-update.reducer';
import { BrandState } from './brands/brand.reducer';
import { BrandsSearchState } from './brands/brands-search.reducer';
import { BrandsState } from './brands/brands.reducer';
import { CategoriesSearchState } from './catalog/categories-search.reducer';
import { CategoriesState } from './catalog/categories.reducer';
import { CategoryCreateState } from './catalog/category-create.reducer';
import { CategoryDeleteState } from './catalog/category-delete.reducer';
import { CategoryUpdateState } from './catalog/category-update.reducer';
import { CategoryState } from './catalog/category.reducer';
import { CharacteristicsGroupsState } from './characteristics/characteristics-groups.reducer';
import { CharacteristicsSearchState } from './characteristics/characteristics-search.reducer';
import { CompaniesSearchState } from './companies/companies-search.reducer';
import { CompaniesState } from './companies/companies.reducer';
import { CompaniesUsersState } from './users/companies.reducer';
import { CompanyCityState } from './companies/company-city.reducer';
import { CompanyContactsState } from './companies/company-contacts.reducer';
import { CompanyContractsState } from './companies/company-contracts.reducer';
import { CompanyContragentsState } from './companies/company-contragents.reducer';
import { CompanyCreateState } from './companies/company-create.reducer';
import { CompanyDeleteState } from './companies/company-delete.reducer';
import { CompanyFeedState } from './companies/company-feed.reducer';
import { CompanyOrdersState } from './companies/company-orders.reducer';
import { CompanyPosState } from './companies/company-pos.reducer';
import { CompanyUpdateState } from './companies/company-update.reducer';
import { CompanyUsersState } from './companies/company-users.reducer';
import { CompanyState } from './companies/company.reducer';
import { ContactCompanyState } from './contacts/contact-company.reducer';
import { ContactContragentState } from './contacts/contact-contragent.reducer';
import { ContactCreateState } from './contacts/contact-create.reducer';
import { ContactDeleteState } from './contacts/contact-delete.reducer';
import { ContactPosState } from './contacts/contact-pos.reducer';
import { ContactUpdateState } from './contacts/contact-update.reducer';
import { ContactState } from './contacts/contact.reducer';
import { ContactsSearchState } from './contacts/contacts-search.reducer';
import { ContactsState } from './contacts/contacts.reducer';
import { ContractCreateState } from './contracts/contract-create.reducer';
import { ContractDeleteState } from './contracts/contract-delete.reducer';
import { ContractEditDocumentState } from './contracts/contract-edit-document.reducer';
import { ContractUpdateState } from './contracts/contract-update.reducer';
import { ContractState } from './contracts/contract.reducer';
import { ContractsState } from './contracts/contracts.reducer';
import { ContragentCompanyState } from './contragents/contragent-company.reducer';
import { ContragentContactsState } from './contragents/contragent-contacts.reducer';
import { ContragentCreateState } from './contragents/contragent-create.reducer';
import { ContragentDeleteState } from './contragents/contragent-delete.reducer';
import { ContragentOrdersState } from './contragents/contragent-orders.reducer';
import { ContragentPosState } from './contragents/contragent-pos.reducer';
import { ContragentSuggestionState } from './contragents/contragent-suggestion.reducer';
import { ContragentUpdateState } from './contragents/contragent-update.reducer';
import { ContragentState } from './contragents/contragent.reducer';
import { ContragentsSearchState } from './contragents/contragents-search.reducer';
import { ContragentsState } from './contragents/contragents.reducer';
import { CorporationRequisiteCreateState } from './corporation-requisites/corporation-requisite-create.reducer';
import { CorporationRequisiteDeleteState } from './corporation-requisites/corporation-requisite-delete.reducer';
import { CorporationRequisiteUpdateState } from './corporation-requisites/corporation-requisite-update.reducer';
import { CorporationRequisiteState } from './corporation-requisites/corporation-requisite.reducer';
import { CorporationRequisitesState } from './corporation-requisites/corporation-requisites.reducer';
import { ProductsCountriesState } from './catalog/countries.reducer';
import { CustomerCompanyState } from './customers/customer-company.reducer';
import { CustomerContragentState } from './customers/customer-contragent.reducer';
import { CustomerCreateState } from './customers/customer-create.reducer';
import { CustomerDeleteState } from './customers/customer-delete.reducer';
import { CustomerPosState } from './customers/customer-pos.reducer';
import { CustomerUpdateState } from './customers/customer-update.reducer';
import { CustomerState } from './customers/customer.reducer';
import { CustomersSearchState } from './customers/customers-search.reducer';
import { CustomersState } from './customers/customers.reducer';
import { DeliveryProductsState } from './products/delivery-products.reducer';
import { FranchiseeCreateState } from './franchisees/franchisee-create.reducer';
import { FranchiseeDeleteState } from './franchisees/franchisee-delete.reducer';
import { FranchiseeOrganizationsState } from './franchisees/franchisee-organizations.reducer';
import { FranchiseeRestaurantsState } from './franchisees/franchisee-restaurants.reducer';
import { FranchiseeTypeUpdateState } from './franchisee-types/franchisee-type-update.reducer';
import { FranchiseeTypesState } from './franchisee-types/franchisee-types.reducer';
import { FranchiseeUpdateState } from './franchisees/franchisee-update.reducer';
import { FranchiseeState } from './franchisees/franchisee.reducer';
import { FranchiseesState } from './franchisees/franchisees.reducer';
import { FullNameSuggestionState } from './suggestions/fullname-suggestion.reducer';
import { GroupsState } from './nomenclature/groups.reducer';
import { IntervalUpdateState } from './intervals/interval-update.reducer';
import { IntervalsAvailableListSearchState } from './intervals/intervals-available-list-search.reducer';
import { IntervalsAvailableListState } from './intervals/intervals-available-list.reducer';
import { IntervalsState } from './intervals/intervals.reducer';
import { InventoriesState } from './inventories/inventories.reducer';
import { InventoryCreateState } from './inventories/inventory-create.reducer';
import { InventoryDeleteState } from './inventories/inventory-delete.reducer';
import { InventoryUpdateState } from './inventories/inventory-update.reducer';
import { InventoryWorkbookState } from './inventories/inventory-workbook.reducer';
import { InventoryState } from './inventories/inventory.reducer';
import { ListFromInventoryState } from './list-from-inventory/list-from-inventory.reducer';
import { ManagerAssistantsCreateState } from './manager-assistants/manager-assistant-create.reducer';
import { ManagerAssistantDeleteState } from './manager-assistants/manager-assistant-delete.reducer';
import { ManagerAssistantsState } from './manager-assistants/manager-assistants.reducer';
import { ModifierTypesState } from './modifiers/modifier-types.reducer';
import { ModifiersState } from './modifiers/modifiers.reducer';
import { MotivationBonusCreateState } from './motivation-bonus/motivation-bonus-create.reducer';
import { MotivationBonusDeleteState } from './motivation-bonus/motivation-bonus-delete.reducer';
import { MotivationBonusEditDocumentState } from './motivation-bonus/motivation-bonus-edit-document.reducer';
import { MotivationBonusUpdateListState } from './motivation-bonus/motivation-bonus-update-list.reducer';
import { MotivationBonusUpdateState } from './motivation-bonus/motivation-bonus-update.reducer';
import { MotivationBonusState } from './motivation-bonus/motivation-bonus.reducer';
import { MotivationBonusesCompaniesState } from './motivation-bonus/motivation-bonuses-companies.reducer';
import { MotivationBonusesContactsState } from './motivation-bonus/motivation-bonuses-contacts.reducer';
import { MotivationBonusesProductsState } from './motivation-bonus/motivation-bonuses-products.reducer';
import { MotivationBonusesState } from './motivation-bonus/motivation-bonuses.reducer';
import { NoteCreateState } from './notes/note-create.reducer';
import { NoteDeleteState } from './notes/note-delete.reducer';
import { NoteUpdateDocumentsState } from './notes/note-update-documents.reducer';
import { NoteUpdateState } from './notes/note-update.reducer';
import { NotesState } from './notes/notes.reducer';
import { OrderCreateState } from './orders/order-create.reducer';
import { OrderDeleteState } from './orders/order-delete.reducer';
import { OrderDiscountsState } from './orders/order-discounts.reducer';
import { OrderHistoryState } from './orders/order-history.reducer';
import { OrderUpdateStatusState } from './orders/order-update-status.reducer';
import { OrderUpdateState } from './orders/order-update.reducer';
import { OrderState } from './orders/order.reducer';
import { OrdersAnalyticsState } from './orders-analytics/orders-analytics.reducer';
import { OrdersErrorsHeaderState } from './orders-errors/orders-errors-header.reducer';
import { OrdersErrorsUnreadCountState } from './orders-errors/orders-errors-unread-count.reducer';
import { OrdersErrorsUpdateState } from './orders-errors/orders-errors-update.reducer';
import { OrdersErrorsState } from './orders-errors/orders-errors.reducer';
import { OrdersState } from './orders/orders.reducer';
import { OrganizationCreateState } from './organizations/organization-create.reducer';
import { OrganizationDeleteState } from './organizations/organization-delete.reducer';
import { OrganizationPosAreaCreateState } from './organization-pos-area/organization-pos-area-create.reducer';
import { OrganizationPosAreaDeleteState } from './organization-pos-area/organization-pos-area-delete.reducer';
import { OrganizationPosAreaRestrictionsState } from './organization-pos-area/organization-pos-area-restrictions.reducer';
import { OrganizationPosAreaUpdateState } from './organization-pos-area/organization-pos-area-update.reducer';
import { OrganizationPosAreaState } from './organization-pos-area/organization-pos-area.reducer';
import { OrganizationPosAreasState } from './organization-pos-area/organization-pos-areas.reducer';
import { OrganizationPosByCoordinatesState } from './organization-pos/organization-pos-by-coordinates.reducer';
import { OrganizationPosCreateState } from './organization-pos/organization-pos-create.reducer';
import { OrganizationPosDeleteState } from './organization-pos/organization-pos-delete.reducer';
import { OrganizationPosPaymentTypesState } from './organization-pos-payment-type/organization-pos-payment-types.reducer';
import { OrganizationPosUpdateState } from './organization-pos/organization-pos-update.reducer';
import { OrganizationPosState } from './organization-pos/organization-pos.reducer';
import { OrganizationPosesDetailedState } from './organization-pos/organization-poses-detailed.reducer';
import { OrganizationPosesSearchState } from './organization-pos/organization-poses-search.reducer';
import { OrganizationPosesState } from './organization-pos/organization-poses.reducer';
import { OrganizationTreeState } from './organization-tree/organization-tree.reducer';
import { OrganizationUpdateState } from './organizations/organization-update.reducer';
import { OrganizationState } from './organizations/organization.reducer';
import { OrganizationsSearchState } from './organizations/organizations-search.reducer';
import { OrganizationsState } from './organizations/organizations.reducer';
import { PersonnelCreateState } from './personnels/personnel-create.reducer';
import { PersonnelDeleteState } from './personnels/personnel-delete.reducer';
import { PersonnelUpdateState } from './personnels/personnel-update.reducer';
import { PersonnelState } from './personnels/personnel.reducer';
import { PersonnelsState } from './personnels/personnels.reducer';
import { PosCitiesState } from './pos-cities/pos-cities.reducer';
import { PosCompanySearchState } from './pos/pos-company-search.reducer';
import { PosContactsState } from './pos/pos-contacts.reducer';
import { PosContragentState } from './pos/pos-contragent.reducer';
import { PosCreateState } from './pos/pos-create.reducer';
import { PosDeleteState } from './pos/pos-delete.reducer';
import { PosListState } from './pos/pos-list.reducer';
import { PosMediaEditState } from './pos/pos-media-edit.reducer';
import { PosMediaListState } from './pos/pos-media-list.reducer';
import { PosOrdersState } from './pos/pos-orders.reducer';
import { PosSearchState } from './pos/pos-search.reducer';
import { PosUpdateState } from './pos/pos-update.reducer';
import { PosState } from './pos/pos.reducer';
import { PostingCreateState } from './postings/posting-create.reducer';
import { PostingDeleteState } from './postings/posting-delete.reducer';
import { PostingUpdateState } from './postings/posting-update.reducer';
import { PostingWorkbookState } from './postings/posting-workbook.reducer';
import { PostingState } from './postings/posting.reducer';
import { PostingsState } from './postings/postings.reducer';
import { PriceTypeListState } from './pricelist/price-type-list.reducer';
import { PricelistCreateState } from './pricelist/pricelist-create.reducer';
import { PricelistDeleteState } from './pricelist/pricelist-delete.reducer';
import { PricelistListState } from './pricelist/pricelist-list.reducer';
import { PricelistUpdateStatusState } from './pricelist/pricelist-update-status.reducer';
import { PricelistUpdateState } from './pricelist/pricelist-update.reducer';
import { PricelistWorkbookState } from './pricelist/pricelist-workbook.reducer';
import { PricelistState } from './pricelist/pricelist.reducer';
import { ProductCreateState } from './catalog/product-create.reducer';
import { ProductDeleteState } from './catalog/product-delete.reducer';
import { ProductImageDeleteState } from './catalog/product-image-delete.reducer';
import { ProductImageUpdateState } from './catalog/product-image-update.reducer';
import { ProductMediaEditState } from './product/product-media-edit.reducer';
import { ProductMediaListState } from './product/product-media-list.reducer';
import { ProductNomenclaturesState } from './product-nomenclatures/product-nomenclatures.reducer';
import { ProductTypesState } from './product-types/product-types.reducer';
import { ProductUpdateState } from './catalog/product-update.reducer';
import { ProductState } from './catalog/product.reducer';
import { ProductsBrandsState } from './catalog/products-brands.reducer';
import { ProductsSearchState } from './products/products-search.reducer';
import { ProductsState } from './products/products.reducer';
import { ProfileUpdateSettingsState } from './profile/profile-update-settings.reducer';
import { PublicCorporationCategoriesState } from './corporation-catalog/public-categories.reducer';
import { PublicCorporationProductsState } from './corporation-catalog/public-products.reducer';
import { PurchaseInvoiceCreateState } from './purchase-invoices/purchase-invoice-create.reducer';
import { PurchaseInvoiceDeleteState } from './purchase-invoices/purchase-invoice-delete.reducer';
import { PurchaseInvoiceUpdateState } from './purchase-invoices/purchase-invoice-update.reducer';
import { PurchaseInvoiceState } from './purchase-invoices/purchase-invoice.reducer';
import { PurchaseInvoicesState } from './purchase-invoices/purchase-invoices.reducer';
import { PurchaseOrderCreateState } from './purchase-orders/purchase-order-create.reducer';
import { PurchaseOrderDeleteState } from './purchase-orders/purchase-order-delete.reducer';
import { PurchaseOrderUpdateState } from './purchase-orders/purchase-order-update.reducer';
import { PurchaseOrderState } from './purchase-orders/purchase-order.reducer';
import { PurchaseOrdersState } from './purchase-orders/purchase-orders.reducer';
import { PurchaseReturnCreateState } from './purchase-returns/purchase-return-create.reducer';
import { PurchaseReturnDeleteState } from './purchase-returns/purchase-return-delete.reducer';
import { PurchaseReturnUpdateState } from './purchase-returns/purchase-return-update.reducer';
import { PurchaseReturnState } from './purchase-returns/purchase-return.reducer';
import { PurchaseReturnsState } from './purchase-returns/purchase-returns.reducer';
import { RequisiteCreateState } from './requisites/requisite-create.reducer';
import { RequisiteDeleteState } from './requisites/requisite-delete.reducer';
import { RequisiteSuggestionState } from './requisites/requisite-suggestion.reducer';
import { RequisiteUpdateState } from './requisites/requisite-update.reducer';
import { RequisiteState } from './requisites/requisite.reducer';
import { RequisitesState } from './requisites/requisites.reducer';
import { ReservationCreateState } from './reservations/reservation-create.reducer';
import { ReservationState } from './reservations/reservation.reducer';
import { RestaurantCategoriesState } from './restaurant-categories/restaurant-categories.reducer';
import { RestaurantProductsState } from './restaurant-products/restaurant-products.reducer';
import { RestaurantTemplatesState } from './restaurant-templates/restaurant-templates.reducer';
import { SalesPlanUpdateListState } from './sales-plan/sales-plan-update-list.reducer';
import { SalesPlanUpdateState } from './sales-plan/sales-plan-update.reducer';
import { SalesPlanState } from './sales-plan/sales-plan.reducer';
import { SalesPlansState } from './sales-plan/sales-plans.reducer';
import { SupplierInvoiceCreateState } from './supplier-invoices/supplier-invoice-create.reducer';
import { SupplierInvoiceDeleteState } from './supplier-invoices/supplier-invoice-delete.reducer';
import { SupplierInvoiceUpdateState } from './supplier-invoices/supplier-invoice-update.reducer';
import { SupplierInvoiceState } from './supplier-invoices/supplier-invoice.reducer';
import { SupplierInvoicesState } from './supplier-invoices/supplier-invoices.reducer';
import { TaskCreateState } from './tasks/task-create.reducer';
import { TaskDeleteState } from './tasks/task-delete.reducer';
import { TaskUpdateDocumentsState } from './tasks/task-update-documents.reducer';
import { TaskUpdateState } from './tasks/task-update.reducer';
import { TaskState } from './tasks/task.reducer';
import { TasksState } from './tasks/tasks.reducer';
import { TechnologicalMapActiveByProductState } from './technological-maps/technological-map-active-by-product.reducer';
import { TechnologicalMapArchivesState } from './technological-maps/technological-map-archives.reducer';
import { TechnologicalMapByContentState } from './technological-maps/technological-map-by-content.reducer';
import { TechnologicalMapByProductState } from './technological-maps/technological-map-by-product.reducer';
import { TechnologicalMapCreateState } from './technological-maps/technological-map-create.reducer';
import { TechnologicalMapRestoreState } from './technological-maps/technological-map-restore.reducer';
import { TechnologicalMapRevertUpdateState } from './technological-maps/technological-map-revert-update.reducer';
import { TechnologicalMapUpdateState } from './technological-maps/technological-map-update.reducer';
import { TechnologicalMapsState } from './technological-maps/technological-maps.reducer';
import { TimeIntervalsUpdateState } from './intervals/time-intervals-update.reducer';
import { TimeIntervalsState } from './intervals/time-intervals.reducer';
import { TimezonesState } from './timezones/timezones.reducer';
import { TransferCreateState } from './transfers/transfer-create.reducer';
import { TransferDeleteState } from './transfers/transfer-delete.reducer';
import { TransferUpdateState } from './transfers/transfer-update.reducer';
import { TransferWorkbookState } from './transfers/transfer-workbook.reducer';
import { TransferState } from './transfers/transfer.reducer';
import { TransfersState } from './transfers/transfers.reducer';
import { UnitTypesState } from './nomenclature/unit-types.reducer';
import { UserChangePasswordState } from './users/user-change-password.reducer';
import { UserDeleteState } from './users/user-delete.reducer';
import { UserUpdateState } from './users/user-update.reducer';
import { UserState } from './users/user.reducer';
import { UsersFiredState } from './users/users-fired.reducer';
import { UserSearchState } from './users/users-search.reducer';
import { UsersState } from './users/users.reducer';
import { VatsState } from './vats/vats.reducer';
import { VehicleBrandsState } from './vehicle-brands/vehicle-brands.reducer';
import { WarehouseUpdateState } from './warehouses/warehouse-update.reducer';
import { WarehouseState } from './warehouses/warehouse.reducer';
import { WarehousesProductsState } from './warehouses/warehouses-products.reducer';
import { WarehousesSearchState } from './warehouses/warehouses-search.reducer';
import { WarehousesState } from './warehouses/warehouses.reducer';
import { WriteOffCreateState } from './write-offs/write-off-create.reducer';
import { WriteOffDeleteState } from './write-offs/write-off-delete.reducer';
import { WriteOffUpdateState } from './write-offs/write-off-update.reducer';
import { WriteOffWorkbookState } from './write-offs/write-off-workbook.reducer';
import { WriteOffState } from './write-offs/write-off.reducer';
import { WriteOffsState } from './write-offs/write-offs.reducer';

export const states = [
    AttributeGroupsState,
    BrandState,
    BrandsState,
    BrandsSearchState,
    BrandUpdateState,
    BrandDeleteState,
    BrandCreateState,
    BrandDeleteImageState,
    PosCompanySearchState,
    PosCreateState,
    PosDeleteState,
    PosUpdateState,
    PosState,
    PosListState,
    PosCitiesState,
    PosSearchState,
    PosOrdersState,
    PosContragentState,
    CategoriesState,
    CategoryState,
    CategoryDeleteState,
    CategoryUpdateState,
    CategoryCreateState,
    CategoriesSearchState,
    CharacteristicsGroupsState,
    CharacteristicsSearchState,
    CompaniesState,
    CompaniesSearchState,
    CompanyCreateState,
    CompanyDeleteState,
    CompanyFeedState,
    CompanyUpdateState,
    CompanyState,
    CompanyOrdersState,
    CompanyContactsState,
    CompanyContractsState,
    CompanyContragentsState,
    CompanyPosState,
    CompanyCityState,
    CompanyUsersState,
    ContactCreateState,
    ContactDeleteState,
    ContactUpdateState,
    ContactState,
    ContactsState,
    ContactsSearchState,
    ContactCompanyState,
    ContactContragentState,
    ContactPosState,
    ContractCreateState,
    ContractDeleteState,
    ContractUpdateState,
    ContractsState,
    ContractState,
    ContractEditDocumentState,
    ContragentCreateState,
    ContragentDeleteState,
    ContragentUpdateState,
    ContragentState,
    ContragentsState,
    ContragentsSearchState,
    ContragentContactsState,
    ContragentPosState,
    ContragentSuggestionState,
    ContragentOrdersState,
    ContragentCompanyState,
    DeliveryProductsState,
    FullNameSuggestionState,
    ManagerAssistantsState,
    ManagerAssistantsCreateState,
    ManagerAssistantDeleteState,
    PublicCorporationCategoriesState,
    PublicCorporationProductsState,
    MotivationBonusDeleteState,
    MotivationBonusCreateState,
    MotivationBonusEditDocumentState,
    MotivationBonusUpdateState,
    MotivationBonusUpdateListState,
    MotivationBonusState,
    MotivationBonusesState,
    MotivationBonusesCompaniesState,
    MotivationBonusesContactsState,
    MotivationBonusesProductsState,
    NoteCreateState,
    NoteDeleteState,
    NoteUpdateDocumentsState,
    NoteUpdateState,
    NotesState,
    OrganizationCreateState,
    OrganizationDeleteState,
    OrganizationPosAreaRestrictionsState,
    OrganizationPosAreaState,
    OrganizationPosAreasState,
    OrganizationUpdateState,
    OrganizationPosAreaCreateState,
    OrganizationPosAreaDeleteState,
    OrganizationPosAreaUpdateState,
    OrganizationState,
    OrganizationsState,
    OrganizationsSearchState,
    PosContactsState,
    PosMediaListState,
    PosMediaEditState,
    ActivePricelistState,
    PostingCreateState,
    PostingDeleteState,
    PostingUpdateState,
    PostingWorkbookState,
    PostingState,
    PostingsState,
    PriceTypeListState,
    PricelistCreateState,
    PricelistDeleteState,
    PricelistListState,
    PricelistUpdateStatusState,
    PricelistUpdateState,
    PricelistWorkbookState,
    PricelistState,
    ProfileUpdateSettingsState,
    ProductState,
    ProductCreateState,
    ProductUpdateState,
    ProductDeleteState,
    ProductsCountriesState,
    ProductsState,
    ProductsSearchState,
    ProductImageUpdateState,
    ProductImageDeleteState,
    ProductsBrandsState,
    TaskCreateState,
    TaskDeleteState,
    TaskUpdateState,
    TaskState,
    TasksState,
    TaskUpdateDocumentsState,
    RequisiteCreateState,
    RequisiteDeleteState,
    RequisiteUpdateState,
    RequisitesState,
    RequisiteState,
    RequisiteSuggestionState,
    SalesPlansState,
    SalesPlanState,
    SalesPlanUpdateState,
    SalesPlanUpdateListState,
    VehicleBrandsState,
    UserState,
    UserSearchState,
    UsersState,
    UsersFiredState,
    UserDeleteState,
    UserUpdateState,
    UserChangePasswordState,
    CompaniesUsersState,

    WarehouseUpdateState,
    WarehouseState,
    WarehousesSearchState,
    WarehousesState,
    WarehousesProductsState,

    InventoryCreateState,
    InventoryDeleteState,
    InventoryUpdateState,
    InventoryWorkbookState,
    InventoryState,
    InventoriesState,
    ListFromInventoryState,

    WriteOffsState,
    WriteOffDeleteState,
    WriteOffCreateState,
    WriteOffUpdateState,
    WriteOffWorkbookState,
    WriteOffState,

    TransfersState,
    TransferDeleteState,
    TransferCreateState,
    TransferUpdateState,
    TransferWorkbookState,
    TransferState,

    GroupsState,
    UnitTypesState,

    ProductTypesState,
    ProductMediaListState,
    ProductMediaEditState,
    ProductNomenclaturesState,

    PurchaseOrderState,
    PurchaseOrdersState,
    PurchaseOrderCreateState,
    PurchaseOrderDeleteState,
    PurchaseOrderUpdateState,

    SupplierInvoiceState,
    SupplierInvoicesState,
    SupplierInvoiceCreateState,
    SupplierInvoiceDeleteState,
    SupplierInvoiceUpdateState,

    AcceptanceState,
    AcceptancesState,
    AcceptanceCreateState,
    AcceptanceDeleteState,
    AcceptanceUpdateState,

    PurchaseReturnState,
    PurchaseReturnsState,
    PurchaseReturnCreateState,
    PurchaseReturnDeleteState,
    PurchaseReturnUpdateState,

    PurchaseInvoiceState,
    PurchaseInvoicesState,
    PurchaseInvoiceCreateState,
    PurchaseInvoiceDeleteState,
    PurchaseInvoiceUpdateState,

    OrganizationPosState,
    OrganizationPosUpdateState,
    OrganizationPosDeleteState,
    OrganizationPosCreateState,
    OrganizationPosesState,
    OrganizationPosByCoordinatesState,
    OrganizationPosesSearchState,
    OrganizationPosesDetailedState,
    OrganizationPosPaymentTypesState,
    OrganizationTreeState,
    VatsState,

    OrderState,
    OrdersAnalyticsState,
    OrdersState,
    OrderCreateState,
    OrderDeleteState,
    OrderHistoryState,
    OrderDiscountsState,
    OrderUpdateState,
    OrderUpdateStatusState,
    OrdersErrorsHeaderState,
    OrdersErrorsUnreadCountState,
    OrdersErrorsUpdateState,
    OrdersErrorsState,

    FranchiseesState,
    FranchiseeState,
    FranchiseeCreateState,
    FranchiseeDeleteState,
    FranchiseeUpdateState,
    FranchiseeTypesState,
    FranchiseeTypeUpdateState,
    FranchiseeOrganizationsState,
    FranchiseeRestaurantsState,

    RestaurantTemplatesState,

    PersonnelsState,
    PersonnelState,
    PersonnelCreateState,
    PersonnelUpdateState,
    PersonnelDeleteState,

    CorporationRequisiteState,
    CorporationRequisitesState,
    CorporationRequisiteCreateState,
    CorporationRequisiteUpdateState,
    CorporationRequisiteDeleteState,

    IntervalsState,
    IntervalUpdateState,
    IntervalsAvailableListState,
    IntervalsAvailableListSearchState,
    TimeIntervalsUpdateState,
    TimeIntervalsState,
    ReservationCreateState,
    ReservationState,

    TimezonesState,
    RestaurantCategoriesState,
    RestaurantProductsState,
    CustomerCreateState,
    CustomerDeleteState,
    CustomerUpdateState,
    CustomerState,
    CustomersState,
    CustomersSearchState,
    CustomerCompanyState,
    CustomerContragentState,
    CustomerPosState,

    ModifiersState,
    ModifierTypesState,

    TechnologicalMapsState,
    TechnologicalMapUpdateState,
    TechnologicalMapCreateState,
    TechnologicalMapArchivesState,
    TechnologicalMapRestoreState,
    TechnologicalMapByProductState,
    TechnologicalMapByContentState,
    TechnologicalMapRevertUpdateState,
    TechnologicalMapActiveByProductState,

    BillingTransactionsState
];
