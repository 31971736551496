import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ContragentOrdersAction, ContragentOrdersFailAction, ContragentOrdersResetAction, ContragentOrdersSetFilterAction, ContragentOrdersSuccessAction } from './contragent-orders.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrdersListFilter, OrdersListFilterData } from '../../models/filters/orders-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface ContragentOrdersStateModel extends PaginationStateModel<OrderListItem> {
    uids: string[];
    entities: { [uid: string]: OrderListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: OrdersListFilterData;
    total: number;
}

@State<ContragentOrdersStateModel>({
    name: 'contragentOrders',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class ContragentOrdersState extends PaginationStateBase {
    constructor(private ordersService: OrdersService) {
        super();
    }

    @Action([ContragentOrdersAction]) ordersGet(ctx: StateContext<ContragentOrdersStateModel>, action: ContragentOrdersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrdersListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.ordersAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.ordersService.getOrderList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ContragentOrdersSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContragentOrdersFailAction(err)), 0));
    }

    @Action(ContragentOrdersSuccessAction) ordersGetSuccess(ctx: StateContext<ContragentOrdersStateModel>, action: ContragentOrdersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.ordersAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ContragentOrdersFailAction) ordersGetFail(ctx: StateContext<ContragentOrdersStateModel>, action: ContragentOrdersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContragentOrdersResetAction) ordersGetReset(ctx: StateContext<ContragentOrdersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(ContragentOrdersSetFilterAction) ordersSetFilter(ctx: StateContext<ContragentOrdersStateModel>, action: ContragentOrdersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ContragentOrdersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
