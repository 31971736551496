import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ProductsCountriesAction, ProductsCountriesFailAction, ProductsCountriesResetAction, ProductsCountriesSuccessAction } from './countries.actions';

import { Country } from '../../models/business/geocoding/country.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface ProductsCountriesStateModel {
    entities: Country[];
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<ProductsCountriesStateModel>({
    name: 'productsCountries',
    defaults: { pending: false, entities: [], retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class ProductsCountriesState {
    constructor(private catalogService: CatalogService) { }

    @Action([ProductsCountriesAction]) adventuresGet(ctx: StateContext<ProductsCountriesStateModel>, _: ProductsCountriesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.catalogService.getProductsCountries()
            .then(resp => setTimeout(() => ctx.dispatch(new ProductsCountriesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductsCountriesFailAction(err)), 0));
    }

    @Action(ProductsCountriesSuccessAction) adventuresGetSuccess(ctx: StateContext<ProductsCountriesStateModel>, action: ProductsCountriesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(ProductsCountriesFailAction) adventuresGetFail(ctx: StateContext<ProductsCountriesStateModel>, action: ProductsCountriesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductsCountriesResetAction) adventuresGetReset(ctx: StateContext<ProductsCountriesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
