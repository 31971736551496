import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { FranchiseesService } from '../../services/business/franchisees.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Franchisee } from '../../models/business/franchisees/franchisee.model';

import { FranchiseeAction, FranchiseeFailAction, FranchiseeResetAction, FranchiseeSuccessAction } from './franchisee.actions';

export interface FranchiseeStateModel {
    pending: boolean;
    entity: Franchisee;
    error: ErrorObject;
}

@State<FranchiseeStateModel>({
    name: 'franchisee',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class FranchiseeState {
    constructor(private franchiseesService: FranchiseesService) { }

    @Action(FranchiseeAction) async franchisee(ctx: StateContext<FranchiseeStateModel>, action: FranchiseeAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.franchiseesService.getFranchisee(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new FranchiseeSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeFailAction(err)), 0));
    }

    @Action(FranchiseeSuccessAction) franchiseeSuccess(ctx: StateContext<FranchiseeStateModel>, action: FranchiseeSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(FranchiseeFailAction) franchiseeFail(ctx: StateContext<FranchiseeStateModel>, action: FranchiseeFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FranchiseeResetAction) franchiseeReset(ctx: StateContext<FranchiseeStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
