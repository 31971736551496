import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContractsService } from '../../services/business/contracts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContractUpdateAction, ContractUpdateFailAction, ContractUpdateResetAction, ContractUpdateSuccessAction } from './contract-update.actions';

export interface ContractUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<ContractUpdateStateModel>({
    name: 'contractUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class ContractUpdateState {
    constructor(private contractsService: ContractsService) { }

    @Action(ContractUpdateAction) async contractUpdate(ctx: StateContext<ContractUpdateStateModel>, action: ContractUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.contractsService.updateContract(action.payload.uid, action.payload.contragentUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new ContractUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContractUpdateFailAction(err)), 0));
    }

    @Action(ContractUpdateSuccessAction) contractUpdateSuccess(ctx: StateContext<ContractUpdateStateModel>, _: ContractUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(ContractUpdateFailAction) contractUpdateFail(ctx: StateContext<ContractUpdateStateModel>, action: ContractUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(ContractUpdateResetAction) contractUpdateReset(ctx: StateContext<ContractUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
