import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WarehouseCreateOrUpdateRequest } from '../../models/business/warehouses/warehouse-create-or-update-request.model';
import { WarehouseListItem } from '../../models/business/warehouses/warehouse-list-item.model';
import { Warehouse } from '../../models/business/warehouses/warehouse.model';
import { WarehousesListFilter } from '../../models/filters/warehouses-list-filter.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root',
})
export class WarehousesService extends BaseSingletonService {
    private settings: {
        service: {
            getWarehouse: string;
            getWarehouseList: string;
            updateWarehouse: string;
        };
    };

    constructor(private http: HttpService) {
        super('WarehousesService');
        this.settings = {
            service: {
                getWarehouse: '/warehouses/v1/warehouses/{0}',
                getWarehouseList:
                    '/warehouses-aggregation/v1/warehouses?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}&organizationUid={5}&organizationPosUid={6}',
                updateWarehouse: '/warehouses/v1/warehouses',
            },
        };
    }

    public async getWarehouse(uid: string): Promise<Warehouse> {
        return this.http.get<{ data: Warehouse }>(String.format(this.settings.service.getWarehouse, uid)).then(res => res.data);
    }

    public async getWarehouseList(filter: WarehousesListFilter): Promise<PaginationResult<WarehouseListItem>> {
        return this.http
            .get<PaginationResult<WarehouseListItem>>(
                String.format(
                    this.settings.service.getWarehouseList,
                    filter.term,
                    filter.sortField,
                    filter.sortOrder,
                    filter.pageIndex,
                    filter.pageSize,
                    filter.organizationUid,
                    filter.organizationPosUid
                )
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async updateWarehouse(req: WarehouseCreateOrUpdateRequest[]): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateWarehouse), req);
    }
}
