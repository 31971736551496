import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DecompositionUpdateAction {
    static readonly type = '[Booking Page] UpdateDecomposition';

    constructor(public payload: { uid: string, request: DCRCreateOrUpdateRequest }) { }
}

export class DecompositionUpdateSuccessAction {
    static readonly type = '[Decomposition API] UpdateDecomposition Success';

    constructor(public payload: { uid: string, request: DCRCreateOrUpdateRequest }) { }
}

export class DecompositionUpdateFailAction {
    static readonly type = '[Decomposition API] UpdateDecomposition Fail';

    constructor(public payload: ErrorObject) { }
}

export class DecompositionUpdateResetAction {
    static readonly type = '[Booking Page] UpdateDecomposition Reset';
}
