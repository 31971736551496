import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';

import { BehaviorSubject, Subject, filter, merge, takeUntil } from 'rxjs';

import { PerfectScrollbarComponent } from '@eklipse/perfect-scrollbar';

import { BvcIntervalFormComponent } from '../../../../../evasys/shared/components/business/bvc-interval-form/bvc-interval-form.component';

import { DialogService } from '../../../../../burns-ui-framework/shared/services/common/dialog.service';

import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogMode } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-mode.model';
import { ExgDialogResultEvent } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { IntervalCreateOrUpdateRequest } from '../../../../../evasys/shared/models/business/intervals/interval-create-or-update-request.model';
import { IntervalGetFilterData } from '../../../../../evasys/shared/models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../../../../evasys/shared/models/business/intervals/interval-list-item.model';
import { IntervalSandbox } from '../../../../../ye-orders/ye-intervals/shared/interval.sandbox';
import { OrderListItem } from '../../../../../evasys/shared/models/business/intervals/order-list-item.model';
import { DateUtils } from '../../../../../burns-ui-framework/shared/utils/date-utils';
import { SnackbarService } from '../../../../../burns-ui-framework/shared/services/common/snackbar.service';

@Component({
    selector: 'bvc-intervals-table',
    templateUrl: './intervals-table.component.html',
    styleUrls: ['./intervals-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntervalsTableComponent implements OnDestroy, OnChanges {

    @Input() posItem: {
        uid: string;
        name: string;
    };
    @Input() showName = true;

    @ViewChild(PerfectScrollbarComponent, { static: true }) scrollbar?: PerfectScrollbarComponent;

    public startDate: number;
    public endDate: number;
    public dialogMode = ExgDialogMode;
    public componentData: { component: any; inputs: any };
    public showDialog: boolean;
    public dialogPosition = { right: '0' };
    public intervalForUpdate$ = new BehaviorSubject<number>(null);
    public intervalMaxAmount: number;
    public defaultValue = DateUtils.convertStringToEpoc(DateUtils.currentDate);
    public intervals$ = this.intervalSandbox.intervals$;
    public updated$ = this.intervalSandbox.updated$;
    public pending$ = this.intervalSandbox.pending$;
    public updatePending$ = this.intervalSandbox.updatePending$;
    public filter: IntervalGetFilterData;

    private unsubscribe$ = new Subject();

    constructor(private readonly dialogService: DialogService, private readonly intervalSandbox: IntervalSandbox, private snackbar: SnackbarService) {
        this.intervalSandbox.updated$
            .pipe(
                filter(x => !!x),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.intervalForUpdate$.next(null);
                this.intervalMaxAmount = 0;
            });

        merge(this.intervalSandbox.error$, this.intervalSandbox.updateError$)
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(err => !!err)
            )
            .subscribe(error => this.snackbar.showError(error));

        this.intervalSandbox.updated$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(() => {
                this.intervalSandbox.dispatchIntervalUpdateReset();
                this.snackbar.showInfo('Interval successfully saved.');
            });

        this.intervalSandbox.filter$.pipe(takeUntil(this.unsubscribe$)).subscribe(filter => {
            this.filter = filter;
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.posItem && this.posItem) {
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public onUpdateInterval($event: IntervalCreateOrUpdateRequest) {
        this.intervalSandbox.dispatchIntervalUpdate($event);
    }

    public onChangeFilter($event) {
        this.intervalSandbox.dispatchIntervalsSetFilter({ ...this.filter, date: $event?.date || $event });
    }

    public async onStartPause(interval: IntervalListItem) {
        const description = interval.isStopped ? 'Are you sure you want to return from stop this interval?' : 'Are you sure you want to stop this interval?';

        if ((await this.dialogService.showConfirm({ message: 'Stopping an interval', description })) === DialogResult.Confirm) {
            const request = {
                dateFrom: interval.dateFrom,
                dateTo: interval.dateTo,
                maxAmount: interval.maxAmount,
                maxCount: interval.maxCount,
                isStopped: !interval.isStopped,
                organizationPosUid: this.posItem.uid,
            };

            this.onUpdateInterval(request);
        }
    }

    public openIntervalForm(): void {
        this.dialogPosition = { right: '0' };
        this.componentData = {
            component: BvcIntervalFormComponent,
            inputs: { organizationPosUid: this.posItem.uid },
        };

        this.showDialog = true;
    }

    public onDialogClose(event: ExgDialogResultEvent): void {
        this.showDialog = false;

        if (event.dialogResult === DialogResult.Ok) {
            this.onChangeFilter(this.filter);
        }
    }

    public trackByOrders(_, item: OrderListItem): string {
        return item.uid;
    }

    public onChangeAmount(interval: IntervalListItem): void {
        if (!this.intervalForUpdate$.value) {
            this.intervalForUpdate$.next(interval.dateFrom);
        } else if (!!this.intervalMaxAmount && this.intervalMaxAmount > 0) {
            const request = {
                dateFrom: interval.dateFrom,
                dateTo: interval.dateTo,
                maxAmount: this.intervalMaxAmount,
                maxCount: interval.maxCount,
                isStopped: interval.isStopped,
                organizationPosUid: this.posItem.uid,
            };

            this.onUpdateInterval(request);
        } else {
            this.intervalForUpdate$.next(null);
        }
    }

    public onChangeMaxAmount($event): void {
        this.intervalMaxAmount = +$event;
    }
}
