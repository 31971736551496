import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { BrandsService } from '../../services/business/brands.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { BrandUpdateAction, BrandUpdateFailAction, BrandUpdateResetAction, BrandUpdateSuccessAction } from './brand-update.actions';

export interface BrandUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<BrandUpdateStateModel>({
    name: 'brandUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class BrandUpdateState {
    constructor(private brandsService: BrandsService) { }

    @Action(BrandUpdateAction) async brandUpdate(ctx: StateContext<BrandUpdateStateModel>, action: BrandUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.brandsService.updateBrand(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new BrandUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new BrandUpdateFailAction(err)), 0));
    }

    @Action(BrandUpdateSuccessAction) brandUpdateSuccess(ctx: StateContext<BrandUpdateStateModel>, _: BrandUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(BrandUpdateFailAction) brandUpdateFail(ctx: StateContext<BrandUpdateStateModel>, action: BrandUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(BrandUpdateResetAction) brandUpdateReset(ctx: StateContext<BrandUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
