import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { WarehousesListFilterData } from '../../models/filters/warehouses-list-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { WarehousesSearchAction, WarehousesSearchResetAction } from './warehouses-search.actions';

@Injectable({
    providedIn: 'root'
})
export class WarehousesSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('WarehousesSearchDispatchers');
    }

    public dispatchWarehousesSearchAction(filterData: WarehousesListFilterData) {
        this.store.dispatch(new WarehousesSearchAction(filterData));
    }

    public dispatchWarehousesSearchResetAction() {
        this.store.dispatch(new WarehousesSearchResetAction());
    }
}
