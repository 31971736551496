<button class="btn" type="button" (click)="onOpenContactClick()" [class.bonus]="contact.isTakesPartInBonusProgram">
    <div class="full-name" *ngIf="listConfig[contactSortingFields.FullName]" [class.active]="filter.sortField === contactSortingFields.FullName">
        <span title="{{contact.fullName}}">{{contact.fullName}}</span>
    </div>
    <div class="registered" *ngIf="listConfig[contactSortingFields.Email]" [class.active]="filter.sortField === contactSortingFields.Email">
        <span title="{{contact.email }}">{{contact.email}}</span>
    </div>
    <div class="last-entrance" *ngIf="listConfig[contactSortingFields.Phone]" [class.active]="filter.sortField === contactSortingFields.Phone">
        <span title="{{contact.phone}}">{{contact.phone}}</span>
    </div>
    <div class="last-entrance" *ngIf="listConfig[contactSortingFields.Company]" [class.active]="filter.sortField === contactSortingFields.Company">
        <span title="{{contact.company?.name}}">{{contact.company?.name}}</span>
    </div>
</button>
<button class="btn btn_mobile" type="button" (click)="onOpenContactClick()">
    <div class="goup-line">
        <div class="goup-left">
            <div title="{{contact.fullName}}" class="title">{{contact.fullName}}</div>
        </div>
        <div class="goup-right"></div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Email' | translate}}</div>
        <div class="goup-right" title="{{contact.email}}">
            {{contact.email}}
        </div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Phone' | translate}}</div>
        <div class="goup-right" title="{{contact.phone}}">
            {{contact.phone}}
        </div>
    </div>
    <div class="goup-line">
        <div class="goup-left">{{'Company' | translate}}</div>
        <div class="goup-right" title="{{contact.company?.name}}">
            {{contact.company?.name}}
        </div>
    </div>
</button>