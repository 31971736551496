import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RecyclingsService } from '../../services/recyclings.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { RecyclingDeleteAction, RecyclingDeleteFailAction, RecyclingDeleteResetAction, RecyclingDeleteSuccessAction } from './recycling-delete.actions';

export interface RecyclingDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<RecyclingDeleteStateModel>({
    name: 'recyclingDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class RecyclingDeleteState {
    constructor(private recyclingsService: RecyclingsService) { }

    @Action(RecyclingDeleteAction) async recyclingDelete(ctx: StateContext<RecyclingDeleteStateModel>, action: RecyclingDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.recyclingsService.deleteRecycling(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new RecyclingDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new RecyclingDeleteFailAction(error)), 0));
    }

    @Action(RecyclingDeleteSuccessAction) recyclingDeleteSuccess(ctx: StateContext<RecyclingDeleteStateModel>, _: RecyclingDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(RecyclingDeleteFailAction) recyclingDeleteFail(ctx: StateContext<RecyclingDeleteStateModel>, action: RecyclingDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RecyclingDeleteResetAction) recyclingDeleteReset(ctx: StateContext<RecyclingDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
