import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PostingCreateOrUpdateRequest } from '../../models/business/postings/posting-create-or-update-request.model';
import { PostingListGetFilter } from '../../models/filters/posting-list-get-filter.model';
import { PostingListItem } from '../../models/business/postings/posting-list-item.model';
import { PostingStatus } from '../../models/business/postings/posting-status.enum';
import { Posting } from '../../models/business/postings/posting.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class PostingsService extends BaseSingletonService {

    private settings: {
        service: {
            getPosting: string;
            getWorkbook: string;
            getPostingList: string;
            createPosting: string;
            updatePosting: string;
            deletePosting: string;
        }
    };

    private postings = [{

    }];

    constructor(private http: HttpService) {
        super('PostingsService');
        this.settings = {
            service: {
                getPosting: '/warehouses-aggregation/v1/postings/{0}',
                getWorkbook: '/warehouses/v1/postings/{0}/workbook',
                getPostingList: '/warehouses-aggregation/v1/postings?organizationUid={0}&warehouseUid={1}&status={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createPosting: '/warehouses-aggregation/v1/postings',
                updatePosting: '/warehouses/v1/postings/{0}',
                deletePosting: '/warehouses/v1/postings/{0}'
            }
        };
    }

    public async getPosting(uid: string): Promise<Posting> {
        return this.http.get<{ data: Posting }>(String.format(this.settings.service.getPosting, uid))
            .then(res => res.data);
    }

    public async getWorkbook(uid: string, fileName: string): Promise<Blob> {
        return this.http.download(String.format(this.settings.service.getWorkbook, uid), fileName);
    }

    public async getPostingList(filter: PostingListGetFilter): Promise<PaginationResult<PostingListItem>> {
        return this.http.get<PaginationResult<PostingListItem>>(String.format(this.settings.service.getPostingList, filter.organizationUid, filter.warehouseUid, filter.status, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createPosting(request: PostingCreateOrUpdateRequest): Promise<Posting> {
        return this.http.post<{ data: Posting }>(String.format(this.settings.service.createPosting), request)
            .then(res => res.data);
    }

    public async updatePosting(uid: string, request: PostingCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updatePosting, uid), request);
    }

    public async deletePosting(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePosting, uid));
    }
}
