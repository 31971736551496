import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class CookingDeleteAction {
    static readonly type = '[My Cooking Page] DeleteCooking';

    constructor(public payload: { uid: string }) { }
}

export class CookingDeleteSuccessAction {
    static readonly type = '[My Cookings API] DeleteCooking Success';

    constructor(public payload: { uid: string }) { }
}

export class CookingDeleteFailAction {
    static readonly type = '[My Cookings API] DeleteCooking Fail';

    constructor(public payload: ErrorObject) { }
}

export class CookingDeleteResetAction {
    static readonly type = '[My Cooking Page] DeleteCooking Reset';
}
