import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { SharedModule } from '../burns-ui-framework/shared/shared.module';
import { SharedEvaModule } from '../evasys/shared/shared.module';

import { BvcDcrAddLineComponent } from './components/dcr-list/dcr-add-line/dcr-add-line.component';
import { DCRFormComponent } from './components/dcr-form/dcr-form.component';
import { DcrHeaderComponent } from './components/dcr-list/dcr-header/dcr-header.component';
import { DcrListItemComponent } from './components/dcr-list/dcr-list-item/dcr-list-item.component';
import { DrcListComponent } from './components/dcr-list/dcr-list.component';
import { DcrNotesFormComponent } from './components/dcr-form/dcr-notes-from/dcr-notes-from.component';
import { OrganizationsPosSelectTreeComponent } from './components/organizations-pos-select-tree/organizations-pos-select-tree.component';
import { YeTopMenuComponent } from './components/ye-top-menu/ye-top-menu.component';

import { YeTimeIntervalPipe } from './pipes/ye-time-interval/ye-time-interval.pipe';

@NgModule({
    imports: [
        SharedModule,
        SharedEvaModule
    ],
    declarations: [
        DcrNotesFormComponent,
        DCRFormComponent,
        BvcDcrAddLineComponent,
        DcrHeaderComponent,
        DcrListItemComponent,
        DrcListComponent,
        YeTopMenuComponent,
        OrganizationsPosSelectTreeComponent,
    ],
    exports: [
        DcrNotesFormComponent,
        DCRFormComponent,
        BvcDcrAddLineComponent,
        DcrHeaderComponent,
        DcrListItemComponent,
        DrcListComponent,
        YeTopMenuComponent,
        OrganizationsPosSelectTreeComponent,
    ]
})
export class YeModule {
    static forRoot(settingsProviders: { token: string, value: any }[]): ModuleWithProviders<YeModule> {
        const providers: Provider[] = settingsProviders.map(setting => ({ provide: setting.token, useValue: setting.value }));

        return {
            ngModule: YeModule,
            providers
        };
    }
}
