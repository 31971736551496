import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { CharacteristicsListGetFilterData } from '../../models/filters/characteristics-list-get-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { CharacteristicsSearchAction, CharacteristicsSearchResetAction } from './characteristics-search.actions';

@Injectable({
    providedIn: 'root'
})
export class CharacteristicsSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('CharacteristicsSearchDispatchers');
    }

    public dispatchCharacteristicsSearchAction(request: CharacteristicsListGetFilterData) {
        this.store.dispatch(new CharacteristicsSearchAction(request));
    }

    public dispatchCharacteristicsSearchResetAction() {
        this.store.dispatch(new CharacteristicsSearchResetAction());
    }
}
