import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { OrderListItem } from '../../../../models/business/orders/order-list-item.model';
import { OrderSortingFieldsEnum } from '../../../../models/business/orders/order-sorting-fields.enum';
import { OrderStatus } from '../../../../models/business/orders/order-status.enum';
import { OrdersListFilterData } from '../../../../models/filters/orders-list-filter.model';

@Component({
    selector: 'orders-list-item',
    templateUrl: './orders-list-item.component.html',
    styleUrls: ['./orders-list-item.component.scss', '../orders-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderListItemComponent implements OnChanges {
    @Input() order: OrderListItem;
    @Input() filter: OrdersListFilterData;
    @Input() listConfig: any;

    @Output() readonly openOrder = new EventEmitter<string>();

    public orderStatus = OrderStatus;
    public orderSortingFields = OrderSortingFieldsEnum;
    public productResponsibleUsers: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.order && this.order) {
            this.productResponsibleUsers = this.order.productResponsibleUsers?.map(user => user.fullName).join(', ');
        }
    }

    public onOpenOrderClick() {
        this.openOrder.emit(this.order.uid);
    }
}
