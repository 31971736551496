import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContractSortingFieldsEnum } from '../../../models/business/contract/contract-sorting-fields.model';
import { Contract } from '../../../models/business/contract/contract.model';
import { ContractsListFilterData } from '../../../models/filters/contracts-list-filter.model';

@Component({
    selector: 'contracts-list',
    templateUrl: './contracts-list.component.html',
    styleUrls: ['./contracts-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractListComponent {

    @Input() contracts: Contract[];
    @Input() allContractsLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: ContractsListFilterData;
    @Input() enableAddLine = false;
    @Input() ignoreRows: ContractSortingFieldsEnum[] = [];

    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openContract = new EventEmitter<string>();
    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: string, sortField: string }>();
    @Output() readonly addBtnClick = new EventEmitter<void>();

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onSortingChange($event: { sortOrder: string, sortField: string }) {
        this.sortingChange.emit($event);
    }

    public trackByContracts(_, item: Contract) {
        return item.uid;
    }

    public onOpenContract(uid: string) {
        this.openContract.emit(uid);
    }
}
