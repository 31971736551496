import { ModuleWithProviders, NgModule } from '@angular/core';

import { GeocodingModule } from '../geocoding/geocoding.module';
import { SharedModule } from '../shared/shared.module';
import { YandexGeocodingModule } from '../yandex-geocoding/yandex-geocoding.module';

import { ExgYandexComponentInputTypePipe } from './pipes/exg-yandex-component-input-type.pipe';

import { ExgAddressAggregationAutocompleteComponent } from './components/exg-address-aggregation-autocomplete/exg-address-aggregation-autocomplete.component';
import { ExgCountryAutocompleteComponent } from '../geocoding/components/exg-country-autocomplete/exg-country-autocomplete.component';

import { GeocodingStrategy } from './models/geocoding-strategy.model';
import { GeocodingType } from './models/geocoding-type.enum';

import { COUNTRY_GEOCODE_STRATEGIES_TOKEN, DEFAULT_COUNTRY_TOKEN, DEFAULT_GEOCODING_TOKEN } from './geocoding-tokens';

@NgModule({
    imports: [
        GeocodingModule,
        YandexGeocodingModule,
        SharedModule
    ],
    declarations: [
        ExgYandexComponentInputTypePipe,

        ExgAddressAggregationAutocompleteComponent
    ],
    exports: [
        ExgAddressAggregationAutocompleteComponent,
        ExgCountryAutocompleteComponent
    ]
})
export class GeocodingAggregationModule {
    static forRoot(defaultCountryCode: string, defaultGeocoding: GeocodingType, countryGeocodeStrategies: GeocodingStrategy[]): ModuleWithProviders<GeocodingAggregationModule> {
        return {
            ngModule: GeocodingAggregationModule,
            providers: [
                { provide: DEFAULT_COUNTRY_TOKEN, useValue: defaultCountryCode },
                { provide: DEFAULT_GEOCODING_TOKEN, useValue: defaultGeocoding },
                { provide: COUNTRY_GEOCODE_STRATEGIES_TOKEN, useValue: countryGeocodeStrategies }
            ]
        };
    }
}
