import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SupplierInvoicesService } from '../../services/business/supplier-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { SupplierInvoice } from '../../models/business/supplier-invoices/supplier-invoice.model';

import { SupplierInvoiceFailAction, SupplierInvoiceGetAction, SupplierInvoiceGetSuccessAction, SupplierInvoiceResetAction } from './supplier-invoice.actions';

export interface SupplierInvoiceStateModel {
    entity: SupplierInvoice;
    pending: boolean;
    error: ErrorObject;
}

@State<SupplierInvoiceStateModel>({
    name: 'supplierInvoice',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class SupplierInvoiceState {
    constructor(private supplierInvoicesService: SupplierInvoicesService) { }

    @Action(SupplierInvoiceGetAction) supplierInvoiceGet(ctx: StateContext<SupplierInvoiceStateModel>, action: SupplierInvoiceGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.supplierInvoicesService.getSupplierInvoice(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new SupplierInvoiceGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SupplierInvoiceFailAction(err)), 0));
    }

    @Action(SupplierInvoiceGetSuccessAction) supplierInvoiceGetSuccess(ctx: StateContext<SupplierInvoiceStateModel>, action: SupplierInvoiceGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(SupplierInvoiceFailAction) supplierInvoiceGetFail(ctx: StateContext<SupplierInvoiceStateModel>, action: SupplierInvoiceFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(SupplierInvoiceResetAction) supplierInvoiceGetReset(ctx: StateContext<SupplierInvoiceStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
