export const environment = {
    production: false,
    envName: 'dev',
    apiKey: 'APP_API_KEY',
    apiUrl: 'APP_API_URL',
    defaultCulture: 'APP_CULTURE',
    defaultCountry: 'APP_COUNTRY',
    defaultCurrency: 'APP_CURRENCY',
    enableSoundNotification: 'APP_ENABLE_SOUND_NOTIFICATION',
    version: require('../../package.json').version,
    hmr: false
};
