import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ContractCreateOrUpdateRequest } from '../../models/business/contract/contract-create-or-update.model';
import { Contract } from '../../models/business/contract/contract.model';
import { ContractsListFilter } from '../../models/filters/contracts-list-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ContractsService extends BaseSingletonService {

    private settings: {
        service: {
            getContract: string;
            getContractList: string;
            createContract: string;
            updateContract: string;
            deleteContract: string;
            editContractDocument: string;
        }
    };

    constructor(private http: HttpService) {
        super('ContractsService');
        this.settings = {
            service: {
                getContract: '/crm/v1/contragents/{0}/contracts/{1}',
                getContractList: '/crm/v1/contragents/contracts?term={0}&companyUid={1}&contragentUid={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createContract: '/crm/v1/contragents/{0}/contracts',
                updateContract: '/crm/v1/contragents/{0}/contracts/{1}',
                deleteContract: '/crm/v1/contragents/{0}/contracts/{1}',
                editContractDocument: '/crm/v1/contragents/{0}/contracts/{1}/documents'
            }
        };
    }

    public async getContract(uid: string, contragenUid: string): Promise<Contract> {
        return this.http.get<{ data: Contract }>(String.format(this.settings.service.getContract, contragenUid, uid))
            .then(res => res.data);
    }

    public async getContractList(filter: ContractsListFilter): Promise<PaginationResult<Contract>> {
        return this.http.get<PaginationResult<Contract>>(String.format(this.settings.service.getContractList, filter.term, filter.companyUid, filter.contragentUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createContract(contragentUid: string, request: ContractCreateOrUpdateRequest): Promise<Contract> {
        return this.http.post<{ data: Contract }>(String.format(this.settings.service.createContract, contragentUid), request)
            .then(res => res.data);
    }

    public async updateContract(uid: string, contragentUid: string, request: ContractCreateOrUpdateRequest): Promise<Contract> {
        return this.http.put(String.format(this.settings.service.updateContract, contragentUid, uid), request);
    }

    public async deleteContract(uid: string, contragentUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteContract, contragentUid, uid));
    }

    public async editContractDocument(uid: string, contragentUid: string, request: any): Promise<string> {
        const formData = new FormData();

        for (const file of request) {
            formData.append(file.name, file);
        }

        return this.http.upload<{ data: string }>(String.format(this.settings.service.editContractDocument, contragentUid, uid), formData)
            .then(x => x.data);
    }
}
