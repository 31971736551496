import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { BillingTransactionListItem } from '../../models/business/billing-transactions/billing-transaction.model';
import { BillingTransactionsFilter } from '../../models/filters/billing-transactions-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class BankingService extends BaseSingletonService {

    private settings: {
        service: {
            getBillingTransactions: string;
        }
    };

    constructor(private http: HttpService) {
        super('BankingService');
        this.settings = {
            service: {
                getBillingTransactions: '/banking/v1/billing-transactions?term={0}&dateFrom={1}&dateTo={2}&statuses={3}&paymentTypes={4}&transactionTypes={5}&sortField={6}&sortOrder={7}&pageIndex={8}&pageSize={9}',
            }
        };
    }

    public async getBillingTransactions(filter: BillingTransactionsFilter): Promise<PaginationResult<BillingTransactionListItem>> {
        const statuses = filter.statuses?.join(',');
        const paymentTypes = filter.paymentTypes?.join(',');
        const transactionTypes = filter.transactionTypes?.join(',');
        return this.http
            .get<PaginationResult<BillingTransactionListItem>>(
                String.format(
                    this.settings.service.getBillingTransactions,
                    filter.term,
                    filter.dateFrom,
                    filter.dateTo,
                    statuses,
                    paymentTypes,
                    transactionTypes,
                    filter.sortField,
                    filter.sortOrder,
                    filter.pageIndex,
                    filter.pageSize,
                )
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }
}
