import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CategoryDeleteAction {
    static readonly type = '[My Category Page] DeleteCategory';

    constructor(public payload: { uid: string }) { }
}

export class CategoryDeleteSuccessAction {
    static readonly type = '[My Categorys API] DeleteCategory Success';

    constructor(public payload: { uid: string }) { }
}

export class CategoryDeleteFailAction {
    static readonly type = '[My Categorys API] DeleteCategory Fail';

    constructor(public payload: ErrorObject) { }
}

export class CategoryDeleteResetAction {
    static readonly type = '[My Category Page] DeleteCategory Reset';
}
