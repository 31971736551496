import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PricelistWorkbookGetAction {
    static readonly type = '[PricelistWorkbook Page] GetPricelistWorkbook Get';

    constructor(public payload: { uid: string, fileName: string }) { }
}

export class PricelistWorkbookGetSuccessAction {
    static readonly type = '[PricelistWorkbook API] GetPricelistWorkbook Success';

    constructor(public payload: Blob) { }
}

export class PricelistWorkbookFailAction {
    static readonly type = '[PricelistWorkbook API] GetPricelistWorkbook Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistWorkbookResetAction {
    static readonly type = '[PricelistWorkbook Page] GetPricelistWorkbook Reset';
}
