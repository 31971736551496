import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RequisitesService } from '../../services/business/requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { RequisiteCreateAction, RequisiteCreateFailAction, RequisiteCreateResetAction, RequisiteCreateSuccessAction } from './requisite-create.actions';
import { Requisite } from '../../models/business/contragents/requisites.model';

export interface RequisiteCreateStateModel {
    pending: boolean;
    created: Requisite;
    error: ErrorObject;
}

@State<RequisiteCreateStateModel>({
    name: 'requisiteCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class RequisiteCreateState {
    constructor(private requisitesService: RequisitesService) { }

    @Action(RequisiteCreateAction) async requisiteCreate(ctx: StateContext<RequisiteCreateStateModel>, action: RequisiteCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.requisitesService.createRequisite(action.payload.contragentUid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new RequisiteCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RequisiteCreateFailAction(err)), 0));
    }

    @Action(RequisiteCreateSuccessAction) requisiteCreateSuccess(ctx: StateContext<RequisiteCreateStateModel>, action: RequisiteCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(RequisiteCreateFailAction) requisiteCreateFail(ctx: StateContext<RequisiteCreateStateModel>, action: RequisiteCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(RequisiteCreateResetAction) requisiteCreateReset(ctx: StateContext<RequisiteCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
