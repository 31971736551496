import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class BrandDeleteImageAction {
    static readonly type = '[My Brand Page] DeleteBrandImage';

    constructor(public payload: { uid: string }) { }
}

export class BrandDeleteImageSuccessAction {
    static readonly type = '[My Brands API] DeleteBrandImage Success';

    constructor(public payload: { uid: string }) { }
}

export class BrandDeleteImageFailAction {
    static readonly type = '[My Brands API] DeleteBrandImage Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandDeleteImageResetAction {
    static readonly type = '[My Brand Page] DeleteBrandImage Reset';
}
