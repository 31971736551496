import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationsService } from '../../services/business/organizations.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { OrganizationsSearchAction, OrganizationsSearchFailAction, OrganizationsSearchResetAction, OrganizationsSearchSuccessAction } from './organizations-search.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilter } from '../../models/filters/organizations-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface OrganizationsSearchStateModel extends PaginationStateModel<OrganizationListItem> {
    uids: string[];
    entities: { [uid: string]: OrganizationListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationsSearchStateModel>({
    name: 'organizationsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class OrganizationsSearchState extends PaginationStateBase {
    constructor(private organizationsService: OrganizationsService) {
        super();
    }

    @Action([OrganizationsSearchAction]) organizationsGet(ctx: StateContext<OrganizationsSearchStateModel>, action: OrganizationsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrganizationsListGetFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.organizationsService.getOrganizationList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationsSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationsSearchFailAction(err)), 0));
    }

    @Action(OrganizationsSearchSuccessAction) organizationsGetSuccess(ctx: StateContext<OrganizationsSearchStateModel>, action: OrganizationsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.organizationsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(OrganizationsSearchFailAction) organizationsGetFail(ctx: StateContext<OrganizationsSearchStateModel>, action: OrganizationsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationsSearchResetAction) organizationsGetReset(ctx: StateContext<OrganizationsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
