import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TransferListGetFilterData } from '../../models/filters/transfer-list-get-filter.model';
import { TransferListItem } from '../../models/business/transfers/transfer-list-item.model';

export class TransfersAction {
    static readonly type = '[Transferss Page] GetTransfers';

    constructor(public payload: PaginationRequest) { }
}

export class TransfersSuccessAction {
    static readonly type = '[Transfers API] GetTransfers Success';

    constructor(public payload: { list: PaginationResult<TransferListItem>, pagination: PaginationRequest }) { }
}

export class TransfersFailAction {
    static readonly type = '[Transfers API] GetTransfers Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransfersResetAction {
    static readonly type = '[Transfers Page] GetTransfers Reset';
}

export class TransfersSetFilterAction {
    static readonly type = '[Transfers API] SetFilter';

    constructor(public payload: TransferListGetFilterData) { }
}
