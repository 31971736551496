import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrderDeleteAction {
    static readonly type = '[My Order Page] DeleteOrder';

    constructor(public payload: { uid: string }) { }
}

export class OrderDeleteSuccessAction {
    static readonly type = '[My Orders API] DeleteOrder Success';

    constructor(public payload: { uid: string }) { }
}

export class OrderDeleteFailAction {
    static readonly type = '[My Orders API] DeleteOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderDeleteResetAction {
    static readonly type = '[My Order Page] DeleteOrder Reset';
}
