import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Pos } from '../../models/business/pos/pos.model';

export class PosGetAction {
    static readonly type = '[Pos Page] GetPos Get';

    constructor(public payload: string) { }
}

export class PosGetSuccessAction {
    static readonly type = '[Pos API] GetPos Success';

    constructor(public payload: Pos) { }
}

export class PosFailAction {
    static readonly type = '[Pos API] GetPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosResetAction {
    static readonly type = '[Pos Page] GetPos Reset';
}
