<button class="btn" type="button" (click)="onOpenContractClick()">
    <div class="full-name" [class.active]="filter.sortField === contractSortingFields.Number">
        <span title="{{contract.number}}">{{contract.number}}</span>
    </div>
    <div class="registered" [class.active]="filter.sortField === contractSortingFields.Name">
        <span title="{{contract.name }}">{{contract.name}}</span>
    </div>
    <div class="last-entrance" [class.active]="filter.sortField === contractSortingFields.DueDate">
        <span title="{{contract.dateTo}}">{{contract.dateTo}}</span>
    </div>
</button>