import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationFilteredByIdStateBase, PaginationFilteredByIdStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-id-base';

import { BankingService } from '../../services/business/banking.service';
import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';

import { BillingTransactionListItem } from '../../models/business/billing-transactions/billing-transaction.model';
import { BillingTransactionsFilter, BillingTransactionsFilterData } from '../../models/filters/billing-transactions-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { BillingTransactionsAction, BillingTransactionsFailAction, BillingTransactionsResetAction, BillingTransactionsSetFilterAction, BillingTransactionsSuccessAction } from './billing-transactions.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface BillingTransactionsStateModel extends PaginationFilteredByIdStateModel<BillingTransactionListItem, BillingTransactionsFilterData> {
    ids: number[];
    entities: { [id: number]: BillingTransactionListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: BillingTransactionsFilterData;
    total: number;
}

@State<BillingTransactionsStateModel>({
    name: 'billingTransactions',
    defaults: { pending: false, ids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { pageSize: null } }
})
@Injectable()
export class BillingTransactionsState extends PaginationFilteredByIdStateBase {
    constructor(private bankingService: BankingService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([BillingTransactionsAction]) billingTransactionsGet(ctx: StateContext<BillingTransactionsStateModel>, action: BillingTransactionsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'billingTransactions');
        ctx.setState({ ...state, filter: filterData });

        const filter = new BillingTransactionsFilter({ pageIndex: state.index, pageSize: state.filter.pageSize || filterData.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.bankingService.getBillingTransactions(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new BillingTransactionsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new BillingTransactionsFailAction(err)), 0));
    }

    @Action(BillingTransactionsSuccessAction) billingTransactionsGetSuccess(ctx: StateContext<BillingTransactionsStateModel>, action: BillingTransactionsSuccessAction) {
        const state = ctx.getState();
        const { ids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, ids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(BillingTransactionsFailAction) billingTransactionsGetFail(ctx: StateContext<BillingTransactionsStateModel>, action: BillingTransactionsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(BillingTransactionsResetAction) billingTransactionsGetReset(ctx: StateContext<BillingTransactionsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, ids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(BillingTransactionsSetFilterAction) billingTransactionsSetFilter(ctx: StateContext<BillingTransactionsStateModel>, action: BillingTransactionsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'billingTransactions');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new BillingTransactionsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
