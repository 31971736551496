import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ProductsService } from '../../services/business/products.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProductMediaEditFailAction, ProductMediaEditResetAction, ProductMediaEditSuccessAction, ProductMediaEditАction } from './product-media-edit.actions';
import { MediaLight } from '../../../../burns-ui-framework/shared/models/business/media/media-light.model';
export interface ProductMediaEditStateModel {
    pending: boolean;
    error: ErrorObject;
    mediaUpdated: boolean;
    productMediaInfo: { addedPhotos: MediaLight[]; deletedPhotos: string[]; productUid: string } | null;
}

@State<ProductMediaEditStateModel>({
    name: 'productMediaEdit',
    defaults: { pending: false, error: null, mediaUpdated: false, productMediaInfo: null },
})
@Injectable()
export class ProductMediaEditState {
    constructor(private productService: ProductsService) {}

    @Action(ProductMediaEditАction) async productMediaEdit(ctx: StateContext<ProductMediaEditStateModel>, action: ProductMediaEditАction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, mediaUpdated: false });

        return this.productService
            .editProductMedia(action.payload.productUid, action.payload.addedPhotos, action.payload.deletedPhotos)
            .then(res => setTimeout(() => ctx.dispatch(new ProductMediaEditSuccessAction({ ...res, productUid: action.payload.productUid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ProductMediaEditFailAction(error)), 0));
    }

    @Action(ProductMediaEditSuccessAction) productMediaEditSuccess(ctx: StateContext<ProductMediaEditStateModel>, _: ProductMediaEditSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, mediaUpdated: true, productMediaInfo: _.payload });
    }

    @Action(ProductMediaEditFailAction) productMediaEditFail(ctx: StateContext<ProductMediaEditStateModel>, action: ProductMediaEditFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload, mediaUpdated: false, productMediaInfo: null });
    }

    @Action(ProductMediaEditResetAction) productMediaEditReset(ctx: StateContext<ProductMediaEditStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, mediaUpdated: false, productMediaInfo: null });
    }
}
