import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ErrorListGetFilter } from '../../models/filters/error-list-get-filter.model';
import { ErrorListItem } from '../../models/business/orders-errors/error-list-item.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class OrdersErrorsService extends BaseSingletonService {

    private settings: {
        service: {
            getErrorsList: string;
            getUnreadCount: string;
            updateErrorsList: string;
        }
    };

    constructor(private http: HttpService) {
        super('OrdersErrorsService');
        this.settings = {
            service: {
                getErrorsList: '/orders/v1/errors?orderUid={0}&franchiseeUid={1}&organizationUid={2}&organizationPosUid={3}&dateFrom={4}&dateTo={5}&sortField={6}&sortOrder={7}&pageIndex={8}&pageSize={9}',
                updateErrorsList: '/orders/v1/errors/status',
                getUnreadCount: '/orders/v1/errors/unread-count'
            }
        };
    }

    public async getErrorsList(filter: ErrorListGetFilter): Promise<PaginationResult<ErrorListItem>> {
        return this.http.get<PaginationResult<ErrorListItem>>(String.format(
            this.settings.service.getErrorsList,
            filter.orderUid,
            filter.franchiseeUid,
            filter.organizationUid,
            filter.organizationPosUid,
            filter.dateFrom,
            filter.dateTo,
            filter.sortField,
            filter.sortOrder,
            filter.pageIndex,
            filter.pageSize
        ))
        .then((res) => {
            res.pageSize = filter.pageSize;
            res.pageIndex = filter.pageIndex;
            return res;
        });
    }

    /**
     * Number of unread errors
     */
    public async getUnreadCount(): Promise<number> {
        return this.http.get<{ data: number }>(this.settings.service.getUnreadCount).then(res => res.data);
    }

    /**
     * Update errors status
     */
    public async updateErrors(req: any): Promise<boolean> {
        return this.http.put<boolean>(this.settings.service.updateErrorsList, req);
    }
}
