import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { CorporationRequisite } from '../../models/business/corporation-requisites/corporation-requisites.model';

export class CorporationRequisiteAction {
    static readonly type = '[CorporationRequisite Page] CorporationRequisite';

    constructor(public payload: { uid: string, organizationUid: string }) { }
}

export class CorporationRequisiteSuccessAction {
    static readonly type = '[CorporationRequisite API] CorporationRequisite Success';

    constructor(public payload: CorporationRequisite) { }
}

export class CorporationRequisiteFailAction {
    static readonly type = '[CorporationRequisite API] CorporationRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationRequisiteResetAction {
    static readonly type = '[CorporationRequisite Page] CorporationRequisite Reset';
}
