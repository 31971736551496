import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class RecyclingCreateAction {
    static readonly type = '[Recyclings Page] CreateRecycling';

    constructor(public payload: DCRCreateOrUpdateRequest) { }
}

export class RecyclingCreateSuccessAction {
    static readonly type = '[Recycling API] CreateRecycling Success';

    constructor(public payload: DCRItem) { }
}

export class RecyclingCreateFailAction {
    static readonly type = '[Recycling API] CreateRecycling Fail';

    constructor(public payload: ErrorObject) { }
}

export class RecyclingCreateResetAction {
    static readonly type = '[Recyclings Page] CreateRecycling Reset';
}
