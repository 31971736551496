import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { BrandsService } from '../../services/business/brands.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { BrandDeleteAction, BrandDeleteFailAction, BrandDeleteResetAction, BrandDeleteSuccessAction } from './brand-delete.actions';

export interface BrandDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<BrandDeleteStateModel>({
    name: 'brandDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class BrandDeleteState {
    constructor(private brandsService: BrandsService) { }

    @Action(BrandDeleteAction) async brandDelete(ctx: StateContext<BrandDeleteStateModel>, action: BrandDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.brandsService.deleteBrand(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new BrandDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new BrandDeleteFailAction(error)), 0));
    }

    @Action(BrandDeleteSuccessAction) brandDeleteSuccess(ctx: StateContext<BrandDeleteStateModel>, _: BrandDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(BrandDeleteFailAction) brandDeleteFail(ctx: StateContext<BrandDeleteStateModel>, action: BrandDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(BrandDeleteResetAction) brandDeleteReset(ctx: StateContext<BrandDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
