import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Task } from '../../models/business/tasks/task.model';

export class TaskUpdateDocumentsAction {
    static readonly type = '[Booking Page] TaskUpdateDocuments';

    constructor(public payload: { uid: string, documentsToAdd: File[], documentsToRemove: string[] }) { }
}

export class TaskUpdateDocumentsSuccessAction {
    static readonly type = '[Task API] TaskUpdateDocuments Success';

    constructor(public payload: { uid: string, entity: Task, documentsToRemove: string[] }) { }
}

export class TaskUpdateDocumentsFailAction {
    static readonly type = '[Task API] TaskUpdateDocuments Fail';

    constructor(public payload: ErrorObject) { }
}

export class TaskUpdateDocumentsResetAction {
    static readonly type = '[Booking Page] TaskUpdateDocuments Reset';
}
