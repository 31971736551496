import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseInvoicesService } from '../../services/business/purchase-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PurchaseInvoiceDeleteAction, PurchaseInvoiceDeleteFailAction, PurchaseInvoiceDeleteResetAction, PurchaseInvoiceDeleteSuccessAction } from './purchase-invoice-delete.actions';

export interface PurchaseInvoiceDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PurchaseInvoiceDeleteStateModel>({
    name: 'purchaseInvoiceDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PurchaseInvoiceDeleteState {
    constructor(private purchaseInvoicesService: PurchaseInvoicesService) { }

    @Action(PurchaseInvoiceDeleteAction) async purchaseInvoiceDelete(ctx: StateContext<PurchaseInvoiceDeleteStateModel>, action: PurchaseInvoiceDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.purchaseInvoicesService.deletePurchaseInvoice(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PurchaseInvoiceDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PurchaseInvoiceDeleteFailAction(error)), 0));
    }

    @Action(PurchaseInvoiceDeleteSuccessAction) purchaseInvoiceDeleteSuccess(ctx: StateContext<PurchaseInvoiceDeleteStateModel>, _: PurchaseInvoiceDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PurchaseInvoiceDeleteFailAction) purchaseInvoiceDeleteFail(ctx: StateContext<PurchaseInvoiceDeleteStateModel>, action: PurchaseInvoiceDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseInvoiceDeleteResetAction) purchaseInvoiceDeleteReset(ctx: StateContext<PurchaseInvoiceDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
