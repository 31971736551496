import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { OrganizationTreeListGetFilterData } from '../../models/filters/organization-tree-list-get-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { OrganizationTreeAction, OrganizationTreeResetAction } from './organization-tree.actions';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTreeDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('OrganizationTreeDispatchers');
    }

    public dispatchOrganizationTreeAction(request: OrganizationTreeListGetFilterData) {
        this.store.dispatch(new OrganizationTreeAction(request));
    }

    public dispatchOrganizationTreeResetAction() {
        this.store.dispatch(new OrganizationTreeResetAction());
    }
}
