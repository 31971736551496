import { CategoryListItem } from '../../models/business/corporation-catalog/category-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategoriesListGetAggregationFilterData } from '../../models/filters/product-categories-list-get-aggregation-filter.model';

export class PublicCorporationCategoriesAction {
    static readonly type = '[PublicCorporationCategories API] GetCategories';

    constructor(public payload: ProductCategoriesListGetAggregationFilterData) { }
}

export class PublicCorporationCategoriesSuccessAction {
    static readonly type = '[PublicCorporationCategories API] GetPublicCorporationCategories Success';

    constructor(public payload: { list: CategoryListItem[] }) { }
}

export class PublicCorporationCategoriesFailAction {
    static readonly type = '[PublicCorporationCategories API] GetPublicCorporationCategories Fail';

    constructor(public payload: ErrorObject) { }
}

export class PublicCorporationCategoriesResetAction {
    static readonly type = '[PublicCorporationCategories Page] GetPublicCorporationCategories Reset';
}
