
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PricelistDeleteAction {
    static readonly type = '[My Pricelist Page] DeletePricelist';

    constructor(public payload: { uid: string }) { }
}

export class PricelistDeleteSuccessAction {
    static readonly type = '[My Pricelists API] DeletePricelist Success';

    constructor(public payload: { uid: string }) { }
}

export class PricelistDeleteFailAction {
    static readonly type = '[My Pricelists API] DeletePricelist Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistDeleteResetAction {
    static readonly type = '[My Pricelist Page] DeletePricelist Reset';
}
