import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationsService } from '../../services/business/organizations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import {
    OrganizationUpdateAction,
    OrganizationUpdateFailAction,
    OrganizationUpdateResetAction,
    OrganizationUpdateSuccessAction,
} from './organization-update.actions';
import { CorporationRequisitesService } from '../../services/business/corporation-requisites.service';

export interface OrganizationUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<OrganizationUpdateStateModel>({
    name: 'organizationUpdate',
    defaults: { pending: false, updated: false, error: null },
})
@Injectable()
export class OrganizationUpdateState {
    constructor(private organizationsService: OrganizationsService, private readonly corporationRequisitesService: CorporationRequisitesService) {}

    @Action(OrganizationUpdateAction) async organizationUpdate(ctx: StateContext<OrganizationUpdateStateModel>, action: OrganizationUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });
        const defaultRequisiteUid = action.payload.defaultRequisiteUid;
        return Promise.all([
            defaultRequisiteUid ? this.corporationRequisitesService.setDefaultRequisite(defaultRequisiteUid, action.payload.uid) : null,
            this.organizationsService.updateOrganization(action.payload.uid, action.payload.request),
        ])
            .then(() => setTimeout(() => ctx.dispatch(new OrganizationUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationUpdateFailAction(err)), 0));
    }

    @Action(OrganizationUpdateSuccessAction) organizationUpdateSuccess(ctx: StateContext<OrganizationUpdateStateModel>, _: OrganizationUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(OrganizationUpdateFailAction) organizationUpdateFail(ctx: StateContext<OrganizationUpdateStateModel>, action: OrganizationUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(OrganizationUpdateResetAction) organizationUpdateReset(ctx: StateContext<OrganizationUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
