import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ActivePricelistFailAction, ActivePricelistGetAction, ActivePricelistGetSuccessAction, ActivePricelistResetAction } from './active-pricelist.actions';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

export interface ActivePricelistStateModel {
    entity: Pricelist;
    pending: boolean;
    error: ErrorObject;
}

@State<ActivePricelistStateModel>({
    name: 'activePricelist',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ActivePricelistState {
    constructor(private pricelistService: PricelistService) { }

    @Action(ActivePricelistGetAction) pricelistGet(ctx: StateContext<ActivePricelistStateModel>, _: ActivePricelistGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.pricelistService.getActivePriceList()
            .then(resp => setTimeout(() => ctx.dispatch(new ActivePricelistGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ActivePricelistFailAction(err)), 0));
    }

    @Action(ActivePricelistGetSuccessAction) pricelistGetSuccess(ctx: StateContext<ActivePricelistStateModel>, action: ActivePricelistGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ActivePricelistFailAction) pricelistGetFail(ctx: StateContext<ActivePricelistStateModel>, action: ActivePricelistFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ActivePricelistResetAction) pricelistGetReset(ctx: StateContext<ActivePricelistStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
