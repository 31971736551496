import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { ContractsService } from '../../services/business/contracts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContractEditDocumentFailAction, ContractEditDocumentResetAction, ContractEditDocumentSuccessAction, ContractEditDocumentАction } from './contract-edit-document.actions';

export interface ContractEditDocumentStateModel {
    pending: boolean;
    error: ErrorObject;
    edited: boolean;
}

@State<ContractEditDocumentStateModel>({
    name: 'contractEditDocument',
    defaults: { pending: false, error: null, edited: false }
})
@Injectable()
export class ContractEditDocumentState {
    constructor(private contractsService: ContractsService) { }

    @Action(ContractEditDocumentАction) async contractStatusEdit(ctx: StateContext<ContractEditDocumentStateModel>, action: ContractEditDocumentАction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, edited: false });

        return this.contractsService.editContractDocument(action.payload.uid, action.payload.contragentUid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new ContractEditDocumentSuccessAction({ uid: action.payload.uid, contragentUid: action.payload.contragentUid, url: res })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ContractEditDocumentFailAction(error)), 0));
    }

    @Action(ContractEditDocumentSuccessAction) contractEditSuccess(ctx: StateContext<ContractEditDocumentStateModel>, _: ContractEditDocumentSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, edited: true });
    }

    @Action(ContractEditDocumentFailAction) contractEditFail(ctx: StateContext<ContractEditDocumentStateModel>, action: ContractEditDocumentFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload, edited: false });
    }

    @Action(ContractEditDocumentResetAction) contractEditReset(ctx: StateContext<ContractEditDocumentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, edited: false });
    }
}
