import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersErrorsService } from '../../services/business/orders-errors.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { OrdersErrorsAction, OrdersErrorsFailAction, OrdersErrorsResetAction, OrdersErrorsSetFilterAction, OrdersErrorsSuccessAction } from './orders-errors.actions';

import { ErrorListGetFilter, ErrorListGetFilterData } from '../../models/filters/error-list-get-filter.model';
import { ErrorListItem } from '../../models/business/orders-errors/error-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface OrdersErrorsStateModel extends PaginationStateModel<ErrorListItem> {
    uids: string[];
    entities: { [uid: string]: ErrorListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ErrorListGetFilterData;
    total: number;
}

@State<OrdersErrorsStateModel>({
    name: 'ordersErrors',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class OrdersErrorsState extends PaginationStateBase {
    constructor(private ordersErrorsService: OrdersErrorsService) {
        super();
    }

    @Action([OrdersErrorsAction]) productsGet(ctx: StateContext<OrdersErrorsStateModel>, action: OrdersErrorsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ErrorListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.ordersErrorsService.getErrorsList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrdersErrorsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrdersErrorsFailAction(err)), 0));
    }

    @Action(OrdersErrorsSuccessAction) orderGetSuccess(ctx: StateContext<OrdersErrorsStateModel>, action: OrdersErrorsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(OrdersErrorsFailAction) orderGetFail(ctx: StateContext<OrdersErrorsStateModel>, action: OrdersErrorsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrdersErrorsResetAction) orderGetReset(ctx: StateContext<OrdersErrorsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(OrdersErrorsSetFilterAction) usersSetFilter(ctx: StateContext<OrdersErrorsStateModel>, action: OrdersErrorsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, index: 0 });
            setTimeout(() => ctx.dispatch(new OrdersErrorsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
