import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapUpdateRequest } from '../../models/business/technological-maps/technological-map-update-request.model';

export class TechnologicalMapRevertUpdateAction {
    static readonly type = '[Booking Page] RevertUpdateTechnologicalMap';

    constructor(public payload: { uid: string, request: TechnologicalMapUpdateRequest }) { }
}

export class TechnologicalMapRevertUpdateSuccessAction {
    static readonly type = '[TechnologicalMap API] RevertUpdateTechnologicalMap Success';

    constructor(public payload: { uid: string, request: TechnologicalMapUpdateRequest }) { }
}

export class TechnologicalMapRevertUpdateFailAction {
    static readonly type = '[TechnologicalMap API] RevertUpdateTechnologicalMap Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapRevertUpdateResetAction {
    static readonly type = '[Booking Page] RevertUpdateTechnologicalMap Reset';
}
