import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class RecyclingUpdateAction {
    static readonly type = '[Booking Page] UpdateRecycling';

    constructor(public payload: { uid: string, request: DCRCreateOrUpdateRequest }) { }
}

export class RecyclingUpdateSuccessAction {
    static readonly type = '[Recycling API] UpdateRecycling Success';

    constructor(public payload: { uid: string, request: DCRCreateOrUpdateRequest }) { }
}

export class RecyclingUpdateFailAction {
    static readonly type = '[Recycling API] UpdateRecycling Fail';

    constructor(public payload: ErrorObject) { }
}

export class RecyclingUpdateResetAction {
    static readonly type = '[Booking Page] UpdateRecycling Reset';
}
