import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrganizationPosCreateAction, OrganizationPosCreateFailAction, OrganizationPosCreateResetAction, OrganizationPosCreateSuccessAction } from './organization-pos-create.actions';
import { OrganizationPos } from '../../models/business/organization-pos/pos.model';

export interface OrganizationPosCreateStateModel {
    pending: boolean;
    created: OrganizationPos;
    error: ErrorObject;
}

@State<OrganizationPosCreateStateModel>({
    name: 'organizationPosCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class OrganizationPosCreateState {
    constructor(private orgPosService: OrganizationPosService) { }

    @Action(OrganizationPosCreateAction) async posCreate(ctx: StateContext<OrganizationPosCreateStateModel>, action: OrganizationPosCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.orgPosService.createPos({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new OrganizationPosCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosCreateFailAction(err)), 0));
    }

    @Action(OrganizationPosCreateSuccessAction) organizationPosCreateSuccess(ctx: StateContext<OrganizationPosCreateStateModel>, action: OrganizationPosCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(OrganizationPosCreateFailAction) organizationPosCreateFail(ctx: StateContext<OrganizationPosCreateStateModel>, action: OrganizationPosCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(OrganizationPosCreateResetAction) organizationPosCreateReset(ctx: StateContext<OrganizationPosCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
