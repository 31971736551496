import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { RequisitesListFilterData } from '../../models/filters/requisites-list-filter.model';
import { Requisite } from '../../models/business/contragents/requisites.model';

export class RequisitesAction {
    static readonly type = '[Requisites Page] GetRequisites';

    constructor(public payload: PaginationRequest) { }
}

export class RequisitesSuccessAction {
    static readonly type = '[Requisites API] GetRequisites Success';

    constructor(public payload: { list: PaginationResult<Requisite>, pagination: PaginationRequest }) { }
}

export class RequisitesFailAction {
    static readonly type = '[Requisites API] GetRequisites Fail';

    constructor(public payload: ErrorObject) { }
}

export class RequisitesResetAction {
    static readonly type = '[Requisites Page] GetRequisites Reset';
}

export class RequisitesSetFilterAction {
    static readonly type = '[Requisites API] SetFilter';

    constructor(public payload: RequisitesListFilterData) { }
}
