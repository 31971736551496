import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { AreaCreateOrUpdateRequest } from '../../models/business/organization-pos/area-create-or-update-request.model';
import { AreaListItem } from '../../models/business/organization-pos/area-list-item.model';
import { AreaRestrictionCreateOrUpdateRequest } from '../../models/business/organization-pos/area-restriction-create-or-update-request.model';
import { AreaRestrictionListItem } from '../../models/business/organization-pos/area-restriction-list-item.model';
import { Area } from '../../models/business/organization-pos/area.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPosAreasService extends BaseSingletonService {

    private settings: {
        service: {
            getPosArea: string;
            getPosAreas: string;
            createPosArea: string;
            updatePosArea: string;
            deletePosArea: string;

            getPosAreaRestrictions: string;
            updatePosAreaRestrictions: string;
        }
    };

    constructor(private http: HttpService) {
        super('OrganizationPosAreasService');
        this.settings = {
            service: {
                getPosArea: '/corporation/v1/areas/{0}',
                getPosAreas: '/corporation/v1/areas?organizationPosUid={0}&includePoints=true&cityId={1}',
                createPosArea: '/corporation/v1/areas',
                updatePosArea: '/corporation/v1/areas/{0}',
                deletePosArea: '/corporation/v1/areas/{0}',

                getPosAreaRestrictions: '/corporation/v1/area-restrictions?organizationPosUid={0}',
                updatePosAreaRestrictions: '/corporation/v1/area-restrictions/list',
            }
        };
    }

    public async getPosArea(organizationPosUid?: string, cityId?: string): Promise<Area> {
        return this.http.get<{ data: Area }>(String.format(this.settings.service.getPosAreas, organizationPosUid, cityId))
            .then(res => res.data);
    }

    public async getPosAreas(organizationPosUid?: string, cityId?: string): Promise<AreaListItem[]> {
        return this.http.get<{ list: AreaListItem[] }>(String.format(this.settings.service.getPosAreas, organizationPosUid, cityId))
            .then(res => res.list);
    }

    public async createPosArea(request: AreaCreateOrUpdateRequest): Promise<Area> {
        return this.http.post<{ data: Area }>(String.format(this.settings.service.createPosArea), request)
            .then(res => res.data);
    }

    public async updatePosArea(uid: string, request: AreaCreateOrUpdateRequest): Promise<any> {
        return this.http.put(String.format(this.settings.service.updatePosArea, uid), request);
    }

    public async deletePosArea(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePosArea, uid));
    }

    public async getPosAreaRestrictions(organizationPosUid: string): Promise<AreaRestrictionListItem[]> {
        return this.http.get<{ list: AreaRestrictionListItem[] }>(String.format(this.settings.service.getPosAreaRestrictions, organizationPosUid))
            .then(res => res.list);
    }

    public async updatePosAreaRestrictions(request: AreaRestrictionCreateOrUpdateRequest[]): Promise<any> {
        return this.http.post(String.format(this.settings.service.updatePosAreaRestrictions), request);
    }
}
