import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResultWithAmount } from '../../models/business/common/pagination-result-with-amount';
import { AcceptanceCreateOrUpdateRequest } from '../../models/business/acceptances/acceptance-create-or-update-request.model';
import { AcceptanceListGetFilter } from '../../models/filters/acceptance-list-get-filter.model';
import { AcceptanceListItem } from '../../models/business/acceptances/acceptance-list-item.model';
import { Acceptance } from '../../models/business/acceptances/acceptance.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class AcceptancesService extends BaseSingletonService {

    private settings: {
        service: {
            getAcceptance: string;
            getAcceptanceList: string;
            createAcceptance: string;
            updateAcceptance: string;
            deleteAcceptance: string;
        }
    };

    constructor(private http: HttpService) {
        super('AcceptancesService');
        this.settings = {
            service: {
                getAcceptance: '/purchases-aggregation/v1/acceptances/{0}',
                getAcceptanceList: '/purchases-aggregation/v1/acceptances?sortField={0}&sortOrder={1}&pageIndex={2}&pageSize={3}&term={4}',
                createAcceptance: '/purchases-aggregation/v1/acceptances',
                updateAcceptance: '/purchases-aggregation/v1/acceptances/{0}',
                deleteAcceptance: '/purchases/v1/acceptancess/{0}'
            }
        };
    }

    public async getAcceptance(uid: string): Promise<Acceptance> {
        return this.http.get<{ data: Acceptance }>(String.format(this.settings.service.getAcceptance, uid))
            .then(res => res.data);
    }

    public async getAcceptanceList(filter: AcceptanceListGetFilter): Promise<PaginationResultWithAmount<AcceptanceListItem>> {
        return this.http.get<PaginationResultWithAmount<AcceptanceListItem>>(String.format(this.settings.service.getAcceptanceList, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createAcceptance(request: AcceptanceCreateOrUpdateRequest): Promise<Acceptance> {
        return this.http.post<{ data: Acceptance }>(String.format(this.settings.service.createAcceptance), request)
            .then(res => res.data);
    }

    public async updateAcceptance(uid: string, request: AcceptanceCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateAcceptance, uid), request);
    }

    public async deleteAcceptance(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteAcceptance, uid));
    }
}
