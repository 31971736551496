import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class WriteOffWorkbookGetAction {
    static readonly type = '[WriteOffWorkbook Page] GetWriteOffWorkbook Get';

    constructor(public payload: { uid: string, fileName: string }) { }
}

export class WriteOffWorkbookGetSuccessAction {
    static readonly type = '[WriteOffWorkbook API] GetWriteOffWorkbook Success';

    constructor(public payload: Blob) { }
}

export class WriteOffWorkbookFailAction {
    static readonly type = '[WriteOffWorkbook API] GetWriteOffWorkbook Fail';

    constructor(public payload: ErrorObject) { }
}

export class WriteOffWorkbookResetAction {
    static readonly type = '[WriteOffWorkbook Page] GetWriteOffWorkbook Reset';
}
