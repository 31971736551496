import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { FranchiseesService } from '../../services/business/franchisees.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { FranchiseeUpdateAction, FranchiseeUpdateFailAction, FranchiseeUpdateResetAction, FranchiseeUpdateSuccessAction } from './franchisee-update.actions';
import { OrganizationsService } from '../../services/business/organizations.service';

export interface FranchiseeUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<FranchiseeUpdateStateModel>({
    name: 'franchiseeUpdate',
    defaults: { pending: false, updated: false, error: null },
})
@Injectable()
export class FranchiseeUpdateState {
    constructor(private franchiseesService: FranchiseesService, private organizationService: OrganizationsService) {}

    @Action(FranchiseeUpdateAction) async franchiseeUpdate(ctx: StateContext<FranchiseeUpdateStateModel>, action: FranchiseeUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });
        const orgUid = action.payload.defaultOrganizationUid;
        return Promise.all([
            orgUid ? this.organizationService.setDefaultOrganization(action.payload.defaultOrganizationUid) : null,
            this.franchiseesService.updateFranchisee(action.payload.uid, action.payload.request),
        ])
            .then(() => setTimeout(() => ctx.dispatch(new FranchiseeUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeUpdateFailAction(err)), 0));
    }

    @Action(FranchiseeUpdateSuccessAction) franchiseeUpdateSuccess(ctx: StateContext<FranchiseeUpdateStateModel>, _: FranchiseeUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(FranchiseeUpdateFailAction) franchiseeUpdateFail(ctx: StateContext<FranchiseeUpdateStateModel>, action: FranchiseeUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(FranchiseeUpdateResetAction) franchiseeUpdateReset(ctx: StateContext<FranchiseeUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
