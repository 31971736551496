import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DecompositionGetAction {
    static readonly type = '[Decomposition Page] GetDecomposition Get';

    constructor(public payload: string) { }
}

export class DecompositionGetSuccessAction {
    static readonly type = '[Decomposition API] GetDecomposition Success';

    constructor(public payload: DCRItem) { }
}

export class DecompositionFailAction {
    static readonly type = '[Decomposition API] GetDecomposition Fail';

    constructor(public payload: ErrorObject) { }
}

export class DecompositionResetAction {
    static readonly type = '[Decomposition Page] GetDecomposition Reset';
}
