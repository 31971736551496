import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TechnologicalMapByContentAction, TechnologicalMapByContentResetAction, TechnologicalMapByContentSetFilterAction, TechnologicalMapsByContentUpdateItemAction } from './technological-map-by-content.actions';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

@Injectable({
    providedIn: 'root'
})
export class TechnologicalMapByContentDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TechnologicalMapByContentDispatchers');
    }

    public dispatchTechnologicalMapByContentAction(request: PaginationRequest) {
        this.store.dispatch(new TechnologicalMapByContentAction(request));
    }

    public dispatchTechnologicalMapByContentResetAction() {
        this.store.dispatch(new TechnologicalMapByContentResetAction());
    }

    public dispatchTechnologicalMapsByContentUpdateListItemAction(item: TechnologicalMapListItemAggregation) {
        this.store.dispatch(new TechnologicalMapsByContentUpdateItemAction(item));
    }

    public dispatchTechnologicalMapByContentSetFilterAction(filterData: TechnologicalMapListGetFilterData) {
        this.store.dispatch(new TechnologicalMapByContentSetFilterAction(filterData));
    }
}
