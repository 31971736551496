import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TaskCreateOrUpdateRequest } from '../../models/business/tasks/task-create-or-update.model';

export class TaskUpdateAction {
    static readonly type = '[Booking Page] UpdateTask';

    constructor(public payload: { uid: string, request: TaskCreateOrUpdateRequest }) { }
}

export class TaskUpdateSuccessAction {
    static readonly type = '[Task API] UpdateTask Success';

    constructor(public payload: { uid: string, request: TaskCreateOrUpdateRequest }) { }
}

export class TaskUpdateFailAction {
    static readonly type = '[Task API] UpdateTask Fail';

    constructor(public payload: ErrorObject) { }
}

export class TaskUpdateResetAction {
    static readonly type = '[Booking Page] UpdateTask Reset';
}
