import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';

export class TechnologicalMapListGetFilter extends SearchPaginationFilter {
    public productUid?: string;
    public organizationPosUid?: string;
    public isArchived?: boolean;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: TechnologicalMapListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.organizationPosUid = (args && args.filterData) ? args.filterData.organizationPosUid : null;
        this.productUid = (args && args.filterData) ? args.filterData.productUid : null;
        this.isArchived = (args && args.filterData && args.filterData.isArchived != null) ? args.filterData.isArchived : null;
    }
}

export class TechnologicalMapListGetFilterData extends SearchFilterData {
    public organizationPosUid?: string;
    public productUid?: string;
    public pageSize?: number;
    public isArchived?: boolean;
}
