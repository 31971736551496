import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PersonnelsService } from '../../services/business/personnels.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PersonnelDeleteAction, PersonnelDeleteFailAction, PersonnelDeleteResetAction, PersonnelDeleteSuccessAction } from './personnel-delete.actions';

export interface PersonnelDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PersonnelDeleteStateModel>({
    name: 'personnelDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PersonnelDeleteState {
    constructor(private personnelsService: PersonnelsService) { }

    @Action(PersonnelDeleteAction) async personnelDelete(ctx: StateContext<PersonnelDeleteStateModel>, action: PersonnelDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.personnelsService.deletePersonnel(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PersonnelDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PersonnelDeleteFailAction(error)), 0));
    }

    @Action(PersonnelDeleteSuccessAction) personnelDeleteSuccess(ctx: StateContext<PersonnelDeleteStateModel>, _: PersonnelDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PersonnelDeleteFailAction) personnelDeleteFail(ctx: StateContext<PersonnelDeleteStateModel>, action: PersonnelDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PersonnelDeleteResetAction) personnelDeleteReset(ctx: StateContext<PersonnelDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
