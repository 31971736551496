import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { UnitTypeListItem } from '../../models/business/nomenclature/unit-type-list-item.model';


export class UnitTypesAction {
    static readonly type = '[UnitTypes Page] UnitTypes';
}

export class UnitTypesSuccessAction {
    static readonly type = '[UnitTypes API] UnitTypes Success';

    constructor(public payload: UnitTypeListItem[]) { }
}

export class UnitTypesFailAction {
    static readonly type = '[UnitTypes API] UnitTypes Fail';

    constructor(public payload: ErrorObject) { }
}

export class UnitTypesResetAction {
    static readonly type = '[UnitTypes Page] UnitTypes Reset';
}
