import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IntervalsService } from '../../services/business/intervals.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetAvailableListFilter, IntervalGetAvailableListFilterData } from '../../models/filters/interval-get-available-list-filter.model';
import { IntervalShort } from '../../models/business/intervals/interval-short.model';

import { IntervalsAvailableListSearchFailAction, IntervalsAvailableListSearchGetAction, IntervalsAvailableListSearchGetSuccessAction, IntervalsAvailableListSearchResetAction } from './intervals-available-list-search.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface IntervalsAvailableListSearchStateModel {
    uids: number[];
    entities: { [uid: number]: IntervalShort };
    pending: boolean;
    error: ErrorObject;
    filter: IntervalGetAvailableListFilterData;
}

@State<IntervalsAvailableListSearchStateModel>({
    name: 'intervalsAvailableListSearch',
    defaults: { pending: false, uids: [], entities: {}, error: null, filter: null }
})
@Injectable()
export class IntervalsAvailableListSearchState {

    constructor(private intervalsService: IntervalsService) { }

    @Action(IntervalsAvailableListSearchGetAction) intervalGet(ctx: StateContext<IntervalsAvailableListSearchStateModel>, action: IntervalsAvailableListSearchGetAction) {

        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };

        if (!!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            return;
        }

        ctx.setState({ ...state, pending: true, error: null, filter: filterData });

        const filter = new IntervalGetAvailableListFilter({ pageIndex: 0, pageSize: 0, filterData });

        return this.intervalsService.getIntervalsAvailableList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new IntervalsAvailableListSearchGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new IntervalsAvailableListSearchFailAction(err)), 0));
    }

    @Action(IntervalsAvailableListSearchGetSuccessAction) intervalsGetSuccess(ctx: StateContext<IntervalsAvailableListSearchStateModel>, action: IntervalsAvailableListSearchGetSuccessAction) {
        const state = ctx.getState();
        const uids = action.payload.map(p => p.dateFrom);
        const entities = action.payload.reduce((items: { [uid: string]: IntervalShort }, item: IntervalShort) => ({ ...items, [item.dateFrom]: item }), {});
        ctx.setState({ ...state, pending: false, uids, entities, error: null });
    }

    @Action(IntervalsAvailableListSearchFailAction) intervalsGetFail(ctx: StateContext<IntervalsAvailableListSearchStateModel>, action: IntervalsAvailableListSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(IntervalsAvailableListSearchResetAction) intervalsGetReset(ctx: StateContext<IntervalsAvailableListSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, error: null });
    }
}
