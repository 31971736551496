import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PurchaseOrderListGetFilterData } from '../../models/filters/purchase-order-list-get-filter.model';
import { PurchaseOrderListItem } from '../../models/business/purchase-orders/purchase-order-list-item.model';

export class PurchaseOrdersAction {
    static readonly type = '[PurchaseOrderss Page] GetPurchaseOrders';

    constructor(public payload: PaginationRequest) { }
}

export class PurchaseOrdersSuccessAction {
    static readonly type = '[PurchaseOrders API] GetPurchaseOrders Success';

    constructor(public payload: { list: PaginationResult<PurchaseOrderListItem>, pagination: PaginationRequest, totalAmount: number }) { }
}

export class PurchaseOrdersFailAction {
    static readonly type = '[PurchaseOrders API] GetPurchaseOrders Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseOrdersResetAction {
    static readonly type = '[PurchaseOrders Page] GetPurchaseOrders Reset';
}

export class PurchaseOrdersSetFilterAction {
    static readonly type = '[PurchaseOrders API] SetFilter';

    constructor(public payload: PurchaseOrderListGetFilterData) { }
}
