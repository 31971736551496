import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Warehouse } from '../../models/business/warehouses/warehouse.model';

export class WarehouseAction {
    static readonly type = '[Warehouse Page] Warehouse';

    constructor(public payload: { uid: string }) { }
}

export class WarehouseSuccessAction {
    static readonly type = '[Warehouse API] Warehouse Success';

    constructor(public payload: Warehouse) { }
}

export class WarehouseFailAction {
    static readonly type = '[Warehouse API] Warehouse Fail';

    constructor(public payload: ErrorObject) { }
}

export class WarehouseResetAction {
    static readonly type = '[Warehouse Page] Warehouse Reset';
}
