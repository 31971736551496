import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseInvoiceCreateOrUpdateRequest } from '../../models/business/purchase-invoices/purchase-invoice-create-or-update-request.model';

export class PurchaseInvoiceUpdateAction {
    static readonly type = '[Booking Page] UpdatePurchaseInvoice';

    constructor(public payload: { uid: string, request: PurchaseInvoiceCreateOrUpdateRequest }) { }
}

export class PurchaseInvoiceUpdateSuccessAction {
    static readonly type = '[PurchaseInvoice API] UpdatePurchaseInvoice Success';

    constructor(public payload: { uid: string, request: PurchaseInvoiceCreateOrUpdateRequest }) { }
}

export class PurchaseInvoiceUpdateFailAction {
    static readonly type = '[PurchaseInvoice API] UpdatePurchaseInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseInvoiceUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePurchaseInvoice Reset';
}
