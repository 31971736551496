import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { UserDeleteAction } from './user-delete.actions';
import { UsersAction, UsersFailAction, UsersResetAction, UsersSetFilterAction, UsersSuccessAction } from './users.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';
import { UserFilter, UserFilterData } from '../../models/filters/user-filter.model';
import { UserListItem } from '../../models/business/user/user.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface UsersStateModel extends PaginationStateModel<UserListItem> {
    pending: boolean;
    uids: string[];
    entities: { [uid: string]: UserListItem };
    retrieved: boolean;
    error: ErrorObject;
    allItemsLoaded: boolean;
    total: number;
    index: number;
    filter: UserFilterData;
}

@State<UsersStateModel>({
    name: 'users',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class UsersState extends PaginationStateBase {
    constructor(private usersService: UsersService) {
        super();
    }

    @Action([UsersAction]) async usersGet(ctx: StateContext<UsersStateModel>, action: UsersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new UserFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.usersAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.usersService.getUsers(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new UsersSuccessAction({ users: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UsersFailAction(err)), 0));
    }

    @Action(UsersSuccessAction) usersGetSuccess(ctx: StateContext<UsersStateModel>, action: UsersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.users, action.payload.pagination, ExgParamsConfig.pagination.usersAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, total, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(UsersFailAction) usersGetFail(ctx: StateContext<UsersStateModel>, action: UsersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(UsersSetFilterAction) usersSetFilter(ctx: StateContext<UsersStateModel>, action: UsersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new UsersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(UserDeleteAction) deleteUser(ctx: StateContext<UsersStateModel>, action: UserDeleteAction) {
        const state = ctx.getState();
        const uids = state.uids.filter(x => x !== action.payload);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload];
        ctx.setState({ ...state, uids, entities });
    }

    @Action(UsersResetAction) usersGetReset(ctx: StateContext<UsersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }
}
