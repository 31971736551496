import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PricelistUpdateRequest } from '../../models/business/pricelist/pricelist-update-request.model';

export class PricelistUpdateAction {
    static readonly type = '[Booking Page] UpdatePricelist';

    constructor(public payload: { uid: string, request: PricelistUpdateRequest }) { }
}

export class PricelistUpdateSuccessAction {
    static readonly type = '[Pricelist API] UpdatePricelist Success';

    constructor(public payload: { uid: string, request: PricelistUpdateRequest }) { }
}

export class PricelistUpdateFailAction {
    static readonly type = '[Pricelist API] UpdatePricelist Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePricelist Reset';
}
