import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PosCity } from '../../models/business/organization-pos/pos-city.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class PosCitiesService extends BaseSingletonService {

    private settings: {
        service: {
            getPosCities: string;
        }
    };

    constructor(private http: HttpService) {
        super('PosCitiesService');
        this.settings = {
            service: {
                getPosCities: '/corporation/v1/cities'
            }
        };
    }

    public async getPosCities(): Promise<PosCity[]> {
        return this.http.get<{ list: PosCity[] }>(String.format(this.settings.service.getPosCities))
            .then(res => res.list);
    }
}
