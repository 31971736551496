<section class="row-header">
    <bvc-filter-field class="full-name header"
                      *ngIf="listConfig[posSortingFields.FullName]"
                      [class.active]="filter.sortField === posSortingFields.FullName"
                      [name]="'Name1'"
                      [fieldName]="posSortingFields.FullName"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="brand header"
                      *ngIf="listConfig[posSortingFields.Brand]"
                      [class.active]="filter.sortField === posSortingFields.Brand"
                      [name]="'Brand'"
                      [fieldName]="posSortingFields.Brand"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="company header"
                      *ngIf="listConfig[posSortingFields.Company]"
                      [class.active]="filter.sortField === posSortingFields.Company"
                      [name]="'Company'"
                      [fieldName]="posSortingFields.Company"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    
    <bvc-filter-field class="contact header"
                      *ngIf="listConfig[posSortingFields.Contact]"
                      [class.active]="filter.sortField === posSortingFields.Contact"
                      [name]="'Contact'"
                      [fieldName]="posSortingFields.Contact"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="phone header"
                      *ngIf="listConfig[posSortingFields.Phone]"
                      [class.active]="filter.sortField === posSortingFields.Phone"
                      [name]="'Phone'"
                      [fieldName]="posSortingFields.Phone"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>
                                
    <bvc-filter-field class="address header"
                      *ngIf="listConfig[posSortingFields.Address]"
                      [class.active]="filter.sortField === posSortingFields.Address"
                      [name]="'Address'"
                      [fieldName]="posSortingFields.Address"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>
</section>
<section class="row-header shadow">
    <div class="full-name header" *ngIf="listConfig[posSortingFields.FullName]"></div>
    <div class="brand header" *ngIf="listConfig[posSortingFields.Brand]"></div>
    <div class="company header" *ngIf="listConfig[posSortingFields.Company]"></div>
    <div class="contact header" *ngIf="listConfig[posSortingFields.Contact]"></div>
    <div class="phone header" *ngIf="listConfig[posSortingFields.Phone]"></div>
    <div class="address header" *ngIf="listConfig[posSortingFields.Address]"></div>
</section>