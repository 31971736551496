<a exgRipple class="menu_el header" [routerLink]="['/']" routerLinkActive="active" >
    <exg-icon class="logo-icon" [class.logo-square]="headerSettings?.logoSquare" [class.logo-vertical]="headerSettings?.logoVertical" iconName="logo2"></exg-icon>
</a>
<a exgRipple class="menu_el" *ngFor="let menuItem of menu; trackBy: trackByMenu" [routerLink]="[menuItem.route]" [class.bottom]="menuItem.bottom" routerLinkActive="active" >
    <exg-icon class="menu_icon icon_stroke" [iconName]="menuItem.icon"></exg-icon>
    <span>{{menuItem.title | translate}}</span>
</a>

<div class="bottom-items">
    <a exgRipple class="menu_el" *ngFor="let menuItem of bottomMenu; trackBy: trackByMenu" [routerLink]="[menuItem.route]" [class.bottom]="menuItem.bottom" routerLinkActive="active" >
        <exg-icon class="menu_icon icon_stroke" [iconName]="menuItem.icon"></exg-icon>
        <span>{{menuItem.title | translate}}</span>
    </a>
    <a exgRipple class="menu_el" [class.disable-margin]="disableMargin" *ngIf="profile" (click)="onProfileClick()">
        <exg-icon class="menu_icon icon_stroke" iconName="logout"></exg-icon>
        <span>{{'Logout' | translate}}</span>
    </a>
</div>