import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResultWithAmount } from '../../models/business/common/pagination-result-with-amount';
import { SupplierInvoiceCreateOrUpdateRequest } from '../../models/business/supplier-invoices/supplier-invoice-create-or-update-request.model';
import { SupplierInvoiceListGetFilter } from '../../models/filters/supplier-invoice-list-get-filter.model';
import { SupplierInvoiceListItem } from '../../models/business/supplier-invoices/supplier-invoice-list-item.model';
import { SupplierInvoice } from '../../models/business/supplier-invoices/supplier-invoice.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class SupplierInvoicesService extends BaseSingletonService {

    private settings: {
        service: {
            getSupplierInvoice: string;
            getSupplierInvoiceList: string;
            createSupplierInvoice: string;
            updateSupplierInvoice: string;
            deleteSupplierInvoice: string;
        }
    };

    constructor(private http: HttpService) {
        super('SupplierInvoicesService');
        this.settings = {
            service: {
                getSupplierInvoice: '/purchases-aggregation/v1/supplier-invoices/{0}',
                getSupplierInvoiceList: '/purchases-aggregation/v1/supplier-invoices?countryCodes={0}&citiesIds={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}&term={6}',
                createSupplierInvoice: '/purchases-aggregation/v1/supplier-invoices',
                updateSupplierInvoice: '/purchases-aggregation/v1/supplier-invoices/{0}',
                deleteSupplierInvoice: '/purchases/v1/supplier-invoices/{0}'
            }
        };
    }

    public async getSupplierInvoice(uid: string): Promise<SupplierInvoice> {
        return this.http.get<{ data: SupplierInvoice }>(String.format(this.settings.service.getSupplierInvoice, uid))
            .then(res => res.data);
    }

    public async getSupplierInvoiceList(filter: SupplierInvoiceListGetFilter): Promise<PaginationResultWithAmount<SupplierInvoiceListItem>> {
        return this.http.get<PaginationResultWithAmount<SupplierInvoiceListItem>>(String.format(this.settings.service.getSupplierInvoiceList, filter.countryCodes, filter.citiesUids, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createSupplierInvoice(request: SupplierInvoiceCreateOrUpdateRequest): Promise<SupplierInvoice> {
        return this.http.post<{ data: SupplierInvoice }>(String.format(this.settings.service.createSupplierInvoice), request)
            .then(res => res.data);
    }

    public async updateSupplierInvoice(uid: string, request: SupplierInvoiceCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateSupplierInvoice, uid), request);
    }

    public async deleteSupplierInvoice(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteSupplierInvoice, uid));
    }
}
