import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationCatalogService } from '../../services/business/corporation-catalog.service';

import { PublicCorporationCategoriesAction, PublicCorporationCategoriesFailAction, PublicCorporationCategoriesResetAction, PublicCorporationCategoriesSuccessAction } from './public-categories.actions';

import { CategoryListItem } from '../../models/business/corporation-catalog/category-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategoriesListGetAggregationFilter, ProductCategoriesListGetAggregationFilterData } from '../../models/filters/product-categories-list-get-aggregation-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface PublicCorporationCategoriesStateModel {
    entities: CategoryListItem[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: ProductCategoriesListGetAggregationFilterData;
}

@State<PublicCorporationCategoriesStateModel>({
    name: 'corporationPublicCategories',
    defaults: { pending: false, entities: [], retrieved: false, error: null, filter: {} }
})
@Injectable()
export class PublicCorporationCategoriesState {
    constructor(private corporationCatalogService: CorporationCatalogService) {
    }

    @Action([PublicCorporationCategoriesAction]) categoriesGet(ctx: StateContext<PublicCorporationCategoriesStateModel>, action: PublicCorporationCategoriesAction) {
        const state = ctx.getState();

        const filterData = { ...state.filter, ...action.payload };
        if (CompareUtils.isObjectsEqual(state.filter, filterData)) {
            return;
        }

        ctx.setState({ ...state, pending: true, filter: filterData, error: null });
        return this.corporationCatalogService.getPublicCategories(new ProductCategoriesListGetAggregationFilter({ filterData }))
            .then(resp => setTimeout(() => ctx.dispatch(new PublicCorporationCategoriesSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PublicCorporationCategoriesFailAction(err)), 0));
    }

    @Action(PublicCorporationCategoriesSuccessAction) categoriesGetSuccess(ctx: StateContext<PublicCorporationCategoriesStateModel>, action: PublicCorporationCategoriesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(PublicCorporationCategoriesFailAction) categoriesGetFail(ctx: StateContext<PublicCorporationCategoriesStateModel>, action: PublicCorporationCategoriesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PublicCorporationCategoriesResetAction) categoriesGetReset(ctx: StateContext<PublicCorporationCategoriesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null, filter: {} });
    }

}
