
import { AreaListItem } from '../../models/business/organization-pos/area-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosAreasAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreas';

    constructor(public payload: { organizationPosUid?: string, cityId?: string }) { }
}

export class OrganizationPosAreasSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreas Success';

    constructor(public payload: { list: AreaListItem[] }) { }
}

export class OrganizationPosAreasFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreas Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosAreasResetAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreas Reset';
}

