import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { AcceptanceListGetFilterData } from '../../models/filters/acceptance-list-get-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { AcceptancesAction, AcceptancesResetAction, AcceptancesSetFilterAction } from './acceptances.actions';

@Injectable({
    providedIn: 'root'
})
export class AcceptancesDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('AcceptancesDispatchers');
    }

    public dispatchAcceptancesAction(request: PaginationRequest) {
        this.store.dispatch(new AcceptancesAction(request));
    }

    public dispatchAcceptancesResetAction() {
        this.store.dispatch(new AcceptancesResetAction());
    }

    public dispatchAcceptancesSetFilterAction(filterData: AcceptanceListGetFilterData) {
        this.store.dispatch(new AcceptancesSetFilterAction(filterData));
    }
}
