import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ProductsService } from '../../services/business/products.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationStateBase } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilter } from '../../models/filters/products-list-get-filter.model';

import { DeliveryProductsAction, DeliveryProductsFailAction, DeliveryProductsResetAction, DeliveryProductsSuccessAction } from './delivery-products.actions';

export interface DeliveryProductsStateModel {
    entities: ProductListItemAggregation[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<DeliveryProductsStateModel>({
    name: 'DeliveryProducts',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class DeliveryProductsState {
    constructor(private productsService: ProductsService) {
    }

    @Action([DeliveryProductsAction]) DeliveryProductsGet(ctx: StateContext<DeliveryProductsStateModel>, action: DeliveryProductsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ProductsListGetFilter({});
        new PaginationStateBase().applyFilterPagination(PaginationRequest.ReloadFullList, null, filter);

        return this.productsService.getDeliveryProductsList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DeliveryProductsSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DeliveryProductsFailAction(err)), 0));
    }

    @Action(DeliveryProductsSuccessAction) DeliveryProductsGetSuccess(ctx: StateContext<DeliveryProductsStateModel>, action: DeliveryProductsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(DeliveryProductsFailAction) DeliveryProductsGetFail(ctx: StateContext<DeliveryProductsStateModel>, action: DeliveryProductsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DeliveryProductsResetAction) DeliveryProductsGetReset(ctx: StateContext<DeliveryProductsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
