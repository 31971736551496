import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationsService } from '../../services/business/organizations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrganizationCreateAction, OrganizationCreateFailAction, OrganizationCreateResetAction, OrganizationCreateSuccessAction } from './organization-create.actions';
import { Organization } from '../../models/business/organizations/organization.model';

export interface OrganizationCreateStateModel {
    pending: boolean;
    created: Organization;
    error: ErrorObject;
}

@State<OrganizationCreateStateModel>({
    name: 'organizationCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class OrganizationCreateState {
    constructor(private organizationsService: OrganizationsService) { }

    @Action(OrganizationCreateAction) async organizationCreate(ctx: StateContext<OrganizationCreateStateModel>, action: OrganizationCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.organizationsService.createOrganization({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new OrganizationCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationCreateFailAction(err)), 0));
    }

    @Action(OrganizationCreateSuccessAction) organizationCreateSuccess(ctx: StateContext<OrganizationCreateStateModel>, action: OrganizationCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(OrganizationCreateFailAction) organizationCreateFail(ctx: StateContext<OrganizationCreateStateModel>, action: OrganizationCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(OrganizationCreateResetAction) organizationCreateReset(ctx: StateContext<OrganizationCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
