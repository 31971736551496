import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RequisitesCreateOrUpdateRequest } from '../../models/business/contragents/requisites-create-or-update.model';

export class RequisiteUpdateAction {
    static readonly type = '[Requisite Page] UpdateRequisite';

    constructor(public payload: { uid: string, contragentUid: string, request: RequisitesCreateOrUpdateRequest }) { }
}

export class RequisiteUpdateSuccessAction {
    static readonly type = '[Requisite API] UpdateRequisite Success';

    constructor(public payload: { uid: string, contragentUid: string, request: RequisitesCreateOrUpdateRequest }) { }
}

export class RequisiteUpdateFailAction {
    static readonly type = '[Requisite API] UpdateRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class RequisiteUpdateResetAction {
    static readonly type = '[Requisite Page] UpdateRequisite Reset';
}
