import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationRequisitesService } from '../../services/business/corporation-requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CorporationRequisiteCreateAction, CorporationRequisiteCreateFailAction, CorporationRequisiteCreateResetAction, CorporationRequisiteCreateSuccessAction } from './corporation-requisite-create.actions';
import { CorporationRequisite } from '../../models/business/corporation-requisites/corporation-requisites.model';

export interface CorporationRequisiteCreateStateModel {
    pending: boolean;
    created: CorporationRequisite;
    error: ErrorObject;
}

@State<CorporationRequisiteCreateStateModel>({
    name: 'corporationRequisiteCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class CorporationRequisiteCreateState {
    constructor(private corporationRequisitesService: CorporationRequisitesService) { }

    @Action(CorporationRequisiteCreateAction) async corporationCorporationCorporationRequisiteCreate(ctx: StateContext<CorporationRequisiteCreateStateModel>, action: CorporationRequisiteCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.corporationRequisitesService.createRequisite(action.payload.organizationUid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new CorporationRequisiteCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CorporationRequisiteCreateFailAction(err)), 0));
    }

    @Action(CorporationRequisiteCreateSuccessAction) corporationCorporationCorporationRequisiteCreateSuccess(ctx: StateContext<CorporationRequisiteCreateStateModel>, action: CorporationRequisiteCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(CorporationRequisiteCreateFailAction) corporationCorporationCorporationRequisiteCreateFail(ctx: StateContext<CorporationRequisiteCreateStateModel>, action: CorporationRequisiteCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(CorporationRequisiteCreateResetAction) corporationCorporationCorporationRequisiteCreateReset(ctx: StateContext<CorporationRequisiteCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
