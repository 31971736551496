import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContractSortingFieldsEnum } from '../../../../models/business/contract/contract-sorting-fields.model';
import { Contract } from '../../../../models/business/contract/contract.model';
import { ContractsListFilterData } from '../../../../models/filters/contracts-list-filter.model';

@Component({
    selector: 'contract-list-item',
    templateUrl: './contracts-list-item.component.html',
    styleUrls: ['./contracts-list-item.component.scss', '../contracts-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractListItemComponent {

    @Input() contract: Contract;
    @Input() filter: ContractsListFilterData;

    @Output() readonly openContract = new EventEmitter<string>();

    public contractSortingFields = ContractSortingFieldsEnum;

    public onOpenContractClick() {
        this.openContract.emit(this.contract.uid);
    }
}
