import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export class TechnologicalMapByContentAction {
    static readonly type = '[TechnologicalMapByContent Page] GetTechnologicalMapByContent';

    constructor(public payload: PaginationRequest) { }
}

export class TechnologicalMapByContentSuccessAction {
    static readonly type = '[TechnologicalMapByContent API] GetTechnologicalMapByContent Success';

    constructor(public payload: { list: PaginationResult<TechnologicalMapListItemAggregation>, pagination: PaginationRequest }) { }
}

export class TechnologicalMapByContentFailAction {
    static readonly type = '[TechnologicalMapByContent API] GetTechnologicalMapByContent Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapByContentResetAction {
    static readonly type = '[TechnologicalMapByContent Page] GetTechnologicalMapByContent Reset';
}

export class TechnologicalMapsByContentUpdateItemAction {
    static readonly type = '[TechnologicalMaps] Manually Updating';

    constructor(public payload: TechnologicalMapListItemAggregation) { }
}

export class TechnologicalMapByContentSetFilterAction {
    static readonly type = '[TechnologicalMapByContent API] SetFilter';

    constructor(public payload: TechnologicalMapListGetFilterData) { }
}
