import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'company-add',
    templateUrl: './company-add.component.html',
    styleUrls: ['./company-add.component.scss', '../company-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAddComponent {

    @Output() readonly addBtnClick = new EventEmitter<void>();

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }
}
