export enum TaskType {
    /// <summary>
    /// Task
    /// </summary>
    Task = 1,

    /// <summary>
    /// Email
    /// </summary>
    Email,

    /// <summary>
    /// Call
    /// </summary>
    Call,

    /// <summary>
    /// Meeting
    /// </summary>
    Meeting
}
