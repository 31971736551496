import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductImageUpdateRequest } from '../../models/business/catalog/product-image-update.model';

export class ProductImageUpdateAction {
    static readonly type = '[Product Page] UpdateProductImage';

    constructor(public payload: { uid: string, request: ProductImageUpdateRequest }) { }
}

export class ProductImageUpdateSuccessAction {
    static readonly type = '[Product API] UpdateProductImage Success';

    constructor(public payload: { uid: string, request: ProductImageUpdateRequest }) { }
}

export class ProductImageUpdateFailAction {
    static readonly type = '[Product API] UpdateProductImage Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductImageUpdateResetAction {
    static readonly type = '[Product Page] UpdateProductImage Reset';
}
