import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CategoriesSearchAction, CategoriesSearchFailAction, CategoriesSearchResetAction, CategoriesSearchSuccessAction } from './categories-search.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductCategoriesListFilter, ProductCategoriesListFilterData } from '../../models/filters/product-categories-list-filter.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface CategoriesSearchStateModel extends PaginationStateModel<ProductCategory> {
    uids: string[];
    entities: { [uid: string]: ProductCategory };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ProductCategoriesListFilterData;
    total: number;
}

@State<CategoriesSearchStateModel>({
    name: 'categoriesSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class CategoriesSearchState extends PaginationStateBase {
    constructor(private catalogService: CatalogService) {
        super();
    }

    @Action([CategoriesSearchAction]) categoriesSearchGet(ctx: StateContext<CategoriesSearchStateModel>, _: CategoriesSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ProductCategoriesListFilter({ pageIndex: state.index });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.catalogService.getCategories(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CategoriesSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CategoriesSearchFailAction(err)), 0));
    }

    @Action(CategoriesSearchSuccessAction) categoriesSearchGetSuccess(ctx: StateContext<CategoriesSearchStateModel>, action: CategoriesSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadFullList, ExgParamsConfig.pagination.categoriesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CategoriesSearchFailAction) categoriesSearchGetFail(ctx: StateContext<CategoriesSearchStateModel>, action: CategoriesSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CategoriesSearchResetAction) categoriesSearchGetReset(ctx: StateContext<CategoriesSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }
}
