import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TechnologicalMapByContentDispatchers } from '../../../shared/store/technological-maps/technological-map-by-content.dispatchers';
import { TechnologicalMapByContentSelectors } from '../../../shared/store/technological-maps/technological-map-by-content.selectors';
import { TechnologicalMapByProductDispatchers } from '../../../../evasys/shared/store/technological-maps/technological-map-by-product.dispatchers';
import { TechnologicalMapByProductSelectors } from '../../../../evasys/shared/store/technological-maps/technological-map-by-product.selectors';
import { TechnologicalMapCreateDispatchers } from '../../../../evasys/shared/store/technological-maps/technological-map-create.dispatchers';
import { TechnologicalMapCreateSelectors } from '../../../../evasys/shared/store/technological-maps/technological-map-create.selectors';
import { TechnologicalMapListGetFilterData } from '../../../../evasys/shared/models/filters/technological-map-list-get-filter.model';
import { TechnologicalMapRevertUpdateDispatchers } from '../../../shared/store/technological-maps/technological-map-revert-update.dispatchers';
import { TechnologicalMapRevertUpdateSelectors } from '../../../shared/store/technological-maps/technological-map-revert-update.selectors';

import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapCreateRequest } from '../../../../evasys/shared/models/business/technological-maps/technological-map-create-request.model';
import { TechnologicalMapListItemAggregation } from '../../../../evasys/shared/models/business/technological-maps/technological-map-list-item-aggregation.model';
import { TechnologicalMapUpdateRequest } from '../../../../evasys/shared/models/business/technological-maps/technological-map-update-request.model';

@Injectable({
    providedIn: 'root',
})
export class TechnologicalMapsSandbox extends BaseSingletonService {

    public technologicalMapByContent$ = this.technologicalMapByContentSelectors.technologicalMapByContent$;
    public pending$ = this.technologicalMapByContentSelectors.pending$;
    public retrieved$ = this.technologicalMapByContentSelectors.retrieved$;
    public error$ = this.technologicalMapByContentSelectors.error$;
    public filter$ = this.technologicalMapByContentSelectors.filter$;
    public total$ = this.technologicalMapByContentSelectors.total$;

    public technologicalMapUpdated$ = this.technologicalMapUpdateSelectors.updated$;
    public technologicalMapUpdatePending$ = this.technologicalMapUpdateSelectors.pending$;
    public technologicalMapUpdateError$ = this.technologicalMapUpdateSelectors.error$;

    public technologicalMapCreated$ = this.technologicalMapCreateSelectors.created$;
    public technologicalMapCreatePending$ = this.technologicalMapCreateSelectors.pending$;
    public technologicalMapCreateError$ = this.technologicalMapCreateSelectors.error$;

    public technologicalMapByProduct$ = this.technologicalMapByProductSelectors.technologicalMapByProduct$;
    public technologicalMapByProductRetrieved$ = this.technologicalMapByProductSelectors.retrieved$;

    constructor(
        private technologicalMapByContentDispatchers: TechnologicalMapByContentDispatchers,
        private technologicalMapByContentSelectors: TechnologicalMapByContentSelectors,
        private technologicalMapByProductDispatchers: TechnologicalMapByProductDispatchers,
        private technologicalMapByProductSelectors: TechnologicalMapByProductSelectors,
        private technologicalMapUpdateDispatchers: TechnologicalMapRevertUpdateDispatchers,
        private technologicalMapUpdateSelectors: TechnologicalMapRevertUpdateSelectors,
        private technologicalMapCreateDispatchers: TechnologicalMapCreateDispatchers,
        private technologicalMapCreateSelectors: TechnologicalMapCreateSelectors,
    ) {
        super('TechnologicalMapsSandbox');
    }

    public dispatchTechnologicalMapByContentFilter(filter: TechnologicalMapListGetFilterData): void {
        this.technologicalMapByContentDispatchers.dispatchTechnologicalMapByContentSetFilterAction(filter);
    }

    public dispatchTechnologicalMapByProductFilter(filter: TechnologicalMapListGetFilterData): void {
        this.technologicalMapByProductDispatchers.dispatchTechnologicalMapByProductSetFilterAction(filter);
    }

    public dispatchTechnologicalMapByContentAction(request: PaginationRequest): void {
        this.technologicalMapByContentDispatchers.dispatchTechnologicalMapByContentAction(request);
    }

    public dispatchTechnologicalMapByContentReset(): void {
        this.technologicalMapByContentDispatchers.dispatchTechnologicalMapByContentResetAction();
    }

    public dispatchTechnologicalMapUpdate(uid: string, request: TechnologicalMapUpdateRequest): void  {
        this.technologicalMapUpdateDispatchers.dispatchTechnologicalMapRevertUpdateAction(uid, request);
    }

    public dispatchTechnologicalMapUpdateReset(): void  {
        this.technologicalMapUpdateDispatchers.dispatchTechnologicalMapRevertUpdateResetAction();
    }

    public dispatchTechnologicalMapCreate(request: TechnologicalMapCreateRequest): void  {
        this.technologicalMapCreateDispatchers.dispatchTechnologicalMapCreateAction(request);
    }

    public dispatchTechnologicalMapCreateReset(): void  {
        this.technologicalMapCreateDispatchers.dispatchTechnologicalMapCreateResetAction();
    }

    public dispatchTechnologicalMapsByContentUpdateListItem(item: TechnologicalMapListItemAggregation): void  {
        this.technologicalMapByContentDispatchers.dispatchTechnologicalMapsByContentUpdateListItemAction(item);
    }

}
