import { states as baseState } from '../burns-ui-framework/shared/store/reducers';

import { states as evaStates } from '../evasys/shared/store/reducers';

import { CookingCreateState } from './store/cookings/cooking-create.reducer';
import { CookingDeleteState } from './store/cookings/cooking-delete.reducer';
import { CookingUpdateState } from './store/cookings/cooking-update.reducer';
import { CookingState } from './store/cookings/cooking.reducer';
import { CookingsState } from './store/cookings/cookings.reducer';
import { DCRProductState } from './store/dcr/dcr-product.reducer';
import { DecompositionCreateState } from './store/decompositions/decomposition-create.reducer';
import { DecompositionDeleteState } from './store/decompositions/decomposition-delete.reducer';
import { DecompositionUpdateState } from './store/decompositions/decomposition-update.reducer';
import { DecompositionState } from './store/decompositions/decomposition.reducer';
import { DecompositionsState } from './store/decompositions/decompositions.reducer';
import { RecyclingCreateState } from './store/recyclings/recycling-create.reducer';
import { RecyclingDeleteState } from './store/recyclings/recycling-delete.reducer';
import { RecyclingUpdateState } from './store/recyclings/recycling-update.reducer';
import { RecyclingState } from './store/recyclings/recycling.reducer';
import { RecyclingsState } from './store/recyclings/recyclings.reducer';

export const states = [
    ...baseState,

    ...evaStates,

    // YobiEVA states
    CookingCreateState,
    CookingDeleteState,
    CookingUpdateState,
    CookingState,
    CookingsState,
    DCRProductState,
    DecompositionCreateState,
    DecompositionDeleteState,
    DecompositionUpdateState,
    DecompositionState,
    DecompositionsState,
    RecyclingCreateState,
    RecyclingDeleteState,
    RecyclingUpdateState,
    RecyclingState,
    RecyclingsState
];
