<form [formGroup]="mainForm" class="form" [autocomplete]="true" (submit)="$event.preventDefault(); onSubmit()">
    <section class="form-header">
        <div class="navigation">
            <exg-button class="exit-btn" styleType="texted" [excludePadding]="true" color="primary" (btnClick)="onBackClick()">
                <exg-icon class="exit-icon" iconName="arrowleft"></exg-icon>
                {{ 'Back' | translate }}
            </exg-button>
        </div>
        <div class="header-box">
            <div class="title">{{ title | translate }}</div>
            <exg-textbox
                class="text-box-number"
                formControlName="number"
                prefixPlaceholder="№"
                styleType="inline"
                inputType="number"
                [maxlength]="6"
                placeholder="{{ 'Orders_form2' | translate }}"
                [controlsToValidate]="[number]"
                [validate]="validate$ | async"></exg-textbox>
            <exg-datepicker
                class="datepicker"
                formControlName="date"
                styleType="empty"
                placeholder="{{ 'Date from' | translate }}"
                [controlsToValidate]="[date]"
                [validate]="validate$ | async"></exg-datepicker>

            <exg-timepicker
                class="timepicker"
                formControlName="time"
                styleType="empty"
                placeholder="{{ 'Time' | translate }}"
                [controlsToValidate]="[time]"
                [validate]="validate$ | async"></exg-timepicker>

            <div class="status" [ngClass]="dcrStatusEnum[entity?.status || 1]">
                {{ dcrStatusEnum[entity?.status || 1] | translate }}
            </div>

            <exg-button class="cancel" styleType="outlined" (btnClick)="onCancelClick()">{{ 'Cancel' | translate }}</exg-button>
            <exg-button class="submit" type="submit">{{ 'Save' | translate }}</exg-button>
            <bvc-form-header [showMenuButton]="false" />
        </div>
    </section>
    <section class="main-info">
        <div class="main-info-box">
            <exg-select
                class="form-control main-info-control"
                [data]="dcrStatuses$ | async"
                formControlName="status"
                placeholder="{{ 'Status' | translate }}"
                [controlsToValidate]="[status]"
                [validate]="validate$ | async"></exg-select>

            <exg-autocomplete
                class="form-control main-info-control"
                [maxlength]="255"
                [data]="warehouses"
                formControlName="warehouseFrom"
                placeholder="{{ 'Warehouse from' | translate }}"
                [controlsToValidate]="[warehouseFrom]"
                [readonly]="!organization.value?.uid || !organizationPos.value?.uid"
                [displayValueFunction]="warehouseDisplayValueFunction"
                [validate]="validate$ | async"
                (valueChange)="onSearchWarehouses($event)"
                (selectionChange)="onWarehousesSelected($event)"></exg-autocomplete>

            <exg-autocomplete
                class="form-control main-info-control"
                [maxlength]="255"
                [data]="warehouses"
                formControlName="warehouseTo"
                placeholder="{{ 'Warehouse to' | translate }}"
                [controlsToValidate]="[warehouseTo]"
                [readonly]="!organization.value?.uid || !organizationPos.value?.uid"
                [displayValueFunction]="warehouseDisplayValueFunction"
                [validate]="validate$ | async"
                (valueChange)="onSearchWarehouses($event)"
                (selectionChange)="onWarehousesSelected($event)"></exg-autocomplete>
        </div>
        <div class="main-info-box">
            <exg-autocomplete
                class="form-control tripple-control"
                [maxlength]="255"
                [data]="organizationsSearch"
                formControlName="organization"
                placeholder="{{ 'Organization' | translate }}"
                [controlsToValidate]="[organization]"
                [displayValueFunction]="organizationDisplayValueFunction"
                [validate]="validate$ | async"
                (selectionChange)="onOrganizationChanged($event)"
                (valueChange)="onSearchOrganizations($event)"></exg-autocomplete>

            <exg-autocomplete
                class="form-control tripple-control"
                [maxlength]="255"
                [data]="organizationPosesSearch"
                formControlName="organizationPos"
                placeholder="{{ 'Pos' | translate }}"
                [readonly]="!organization.value?.uid"
                [controlsToValidate]="[organizationPos]"
                [displayValueFunction]="posDisplayValueFunction"
                [validate]="validate$ | async"
                (selectionChange)="onPosChanged($event)"
                (valueChange)="onSearchOrganizationPoses($event)"></exg-autocomplete>

            <exg-select
                class="form-control tripple-control"
                [data]="usersSearch"
                formControlName="responsibleUser"
                placeholder="{{ 'Responsible' | translate }}"
                [controlsToValidate]="[responsibleUser]"
                [displayValueFunction]="userDisplayValueFunction"
                [selectValueFunction]="userSelectValueFunction"
                [displayValueFunction]="usersDisplayValueFunction"
                [validate]="validate$ | async"></exg-select>

            <exg-textbox
                class="form-control tripple-control"
                formControlName="notes"
                [maxlength]="3000"
                placeholder="{{ 'Comment' | translate }}"
                [controlsToValidate]="[notes]"
                [validate]="validate$ | async"
                (click)="onOpenNoteDialogClick()"></exg-textbox>
        </div>
        <exg-button *ngIf="this.entity && this.entity?.uid" (btnClick)="onDelete()" styleType="custom" color="warn" class="remove">
            {{ deleteTitle | translate }}
        </exg-button>
    </section>
    <section class="products-from" formArrayName="fromProducts">
        <div class="add-line">
            <div class="add-line-title">
                {{ (isDecompositions ? 'Materials for decompositions' : 'Semi-finished products') | translate }}
            </div>
            <exg-button class="add-row" color="primary" styleType="custom" (btnClick)="onAddFromProduct()">
                <exg-icon class="add-icon" color="primary" iconName="add"></exg-icon>
                <div class="btn-title">{{ 'Add position' | translate }}</div>
            </exg-button>
        </div>
        <div class="products-from-table">
            <div class="header-row">
                <div class="code header">
                    <span class="sorting-field">{{ 'Product code' | translate }}</span>
                </div>
                <div class="products header">
                    <span class="sorting-field">{{ 'Name1' | translate }}</span>
                </div>
                <div class="units header">
                    <span class="sorting-field">{{ 'UnitFull' | translate }}</span>
                </div>
                <div class="amount header">
                    <span class="sorting-field">{{ 'Volume' | translate }}</span>
                </div>
                <div class="cost-unit header">
                    <span class="sorting-field">{{ 'Cost/unit' | translate }}</span>
                </div>
                <div class="total header">
                    <span class="sorting-field">{{ 'Amount' | translate }}</span>
                </div>
                <div class="add-delete-group"></div>
            </div>
            <div
                class="row-item"
                [class.selected]="selectedLine === i"
                *ngFor="let fromProduct of fromProducts.controls; let i = index"
                [formGroupName]="i"
                (click)="onSelectClick(i)">
                <div class="code">
                    <exg-textbox class="table-control" formControlName="code" [readonly]="true" styleType="readonly"></exg-textbox>
                </div>
                <div class="products">
                    <exg-autocomplete
                        class="table-control control"
                        [maxlength]="255"
                        styleType="readonly"
                        [data]="products"
                        [readonly]="!!getControl(fromProduct, 'product').value?.uid"
                        formControlName="product"
                        [displayValueFunction]="productDisplayValueFunction"
                        [selectedDisplayFunction]="productDisplayValueFunction"
                        [displayValueTemplate]="productDisplayValueTemplate"
                        [controlsToValidate]="[getControl(fromProduct, 'product')]"
                        [validate]="(validate$ | async) || (validateList$ | async)"
                        (valueChange)="onSearchProducts($event)"
                        (selectionChange)="onSelectProduct($event, i)"></exg-autocomplete>

                    <ng-template #productDisplayValueTemplate let-val>
                        <div class="product-box">
                            <span>{{ val.name }}</span>
                        </div>
                    </ng-template>
                </div>
                <div class="units">
                    <exg-select
                        class="table-control"
                        [data]="unitTypes"
                        formControlName="unitType"
                        [displayValueFunction]="unitTypeDisplayValueFunction"
                        styleType="readonly"
                        inputType="number"></exg-select>
                </div>
                <div class="amount">
                    <exg-textbox class="table-control" formControlName="amount" styleType="readonly" inputType="number"></exg-textbox>
                </div>
                <div class="cost-unit">
                    <exg-textbox class="table-control" formControlName="price" styleType="readonly" inputType="number"></exg-textbox>
                </div>
                <div class="total">
                    <exg-textbox class="table-control" [readonly]="true" formControlName="total" styleType="readonly" inputType="number"></exg-textbox>
                </div>
                <div class="add-delete-group">
                    <exg-button class="add-group-btn" [excludePadding]="true" styleType="icon" (btnClick)="onDeleteFromProduct(i)">
                        <exg-icon class="remove_icon" iconName="delete"></exg-icon>
                    </exg-button>
                </div>
            </div>
        </div>
    </section>
    <section class="products-to" *ngIf="toProducts.controls.length > 0" formArrayName="toProducts">
        <div class="add-line">
            <div class="add-line-title">
                {{ 'Ingredients' | translate }}
            </div>
        </div>
        <div class="products-to-table">
            <div class="header-row">
                <div class="code header">
                    <span class="sorting-field">{{ 'Product code' | translate }}</span>
                </div>
                <div class="products header">
                    <span class="sorting-field">{{ 'Name1' | translate }}</span>
                </div>
                <div class="units header">
                    <span class="sorting-field">{{ 'UnitFull' | translate }}</span>
                </div>
                <div class="amount header">
                    <span class="sorting-field">{{ 'Volume' | translate }}</span>
                </div>
                <div class="amount-percent">
                    <span class="sorting-field">{{ 'Consumption (%)' | translate }}</span>
                </div>
                <div class="cost-unit header">
                    <span class="sorting-field">{{ 'Cost/unit' | translate }}</span>
                </div>
                <div class="total header">
                    <span class="sorting-field">{{ 'Amount' | translate }}</span>
                </div>
            </div>
            <div class="row-item" *ngFor="let toProduct of toProducts.controls; let i = index" [formGroupName]="i">
                <div class="code">
                    <exg-textbox class="table-control" formControlName="code" [readonly]="true" styleType="readonly"></exg-textbox>
                </div>
                <div class="products">
                    <exg-autocomplete
                        class="table-control control"
                        [maxlength]="255"
                        styleType="readonly"
                        [readonly]="true"
                        formControlName="product"
                        [displayValueFunction]="productDisplayValueFunction"
                        [selectedDisplayFunction]="productDisplayValueFunction"
                        [displayValueTemplate]="productDisplayValueTemplate"
                        [controlsToValidate]="[getControl(toProduct, 'product')]"></exg-autocomplete>

                    <ng-template #productDisplayValueTemplate let-val>
                        <div class="product-box">
                            <span>{{ val.name }}</span>
                        </div>
                    </ng-template>
                </div>
                <div class="units">
                    <exg-select
                        class="table-control"
                        [data]="unitTypes"
                        formControlName="unitType"
                        styleType="readonly"
                        [displayValueFunction]="unitTypeDisplayValueFunction"
                        inputType="number"></exg-select>
                </div>
                <div class="amount">
                    <exg-textbox class="table-control" formControlName="amount" styleType="readonly" inputType="number"></exg-textbox>
                </div>
                <div class="amount-percent">
                    {{ getControl(toProduct, 'percent').value * 100 | exgPercent : '1.0-0' }}
                </div>
                <div class="cost-unit">
                    <exg-textbox class="table-control" formControlName="price" [readonly]="true" styleType="readonly" inputType="number"></exg-textbox>
                </div>
                <div class="total">
                    <exg-textbox class="table-control" [readonly]="true" formControlName="total" styleType="readonly" inputType="number"></exg-textbox>
                </div>
            </div>
        </div>
    </section>
</form>

<exg-dialog
    [componentData]="componentData"
    [dialogMode]="dialogMode.NoButtons"
    [mobileFullScreen]="true"
    [hideBackdrop]="true"
    [dialogPosition]="dialogPosition"
    [showDialog]="showDialog"
    (dialogClose)="onDialogClose($event)"></exg-dialog>
