import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TechnologicalMapByProductAction, TechnologicalMapByProductResetAction, TechnologicalMapByProductSetFilterAction } from './technological-map-by-product.actions';

@Injectable({
    providedIn: 'root'
})
export class TechnologicalMapByProductDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TechnologicalMapByProductDispatchers');
    }

    public dispatchTechnologicalMapByProductAction(request: PaginationRequest) {
        this.store.dispatch(new TechnologicalMapByProductAction(request));
    }

    public dispatchTechnologicalMapByProductResetAction() {
        this.store.dispatch(new TechnologicalMapByProductResetAction());
    }

    public dispatchTechnologicalMapByProductSetFilterAction(filterData: TechnologicalMapListGetFilterData) {
        this.store.dispatch(new TechnologicalMapByProductSetFilterAction(filterData));
    }
}
