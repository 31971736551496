import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PurchaseInvoiceDeleteAction {
    static readonly type = '[My PurchaseInvoice Page] DeletePurchaseInvoice';

    constructor(public payload: { uid: string }) { }
}

export class PurchaseInvoiceDeleteSuccessAction {
    static readonly type = '[My PurchaseInvoices API] DeletePurchaseInvoice Success';

    constructor(public payload: { uid: string }) { }
}

export class PurchaseInvoiceDeleteFailAction {
    static readonly type = '[My PurchaseInvoices API] DeletePurchaseInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseInvoiceDeleteResetAction {
    static readonly type = '[My PurchaseInvoice Page] DeletePurchaseInvoice Reset';
}
