import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContactsAction {
    static readonly type = '[Contacts Page] GetContacts';

    constructor(public payload: PaginationRequest) { }
}

export class ContactsSuccessAction {
    static readonly type = '[Contacts API] GetContacts Success';

    constructor(public payload: { list: PaginationResult<ContactListItem>, pagination: PaginationRequest }) { }
}

export class ContactsFailAction {
    static readonly type = '[Contacts API] GetContacts Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactsResetAction {
    static readonly type = '[Contacts Page] GetContacts Reset';
}

export class ContactsSetFilterAction {
    static readonly type = '[Contacts API] SetFilter';

    constructor(public payload: ContactsListFilterData) { }
}
