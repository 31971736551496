
import { ErrorObject } from '../../shared/models/common/error-object.model';
import { YandexPlaceComponent } from '../models/yandex-place-component.model';

export class PlacesForwardAction {
    static readonly type = '[Yandex Places Forward Page] Get Places Forward';

    constructor(public payload: { uri: string }) { }
}

export class PlacesForwardSuccessAction {
    static readonly type = '[Yandex Places Forward API] Get Places Forward Success';

    constructor(public payload: YandexPlaceComponent[]) { }
}

export class PlacesForwardFailAction {
    static readonly type = '[Yandex Places Forward API] Get Places Forward Fail';

    constructor(public payload: ErrorObject) { }
}

export class PlacesForwardResetAction {
    static readonly type = '[Yandex Places Forward Page] Places Forward Reset';
}
