import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { AcceptanceCreateOrUpdateRequest } from '../../models/business/acceptances/acceptance-create-or-update-request.model';
import { Acceptance } from '../../models/business/acceptances/acceptance.model';

export class AcceptanceCreateAction {
    static readonly type = '[Acceptances Page] CreateAcceptance';

    constructor(public payload: AcceptanceCreateOrUpdateRequest) { }
}

export class AcceptanceCreateSuccessAction {
    static readonly type = '[Acceptance API] CreateAcceptance Success';

    constructor(public payload: Acceptance) { }
}

export class AcceptanceCreateFailAction {
    static readonly type = '[Acceptance API] CreateAcceptance Fail';

    constructor(public payload: ErrorObject) { }
}

export class AcceptanceCreateResetAction {
    static readonly type = '[Acceptances Page] CreateAcceptance Reset';
}
