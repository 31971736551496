import { ChangeDetectionStrategy, Component, Injector, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, combineLatest, filter, map, Subject, takeUntil } from 'rxjs';

import { ExgFormComponent } from '../../../../../burns-ui-framework/shared/components/abstract/exg-form.component';

import { ExgTranslateService } from '../../../../../burns-ui-framework/shared/services/common/exg-translate.service';
import { SnackbarService } from '../../../../../burns-ui-framework/shared/services/common/snackbar.service';

import { CustomValidators } from '../../../../../burns-ui-framework/shared/validators/custom.validators';

import { TimeIntervalsUpdateDispatchers } from '../../../store/intervals/time-intervals-update.dispatchers';
import { TimeIntervalsUpdateSelectors } from '../../../store/intervals/time-intervals-update.selectors';
import { TimeIntervalsDispatchers } from '../../../store/intervals/time-intervals.dispatchers';
import { TimeIntervalsSelectors } from '../../../store/intervals/time-intervals.selectors';

import { BvcIntervalActiveTabEnum } from './bvc-interval-active-tab.enum';
import { BvcIntervalLimitTypeEnum } from './bvc-interval-limit-type.enum';
import { BvcTimeIntervals } from './bvc-time-intervals.enum';
import { DayOfWeek } from '../../../models/business/intervals/day-of-week.enum';
import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogButton } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-button.model';
import { ExgDialogResultEvent } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { IExgDialogable } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialogable.interface';
import { OrganizationPosSettings } from '../../../models/business/intervals/organization-pos-settings.model';

import { Utils } from '../../../../../burns-ui-framework/shared/utils/utils';

@Component({
    selector: 'bvc-interval-form',
    templateUrl: './bvc-interval-form.component.html',
    styleUrls: ['./bvc-interval-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BvcIntervalFormComponent extends ExgFormComponent implements IExgDialogable, OnDestroy {

    public timeIntervals$ = this.timeIntervalsSelectors.timeIntervals$;
    public timeIntervalsPending$ = this.timeIntervalsSelectors.pending$;
    public timeIntervalsError$ = this.timeIntervalsSelectors.error$;

    public timeIntervalsUpdated$ = this.timeIntervalsUpdateSelectors.updated$;
    public timeIntervalsUpdatePending$ = this.timeIntervalsUpdateSelectors.pending$;
    public timeIntervalsUpdateError$ = this.timeIntervalsUpdateSelectors.error$;

    public intervalSize: FormControl;
    public intervalAmount: FormControl;
    public intervalLimitType: FormControl;
    public intervalsArray: FormArray;

    public selectedRestaurants = [];
    public organizationPosUid: string;

    public editState$ = new Subject<boolean>();
    public previourseState$ = new BehaviorSubject(null);

    public activeTab$ = new BehaviorSubject<BvcIntervalActiveTabEnum>(BvcIntervalActiveTabEnum.Mon);
    public tabsEnum = BvcIntervalActiveTabEnum;
    public intervalActiveTabEnum$ = Utils.enumToTranslatedSelectData(BvcIntervalActiveTabEnum, this.translate);

    public intervalLimitTypeEnum$ = Utils.enumToTranslatedSelectData(BvcIntervalLimitTypeEnum, this.translate);
    public timeIntervalsBase$ = Utils.enumToTranslatedSelectData(BvcTimeIntervals, this.translate);

    public intervalLimitTypeIcons = {
        [BvcIntervalLimitTypeEnum.Amount]: 'database',
        // [BvcIntervalLimitTypeEnum.Count1]: 'sushi',
    };

    private unsubscribe$ = new Subject();
    private proceedClose: (_: ExgDialogResultEvent) => void;

    private millisecondsPearDay = 86400000;

    private weekAtSundayStart = {
        [BvcIntervalActiveTabEnum.Mon]: DayOfWeek.Monday,
        [BvcIntervalActiveTabEnum.Tue]: DayOfWeek.Tuesday,
        [BvcIntervalActiveTabEnum.Wed]: DayOfWeek.Wednesday,
        [BvcIntervalActiveTabEnum.Thu]: DayOfWeek.Thursday,
        [BvcIntervalActiveTabEnum.Fri]: DayOfWeek.Friday,
        [BvcIntervalActiveTabEnum.Sat]: DayOfWeek.Saturday,
        [BvcIntervalActiveTabEnum.Sun]: DayOfWeek.Sunday
    };

    private weekAtNormalOrdering = {
        [DayOfWeek.Monday]: BvcIntervalActiveTabEnum.Mon,
        [DayOfWeek.Tuesday]: BvcIntervalActiveTabEnum.Tue,
        [DayOfWeek.Wednesday]: BvcIntervalActiveTabEnum.Wed,
        [DayOfWeek.Thursday]: BvcIntervalActiveTabEnum.Thu,
        [DayOfWeek.Friday]: BvcIntervalActiveTabEnum.Fri,
        [DayOfWeek.Saturday]: BvcIntervalActiveTabEnum.Sat,
        [DayOfWeek.Sunday]: BvcIntervalActiveTabEnum.Sun
    };

    constructor(private formBuilder: FormBuilder,
                private injector: Injector,
                private translate: ExgTranslateService,
                private snackbar: SnackbarService,
                private timeIntervalsUpdateDispatchers: TimeIntervalsUpdateDispatchers,
                private timeIntervalsUpdateSelectors: TimeIntervalsUpdateSelectors,
                private timeIntervalsDispatchers: TimeIntervalsDispatchers,
                private timeIntervalsSelectors: TimeIntervalsSelectors) {
        super();
        this.createForm();
        this.organizationPosUid = this.injector.get('organizationPosUid');
        this.timeIntervalsDispatchers.dispatchTimeIntervalsAction(this.organizationPosUid);
        this.timeIntervals$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe((intervals) => {
            this.setFormData(intervals);
        });

        combineLatest([this.mainForm.valueChanges, this.previourseState$])
            .pipe(map(x => x[0] !== x[1]))
            .subscribe(x => this.editState$.next(x));

        this.timeIntervalsError$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe(error => this.snackbar.showError(error));
        this.timeIntervalsUpdateError$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe(error => this.snackbar.showError(error));

        this.timeIntervalsUpdated$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe(() => {
            this.snackbar.showInfo('Productivity settings successfully saved.');
            this.timeIntervalsUpdateDispatchers.dispatchTimeIntervalsUpdateResetAction();
            this.proceedClose({ dialogResult: DialogResult.Ok });
        });
    }

    public onCloseClick() {
        this.proceedClose({ dialogResult: DialogResult.Close });
    }

    public onNextTab(tab: BvcIntervalActiveTabEnum) {
        if (this.mainForm.valid) {
            this.activeTab$.next(tab);
        }
    }

    public registerOnDialogClose(fn: (_: ExgDialogResultEvent) => void): void {
        this.proceedClose = fn;
    }

    public exgDialogClose(_: ExgDialogButton): any {
        /** no need */
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
        this.timeIntervalsDispatchers.dispatchTimeIntervalsResetAction();
    }

    public addRow(): void {
        const group = this.formBuilder.group({
            time: new FormControl(null, Validators.required),
            maxAmount: new FormControl(null, Validators.required),
            maxCount: new FormControl(null)
        });

        const array = this.getFormArray();

        if (!array.valid) {
            this.validate$.next(true);
            return;
        }

        array.push(group);
    }

    public onDelete(i) {
        this.getFormArray().removeAt(i);
    }

    public getControlByIndex(index: number, controlName: string) : FormControl {
        return <FormControl>(<FormGroup>this.getFormArray().at(index)).controls[controlName];
    }

    public getFormArray(): FormArray {
        return <FormArray>(<FormGroup>this.intervalsArray.at(this.activeTab$.value)).controls.intervals;
    }

    public getControlsForArray(arrIndex: number) {
        return <FormArray>(<FormGroup>this.intervalsArray.at(arrIndex)).controls.intervals;
    }

    public onSelectedRestaurants($event) {
        this.selectedRestaurants = $event.organizationPosUids;
    }

    protected processSubmit(): void {
        const timeIntervals = [];
        const intervalsArrayValue = this.intervalsArray.value;
        for (let index = 0; index < intervalsArrayValue.length; index++) {
            const intervals = intervalsArrayValue[index].intervals;
            for (const interval of intervals) {
                timeIntervals.push({
                    day: this.weekAtSundayStart[index],
                    timeFrom: interval.time.begin,
                    timeTo: interval.time.end,
                    maxAmount: interval.maxAmount,
                    maxCount: interval.maxCount
                });
            }
        }

        const updateInterval = {
            organizationPosUids: [this.organizationPosUid, ...this.selectedRestaurants?.map(x => x.uid).filter(x => x !== this.organizationPosUid)],
            timeIntervals,
            intervalSize: this.intervalSize.value.id,
            intervalAmount: this.intervalAmount.value,
        };

        this.timeIntervalsUpdateDispatchers.dispatchTimeIntervalsUpdateAction(updateInterval);
    }

    private createForm(): void {
        this.intervalLimitType = this.formBuilder.control({ id: BvcIntervalLimitTypeEnum.Amount }, [Validators.required]);
        this.intervalSize = this.formBuilder.control({ id: BvcTimeIntervals.FifteenMinutes }, [Validators.required]);
        this.intervalAmount = this.formBuilder.control(4000, [Validators.required]);

        this.intervalsArray = this.formBuilder.array([]);
        for (let index = 0; index < 7; index++) {
            this.intervalsArray.push(this.formBuilder.group({ intervals: new FormArray([], { validators: [control => CustomValidators.shouldNotOverlappingTime1(control)] }) }));
        }

        this.mainForm = this.formBuilder.group({
            intervalLimitType: this.intervalLimitType,
            intervalSize: this.intervalSize,
            intervalAmount: this.intervalAmount,
            intervalsArray: this.intervalsArray
        });
    }

    private setFormData(organizationPosSettings: OrganizationPosSettings) {
        this.intervalSize.patchValue({ id: organizationPosSettings.intervalSize });
        this.intervalAmount.patchValue(organizationPosSettings.intervalAmount);
        for (const timeInterval of organizationPosSettings.timeIntervals) {
            const index = this.weekAtNormalOrdering[timeInterval.day];
            const group = this.getIntervalGroup(timeInterval.timeFrom, timeInterval.timeTo, timeInterval.maxAmount, timeInterval.maxCount);
            (<FormArray>(<FormGroup>this.intervalsArray.at(index)).controls.intervals).push(group);
        }
    }

    private getIntervalGroup(timeFrom: number, timeTo: number, maxAmount: number, maxCount: number): FormGroup {
        const group = this.formBuilder.group({
            time: new FormControl({ begin: timeFrom, end: timeTo }, Validators.required),
            maxAmount: new FormControl(maxAmount, Validators.required),
            maxCount: new FormControl(maxCount)
        });

        return group;
    }
}
