import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContactsSearchAction {
    static readonly type = '[ContactsSearch Page] GetContactsSearch';

    constructor(public payload: ContactsListFilterData) { }
}

export class ContactsSearchSuccessAction {
    static readonly type = '[ContactsSearch API] GetContactsSearch Success';

    constructor(public payload: PaginationResult<ContactListItem>) { }
}

export class ContactsSearchFailAction {
    static readonly type = '[ContactsSearch API] GetContactsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactsSearchResetAction {
    static readonly type = '[ContactsSearch Page] GetContactsSearch Reset';
}
