import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PurchaseInvoiceListGetFilterData } from '../../models/filters/purchase-invoice-list-get-filter.model';
import { PurchaseInvoiceListItem } from '../../models/business/purchase-invoices/purchase-invoice-list-item.model';

export class PurchaseInvoicesAction {
    static readonly type = '[PurchaseInvoicess Page] GetPurchaseInvoices';

    constructor(public payload: PaginationRequest) { }
}

export class PurchaseInvoicesSuccessAction {
    static readonly type = '[PurchaseInvoices API] GetPurchaseInvoices Success';

    constructor(public payload: { list: PaginationResult<PurchaseInvoiceListItem>, pagination: PaginationRequest, totalAmount: number, totalPaidAmount: number }) { }
}

export class PurchaseInvoicesFailAction {
    static readonly type = '[PurchaseInvoices API] GetPurchaseInvoices Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseInvoicesResetAction {
    static readonly type = '[PurchaseInvoices Page] GetPurchaseInvoices Reset';
}

export class PurchaseInvoicesSetFilterAction {
    static readonly type = '[PurchaseInvoices API] SetFilter';

    constructor(public payload: PurchaseInvoiceListGetFilterData) { }
}
