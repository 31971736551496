import { BrandCreateOrUpdateRequest } from '../../models/business/brands/brand-create-or-update.model';
import { Brand } from '../../models/business/brands/brand.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class BrandCreateAction {
    static readonly type = '[Brands Page] CreateBrand';

    constructor(public payload: { request: BrandCreateOrUpdateRequest }) { }
}

export class BrandCreateSuccessAction {
    static readonly type = '[Brand API] CreateBrand Success';

    constructor(public payload: Brand) { }
}

export class BrandCreateFailAction {
    static readonly type = '[Brand API] CreateBrand Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandCreateResetAction {
    static readonly type = '[Brands Page] CreateBrand Reset';
}
