import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DocumentModel } from '../../../../models/business/feed/document.model';
import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Feed } from '../../../../models/business/feed/feed.model';
import { NoteUpdateRequest } from '../../../../models/business/notes/note-update-request.model';

@Component({
    selector: 'bvc-clients-activity-note',
    templateUrl: './clients-activity-note.component.html',
    styleUrls: ['./clients-activity-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityNoteComponent {
    @Input() feedItem: Feed;
    @Input() error: ErrorObject;
    @Input() companyUid: string;

    @Output() readonly noteUpdate = new EventEmitter<{ uid: string, request: NoteUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }>();
    @Output() readonly noteDelete = new EventEmitter<{ uid: string }>();

    public editState = false;
    public menuItems = [
        { id: 'edit', title: 'Edit' },
        { id: 'delete', title: 'Delete' }
    ];

    public onNoteUpdate($event: { uid: string, request: NoteUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }) {
        this.noteUpdate.emit($event);
    }

    public trackByDocuments(_, item: DocumentModel) {
        return item.uid;
    }

    public onMenuItemClick(event: 'edit' | 'delete') {
        switch (event) {
            case 'edit':
                this.editState = true;
                break;
            case 'delete':
                this.noteDelete.emit({ uid: this.feedItem.uid });
                break;
        }
    }
}
