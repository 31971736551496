import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { IntervalCreateOrUpdateRequest } from '../../models/business/intervals/interval-create-or-update-request.model';
import { IntervalGetAvailableListFilter } from '../../models/filters/interval-get-available-list-filter.model';
import { IntervalGetFilter } from '../../models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../models/business/intervals/interval-list-item.model';
import { IntervalShort } from '../../models/business/intervals/interval-short.model';
import { OrganizationPosSettingsUpdateRequest } from '../../models/business/intervals/organization-pos-settings-update-request.model';
import { OrganizationPosSettings } from '../../models/business/intervals/organization-pos-settings.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class IntervalsService extends BaseSingletonService {

    private settings: {
        service: {
            getIntervals: string;
            getIntervalsAvailableList: string;
            updateInterval: string;

            getTimeIntervals: string;
            updateTimeIntervals: string;
        }
    };

    constructor(private http: HttpService) {
        super('IntervalsService');
        this.settings = {
            service: {
                getIntervals: '/productivity/v1/intervals?date={0}&organizationPosUid={1}',
                getIntervalsAvailableList: '/productivity/v1/intervals/available-list?organizationPosUid={0}&dateTime={1}&amount={2}',
                updateInterval: '/productivity/v1/intervals',

                getTimeIntervals: '/productivity/v1/organization-pos-settings?organizationPosUid={0}',
                updateTimeIntervals: '/productivity/v1/organization-pos-settings'
            }
        };
    }

    public async getIntervals(filter: IntervalGetFilter): Promise<IntervalListItem[]> {
        return this.http.get<{ list: IntervalListItem[] }>(String.format(this.settings.service.getIntervals, filter.date, filter.organizationPosUid))
            .then(res => res.list);
    }

    public async getIntervalsAvailableList(filter: IntervalGetAvailableListFilter): Promise<IntervalShort[]> {
        return this.http.get<{ list: IntervalShort[] }>(String.format(this.settings.service.getIntervalsAvailableList, filter.organizationPosUid, filter.dateTime, filter.amount))
            .then(res => res.list);
    }

    public async updateInterval(request: IntervalCreateOrUpdateRequest): Promise<IntervalListItem[]> {
        return this.http.put<{ list: IntervalListItem[] }>(String.format(this.settings.service.updateInterval), request)
            .then(res => res.list);
    }

    public getTimeIntervals(organizationPosUid: string): Promise<OrganizationPosSettings> {
        return this.http.get<{ data: OrganizationPosSettings }>(String.format(this.settings.service.getTimeIntervals, organizationPosUid))
            .then(res => res.data);
    }

    public updateTimeIntervals(request: OrganizationPosSettingsUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateTimeIntervals), request);
    }
}
