import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FullnamePart } from '../../models/business/suggestions/fullname-part.enum';
import { SuggestionFullName } from '../../models/business/suggestions/suggestion-full-name.model';

export class FullNameSuggestionGetAction {
    static readonly type = '[FullNameSuggestion Page] GetFullNameSuggestion Get';

    constructor(public payload: { term: string, parts: FullnamePart[] }) { }
}

export class FullNameSuggestionGetSuccessAction {
    static readonly type = '[FullNameSuggestion API] GetFullNameSuggestion Success';

    constructor(public payload: { list: SuggestionFullName[], parts: FullnamePart[] }) { }
}

export class FullNameSuggestionFailAction {
    static readonly type = '[FullNameSuggestion API] GetFullNameSuggestion Fail';

    constructor(public payload: ErrorObject) { }
}

export class FullNameSuggestionResetAction {
    static readonly type = '[FullNameSuggestion Page] GetFullNameSuggestion Reset';
}
