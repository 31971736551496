import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseOrdersService } from '../../services/business/purchase-orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseOrderCreateOrUpdateRequest } from '../../models/business/purchase-orders/purchase-order-create-or-update-request.model';

import { PurchaseOrderUpdateAction, PurchaseOrderUpdateFailAction, PurchaseOrderUpdateResetAction, PurchaseOrderUpdateSuccessAction } from './purchase-order-update.actions';

export interface PurchaseOrderUpdateStateModel {
    pending: boolean;
    updated: PurchaseOrderCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<PurchaseOrderUpdateStateModel>({
    name: 'purchaseOrderUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class PurchaseOrderUpdateState {
    constructor(private purchaseOrdersService: PurchaseOrdersService) { }

    @Action(PurchaseOrderUpdateAction) async purchaseOrderUpdate(ctx: StateContext<PurchaseOrderUpdateStateModel>, action: PurchaseOrderUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.purchaseOrdersService.updatePurchaseOrder(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PurchaseOrderUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseOrderUpdateFailAction(err)), 0));
    }

    @Action(PurchaseOrderUpdateSuccessAction) purchaseOrderUpdateSuccess(ctx: StateContext<PurchaseOrderUpdateStateModel>, action: PurchaseOrderUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(PurchaseOrderUpdateFailAction) purchaseOrderUpdateFail(ctx: StateContext<PurchaseOrderUpdateStateModel>, action: PurchaseOrderUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(PurchaseOrderUpdateResetAction) purchaseOrderUpdateReset(ctx: StateContext<PurchaseOrderUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
