import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CompaniesService } from '../../services/business/companies.service';
import { MotivationsService } from '../../services/business/motivations.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { MotivationBonusesContactsAction, MotivationBonusesContactsFailAction, MotivationBonusesContactsResetAction, MotivationBonusesContactsSetFilterAction, MotivationBonusesContactsSuccessAction } from './motivation-bonuses-contacts.actions';

import { Company } from '../../models/business/companies/company.model';
import { ContactsMotivationBonus } from '../../models/business/contacts/contacts-motivation-bonus.model';
import { ContactsMotivationBonusesListGetFilter, ContactsMotivationBonusesListGetFilterData } from '../../models/filters/contacts-motivation-bonuses-list-get-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';
import { MotivationBonusUpdateListSuccessAction } from './motivation-bonus-update-list.actions';

export interface MotivationBonusesContactsStateModel extends PaginationStateModel<ContactsMotivationBonus> {
    uids: string[];
    entities: { [uid: string]: ContactsMotivationBonus };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ContactsMotivationBonusesListGetFilterData;
    total: number;
    company: Company;
}

@State<MotivationBonusesContactsStateModel>({
    name: 'motivationBonusesContacts',
    defaults: { pending: false, uids: [], entities: {}, company: null, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class MotivationBonusesContactsState extends PaginationStateBase {
    constructor(private motivationsService: MotivationsService, private companiesService: CompaniesService) {
        super();
    }

    @Action([MotivationBonusesContactsAction]) contactsGet(ctx: StateContext<MotivationBonusesContactsStateModel>, action: MotivationBonusesContactsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContactsMotivationBonusesListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.motivationsService.getMotivationContacts(state.company.uid, filter)
            .then(resp => setTimeout(() => ctx.dispatch(new MotivationBonusesContactsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusesContactsFailAction(err)), 0));
    }

    @Action(MotivationBonusesContactsSuccessAction) contactsGetSuccess(ctx: StateContext<MotivationBonusesContactsStateModel>, action: MotivationBonusesContactsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(MotivationBonusesContactsFailAction) contactsGetFail(ctx: StateContext<MotivationBonusesContactsStateModel>, action: MotivationBonusesContactsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(MotivationBonusesContactsResetAction) contactsGetReset(ctx: StateContext<MotivationBonusesContactsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, company: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(MotivationBonusesContactsSetFilterAction) usersSetFilter(ctx: StateContext<MotivationBonusesContactsStateModel>, action: MotivationBonusesContactsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload.filter };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
        }

        return this.companiesService.getCompany(action.payload.uid)
            .then(resp => setTimeout(() => {
                const newState = ctx.getState();
                ctx.setState({ ...newState, company: resp });
                ctx.dispatch(new MotivationBonusesContactsAction(PaginationRequest.ReloadCurrentList));
            }, 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusesContactsFailAction(err)), 0));
    }

    @Action(MotivationBonusUpdateListSuccessAction) motivationBonusUpdateSuccess(ctx: StateContext<MotivationBonusesContactsStateModel>, _: MotivationBonusUpdateListSuccessAction) {
        setTimeout(() => ctx.dispatch(new MotivationBonusesContactsAction(PaginationRequest.ReloadCurrentList)), 0);
    }
}
