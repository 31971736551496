import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WarehouseCreateOrUpdateRequest } from '../../models/business/warehouses/warehouse-create-or-update-request.model';

export class WarehouseUpdateAction {
    static readonly type = '[WarehouseUpdate Page] WarehouseUpdate';

    constructor(public payload: { request: WarehouseCreateOrUpdateRequest[] }) { }
}

export class WarehouseUpdateSuccessAction {
    static readonly type = '[WarehouseUpdate API] WarehouseUpdate Success';
}

export class WarehouseUpdateFailAction {
    static readonly type = '[WarehouseUpdate API] WarehouseUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class WarehouseUpdateResetAction {
    static readonly type = '[WarehouseUpdate Page] WarehouseUpdate Reset';
}
