import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PostingWorkbookGetAction {
    static readonly type = '[PostingWorkbook Page] GetPostingWorkbook Get';

    constructor(public payload: { uid: string, fileName: string }) { }
}

export class PostingWorkbookGetSuccessAction {
    static readonly type = '[PostingWorkbook API] GetPostingWorkbook Success';

    constructor(public payload: Blob) { }
}

export class PostingWorkbookFailAction {
    static readonly type = '[PostingWorkbook API] GetPostingWorkbook Fail';

    constructor(public payload: ErrorObject) { }
}

export class PostingWorkbookResetAction {
    static readonly type = '[PostingWorkbook Page] GetPostingWorkbook Reset';
}
