import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { GeoService } from '../../../../burns-ui-framework/geocoding/services/geo.service';

import { CompanyCityAction, CompanyCityFailAction, CompanyCityResetAction, CompanyCitySuccessAction } from './company-city.actions';

import { City } from '../../models/business/geocoding/city.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationStateBase } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

export interface CompanyCityStateModel {
    pending: boolean;
    uids: string[];
    entities: { [uid: string]: City };
    retrieved: boolean;
    error: ErrorObject;
}

@State<CompanyCityStateModel>({
    name: 'companyCity',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null }
})
@Injectable()
export class CompanyCityState extends PaginationStateBase {
    constructor(private geoService: GeoService) {
        super();
    }

    @Action([CompanyCityAction]) async usersGet(ctx: StateContext<CompanyCityStateModel>, _: CompanyCityAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.geoService.getCityList()
            .then(resp => setTimeout(() => ctx.dispatch(new CompanyCitySuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompanyCityFailAction(err)), 0));
    }

    @Action(CompanyCitySuccessAction) usersGetSuccess(ctx: StateContext<CompanyCityStateModel>, action: CompanyCitySuccessAction) {
        const state = ctx.getState();
        const uids = action.payload.map(p => p.cityId);
        const entities = action.payload.reduce((items: { [uid: string]: City }, item: City) => ({ ...items, [item.cityId]: item }), {});
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null });
    }

    @Action(CompanyCityFailAction) usersGetFail(ctx: StateContext<CompanyCityStateModel>, action: CompanyCityFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CompanyCityResetAction) usersGetReset(ctx: StateContext<CompanyCityStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null });
    }
}
