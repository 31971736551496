
import { AreaCreateOrUpdateRequest } from '../../models/business/organization-pos/area-create-or-update-request.model';
import { AreaRestrictionCreateOrUpdateRequest } from '../../models/business/organization-pos/area-restriction-create-or-update-request.model';
import { Area } from '../../models/business/organization-pos/area.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosAreaCreateAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaCreate';

    constructor(public payload: { request: AreaCreateOrUpdateRequest, restrictions: AreaRestrictionCreateOrUpdateRequest[] }) { }
}

export class OrganizationPosAreaCreateSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaCreate Success';

    constructor(public payload: Area) { }
}

export class OrganizationPosAreaCreateFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaCreate Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosAreaCreateResetAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaCreate Reset';
}

