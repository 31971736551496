import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { BrandsService } from '../../services/business/brands.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { BrandAction, BrandFailAction, BrandResetAction, BrandSuccessAction } from './brand.actions';
import { Brand } from '../../models/business/brands/brand.model';

export interface BrandStateModel {
    pending: boolean;
    entity: Brand;
    error: ErrorObject;
}

@State<BrandStateModel>({
    name: 'brand',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class BrandState {
    constructor(private brandsService: BrandsService) { }

    @Action(BrandAction) async brand(ctx: StateContext<BrandStateModel>, action: BrandAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.brandsService.getBrand(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new BrandSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new BrandFailAction(err)), 0));
    }

    @Action(BrandSuccessAction) brandSuccess(ctx: StateContext<BrandStateModel>, action: BrandSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(BrandFailAction) brandFail(ctx: StateContext<BrandStateModel>, action: BrandFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(BrandResetAction) brandReset(ctx: StateContext<BrandStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
