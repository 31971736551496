import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class PosContactsAction {
    static readonly type = '[PosContacts Page] GetPosContacts';

    constructor(public payload: PaginationRequest) { }
}

export class PosContactsSuccessAction {
    static readonly type = '[PosContacts API] GetPosContacts Success';

    constructor(public payload: { list: PaginationResult<ContactListItem>, pagination: PaginationRequest }) { }
}

export class PosContactsFailAction {
    static readonly type = '[PosContacts API] GetPosContacts Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosContactsResetAction {
    static readonly type = '[PosContacts Page] GetPosContacts Reset';
}

export class PosContactsSetFilterAction {
    static readonly type = '[PosContacts API] SetFilter';

    constructor(public payload: ContactsListFilterData) { }
}
