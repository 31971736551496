import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';
import { RestaurantProductListGetFilter } from '../../models/filters/restaurant-product-list-get-filter.model';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';
import { RestaurantProductListItem } from '../../models/business/restaurant-products/restaurant-product-list-item.model';
  
@Injectable({
    providedIn: 'root'
})
export class RestaurantProductService extends BaseSingletonService {

    private settings: {
        service: {
            getRestaurantProducts: string;
            getRestaurantProductsList: string;
            createRestaurantProducts: string;
            updateRestaurantProducts: string;
            deleteRestaurantProducts: string;
        }
    };

    constructor(private http: HttpService) {
        super('RestaurantProductService');
        this.settings = {
            service: {
                getRestaurantProducts: '/corporation-aggregation/v1/catalog/products/{0}',
                getRestaurantProductsList: '/corporation-aggregation/v1/catalog/products?&organizationPosUid={0}&categoryUids={1}&isPublic={2}&isArchived={3}',
                createRestaurantProducts: '/corporation-aggregation/v1/catalog/products',
                updateRestaurantProducts: '/corporation-aggregation/v1/catalog/products/{0}',
                deleteRestaurantProducts: '/corporation-aggregation/v1/catalog/products/{0}',
            }
        };
    }

    public async getRestaurantProductList(filter: RestaurantProductListGetFilter): Promise<PaginationResult<RestaurantProductListItem>> {
        
        return this.http.get<PaginationResult<RestaurantProductListItem>>(String.format(
            this.settings.service.getRestaurantProductsList,
            filter.organizationPosUid,
            filter.categoryUids,
            filter.isPublic,
            filter.isArchived,
            filter.pageIndex,
            filter.pageSize,
            filter.term,
        ))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }


    public async deleteRestaurantProduct(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRestaurantProducts, uid));
    }

}
