import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { PriceTypeListAction, PriceTypeListFailAction, PriceTypeListResetAction, PriceTypeListSuccessAction } from './price-type-list.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PriceTypeListGetFilter } from '../../models/filters/price-type-list-get-filter.model';
import { PriceType } from '../../models/business/pricelist/price-type.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface PriceTypeListStateModel extends PaginationStateModel<PriceType> {
    uids: string[];
    entities: { [uid: string]: PriceType };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<PriceTypeListStateModel>({
    name: 'priceTypeList',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class PriceTypeListState extends PaginationStateBase {
    constructor(private pricelistService: PricelistService) {
        super();
    }

    @Action([PriceTypeListAction]) pricelistGet(ctx: StateContext<PriceTypeListStateModel>, action: PriceTypeListAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new PriceTypeListGetFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.pricelistService.getPriceTypeList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PriceTypeListSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PriceTypeListFailAction(err)), 0));
    }

    @Action(PriceTypeListSuccessAction) pricelistGetSuccess(ctx: StateContext<PriceTypeListStateModel>, action: PriceTypeListSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadFullList, ExgParamsConfig.pagination.pricelistAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(PriceTypeListFailAction) pricelistGetFail(ctx: StateContext<PriceTypeListStateModel>, action: PriceTypeListFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PriceTypeListResetAction) pricelistGetReset(ctx: StateContext<PriceTypeListStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
