import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '../../../../../burns-ui-framework/shared/services/business/auth.service';
import { ExgTranslateService } from '../../../../../burns-ui-framework/shared/services/common/exg-translate.service';

import { CompanyCityDispatchers } from '../../../store/companies/company-city.dispatchers';
import { CompanyCitySelectors } from '../../../store/companies/company-city.selectors';
import { UiSelectors } from '../../../../../burns-ui-framework/shared/store/ui/ui.selectors';
import { UserSearchDispatchers } from '../../../store/users/users-search.dispatchers';
import { UserSearchSelectors } from '../../../store/users/users-search.selectors';

import { CompanyStatus } from '../../../models/business/companies/company-status.enum';
import { CompanyType } from '../../../models/business/companies/company-type.enum';
import { GroupBy } from '../../../models/business/data-grouping.enum';
import { GraphType } from '../bvc-graph/graph-type.enum';
import { Period, PeriodExtended } from '../../../models/business/period-selection.enum';
import { Roles } from '../../../../../burns-ui-framework/shared/models/common/roles.model';
import { RoutingConfig } from '../../../routing.config';

import { DateUtils } from '../../../../../burns-ui-framework/shared/utils/date-utils';
import { Utils } from '../../../../../burns-ui-framework/shared/utils/utils';

@Component({
    selector: 'bvc-analytics',
    templateUrl: './bvc-analytics.component.html',
    styleUrls: ['./bvc-analytics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcAnalyticsComponent implements OnChanges, OnDestroy {

    @Input() companyUid: string;
    @Input() contragentUid: string;
    @Input() posUid: string;
    @Input() selectAll: boolean;
    @Input() mainPage: boolean;

    public cultureChanged$ = this.uiSelectors.culture$;

    public usersSearch$ = this.userSearchSelectors.users$.pipe(map(u => u.map(x => ({ id: x.uid, text: x.fullName }))));
    public cities$ = this.companyCitySelectors.cities$.pipe(map(u => u.map(x => ({ id: x.cityId, text: x.name }))));

    public period = { id: PeriodExtended.Year, text: PeriodExtended[PeriodExtended.Year] };
    public group: { id: GroupBy, text?: any } = { id: GroupBy.Week, text: GroupBy[GroupBy.Week] };
    public responsibleUsersUids: { id: string, text: string }[];
    public citiesIds: { id: string, text: string }[];
    public countryCodes: any[];
    public dateRange = null;
    public graphTypeEnum = GraphType;
    public periodEnum = PeriodExtended;
    public periodSelection = Utils.enumToSelectData(PeriodExtended);
    public groupSelection = [GroupBy.Month, GroupBy.Week, GroupBy.Day].map(x => ({ id: x, text: GroupBy[x] }));

    public clientBase$ = new BehaviorSubject([]);
    public clientTypes$ = new BehaviorSubject([]);
    public productsData$ = new BehaviorSubject([]);
    public solidGaugeData$ = new BehaviorSubject([]);

    public ordersViewAny = this.authService.isAuthorized(Roles.ordersViewAny);
    public usersViewAny = this.authService.isAuthorized(Roles.usersViewAny);

    public dataSeries = [];

    constructor(
                private userSearchDispatchers: UserSearchDispatchers,
                private userSearchSelectors: UserSearchSelectors,
                private companyCityDispatchers: CompanyCityDispatchers,
                private companyCitySelectors: CompanyCitySelectors,
                private uiSelectors: UiSelectors,
                private router: Router,
                private authService: AuthService,
                private translate: ExgTranslateService) {
        this.userSearchDispatchers.dispatchUserSearchAction();
        this.companyCityDispatchers.dispatchCompanyCityAction();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes.companyUid && this.companyUid) || (changes.contragentUid && this.contragentUid) || (changes.posUid && this.posUid)) {
            this.requestData();
        }

        if (changes.selectAll && this.selectAll) {
            this.requestData();
        }
    }

    ngOnDestroy(): void {
        this.companyCityDispatchers.dispatchCompanyCityResetAction();
        this.userSearchDispatchers.dispatchUserSearchResetAction();
    }

    public onPeriodChange($event) {
        this.period = $event;
        this.setGroupSelection();
        if (this.group) {
            this.requestData();
        }
    }

    public onGroupChange($event) {
        this.group = $event;
        this.requestData();
    }

    public getOrderData(data) {
        return data.map(x => x.value);
    }

    public onDatesRangeChanged($event) {
        this.dateRange = $event;
        this.requestData();
    }

    public onUserChanged($event) {
        this.responsibleUsersUids = $event && $event.length > 0 ? $event : null;
        this.requestData();
    }

    public onCityChanged($event) {
        this.citiesIds = $event && $event.length > 0 ? $event : null;
        this.requestData();
    }

    public onCountriesChanged($event) {
        this.countryCodes = $event && $event.length > 0 ? $event : null;
        this.requestData();
    }

    public countryDisplayValueFunction(item) {
        return item.name;
    }

    public onOpenCompany($event: string) {
        this.router.navigate([RoutingConfig.routes.clients.companies.edit.fullUrl, $event]);
    }

    public getManagerState(amount, revenue) {
        if (revenue < 1) {
            return 'green';
        }

        const planState = amount / revenue;
        if (planState <= 50) {
            return 'red';
        }

        if (planState <= 80) {
            return 'yellow';
        }

        return 'green';
    }

    public getDifferencesPersent(value, diff) {
        return value && value !== 0 ? Math.abs(((value / (value - diff)) - 1) * 100) : 0;
    }

    private requestData() {
        if (this.period && this.period.id && this.group && this.group.id && ((this.period.id === PeriodExtended.Custom && this.dateRange) || this.period.id !== PeriodExtended.Custom)) {
            const dates = this.getPeriods();
            const responsibleUsersUids = this.responsibleUsersUids ? this.responsibleUsersUids.map(x => x.id) : null;
            const citiesIds = this.citiesIds ? this.citiesIds.map(x => x.id) : null;
            const countryCodes = this.countryCodes ? this.countryCodes.map(x => x.code) : null;
        }
    }

    private setGroupSelection() {
        if (this.period.id === PeriodExtended.Custom) {
            this.group = { id: GroupBy.Day, text: GroupBy[GroupBy.Day] };
            this.dateRange = null;
            this.groupSelection = Utils.enumToSelectData(GroupBy);
        }

        switch (this.period.id) {
            case PeriodExtended.Year:
                this.group = !this.group
                    ? { id: GroupBy.Month, text: GroupBy[GroupBy.Month] }
                    : this.group.id > GroupBy.Month
                        ? { id: GroupBy.Month, text: GroupBy[GroupBy.Month] }
                        : this.group;

                this.groupSelection = [GroupBy.Month, GroupBy.Week, GroupBy.Day].map(x => ({ id: x, text: GroupBy[x] }));
                break;

            case PeriodExtended.Month:
                this.group = !this.group
                    ? { id: GroupBy.Week, text: GroupBy[GroupBy.Week] }
                    : this.group.id > GroupBy.Week
                        ? { id: GroupBy.Week, text: GroupBy[GroupBy.Week] }
                        : this.group;

                this.groupSelection = [GroupBy.Week, GroupBy.Day].map(x => ({ id: x, text: GroupBy[x] }));
                break;

            case PeriodExtended.Week:
                this.group = !this.group
                    ? { id: GroupBy.Day, text: GroupBy[GroupBy.Day] }
                    : this.group.id > GroupBy.Day
                        ? { id: GroupBy.Day, text: GroupBy[GroupBy.Day] }
                        : this.group;

                this.groupSelection = [GroupBy.Day].map(x => ({ id: x, text: GroupBy[x] }));
                break;
        }
    }

    private getPeriods(): { begin: number, end: number } {
        if (this.period.id === PeriodExtended.Custom) {
            return this.dateRange;
        }

        const period = this.period.id === PeriodExtended.Year
            ? 'year'
            : this.period.id === PeriodExtended.Month
                ? 'month'
                : this.period.id === PeriodExtended.Week
                    ? 'week'
                    : null;
        const begin = DateUtils.convertStringToEpoc(DateUtils.startOf(DateUtils.currentDate, period));
        const end = DateUtils.convertStringToEpoc(DateUtils.currentDate);
        return { begin, end };
    }

    private getColorFromCompanyStatus(type: CompanyStatus) {
        switch (type) {
            case CompanyStatus.New:
                return '#ace4ee';
            case CompanyStatus.Client:
                return '#2ddca1';
            case CompanyStatus.Active:
                return '#faddbe';
            case CompanyStatus.Vip:
                return '#decae8';
            case CompanyStatus.Lost:
                return '#fabfbf';
            default:
                return '#bcbdc8';
        }
    }
}
