import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class InventoryDeleteAction {
    static readonly type = '[My Inventory Page] DeleteInventory';

    constructor(public payload: { uid: string }) { }
}

export class InventoryDeleteSuccessAction {
    static readonly type = '[My Inventories API] DeleteInventory Success';

    constructor(public payload: { uid: string }) { }
}

export class InventoryDeleteFailAction {
    static readonly type = '[My Inventories API] DeleteInventory Fail';

    constructor(public payload: ErrorObject) { }
}

export class InventoryDeleteResetAction {
    static readonly type = '[My Inventory Page] DeleteInventory Reset';
}
