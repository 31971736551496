import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Inventory } from '../../models/business/inventories/inventory.model';

export class InventoryGetAction {
    static readonly type = '[Inventory Page] GetInventory Get';

    constructor(public payload: string) { }
}

export class InventoryGetSuccessAction {
    static readonly type = '[Inventory API] GetInventory Success';

    constructor(public payload: Inventory) { }
}

export class InventoryFailAction {
    static readonly type = '[Inventory API] GetInventory Fail';

    constructor(public payload: ErrorObject) { }
}

export class InventoryResetAction {
    static readonly type = '[Inventory Page] GetInventory Reset';
}
