export enum ContragentStatus {
    Active = 0,

    Liquidating = 1,

    Liquidated = 2,

    Reorganizing = 3,

    Bankrupt = 4,

    Unknown = 1000
}
