import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapLight } from '../../models/business/technological-maps/technological-map-light.model';

export class TechnologicalMapActiveByProductGetAction {
    static readonly type = '[TechnologicalMapActiveByProduct Page] GetTechnologicalMapActiveByProduct Get';

    constructor(public payload: string) { }
}

export class TechnologicalMapActiveByProductGetSuccessAction {
    static readonly type = '[TechnologicalMapActiveByProduct API] GetTechnologicalMapActiveByProduct Success';

    constructor(public payload: TechnologicalMapLight) { }
}

export class TechnologicalMapActiveByProductFailAction {
    static readonly type = '[TechnologicalMapActiveByProduct API] GetTechnologicalMapActiveByProduct Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapActiveByProductResetAction {
    static readonly type = '[TechnologicalMapActiveByProduct Page] GetTechnologicalMapActiveByProduct Reset';
}
