import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosAreasService } from '../../services/business/organization-pos-areas.service';

import { OrganizationPosAreaUpdateAction, OrganizationPosAreaUpdateFailAction, OrganizationPosAreaUpdateResetAction, OrganizationPosAreaUpdateSuccessAction } from './organization-pos-area-update.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface OrganizationPosAreaUpdateStateModel {
    updated: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosAreaUpdateStateModel>({
    name: 'OrganizationPosAreaUpdate',
    defaults: {
        pending: false, updated: null, error: null
    }
})
@Injectable()
export class OrganizationPosAreaUpdateState {
    constructor(private orgPosAreasService: OrganizationPosAreasService) { }

    @Action([OrganizationPosAreaUpdateAction]) organizationPosesGet(ctx: StateContext<OrganizationPosAreaUpdateStateModel>, action: OrganizationPosAreaUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        this.orgPosAreasService.updatePosArea(action.payload.uid, action.payload.request)
            .then(() => this.orgPosAreasService.updatePosAreaRestrictions(action.payload.restrictions)
                .then(() => setTimeout(() => ctx.dispatch(new OrganizationPosAreaUpdateSuccessAction({ uid: action.payload.uid, area: action.payload.request })), 0))
                .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaUpdateFailAction(err)), 0)))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaUpdateFailAction(err)), 0));
    }

    @Action(OrganizationPosAreaUpdateSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosAreaUpdateStateModel>, _: OrganizationPosAreaUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(OrganizationPosAreaUpdateFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosAreaUpdateStateModel>, action: OrganizationPosAreaUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosAreaUpdateResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosAreaUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
