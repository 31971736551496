import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ContragentsListFilterData } from '../../models/filters/contragents-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContragentsAction {
    static readonly type = '[Contragents Page] GetContragents';

    constructor(public payload: PaginationRequest) { }
}

export class ContragentsSuccessAction {
    static readonly type = '[Contragents API] GetContragents Success';

    constructor(public payload: { list: PaginationResult<ContragentListItem>, pagination: PaginationRequest }) { }
}

export class ContragentsFailAction {
    static readonly type = '[Contragents API] GetContragents Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentsResetAction {
    static readonly type = '[Contragents Page] GetContragents Reset';
}

export class ContragentsSetFilterAction {
    static readonly type = '[Contragents API] SetFilter';

    constructor(public payload: ContragentsListFilterData) { }
}
