import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { MediaCreateRequest } from '../../../../burns-ui-framework/shared/models/business/media/media-light-request.model';
import { MediaLight } from '../../../../burns-ui-framework/shared/models/business/media/media-light.model';
import { Media } from '../../../../burns-ui-framework/shared/models/business/media/media.model';
import { MediasFilter } from '../../models/filters/medias-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosCreateOrUpdateRequest } from '../../models/business/pos/pos-create-or-update.model';
import { PosListFilter } from '../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../models/business/pos/pos-list-item.model';
import { PosLookupItem, PosLookupItemFromBack } from '../../models/business/pos/pos-lookup-item.model';
import { Pos } from '../../models/business/pos/pos.model';

import { GuidUtils } from '../../../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class PosService extends BaseSingletonService {

    private settings: {
        service: {
            getPos: string;
            getPosList: string;
            getPosLookup: string;
            createPos: string;
            updatePos: string;
            deletePos: string;

            getPosMediaList: string;
            createPosMedia: string;
            deletePosMedia: string;
        }
    };

    constructor(private http: HttpService) {
        super('PossService');
        this.settings = {
            service: {
                getPos: '/crm/v1/pos/{0}',
                getPosList: '/crm/v1/pos?term={0}&companyUid={1}&contragentUid={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                getPosLookup: '/crm/v1/pos/lookup?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                createPos: '/crm/v1/pos',
                updatePos: '/crm/v1/pos/{0}',
                deletePos: '/crm/v1/pos/{0}',

                getPosMediaList: '/crm/v1/pos/{0}/medias?sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                createPosMedia: '/crm/v1/pos/{0}/medias',
                deletePosMedia: '/crm/v1/pos/{0}/medias?mediaUids={1}'
            }
        };
    }

    public async getPos(uid: string): Promise<Pos> {
        return this.http.get<{ data: Pos }>(String.format(this.settings.service.getPos, uid))
            .then(res => res.data);
    }

    public async getPosList(filter: PosListFilter): Promise<PaginationResult<PosListItem>> {
        return this.http.get<PaginationResult<PosListItem>>(String.format(this.settings.service.getPosList, filter.term, filter.companyUid, filter.contragentUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getPosLookup(filter: PosListFilter): Promise<PaginationResult<PosLookupItem>> {
        return this.http.get<PaginationResult<PosLookupItemFromBack>>(String.format(this.settings.service.getPosLookup, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                const list = res.list.map(x => ({ ...x, posUid: x.uid, uid: GuidUtils.newGuid }));
                return { pageSize: res.pageSize, pageIndex: filter.pageIndex, list, total: res.total };
            });
    }

    public async createPos(request: PosCreateOrUpdateRequest): Promise<Pos> {
        return this.http.post<{ data: Pos }>(String.format(this.settings.service.createPos), request)
            .then(res => res.data);
    }

    public async updatePos(uid: string, request: PosCreateOrUpdateRequest): Promise<Pos> {
        return this.http.put(String.format(this.settings.service.updatePos, uid), request);
    }

    public async deletePos(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePos, uid));
    }

    public async getPosMediaList(uid: string, filter: MediasFilter): Promise<PaginationResult<Media>> {
        return this.http.get<PaginationResult<Media>>(String.format(this.settings.service.getPosMediaList, uid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async editPosMedia(posUid: string, addedPhotos: MediaCreateRequest[], deletedPhotos: string[]): Promise<{ addedPhotos: MediaLight[], deletedPhotos: string[] }> {
        const addPhotosHandler = (addedPhotos && addedPhotos[0]) ? this.createPosMedia(posUid, addedPhotos) : Promise.resolve(<MediaLight[]>[]);
        const deletePhotosHandler = (deletedPhotos && deletedPhotos[0]) ? this.deleteHeaderMedias(posUid, deletedPhotos) : Promise.resolve(true);

        return Promise.all([addPhotosHandler, deletePhotosHandler]).then(res => ({ addedPhotos: res[0], deletedPhotos }));
    }

    private async createPosMedia(uid: string, photos: MediaCreateRequest[]): Promise<MediaLight[]> {
        const promises = photos.map(r => this.http.post<{ data: MediaLight }>(String.format(this.settings.service.createPosMedia, uid), r).then(res => res.data));
        return Promise.all(promises);
    }

    private async deleteHeaderMedias(uid: string, photosIds: string[]): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePosMedia, uid, (photosIds || []).join(',')));
    }
}
