<section class="row-header">
    <bvc-filter-field class="full-name header"
                      *ngIf="listConfig[contragentSortingFields.FullName]"
                      [class.active]="filter.sortField === contragentSortingFields.FullName"
                      [name]="'Name1'"
                      [fieldName]="contragentSortingFields.FullName"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="registered header"
                      *ngIf="listConfig[contragentSortingFields.Inn]"
                      [class.active]="filter.sortField === contragentSortingFields.Inn"
                      [name]="'Inn'"
                      [fieldName]="contragentSortingFields.Inn"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="registered header"
                      *ngIf="listConfig[contragentSortingFields.Company]"
                      [class.active]="filter.sortField === contragentSortingFields.Company"
                      [name]="'Company'"
                      [fieldName]="contragentSortingFields.Company"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="registered header"
                      *ngIf="listConfig[contragentSortingFields.User]"
                      [class.active]="filter.sortField === contragentSortingFields.User"
                      [name]="'User'"
                      [fieldName]="contragentSortingFields.User"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>
</section>
<section class="row-header shadow">
    <div class="full-name header" *ngIf="listConfig[contragentSortingFields.FullName]"></div>
    <div class="registered header" *ngIf="listConfig[contragentSortingFields.Inn]"></div>
    <div class="registered header" *ngIf="listConfig[contragentSortingFields.Company]"></div>
    <div class="registered header" *ngIf="listConfig[contragentSortingFields.User]"></div>
</section>