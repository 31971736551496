<div class="container">
    <bvc-dcr-add-line addBtnTitle="Create"></bvc-dcr-add-line>

    <dcr-header *ngIf="filter"
                class="sticky-header"
                [filter]="filter"
                [listConfig]="listConfig"
                (filterChanged)="onFilterChanged($event)"
                (checkAll)="onCheckAll($event)"
                (listConfigChanged)="onListConfigChanged($event)"></dcr-header>

    <perfect-scrollbar class="scroll" *ngIf="retrieved" (psYReachEnd)="onLoadMore($event)">
        <dcr-list-item *ngFor="let entity of entities; trackBy: trackByEntity"
                        class="item_row"
                        [filter]="filter"
                        [profile]="profile"
                        [entity]="entity"
                        [checked]="selected[entity.uid]"
                        [listConfig]="listConfig"
                        (openEntity)="onOpenEntity($event)"
                        (checkItem)="onCheckItem($event)"></dcr-list-item>
    </perfect-scrollbar>
</div>
