import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { SupplierInvoice } from '../../models/business/supplier-invoices/supplier-invoice.model';

export class SupplierInvoiceGetAction {
    static readonly type = '[SupplierInvoice Page] GetSupplierInvoice Get';

    constructor(public payload: string) { }
}

export class SupplierInvoiceGetSuccessAction {
    static readonly type = '[SupplierInvoice API] GetSupplierInvoice Success';

    constructor(public payload: SupplierInvoice) { }
}

export class SupplierInvoiceFailAction {
    static readonly type = '[SupplierInvoice API] GetSupplierInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class SupplierInvoiceResetAction {
    static readonly type = '[SupplierInvoice Page] GetSupplierInvoice Reset';
}
