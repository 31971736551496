<div class="note note-view" *ngIf="!editState">
    <div class="type-icon">
        <div class="icon-area">
            <exg-icon class="note-icon" iconName="task"></exg-icon>
        </div>
    </div>
    <div class="title_line">
        <div class="title">{{'Task' | translate}}</div>
        <div class="create_time">{{feedItem?.createDate | exgDate:'h:mm'}}</div>
        <div class="task_status">
            <div class="status" [class]="taskStatusEnum[feedItem.taskStatus]">{{ taskStatusEnum[feedItem.taskStatus] | translate }}</div>
        </div>
        <div class="menu">
            <exg-menu class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event.id)">
                <exg-button class="menu_btn" styleType="icon"><exg-icon class="menu_icon" iconName="menudotted"></exg-icon></exg-button>
            </exg-menu>
        </div>
    </div>
    <div class="task_line">
        <exg-image class="profile-avatar" [image]="emptyAvatar" [isRound]="true"></exg-image>
        <div class="profile-name">
            <div class="info_title">{{ 'Responsible user' | translate }}</div>
            <div class="info_full_name">{{feedItem?.responsibleUser?.fullName}}</div>
        </div>
        <div class="due-date">
            <div class="info_title">{{ 'Expires' | translate }}</div>
            <div class="info_full_name">{{feedItem?.dueDate | exgDate:'dd.MM.y HH:mm'}}</div>
        </div>
    </div>
    <div class="split_line"></div>
    <div class="text_line">{{feedItem.text}}</div>
    <div class="documents_line">
        <bvc-clients-activity-document class="document"
                                       *ngFor="let document of feedItem.documents; trackBy: trackByDocuments"
                                       [document]="document"
                                       [allowRemove]="false"></bvc-clients-activity-document>
    </div>
</div>
<bvc-clients-activity-task-form *ngIf="editState"
                                class="note"
                                [task]="feedItem"
                                [error]="error"
                                [companyUid]="companyUid"
                                [users]="users"
                                (taskUpdate)="onTaskUpdate($event)"
                                (cancelClick)="editState=false"></bvc-clients-activity-task-form>
