import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { CompanyContragentsAction, CompanyContragentsFailAction, CompanyContragentsResetAction, CompanyContragentsSetFilterAction, CompanyContragentsSuccessAction } from './company-contragents.actions';

import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ContragentsListFilter, ContragentsListFilterData } from '../../models/filters/contragents-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface CompanyContragentsStateModel extends PaginationStateModel<ContragentListItem> {
    uids: string[];
    entities: { [uid: string]: ContragentListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ContragentsListFilterData;
    total: number;
}

@State<CompanyContragentsStateModel>({
    name: 'companyContragents',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class CompanyContragentsState extends PaginationStateBase {
    constructor(private contragentsService: ContragentsService) {
        super();
    }

    @Action([CompanyContragentsAction]) contragentsGet(ctx: StateContext<CompanyContragentsStateModel>, action: CompanyContragentsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContragentsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contragentsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contragentsService.getContragentList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CompanyContragentsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompanyContragentsFailAction(err)), 0));
    }

    @Action(CompanyContragentsSuccessAction) contragentsGetSuccess(ctx: StateContext<CompanyContragentsStateModel>, action: CompanyContragentsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contragentsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(CompanyContragentsFailAction) contragentsGetFail(ctx: StateContext<CompanyContragentsStateModel>, action: CompanyContragentsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CompanyContragentsResetAction) contragentsGetReset(ctx: StateContext<CompanyContragentsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(CompanyContragentsSetFilterAction) usersSetFilter(ctx: StateContext<CompanyContragentsStateModel>, action: CompanyContragentsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new CompanyContragentsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
