import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DCRSortingFieldsEnum } from '../../../models/business/dcr/dcr-sorting-fields.enum';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

import { Utils } from '../../../../burns-ui-framework/shared/utils/utils';

@Component({
    selector: 'dcr-header',
    templateUrl: './dcr-header.component.html',
    styleUrls: ['./dcr-header.component.scss', '../dcr-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DcrHeaderComponent {

    @Input() filter: any;
    @Input() listConfig: {};

    @Output() readonly filterChanged = new EventEmitter<{ sortOrder?: SortOrder, sortField?: string, pageSize?: number }>();
    @Output() readonly checkAll = new EventEmitter<{ checked: boolean }>();
    @Output() readonly listConfigChanged = new EventEmitter<{ }>();

    public drcSortingFieldsEnum = DCRSortingFieldsEnum;

    public enumArray = Utils.enumToArray(DCRSortingFieldsEnum);
    public translations = {};

    public openedMenu = false;

    constructor() {
        this.translations[DCRSortingFieldsEnum.Number] = 'Number';
        this.translations[DCRSortingFieldsEnum.Date] = 'Date/time';
        this.translations[DCRSortingFieldsEnum.Products] = 'Commodity';
        this.translations[DCRSortingFieldsEnum.Sum] = 'Amount';
        this.translations[DCRSortingFieldsEnum.DifferenceAmount] = 'Difference amount';
        this.translations[DCRSortingFieldsEnum.UnitType] = 'Unit';
        this.translations[DCRSortingFieldsEnum.WarehouseFrom] = 'Warehouse from';
        this.translations[DCRSortingFieldsEnum.WarehouseTo] = 'Warehouse to';
        this.translations[DCRSortingFieldsEnum.Notes] = 'Comment';
    }

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.filterChanged.emit($event);
    }

    public onPageSizeChanged($event: number) {
        this.filterChanged.emit({ pageSize: $event });
    }

    public onCheckAll($event: boolean) {
        this.checkAll.emit({ checked: $event });
    }

    public onListConfigChanged($event: any) {
        this.listConfigChanged.emit($event);
    }
}
