<div class="note note-view" *ngIf="!editState">
    <div class="type-icon">
        <div class="icon-area">
            <exg-icon class="note-icon" iconName="note"></exg-icon>
        </div>
    </div>
    <div class="title_line">
        <div class="title">{{'Note' | translate}}</div>
        <div class="menu">
            <exg-menu class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event.id)">
                <exg-button class="menu_btn" styleType="icon"><exg-icon class="menu_icon" iconName="menudotted"></exg-icon></exg-button>
            </exg-menu>
        </div>
    </div>
    <div class="text_line">{{feedItem.text}}</div>
    <div class="documents_line">
        <bvc-clients-activity-document class="document"
                                       *ngFor="let document of feedItem.documents; trackBy: trackByDocuments"
                                       [document]="document"
                                       [allowRemove]="false"></bvc-clients-activity-document>
    </div>
</div>
<bvc-clients-activity-form *ngIf="editState"
                           class="note"
                           [note]="feedItem"
                           [error]="error"
                           [companyUid]="companyUid"
                           (noteUpdate)="onNoteUpdate($event)"
                           (cancelClick)="editState=false"></bvc-clients-activity-form>