import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { PurchaseInvoicesService } from '../../services/business/purchase-invoices.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { PurchaseInvoiceCreateSuccessAction } from './purchase-invoice-create.actions';
import { PurchaseInvoiceDeleteSuccessAction } from './purchase-invoice-delete.actions';
import { PurchaseInvoiceUpdateSuccessAction } from './purchase-invoice-update.actions';
import { PurchaseInvoicesAction, PurchaseInvoicesFailAction, PurchaseInvoicesResetAction, PurchaseInvoicesSetFilterAction, PurchaseInvoicesSuccessAction } from './purchase-invoices.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PurchaseInvoiceListGetFilter, PurchaseInvoiceListGetFilterData } from '../../models/filters/purchase-invoice-list-get-filter.model';
import { PurchaseInvoiceListItem } from '../../models/business/purchase-invoices/purchase-invoice-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface PurchaseInvoicesStateModel extends PaginationFilteredStateModel<PurchaseInvoiceListItem, PurchaseInvoiceListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: PurchaseInvoiceListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PurchaseInvoiceListGetFilterData;
    total: number;
    totalAmount: number;
    totalPaidAmount: number;
}

@State<PurchaseInvoicesStateModel>({
    name: 'purchaseInvoices',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, totalAmount: 0, totalPaidAmount: 0, filter: {} }
})
@Injectable()
export class PurchaseInvoicesState extends PaginationFilteredStateBase {
    constructor(private purchaseInvoicesService: PurchaseInvoicesService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([PurchaseInvoicesAction]) productsGet(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoicesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'purchaseInvoice');
        ctx.setState({ ...state, filter: filterData });

        const filter = new PurchaseInvoiceListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.purchaseInvoicesService.getPurchaseInvoiceList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PurchaseInvoicesSuccessAction({ list: resp, pagination: action.payload, totalAmount: resp.totalAmount, totalPaidAmount: resp.totalPaidAmount })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseInvoicesFailAction(err)), 0));
    }

    @Action(PurchaseInvoicesSuccessAction) purchaseInvoiceGetSuccess(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoicesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, totalPaidAmount: action.payload.totalPaidAmount, totalAmount: action.payload.totalAmount, allItemsLoaded, index });
    }

    @Action(PurchaseInvoicesFailAction) purchaseInvoiceGetFail(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoicesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseInvoicesResetAction) purchaseInvoiceGetReset(ctx: StateContext<PurchaseInvoicesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(PurchaseInvoicesSetFilterAction) usersSetFilter(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoicesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'purchaseInvoice');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PurchaseInvoicesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PurchaseInvoiceCreateSuccessAction) purchaseInvoiceCreateSuccess(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoiceCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(PurchaseInvoiceUpdateSuccessAction) purchaseInvoiceUpdateSuccess(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoiceUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PurchaseInvoiceDeleteSuccessAction) purchaseInvoiceDeleteSuccess(ctx: StateContext<PurchaseInvoicesStateModel>, action: PurchaseInvoiceDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
