import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { City } from '../../models/business/geocoding/city.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CompanyCityState, CompanyCityStateModel } from './company-city.reducer';

@Injectable({
    providedIn: 'root'
})
export class CompanyCitySelectors extends BaseSingletonService {

    static readonly getPending = createSelector([CompanyCityState], (state: CompanyCityStateModel) => state.pending);

    static readonly getCompanyCity = createSelector([CompanyCityState], (state: CompanyCityStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([CompanyCityState], (state: CompanyCityStateModel) => state.retrieved);

    static readonly getError = createSelector([CompanyCityState], (state: CompanyCityStateModel) => state.error);

    @Select(CompanyCitySelectors.getPending) pending$: Observable<boolean>;

    @Select(CompanyCitySelectors.getCompanyCity) cities$: Observable<City[]>;

    @Select(CompanyCitySelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(CompanyCitySelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('CompanyCitySelectors');
    }
}
