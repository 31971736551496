import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosListFilterData } from '../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../models/business/pos/pos-list-item.model';

export class ContragentPosAction {
    static readonly type = '[ContragentPos Page] GetContragentPos';

    constructor(public payload: PaginationRequest) { }
}

export class ContragentPosSuccessAction {
    static readonly type = '[ContragentPos API] GetContragentPos Success';

    constructor(public payload: { list: PaginationResult<PosListItem>, pagination: PaginationRequest }) { }
}

export class ContragentPosFailAction {
    static readonly type = '[ContragentPos API] GetContragentPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentPosResetAction {
    static readonly type = '[ContragentPos Page] GetContragentPos Reset';
}

export class ContragentPosSetFilterAction {
    static readonly type = '[ContragentPos API] SetFilter';

    constructor(public payload: PosListFilterData) { }
}
