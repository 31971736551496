import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SalesPlanService } from '../../services/business/sales-plan.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PlanListFilter, PlanListFilterData } from '../../models/filters/plan-list-filter.model';
import { Plan } from '../../models/business/sales-plan/plan.model';

import { SalesPlansAction, SalesPlansFailAction, SalesPlansResetAction, SalesPlansSetFilterAction, SalesPlansSuccessAction } from './sales-plans.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface SalesPlansStateModel extends PaginationStateModel<Plan> {
    uids: string[];
    entities: { [uid: string]: Plan };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PlanListFilterData;
    total: number;
}

@State<SalesPlansStateModel>({
    name: 'salesPlans',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class SalesPlansState extends PaginationStateBase {
    constructor(private salesPlanService: SalesPlanService) {
        super();
    }

    @Action([SalesPlansAction]) salesPlansGet(ctx: StateContext<SalesPlansStateModel>, action: SalesPlansAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new PlanListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.salesPlanAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload.request, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.salesPlanService.getSalesPlans(action.payload.year, filter)
            .then(resp => setTimeout(() => ctx.dispatch(new SalesPlansSuccessAction({ list: resp, pagination: action.payload.request })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SalesPlansFailAction(err)), 0));
    }

    @Action(SalesPlansSuccessAction) salesPlansGetSuccess(ctx: StateContext<SalesPlansStateModel>, action: SalesPlansSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.salesPlanAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(SalesPlansFailAction) salesPlansGetFail(ctx: StateContext<SalesPlansStateModel>, action: SalesPlansFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(SalesPlansResetAction) salesPlansGetReset(ctx: StateContext<SalesPlansStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(SalesPlansSetFilterAction) salesPlansSetFilter(ctx: StateContext<SalesPlansStateModel>, action: SalesPlansSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload.filter };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new SalesPlansAction({ request: PaginationRequest.ReloadFullList, year: action.payload.year })), 0);
        }
    }
}
