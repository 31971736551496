import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContactUpdateAction, ContactUpdateFailAction, ContactUpdateResetAction, ContactUpdateSuccessAction } from './contact-update.actions';

export interface ContactUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<ContactUpdateStateModel>({
    name: 'contactUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class ContactUpdateState {
    constructor(private contactsService: ContactsService) { }

    @Action(ContactUpdateAction) async contactUpdate(ctx: StateContext<ContactUpdateStateModel>, action: ContactUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.contactsService.updateContact(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new ContactUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContactUpdateFailAction(err)), 0));
    }

    @Action(ContactUpdateSuccessAction) contactUpdateSuccess(ctx: StateContext<ContactUpdateStateModel>, _: ContactUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(ContactUpdateFailAction) contactUpdateFail(ctx: StateContext<ContactUpdateStateModel>, action: ContactUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(ContactUpdateResetAction) contactUpdateReset(ctx: StateContext<ContactUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
