
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantCategoryListItem } from '../../models/business/restaurant-category/restaurant-category-list-item.model';
import { RestaurantCategoryListGetFilterData } from '../../models/filters/restaurant-category-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class RestaurantCategoriesAction {
    static readonly type = '[RestaurantCategories Page] GetRestaurantCategories';

    constructor(public payload: PaginationRequest) { }
}

export class RestaurantCategoriesSuccessAction {
    static readonly type = '[RestaurantCategories API] GetRestaurantCategories Success';

    constructor(public payload: { list: PaginationResult<RestaurantCategoryListItem>, pagination: PaginationRequest }) { }
}

export class RestaurantCategoriesFailAction {
    static readonly type = '[RestaurantCategories API] GetRestaurantCategories Fail';

    constructor(public payload: ErrorObject) { }
}

export class RestaurantCategoriesResetAction {
    static readonly type = '[RestaurantCategories Page] GetRestaurantCategories Reset';
}

export class RestaurantCategoriesSetFilterAction {
    static readonly type = '[RestaurantCategories API] SetFilter';

    constructor(public payload: RestaurantCategoryListGetFilterData) { }
}
