import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { FranchiseesService } from '../../services/business/franchisees.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { FranchiseeDeleteAction, FranchiseeDeleteFailAction, FranchiseeDeleteResetAction, FranchiseeDeleteSuccessAction } from './franchisee-delete.actions';

export interface FranchiseeDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<FranchiseeDeleteStateModel>({
    name: 'franchiseeDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class FranchiseeDeleteState {
    constructor(private franchiseesService: FranchiseesService) { }

    @Action(FranchiseeDeleteAction) async franchiseeDelete(ctx: StateContext<FranchiseeDeleteStateModel>, action: FranchiseeDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.franchiseesService.deleteFranchisee(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new FranchiseeDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new FranchiseeDeleteFailAction(error)), 0));
    }

    @Action(FranchiseeDeleteSuccessAction) franchiseeDeleteSuccess(ctx: StateContext<FranchiseeDeleteStateModel>, _: FranchiseeDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(FranchiseeDeleteFailAction) franchiseeDeleteFail(ctx: StateContext<FranchiseeDeleteStateModel>, action: FranchiseeDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FranchiseeDeleteResetAction) franchiseeDeleteReset(ctx: StateContext<FranchiseeDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
