import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationFilteredStateBase } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { ProductsService } from '../../services/business/products.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WarehousesProductListItem } from '../../models/business/warehouses/warehouses-product-list-item.model';
import { WarehousesProductsListGetFilter, WarehousesProductsListGetFilterData } from '../../models/filters/warehouses-products-list-get-filter.model';

import { WarehousesProductsAction, WarehousesProductsFailAction, WarehousesProductsResetAction, WarehousesProductsSuccessAction } from './warehouses-products.actions';


export interface WarehousesProductsStateModel{
    uids: string[];
    entities: WarehousesProductListItem[];
    retrieved: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: WarehousesProductsListGetFilterData;
}

@State<WarehousesProductsStateModel>({
    name: 'warehousesProducts',
    defaults: { pending: false, uids: [], entities: [], retrieved: false, error: null, index: 0, filter: null }
})
@Injectable()
export class WarehousesProductsState extends PaginationFilteredStateBase {
    constructor(private productsService: ProductsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([WarehousesProductsAction]) productsGet(ctx: StateContext<WarehousesProductsStateModel>, action: WarehousesProductsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

         const filter = new WarehousesProductsListGetFilter({ filterData: action.payload });
        return this.productsService.getWarehousesProductList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new WarehousesProductsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WarehousesProductsFailAction(err)), 0));
    }

    @Action(WarehousesProductsSuccessAction) productsGetSuccess(ctx: StateContext<WarehousesProductsStateModel>, action: WarehousesProductsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });

    }

    @Action(WarehousesProductsFailAction) productsGetFail(ctx: StateContext<WarehousesProductsStateModel>, action: WarehousesProductsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WarehousesProductsResetAction) productsGetReset(ctx: StateContext<WarehousesProductsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: [], retrieved: false, error: null, index: 0, filter: {} });
    }

}
