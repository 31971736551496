import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

import { TechnologicalMapCreateAction, TechnologicalMapCreateFailAction, TechnologicalMapCreateResetAction, TechnologicalMapCreateSuccessAction } from './technological-map-create.actions';

export interface TechnologicalMapCreateStateModel {
    pending: boolean;
    created: TechnologicalMapListItemAggregation;
    error: ErrorObject;
}

@State<TechnologicalMapCreateStateModel>({
    name: 'technologicalMapCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TechnologicalMapCreateState {
    constructor(private technologicalMapsService: TechnologicalMapsService) { }

    @Action(TechnologicalMapCreateAction) async technologicalMapCreate(ctx: StateContext<TechnologicalMapCreateStateModel>, action: TechnologicalMapCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.technologicalMapsService.createTechnologicalMap(action.payload)
            .then(res => setTimeout(() => ctx.dispatch(new TechnologicalMapCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapCreateFailAction(err)), 0));
    }

    @Action(TechnologicalMapCreateSuccessAction) technologicalMapCreateSuccess(ctx: StateContext<TechnologicalMapCreateStateModel>, action: TechnologicalMapCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TechnologicalMapCreateFailAction) technologicalMapCreateFail(ctx: StateContext<TechnologicalMapCreateStateModel>, action: TechnologicalMapCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(TechnologicalMapCreateResetAction) technologicalMapCreateReset(ctx: StateContext<TechnologicalMapCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
