import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FranchiseeTypeCreateOrUpdate } from '../../models/business/franchisees/franchisee-type-create-or-update.model';

export class FranchiseeTypeUpdateAction {
    static readonly type = '[FranchiseeType Page] UpdateFranchiseeType';

    constructor(public payload: { uid: string, request: FranchiseeTypeCreateOrUpdate }) { }
}

export class FranchiseeTypeUpdateSuccessAction {
    static readonly type = '[FranchiseeType API] UpdateFranchiseeType Success';

    constructor(public payload: { uid: string, request: FranchiseeTypeCreateOrUpdate }) { }
}

export class FranchiseeTypeUpdateFailAction {
    static readonly type = '[FranchiseeType API] UpdateFranchiseeType Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeTypeUpdateResetAction {
    static readonly type = '[FranchiseeType Page] UpdateFranchiseeType Reset';
}
