import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { User } from '../../models/business/user/user.model';

import { UserUpdateSuccessAction } from './user-update.actions';
import { UserFailAction, UserGetAction, UserGetSuccessAction, UserResetAction } from './user.actions';

export interface UserStateModel {
    entity: User;
    pending: boolean;
    error: ErrorObject;
}

@State<UserStateModel>({
    name: 'user',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class UserState {
    constructor(private userService: UsersService) { }

    @Action(UserGetAction) adventureWizardsGet(ctx: StateContext<UserStateModel>, action: UserGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        this.userService.getUser(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new UserGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UserFailAction(err)), 0));
    }

    @Action(UserGetSuccessAction) adventureWizardsGetSuccess(ctx: StateContext<UserStateModel>, action: UserGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(UserFailAction) adventureWizardsGetFail(ctx: StateContext<UserStateModel>, action: UserFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(UserResetAction) adventureWizardsGetReset(ctx: StateContext<UserStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(UserUpdateSuccessAction) userUpdateSuccess(ctx: StateContext<UserStateModel>, action: UserUpdateSuccessAction) {
        const state = ctx.getState();

        const user = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity: user, error: null });
    }
}
