import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RecyclingsService } from '../../services/recyclings.service';

import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { RecyclingCreateAction, RecyclingCreateFailAction, RecyclingCreateResetAction, RecyclingCreateSuccessAction } from './recycling-create.actions';

export interface RecyclingCreateStateModel {
    pending: boolean;
    created: DCRItem;
    error: ErrorObject;
}

@State<RecyclingCreateStateModel>({
    name: 'recyclingCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class RecyclingCreateState {
    constructor(private recyclingsService: RecyclingsService) { }

    @Action(RecyclingCreateAction) async recyclingCreate(ctx: StateContext<RecyclingCreateStateModel>, action: RecyclingCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.recyclingsService.createRecycling({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new RecyclingCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RecyclingCreateFailAction(err)), 0));
    }

    @Action(RecyclingCreateSuccessAction) recyclingCreateSuccess(ctx: StateContext<RecyclingCreateStateModel>, action: RecyclingCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(RecyclingCreateFailAction) recyclingCreateFail(ctx: StateContext<RecyclingCreateStateModel>, action: RecyclingCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(RecyclingCreateResetAction) recyclingCreateReset(ctx: StateContext<RecyclingCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
