import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransferDeleteAction {
    static readonly type = '[My Transfer Page] DeleteTransfer';

    constructor(public payload: { uid: string }) { }
}

export class TransferDeleteSuccessAction {
    static readonly type = '[My Transfers API] DeleteTransfer Success';

    constructor(public payload: { uid: string }) { }
}

export class TransferDeleteFailAction {
    static readonly type = '[My Transfers API] DeleteTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransferDeleteResetAction {
    static readonly type = '[My Transfer Page] DeleteTransfer Reset';
}
