import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { VatsAction, VatsResetAction } from './vats.actions';

@Injectable({
    providedIn: 'root'
})
export class VatsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('VatsDispatchers');
    }

    public dispatchVatsAction() {
        this.store.dispatch(new VatsAction());
    }

    public dispatchVatsResetAction() {
        this.store.dispatch(new VatsResetAction());
    }
}
