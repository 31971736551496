import { DcrListGetFilterData } from '../../models/filters/dcr-list-get-filter.model';
import { DCRListItem } from '../../models/business/dcr/dcr-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class DecompositionsAction {
    static readonly type = '[Decompositionss Page] GetDecompositions';

    constructor(public payload: PaginationRequest) { }
}

export class DecompositionsSuccessAction {
    static readonly type = '[Decompositions API] GetDecompositions Success';

    constructor(public payload: { list: PaginationResult<DCRListItem>, pagination: PaginationRequest }) { }
}

export class DecompositionsFailAction {
    static readonly type = '[Decompositions API] GetDecompositions Fail';

    constructor(public payload: ErrorObject) { }
}

export class DecompositionsResetAction {
    static readonly type = '[Decompositions Page] GetDecompositions Reset';
}

export class DecompositionsSetFilterAction {
    static readonly type = '[Decompositions API] SetFilter';

    constructor(public payload: DcrListGetFilterData) { }
}
