import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';


import { FranchiseeTypeUpdateAction, FranchiseeTypeUpdateFailAction, FranchiseeTypeUpdateResetAction, FranchiseeTypeUpdateSuccessAction } from './franchisee-type-update.actions';
import { FranchiseeTypeCreateOrUpdate } from '../../models/business/franchisees/franchisee-type-create-or-update.model';
import { FranchiseesService } from '../../services/business/franchisees.service';

export interface FranchiseeTypeUpdateStateModel {
    pending: boolean;
    updated: FranchiseeTypeCreateOrUpdate;
    error: ErrorObject;
}

@State<FranchiseeTypeUpdateStateModel>({
    name: 'franchiseeTypeUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class FranchiseeTypeUpdateState {
    constructor(private franchiseeTypesService: FranchiseesService) { }

    @Action(FranchiseeTypeUpdateAction) async franchiseeTypeUpdate(ctx: StateContext<FranchiseeTypeUpdateStateModel>, action: FranchiseeTypeUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.franchiseeTypesService.updateFranchiseeType(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new FranchiseeTypeUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeTypeUpdateFailAction(err)), 0));
    }

    @Action(FranchiseeTypeUpdateSuccessAction) franchiseeTypeUpdateSuccess(ctx: StateContext<FranchiseeTypeUpdateStateModel>, action: FranchiseeTypeUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(FranchiseeTypeUpdateFailAction) franchiseeTypeUpdateFail(ctx: StateContext<FranchiseeTypeUpdateStateModel>, action: FranchiseeTypeUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(FranchiseeTypeUpdateResetAction) franchiseeTypeUpdateReset(ctx: StateContext<FranchiseeTypeUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
