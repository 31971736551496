import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetAvailableListFilterData } from '../../models/filters/interval-get-available-list-filter.model';
import { IntervalShort } from '../../models/business/intervals/interval-short.model';

export class IntervalsAvailableListSearchGetAction {
    static readonly type = '[IntervalsAvailableListSearch Page] GetInterval Get';

    constructor(public payload: IntervalGetAvailableListFilterData) { }
}

export class IntervalsAvailableListSearchGetSuccessAction {
    static readonly type = '[IntervalsAvailableListSearch API] GetInterval Success';

    constructor(public payload: IntervalShort[]) { }
}

export class IntervalsAvailableListSearchFailAction {
    static readonly type = '[IntervalsAvailableListSearch API] GetInterval Fail';

    constructor(public payload: ErrorObject) { }
}

export class IntervalsAvailableListSearchResetAction {
    static readonly type = '[IntervalsAvailableListSearch Page] GetInterval Reset';
}
