import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';

export class RestaurantTemplateListGetFilter extends SearchPaginationFilter {
    public organizationUid?: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: RestaurantTemplateListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.organizationUid = (args && args.filterData) ? args.filterData.organizationUid : null;
    }
}

export class RestaurantTemplateListGetFilterData extends SearchFilterData {
    public organizationUid?: string;
    public pageSize?: number;
}
