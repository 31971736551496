import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { User } from '../../models/business/user/user.model';

export class UserGetAction {
    static readonly type = '[My User Page] GetUser Get';

    constructor(public payload: string) { }
}

export class UserGetSuccessAction {
    static readonly type = '[My User API] GetUser Success';

    constructor(public payload: User) { }
}

export class UserFailAction {
    static readonly type = '[My User API] GetUser Fail';

    constructor(public payload: ErrorObject) { }
}

export class UserResetAction {
    static readonly type = '[My User Page] GetUser Reset';
}

