import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TechnologicalMapCreateRequest } from '../../models/business/technological-maps/technological-map-create-request.model';

import { TechnologicalMapCreateAction, TechnologicalMapCreateResetAction } from './technological-map-create.actions';

@Injectable({
    providedIn: 'root'
})
export class TechnologicalMapCreateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TechnologicalMapCreateDispatchers');
    }

    public dispatchTechnologicalMapCreateAction(request: TechnologicalMapCreateRequest) {
        this.store.dispatch(new TechnologicalMapCreateAction(request));
    }

    public dispatchTechnologicalMapCreateResetAction() {
        this.store.dispatch(new TechnologicalMapCreateResetAction());
    }
}
