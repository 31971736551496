import { Customer } from '../../models/business/customers/customer.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CustomerGetAction {
    static readonly type = '[Customer Page] GetCustomer Get';

    constructor(public payload: string) { }
}

export class CustomerGetSuccessAction {
    static readonly type = '[Customer API] GetCustomer Success';

    constructor(public payload: Customer) { }
}

export class CustomerFailAction {
    static readonly type = '[Customer API] GetCustomer Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerResetAction {
    static readonly type = '[Customer Page] GetCustomer Reset';
}
