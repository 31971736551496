import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ReservationCreateRequest } from '../../models/business/reservations/reservation-create-request.model';
import { Reservation } from '../../models/business/reservations/reservation.model';

export class ReservationCreateAction {
    static readonly type = '[Reservations Page] CreateReservation';

    constructor(public payload: ReservationCreateRequest) { }
}

export class ReservationCreateSuccessAction {
    static readonly type = '[Reservation API] CreateReservation Success';

    constructor(public payload: Reservation) { }
}

export class ReservationCreateFailAction {
    static readonly type = '[Reservation API] CreateReservation Fail';

    constructor(public payload: ErrorObject) { }
}

export class ReservationCreateResetAction {
    static readonly type = '[Reservations Page] CreateReservation Reset';
}
