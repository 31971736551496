import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PostingsService } from '../../services/business/postings.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PostingCreateOrUpdateRequest } from '../../models/business/postings/posting-create-or-update-request.model';

import { PostingUpdateAction, PostingUpdateFailAction, PostingUpdateResetAction, PostingUpdateSuccessAction } from './posting-update.actions';

export interface PostingUpdateStateModel {
    pending: boolean;
    updated: PostingCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<PostingUpdateStateModel>({
    name: 'postingUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class PostingUpdateState {
    constructor(private postingsService: PostingsService) { }

    @Action(PostingUpdateAction) async postingUpdate(ctx: StateContext<PostingUpdateStateModel>, action: PostingUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.postingsService.updatePosting(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PostingUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PostingUpdateFailAction(err)), 0));
    }

    @Action(PostingUpdateSuccessAction) postingUpdateSuccess(ctx: StateContext<PostingUpdateStateModel>, action: PostingUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(PostingUpdateFailAction) postingUpdateFail(ctx: StateContext<PostingUpdateStateModel>, action: PostingUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(PostingUpdateResetAction) postingUpdateReset(ctx: StateContext<PostingUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
