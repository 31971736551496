import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PosContragentFailAction, PosContragentGetAction, PosContragentGetSuccessAction, PosContragentResetAction } from './pos-contragent.actions';

export interface PosContragentStateModel {
    entity: Contragent;
    pending: boolean;
    error: ErrorObject;
}

@State<PosContragentStateModel>({
    name: 'posContragent',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PosContragentState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(PosContragentGetAction) contragentGet(ctx: StateContext<PosContragentStateModel>, action: PosContragentGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contragentsService.getContragent(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PosContragentGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosContragentFailAction(err)), 0));
    }

    @Action(PosContragentGetSuccessAction) contragentGetSuccess(ctx: StateContext<PosContragentStateModel>, action: PosContragentGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PosContragentFailAction) contragentGetFail(ctx: StateContext<PosContragentStateModel>, action: PosContragentFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosContragentResetAction) contragentGetReset(ctx: StateContext<PosContragentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
