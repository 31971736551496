import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Suggestion } from '../../models/business/contragents/suggestion.model';

export class ContragentSuggestionGetAction {
    static readonly type = '[ContragentSuggestion Page] GetContragentSuggestion Get';

    constructor(public payload: string) { }
}

export class ContragentSuggestionGetSuccessAction {
    static readonly type = '[ContragentSuggestion API] GetContragentSuggestion Success';

    constructor(public payload: Suggestion[]) { }
}

export class ContragentSuggestionFailAction {
    static readonly type = '[ContragentSuggestion API] GetContragentSuggestion Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentSuggestionResetAction {
    static readonly type = '[ContragentSuggestion Page] GetContragentSuggestion Reset';
}
