
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosDeleteAction {
    static readonly type = '[My OrganizationPos Page] DeleteOrganizationPos';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationPosDeleteSuccessAction {
    static readonly type = '[My OrganizationPos API] DeleteOrganizationPos Success';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationPosDeleteFailAction {
    static readonly type = '[My OrganizationPos API] DeleteOrganizationPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosDeleteResetAction {
    static readonly type = '[My OrganizationPos Page] DeleteOrganizationPos Reset';
}
