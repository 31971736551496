import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersAnalyticsService } from '../../services/business/orders-analytics.service';

import { OrdersAnalyticsAction, OrdersAnalyticsFailAction, OrdersAnalyticsResetAction, OrdersAnalyticsSuccessAction } from './orders-analytics.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrdersAnalytics } from '../../models/business/orders-analytics/orders-analytics.model';
import { OrdersStatisticGetFilter, OrdersStatisticGetFilterData } from '../../models/filters/orders-statistic-get-filter.model';

export interface OrdersAnalyticsStateModel {
    entity: OrdersAnalytics;
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: OrdersStatisticGetFilterData;
}

@State<OrdersAnalyticsStateModel>({
    name: 'OrdersAnalytics',
    defaults: { pending: false, entity: null, retrieved: false, error: null, filter: {} }
})
@Injectable()
export class OrdersAnalyticsState {
    constructor(private ordersAnalyticsService: OrdersAnalyticsService) {
    }

    @Action([OrdersAnalyticsAction]) productsGet(ctx: StateContext<OrdersAnalyticsStateModel>, action: OrdersAnalyticsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null, filter: action.payload });

        const filter = new OrdersStatisticGetFilter({ filterData: action.payload });

        return this.ordersAnalyticsService.getOrdersAnalytics(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrdersAnalyticsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrdersAnalyticsFailAction(err)), 0));
    }

    @Action(OrdersAnalyticsSuccessAction) orderGetSuccess(ctx: StateContext<OrdersAnalyticsStateModel>, action: OrdersAnalyticsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, retrieved: true, error: null });
    }

    @Action(OrdersAnalyticsFailAction) orderGetFail(ctx: StateContext<OrdersAnalyticsStateModel>, action: OrdersAnalyticsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrdersAnalyticsResetAction) orderGetReset(ctx: StateContext<OrdersAnalyticsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, retrieved: false, error: null, filter: null });
    }
}
