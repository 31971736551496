import { City } from '../../models/business/geocoding/city.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CompanyCityAction {
    static readonly type = '[CompanyCity API] GetCompanyCity';
}

export class CompanyCitySuccessAction {
    static readonly type = '[CompanyCity API] GetCompanyCity Success';

    constructor(public payload: City[]) { }
}

export class CompanyCityFailAction {
    static readonly type = '[CompanyCity API] GetCompanyCity Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyCityResetAction {
    static readonly type = '[CompanyCity API] GetCompanyCity Reset';
}
