import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PurchaseReturnDeleteAction {
    static readonly type = '[My PurchaseReturn Page] DeletePurchaseReturn';

    constructor(public payload: { uid: string }) { }
}

export class PurchaseReturnDeleteSuccessAction {
    static readonly type = '[My PurchaseReturns API] DeletePurchaseReturn Success';

    constructor(public payload: { uid: string }) { }
}

export class PurchaseReturnDeleteFailAction {
    static readonly type = '[My PurchaseReturns API] DeletePurchaseReturn Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseReturnDeleteResetAction {
    static readonly type = '[My PurchaseReturn Page] DeletePurchaseReturn Reset';
}
