import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { BankSuggestion } from '../../models/business/contragents/bank-suggestion.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { CorporationRequisitesCreateOrUpdateRequest } from '../../models/business/corporation-requisites/corporation-requisites-create-or-update.model';
import { CorporationRequisitesListFilter } from '../../models/filters/corporation-requisites-list-filter.model';
import { CorporationRequisite } from '../../models/business/corporation-requisites/corporation-requisites.model';
import { CorporationRequisiteListItem } from '../../models/business/corporation-requisites/corporation-requisite-list-item.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CorporationRequisitesService extends BaseSingletonService {

    private settings: {
        service: {
            getRequisite: string;
            getRequisiteList: string;
            getSuggestions: string;
            createRequisite: string;
            updateRequisite: string;
            deleteRequisite: string;
            setDefaultRequisite: string;
            
        }
    };

    constructor(private http: HttpService) {
        super('CorporationRequisitesService');
        this.settings = {
            service: {
                getRequisite: '/corporation/v1/organizations/{0}/requisites/{1}',
                getRequisiteList: '/corporation/v1/organizations/requisites?term={0}&franchiseeUid={1}&organizationUid={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                getSuggestions: '/corporation/v1/organizations/requisites/suggestions?term={0}',
                createRequisite: '/corporation/v1/organizations/{0}/requisites',
                updateRequisite: '/corporation/v1/organizations/{0}/requisites/{1}',
                deleteRequisite: '/corporation/v1/organizations/{0}/requisites/{1}',
                setDefaultRequisite: '/corporation/v1/organizations/{0}/requisites/{1}/default'
            }
        };
    }

    public async getRequisite(uid: string, organizationUid: string): Promise<CorporationRequisite> {
        return this.http.get<{ data: CorporationRequisite }>(String.format(this.settings.service.getRequisite, organizationUid, uid))
            .then(res => res.data);
    }

    public async getRequisiteList(filter: CorporationRequisitesListFilter): Promise<PaginationResult<CorporationRequisiteListItem>> {
          
        return this.http.get<PaginationResult<CorporationRequisiteListItem>>(String.format(this.settings.service.getRequisiteList, filter.term, filter.franchiseeUid, filter.organizationUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async setDefaultRequisite(uid: string, organizationUid: string): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.setDefaultRequisite, organizationUid, uid), {});
    }

    public async getSuggestions(term: string): Promise<BankSuggestion[]> {
        return this.http.get<{ list: BankSuggestion[] }>(String.format(this.settings.service.getSuggestions, term))
            .then(res => res.list);
    }

    public async createRequisite(organizationUid: string, request: CorporationRequisitesCreateOrUpdateRequest): Promise<CorporationRequisite> {
        return this.http.post<{ data: CorporationRequisite }>(String.format(this.settings.service.createRequisite, organizationUid), request)
            .then(res => res.data);
    }

    public async updateRequisite(uid: string, organizationUid: string, request: CorporationRequisitesCreateOrUpdateRequest): Promise<CorporationRequisite> {
        return this.http.put(String.format(this.settings.service.updateRequisite, organizationUid, uid), request);
    }

    public async deleteRequisite(uid: string, organizationUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRequisite, organizationUid, uid));
    }
}
