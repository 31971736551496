import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProductImageUpdateAction, ProductImageUpdateFailAction, ProductImageUpdateResetAction, ProductImageUpdateSuccessAction } from './product-image-update.actions';

export interface ProductImageUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<ProductImageUpdateStateModel>({
    name: 'productImageUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class ProductImageUpdateState {
    constructor(private catalogService: CatalogService) { }

    @Action(ProductImageUpdateAction) async productImageUpdate(ctx: StateContext<ProductImageUpdateStateModel>, action: ProductImageUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.catalogService.updateProductPhoto(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new ProductImageUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductImageUpdateFailAction(err)), 0));
    }

    @Action(ProductImageUpdateSuccessAction) productImageUpdateSuccess(ctx: StateContext<ProductImageUpdateStateModel>, _: ProductImageUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(ProductImageUpdateFailAction) productImageUpdateFail(ctx: StateContext<ProductImageUpdateStateModel>, action: ProductImageUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(ProductImageUpdateResetAction) productImageUpdateReset(ctx: StateContext<ProductImageUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
