import { AttributeGroup } from '../../models/business/nomenclature/attribute-group.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class AttributeGroupsAction {
    static readonly type = '[AttributeGroups Page] AttributeGroups';
}

export class AttributeGroupsSuccessAction {
    static readonly type = '[AttributeGroups API] AttributeGroups Success';

    constructor(public payload: AttributeGroup[]) { }
}

export class AttributeGroupsFailAction {
    static readonly type = '[AttributeGroups API] AttributeGroups Fail';

    constructor(public payload: ErrorObject) { }
}

export class AttributeGroupsResetAction {
    static readonly type = '[AttributeGroups Page] AttributeGroups Reset';
}
