import { Company } from '../../models/business/companies/company.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CustomerCompanyGetAction {
    static readonly type = '[CustomerCompany Page] GetCustomerCompany Get';

    constructor(public payload: string) { }
}

export class CustomerCompanyGetSuccessAction {
    static readonly type = '[CustomerCompany API] GetCustomerCompany Success';

    constructor(public payload: Company) { }
}

export class CustomerCompanyFailAction {
    static readonly type = '[CustomerCompany API] GetCustomerCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerCompanyResetAction {
    static readonly type = '[CustomerCompany Page] GetCustomerCompany Reset';
}
