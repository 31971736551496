import { PeriodExtended } from '../business/period-selection.enum';

export class OrdersStatisticGetFilter {
    public franchiseeUids: string[];
    public organizationUids: string[];
    public organizationPosUids: string[];
    public dateFrom: number;
    public dateTo: number;
    public period: PeriodExtended;

    // tslint:disable-next-line:cyclomatic-complexity
    constructor(args?: { filterData?: OrdersStatisticGetFilterData }) {
        this.franchiseeUids = (args && args.filterData) ? args.filterData.franchiseeUids : null;
        this.organizationUids = (args && args.filterData) ? args.filterData.organizationUids : null;
        this.organizationPosUids = (args && args.filterData) ? args.filterData.organizationPosUids : null;
        this.dateFrom = (args && args.filterData) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData) ? args.filterData.dateTo : null;
        this.period = (args && args.filterData) ? args.filterData.period : PeriodExtended.Custom;
    }
}

export class OrdersStatisticGetFilterData {
    public franchiseeUids?: string[];
    public organizationUids?: string[];
    public organizationPosUids?: string[];
    public dateFrom?: number;
    public dateTo?: number;
    public period?: PeriodExtended;
}
