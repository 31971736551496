import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseOrderCreateOrUpdateRequest } from '../../models/business/purchase-orders/purchase-order-create-or-update-request.model';

export class PurchaseOrderUpdateAction {
    static readonly type = '[Booking Page] UpdatePurchaseOrder';

    constructor(public payload: { uid: string, request: PurchaseOrderCreateOrUpdateRequest }) { }
}

export class PurchaseOrderUpdateSuccessAction {
    static readonly type = '[PurchaseOrder API] UpdatePurchaseOrder Success';

    constructor(public payload: { uid: string, request: PurchaseOrderCreateOrUpdateRequest }) { }
}

export class PurchaseOrderUpdateFailAction {
    static readonly type = '[PurchaseOrder API] UpdatePurchaseOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseOrderUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePurchaseOrder Reset';
}
