import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { MotivationBonusEditDocumentFailAction, MotivationBonusEditDocumentResetAction, MotivationBonusEditDocumentSuccessAction, MotivationBonusEditDocumentАction } from './motivation-bonus-edit-document.actions';

export interface MotivationBonusEditDocumentStateModel {
    pending: boolean;
    error: ErrorObject;
    edited: boolean;
}

@State<MotivationBonusEditDocumentStateModel>({
    name: 'motivationBonusEditDocument',
    defaults: { pending: false, error: null, edited: false }
})
@Injectable()
export class MotivationBonusEditDocumentState {
    constructor(private contactsService: ContactsService) { }

    @Action(MotivationBonusEditDocumentАction) async motivationBonusStatusEdit(ctx: StateContext<MotivationBonusEditDocumentStateModel>, action: MotivationBonusEditDocumentАction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, edited: false });

        return this.contactsService.updateMotivationDocument(action.payload.contactUid, action.payload.motivationUid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new MotivationBonusEditDocumentSuccessAction({ contactUid: action.payload.contactUid, motivationUid: action.payload.motivationUid, url: res })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new MotivationBonusEditDocumentFailAction(error)), 0));
    }

    @Action(MotivationBonusEditDocumentSuccessAction) motivationBonusEditSuccess(ctx: StateContext<MotivationBonusEditDocumentStateModel>, _: MotivationBonusEditDocumentSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, edited: true });
    }

    @Action(MotivationBonusEditDocumentFailAction) motivationBonusEditFail(ctx: StateContext<MotivationBonusEditDocumentStateModel>, action: MotivationBonusEditDocumentFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload, edited: false });
    }

    @Action(MotivationBonusEditDocumentResetAction) motivationBonusEditReset(ctx: StateContext<MotivationBonusEditDocumentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, edited: false });
    }
}
