
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ManagerAssistantDeleteAction {
    static readonly type = '[My ManagerAssistant Page] DeleteManagerAssistant';

    constructor(public payload: { uid: string }) { }
}

export class ManagerAssistantDeleteSuccessAction {
    static readonly type = '[My ManagerAssistants API] DeleteManagerAssistant Success';

    constructor(public payload: { uid: string }) { }
}

export class ManagerAssistantDeleteFailAction {
    static readonly type = '[My ManagerAssistants API] DeleteManagerAssistant Fail';

    constructor(public payload: ErrorObject) { }
}

export class ManagerAssistantDeleteResetAction {
    static readonly type = '[My ManagerAssistant Page] DeleteManagerAssistant Reset';
}
