import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CompaniesListFilterData } from '../../../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../../../models/business/companies/company-list-item.model';
import { CompanySortingFieldsEnum } from '../../../models/business/companies/company-sorting-fields.enum';

@Component({
    selector: 'company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyListComponent {

    @Input() companies: CompanyListItem[];
    @Input() allCompaniesLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: CompaniesListFilterData;
    @Input() enableAddLine = false;
    @Input() ignoreRows: CompanySortingFieldsEnum[] = [];

    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openCompany = new EventEmitter<string>();
    @Output() readonly sortingChange = new EventEmitter<CompaniesListFilterData>();
    @Output() readonly addBtnClick = new EventEmitter<void>();

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onSortingChange($event: CompaniesListFilterData) {
        this.sortingChange.emit($event);
    }

    public trackByCompanys(_, item: CompanyListItem) {
        return item.uid;
    }

    public onOpenCompany(uid: string) {
        this.openCompany.emit(uid);
    }
}
