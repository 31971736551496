import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContractsService } from '../../services/business/contracts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContractDeleteAction, ContractDeleteFailAction, ContractDeleteResetAction, ContractDeleteSuccessAction } from './contract-delete.actions';

export interface ContractDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<ContractDeleteStateModel>({
    name: 'contractDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class ContractDeleteState {
    constructor(private contractsService: ContractsService) { }

    @Action(ContractDeleteAction) async contractDelete(ctx: StateContext<ContractDeleteStateModel>, action: ContractDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.contractsService.deleteContract(action.payload.uid, action.payload.contragentUid)
            .then(() => setTimeout(() => ctx.dispatch(new ContractDeleteSuccessAction({ uid: action.payload.uid, contragentUid: action.payload.contragentUid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ContractDeleteFailAction(error)), 0));
    }

    @Action(ContractDeleteSuccessAction) contractDeleteSuccess(ctx: StateContext<ContractDeleteStateModel>, _: ContractDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(ContractDeleteFailAction) contractDeleteFail(ctx: StateContext<ContractDeleteStateModel>, action: ContractDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContractDeleteResetAction) contractDeleteReset(ctx: StateContext<ContractDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
