import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FeedType } from '../../../../models/business/feed/feed-type.enum';
import { Feed } from '../../../../models/business/feed/feed.model';
import { NoteUpdateRequest } from '../../../../models/business/notes/note-update-request.model';
import { TaskCreateOrUpdateRequest } from '../../../../models/business/tasks/task-create-or-update.model';
import { UserForTyping } from '../../../../../../burns-ui-framework/shared/models/business/user/user-for-typing.model';

@Component({
    selector: 'bvc-clients-activity-item',
    templateUrl: './clients-activity-item.component.html',
    styleUrls: ['./clients-activity-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityItemComponent {
    @Input() feedItem: Feed;
    @Input() error: ErrorObject;
    @Input() companyUid: string;
    @Input() users: UserForTyping[];

    @Output() readonly noteUpdate = new EventEmitter<{ uid: string, request: NoteUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }>();
    @Output() readonly noteDelete = new EventEmitter<{ uid: string }>();
    @Output() readonly taskUpdate = new EventEmitter<{ uid: string, request: TaskCreateOrUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }>();
    @Output() readonly taskDelete = new EventEmitter<{ uid: string }>();

    public feedTypeEnum = FeedType;

    public onNoteUpdate($event: { uid: string, request: NoteUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }) {
        this.noteUpdate.emit($event);
    }

    public onNoteDelete($event: { uid: string }) {
        this.noteDelete.emit($event);
    }

    public onTaskUpdate($event: { uid: string, request: TaskCreateOrUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }) {
        this.taskUpdate.emit($event);
    }

    public onTaskDelete($event: { uid: string }) {
        this.taskDelete.emit($event);
    }
}
