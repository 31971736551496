import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PostingCreateOrUpdateRequest } from '../../models/business/postings/posting-create-or-update-request.model';
import { Posting } from '../../models/business/postings/posting.model';

export class PostingCreateAction {
    static readonly type = '[Postings Page] CreatePosting';

    constructor(public payload: PostingCreateOrUpdateRequest) { }
}

export class PostingCreateSuccessAction {
    static readonly type = '[Posting API] CreatePosting Success';

    constructor(public payload: Posting) { }
}

export class PostingCreateFailAction {
    static readonly type = '[Posting API] CreatePosting Fail';

    constructor(public payload: ErrorObject) { }
}

export class PostingCreateResetAction {
    static readonly type = '[Postings Page] CreatePosting Reset';
}
