import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { FranchiseesService } from '../../services/business/franchisees.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { FranchiseeCreateAction, FranchiseeCreateFailAction, FranchiseeCreateResetAction, FranchiseeCreateSuccessAction } from './franchisee-create.actions';
import { Franchisee } from '../../models/business/franchisees/franchisee.model';

export interface FranchiseeCreateStateModel {
    pending: boolean;
    created: Franchisee;
    error: ErrorObject;
}

@State<FranchiseeCreateStateModel>({
    name: 'franchiseeCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class FranchiseeCreateState {
    constructor(private franchiseeService: FranchiseesService) { }

    @Action(FranchiseeCreateAction) async franchiseeCreate(ctx: StateContext<FranchiseeCreateStateModel>, action: FranchiseeCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.franchiseeService.createFranchisee(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new FranchiseeCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeCreateFailAction(err)), 0));
    }

    @Action(FranchiseeCreateSuccessAction) franchiseeCreateSuccess(ctx: StateContext<FranchiseeCreateStateModel>, action: FranchiseeCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(FranchiseeCreateFailAction) franchiseeCreateFail(ctx: StateContext<FranchiseeCreateStateModel>, action: FranchiseeCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(FranchiseeCreateResetAction) franchiseeCreateReset(ctx: StateContext<FranchiseeCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
