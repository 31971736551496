import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { UserUpdateAction, UserUpdateFailAction, UserUpdateResetAction, UserUpdateSuccessAction } from './user-update.actions';

export interface UpdateState {
    year: number;
    isConfirmed: boolean;
}

export interface UserUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<UserUpdateStateModel>({
    name: 'userUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class UserUpdateState {
    constructor(private usersService: UsersService) { }

    @Action(UserUpdateAction) async userUpdate(ctx: StateContext<UserUpdateStateModel>, action: UserUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.usersService.updateUser(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new UserUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UserUpdateFailAction(err)), 0));
    }

    @Action(UserUpdateSuccessAction) userUpdateSuccess(ctx: StateContext<UserUpdateStateModel>, _: UserUpdateSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(UserUpdateFailAction) userUpdateFail(ctx: StateContext<UserUpdateStateModel>, action: UserUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(UserUpdateResetAction) userUpdateReset(ctx: StateContext<UserUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
