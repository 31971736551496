import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { PostingsService } from '../../services/business/postings.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { PostingCreateSuccessAction } from './posting-create.actions';
import { PostingDeleteSuccessAction } from './posting-delete.actions';
import { PostingUpdateSuccessAction } from './posting-update.actions';
import { PostingsAction, PostingsFailAction, PostingsResetAction, PostingsSetFilterAction, PostingsSuccessAction } from './postings.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PostingListGetFilter, PostingListGetFilterData } from '../../models/filters/posting-list-get-filter.model';
import { PostingListItem } from '../../models/business/postings/posting-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface PostingsStateModel extends PaginationFilteredStateModel<PostingListItem, PostingListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: PostingListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PostingListGetFilterData;
    total: number;
}

@State<PostingsStateModel>({
    name: 'postings',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class PostingsState extends PaginationFilteredStateBase {
    constructor(private postingsService: PostingsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([PostingsAction]) productsGet(ctx: StateContext<PostingsStateModel>, action: PostingsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'posting');
        ctx.setState({ ...state, filter: filterData });

        const filter = new PostingListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.postingsService.getPostingList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PostingsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PostingsFailAction(err)), 0));
    }

    @Action(PostingsSuccessAction) postingGetSuccess(ctx: StateContext<PostingsStateModel>, action: PostingsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(PostingsFailAction) postingGetFail(ctx: StateContext<PostingsStateModel>, action: PostingsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PostingsResetAction) postingGetReset(ctx: StateContext<PostingsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(PostingsSetFilterAction) usersSetFilter(ctx: StateContext<PostingsStateModel>, action: PostingsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'posting');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PostingsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PostingCreateSuccessAction) postingCreateSuccess(ctx: StateContext<PostingsStateModel>, action: PostingCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(PostingUpdateSuccessAction) postingUpdateSuccess(ctx: StateContext<PostingsStateModel>, action: PostingUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PostingDeleteSuccessAction) postingDeleteSuccess(ctx: StateContext<PostingsStateModel>, action: PostingDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
