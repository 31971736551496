import { DcrListGetFilterData } from '../../models/filters/dcr-list-get-filter.model';
import { DCRListItem } from '../../models/business/dcr/dcr-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class RecyclingsAction {
    static readonly type = '[Recyclingss Page] GetRecyclings';

    constructor(public payload: PaginationRequest) { }
}

export class RecyclingsSuccessAction {
    static readonly type = '[Recyclings API] GetRecyclings Success';

    constructor(public payload: { list: PaginationResult<DCRListItem>, pagination: PaginationRequest }) { }
}

export class RecyclingsFailAction {
    static readonly type = '[Recyclings API] GetRecyclings Fail';

    constructor(public payload: ErrorObject) { }
}

export class RecyclingsResetAction {
    static readonly type = '[Recyclings Page] GetRecyclings Reset';
}

export class RecyclingsSetFilterAction {
    static readonly type = '[Recyclings API] SetFilter';

    constructor(public payload: DcrListGetFilterData) { }
}
