import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { InventoryCreateOrUpdateRequest } from '../../models/business/inventories/inventory-create-or-update-request.model';
import { InventoryListGetFilter } from '../../models/filters/inventory-list-get-filter.model';
import { InventoryListItem } from '../../models/business/inventories/inventory-list-item.model';
import { Inventory } from '../../models/business/inventories/inventory.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';
import { ListFromInventory } from '../../models/business/inventories/list-from-inventory.model';
import { ListFromInventoryType } from '../../models/business/inventories/list-from-inventory-type.enum';

@Injectable({
    providedIn: 'root',
})
export class InventoriesService extends BaseSingletonService {
    private settings: {
        service: {
            getInventory: string;
            getWorkbook: string;
            getInventoryList: string;
            createInventory: string;
            updateInventory: string;
            deleteInventory: string;
            listFromInventoryPosting: string;
            listFromInventoryWriteOff: string;
        };
    };

    private inventories = [{}];

    constructor(private http: HttpService) {
        super('InventoriesService');
        this.settings = {
            service: {
                getInventory: '/warehouses-aggregation/v1/inventories/{0}',
                getWorkbook: '/warehouses/v1/inventories/{0}/workbook',
                getInventoryList: '/warehouses-aggregation/v1/inventories?organizationUid={0}&warehouseUid={1}&status={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createInventory: '/warehouses-aggregation/v1/inventories',
                updateInventory: '/warehouses/v1/inventories/{0}',
                deleteInventory: '/warehouses/v1/inventories/{0}',
                listFromInventoryPosting: '/warehouses/v1/postings/list-from-inventory',
                listFromInventoryWriteOff: '/warehouses/v1/writting-offs/list-from-inventory',
            },
        };
    }

    public async getInventory(uid: string): Promise<Inventory> {
        return this.http.get<{ data: Inventory }>(String.format(this.settings.service.getInventory, uid)).then(res => res.data);
    }

    public async getWorkbook(uid: string, fileName: string): Promise<Blob> {
        return this.http.download(String.format(this.settings.service.getWorkbook, uid), fileName);
    }

    public async getInventoryList(filter: InventoryListGetFilter): Promise<PaginationResult<InventoryListItem>> {
        return this.http
            .get<PaginationResult<InventoryListItem>>(
                String.format(
                    this.settings.service.getInventoryList,
                    filter.organizationUid,
                    filter.warehouseUid,
                    filter.status,
                    filter.sortField,
                    filter.sortOrder,
                    filter.pageIndex,
                    filter.pageSize
                )
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createInventory(request: InventoryCreateOrUpdateRequest): Promise<Inventory> {
        return this.http.post<{ data: Inventory }>(String.format(this.settings.service.createInventory), request).then(res => res.data);
    }

    public async updateInventory(uid: string, request: InventoryCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateInventory, uid), request);
    }

    public async deleteInventory(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteInventory, uid));
    }

    public async listFromInventory(inventoryUid: string, type: ListFromInventoryType): Promise<ListFromInventory> {
        return this.http.post<{ data: ListFromInventory }>(String.format(this.settings.service[type]), { inventoryUid });
    }
}
