import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CustomersService } from '../../services/business/customers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerUpdateAction, CustomerUpdateFailAction, CustomerUpdateResetAction, CustomerUpdateSuccessAction } from './customer-update.actions';

export interface CustomerUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<CustomerUpdateStateModel>({
    name: 'customerUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class CustomerUpdateState {
    constructor(private customersService: CustomersService) { }

    @Action(CustomerUpdateAction) async customerUpdate(ctx: StateContext<CustomerUpdateStateModel>, action: CustomerUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.customersService.updateCustomer(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new CustomerUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomerUpdateFailAction(err)), 0));
    }

    @Action(CustomerUpdateSuccessAction) customerUpdateSuccess(ctx: StateContext<CustomerUpdateStateModel>, _: CustomerUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(CustomerUpdateFailAction) customerUpdateFail(ctx: StateContext<CustomerUpdateStateModel>, action: CustomerUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(CustomerUpdateResetAction) customerUpdateReset(ctx: StateContext<CustomerUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
