import { LinkedCity } from '../linked-city.model';
import { LinkedContact } from '../contacts/linked-contact.model';
import { LinkedContragent } from './linked-contragent.model';
import { LinkedOrganizationPos } from '../organization-pos/linked-organization-pos.model';
import { LinkedOrganization } from '../organizations/linked-organization.model';
import { LinkedPlace } from '../linked-place.model';
import { LinkedPos } from '../organization-pos/linked-pos.model';
import { LinkedUser } from '../user/linked-user.model';
import { ActivityStatus } from './order-activity-status.enum';
import { OrderShippingStatus } from './order-shipping-status.enum';
import { OrderSource } from './order-source.enum';
import { OrderStatus } from './order-status.enum';
import { PaymentStatus } from './payment-status.enum';


export class OrderListItem {
    public uid: string;
    public number: string;
    public externalNumber: string;
    public contragent: LinkedContragent;
    public pos: LinkedPos;
    public contact: LinkedContact;
    public organization: LinkedOrganization;
    public organizationPos: LinkedOrganizationPos;
    public shippingMethod: number;
    public shippingDateFrom?: number;
    public shippingDateTo?: number;
    public prepareDateTo: number;
    public preparedDate: number;
    public shippingAddress: LinkedPlace;
    public shippingCity: LinkedCity;
    public amount: number;
    public invoicesAmount: number;
    public reservedAmount: number;
    public dispatchedAmount: number;
    public notes: string;
    public source: OrderSource;
    public activityStatus: ActivityStatus;
    public status: OrderStatus;
    public paymentTypeUid: string;
    public paymentTypeCode: string;
    public paymentStatus: PaymentStatus;
    public paidAmount: number;
    public shippingStatus: OrderShippingStatus;
    public shippingDate?: number;
    public responsibleUser: LinkedUser;
    public productResponsibleUsers: LinkedUser[];
    public createDate: number;
    public internalNumber?: number;
    public company?: any;
    public totalAmount?: number;
    public shippingAddressHouse?: string;
    public shippingAddressCorpus?: string;
}
