import { CompanyCreateOrUpdateRequest } from '../../models/business/companies/company-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CompanyUpdateAction {
    static readonly type = '[Booking Page] UpdateCompany';

    constructor(public payload: { uid: string, request: CompanyCreateOrUpdateRequest }) { }
}

export class CompanyUpdateSuccessAction {
    static readonly type = '[Company API] UpdateCompany Success';

    constructor(public payload: { uid: string, request: CompanyCreateOrUpdateRequest }) { }
}

export class CompanyUpdateFailAction {
    static readonly type = '[Company API] UpdateCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyUpdateResetAction {
    static readonly type = '[Booking Page] UpdateCompany Reset';
}
