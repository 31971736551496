import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderCreateOrUpdateRequest } from '../../models/business/orders/order-create-or-update-request.model';

import { OrderUpdateAction, OrderUpdateFailAction, OrderUpdateResetAction, OrderUpdateSuccessAction } from './order-update.actions';

export interface OrderUpdateStateModel {
    pending: boolean;
    updated: OrderCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<OrderUpdateStateModel>({
    name: 'orderUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class OrderUpdateState {
    constructor(private ordersService: OrdersService) { }

    @Action(OrderUpdateAction) async orderUpdate(ctx: StateContext<OrderUpdateStateModel>, action: OrderUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.ordersService.updateOrder(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new OrderUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrderUpdateFailAction(err)), 0));
    }

    @Action(OrderUpdateSuccessAction) orderUpdateSuccess(ctx: StateContext<OrderUpdateStateModel>, action: OrderUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(OrderUpdateFailAction) orderUpdateFail(ctx: StateContext<OrderUpdateStateModel>, action: OrderUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(OrderUpdateResetAction) orderUpdateReset(ctx: StateContext<OrderUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
