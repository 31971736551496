import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { CorporationRequisitesCreateOrUpdateRequest } from '../../models/business/corporation-requisites/corporation-requisites-create-or-update.model';
import { CorporationRequisite } from '../../models/business/corporation-requisites/corporation-requisites.model';

export class CorporationRequisiteCreateAction {
    static readonly type = '[CorporationRequisites Page] CreateCorporationRequisite';

    constructor(public payload: { organizationUid: string, request: CorporationRequisitesCreateOrUpdateRequest }) { }
}

export class CorporationRequisiteCreateSuccessAction {
    static readonly type = '[CorporationRequisite API] CreateCorporationRequisite Success';

    constructor(public payload: CorporationRequisite) { }
}

export class CorporationRequisiteCreateFailAction {
    static readonly type = '[CorporationRequisite API] CreateCorporationRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationRequisiteCreateResetAction {
    static readonly type = '[CorporationRequisites Page] CreateCorporationRequisite Reset';
}
