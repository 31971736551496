import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class IntervalGetFilter{
    public sortField: string;
    public sortOrder: SortOrder;
    public date: number;
    public organizationPosUid: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: IntervalGetFilterData }) {
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.date = (args && args.filterData && args.filterData.date != null) ? args.filterData.date : null;
        this.organizationPosUid = (args && args.filterData && args.filterData.organizationPosUid != null) ? args.filterData.organizationPosUid : null;
    }
}

export class IntervalGetFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public date?: number;
    public organizationPosUid?: string;
}
