import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TasksService } from '../../services/business/tasks.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TaskUpdateSuccessAction } from './task-update.actions';
import { TaskFailAction, TaskGetAction, TaskGetSuccessAction, TaskResetAction } from './task.actions';
import { Task } from '../../models/business/tasks/task.model';

export interface TaskStateModel {
    entity: Task;
    pending: boolean;
    error: ErrorObject;
}

@State<TaskStateModel>({
    name: 'task',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class TaskState {
    constructor(private tasksService: TasksService) { }

    @Action(TaskGetAction) taskGet(ctx: StateContext<TaskStateModel>, action: TaskGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.tasksService.getTask(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new TaskGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TaskFailAction(err)), 0));
    }

    @Action(TaskGetSuccessAction) taskGetSuccess(ctx: StateContext<TaskStateModel>, action: TaskGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(TaskFailAction) taskGetFail(ctx: StateContext<TaskStateModel>, action: TaskFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TaskResetAction) taskGetReset(ctx: StateContext<TaskStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(TaskUpdateSuccessAction) taskUpdateSuccess(ctx: StateContext<TaskStateModel>, action: TaskUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
