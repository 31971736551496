import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilterData } from '../../models/filters/products-list-get-filter.model';

export class ProductsAction {
    static readonly type = '[Products Page] GetProducts';

    constructor(public payload: PaginationRequest) { }
}

export class ProductsSuccessAction {
    static readonly type = '[Products API] GetProducts Success';

    constructor(public payload: { list: PaginationResult<ProductListItemAggregation>, pagination: PaginationRequest }) { }
}

export class ProductsFailAction {
    static readonly type = '[Products API] GetProducts Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductsResetAction {
    static readonly type = '[Products Page] GetProducts Reset';
}

export class ProductsSetFilterAction {
    static readonly type = '[Products API] SetFilter';

    constructor(public payload: ProductsListGetFilterData) { }
}
