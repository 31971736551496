import { DCRFromProductAggregation } from './dcr-from-product-aggregation.model';
import { DCRToProductAggregation } from './dcr-to-product-aggregation.model';
import { LinkedOrganization } from '../../../../evasys/shared/models/business/organizations/linked-organization.model';
import { LinkedUser } from '../../../../evasys/shared/models/business/user/linked-user.model';
import { LinkedWarehouse } from '../../../../evasys/shared/models/business/warehouses/linked-warehouse.model';
import { LinkedOrganizationPos } from '../../../../evasys/shared/models/business/organization-pos/linked-organization-pos.model';

export class DCRItem {
    public uid: string;
    public number: string;
    public date: number;
    public organization: LinkedOrganization;
    public organizationPos: LinkedOrganizationPos;
    public warehouseFrom: LinkedWarehouse;
    public warehouseTo: LinkedWarehouse;
    public responsibleUser: LinkedUser;
    public fromProducts: DCRFromProductAggregation[];
    public toProducts: DCRToProductAggregation[];
    public sum: number;
    public status: number;
    public notes: string;
    public createDate: number;
    public updateDate: number;
}
