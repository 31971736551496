import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ProductImageDeleteAction {
    static readonly type = '[My Product Page] DeleteProductImage';

    constructor(public payload: { uid: string }) { }
}

export class ProductImageDeleteSuccessAction {
    static readonly type = '[My Product API] DeleteProductImage Success';

    constructor(public payload: { uid: string }) { }
}

export class ProductImageDeleteFailAction {
    static readonly type = '[My Product API] DeleteProductImage Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductImageDeleteResetAction {
    static readonly type = '[My Product Page] DeleteProductImage Reset';
}
