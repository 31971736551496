import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { VehicleBrand } from '../../models/business/pos/vehicle-brand.model';
import { VehicleBrandsListFilter } from '../../models/filters/vehicle-brands-list-filter.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class VehicleBrandsService extends BaseSingletonService {

    private settings: {
        service: {
            getVehicleBrands: string;
        }
    };

    constructor(private http: HttpService) {
        super('VehicleBrandsService');
        this.settings = {
            service: {
                getVehicleBrands: '/crm/v1/vehicle-brands?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}'
            }
        };
    }

    public async getVehicleBrands(filter: VehicleBrandsListFilter): Promise<PaginationResult<VehicleBrand>> {
        return this.http.get<PaginationResult<VehicleBrand>>(String.format(this.settings.service.getVehicleBrands, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }
}
