import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseOrdersService } from '../../services/business/purchase-orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PurchaseOrderCreateAction, PurchaseOrderCreateFailAction, PurchaseOrderCreateResetAction, PurchaseOrderCreateSuccessAction } from './purchase-order-create.actions';
import { PurchaseOrder } from '../../models/business/purchase-orders/purchase-order.model';

export interface PurchaseOrderCreateStateModel {
    pending: boolean;
    created: PurchaseOrder;
    error: ErrorObject;
}

@State<PurchaseOrderCreateStateModel>({
    name: 'purchaseOrderCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PurchaseOrderCreateState {
    constructor(private purchaseOrdersService: PurchaseOrdersService) { }

    @Action(PurchaseOrderCreateAction) async purchaseOrderCreate(ctx: StateContext<PurchaseOrderCreateStateModel>, action: PurchaseOrderCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.purchaseOrdersService.createPurchaseOrder({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PurchaseOrderCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseOrderCreateFailAction(err)), 0));
    }

    @Action(PurchaseOrderCreateSuccessAction) purchaseOrderCreateSuccess(ctx: StateContext<PurchaseOrderCreateStateModel>, action: PurchaseOrderCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PurchaseOrderCreateFailAction) purchaseOrderCreateFail(ctx: StateContext<PurchaseOrderCreateStateModel>, action: PurchaseOrderCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PurchaseOrderCreateResetAction) purchaseOrderCreateReset(ctx: StateContext<PurchaseOrderCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
