import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

// tslint:disable-next-line:no-namespace
export namespace OrdersErrorsUnreadCountAction {
    export class FromHeaderComponent {
        static readonly type = '[Header Component] GetOrdersErrorsUnreadCount';
    }

    export class OnNotificationSuccessUpdate {
        static readonly type = '[On Notification Success Update action] GetOrdersErrorsUnreadCount';
    }

    export type OrdersErrorsUnreadCountActionsUnion = FromHeaderComponent | OnNotificationSuccessUpdate;
}

export class OrdersErrorsUnreadCountSuccessAction {
    static readonly type = '[OrdersErrors API] GetOrdersErrorsUnreadCount Success';

    constructor(public payload: number) { }
}

export class OrdersErrorsUnreadCountFailAction {
    static readonly type = '[OrdersErrors API] GetOrdersErrorsUnreadCount Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrdersErrorsUnreadCountResetAction {
    static readonly type = '[Header Component] GetOrdersErrorsUnreadCount Reset';
}
