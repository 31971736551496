import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Timezone } from '../../models/business/timezones/timezone.model';

import { TimezonesAction, TimezonesFailAction, TimezonesResetAction, TimezonesSuccessAction } from './timezones.actions';
import { TimeZonesService } from '../../services/business/timezones.service';

export interface TimezonesStateModel {
    pending: boolean;
    timezones: Timezone[];
    error: ErrorObject;
}

@State<TimezonesStateModel>({
    name: 'timezones',
    defaults: { pending: false, timezones: [], error: null }
})
@Injectable()
export class TimezonesState {
    constructor(private timeZonesService: TimeZonesService) { }

    @Action(TimezonesAction) timezonesGet(ctx: StateContext<TimezonesStateModel>, action: TimezonesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.timeZonesService.getTimeZones(action.payload.culture)
            .then(resp => setTimeout(() => ctx.dispatch(new TimezonesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TimezonesFailAction(err)), 0));
    }

    @Action(TimezonesSuccessAction) timezonesGetSuccess(ctx: StateContext<TimezonesStateModel>, action: TimezonesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, timezones: action.payload, error: null });
    }

    @Action(TimezonesFailAction) timezonesGetFail(ctx: StateContext<TimezonesStateModel>, action: TimezonesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TimezonesResetAction) timezonesGetReset(ctx: StateContext<TimezonesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, timezones: [], error: null });
    }
}
