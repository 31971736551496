<section class="row-header">
    <exg-button *ngIf="showAddButton" (btnClick)="onAddButton()" class="add-btn" color="primary" styleType="texted">
        <exg-icon class="add-icon" color="primary" iconName="add"></exg-icon>
        <div class="btn-title">{{ addBtnTitle | translate }}</div>
    </exg-button>
    <div class="menu">
        <exg-menu class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event)">
            <exg-button class="menu_btn" [styleType]="btnMenuStyleType"><exg-icon class="menu_icon" color="primary" iconName="menudotted_vertical"></exg-icon></exg-button>
        </exg-menu>
    </div>
</section>
