import { ContractCreateOrUpdateRequest } from '../../models/business/contract/contract-create-or-update.model';
import { Contract } from '../../models/business/contract/contract.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContractCreateAction {
    static readonly type = '[Contracts Page] CreateContract';

    constructor(public payload: { contragentUid: string, request: ContractCreateOrUpdateRequest }) { }
}

export class ContractCreateSuccessAction {
    static readonly type = '[Contract API] CreateContract Success';

    constructor(public payload: Contract) { }
}

export class ContractCreateFailAction {
    static readonly type = '[Contract API] CreateContract Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContractCreateResetAction {
    static readonly type = '[Contracts Page] CreateContract Reset';
}
