<div class="right" *ngIf="posItem?.uid" [exgDisabled]="(pending$ | async) || (updatePending$ | async)">
    <section [ngClass]="{'corporation-card-header': !showName}" class="right-top-navigation">
        <span *ngIf="showName" >{{ posItem?.name }}</span>
        <div class="filters" [ngClass]="{'corporation-card': !showName}">
            <exg-datepicker
                class="datepicker"
                styleType="flat"
                [value]="filter?.date"
                [defaultValue]="defaultValue"
                (valueChange)="onChangeFilter($event)"
                placeholder="{{ 'Date' | translate }}"></exg-datepicker>

                <div class="setting-buttons">
                    <exg-button class="chip_btn" color="warn" styleType="outlined">
                        <exg-icon class="chip_icon" color="warn" iconName="stop-circle-line"></exg-icon>
                    </exg-button>
                    <exg-button (btnClick)="openIntervalForm()" class="settings_btn" styleType="outlined">
                        <exg-icon class="settings_icon" color="primary" iconName="gear1"></exg-icon>
                    </exg-button>
                </div>
        </div>
    </section>
    <div class="table">
        <div class="header-row">
            <div class="sticky-header">
                <div class="header-row">
                    <div class="slot header">
                        <span class="sorting-field">{{ 'Kitchen slot' | translate }}</span>
                    </div>
                    <div class="cooks header">
                        <span class="sorting-field">{{ 'Cooks' | translate }}</span>
                    </div>
                    <div class="maximum header">
                        <span class="sorting-field">{{ 'Max rub' | translate }}</span>
                    </div>
                    <div class="orders header">
                        <span class="sorting-field">{{ 'Orders' | translate }}</span>
                    </div>
                    <div class="total header">
                        <span class="sorting-field">{{ 'Total rub' | translate }}</span>
                    </div>
                    <div class="amount header">
                        <span class="sorting-field">{{ 'Remaining rub' | translate }}</span>
                    </div>
                    <div class="control header">
                        <span class="sorting-field">{{ 'Start/stop' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
        <perfect-scrollbar class="scroll-products" #scrollbar [config]="{ useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false }">
            <div>
                <div class="row-item" *ngFor="let interval of (intervals$ | async); let i = index" [class.selected]="(intervalForUpdate$ | async) === interval.dateFrom">
                    <div class="slot">
                        <div class="table-item">
                            {{ interval.dateFrom | yeTimeInterval : interval.dateTo }}
                        </div>
                    </div>

                    <div class="cooks">
                        <div class="table-item"></div>
                    </div>

                    <div class="maximum">
                        <div class="table-item interval-amount">
                            <exg-textbox
                                class="table-control"
                                [value]="'' + interval.maxAmount"
                                [inputMask]="{ type: 'number', scale: 2, min: 1, max: 9999999 }"
                                styleType="readonly"
                                [readonly]="(intervalForUpdate$ | async) !== interval.dateFrom"
                                (valueChange)="onChangeMaxAmount($event)"></exg-textbox>

                            <exg-button class="change-max-amount_btn" styleType="icon" (btnClick)="onChangeAmount(interval)">
                                <exg-icon
                                    class="change-max-amount_icon"
                                    [iconName]="(intervalForUpdate$ | async) === interval.dateFrom ? 'complete' : 'edit1'"></exg-icon>
                            </exg-button>
                        </div>
                    </div>

                    <div class="orders">
                        <div class="table-item">
                            <ng-container *ngFor="let order of interval.orders; trackBy: trackByOrders; let i = index">
                                {{ order.number }} ({{ order.amount | exgNumber : '1.2-2' }}){{ i == interval.orders?.length - 1 ? '' : ',' }}
                            </ng-container>
                        </div>
                    </div>
                    <div class="total">
                        <div class="table-item">{{ interval.totalAmount | exgNumber : '1.2-2' }}</div>
                    </div>
                    <div class="amount">
                        <div class="table-item">
                            {{ interval.maxAmount - interval.totalAmount | exgNumber : '1.2-2' }}
                        </div>
                    </div>
                    <div class="control">
                        <div class="table-item">
                            <exg-button (btnClick)="onStartPause(interval)" styleType="link" class="control-btn">
                                <exg-icon class="control-icon" [iconName]="interval.isStopped ? 'play-round' : 'pause-circle-line'"></exg-icon>
                            </exg-button>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>

<exg-dialog
    [componentData]="componentData"
    [dialogMode]="dialogMode.NoButtons"
    [mobileFullScreen]="true"
    [hideBackdrop]="!!dialogPosition"
    [disableClose]="true"
    [dialogPosition]="dialogPosition"
    [showDialog]="showDialog"
    (dialogClose)="onDialogClose($event)"></exg-dialog>
