import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductsListGetFilterData } from '../../models/filters/products-list-get-filter.model';

import { ProductsSearchAction, ProductsSearchResetAction, ProductsSearchSetFilterAction } from './products-search.actions';

@Injectable({
    providedIn: 'root'
})
export class ProductsSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('ProductsSearchDispatchers');
    }

    public dispatchProductsSearchAction(request: PaginationRequest) {
        this.store.dispatch(new ProductsSearchAction(request));
    }

    public dispatchProductsSearchResetAction() {
        this.store.dispatch(new ProductsSearchResetAction());
    }

    public dispatchProductsSearchSetFilterAction(filterData: ProductsListGetFilterData) {
        this.store.dispatch(new ProductsSearchSetFilterAction(filterData));
    }
}
