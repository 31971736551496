import { EvaSettingsTokens } from '../evasys/shared/eva-settings-tokens.config';
import { EvaSettings } from './eva-settings.config';

export class EvaSiteSettingsProviders {
    static getProviders: { token: string, value: any }[] = [
        { token: EvaSettingsTokens.tokens.headerMenu, value: EvaSettings.headerMenu },
        { token: EvaSettingsTokens.tokens.headerMobileMenu, value: EvaSettings.mobileMenu },
        { token: EvaSettingsTokens.tokens.headerSettings, value: EvaSettings.headerSettings },
        { token: EvaSettingsTokens.tokens.restrictedRoutes, value: EvaSettings.restrictedRoutes },
        { token: EvaSettingsTokens.tokens.ordersSettings, value: EvaSettings.ordersSettings },
        { token: EvaSettingsTokens.tokens.organizationsTabs, value: EvaSettings.organizationsTabs },
        { token: EvaSettingsTokens.tokens.userSettings, value: EvaSettings.userSettings },
        { token: EvaSettingsTokens.tokens.companyTabs, value: EvaSettings.companiesTabs },
        { token: EvaSettingsTokens.tokens.warehouseTabs, value: EvaSettings.warehouseTabs },
        { token: EvaSettingsTokens.tokens.financeTabs, value: EvaSettings.financeTabs },
        { token: EvaSettingsTokens.tokens.contragentTabs, value: EvaSettings.contragentsTabs },
        { token: EvaSettingsTokens.tokens.purchasesTabs, value: EvaSettings.purchasesTabs },
        { token: EvaSettingsTokens.tokens.purchasesAcceptanceEditTabs, value: EvaSettings.purchasesTabs },
        { token: EvaSettingsTokens.tokens.orderTabs, value: EvaSettings.orderTabs },
        { token: EvaSettingsTokens.tokens.yeOrderTabs, value: EvaSettings.callCenterTabs },
    ];
}
