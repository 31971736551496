import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { VatsAction, VatsFailAction, VatsResetAction, VatsSuccessAction } from './vats.actions';
import { DictionariesService } from '../../services/business/dictionaries.service';

export interface VatsStateModel {
    pending: boolean;
    entity: number[];
    error: ErrorObject;
}

@State<VatsStateModel>({
    name: 'vatType',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class VatsState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(VatsAction) async order(ctx: StateContext<VatsStateModel>, _: VatsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.dictionariesService.getVats()
            .then(res => setTimeout(() => ctx.dispatch(new VatsSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VatsFailAction(err)), 0));
    }

    @Action(VatsSuccessAction) orderSuccess(ctx: StateContext<VatsStateModel>, action: VatsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(VatsFailAction) orderFail(ctx: StateContext<VatsStateModel>, action: VatsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VatsResetAction) orderReset(ctx: StateContext<VatsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
