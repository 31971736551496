<div class="analytics-container" [class.main-page]="mainPage">
    <div class="selection-line">
        <exg-select class="select"
                    [value]="period"
                    [data]="periodSelection"
                    styleType="empty"
                    [displayValueTemplate]="periodDisplayTemplate"
                    [selectedDisplayTemplate]="periodSelectedDisplayTemplate"
                    placeholder="{{'Period selection' | translate}}"
                    (valueChange)="onPeriodChange($event)">
        </exg-select>

        <exg-select class="select"
                    [data]="groupSelection"
                    [value]="group"
                    styleType="empty"
                    [displayValueTemplate]="periodDisplayTemplate"
                    [selectedDisplayTemplate]="periodSelectedDisplayTemplate"
                    placeholder="{{'Group by' | translate}}"
                    (valueChange)="onGroupChange($event)">
        </exg-select>

        <ng-template #periodDisplayTemplate let-val>
            {{val?.text | translate}}
        </ng-template>
        <ng-template #periodSelectedDisplayTemplate let-val>{{val?.text | translate}}</ng-template>

        <exg-datepicker-range *ngIf="period.id === periodEnum.Custom"
                              class="dates"
                              [border]="false"
                              [value]="dateRange"
                              placeholder="{{'Range' | translate}}"
                              [changeLanguage]="cultureChanged$ | async"
                              (valueChange)="onDatesRangeChanged($event)"></exg-datepicker-range>
    </div>
    <perfect-scrollbar class="scroll">
        <div class="list-content">
            <div class="analytics-graph-box" >
                <bvc-graph class="analytics-graph" [data]="dataSeries" [group]="group.id"></bvc-graph>
            </div>
            
            <!-- <div class="info-box-group">
                <div class="info-box">
                    <div class="info-title">{{'Amount of orders shipped' | translate}}</div>
                    <div class="info-value">{{statistic.shippedOrdersAmount | exgNumber: '0.0-0':false:null:true}}</div>
                    <div class="dif">
                        <exg-icon *ngIf="statistic.shippedOrdersAmountDifference >= 0" class="statistic-icon" iconName="statistic-arrow-up"></exg-icon>
                        <exg-icon *ngIf="statistic.shippedOrdersAmountDifference < 0" class="statistic-icon" iconName="statistic-arrow-down"></exg-icon>
                        <span class="dif-plus" *ngIf="statistic.shippedOrdersAmountDifference >= 0">+{{getDifferencesPersent(statistic.shippedOrdersAmount, statistic.shippedOrdersAmountDifference) | exgPercent}}</span>
                        <span class="dif-minus" *ngIf="statistic.shippedOrdersAmountDifference < 0">{{getDifferencesPersent(statistic.shippedOrdersAmount, statistic.shippedOrdersAmountDifference) | exgPercent}}</span>
                        &nbsp;/&nbsp;{{('Per ' + periodEnum[period.id]) | translate}}
                    </div>
                </div>
    
                <div class="info-box">
                    <div class="info-title">{{'Sales amount' | translate}}</div>
                    <div class="info-value">{{statistic.ordersAmount | exgNumber: '0.0-0':false:null:true}}</div>
                    <div class="dif">
                        <exg-icon *ngIf="statistic.ordersAmountDifference >= 0" class="statistic-icon" iconName="statistic-arrow-up"></exg-icon>
                        <exg-icon *ngIf="statistic.ordersAmountDifference < 0" class="statistic-icon" iconName="statistic-arrow-down"></exg-icon>
                        <span class="dif-plus" *ngIf="statistic.ordersAmountDifference >= 0">+{{ getDifferencesPersent(statistic.ordersAmount, statistic.ordersAmountDifference) | exgPercent }}</span>
                        <span class="dif-minus" *ngIf="statistic.ordersAmountDifference < 0">{{ getDifferencesPersent(statistic.ordersAmount, statistic.ordersAmountDifference) | exgPercent }}</span>
                        &nbsp;/&nbsp;{{('Per ' + periodEnum[period.id]) | translate}}
                    </div>
                </div>
                
                <div class="info-box">
                    <div class="info-title">{{'Average check' | translate}}</div>
                    <div class="info-value">{{statistic.averageCheckAmount | exgNumber: '0.0-0':false:null:true}}</div>
                    <div class="dif">
                        <exg-icon *ngIf="statistic.averageCheckAmountDifference >= 0" class="statistic-icon" iconName="statistic-arrow-up"></exg-icon>
                        <exg-icon *ngIf="statistic.averageCheckAmountDifference < 0" class="statistic-icon" iconName="statistic-arrow-down"></exg-icon>
                        <span class="dif-plus" *ngIf="statistic.averageCheckAmountDifference >= 0">+{{getDifferencesPersent(statistic.averageCheckAmount, statistic.averageCheckAmountDifference) | exgPercent}}</span>
                        <span class="dif-minus" *ngIf="statistic.averageCheckAmountDifference < 0">{{getDifferencesPersent(statistic.averageCheckAmount, statistic.averageCheckAmountDifference) | exgPercent}}</span>
                        &nbsp;/&nbsp;{{('Per ' + periodEnum[period.id]) | translate}}
                    </div>
                </div>
            </div> -->
            <div class="info-box-accounts-receivable">

                <!-- <bvc-accounts-receivable class="accounts-receivable" *ngIf="companyUid || contragentUid"
                                         [accountsReceivable]="accountsReceivable$ | async"
                                         [pending]="accountsReceivablePending$ | async"></bvc-accounts-receivable>

                <bvc-accounts-receivable-in-stoplist class="accounts-receivable" *ngIf="(companyUid || contragentUid) && (accountsReceivable$ | async)?.isInStoplist"></bvc-accounts-receivable-in-stoplist> -->
            </div>
        </div>
    </perfect-scrollbar>
</div>
