import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { GroupListItem } from '../../models/business/nomenclature/group-list-item.model';

export class GroupsAction {
    static readonly type = '[Groups Page] Groups';
}

export class GroupsSuccessAction {
    static readonly type = '[Groups API] Groups Success';

    constructor(public payload: GroupListItem[]) { }
}

export class GroupsFailAction {
    static readonly type = '[Groups API] Groups Fail';

    constructor(public payload: ErrorObject) { }
}

export class GroupsResetAction {
    static readonly type = '[Groups Page] Groups Reset';
}
