// tslint:disable:no-namespace
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class MotivationBonusEditDocumentАction {
    static readonly type = '[My MotivationBonus Page] MotivationBonusEditDocument';

    constructor(public payload: { contactUid: string, motivationUid: string, request: FileList }) { }
}

export class MotivationBonusEditDocumentSuccessAction {
    static readonly type = '[My MotivationBonus API] MotivationBonusEditDocument Success';

    constructor(public payload: { contactUid: string, motivationUid: string, url: string }) { }
}

export class MotivationBonusEditDocumentFailAction {
    static readonly type = '[My MotivationBonus API] MotivationBonusEditDocument Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusEditDocumentResetAction {
    static readonly type = '[My MotivationBonus Page] MotivationBonusEditDocument Reset';
}
