import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ModifierType } from '../../models/business/modifiers/modifier-type.model';

import { ModifierTypeAction, ModifierTypeFailAction, ModifierTypeResetAction, ModifierTypeSuccessAction } from './modifier-types.actions';
import { ModifiersService } from '../../services/business/modifiers.service';


export interface ModifierTypesStateModel {
    pending: boolean;
    retrieved: boolean;
    entity: ModifierType[];
    error: ErrorObject;
}

@State<ModifierTypesStateModel>({
    name: 'modifierType',
    defaults: { pending: false, entity: [], retrieved: false, error: null }
})
@Injectable()
export class ModifierTypesState {
    constructor(private modifiersService: ModifiersService) { }

    @Action(ModifierTypeAction) async modifier(ctx: StateContext<ModifierTypesStateModel>, _: ModifierTypeAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: [], error: null });

        return this.modifiersService.getModifierTypes()
            .then(res => setTimeout(() => ctx.dispatch(new ModifierTypeSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ModifierTypeFailAction(err)), 0));
    }

    @Action(ModifierTypeSuccessAction) orderSuccess(ctx: StateContext<ModifierTypesStateModel>, action: ModifierTypeSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, retrieved: true, entity: action.payload, error: null });
    }

    @Action(ModifierTypeFailAction) orderFail(ctx: StateContext<ModifierTypesStateModel>, action: ModifierTypeFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ModifierTypeResetAction) orderReset(ctx: StateContext<ModifierTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: [], retrieved: false, error: null });
    }
}
