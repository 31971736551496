<section class="row-header">
    <bvc-filter-field class="full-name header"
                      [class.active]="filter.sortField === contractSortingFields.Number"
                      [name]="'Number'"
                      [fieldName]="contractSortingFields.Number"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="registered header"
                      [class.active]="filter.sortField === contractSortingFields.Name"
                      [name]="'Name'"
                      [fieldName]="contractSortingFields.Name"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="last-entrance header"
                      [class.active]="filter.sortField === contractSortingFields.DueDate"
                      [name]="'Due Date'"
                      [fieldName]="contractSortingFields.DueDate"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

</section>
<section class="row-header shadow">
    <div class="full-name header"></div>
    <div class="registered header"></div>
    <div class="last-entrance header"></div>
</section>