import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContactContragentFailAction, ContactContragentGetAction, ContactContragentGetSuccessAction, ContactContragentResetAction } from './contact-contragent.actions';

export interface ContactContragentStateModel {
    entity: Contragent;
    pending: boolean;
    error: ErrorObject;
}

@State<ContactContragentStateModel>({
    name: 'contactContragent',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ContactContragentState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(ContactContragentGetAction) contragentGet(ctx: StateContext<ContactContragentStateModel>, action: ContactContragentGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contragentsService.getContragent(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new ContactContragentGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContactContragentFailAction(err)), 0));
    }

    @Action(ContactContragentGetSuccessAction) contragentGetSuccess(ctx: StateContext<ContactContragentStateModel>, action: ContactContragentGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ContactContragentFailAction) contragentGetFail(ctx: StateContext<ContactContragentStateModel>, action: ContactContragentFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContactContragentResetAction) contragentGetReset(ctx: StateContext<ContactContragentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
