import { PurchaseReturnStatus } from '../business/purchase-returns/purchase-return-status.enum';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class PurchaseReturnListGetFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public status?: PurchaseReturnStatus;
    public citiesUids?: string[];
    public countryCodes?: string[];
    public dateFrom?: number;
    public dateTo?: number;
    public organizationUid?: string;
    public contragentUid?: string;
    public warehouseUid?: string;
    public userUid?: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: PurchaseReturnListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.dateFrom = (args && args.filterData) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData) ? args.filterData.dateTo : null;
        this.organizationUid = (args && args.filterData) ? args.filterData.organizationUid : null;
        this.warehouseUid = (args && args.filterData) ? args.filterData.warehouseUid : null;
        this.contragentUid = (args && args.filterData) ? args.filterData.contragentUid : null;
        this.userUid = (args && args.filterData) ? args.filterData.userUid : null;
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.citiesUids = (args && args.filterData) ? args.filterData.citiesUids : null;
        this.countryCodes = (args && args.filterData) ? args.filterData.countryCodes : null;
        this.status = (args && args.filterData && args.filterData.status != null) ? args.filterData.status : null;
    }
}

export class PurchaseReturnListGetFilterData extends SearchFilterData {
    public dateFrom?: number;
    public dateTo?: number;
    public status?: PurchaseReturnStatus;
    public sortField?: string;
    public sortOrder?: SortOrder;
    public pageSize?: number;
    public citiesUids?: string[];
    public countryCodes?: string[];
    public organizationUid?: string;
    public contragentUid?: string;
    public warehouseUid?: string;
    public userUid?: string;
}
