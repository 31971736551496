import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseReturnsService } from '../../services/business/purchase-returns.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PurchaseReturnDeleteAction, PurchaseReturnDeleteFailAction, PurchaseReturnDeleteResetAction, PurchaseReturnDeleteSuccessAction } from './purchase-return-delete.actions';

export interface PurchaseReturnDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PurchaseReturnDeleteStateModel>({
    name: 'purchaseReturnDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PurchaseReturnDeleteState {
    constructor(private purchaseReturnsService: PurchaseReturnsService) { }

    @Action(PurchaseReturnDeleteAction) async purchaseReturnDelete(ctx: StateContext<PurchaseReturnDeleteStateModel>, action: PurchaseReturnDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.purchaseReturnsService.deletePurchaseReturn(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PurchaseReturnDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PurchaseReturnDeleteFailAction(error)), 0));
    }

    @Action(PurchaseReturnDeleteSuccessAction) purchaseReturnDeleteSuccess(ctx: StateContext<PurchaseReturnDeleteStateModel>, _: PurchaseReturnDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PurchaseReturnDeleteFailAction) purchaseReturnDeleteFail(ctx: StateContext<PurchaseReturnDeleteStateModel>, action: PurchaseReturnDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseReturnDeleteResetAction) purchaseReturnDeleteReset(ctx: StateContext<PurchaseReturnDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
