import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PurchaseOrderDeleteAction {
    static readonly type = '[My PurchaseOrder Page] DeletePurchaseOrder';

    constructor(public payload: { uid: string }) { }
}

export class PurchaseOrderDeleteSuccessAction {
    static readonly type = '[My PurchaseOrders API] DeletePurchaseOrder Success';

    constructor(public payload: { uid: string }) { }
}

export class PurchaseOrderDeleteFailAction {
    static readonly type = '[My PurchaseOrders API] DeletePurchaseOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseOrderDeleteResetAction {
    static readonly type = '[My PurchaseOrder Page] DeletePurchaseOrder Reset';
}
