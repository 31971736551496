import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TasksService } from '../../services/business/tasks.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TaskDeleteAction, TaskDeleteFailAction, TaskDeleteResetAction, TaskDeleteSuccessAction } from './task-delete.actions';

export interface TaskDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<TaskDeleteStateModel>({
    name: 'taskDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class TaskDeleteState {
    constructor(private tasksService: TasksService) { }

    @Action(TaskDeleteAction) async taskDelete(ctx: StateContext<TaskDeleteStateModel>, action: TaskDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.tasksService.deleteTask(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new TaskDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new TaskDeleteFailAction(error)), 0));
    }

    @Action(TaskDeleteSuccessAction) taskDeleteSuccess(ctx: StateContext<TaskDeleteStateModel>, _: TaskDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(TaskDeleteFailAction) taskDeleteFail(ctx: StateContext<TaskDeleteStateModel>, action: TaskDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TaskDeleteResetAction) taskDeleteReset(ctx: StateContext<TaskDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
