import { ErrorListGetFilterData } from '../../models/filters/error-list-get-filter.model';
import { ErrorListItem } from '../../models/business/orders-errors/error-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrdersErrorsAction {
    static readonly type = '[OrdersErrorss Page] GetOrdersErrors';

    constructor(public payload: PaginationRequest) { }
}

export class OrdersErrorsSuccessAction {
    static readonly type = '[OrdersErrors API] GetOrdersErrors Success';

    constructor(public payload: { list: PaginationResult<ErrorListItem>, pagination: PaginationRequest }) { }
}

export class OrdersErrorsFailAction {
    static readonly type = '[OrdersErrors API] GetOrdersErrors Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrdersErrorsResetAction {
    static readonly type = '[OrdersErrors Page] GetOrdersErrors Reset';
}

export class OrdersErrorsSetFilterAction {
    static readonly type = '[OrdersErrors API] SetFilter';

    constructor(public payload: ErrorListGetFilterData) { }
}


