
import { AreaListItem } from '../../models/business/organization-pos/area-list-item.model';
import { Area } from '../../models/business/organization-pos/area.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosAreaAction {
    static readonly type = '[Pos Page] GetOrganizationPosArea';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationPosAreaFromListAction {
    static readonly type = '[Pos Page] GetOrganizationPosArea From List';

    constructor(public payload: AreaListItem) { }
}

export class OrganizationPosAreaSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosArea Success';

    constructor(public payload: Area) { }
}

export class OrganizationPosAreaFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosArea Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosAreaResetAction {
    static readonly type = '[Pos Page] GetOrganizationPosArea Reset';
}

