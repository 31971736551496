import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DialogService } from '../../../../../../burns-ui-framework/shared/services/common/dialog.service';

import { DialogResult } from '../../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { DocumentModel } from '../../../../models/business/feed/document.model';

@Component({
    selector: 'bvc-clients-activity-document',
    templateUrl: './clients-activity-document.component.html',
    styleUrls: ['./clients-activity-document.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityDocumentComponent implements OnChanges {
    @Input() document: DocumentModel;
    @Input() documentDownloadable: File;
    @Input() allowRemove = true;

    @Output() readonly deleteFile = new EventEmitter();

    public fileIcon = 'emptydocument';
    public fileSize = '';

    private knownExtentions = [
        { icon: 'docx', formats: ['.doc', '.docx'] },
        { icon: 'exel', formats: ['.xls', '.xlsx'] },
        { icon: 'pdf', formats: ['.pdf'] },
        { icon: 'jpg', formats: ['.jpg', '.jpeg', '.jpe'] },
        { icon: 'png', formats: ['.png'] }
    ];

    private kbytes = 1024;
    private mbytes = this.kbytes * this.kbytes;
    private gbytes = this.mbytes * this.kbytes;

    constructor(private dialogService: DialogService) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.document && this.document) {
            this.setDocumentIcon(this.document.fileUrl);
            this.fileSize = this.setFileSize(this.document.fileSize);
        }

        if (changes.documentDownloadable && this.documentDownloadable) {
            this.setDocumentIcon(this.documentDownloadable.name);
            this.fileSize = this.setFileSize(this.documentDownloadable.size);
        }
    }

    public async onDocumentRemove() {
        if (this.document && this.document.uid) {
            if (await this.dialogService.showConfirm({ title: 'Delete document', description: 'Are you sure you want to delete the document?' }) === DialogResult.Confirm) {
                this.deleteFile.emit();
            }
        } else {
            this.deleteFile.emit();
        }
    }

    private setDocumentIcon(fileName: string) {
        for (const knownExtention of this.knownExtentions) {
            if (knownExtention.formats.some(x => fileName.endsWith(x))) {
                this.fileIcon = knownExtention.icon;
                return;
            }
        }
    }

    private setFileSize(bytes: number) {
        if (bytes > this.gbytes) {
            return `${(bytes / this.gbytes).toFixed(2)} Gb`;
        }

        if (bytes > this.mbytes) {
            return `${(bytes / this.mbytes).toFixed(2)} Mb`;
        }

        return `${(bytes / this.kbytes).toFixed(2)} Kb`;
    }
}
