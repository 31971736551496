import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCreateOrUpdateRequest } from '../../models/business/catalog/product-create-or-update.model';
import { Product } from '../../models/business/catalog/product.model';
import { ModifierCreateOrUpdateRequest } from '../../models/business/modifiers/modifier-create-or-update-request.model';

export class ProductCreateAction {
    static readonly type = '[Products Page] CreateProduct';

    constructor(public payload: { request: ProductCreateOrUpdateRequest; modifiers: ModifierCreateOrUpdateRequest[] }) { }
}

export class ProductCreateSuccessAction {
    static readonly type = '[Product API] CreateProduct Success';

    constructor(public payload: Product) { }
}

export class ProductCreateFailAction {
    static readonly type = '[Product API] CreateProduct Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductCreateResetAction {
    static readonly type = '[Products Page] CreateProduct Reset';
}
