import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrdersListFilterData } from '../../models/filters/orders-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContragentOrdersAction {
    static readonly type = '[ContragentOrders Page] GetOrders';

    constructor(public payload: PaginationRequest) { }
}

export class ContragentOrdersSuccessAction {
    static readonly type = '[ContragentOrders API] GetOrders Success';

    constructor(public payload: { list: PaginationResult<OrderListItem>, pagination: PaginationRequest }) { }
}

export class ContragentOrdersFailAction {
    static readonly type = '[ContragentOrders API] GetOrders Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentOrdersResetAction {
    static readonly type = '[ContragentOrders Page] GetOrders Reset';
}

export class ContragentOrdersSetFilterAction {
    static readonly type = '[ContragentOrders API] SetFilter';

    constructor(public payload: OrdersListFilterData) { }
}
