import { Company } from '../../models/business/companies/company.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CompanyGetAction {
    static readonly type = '[Company Page] GetCompany Get';

    constructor(public payload: string) { }
}

export class CompanyGetSuccessAction {
    static readonly type = '[Company API] GetCompany Success';

    constructor(public payload: Company) { }
}

export class CompanyFailAction {
    static readonly type = '[Company API] GetCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyResetAction {
    static readonly type = '[Company Page] GetCompany Reset';
}
