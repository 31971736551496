import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TasksService } from '../../services/business/tasks.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { TaskCreateSuccessAction } from './task-create.actions';
import { TaskDeleteSuccessAction } from './task-delete.actions';
import { TaskUpdateSuccessAction } from './task-update.actions';
import { TasksAction, TasksFailAction, TasksResetAction, TasksSetFilterAction, TasksSuccessAction } from './tasks.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { Task } from '../../models/business/tasks/task.model';
import { TasksListFilter, TasksListFilterData } from '../../models/filters/tasks-list-filter.model';

export interface TasksStateModel extends PaginationStateModel<Task> {
    uids: string[];
    entities: { [uid: string]: Task };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TasksListFilterData;
    total: number;
}

@State<TasksStateModel>({
    name: 'tasks',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class TasksState extends PaginationStateBase {
    constructor(private tasksService: TasksService) {
        super();
    }

    @Action([TasksAction]) tasksGet(ctx: StateContext<TasksStateModel>, action: TasksAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new TasksListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.tasksAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.tasksService.getTaskList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TasksSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TasksFailAction(err)), 0));
    }

    @Action(TasksSuccessAction) tasksGetSuccess(ctx: StateContext<TasksStateModel>, action: TasksSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.tasksAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(TasksFailAction) tasksGetFail(ctx: StateContext<TasksStateModel>, action: TasksFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TasksResetAction) tasksGetReset(ctx: StateContext<TasksStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(TasksSetFilterAction) usersSetFilter(ctx: StateContext<TasksStateModel>, action: TasksSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new TasksAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(TaskCreateSuccessAction) taskCreateSuccess(ctx: StateContext<TasksStateModel>, _: TaskCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new TasksAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(TaskUpdateSuccessAction) taskUpdateSuccess(ctx: StateContext<TasksStateModel>, action: TaskUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(TaskDeleteSuccessAction) taskDeleteSuccess(ctx: StateContext<TasksStateModel>, action: TaskDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
