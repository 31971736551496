import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CustomersService } from '../../services/business/customers.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { CustomerCreateSuccessAction } from './customer-create.actions';
import { CustomerDeleteSuccessAction } from './customer-delete.actions';
import { CustomerUpdateSuccessAction } from './customer-update.actions';
import { CustomersAction, CustomersFailAction, CustomersResetAction, CustomersSetFilterAction, CustomersSuccessAction } from './customers.actions';

import { CustomerListItem } from '../../models/business/customers/customer-list-item.model';
import { CustomersListFilter, CustomersListFilterData } from '../../models/filters/customers-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface CustomersStateModel extends PaginationStateModel<CustomerListItem> {
    uids: string[];
    entities: { [uid: string]: CustomerListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: CustomersListFilterData;
    total: number;
}

@State<CustomersStateModel>({
    name: 'customers',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class CustomersState extends PaginationStateBase {
    constructor(private customersService: CustomersService) {
        super();
    }

    @Action([CustomersAction]) customersGet(ctx: StateContext<CustomersStateModel>, action: CustomersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CustomersListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.customersService.getCustomerList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CustomersSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomersFailAction(err)), 0));
    }

    @Action(CustomersSuccessAction) customersGetSuccess(ctx: StateContext<CustomersStateModel>, action: CustomersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CustomersFailAction) customersGetFail(ctx: StateContext<CustomersStateModel>, action: CustomersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomersResetAction) customersGetReset(ctx: StateContext<CustomersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(CustomersSetFilterAction) usersSetFilter(ctx: StateContext<CustomersStateModel>, action: CustomersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new CustomersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(CustomerCreateSuccessAction) customerCreateSuccess(ctx: StateContext<CustomersStateModel>, _: CustomerCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new CustomersAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(CustomerUpdateSuccessAction) customerUpdateSuccess(ctx: StateContext<CustomersStateModel>, action: CustomerUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(CustomerDeleteSuccessAction) customerDeleteSuccess(ctx: StateContext<CustomersStateModel>, action: CustomerDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
