import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosCreateOrUpdateRequest } from '../../models/business/organization-pos/pos-create-or-update-request.model';
import { OrganizationPos } from '../../models/business/organization-pos/pos.model';

export class OrganizationPosCreateAction {
    static readonly type = '[OrganizationPos Page] CreateOrganizationPos';

    constructor(public payload: OrganizationPosCreateOrUpdateRequest) { }
}

export class OrganizationPosCreateSuccessAction {
    static readonly type = '[OrganizationPos API] CreateOrganizationPos Success';

    constructor(public payload: OrganizationPos) { }
}

export class OrganizationPosCreateFailAction {
    static readonly type = '[OrganizationPos API] CreateOrganizationPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosCreateResetAction {
    static readonly type = '[OrganizationPos Page] CreateOrganizationPos Reset';
}
