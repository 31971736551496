import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { AttributeGroup } from '../../models/business/nomenclature/attribute-group.model';
import { GroupListItem } from '../../models/business/nomenclature/group-list-item.model';
import { UnitTypeListItem } from '../../models/business/nomenclature/unit-type-list-item.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class NomenclatureService extends BaseSingletonService {

    private settings: {
        service: {
            getGroups: string;
            getUnitTypes: string;
            getAttributeGroups: string
        }
    };

    constructor(private http: HttpService) {
        super('NomenclatureService');
        this.settings = {
            service: {
                getGroups: '/nomenclature/v1/dictionaries/groups',
                getUnitTypes: '/nomenclature/v1/dictionaries/unit-types',
                getAttributeGroups: '/nomenclature/v1/dictionaries/attribute-groups'
            }
        };
    }

    public async getGroups(): Promise<GroupListItem[]> {
        return this.http.get<{ list: GroupListItem[] }>(String.format(this.settings.service.getGroups))
            .then(res => res.list);
    }

    public async getUnitTypes(): Promise<UnitTypeListItem[]> {
        return this.http.get<{ list: UnitTypeListItem[] }>(String.format(this.settings.service.getUnitTypes))
            .then(res => res.list);
    }

    public async getAttributeGroups(): Promise<AttributeGroup[]> {
        return this.http.get<{ list: AttributeGroup[] }>(String.format(this.settings.service.getAttributeGroups))
            .then(res => res.list);
    }
}
