import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Plan } from '../../models/business/sales-plan/plan.model';

export class SalesPlanAction {
    static readonly type = '[SalesPlan Page] SalesPlan';

    constructor(public payload: { year: number, userUid: string }) { }
}

export class SalesPlanSuccessAction {
    static readonly type = '[SalesPlan API] SalesPlan Success';

    constructor(public payload: Plan) { }
}

export class SalesPlanFailAction {
    static readonly type = '[SalesPlan API] SalesPlan Fail';

    constructor(public payload: ErrorObject) { }
}

export class SalesPlanResetAction {
    static readonly type = '[SalesPlan Page] SalesPlan Reset';
}
