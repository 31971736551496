import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { AcceptanceCreateOrUpdateRequest } from '../../models/business/acceptances/acceptance-create-or-update-request.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { AcceptanceUpdateAction, AcceptanceUpdateResetAction } from './acceptance-update.actions';

@Injectable({
    providedIn: 'root'
})
export class AcceptanceUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('AcceptanceUpdateDispatchers');
    }

    public dispatchAcceptanceUpdateAction(uid: string, request: AcceptanceCreateOrUpdateRequest) {
        this.store.dispatch(new AcceptanceUpdateAction({ uid, request }));
    }

    public dispatchAcceptanceUpdateResetAction() {
        this.store.dispatch(new AcceptanceUpdateResetAction());
    }
}
