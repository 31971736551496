import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContactUpdateSuccessAction } from './contact-update.actions';
import { ContactFailAction, ContactGetAction, ContactGetSuccessAction, ContactResetAction } from './contact.actions';
import { Contact } from '../../models/business/contacts/contact.model';

export interface ContactStateModel {
    entity: Contact;
    pending: boolean;
    error: ErrorObject;
}

@State<ContactStateModel>({
    name: 'contact',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ContactState {
    constructor(private contactsService: ContactsService) { }

    @Action(ContactGetAction) contactGet(ctx: StateContext<ContactStateModel>, action: ContactGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contactsService.getContact(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new ContactGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContactFailAction(err)), 0));
    }

    @Action(ContactGetSuccessAction) contactGetSuccess(ctx: StateContext<ContactStateModel>, action: ContactGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ContactFailAction) contactGetFail(ctx: StateContext<ContactStateModel>, action: ContactFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContactResetAction) contactGetReset(ctx: StateContext<ContactStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(ContactUpdateSuccessAction) contactUpdateSuccess(ctx: StateContext<ContactStateModel>, action: ContactUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
