import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { NoteCreateRequest } from '../../models/business/notes/note-create-request.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { NoteCreateAction, NoteCreateResetAction } from './note-create.actions';

@Injectable({
    providedIn: 'root'
})
export class NoteCreateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('NoteCreateDispatchers');
    }

    public dispatchNoteCreateAction(request: NoteCreateRequest) {
        this.store.dispatch(new NoteCreateAction(request));
    }

    public dispatchNoteCreateResetAction() {
        this.store.dispatch(new NoteCreateResetAction());
    }
}
