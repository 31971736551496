import { BillingTransactionListItem } from '../../models/business/billing-transactions/billing-transaction.model';
import { BillingTransactionsFilterData } from '../../models/filters/billing-transactions-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class BillingTransactionsAction {
    static readonly type = '[Billing Transactions Page] GetBillingTransactions';

    constructor(public payload: PaginationRequest) { }
}

export class BillingTransactionsSuccessAction {
    static readonly type = '[Billing Transactions API] GetBillingTransactions Success';

    constructor(public payload: { list: PaginationResult<BillingTransactionListItem>, pagination: PaginationRequest }) { }
}

export class BillingTransactionsFailAction {
    static readonly type = '[Billing Transactions API] GetBillingTransactions Fail';

    constructor(public payload: ErrorObject) { }
}

export class BillingTransactionsResetAction {
    static readonly type = '[Billing Transactions Page] GetBillingTransactions Reset';
}

export class BillingTransactionsSetFilterAction {
    static readonly type = '[Billing Transactions API] SetFilter';

    constructor(public payload: BillingTransactionsFilterData) { }
}
