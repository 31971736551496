<button class="btn" type="button" (click)="onOpenPosClick()">
    <div class="full-name" *ngIf="listConfig[posSortingFields.FullName]" [class.active]="filter.sortField === posSortingFields.FullName">
        <span title="{{pos.name}}">{{pos.name}}</span>
    </div>
    <div class="brand" *ngIf="listConfig[posSortingFields.Brand]" [class.active]="filter.sortField === posSortingFields.Brand">
        <span title="{{pos.vehicleBrand?.name}}">{{pos.vehicleBrand?.name}}</span>
    </div>
    <div class="company" *ngIf="listConfig[posSortingFields.Company]" [class.active]="filter.sortField === posSortingFields.Company">
        <span title="{{pos.company?.name}}">{{pos.company?.name}}</span>
    </div>
    <div class="contact" *ngIf="listConfig[posSortingFields.Contact]" [class.active]="filter.sortField === posSortingFields.Contact">
        <span title="{{pos.mainContact?.fullName}}">{{pos.mainContact?.fullName}}</span>
    </div>
    <div class="phone" *ngIf="listConfig[posSortingFields.Phone]" [class.active]="filter.sortField === posSortingFields.Phone">
        <span title="{{pos.mainContact?.phone}}">{{pos.mainContact?.phone}}</span>
    </div>
    <div class="address" *ngIf="listConfig[posSortingFields.Address]" [class.active]="filter.sortField === posSortingFields.Address">
        <span title="{{pos.address?.fullName}}">{{pos.address?.fullName}}</span>
    </div>
</button>
<button class="btn btn_mobile" type="button" (click)="onOpenPosClick()">
    <div class="goup-line">
        <div class="goup-left">
            <div title="{{pos.name}}" class="title">{{pos.name}}</div>
        </div>
        <div class="goup-right"></div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Brand' | translate}}</div>
        <div class="goup-right" title="{{pos.vehicleBrand?.name}}">
            {{pos.vehicleBrand?.name}}
        </div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Company' | translate}}</div>
        <div class="goup-right" title="{{pos.company?.name}}">
            {{pos.company?.name}}
        </div>
    </div>
    <div class="goup-line">
        <div class="goup-left">{{'Contact' | translate}}</div>
        <div class="goup-right" title="{{pos.mainContact?.fullName}}">
            {{pos.mainContact?.fullName}}
        </div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Phone' | translate}}</div>
        <div class="goup-right" title="{{pos.mainContact?.phone}}">
            {{pos.mainContact?.phone}}
        </div>
    </div>
    <div class="goup-line">
        <div class="goup-left">{{'Address' | translate}}</div>
        <div class="goup-right" title="{{pos.address?.fullName}}">
            {{pos.address?.fullName}}
        </div>
    </div>
</button>