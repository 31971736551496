import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Order } from '../../models/business/orders/order.model';

import { OrderFailAction, OrderGetAction, OrderGetSuccessAction, OrderRepeatAction, OrderResetAction } from './order.actions';

export interface OrderStateModel {
    entity: Order;
    pending: boolean;
    error: ErrorObject;
}

@State<OrderStateModel>({
    name: 'order',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class OrderState {
    constructor(private ordersService: OrdersService) { }

    @Action(OrderGetAction) orderGet(ctx: StateContext<OrderStateModel>, action: OrderGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.ordersService.getOrder(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new OrderGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrderFailAction(err)), 0));
    }

    @Action(OrderGetSuccessAction) orderGetSuccess(ctx: StateContext<OrderStateModel>, action: OrderGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(OrderFailAction) orderGetFail(ctx: StateContext<OrderStateModel>, action: OrderFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrderResetAction) orderGetReset(ctx: StateContext<OrderStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(OrderRepeatAction) orderGetRepeat(ctx: StateContext<OrderStateModel>, action: OrderGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }
}
