import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CorporationRequisiteDeleteAction {
    static readonly type = '[My CorporationRequisite Page] DeleteCorporationRequisite';

    constructor(public payload: { uid: string, organizationUid: string }) { }
}

export class CorporationRequisiteDeleteSuccessAction {
    static readonly type = '[My CorporationRequisites API] DeleteCorporationRequisite Success';

    constructor(public payload: { uid: string }) { }
}

export class CorporationRequisiteDeleteFailAction {
    static readonly type = '[My CorporationRequisites API] DeleteCorporationRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationRequisiteDeleteResetAction {
    static readonly type = '[My CorporationRequisite Page] DeleteCorporationRequisite Reset';
}
