import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosCreateOrUpdateRequest } from '../../models/business/organization-pos/pos-create-or-update-request.model';
import { WarehouseCreateOrUpdateRequest } from '../../models/business/warehouses/warehouse-create-or-update-request.model';

export class OrganizationPosUpdateAction {
    static readonly type = '[Booking Page] UpdateOrganizationPos';

    constructor(public payload: { uid: string, request: OrganizationPosCreateOrUpdateRequest, warehouses: WarehouseCreateOrUpdateRequest[] }) { }
}

export class OrganizationPosUpdateSuccessAction {
    static readonly type = '[OrganizationPos API] UpdateOrganizationPos Success';

    constructor(public payload: { uid: string, request: OrganizationPosCreateOrUpdateRequest }) { }
}

export class OrganizationPosUpdateFailAction {
    static readonly type = '[OrganizationPos API] UpdateOrganizationPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosUpdateResetAction {
    static readonly type = '[Booking Page] UpdateOrganizationPos Reset';
}
