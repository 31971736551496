import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PublicProductListGetAggregationFilterData } from '../../models/filters/public-product-list-get-aggregation-filter.model';
import { PublicProductListItemAggregation } from '../../models/business/products/public-product-list-item-aggregation.model';

export class PublicCorporationProductsAction {
    static readonly type = '[PublicCorporationProducts Page] GetPublicCorporationProducts';

    constructor(public payload: PublicProductListGetAggregationFilterData) { }
}

export class PublicCorporationProductsSuccessAction {
    static readonly type = '[PublicCorporationProducts API] GetPublicCorporationProducts Success';

    constructor(public payload: { list: PublicProductListItemAggregation[] }) { }
}

export class PublicCorporationProductsFailAction {
    static readonly type = '[PublicCorporationProducts API] GetPublicCorporationProducts Fail';

    constructor(public payload: ErrorObject) { }
}

export class PublicCorporationProductsResetAction {
    static readonly type = '[PublicCorporationProducts Page] GetPublicCorporationProducts Reset';
}

