import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { PosCreateSuccessAction } from './pos-create.actions';
import { PosDeleteSuccessAction } from './pos-delete.actions';
import { PosListAction, PosListFailAction, PosListResetAction, PosListSetFilterAction, PosListSuccessAction } from './pos-list.actions';
import { PosUpdateSuccessAction } from './pos-update.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PosListFilter, PosListFilterData } from '../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../models/business/pos/pos-list-item.model';

export interface PosListStateModel extends PaginationStateModel<PosListItem> {
    uids: string[];
    entities: { [uid: string]: PosListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PosListFilterData;
    total: number;
}

@State<PosListStateModel>({
    name: 'posList',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class PosListState extends PaginationStateBase {
    constructor(private posService: PosService) {
        super();
    }

    @Action([PosListAction]) posGet(ctx: StateContext<PosListStateModel>, action: PosListAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new PosListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.posAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.posService.getPosList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PosListSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosListFailAction(err)), 0));
    }

    @Action(PosListSuccessAction) posGetSuccess(ctx: StateContext<PosListStateModel>, action: PosListSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.posAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(PosListFailAction) posGetFail(ctx: StateContext<PosListStateModel>, action: PosListFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosListResetAction) posGetReset(ctx: StateContext<PosListStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(PosListSetFilterAction) usersSetFilter(ctx: StateContext<PosListStateModel>, action: PosListSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PosListAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PosCreateSuccessAction) posCreateSuccess(ctx: StateContext<PosListStateModel>, action: PosCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(PosUpdateSuccessAction) posUpdateSuccess(ctx: StateContext<PosListStateModel>, action: PosUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PosDeleteSuccessAction) posDeleteSuccess(ctx: StateContext<PosListStateModel>, action: PosDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
