import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';


import { TechnologicalMapByProductState, TechnologicalMapByProductStateModel } from './technological-map-by-product.reducer';
import { TechnologicalMapProductHierarchy } from '../../models/business/technological-maps/technological-map-product-hierarchy.model';

@Injectable({
    providedIn: 'root'
})
export class TechnologicalMapByProductSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.pending);

    static readonly getTechnologicalMapArchive = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.retrieved);

    static readonly getError = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.error);

    static readonly getAllTechnologicalMapArchiveLoaded = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.filter);

    static readonly getTotal = createSelector([TechnologicalMapByProductState], (state: TechnologicalMapByProductStateModel) => state.total);

    @Select(TechnologicalMapByProductSelectors.getPending) pending$: Observable<boolean>;

    @Select(TechnologicalMapByProductSelectors.getTechnologicalMapArchive) technologicalMapByProduct$: Observable<TechnologicalMapProductHierarchy[]>;

    @Select(TechnologicalMapByProductSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(TechnologicalMapByProductSelectors.getError) error$: Observable<ErrorObject>;

    @Select(TechnologicalMapByProductSelectors.getAllTechnologicalMapArchiveLoaded) allTechnologicalMapArchiveLoaded$: Observable<boolean>;

    @Select(TechnologicalMapByProductSelectors.getFilter) filter$: Observable<TechnologicalMapListGetFilterData>;

    @Select(TechnologicalMapByProductSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('TechnologicalMapByProductSelectors');
    }
}
