import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

export class PricelistGetAction {
    static readonly type = '[Pricelist Page] GetPricelist Get';

    constructor(public payload: string) { }
}

export class PricelistGetWithCopyAction {
    static readonly type = '[Pricelist Page] GetPricelist GetWithCopy';

    constructor(public payload: string) { }
}

export class PricelistGetSuccessAction {
    static readonly type = '[Pricelist API] GetPricelist Success';

    constructor(public payload: Pricelist) { }
}

export class PricelistGetWithCopySuccessAction {
    static readonly type = '[Pricelist API] GetPricelist SuccessWithCopy';

    constructor(public payload: Pricelist) { }
}

export class PricelistFailAction {
    static readonly type = '[Pricelist API] GetPricelist Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistResetAction {
    static readonly type = '[Pricelist Page] GetPricelist Reset';
}
