import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonus } from '../../models/business/contacts/motivation-bonus.model';
import { MotivationBonusesCreateOrUpdateRequest } from '../../models/business/contacts/motivation-bonuses-create-or-update-request.model';

export class MotivationBonusCreateAction {
    static readonly type = '[MotivationBonus Page] CreateMotivationBonus';

    constructor(public payload: { contactUid: string, request: MotivationBonusesCreateOrUpdateRequest }) { }
}

export class MotivationBonusCreateSuccessAction {
    static readonly type = '[MotivationBonus API] CreateMotivationBonus Success';

    constructor(public payload: MotivationBonus) { }
}

export class MotivationBonusCreateFailAction {
    static readonly type = '[MotivationBonus API] CreateMotivationBonus Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusCreateResetAction {
    static readonly type = '[MotivationBonus Page] CreateMotivationBonus Reset';
}
