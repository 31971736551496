import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosAreasService } from '../../services/business/organization-pos-areas.service';

import { OrganizationPosAreaDeleteAction, OrganizationPosAreaDeleteFailAction, OrganizationPosAreaDeleteResetAction, OrganizationPosAreaDeleteSuccessAction } from './organization-pos-area-delete.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface OrganizationPosAreaDeleteStateModel {
    deleted: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosAreaDeleteStateModel>({
    name: 'organizationPosAreaDelete',
    defaults: {
        pending: false, deleted: null, error: null
    }
})
@Injectable()
export class OrganizationPosAreaDeleteState {
    constructor(private orgPosAreasService: OrganizationPosAreasService) { }

    @Action([OrganizationPosAreaDeleteAction]) organizationPosesGet(ctx: StateContext<OrganizationPosAreaDeleteStateModel>, action: OrganizationPosAreaDeleteAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosAreasService.deletePosArea(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new OrganizationPosAreaDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaDeleteFailAction(err)), 0));
    }

    @Action(OrganizationPosAreaDeleteSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosAreaDeleteStateModel>, _: OrganizationPosAreaDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, deleted: true, error: null });
    }

    @Action(OrganizationPosAreaDeleteFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosAreaDeleteStateModel>, action: OrganizationPosAreaDeleteFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosAreaDeleteResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosAreaDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, deleted: null, error: null });
    }
}
