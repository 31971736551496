import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ProductCreateAction, ProductCreateFailAction, ProductCreateResetAction, ProductCreateSuccessAction } from './product-create.actions';
import { Product } from '../../models/business/catalog/product.model';
import { ModifiersService } from '../../services/business/modifiers.service';
import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

export interface ProductCreateStateModel {
    pending: boolean;
    created: Product;
    error: ErrorObject;
}

@State<ProductCreateStateModel>({
    name: 'productCreate',
    defaults: { pending: false, created: null, error: null },
})
@Injectable()
export class ProductCreateState {
    constructor(private catalogService: CatalogService, private modifiersService: ModifiersService, private readonly technologicalMapsService: TechnologicalMapsService) {}

    @Action(ProductCreateAction) async productCreate(ctx: StateContext<ProductCreateStateModel>, action: ProductCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        const { modifiers, request } = action.payload;

        try {
            const createdProduct = await this.catalogService.createProduct(request);

            if (modifiers?.length) {
                await this.modifiersService.createUpdateModifiers(modifiers);
            }

            ctx.dispatch(new ProductCreateSuccessAction(createdProduct));
        } catch (err) {
            ctx.dispatch(new ProductCreateFailAction(err));
        }
    }

    @Action(ProductCreateSuccessAction) productCreateSuccess(ctx: StateContext<ProductCreateStateModel>, action: ProductCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ProductCreateFailAction) productCreateFail(ctx: StateContext<ProductCreateStateModel>, action: ProductCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ProductCreateResetAction) productCreateReset(ctx: StateContext<ProductCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
