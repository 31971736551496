import { ComponentHierarchy } from '../models/component-hierarchy.model';
import { ComponentType } from '../models/component-type.enum';
import { Geocoding } from '../models/geocoding.model';
import { Geocoding as FiasGeocoding } from '../../geocoding/models/geocoding.model';
import { YandexPlaceComponentShort } from '../../yandex-geocoding/models/yandex-place-component-short.model';
import { YandexPlaceComponentType } from '../../yandex-geocoding/models/yandex-place-component-type.enum';
import { YandexPlaceComponent } from '../../yandex-geocoding/models/yandex-place-component.model';
import { YandexPlaceInputComponentType } from '../../yandex-geocoding/models/yandex-place-input-component-type.enum';
import { YandexSuggestionPlaceComponent } from '../../yandex-geocoding/models/yandex-suggestion-place-component.model';

export class GeocodingMapper {
    public static MapYandex(place: YandexPlaceComponent & YandexSuggestionPlaceComponent): Geocoding {
        return {
            id: null,
            fullName: place.fullName,
            zip: null,
            text: null,
            name: place.name,
            longitude: place.center?.longitude,
            latitude: place.center?.latitude,
            componentType: GeocodingMapper.MapComponentType(place.componentType),
            components: !!place.components && place.components.length > 0
                ? place.components.map(c => GeocodingMapper.MapHierarchy(c))
                : null,
            lowerCornerLatitude: place.lowerCorner?.latitude,
            lowerCornerLongitude: place.lowerCorner?.longitude,
            upperCornerLatitude: place.upperCorner?.latitude,
            upperCornerLongitude: place.upperCorner?.longitude,
        };
    }

    public static MapFias(place: FiasGeocoding): Geocoding {
        return {
            id: place.id,
            fullName: place.fullName,
            zip: place.zip,
            text: place.text,
            name: place.name,
            longitude: place.longitude,
            latitude: place.latitude,
            componentType: place.componentType,
            parentPlaceId: place.parentPlaceId,
            components: place.components
        };
    }

    public static MapInputComponentType(componentType: ComponentType): YandexPlaceInputComponentType {
        switch (componentType) {
            case ComponentType.Country:
                return YandexPlaceInputComponentType.Country;
            case ComponentType.Region:
                return YandexPlaceInputComponentType.Province;
            case ComponentType.City:
                return YandexPlaceInputComponentType.City;
            case ComponentType.Locality:
                return YandexPlaceInputComponentType.District;
            case ComponentType.Street:
                return YandexPlaceInputComponentType.Street;
            case ComponentType.Building:
                return YandexPlaceInputComponentType.Building;
            default:
                return null;
        }
    }

    private static MapComponentType(componentType: YandexPlaceComponentType): ComponentType {
        switch (componentType) {
            case YandexPlaceComponentType.Country:
                return ComponentType.Country;
            case YandexPlaceComponentType.Region:
                return ComponentType.Region;
            case YandexPlaceComponentType.Area:
                return ComponentType.Region;
            case YandexPlaceComponentType.Province:
                return ComponentType.Region;
            case YandexPlaceComponentType.Locality:
                return ComponentType.City;
            case YandexPlaceComponentType.District:
                return ComponentType.Locality;
            case YandexPlaceComponentType.Street:
                return ComponentType.Street;
            case YandexPlaceComponentType.House:
                return ComponentType.Building;
            default:
                return null;
        }
    }

    private static MapHierarchy(hierarchy: YandexPlaceComponentShort): ComponentHierarchy {
        return {
            id: null,
            name: hierarchy.name,
            componentType: GeocodingMapper.MapComponentType(hierarchy.componentType)
        };
    }
}
