import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ManagerAssistant } from '../../models/business/manager-assistants/manager-assistant.model';
import { ManagerAssistantsListFilterData } from '../../models/filters/manager-assistants-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ManagerAssistantsAction {
    static readonly type = '[ManagerAssistants Page] GetManagerAssistants';

    constructor(public payload: PaginationRequest) { }
}

export class ManagerAssistantsSuccessAction {
    static readonly type = '[ManagerAssistants API] GetManagerAssistants Success';

    constructor(public payload: { list: PaginationResult<ManagerAssistant>, pagination: PaginationRequest }) { }
}

export class ManagerAssistantsFailAction {
    static readonly type = '[ManagerAssistants API] GetManagerAssistants Fail';

    constructor(public payload: ErrorObject) { }
}

export class ManagerAssistantsResetAction {
    static readonly type = '[ManagerAssistants Page] GetManagerAssistants Reset';
}

export class ManagerAssistantsSetFilterAction {
    static readonly type = '[ManagerAssistants API] SetFilter';

    constructor(public payload: ManagerAssistantsListFilterData) { }
}
