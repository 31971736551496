
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonusProduct } from '../../models/business/contacts/motivation-bonus-product.model';
import { MotivationBonusesProductsListGetFilterData } from '../../models/filters/motivation-bonuses-products-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class MotivationBonusesProductsAction {
    static readonly type = '[MotivationBonusesProducts Page] GetMotivationBonusesProducts';

    constructor(public payload: PaginationRequest) { }
}

export class MotivationBonusesProductsSuccessAction {
    static readonly type = '[MotivationBonusesProducts API] GetMotivationBonusesProducts Success';

    constructor(public payload: { list: PaginationResult<MotivationBonusProduct>, pagination: PaginationRequest }) { }
}

export class MotivationBonusesProductsFailAction {
    static readonly type = '[MotivationBonusesProducts API] GetMotivationBonusesProducts Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusesProductsResetAction {
    static readonly type = '[MotivationBonusesProducts Page] GetMotivationBonusesProducts Reset';
}

export class MotivationBonusesProductsSetFilterAction {
    static readonly type = '[MotivationBonusesProducts API] SetFilter';

    constructor(public payload: { uid: string, contactUid: string, filter: MotivationBonusesProductsListGetFilterData }) { }
}
