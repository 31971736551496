import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA, Provider } from '@angular/core';

import { SharedModule } from '../../burns-ui-framework/shared/shared.module';

import { BvcErpRootComponent } from './components/business/bvc-erp-root/bvc-erp-root.component';
import { BvcFilterFieldComponent } from './components/business/bvc-filter-field/bvc-filter-field.component';
import { BvcLeftMenuComponent } from './components/business/bvc-left-menu/bvc-left-menu.component';
import { BvcMobileMenuComponent } from './components/business/bvc-mobile-menu/bvc-mobile-menu.component';
import { BvcPagingMenuComponent } from './components/business/bvc-paging-menu/bvc-paging-menu.component';
import { BvcTabsComponent } from './components/business/bvc-tabs/bvc-tabs.component';

import { BvcAnalyticsComponent } from './components/business/bvc-analytics/bvc-analytics.component';
import { BvcGraphComponent } from './components/business/bvc-graph/bvc-graph.component';
import { BvcHeaderComponent } from './components/business/bvc-header/bvc-header.component';
import { BvcWorkScheduleComponent } from './components/business/bvc-work-schedule/bvc-work-schedule.component';

import { BvcClientsActivityDocumentComponent } from './components/business/clients-activity/clients-activity-document/clients-activity-document.component';
import { BvcClientsActivityFormComponent } from './components/business/clients-activity/clients-activity-form/clients-activity-form.component';
import { BvcClientsActivityItemComponent } from './components/business/clients-activity/clients-activity-item/clients-activity-item.component';
import { BvcClientsActivityNoteComponent } from './components/business/clients-activity/clients-activity-note/clients-activity-note.component';
import { BvcClientsActivityTaskCallComponent } from './components/business/clients-activity/clients-activity-task-call/clients-activity-task-call.component';
import { BvcClientsActivityTaskEmailFormComponent } from './components/business/clients-activity/clients-activity-task-email-form/clients-activity-task-email-form.component';
import { BvcClientsActivityTaskEmailComponent } from './components/business/clients-activity/clients-activity-task-email/clients-activity-task-email.component';
import { BvcClientsActivityTaskFormComponent } from './components/business/clients-activity/clients-activity-task-form/clients-activity-task-form.component';
import { BvcClientsActivityTaskMeetingComponent } from './components/business/clients-activity/clients-activity-task-meeting/clients-activity-task-meeting.component';
import { BvcClientsActivityTaskComponent } from './components/business/clients-activity/clients-activity-task/clients-activity-task.component';
import { BvcClientsActivityComponent } from './components/business/clients-activity/clients-activity.component';
import { CompanyAddComponent } from './components/business/company-list/company-add/company-add.component';
import { CompanyHeaderComponent } from './components/business/company-list/company-header/company-header.component';
import { CompanyListItemComponent } from './components/business/company-list/company-list-item/company-list-item.component';
import { CompanyListComponent } from './components/business/company-list/company-list.component';
import { ContactAddComponent } from './components/business/contact-list/contact-add/contact-add.component';
import { ContactHeaderComponent } from './components/business/contact-list/contact-header/contact-header.component';
import { ContactListItemComponent } from './components/business/contact-list/contact-list-item/contact-list-item.component';
import { ContactListComponent } from './components/business/contact-list/contact-list.component';
import { ContractAddComponent } from './components/business/contracts-list/contracts-add/contracts-add.component';
import { ContractHeaderComponent } from './components/business/contracts-list/contracts-header/contracts-header.component';
import { ContractListItemComponent } from './components/business/contracts-list/contracts-list-item/contracts-list-item.component';
import { ContractListComponent } from './components/business/contracts-list/contracts-list.component';
import { ContragentAddComponent } from './components/business/contragent-list/contragent-add/contragent-add.component';
import { ContragentHeaderComponent } from './components/business/contragent-list/contragent-header/contragent-header.component';
import { ContragentListItemComponent } from './components/business/contragent-list/contragent-list-item/contragent-list-item.component';
import { ContragentListComponent } from './components/business/contragent-list/contragent-list.component';
import { OrdersAddComponent } from './components/business/orders-list/orders-add/orders-add.component';
import { OrderHeaderComponent } from './components/business/orders-list/orders-header/orders-header.component';
import { OrderListItemComponent } from './components/business/orders-list/orders-list-item/orders-list-item.component';
import { OrdersListComponent } from './components/business/orders-list/orders-list.component';
import { OrganizationsTreeComponent } from './components/business/organizations-tree/organizations-tree.component';
import { PosAddComponent } from './components/business/pos-list/pos-add/pos-add.component';
import { PosHeaderComponent } from './components/business/pos-list/pos-header/pos-header.component';
import { PosListItemComponent } from './components/business/pos-list/pos-list-item/pos-list-item.component';
import { PosListComponent } from './components/business/pos-list/pos-list.component';
import { BvcRightMenuComponent } from './components/business/bvc-right-menu/bvc-right-menu.component';
import { BvcFormHeaderComponent } from './components/business/bvc-form-header/bvc-form-header.component';
import { BvcOrganizationFilterComponent } from './components/business/bvc-organization-filter/bvc-organization-filter.component';
import { BvcEmptyFieldComponent } from './components/business/bvc-empty-field/bvc-empty-field.component';
import { BvcOrganizationFilterButtonComponent } from './components/business/bvc-organization-filter-button/bvc-organization-filter-button.component';
import { BvcIntervalFormComponent } from './components/business/bvc-interval-form/bvc-interval-form.component';
import { IntervalsTableComponent } from './components/business/intervals-table/intervals-table.component';
import { YeTimeIntervalPipe } from '../../ye-shared/pipes/ye-time-interval/ye-time-interval.pipe';
import { BvcFormMenuComponent } from './components/business/bvc-form-menu/bvc-form-menu.component';
import { TechnologicalMapPositionFormComponent } from './components/business/technological-map-position-form/technological-map-position-form.component';

@NgModule({
    imports: [
        SharedModule,
    ],

    declarations: [
        BvcAnalyticsComponent,
        BvcErpRootComponent,
        BvcEmptyFieldComponent,
        BvcFilterFieldComponent,
        BvcGraphComponent,
        BvcHeaderComponent,
        BvcLeftMenuComponent,
        BvcMobileMenuComponent,
        BvcPagingMenuComponent,
        BvcTabsComponent,
        BvcWorkScheduleComponent,
        BvcRightMenuComponent,
        BvcFormHeaderComponent,
        BvcOrganizationFilterComponent,
        BvcOrganizationFilterButtonComponent,
        BvcIntervalFormComponent,
        BvcFormMenuComponent,

        BvcClientsActivityFormComponent,
        BvcClientsActivityDocumentComponent,
        BvcClientsActivityItemComponent,
        BvcClientsActivityNoteComponent,
        BvcClientsActivityComponent,
        BvcClientsActivityTaskComponent,
        BvcClientsActivityTaskFormComponent,
        BvcClientsActivityTaskEmailComponent,
        BvcClientsActivityTaskCallComponent,
        BvcClientsActivityTaskMeetingComponent,
        BvcClientsActivityTaskEmailFormComponent,

        CompanyListComponent,
        CompanyListItemComponent,
        CompanyHeaderComponent,
        CompanyAddComponent,

        ContactListComponent,
        ContactListItemComponent,
        ContactHeaderComponent,
        ContactAddComponent,

        ContractListComponent,
        ContractHeaderComponent,
        ContractListItemComponent,
        ContractAddComponent,

        ContragentListComponent,
        ContragentListItemComponent,
        ContragentHeaderComponent,
        ContragentAddComponent,

        OrdersAddComponent,
        OrderHeaderComponent,
        OrderListItemComponent,
        OrdersListComponent,

        PosListComponent,
        PosListItemComponent,
        PosHeaderComponent,
        PosAddComponent,

        OrganizationsTreeComponent,
        IntervalsTableComponent,
        TechnologicalMapPositionFormComponent,

        YeTimeIntervalPipe
    ],
    exports: [
        BvcAnalyticsComponent,
        BvcErpRootComponent,
        BvcEmptyFieldComponent,
        BvcFilterFieldComponent,
        BvcGraphComponent,
        BvcHeaderComponent,
        BvcLeftMenuComponent,
        BvcMobileMenuComponent,
        BvcPagingMenuComponent,
        BvcTabsComponent,
        BvcWorkScheduleComponent,
        BvcRightMenuComponent,
        BvcFormHeaderComponent,
        BvcIntervalFormComponent,
        BvcFormMenuComponent,

        BvcClientsActivityFormComponent,
        BvcClientsActivityDocumentComponent,
        BvcClientsActivityItemComponent,
        BvcClientsActivityNoteComponent,
        BvcClientsActivityComponent,
        BvcClientsActivityTaskComponent,
        BvcClientsActivityTaskFormComponent,
        BvcClientsActivityTaskEmailComponent,
        BvcClientsActivityTaskCallComponent,
        BvcClientsActivityTaskMeetingComponent,
        BvcClientsActivityTaskEmailFormComponent,
        BvcOrganizationFilterComponent,
        BvcOrganizationFilterButtonComponent,

        CompanyListComponent,
        CompanyListItemComponent,
        CompanyHeaderComponent,
        CompanyAddComponent,

        ContactListComponent,
        ContactListItemComponent,
        ContactHeaderComponent,
        ContactAddComponent,

        ContractListComponent,
        ContractHeaderComponent,
        ContractListItemComponent,
        ContractAddComponent,

        ContragentListComponent,
        ContragentListItemComponent,
        ContragentHeaderComponent,
        ContragentAddComponent,

        OrdersAddComponent,
        OrderHeaderComponent,
        OrderListItemComponent,
        OrdersListComponent,

        PosListComponent,
        PosListItemComponent,
        PosHeaderComponent,
        PosAddComponent,

        OrganizationsTreeComponent,
        IntervalsTableComponent,
        TechnologicalMapPositionFormComponent,

        YeTimeIntervalPipe
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedEvaModule {
    static forRoot(settingsProviders: { token: string, value: any }[]): ModuleWithProviders<SharedEvaModule> {
        const providers: Provider[] = settingsProviders.map(setting => ({ provide: setting.token, useValue: setting.value }));

        return {
            ngModule: SharedEvaModule,
            providers
        };
    }
}
