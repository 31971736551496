import { Brand } from '../../models/business/brands/brand.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ProductsBrandsAction {
    static readonly type = '[ProductsBrands Page] GetProductsBrands';
}

export class ProductsBrandsSuccessAction {
    static readonly type = '[ProductsBrands API] GetProductsBrands Success';

    constructor(public payload: Brand[]) { }
}

export class ProductsBrandsFailAction {
    static readonly type = '[ProductsBrands API] GetProductsBrands Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductsBrandsResetAction {
    static readonly type = '[ProductsBrands Page] GetProductsBrands Reset';
}
