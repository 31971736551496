import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { OrganizationsListGetFilterData } from '../../models/filters/organizations-list-get-filter.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { OrganizationsSearchAction, OrganizationsSearchResetAction } from './organizations-search.actions';

@Injectable({
    providedIn: 'root'
})
export class OrganizationsSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('OrganizationsSearchDispatchers');
    }

    public dispatchOrganizationsSearchAction(request: OrganizationsListGetFilterData) {
        this.store.dispatch(new OrganizationsSearchAction(request));
    }

    public dispatchOrganizationsSearchResetAction() {
        this.store.dispatch(new OrganizationsSearchResetAction());
    }
}
