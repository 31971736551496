import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SharedModule } from '../shared/shared.module';

import { YeYandexGeocodingComponent } from './components/exg-yandex-autocomplete/ye-yandex-geocoding.component';

import { PlacesForwardState } from './store/places-forward.reducer';
import { PlacesReverseState } from './store/places-reverse.reducer';
import { PlacesSuggestionsState } from './store/places-suggestions.reducer';

@NgModule({
    imports: [
        SharedModule,
        NgxsModule.forFeature([
            PlacesForwardState,
            PlacesReverseState,
            PlacesSuggestionsState
        ])
    ],
    declarations: [
        YeYandexGeocodingComponent
    ],
    exports: [
        YeYandexGeocodingComponent
    ]
})
export class YandexGeocodingModule {
}
