import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PostingsService } from '../../services/business/postings.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PostingWorkbookFailAction, PostingWorkbookGetAction, PostingWorkbookGetSuccessAction, PostingWorkbookResetAction } from './posting-workbook.actions';

export interface PostingWorkbookStateModel {
    created: Blob;
    pending: boolean;
    error: ErrorObject;
}

@State<PostingWorkbookStateModel>({
    name: 'postingWorkbook',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PostingWorkbookState {
    constructor(private postingsService: PostingsService) { }

    @Action(PostingWorkbookGetAction) postingGet(ctx: StateContext<PostingWorkbookStateModel>, action: PostingWorkbookGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.postingsService.getWorkbook(action.payload.uid, action.payload.fileName)
            .then(resp => setTimeout(() => ctx.dispatch(new PostingWorkbookGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PostingWorkbookFailAction(err)), 0));
    }

    @Action(PostingWorkbookGetSuccessAction) postingGetSuccess(ctx: StateContext<PostingWorkbookStateModel>, action: PostingWorkbookGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PostingWorkbookFailAction) postingGetFail(ctx: StateContext<PostingWorkbookStateModel>, action: PostingWorkbookFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PostingWorkbookResetAction) postingGetReset(ctx: StateContext<PostingWorkbookStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
