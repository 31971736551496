import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TransferCreateOrUpdateRequest } from '../../models/business/transfers/transfer-create-or-update-request.model';

export class TransferUpdateAction {
    static readonly type = '[Booking Page] UpdateTransfer';

    constructor(public payload: { uid: string, request: TransferCreateOrUpdateRequest }) { }
}

export class TransferUpdateSuccessAction {
    static readonly type = '[Transfer API] UpdateTransfer Success';

    constructor(public payload: { uid: string, request: TransferCreateOrUpdateRequest }) { }
}

export class TransferUpdateFailAction {
    static readonly type = '[Transfer API] UpdateTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransferUpdateResetAction {
    static readonly type = '[Booking Page] UpdateTransfer Reset';
}
