import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class InventoryWorkbookGetAction {
    static readonly type = '[InventoryWorkbook Page] GetInventoryWorkbook Get';

    constructor(public payload: { uid: string, fileName: string }) { }
}

export class InventoryWorkbookGetSuccessAction {
    static readonly type = '[InventoryWorkbook API] GetInventoryWorkbook Success';

    constructor(public payload: Blob) { }
}

export class InventoryWorkbookFailAction {
    static readonly type = '[InventoryWorkbook API] GetInventoryWorkbook Fail';

    constructor(public payload: ErrorObject) { }
}

export class InventoryWorkbookResetAction {
    static readonly type = '[InventoryWorkbook Page] GetInventoryWorkbook Reset';
}
