import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SupplierInvoicesService } from '../../services/business/supplier-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { SupplierInvoiceCreateAction, SupplierInvoiceCreateFailAction, SupplierInvoiceCreateResetAction, SupplierInvoiceCreateSuccessAction } from './supplier-invoice-create.actions';
import { SupplierInvoice } from '../../models/business/supplier-invoices/supplier-invoice.model';

export interface SupplierInvoiceCreateStateModel {
    pending: boolean;
    created: SupplierInvoice;
    error: ErrorObject;
}

@State<SupplierInvoiceCreateStateModel>({
    name: 'supplierInvoiceCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class SupplierInvoiceCreateState {
    constructor(private supplierInvoicesService: SupplierInvoicesService) { }

    @Action(SupplierInvoiceCreateAction) async supplierInvoiceCreate(ctx: StateContext<SupplierInvoiceCreateStateModel>, action: SupplierInvoiceCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.supplierInvoicesService.createSupplierInvoice({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new SupplierInvoiceCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SupplierInvoiceCreateFailAction(err)), 0));
    }

    @Action(SupplierInvoiceCreateSuccessAction) supplierInvoiceCreateSuccess(ctx: StateContext<SupplierInvoiceCreateStateModel>, action: SupplierInvoiceCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(SupplierInvoiceCreateFailAction) supplierInvoiceCreateFail(ctx: StateContext<SupplierInvoiceCreateStateModel>, action: SupplierInvoiceCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(SupplierInvoiceCreateResetAction) supplierInvoiceCreateReset(ctx: StateContext<SupplierInvoiceCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
