
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationDeleteAction {
    static readonly type = '[My Organization Page] DeleteOrganization';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationDeleteSuccessAction {
    static readonly type = '[My Organizations API] DeleteOrganization Success';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationDeleteFailAction {
    static readonly type = '[My Organizations API] DeleteOrganization Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationDeleteResetAction {
    static readonly type = '[My Organization Page] DeleteOrganization Reset';
}
