import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PersonnelsService } from '../../services/business/personnels.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PersonnelUpdateAction, PersonnelUpdateFailAction, PersonnelUpdateResetAction, PersonnelUpdateSuccessAction } from './personnel-update.actions';

export interface PersonnelUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<PersonnelUpdateStateModel>({
    name: 'personnelUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class PersonnelUpdateState {
    constructor(private personnelsService: PersonnelsService) { }

    @Action(PersonnelUpdateAction) async personnelUpdate(ctx: StateContext<PersonnelUpdateStateModel>, action: PersonnelUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.personnelsService.updatePersonnel(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PersonnelUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PersonnelUpdateFailAction(err)), 0));
    }

    @Action(PersonnelUpdateSuccessAction) personnelUpdateSuccess(ctx: StateContext<PersonnelUpdateStateModel>, _: PersonnelUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(PersonnelUpdateFailAction) personnelUpdateFail(ctx: StateContext<PersonnelUpdateStateModel>, action: PersonnelUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(PersonnelUpdateResetAction) personnelUpdateReset(ctx: StateContext<PersonnelUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
