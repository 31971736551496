import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PlanCreateOrUpdateRequest } from '../../models/business/sales-plan/plan-create-or-update-request.model';

export class SalesPlanUpdateAction {
    static readonly type = '[SalesPlan Page] UpdateSalesPlan';

    constructor(public payload: { year: number, userUid: string, request: PlanCreateOrUpdateRequest }) { }
}

export class SalesPlanUpdateSuccessAction {
    static readonly type = '[SalesPlan API] UpdateSalesPlan Success';

    constructor(public payload: { year: number, userUid: string, request: PlanCreateOrUpdateRequest }) { }
}

export class SalesPlanUpdateFailAction {
    static readonly type = '[SalesPlan API] UpdateSalesPlan Fail';

    constructor(public payload: ErrorObject) { }
}

export class SalesPlanUpdateResetAction {
    static readonly type = '[SalesPlan Page] UpdateSalesPlan Reset';
}
