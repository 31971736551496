import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

export class CategoriesSearchAction {
    static readonly type = '[CategoriesSearch Page] GetCategories';
}

export class CategoriesSearchSuccessAction {
    static readonly type = '[CategoriesSearch API] GetCategories Success';

    constructor(public payload: PaginationResult<ProductCategory>) { }
}

export class CategoriesSearchFailAction {
    static readonly type = '[CategoriesSearch API] GetCategories Fail';

    constructor(public payload: ErrorObject) { }
}

export class CategoriesSearchResetAction {
    static readonly type = '[CategoriesSearch Page] GetCategories Reset';
}
