import { ContragentCreateRequest } from '../../models/business/contragents/contragent-create-or-update.model';
import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContragentCreateAction {
    static readonly type = '[Contragents Page] CreateContragent';

    constructor(public payload: ContragentCreateRequest) { }
}

export class ContragentCreateSuccessAction {
    static readonly type = '[Contragent API] CreateContragent Success';

    constructor(public payload: Contragent) { }
}

export class ContragentCreateFailAction {
    static readonly type = '[Contragent API] CreateContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentCreateResetAction {
    static readonly type = '[Contragents Page] CreateContragent Reset';
}
