import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

export class ActivePricelistGetAction {
    static readonly type = '[ActivePricelist Page] GetActivePricelist Get';
}

export class ActivePricelistGetSuccessAction {
    static readonly type = '[ActivePricelist API] GetActivePricelist Success';

    constructor(public payload: Pricelist) { }
}

export class ActivePricelistFailAction {
    static readonly type = '[ActivePricelist API] GetActivePricelist Fail';

    constructor(public payload: ErrorObject) { }
}

export class ActivePricelistResetAction {
    static readonly type = '[ActivePricelist Page] GetActivePricelist Reset';
}
