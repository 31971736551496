import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';

import { OrganizationPosesState, OrganizationPosesStateModel } from './organization-poses.reducer';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPosesSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.pending);

    static readonly getOrganizationPoses = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.retrieved);

    static readonly getError = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.error);

    static readonly getAllOrganizationPosesLoaded = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.filter);

    static readonly getTotal = createSelector([OrganizationPosesState], (state: OrganizationPosesStateModel) => state.total);

    @Select(OrganizationPosesSelectors.getPending) pending$: Observable<boolean>;

    @Select(OrganizationPosesSelectors.getOrganizationPoses) organizationPoses$: Observable<OrganizationPosListItem[]>;

    @Select(OrganizationPosesSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(OrganizationPosesSelectors.getError) error$: Observable<ErrorObject>;

    @Select(OrganizationPosesSelectors.getAllOrganizationPosesLoaded) allOrganizationPosesLoaded$: Observable<boolean>;

    @Select(OrganizationPosesSelectors.getFilter) filter$: Observable<OrganizationPosesListGetFilterData>;

    @Select(OrganizationPosesSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('OrganizationPosesSelectors');
    }
}
