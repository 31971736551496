export enum OrderStatus {
    Unconfirmed = 1,
    New = 2,
    ToDo = 20,
    Paid = 11,
    Processing = 21,
    Prepareing = 25,
    Prepared = 26,
    ReadyForShipping = 40,
    OnWay = 45,
    Shipped = 46,
    Completed = 90,
    Cancelled = 99,
    Deleted = 100,
    Error = -1,
}
