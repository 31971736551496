import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { DeliveryProductsListGetFilter } from '../../models/filters/delivery-products-list-get-filter.model';
import { MediaCreateRequest } from '../../../../burns-ui-framework/shared/models/business/media/media-light-request.model';
import { MediaLight } from '../../../../burns-ui-framework/shared/models/business/media/media-light.model';
import { Media } from '../../../../burns-ui-framework/shared/models/business/media/media.model';
import { MediasFilter } from '../../models/filters/medias-filter.model';
import { ProductType } from '../../models/business/orders/order-product-type.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ProductCreateOrUpdateRequest } from '../../models/business/catalog/product-create-or-update.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { Product } from '../../models/business/catalog/product.model';
import { ProductsListFilter } from '../../models/filters/products-list-filter.model';
import { ProductsListGetFilter } from '../../models/filters/products-list-get-filter.model';
import { WarehousesProductListItem } from '../../models/business/warehouses/warehouses-product-list-item.model';
import { WarehousesProductsListGetFilter } from '../../models/filters/warehouses-products-list-get-filter.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root',
})
export class ProductsService extends BaseSingletonService {
    private settings: {
        service: {
            getProduct: string;
            getProductList: string;
            createProduct: string;
            updateProduct: string;
            deleteProduct: string;
            getProductTypes: string;
            getProductMediaList: string;
            deleteProductMedia: string;
            createProductMedia: string;
            getNomenclatureProductList: string;
            getWarehousesProductList: string;
            getDeliveryProductsList: string;
        };
    };

    constructor(private http: HttpService) {
        super('ProductsService');
        this.settings = {
            service: {
                getProduct: '/nomenclature/v1/products/{0}',
                getProductList:
                    '/warehouses-aggregation/v1/products?term={0}&categoryUids={1}&warehouseUid={2}&typeUids={3}&isArchived={4}&sortField={5}&sortOrder={6}&pageIndex={7}&pageSize={8}&organizationUids={9}&organizationPosUids={10}&franchiseeUids={11}&groupUids={12}',
                getNomenclatureProductList:
                    '/nomenclature/v1/products?term={0}&categoryUids={1}&brandUids={2}&countryCodes={3}&isArchived={4}&sortField={5}&sortOrder={6}&pageIndex={7}&pageSize={8}&typeUids={9}',
                createProduct: '/nomenclature/v1/products',
                updateProduct: '/nomenclature/v1/products/{0}',
                deleteProduct: '/nomenclature/v1/products/{0}',
                getProductTypes: '/nomenclature/v1/dictionaries/product-types',
                getProductMediaList: '/nomenclature/v1/products/{0}/medias?sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                deleteProductMedia: '/nomenclature/v1/products/{0}/medias?mediaUids={1}',
                createProductMedia: '/nomenclature/v1/products/{0}/medias',
                getWarehousesProductList: '/warehouses/v1/products',
                getDeliveryProductsList: '/corporation-aggregation/v1/public-catalog/delivery-products?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}'
            },
        };
    }

    public async getProduct(uid: string): Promise<Product> {
        return this.http.get<{ data: Product }>(String.format(this.settings.service.getProduct, uid)).then(res => res.data);
    }

    public async getProductList(filter: ProductsListGetFilter): Promise<PaginationResult<ProductListItemAggregation>> {
        const typeUids = filter.typeUids?.join(',');
        const groupUids = filter.groupUids?.join(',');
        const categoryUids = filter.categoryUids?.join(',');
        const organizationUids = (filter.organizationUids || []).join(',');
        const organizationPosUids = (filter.organizationPosUids || []).join(',');
        const franchiseesUids = (filter.franchiseeUids || []).join(',');
        return this.http
            .get<PaginationResult<ProductListItemAggregation>>(
                String.format(
                    this.settings.service.getProductList,
                    filter.term,
                    categoryUids,
                    filter.warehouseUid,
                    typeUids,
                    filter.isArchived,
                    filter.sortField,
                    filter.sortOrder,
                    filter.pageIndex,
                    filter.pageSize,
                    organizationUids,
                    organizationPosUids,
                    franchiseesUids,
                    groupUids
                )
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getWarehousesProductList(request: WarehousesProductsListGetFilter): Promise<WarehousesProductListItem[]> {
        return this.http.post<{ list: WarehousesProductListItem[] }>(String.format(this.settings.service.getWarehousesProductList), request).then(res => {
            return res.list;
        });
    }

    public async getNomenclatureProductList(filter: ProductsListFilter): Promise<PaginationResult<Product>> {
        const brands = (filter.brandUids || []).join(',');
        const typeUids = filter.typeUids?.join(',');
        const countryCodes = (filter.countryCodes || []).join(',');
        const categoryUids = filter.categoryUids?.join(',');

        return this.http
            .get<PaginationResult<Product>>(
                String.format(
                    this.settings.service.getNomenclatureProductList,
                    filter.term,
                    categoryUids,
                    brands,
                    countryCodes,
                    filter.isArchived,
                    filter.sortField,
                    filter.sortOrder,
                    filter.pageIndex,
                    filter.pageSize,
                    typeUids
                )
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createProduct(request: ProductCreateOrUpdateRequest): Promise<Product> {
        return this.http.post<{ data: Product }>(String.format(this.settings.service.createProduct), request).then(res => res.data);
    }

    public async updateProduct(uid: string, request: ProductCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateProduct, uid), request);
    }

    public async deleteProduct(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteProduct, uid));
    }

    public async getProductTypes(): Promise<ProductType[]> {
        return this.http.get<{ list: ProductType[] }>(String.format(this.settings.service.getProductTypes)).then(res => res.list);
    }

    public async getProductMediaList(uid: string, filter: MediasFilter): Promise<PaginationResult<Media>> {
        return this.http
            .get<PaginationResult<Media>>(
                String.format(this.settings.service.getProductMediaList, uid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize)
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async editProductMedia(
        posUid: string,
        addedPhotos: MediaCreateRequest[],
        deletedPhotos: string[]
    ): Promise<{ addedPhotos: MediaLight[]; deletedPhotos: string[] }> {
        const addPhotosHandler = addedPhotos && addedPhotos[0] ? this.createProductMedia(posUid, addedPhotos) : Promise.resolve(<MediaLight[]>[]);
        const deletePhotosHandler = deletedPhotos && deletedPhotos[0] ? this.deleteHeaderMedias(posUid, deletedPhotos) : Promise.resolve(true);

        return Promise.all([addPhotosHandler, deletePhotosHandler]).then(res => ({ addedPhotos: res[0], deletedPhotos }));
    }

    public async getDeliveryProductsList(filter: DeliveryProductsListGetFilter): Promise<ProductListItemAggregation[]> {
        return this.http.get<{ list: ProductListItemAggregation[] }>(String.format(this.settings.service.getDeliveryProductsList, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then(res => res.list);
    }

    private async deleteHeaderMedias(uid: string, photosIds: string[]): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteProductMedia, uid, (photosIds || []).join(',')));
    }

    private async createProductMedia(uid: string, photos: MediaCreateRequest[]): Promise<MediaLight[]> {
        const promises = photos.map(r =>
            this.http.post<{ data: MediaLight }>(String.format(this.settings.service.createProductMedia, uid), r).then(res => res.data)
        );
        return Promise.all(promises);
    }
}
