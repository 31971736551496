import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ListFromInventoryType } from '../../models/business/inventories/list-from-inventory-type.enum';
import { ListFromInventory } from '../../models/business/inventories/list-from-inventory.model';

export class ListFromInventoryGetAction {
    static readonly type = '[ListFromInventory Page] GetListFromInventory Get';

    constructor(public payload: string, public listFromInventoryType: ListFromInventoryType) { }
}

export class ListFromInventoryGetSuccessAction {
    static readonly type = '[ListFromInventory API] GetListFromInventory Success';

    constructor(public payload: ListFromInventory, public listFromInventoryType: ListFromInventoryType) { }
}

export class ListFromInventoryFailAction {
    static readonly type = '[ListFromInventory API] GetListFromInventory Fail';

    constructor(public payload: ErrorObject) { }
}

export class ListFromInventoryResetAction {
    static readonly type = '[ListFromInventory Page] GetListFromInventory Reset';
}
