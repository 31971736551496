import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NomenclatureService } from '../../services/business/nomenclature.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { UnitTypeListItem } from '../../models/business/nomenclature/unit-type-list-item.model';

import { UnitTypesAction, UnitTypesFailAction, UnitTypesResetAction, UnitTypesSuccessAction } from './unit-types.actions';

export interface UnitTypesStateModel {
    pending: boolean;
    entity: UnitTypeListItem[];
    error: ErrorObject;
}

@State<UnitTypesStateModel>({
    name: 'UnitTypes',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class UnitTypesState {
    constructor(private nomenclatureService: NomenclatureService) { }

    @Action(UnitTypesAction) async order(ctx: StateContext<UnitTypesStateModel>, _: UnitTypesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.nomenclatureService.getUnitTypes()
            .then(res => setTimeout(() => ctx.dispatch(new UnitTypesSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UnitTypesFailAction(err)), 0));
    }

    @Action(UnitTypesSuccessAction) orderSuccess(ctx: StateContext<UnitTypesStateModel>, action: UnitTypesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(UnitTypesFailAction) orderFail(ctx: StateContext<UnitTypesStateModel>, action: UnitTypesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(UnitTypesResetAction) orderReset(ctx: StateContext<UnitTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
