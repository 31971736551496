import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WriteOffCreateOrUpdateRequest } from '../../models/business/write-offs/write-off-create-or-update-request.model';
import { WriteOffListGetFilter } from '../../models/filters/write-off-list-get-filter.model';
import { WriteOffListItem } from '../../models/business/write-offs/write-off-list-item.model';
import { WriteOff } from '../../models/business/write-offs/write-off.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';
import { WriteOffStatus } from '../../models/business/write-offs/write-off-status.enum';

@Injectable({
    providedIn: 'root'
})
export class WriteOffsService extends BaseSingletonService {

    private settings: {
        service: {
            getWriteOff: string;
            getWorkbook: string;
            getWriteOffList: string;
            createWriteOff: string;
            updateWriteOff: string;
            deleteWriteOff: string;
        }
    };

    constructor(private http: HttpService) {
        super('WriteOffsService');
        this.settings = {
            service: {
                getWriteOff: '/warehouses-aggregation/v1/writting-offs/{0}',
                getWorkbook: '/warehouses/v1/writting-offs/{0}/workbook',
                getWriteOffList: '/warehouses-aggregation/v1/writting-offs?organizationUid={0}&warehouseUid={1}&status={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createWriteOff: '/warehouses-aggregation/v1/writting-offs',
                updateWriteOff: '/warehouses/v1/writting-offs/{0}',
                deleteWriteOff: '/warehouses/v1/writting-offs/{0}'
            }
        };
    }

    public async getWriteOff(uid: string): Promise<WriteOff> {
        return this.http.get<{ data: WriteOff }>(String.format(this.settings.service.getWriteOff, uid))
            .then(res => res.data);
    }

    public async getWorkbook(uid: string, fileName: string): Promise<Blob> {
        return this.http.download(String.format(this.settings.service.getWorkbook, uid), fileName);
    }

    public async getWriteOffList(filter: WriteOffListGetFilter): Promise<PaginationResult<WriteOffListItem>> {
        return this.http.get<PaginationResult<WriteOffListItem>>(String.format(this.settings.service.getWriteOffList, filter.organizationUid, filter.warehouseUid, filter.status, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createWriteOff(request: WriteOffCreateOrUpdateRequest): Promise<WriteOff> {
        return this.http.post<{ data: WriteOff }>(String.format(this.settings.service.createWriteOff), request)
            .then(res => res.data);
    }

    public async updateWriteOff(uid: string, request: WriteOffCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateWriteOff, uid), request);
    }

    public async deleteWriteOff(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteWriteOff, uid));
    }
}
