import { LinkedProduct } from '../../../../evasys/shared/models/business/catalog/linked-product.model';
import { LinkedUnitType } from '../../../../evasys/shared/models/business/nomenclature/linked-unit-type.model';
import { LinkedWarehouse } from '../../../../evasys/shared/models/business/warehouses/linked-warehouse.model';

export class DCRListItem {
    public uid: string;
    public number: string;
    public date: number;
    public sum: number;
    public differenceAmount?: number;
    public unitType: LinkedUnitType;
    public warehouseFrom: LinkedWarehouse;
    public warehouseTo: LinkedWarehouse;
    public products: LinkedProduct[];
    public notes: string;
    public createDate: number;
    public updateDate: number;
}
