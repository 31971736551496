import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderDiscountGetFilterData } from '../../models/filters/order-discount-get-filter.model';
import { OrderDiscountListItem } from '../../models/business/orders/order-discount-list-item.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrderDiscountsAction {
    static readonly type = '[OrderDiscounts Page] GetDiscounts';
}

export class OrderDiscountsSuccessAction {
    static readonly type = '[OrderDiscounts API] GetDiscounts Success';

    constructor(public payload: PaginationResult<OrderDiscountListItem>) { }
}

export class OrderDiscountsFailAction {
    static readonly type = '[OrderDiscounts API] GetDiscounts Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderDiscountsResetAction {
    static readonly type = '[OrderDiscounts Page] GetDiscounts Reset';
}

export class OrderDiscountSetFilterAction {
    static readonly type = '[OrderDiscounts API] SetFilter';

    constructor(public payload: OrderDiscountGetFilterData) { }
}

