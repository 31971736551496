
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilterData } from '../../models/filters/organizations-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrganizationsAction {
    static readonly type = '[Organizations Page] GetOrganizations';

    constructor(public payload: PaginationRequest) { }
}

export class OrganizationsSuccessAction {
    static readonly type = '[Organizations API] GetOrganizations Success';

    constructor(public payload: { list: PaginationResult<OrganizationListItem>, pagination: PaginationRequest }) { }
}

export class OrganizationsFailAction {
    static readonly type = '[Organizations API] GetOrganizations Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationsResetAction {
    static readonly type = '[Organizations Page] GetOrganizations Reset';
}

export class OrganizationsSetFilterAction {
    static readonly type = '[Organizations API] SetFilter';

    constructor(public payload: OrganizationsListGetFilterData) { }
}
