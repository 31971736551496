import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Organization } from '../../models/business/organizations/organization.model';

export class OrganizationGetAction {
    static readonly type = '[Organization Page] GetOrganization Get';

    constructor(public payload: string) { }
}

export class OrganizationGetSuccessAction {
    static readonly type = '[Organization API] GetOrganization Success';

    constructor(public payload: Organization) { }
}

export class OrganizationFailAction {
    static readonly type = '[Organization API] GetOrganization Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationResetAction {
    static readonly type = '[Organization Page] GetOrganization Reset';
}
