import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { MotivationBonusCreateAction, MotivationBonusCreateFailAction, MotivationBonusCreateResetAction, MotivationBonusCreateSuccessAction } from './motivation-bonus-create.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonus } from '../../models/business/contacts/motivation-bonus.model';

export interface MotivationBonusCreateStateModel {
    pending: boolean;
    created: MotivationBonus;
    error: ErrorObject;
}

@State<MotivationBonusCreateStateModel>({
    name: 'motivationBonusCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class MotivationBonusCreateState {
    constructor(private contactsService: ContactsService) { }

    @Action(MotivationBonusCreateAction) async motivationBonusCreate(ctx: StateContext<MotivationBonusCreateStateModel>, action: MotivationBonusCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.contactsService.createMotivation(action.payload.contactUid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new MotivationBonusCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusCreateFailAction(err)), 0));
    }

    @Action(MotivationBonusCreateSuccessAction) motivationBonusCreateSuccess(ctx: StateContext<MotivationBonusCreateStateModel>, action: MotivationBonusCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(MotivationBonusCreateFailAction) motivationBonusCreateFail(ctx: StateContext<MotivationBonusCreateStateModel>, action: MotivationBonusCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(MotivationBonusCreateResetAction) motivationBonusCreateReset(ctx: StateContext<MotivationBonusCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
