import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductType } from '../../models/business/orders/order-product-type.model';

export class ProductTypeAction {
    static readonly type = '[ProductType Page] ProductType';
}

export class ProductTypeSuccessAction {
    static readonly type = '[ProductType API] ProductType Success';

    constructor(public payload: ProductType[]) { }
}

export class ProductTypeFailAction {
    static readonly type = '[ProductType API] ProductType Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductTypeResetAction {
    static readonly type = '[ProductType Page] ProductType Reset';
}
