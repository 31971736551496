import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { ModifiersService } from '../../services/business/modifiers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { Modifier } from '../../models/business/modifiers/modifier.model';
import { ModifiersListGetFilter, ModifiersListGetFilterData } from '../../models/filters/modifiers-list-get-filter.model';

import { ModifiersAction, ModifiersFailAction, ModifiersResetAction, ModifiersSetFilterAction, ModifiersSuccessAction } from './modifiers.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface ModifiersStateModel extends PaginationFilteredStateModel<Modifier, ModifiersListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: Modifier };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ModifiersListGetFilterData;
    total: number;
}

@State<ModifiersStateModel>({
    name: 'modifiers',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { pageSize: null } }
})
@Injectable()
export class ModifiersState extends PaginationFilteredStateBase {
    constructor(private modifiersService: ModifiersService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([ModifiersAction]) modifiersGet(ctx: StateContext<ModifiersStateModel>, action: ModifiersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'modifiers');
        ctx.setState({ ...state, filter: filterData });

        const filter = new ModifiersListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.modifiersService.getModifierList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ModifiersSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ModifiersFailAction(err)), 0));
    }

    @Action(ModifiersSuccessAction) modifiersGetSuccess(ctx: StateContext<ModifiersStateModel>, action: ModifiersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ModifiersFailAction) modifiersGetFail(ctx: StateContext<ModifiersStateModel>, action: ModifiersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ModifiersResetAction) modifiersGetReset(ctx: StateContext<ModifiersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ModifiersSetFilterAction) modifiersSetFilter(ctx: StateContext<ModifiersStateModel>, action: ModifiersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'modifiers');
            ctx.setState({ ...state, filter, index: 0 });
            setTimeout(() => ctx.dispatch(new ModifiersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
