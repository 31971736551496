import { LinkedWarehouseProduct } from '../business/warehouses/linked-warehouse-product';

export class WarehousesProductsListGetFilter {
    public products: LinkedWarehouseProduct[];
    public organizationUid: string;
    public organizationPosUid: string;
    public warehouseUid: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: WarehousesProductsListGetFilterData }) {
        this.warehouseUid = (args && args.filterData && args.filterData.warehouseUid != null) ? args.filterData.warehouseUid : null;
        this.organizationUid = (args && args.filterData && args.filterData.organizationUid != null) ? args.filterData.organizationUid : null;
        this.organizationPosUid = (args && args.filterData && args.filterData.organizationPosUid != null) ? args.filterData.organizationPosUid : null;
        this.products = (args && args.filterData && args.filterData.products != null && args.filterData.products.length) ? args.filterData.products : [];
    }
}

export class WarehousesProductsListGetFilterData {
    public products?: LinkedWarehouseProduct[];
    public organizationUid?: string;
    public organizationPosUid?: string;
    public warehouseUid?: string;
}
