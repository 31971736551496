import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WriteOffsService } from '../../services/business/write-offs.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { WriteOffWorkbookFailAction, WriteOffWorkbookGetAction, WriteOffWorkbookGetSuccessAction, WriteOffWorkbookResetAction } from './write-off-workbook.actions';

export interface WriteOffWorkbookStateModel {
    created: Blob;
    pending: boolean;
    error: ErrorObject;
}

@State<WriteOffWorkbookStateModel>({
    name: 'writeoffWorkbook',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class WriteOffWorkbookState {
    constructor(private writeoffsService: WriteOffsService) { }

    @Action(WriteOffWorkbookGetAction) writeoffGet(ctx: StateContext<WriteOffWorkbookStateModel>, action: WriteOffWorkbookGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.writeoffsService.getWorkbook(action.payload.uid, action.payload.fileName)
            .then(resp => setTimeout(() => ctx.dispatch(new WriteOffWorkbookGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WriteOffWorkbookFailAction(err)), 0));
    }

    @Action(WriteOffWorkbookGetSuccessAction) writeoffGetSuccess(ctx: StateContext<WriteOffWorkbookStateModel>, action: WriteOffWorkbookGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(WriteOffWorkbookFailAction) writeoffGetFail(ctx: StateContext<WriteOffWorkbookStateModel>, action: WriteOffWorkbookFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WriteOffWorkbookResetAction) writeoffGetReset(ctx: StateContext<WriteOffWorkbookStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
