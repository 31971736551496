import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';

export class OrganizationPosesSearchAction {
    static readonly type = '[OrganizationPosesSearch Page] GetOrganizationPosesSearch';

    constructor(public payload: PaginationRequest) { }
}

export class OrganizationPosesSearchSuccessAction {
    static readonly type = '[OrganizationPosesSearch API] GetOrganizationPosesSearch Success';

    constructor(public payload: { list: PaginationResult<OrganizationPosListItem>, pagination: PaginationRequest }) { }
}

export class OrganizationPosesSearchFailAction {
    static readonly type = '[OrganizationPosesSearch API] GetOrganizationPosesSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosesSearchResetAction {
    static readonly type = '[OrganizationPosesSearch Page] GetOrganizationPosesSearch Reset';
}

export class OrganizationPosesSearchSetFilterAction {
    static readonly type = '[OrganizationPosesSearch API] SetFilter';

    constructor(public payload: OrganizationPosesListGetFilterData) { }
}
