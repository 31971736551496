import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PosListFilterData } from '../../../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../../../models/business/pos/pos-list-item.model';
import { PosSortingFieldsEnum } from '../../../../models/business/pos/pos-sorting-fields.enum';

@Component({
    selector: 'pos-list-item',
    templateUrl: './pos-list-item.component.html',
    styleUrls: ['./pos-list-item.component.scss', '../pos-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PosListItemComponent {

    @Input() pos: PosListItem;
    @Input() filter: PosListFilterData;
    @Input() listConfig: any;

    @Output() readonly openPos = new EventEmitter<string>();

    public posSortingFields = PosSortingFieldsEnum;

    public onOpenPosClick() {
        this.openPos.emit(this.pos.uid);
    }
}
