import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CharacteristicsService } from '../../services/business/characteristics.service';

import { CharacteristicsGroupsAction, CharacteristicsGroupsFailAction, CharacteristicsGroupsResetAction, CharacteristicsGroupsSuccessAction } from './characteristics-groups.actions';

import { CharacteristicGroup } from '../../models/business/characteristics/characteristic-group.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface CharacteristicsGroupsStateModel {
    uids: string[];
    entities: { [uid: string]: CharacteristicGroup };
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<CharacteristicsGroupsStateModel>({
    name: 'characteristicsGroups',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null }
})
@Injectable()
export class CharacteristicsGroupsState {
    constructor(private characteristicsService: CharacteristicsService) {
    }

    @Action([CharacteristicsGroupsAction]) characteristicsGet(ctx: StateContext<CharacteristicsGroupsStateModel>, action: CharacteristicsGroupsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.characteristicsService.getCharacteristicsGroups(action.payload.isArchived)
            .then(resp => setTimeout(() => ctx.dispatch(new CharacteristicsGroupsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CharacteristicsGroupsFailAction(err)), 0));
    }

    @Action(CharacteristicsGroupsSuccessAction) characteristicsGetSuccess(ctx: StateContext<CharacteristicsGroupsStateModel>, action: CharacteristicsGroupsSuccessAction) {
        const state = ctx.getState();
        const uids = action.payload.map(x => x.uid);
        const entities = action.payload.reduce((items: { [uid: string]: CharacteristicGroup }, item: CharacteristicGroup) => ({ ...items, [item.uid]: item }), {});
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null });
    }

    @Action(CharacteristicsGroupsFailAction) characteristicsGetFail(ctx: StateContext<CharacteristicsGroupsStateModel>, action: CharacteristicsGroupsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CharacteristicsGroupsResetAction) characteristicsGetReset(ctx: StateContext<CharacteristicsGroupsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null });
    }
}
