import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NomenclatureService } from '../../services/business/nomenclature.service';

import { AttributeGroup } from '../../models/business/nomenclature/attribute-group.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { AttributeGroupsAction, AttributeGroupsFailAction, AttributeGroupsResetAction, AttributeGroupsSuccessAction } from './attribute-groups.actions';

export interface AttributeGroupsStateModel {
    pending: boolean;
    entity: AttributeGroup[];
    error: ErrorObject;
}

@State<AttributeGroupsStateModel>({
    name: 'AttributeGroups',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class AttributeGroupsState {
    constructor(private nomenclatureService: NomenclatureService) { }

    @Action(AttributeGroupsAction) async order(ctx: StateContext<AttributeGroupsStateModel>, _: AttributeGroupsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.nomenclatureService.getAttributeGroups()
            .then(res => setTimeout(() => ctx.dispatch(new AttributeGroupsSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AttributeGroupsFailAction(err)), 0));
    }

    @Action(AttributeGroupsSuccessAction) orderSuccess(ctx: StateContext<AttributeGroupsStateModel>, action: AttributeGroupsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(AttributeGroupsFailAction) orderFail(ctx: StateContext<AttributeGroupsStateModel>, action: AttributeGroupsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AttributeGroupsResetAction) orderReset(ctx: StateContext<AttributeGroupsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
