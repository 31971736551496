import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ReservationCreateRequest } from '../../models/business/reservations/reservation-create-request.model';
import { Reservation } from '../../models/business/reservations/reservation.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ReservationsService extends BaseSingletonService {

    private settings: {
        service: {
            getReservation: string;
            createReservation: string;
            deleteReservation: string;
        }
    };

    constructor(private http: HttpService) {
        super('ReservationsService');
        this.settings = {
            service: {
                getReservation: '/productivity/v1/reservations?contactUid={0}&organizationPosUid={1}',
                createReservation: '/productivity/v1/reservations',
                deleteReservation: '/productivity/v1/reservations?contactUid={0}&organizationPosUid={1}'
            }
        };
    }

    public async getReservation(contactUid: string, organizationPosUid: string): Promise<Reservation> {
        return this.http.get<{ data: Reservation }>(String.format(this.settings.service.getReservation, contactUid, organizationPosUid))
            .then(res => res.data);
    }

    public async createReservation(request: ReservationCreateRequest): Promise<Reservation> {
        return this.http.post<{ data: Reservation }>(String.format(this.settings.service.createReservation), request)
            .then(res => res.data);
    }

    public async deleteReservation(contactUid: string, organizationPosUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteReservation, contactUid, organizationPosUid));
    }
}
