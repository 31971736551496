import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseReturnCreateOrUpdateRequest } from '../../models/business/purchase-returns/purchase-return-create-or-update-request.model';

export class PurchaseReturnUpdateAction {
    static readonly type = '[Booking Page] UpdatePurchaseReturn';

    constructor(public payload: { uid: string, request: PurchaseReturnCreateOrUpdateRequest }) { }
}

export class PurchaseReturnUpdateSuccessAction {
    static readonly type = '[PurchaseReturn API] UpdatePurchaseReturn Success';

    constructor(public payload: { uid: string, request: PurchaseReturnCreateOrUpdateRequest }) { }
}

export class PurchaseReturnUpdateFailAction {
    static readonly type = '[PurchaseReturn API] UpdatePurchaseReturn Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseReturnUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePurchaseReturn Reset';
}
