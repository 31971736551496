import { OrganizationTreeTypeItemEnum } from './organization-tree-type-item.enum';
import { OrganizationTreeFilterItemData } from './organization-tree-filter-item-data.model';
import { TreeNode } from '../../../../../burns-ui-framework/shared/components/common/exg-tree/tree-node.model';


export class DataMapper {
    public static Map(data: any): TreeNode[] {
        return [{
            uid: data.uid,
            name: data.fullName || data.fullName,
            data: OrganizationTreeTypeItemEnum.Root,
            childrens: [...data.organizations.map(DataMapper.getOrganizations), ...data.franchisees.map(DataMapper.getFranchizes)]
        }];
    }

    private static getFranchizes(frn) {
        return {
            uid: frn.uid,
            name: frn.name,
            data: OrganizationTreeTypeItemEnum.Franchisees,
            childrens: frn.organizations?.map(DataMapper.getOrganizations)
        };
    }

    private static getOrganizations(org) {
        return ({
            uid: org.uid,
            name: org.fullName || org.shortName,
            data: OrganizationTreeTypeItemEnum.Organization,
            childrens: org.poses.map(DataMapper.getPoses)
        });
    }

    private static getPoses(pos) {
        return {
            uid: pos.uid,
            name: pos.name,
            data: OrganizationTreeTypeItemEnum.Pos,
        };
    }

    public static getAllChildrenNodes(data: any): OrganizationTreeFilterItemData[] {
        const mappedData = DataMapper.Map(data);
        return DataMapper.extractChildrenNodes(mappedData);
    }

    private static extractChildrenNodes(nodes: TreeNode[]): OrganizationTreeFilterItemData[] {
        let children = [];
        nodes.forEach(node => {
            children.push({ uid: node.uid, name: node.name });
            if (node.childrens) {
                children = children.concat(DataMapper.extractChildrenNodes(node.childrens));
            }
        });
        return children;
    }
}
