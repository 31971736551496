import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetAvailableListFilterData } from '../../models/filters/interval-get-available-list-filter.model';
import { IntervalShort } from '../../models/business/intervals/interval-short.model';

export class IntervalsAvailableListGetAction {
    static readonly type = '[IntervalsAvailableList Page] GetInterval Get';

    constructor(public payload: IntervalGetAvailableListFilterData) { }
}

export class IntervalsAvailableListGetSuccessAction {
    static readonly type = '[IntervalsAvailableList API] GetInterval Success';

    constructor(public payload: IntervalShort[]) { }
}

export class IntervalsAvailableListFailAction {
    static readonly type = '[IntervalsAvailableList API] GetInterval Fail';

    constructor(public payload: ErrorObject) { }
}

export class IntervalsAvailableListResetAction {
    static readonly type = '[IntervalsAvailableList Page] GetInterval Reset';
}
