import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CookingsService } from '../../services/cookings.service';

import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CookingCreateAction, CookingCreateFailAction, CookingCreateResetAction, CookingCreateSuccessAction } from './cooking-create.actions';

export interface CookingCreateStateModel {
    pending: boolean;
    created: DCRItem;
    error: ErrorObject;
}

@State<CookingCreateStateModel>({
    name: 'cookingCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class CookingCreateState {
    constructor(private cookingsService: CookingsService) { }

    @Action(CookingCreateAction) async cookingCreate(ctx: StateContext<CookingCreateStateModel>, action: CookingCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.cookingsService.createCooking({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new CookingCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CookingCreateFailAction(err)), 0));
    }

    @Action(CookingCreateSuccessAction) cookingCreateSuccess(ctx: StateContext<CookingCreateStateModel>, action: CookingCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(CookingCreateFailAction) cookingCreateFail(ctx: StateContext<CookingCreateStateModel>, action: CookingCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(CookingCreateResetAction) cookingCreateReset(ctx: StateContext<CookingCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
