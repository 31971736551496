import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RequisitesCreateOrUpdateRequest } from '../../models/business/contragents/requisites-create-or-update.model';
import { Requisite } from '../../models/business/contragents/requisites.model';

export class RequisiteCreateAction {
    static readonly type = '[Requisites Page] CreateRequisite';

    constructor(public payload: { contragentUid: string, request: RequisitesCreateOrUpdateRequest }) { }
}

export class RequisiteCreateSuccessAction {
    static readonly type = '[Requisite API] CreateRequisite Success';

    constructor(public payload: Requisite) { }
}

export class RequisiteCreateFailAction {
    static readonly type = '[Requisite API] CreateRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class RequisiteCreateResetAction {
    static readonly type = '[Requisites Page] CreateRequisite Reset';
}
