<section>
    <div class="search_area">
        <exg-icon class="search_icon" iconName="search"></exg-icon>
        <exg-textbox
            class="search_input"
            type="text"
            [value]="term"
            styleType="empty"
            [placeholder]="'Search by company name, address' | translate"
            (valueChange)="onSearchChanged($event)"></exg-textbox>
    </div>
    
    <exg-tree [data]="nodes" [value]="selectedUids" (valueChange)="onValueChange($event)"></exg-tree>    
</section>
