import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContragentUpdateSuccessAction } from './contragent-update.actions';
import { ContragentFailAction, ContragentGetAction, ContragentGetSuccessAction, ContragentResetAction } from './contragent.actions';
import { Contragent } from '../../models/business/contragents/contragent.model';

export interface ContragentStateModel {
    entity: Contragent;
    pending: boolean;
    error: ErrorObject;
}

@State<ContragentStateModel>({
    name: 'contragent',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ContragentState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(ContragentGetAction) contragentGet(ctx: StateContext<ContragentStateModel>, action: ContragentGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contragentsService.getContragent(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new ContragentGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContragentFailAction(err)), 0));
    }

    @Action(ContragentGetSuccessAction) contragentGetSuccess(ctx: StateContext<ContragentStateModel>, action: ContragentGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ContragentFailAction) contragentGetFail(ctx: StateContext<ContragentStateModel>, action: ContragentFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContragentResetAction) contragentGetReset(ctx: StateContext<ContragentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(ContragentUpdateSuccessAction) contragentUpdateSuccess(ctx: StateContext<ContragentStateModel>, action: ContragentUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
