import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrderDeleteAction, OrderDeleteFailAction, OrderDeleteResetAction, OrderDeleteSuccessAction } from './order-delete.actions';

export interface OrderDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<OrderDeleteStateModel>({
    name: 'orderDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class OrderDeleteState {
    constructor(private ordersService: OrdersService) { }

    @Action(OrderDeleteAction) async orderDelete(ctx: StateContext<OrderDeleteStateModel>, action: OrderDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.ordersService.deleteOrder(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new OrderDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new OrderDeleteFailAction(error)), 0));
    }

    @Action(OrderDeleteSuccessAction) orderDeleteSuccess(ctx: StateContext<OrderDeleteStateModel>, _: OrderDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(OrderDeleteFailAction) orderDeleteFail(ctx: StateContext<OrderDeleteStateModel>, action: OrderDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrderDeleteResetAction) orderDeleteReset(ctx: StateContext<OrderDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
