import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapUpdateRequest } from '../../models/business/technological-maps/technological-map-update-request.model';

import { TechnologicalMapRevertUpdateAction, TechnologicalMapRevertUpdateFailAction, TechnologicalMapRevertUpdateResetAction, TechnologicalMapRevertUpdateSuccessAction } from './technological-map-revert-update.actions';

export interface TechnologicalMapRevertUpdateStateModel {
    pending: boolean;
    updated: TechnologicalMapUpdateRequest;
    error: ErrorObject;
}

@State<TechnologicalMapRevertUpdateStateModel>({
    name: 'technologicalMapRevertUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class TechnologicalMapRevertUpdateState {
    constructor(private technologicalMapsService: TechnologicalMapsService) { }

    @Action(TechnologicalMapRevertUpdateAction) async technologicalMapRevertUpdate(ctx: StateContext<TechnologicalMapRevertUpdateStateModel>, action: TechnologicalMapRevertUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.technologicalMapsService.updateTechnologicalMap(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TechnologicalMapRevertUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapRevertUpdateFailAction(err)), 0));
    }

    @Action(TechnologicalMapRevertUpdateSuccessAction) technologicalMapRevertUpdateSuccess(ctx: StateContext<TechnologicalMapRevertUpdateStateModel>, action: TechnologicalMapRevertUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(TechnologicalMapRevertUpdateFailAction) technologicalMapRevertUpdateFail(ctx: StateContext<TechnologicalMapRevertUpdateStateModel>, action: TechnologicalMapRevertUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(TechnologicalMapRevertUpdateResetAction) technologicalMapRevertUpdateReset(ctx: StateContext<TechnologicalMapRevertUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
