import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { IntervalUpdateAction, IntervalUpdateResetAction } from './interval-update.actions';

import { IntervalCreateOrUpdateRequest } from '../../models/business/intervals/interval-create-or-update-request.model';

@Injectable({
    providedIn: 'root'
})
export class IntervalUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('IntervalUpdateDispatchers');
    }

    public dispatchIntervalUpdateAction(request: IntervalCreateOrUpdateRequest) {
        this.store.dispatch(new IntervalUpdateAction({ request }));
    }

    public dispatchIntervalUpdateResetAction() {
        this.store.dispatch(new IntervalUpdateResetAction());
    }
}
