import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationsService } from '../../services/business/organizations.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { OrganizationCreateSuccessAction } from './organization-create.actions';
import { OrganizationDeleteSuccessAction } from './organization-delete.actions';
import { OrganizationUpdateSuccessAction } from './organization-update.actions';
import { OrganizationsAction, OrganizationsFailAction, OrganizationsResetAction, OrganizationsSetFilterAction, OrganizationsSuccessAction } from './organizations.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilter, OrganizationsListGetFilterData } from '../../models/filters/organizations-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface OrganizationsStateModel extends PaginationStateModel<OrganizationListItem> {
    uids: string[];
    entities: { [uid: string]: OrganizationListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: OrganizationsListGetFilterData;
    total: number;
}

@State<OrganizationsStateModel>({
    name: 'organizations',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { }
    }
})
@Injectable()
export class OrganizationsState extends PaginationStateBase {
    constructor(private organizationsService: OrganizationsService) {
        super();
    }

    @Action([OrganizationsAction]) organizationsGet(ctx: StateContext<OrganizationsStateModel>, action: OrganizationsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrganizationsListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.organizationsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.organizationsService.getOrganizationList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationsFailAction(err)), 0));
    }

    @Action(OrganizationsSuccessAction) organizationsGetSuccess(ctx: StateContext<OrganizationsStateModel>, action: OrganizationsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.organizationsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(OrganizationsFailAction) organizationsGetFail(ctx: StateContext<OrganizationsStateModel>, action: OrganizationsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationsResetAction) organizationsGetReset(ctx: StateContext<OrganizationsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(OrganizationsSetFilterAction) usersSetFilter(ctx: StateContext<OrganizationsStateModel>, action: OrganizationsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new OrganizationsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(OrganizationCreateSuccessAction) organizationCreateSuccess(ctx: StateContext<OrganizationsStateModel>, action: OrganizationCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(OrganizationUpdateSuccessAction) organizationUpdateSuccess(ctx: StateContext<OrganizationsStateModel>, action: OrganizationUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(OrganizationDeleteSuccessAction) organizationDeleteSuccess(ctx: StateContext<OrganizationsStateModel>, action: OrganizationDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
