
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrganizationPosesAction {
    static readonly type = '[OrganizationPoses Page] GetOrganizationPoses';

    constructor(public payload: PaginationRequest) { }
}

export class OrganizationPosesSuccessAction {
    static readonly type = '[OrganizationPoses API] GetOrganizationPoses Success';

    constructor(public payload: { list: PaginationResult<OrganizationPosListItem>, pagination: PaginationRequest }) { }
}

export class OrganizationPosesFailAction {
    static readonly type = '[OrganizationPoses API] GetOrganizationPoses Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosesResetAction {
    static readonly type = '[OrganizationPoses Page] GetOrganizationPoses Reset';
}

export class OrganizationPosesSetFilterAction {
    static readonly type = '[OrganizationPoses API] SetFilter';

    constructor(public payload: OrganizationPosesListGetFilterData) { }
}
