import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { TechnologicalMapByProductAction, TechnologicalMapByProductFailAction, TechnologicalMapByProductResetAction, TechnologicalMapByProductSetFilterAction, TechnologicalMapByProductSuccessAction } from './technological-map-by-product.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapListGetFilter, TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { TechnologicalMapProductHierarchy } from '../../models/business/technological-maps/technological-map-product-hierarchy.model';

export interface TechnologicalMapByProductStateModel extends PaginationFilteredStateModel<TechnologicalMapProductHierarchy, TechnologicalMapListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: TechnologicalMapProductHierarchy };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TechnologicalMapListGetFilterData;
    total: number;
}

@State<TechnologicalMapByProductStateModel>({
    name: 'technologicalMapByProduct',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class TechnologicalMapByProductState extends PaginationFilteredStateBase {
    constructor(private technologicalMapsService: TechnologicalMapsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([TechnologicalMapByProductAction]) productsGet(ctx: StateContext<TechnologicalMapByProductStateModel>, action: TechnologicalMapByProductAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'technological-map-archive');
        ctx.setState({ ...state, filter: filterData });

        const filter = new TechnologicalMapListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.technologicalMapsService.getTechnologicalMapListByProduct(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TechnologicalMapByProductSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapByProductFailAction(err)), 0));
    }

    @Action(TechnologicalMapByProductSuccessAction) technologicalMapArchiveGetSuccess(ctx: StateContext<TechnologicalMapByProductStateModel>, action: TechnologicalMapByProductSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(TechnologicalMapByProductFailAction) technologicalMapArchiveGetFail(ctx: StateContext<TechnologicalMapByProductStateModel>, action: TechnologicalMapByProductFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TechnologicalMapByProductResetAction) technologicalMapArchiveGetReset(ctx: StateContext<TechnologicalMapByProductStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(TechnologicalMapByProductSetFilterAction) usersSetFilter(ctx: StateContext<TechnologicalMapByProductStateModel>, action: TechnologicalMapByProductSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'technological-map-archive');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new TechnologicalMapByProductAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
