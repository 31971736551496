import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RecyclingsService } from '../../services/recyclings.service';

import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { RecyclingFailAction, RecyclingGetAction, RecyclingGetSuccessAction, RecyclingResetAction } from './recycling.actions';

export interface RecyclingStateModel {
    entity: DCRItem;
    pending: boolean;
    error: ErrorObject;
}

@State<RecyclingStateModel>({
    name: 'recycling',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class RecyclingState {
    constructor(private recyclingsService: RecyclingsService) { }

    @Action(RecyclingGetAction) recyclingGet(ctx: StateContext<RecyclingStateModel>, action: RecyclingGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.recyclingsService.getRecycling(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new RecyclingGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RecyclingFailAction(err)), 0));
    }

    @Action(RecyclingGetSuccessAction) recyclingGetSuccess(ctx: StateContext<RecyclingStateModel>, action: RecyclingGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(RecyclingFailAction) recyclingGetFail(ctx: StateContext<RecyclingStateModel>, action: RecyclingFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RecyclingResetAction) recyclingGetReset(ctx: StateContext<RecyclingStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
