import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { YandexService } from '../services/yandex.service';

import { PlacesReverseAction, PlacesReverseFailAction, PlacesReverseResetAction, PlacesReverseSuccessAction } from './places-reverse.actions';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { YandexPlaceComponent } from '../models/yandex-place-component.model';

export interface PlacesReverseStateModel {
    pending: boolean;
    entities: YandexPlaceComponent[];
    retrieved: boolean;
    error: ErrorObject;
}

@State<PlacesReverseStateModel>({
    name: 'YandexPlacesReverse',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class PlacesReverseState {
    constructor(private yandexService: YandexService) { }

    @Action([PlacesReverseAction]) async placesReverseGet(ctx: StateContext<PlacesReverseStateModel>, action: PlacesReverseAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.yandexService.getPlacesReverse(action.payload.latitude, action.payload.longitude)
            .then(resp => setTimeout(() => ctx.dispatch(new PlacesReverseSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PlacesReverseFailAction(err)), 0));
    }

    @Action(PlacesReverseSuccessAction) placesReverseGetSuccess(ctx: StateContext<PlacesReverseStateModel>, action: PlacesReverseSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(PlacesReverseFailAction) placesReverseGetFail(ctx: StateContext<PlacesReverseStateModel>, action: PlacesReverseFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PlacesReverseResetAction) placesReverseGetReset(ctx: StateContext<PlacesReverseStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
