import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';

import { BvcOrganizationFilterComponent } from '../bvc-organization-filter/bvc-organization-filter.component';

import { OrganizationTreeDispatchers } from '../../../store/organization-tree/organization-tree.dispatchers';
import { OrganizationTreeSelectors } from '../../../store/organization-tree/organization-tree.selectors';

import { DataMapper } from '../organizations-tree/data.mapper';

import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ExgDialogMode } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-mode.model';
import { ExgDialogResultEvent } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { OrganizationTreeFilterItemData } from '../organizations-tree/organization-tree-filter-item-data.model';
import { OrganizationTreeListGetFilterData } from '../../../models/filters/organization-tree-list-get-filter.model';
import { OrganizationTree } from '../../../models/business/organization-tree/organization-tree.model';
import { OrderListGetFilterData } from '../../../models/filters/order-list-get-filter.model';

@Component({
    selector: 'bvc-organization-filter-button',
    templateUrl: './bvc-organization-filter-button.component.html',
    styleUrls: ['./bvc-organization-filter-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcOrganizationFilterButtonComponent implements OnInit {
    @Input() buttonType: 'filter' | 'custom' = 'filter';
    @Input() initSelectAll: boolean = true;
    @Input() filter: OrderListGetFilterData;
    @Output() readonly filterChanged = new EventEmitter<any[]>();
    @Output() readonly selectedItems = new EventEmitter<{}>();

    public organizationTree: OrganizationTree;

    public dialogMode = ExgDialogMode;
    public componentData: { component: any; inputs: any };
    public showDialog: boolean;
    public dialogPosition = { right: '0' };

    public selectedOrganizationNames = '';
    public organizationCount$ = new BehaviorSubject<number>(0);
    public firstOrganizationName$ = new BehaviorSubject<string>('');
    private parentFilterTreeItems: OrganizationTreeFilterItemData[] = [];

    private isTreeLoadedOnce = false;
    private unsubscribe$ = new Subject();
    private selectedFilterTreeItems = new BehaviorSubject<OrganizationTreeFilterItemData[]>([]);

    constructor(private organizationTreeDispatchers: OrganizationTreeDispatchers, private organizationTreeSelectors: OrganizationTreeSelectors) {}

    ngOnInit(): void {
        this.dispatchOrganizationTreeSearch({});

        this.organizationTreeSelectors.organizationTree$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(organizationTree => {
                this.organizationTree = organizationTree;
                if (!this.isTreeLoadedOnce) {
                  const filterUids = [...(this.filter?.franchiseeUids || []), ...(this.filter?.organizationPosUids || []), ...(this.filter?.organizationUids || [])];
                  const childrenNodes = DataMapper.getAllChildrenNodes(organizationTree);
                  if(filterUids.length) {
                    const selectedFilterUids = childrenNodes.filter((cn)=> filterUids.includes(cn.uid));
                    this.selectedFilterTreeItems.next(selectedFilterUids);
                  } else if(this.initSelectAll) {
                    this.selectedFilterTreeItems.next(DataMapper.getAllChildrenNodes(organizationTree))
                  }
                    this.parentFilterTreeItems = DataMapper.getAllChildrenNodes(organizationTree);
                }
                this.isTreeLoadedOnce = true;
            });

        this.selectedFilterTreeItems
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => !!x)
            )
            .subscribe(selectedFilterTreeItems => {
                this.firstOrganizationName$.next(selectedFilterTreeItems?.length ? selectedFilterTreeItems[0].name : '');
                this.selectedOrganizationNames = selectedFilterTreeItems?.length
                    ? selectedFilterTreeItems.map(v => v.name).join(',\n')
                    : this.organizationTree?.fullName;
                if (selectedFilterTreeItems?.length) {
                    this.organizationCount$.next(selectedFilterTreeItems?.length ? selectedFilterTreeItems?.length - 1 : 0) ;
                }
            });
    }

    public dispatchOrganizationTreeSearch(filterData: OrganizationTreeListGetFilterData): void {
        this.organizationTreeDispatchers.dispatchOrganizationTreeAction(filterData);
    }

    public onDialogClose(event: ExgDialogResultEvent): void {
        this.showDialog = false;
        if (event.dialogResult === DialogResult.Ok) {
            if (event.dataFromComponent?.selectedUids) {
                const selectedUids = [...event.dataFromComponent.selectedUids].reverse();

                if (this.initSelectAll && (selectedUids.length === this.parentFilterTreeItems.length - 1 || !selectedUids.length)) {
                    this.selectedFilterTreeItems.next([...this.parentFilterTreeItems]);
                } else {
                    this.selectedFilterTreeItems.next(selectedUids);
                }
                const filterData = event.dataFromComponent?.filter ?? {};

                const selectedByGroups = {
                    organizationUids: filterData?.organizationUids.map(v => this.selectedFilterTreeItems.value.find(x => x.uid === v)) ?? [],
                    organizationPosUids: filterData?.organizationPosUids.map(v => this.selectedFilterTreeItems.value.find(x => x.uid === v)) ?? [],
                    franchiseeUids: filterData?.franchiseeUids.map(v => this.selectedFilterTreeItems.value.find(x => x.uid === v)) ?? [],
                };

                this.selectedItems.emit(selectedByGroups);
                this.filterChanged.emit(filterData);
            }
        }
    }

    public openOrganizationFilter(): void {
        this.componentData = {
            component: BvcOrganizationFilterComponent,
            inputs: { selectedUids: this.selectedFilterTreeItems.value, organizationTree: this.organizationTree },
        };
        this.showDialog = true;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }
}
