import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { ContractsListFilterData } from '../../models/filters/contracts-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ContractsAction, ContractsResetAction, ContractsSetFilterAction } from './contracts.actions';

@Injectable({
    providedIn: 'root'
})
export class ContractsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('ContractsDispatchers');
    }

    public dispatchContractsAction(request: PaginationRequest) {
        this.store.dispatch(new ContractsAction(request));
    }

    public dispatchContractsResetAction() {
        this.store.dispatch(new ContractsResetAction());
    }

    public dispatchContractsSetFilterAction(filterData: ContractsListFilterData) {
        this.store.dispatch(new ContractsSetFilterAction(filterData));
    }
}
