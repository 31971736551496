import { StatisticProductsData } from '../../../models/business/orders/statistic-products-data.model';

export class ChartsOptionsFactory {

    // tslint:disable-next-line:max-func-body-length
    public static getAreasplineOptions(data: any[]) {
        return {
            chart: {
                type: 'area'
            },
            title: {
                text: 'Динамика продаж',
                style: {
                    fontSize: '15px',
                    fontFamily: 'Roboto'
                },
                align: 'left'
            },
            xAxis: {
                reversed: false,
                gridLineDashStyle: 'Solid',
                gridLineWidth: 0,
                gridLineColor: '#4c5862',
                title: { text: '', style: { fontFamily: 'Roboto' } },
                labels: {
                    style: { color: '#4c5862' }
                },
                lineWidth: 0,
                showEmpty: false
            },

            yAxis: {
                title: { text: '', style: { fontFamily: 'Roboto' } },
                gridLineDashStyle: 'Solid',
                gridLineWidth: 0.15,
                gridLineColor: '#4c5862',
                labels: { style: { color: '#4c5862' } },
                lineWidth: 0,
                showEmpty: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.y}'
            },
            credits: { enabled: false },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                borderWidth: 0
            },
            plotOptions: {
                areaspline: {
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 2
                        }
                    },
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2.5,
                        fillColor: '#fff',
                        lineWidth: 1,
                        states: {
                            hover: { enabled: true }
                        }
                    }
                }
            },
            series: data.map(ser => ({
                data: ser.points.map(x => [x.key, x.value]),
                type: 'areaspline',
                color: ser.color,
                name: ser.name
            }))
        };
    }

    public static getPipeChartOptions(title: string, mobileScreen: boolean, mobilePlatform: boolean, data: any[]) {
        const desctopOptions = [{
            type: 'pie',
            data: data.map(x => ({ name: `${x.key}`, y: x.value, color: x.color }))
        }];

        const mobilePhoneOptions = [{
            type: 'pie',
            keys: ['name', 'y', 'color'],
            data: data.map(x => ([`${x.key}`, x.value, x.color]))
        }];

        return {
            credits: { enabled: false },
            tooltip: { enabled: false },
            chart: {
                // plotBackgroundColor: null,
                // plotBorderWidth: null,
                // plotShadow: false,
                type: 'pie'
            },
            title: {
                text: title,
                style: {
                    fontSize: '15px',
                    fontFamily: 'Roboto'
                },
                align: 'left'
            },
            legend: {
                layout: mobileScreen || mobilePlatform ? 'vertical' : 'horizontal',
                align: mobileScreen || mobilePlatform ? 'center' : 'right',
                verticalAlign: mobileScreen || mobilePlatform ? 'bottom' : 'middle',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: mobilePlatform ? mobilePhoneOptions : desctopOptions
        };
    }

    public static getSolidGaugeOptions(title: string, planAmount: number, curAmount: number) {
        return {
            chart: {
                type: 'solidgauge'
            },
            title: {
                text: title,
                style: {
                    fontSize: '15px',
                    fontFamily: 'Roboto'
                },
                align: 'left'
            },
            yAxis: {
                min: 0,
                max: planAmount,
                title: { text: '', style: { fontFamily: 'Roboto' } },
                lineWidth: 0,
                tickWidth: 0,
                tickPositions: [0, planAmount],
                minorTickInterval: null,
                tickAmount: 2,
                labels: {
                    y: 16,
                    align: 'center'
                }
            },

            credits: {
                enabled: false
            },

            series: [{
                type: 'solidgauge',
                data: [curAmount]
            }],

            exporting: {
                enabled: false
            },

            tooltip: {
                enabled: false
            },

            plotOptions: {
                solidgauge: {
                    innerRadius: '75%',
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            }
        };
    }

    public static getVariablepieOptions(title: string, data: StatisticProductsData[], mobilePlatform: boolean) {
        const brandUids = [...new Set(data.map(x => x.brandUid))];
        const groupedProducts = brandUids.map(x => ({
            name: data.find(p => p.brandUid === x)?.brandName || 'Другое',
            drilldown: data.find(p => p.brandUid === x)?.brandName || 'Другое',
            y: data.filter(d => d.brandUid === x).map(d => d.amount).reduce((partialSum, a) => partialSum + a, 0),
            z: data.filter(d => d.brandUid === x).map(d => d.count).reduce((partialSum, a) => partialSum + a, 0)
        }));

        const desctopOptions = [{
            minPointSize: 10,
            innerSize: '80%',
            zMin: 0,
            name: 'Бренды',
            data: groupedProducts
        }];

        const drilldownOptions = {
            series: brandUids.map(brand => ({
                name: data.find(p => p.brandUid === brand)?.brandName || 'Другое',
                id: data.find(p => p.brandUid === brand)?.brandName || 'Другое',
                data: data.filter(d => d.brandUid === brand).map(dataVal => ([`${dataVal.name}`, dataVal.amount]))
            }))
        };

        return {
            chart: {
                type: 'pie'
            },
            title: {
                text: title,
                style: {
                    fontSize: '15px',
                    fontFamily: 'Roboto'
                },
                align: 'left'
            },
            lang: {
                drillUpText: '◁ Вернуться к Брендам'
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                borderWidth: 0
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                },
                point: {
                    valueSuffix: '%'
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    `Сумма продаж: {point.y}<br/>`
            },
            series: desctopOptions,
            drilldown: drilldownOptions
        };
    }
}
