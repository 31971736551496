import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PosDeleteAction, PosDeleteFailAction, PosDeleteResetAction, PosDeleteSuccessAction } from './pos-delete.actions';

export interface PosDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PosDeleteStateModel>({
    name: 'posDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PosDeleteState {
    constructor(private posService: PosService) { }

    @Action(PosDeleteAction) async posDelete(ctx: StateContext<PosDeleteStateModel>, action: PosDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.posService.deletePos(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PosDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PosDeleteFailAction(error)), 0));
    }

    @Action(PosDeleteSuccessAction) posDeleteSuccess(ctx: StateContext<PosDeleteStateModel>, _: PosDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PosDeleteFailAction) posDeleteFail(ctx: StateContext<PosDeleteStateModel>, action: PosDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosDeleteResetAction) posDeleteReset(ctx: StateContext<PosDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
