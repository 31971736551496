import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransfersService } from '../../services/business/transfers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransferWorkbookFailAction, TransferWorkbookGetAction, TransferWorkbookGetSuccessAction, TransferWorkbookResetAction } from './transfer-workbook.actions';

export interface TransferWorkbookStateModel {
    created: Blob;
    pending: boolean;
    error: ErrorObject;
}

@State<TransferWorkbookStateModel>({
    name: 'transferWorkbook',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TransferWorkbookState {
    constructor(private transfersService: TransfersService) { }

    @Action(TransferWorkbookGetAction) transferGet(ctx: StateContext<TransferWorkbookStateModel>, action: TransferWorkbookGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.transfersService.getWorkbook(action.payload.uid, action.payload.fileName)
            .then(resp => setTimeout(() => ctx.dispatch(new TransferWorkbookGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransferWorkbookFailAction(err)), 0));
    }

    @Action(TransferWorkbookGetSuccessAction) transferGetSuccess(ctx: StateContext<TransferWorkbookStateModel>, action: TransferWorkbookGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TransferWorkbookFailAction) transferGetFail(ctx: StateContext<TransferWorkbookStateModel>, action: TransferWorkbookFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransferWorkbookResetAction) transferGetReset(ctx: StateContext<TransferWorkbookStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
