import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';

import { OrganizationsSearchState, OrganizationsSearchStateModel } from './organizations-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class OrganizationsSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([OrganizationsSearchState], (state: OrganizationsSearchStateModel) => state.pending);

    static readonly getOrganizationsSearch = createSelector([OrganizationsSearchState], (state: OrganizationsSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([OrganizationsSearchState], (state: OrganizationsSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([OrganizationsSearchState], (state: OrganizationsSearchStateModel) => state.error);

    static readonly getAllOrganizationsSearchLoaded = createSelector([OrganizationsSearchState], (state: OrganizationsSearchStateModel) => state.allItemsLoaded);

    @Select(OrganizationsSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(OrganizationsSearchSelectors.getOrganizationsSearch) organizations$: Observable<OrganizationListItem[]>;

    @Select(OrganizationsSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(OrganizationsSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(OrganizationsSearchSelectors.getAllOrganizationsSearchLoaded) allOrganizationsSearchLoaded$: Observable<boolean>;

    constructor() {
        super('OrganizationsSearchSelectors');
    }
}
