import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DecompositionsService } from '../../services/decompositions.service';
import { ExgCookieService } from '../../../burns-ui-framework/shared/services/common/exg-cookie.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { DecompositionCreateSuccessAction } from './decomposition-create.actions';
import { DecompositionDeleteSuccessAction } from './decomposition-delete.actions';
import { DecompositionUpdateSuccessAction } from './decomposition-update.actions';
import { DecompositionsAction, DecompositionsFailAction, DecompositionsResetAction, DecompositionsSetFilterAction, DecompositionsSuccessAction } from './decompositions.actions';

import { DcrListGetFilter, DcrListGetFilterData } from '../../models/filters/dcr-list-get-filter.model';
import { DCRListItem } from '../../models/business/dcr/dcr-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface DecompositionsStateModel extends PaginationFilteredStateModel<DCRListItem, DcrListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: DCRListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: DcrListGetFilterData;
    total: number;
}

@State<DecompositionsStateModel>({
    name: 'decompositions',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class DecompositionsState extends PaginationFilteredStateBase {
    constructor(private decompositionsService: DecompositionsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([DecompositionsAction]) productsGet(ctx: StateContext<DecompositionsStateModel>, action: DecompositionsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'decomposition');
        ctx.setState({ ...state, filter: filterData });

        const filter = new DcrListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.decompositionsService.getDecompositions(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DecompositionsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DecompositionsFailAction(err)), 0));
    }

    @Action(DecompositionsSuccessAction) decompositionGetSuccess(ctx: StateContext<DecompositionsStateModel>, action: DecompositionsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(DecompositionsFailAction) decompositionGetFail(ctx: StateContext<DecompositionsStateModel>, action: DecompositionsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DecompositionsResetAction) decompositionGetReset(ctx: StateContext<DecompositionsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(DecompositionsSetFilterAction) usersSetFilter(ctx: StateContext<DecompositionsStateModel>, action: DecompositionsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'decomposition');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new DecompositionsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(DecompositionCreateSuccessAction) decompositionCreateSuccess(ctx: StateContext<DecompositionsStateModel>, action: DecompositionCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(DecompositionUpdateSuccessAction) decompositionUpdateSuccess(ctx: StateContext<DecompositionsStateModel>, action: DecompositionUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(DecompositionDeleteSuccessAction) decompositionDeleteSuccess(ctx: StateContext<DecompositionsStateModel>, action: DecompositionDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
