import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResultWithAmount } from '../../models/business/common/pagination-result-with-amount';

import { Franchisee } from '../../models/business/franchisees/franchisee.model';
import { FranchiseeListItem } from '../../models/business/franchisees/franchisee-list-item.model';
import { FranchiseeCreateOrUpdateRequest } from '../../models/business/franchisees/franchisee-create-or-update.model';
import { FranchiseeListGetFilter } from '../../models/filters/franchisee-list-get-filter.model';
import { FranchiseeTypeListGetFilter } from '../../models/filters/franchisee-type-list-get-filter.model';
import { FranchiseeTypesListItem } from '../../models/business/franchisees/franchisee-types-list-item.model';
import { FranchiseeTypeCreateOrUpdate } from '../../models/business/franchisees/franchisee-type-create-or-update.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class FranchiseesService extends BaseSingletonService {

    private settings: {
        service: {
            getFranchisee: string;
            getFranchiseeList: string;
            createFranchisee: string;
            updateFranchisee: string;
            deleteFranchisee: string;
            getFranchiseeTypeList: string;
            updateFranchiseeType: string;
        }
    };

    constructor(private http: HttpService) {
        super('FranchiseesService');
        this.settings = {
            service: {
                getFranchisee: '/corporation/v1/franchisees/{0}',
                getFranchiseeList: '/corporation/v1/franchisees?sortField={0}&sortOrder={1}&pageIndex={2}&pageSize={3}&term={4}&organizationUids={5}&organizationPosUids={6}&franchiseeUids={7}',
                getFranchiseeTypeList: '/corporation/v1/franchisees/franchisee-types?sortField={0}&sortOrder={1}&pageIndex={2}&pageSize={3}&term={4}&organizationUids={5}&organizationPosUids={6}&franchiseeUids={7}',
                createFranchisee: '/corporation/v1/franchisees',
                updateFranchisee: '/corporation/v1/franchisees/{0}',
                updateFranchiseeType: '/corporation/v1/franchisees/{0}',
                deleteFranchisee: '/corporation/v1/franchisees/{0}'
            }
        };
    }

    public async getFranchisee(uid: string): Promise<Franchisee> {
        return this.http.get<{ data: Franchisee }>(String.format(this.settings.service.getFranchisee, uid))
            .then(res => res.data);
    }

    public async getFranchisees(filter: FranchiseeListGetFilter): Promise<FranchiseeListItem[]> {
        const organizationUids = (filter.organizationUids || []).join(',');
        const organizationPosUids = (filter.organizationPosUids || []).join(',');
        const franchiseesUids = (filter.franchiseeUids || []).join(',');
        return this.http.get<{ list: FranchiseeListItem[] }>(String.format(this.settings.service.getFranchiseeList, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term, organizationUids, organizationPosUids, franchiseesUids))
            .then(res => res.list);
    }

    public async getFranchiseeList(filter: FranchiseeListGetFilter): Promise<PaginationResultWithAmount<FranchiseeListItem>> {
        return this.http.get<PaginationResultWithAmount<FranchiseeListItem>>(String.format(this.settings.service.getFranchiseeList, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getFranchiseeTypeList(filter: FranchiseeTypeListGetFilter): Promise<PaginationResultWithAmount<FranchiseeTypesListItem>> {
        return this.http.get<PaginationResultWithAmount<FranchiseeTypesListItem>>(String.format(this.settings.service.getFranchiseeTypeList, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createFranchisee(request: FranchiseeCreateOrUpdateRequest): Promise<Franchisee> {
        return this.http.post<{ data: Franchisee }>(String.format(this.settings.service.createFranchisee), request)
            .then(res => res.data);
    }

    public async updateFranchisee(uid: string, request: FranchiseeCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateFranchisee, uid), request);
    }

    public async updateFranchiseeType(uid: string, request: FranchiseeTypeCreateOrUpdate): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateFranchisee, uid), request);
    }

    public async deleteFranchisee(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteFranchisee, uid));
    }
}
