import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PricelistStatus } from '../../models/business/pricelist/pricelist-status.enum';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

import { PricelistFailAction, PricelistGetAction, PricelistGetSuccessAction, PricelistGetWithCopyAction, PricelistGetWithCopySuccessAction, PricelistResetAction } from './pricelist.actions';

export interface PricelistStateModel {
    entity: Pricelist;
    pending: boolean;
    error: ErrorObject;
}

@State<PricelistStateModel>({
    name: 'pricelist',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PricelistState {
    constructor(private pricelistService: PricelistService) { }

    @Action(PricelistGetAction) pricelistGet(ctx: StateContext<PricelistStateModel>, action: PricelistGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.pricelistService.getPricelist(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PricelistGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistFailAction(err)), 0));
    }

    @Action(PricelistGetWithCopyAction) pricelistGetWithCopy(ctx: StateContext<PricelistStateModel>, action: PricelistGetWithCopyAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.pricelistService.getPricelist(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PricelistGetWithCopySuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistFailAction(err)), 0));
    }

    @Action(PricelistGetSuccessAction) pricelistGetSuccess(ctx: StateContext<PricelistStateModel>, action: PricelistGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PricelistGetWithCopySuccessAction) pricelistGetWithCopySuccess(ctx: StateContext<PricelistStateModel>, action: PricelistGetWithCopySuccessAction) {
        const state = ctx.getState();
        const entity = { ...action.payload, uid: null, status: PricelistStatus.Draft };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }

    @Action(PricelistFailAction) pricelistGetFail(ctx: StateContext<PricelistStateModel>, action: PricelistFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PricelistResetAction) pricelistGetReset(ctx: StateContext<PricelistStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
