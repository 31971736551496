import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContactContragentGetAction {
    static readonly type = '[ContactContragent Page] GetContragent Get';

    constructor(public payload: string) { }
}

export class ContactContragentGetSuccessAction {
    static readonly type = '[ContactContragent API] GetContragent Success';

    constructor(public payload: Contragent) { }
}

export class ContactContragentFailAction {
    static readonly type = '[ContactContragent API] GetContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactContragentResetAction {
    static readonly type = '[ContactContragent Page] GetContragent Reset';
}
