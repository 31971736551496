import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RecyclingsService } from '../../services/recyclings.service';

import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { RecyclingUpdateAction, RecyclingUpdateFailAction, RecyclingUpdateResetAction, RecyclingUpdateSuccessAction } from './recycling-update.actions';

export interface RecyclingUpdateStateModel {
    pending: boolean;
    updated: DCRCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<RecyclingUpdateStateModel>({
    name: 'recyclingUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class RecyclingUpdateState {
    constructor(private recyclingsService: RecyclingsService) { }

    @Action(RecyclingUpdateAction) async recyclingUpdate(ctx: StateContext<RecyclingUpdateStateModel>, action: RecyclingUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.recyclingsService.updateRecycling(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new RecyclingUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RecyclingUpdateFailAction(err)), 0));
    }

    @Action(RecyclingUpdateSuccessAction) recyclingUpdateSuccess(ctx: StateContext<RecyclingUpdateStateModel>, action: RecyclingUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(RecyclingUpdateFailAction) recyclingUpdateFail(ctx: StateContext<RecyclingUpdateStateModel>, action: RecyclingUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(RecyclingUpdateResetAction) recyclingUpdateReset(ctx: StateContext<RecyclingUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
