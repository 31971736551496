import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosListFilterData } from '../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../models/business/pos/pos-list-item.model';

export class PosSearchAction {
    static readonly type = '[PosSearch Page] GetPosSearch';

    constructor(public payload: PosListFilterData) { }
}

export class PosSearchSuccessAction {
    static readonly type = '[PosSearch API] GetPosSearch Success';

    constructor(public payload: PaginationResult<PosListItem>) { }
}

export class PosSearchFailAction {
    static readonly type = '[PosSearch API] GetPosSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosSearchResetAction {
    static readonly type = '[PosSearch Page] GetPosSearch Reset';
}
