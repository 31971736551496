import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { UserUpdateSettings } from '../../../../burns-ui-framework/shared/models/business/user/user.model';

export class ProfileUpdateSettingsAction {
    static readonly type = '[Profile Page] UpdateProfileSettings';

    constructor(public payload: { uid: string, request: UserUpdateSettings }) { }
}

export class ProfileUpdateSettingsSuccessAction {
    static readonly type = '[Profile API] UpdateProfileSettings Success';

    constructor(public payload: { culture: string }) { }
}

export class ProfileUpdateSettingsFailAction {
    static readonly type = '[Profile API] UpdateProfileSettings Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProfileUpdateSettingsResetAction {
    static readonly type = '[Profile Page] UpdateProfileSettings Reset';
}
