import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { OrderModelMapper } from '../../model-mappers/orders-model-mapper.service';
import { OrdersService } from '../../services/business/orders.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { OrderCreateSuccessAction } from './order-create.actions';
import { OrderDeleteSuccessAction } from './order-delete.actions';
import { OrderUpdateSuccessAction } from './order-update.actions';
import { OrdersAction, OrdersCreateFromSocketAction, OrdersDeleteFromSocketAction, OrdersFailAction, OrdersResetAction, OrdersSetFilterAction, OrdersSuccessAction, OrdersUpdateFromSocketAction } from './orders.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderListGetFilter, OrderListGetFilterData } from '../../models/filters/order-list-get-filter.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrderSummary } from '../../models/business/orders/order-summary.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';
import { YeOrderSortingFieldsEnum } from '../../models/business/orders/ye-order-sorting-fields.enum';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface OrdersStateModel extends PaginationFilteredStateModel<OrderListItem, OrderListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: OrderListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: OrderListGetFilterData;
    total: number;
    summary: OrderSummary;
}

@State<OrdersStateModel>({
    name: 'orders',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, summary: {} as OrderSummary , filter: {} }
})
@Injectable()
export class OrdersState extends PaginationFilteredStateBase {
    constructor(private ordersService: OrdersService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([OrdersAction]) productsGet(ctx: StateContext<OrdersStateModel>, action: OrdersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'order');
        ctx.setState({ ...state, filter: filterData });

        const filter = new OrderListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.ordersService.getOrderList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrdersSuccessAction({ list: resp, pagination: action.payload, summary: resp.summary })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrdersFailAction(err)), 0));
    }

    @Action(OrdersSuccessAction) orderGetSuccess(ctx: StateContext<OrdersStateModel>, action: OrdersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, summary: action.payload.summary, allItemsLoaded, index });
    }

    @Action(OrdersFailAction) orderGetFail(ctx: StateContext<OrdersStateModel>, action: OrdersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrdersResetAction) orderGetReset(ctx: StateContext<OrdersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(OrdersSetFilterAction) usersSetFilter(ctx: StateContext<OrdersStateModel>, action: OrdersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'order');
            ctx.setState({ ...state, filter, index: 0 });
            setTimeout(() => ctx.dispatch(new OrdersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(OrderCreateSuccessAction) orderCreateSuccess(ctx: StateContext<OrdersStateModel>, action: OrderCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(OrderUpdateSuccessAction) orderUpdateSuccess(ctx: StateContext<OrdersStateModel>, action: OrderUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = OrderModelMapper.Map({ ...entities[action.payload.uid] }, action.payload.request);
        ctx.setState({ ...state, entities });
    }

    @Action(OrderDeleteSuccessAction) orderDeleteSuccess(ctx: StateContext<OrdersStateModel>, action: OrderDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }

    @Action(OrdersCreateFromSocketAction) orderCreateFromSocketSuccess(ctx: StateContext<OrdersStateModel>, action: OrdersCreateFromSocketAction) {
        const state = ctx.getState();
        let uids = [...state.uids];
        const entities = { ...state.entities };
        const sortOrder = state.filter.sortOrder || SortOrder.Asc;

        entities[action.payload.uid] = { ...action.payload };

        const sortField = state.filter.sortField;

        if (sortField) {
            const sortedEntities = this.sortEntitiesByFilter(entities, sortOrder, sortField);

            uids = sortedEntities.map(entity => entity.uid);
        } else {
            if (sortOrder === SortOrder.Desc) {
                uids.push(action.payload.uid);
            } else {
                uids.unshift(action.payload.uid);
            }
        }

        ctx.setState({ ...state, uids, entities });
    }

    @Action(OrdersUpdateFromSocketAction) ordersUpdateFromSocketAction(ctx: StateContext<OrdersStateModel>, action: OrdersUpdateFromSocketAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };

        entities[action.payload.uid] = { ...action.payload };
        ctx.setState({ ...state, entities });
    }

    @Action(OrdersDeleteFromSocketAction) orderDeleteFromSocketSuccess(ctx: StateContext<OrdersStateModel>, action: OrdersDeleteFromSocketAction) {
        const state = ctx.getState();
        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }


    private sortEntitiesByFilter (entities: { [uid: string]: OrderListItem }, sortOrder: SortOrder, sortField: string) {
        return Object.values(entities).sort((a, b) => {
            let valueA: any;
            let valueB: any;

            switch (sortField) {
                case YeOrderSortingFieldsEnum.Number:
                    valueA = parseInt(a.number);
                    valueB = parseInt(b.number);
                    break;
                case YeOrderSortingFieldsEnum.CreateDate:
                    valueA = a.createDate;
                    valueB = b.createDate;
                    break;
                case YeOrderSortingFieldsEnum.PrepareDateTo:
                    valueA = a.preparedDate;
                    valueB = b.preparedDate;
                    break;
                case YeOrderSortingFieldsEnum.ShippingMethod:
                    valueA = a.shippingMethod;
                    valueB = b.shippingMethod;
                    break;
                case YeOrderSortingFieldsEnum.ContactFullName:
                    valueA = a.contact?.fullName;
                    valueB = b.contact?.fullName;
                    break;
                case YeOrderSortingFieldsEnum.Amount:
                    valueA = a.amount;
                    valueB = b.amount;
                    break;
                case YeOrderSortingFieldsEnum.PaymentType:
                    valueA = a.paymentTypeUid;
                    valueB = b.paymentTypeUid;
                    break;
                case YeOrderSortingFieldsEnum.Status:
                    valueA = a.status;
                    valueB = b.status;
                    break;
                case YeOrderSortingFieldsEnum.ShippingCity:
                    valueA = a.shippingCity;
                    valueB = b.shippingCity;
                    break;
                default: return 0;
            }

            return sortOrder === SortOrder.Asc ? valueA - valueB : valueB - valueA;
        });
    }
}
