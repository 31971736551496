import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderCreateOrUpdateRequest } from '../../models/business/orders/order-create-or-update-request.model';

export class OrderUpdateAction {
    static readonly type = '[Booking Page] UpdateOrder';

    constructor(public payload: { uid: string, request: OrderCreateOrUpdateRequest }) { }
}

export class OrderUpdateSuccessAction {
    static readonly type = '[Order API] UpdateOrder Success';

    constructor(public payload: { uid: string, request: OrderCreateOrUpdateRequest }) { }
}

export class OrderUpdateFailAction {
    static readonly type = '[Order API] UpdateOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderUpdateResetAction {
    static readonly type = '[Booking Page] UpdateOrder Reset';
}
