import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationRequisitesService } from '../../services/business/corporation-requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CorporationRequisiteUpdateSuccessAction } from './corporation-requisite-update.actions';
import { CorporationRequisiteAction, CorporationRequisiteFailAction, CorporationRequisiteResetAction, CorporationRequisiteSuccessAction } from './corporation-requisite.actions';
import { CorporationRequisite } from '../../models/business/corporation-requisites/corporation-requisites.model';

export interface CorporationRequisiteStateModel {
    pending: boolean;
    entity: CorporationRequisite;
    error: ErrorObject;
}

@State<CorporationRequisiteStateModel>({
    name: 'corporationRequisite',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CorporationRequisiteState {
    constructor(private corporationRequisitesService: CorporationRequisitesService) { }

    @Action(CorporationRequisiteAction) async corporationCorporationCorporationRequisite(ctx: StateContext<CorporationRequisiteStateModel>, action: CorporationRequisiteAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.corporationRequisitesService.getRequisite(action.payload.uid, action.payload.organizationUid)
            .then(res => setTimeout(() => ctx.dispatch(new CorporationRequisiteSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CorporationRequisiteFailAction(err)), 0));
    }

    @Action(CorporationRequisiteSuccessAction) corporationCorporationCorporationRequisiteSuccess(ctx: StateContext<CorporationRequisiteStateModel>, action: CorporationRequisiteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CorporationRequisiteFailAction) corporationCorporationCorporationRequisiteFail(ctx: StateContext<CorporationRequisiteStateModel>, action: CorporationRequisiteFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CorporationRequisiteResetAction) corporationCorporationCorporationRequisiteReset(ctx: StateContext<CorporationRequisiteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(CorporationRequisiteUpdateSuccessAction) corporationCorporationCorporationRequisiteUpdateSuccess(ctx: StateContext<CorporationRequisiteStateModel>, action: CorporationRequisiteUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
