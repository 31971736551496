import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CustomersService } from '../../services/business/customers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerDeleteAction, CustomerDeleteFailAction, CustomerDeleteResetAction, CustomerDeleteSuccessAction } from './customer-delete.actions';

export interface CustomerDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<CustomerDeleteStateModel>({
    name: 'customerDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class CustomerDeleteState {
    constructor(private customersService: CustomersService) { }

    @Action(CustomerDeleteAction) async customerDelete(ctx: StateContext<CustomerDeleteStateModel>, action: CustomerDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.customersService.deleteCustomer(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new CustomerDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new CustomerDeleteFailAction(error)), 0));
    }

    @Action(CustomerDeleteSuccessAction) customerDeleteSuccess(ctx: StateContext<CustomerDeleteStateModel>, _: CustomerDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(CustomerDeleteFailAction) customerDeleteFail(ctx: StateContext<CustomerDeleteStateModel>, action: CustomerDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomerDeleteResetAction) customerDeleteReset(ctx: StateContext<CustomerDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
