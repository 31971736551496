import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContactCreateAction, ContactCreateFailAction, ContactCreateResetAction, ContactCreateSuccessAction } from './contact-create.actions';
import { Contact } from '../../models/business/contacts/contact.model';

export interface ContactCreateStateModel {
    pending: boolean;
    created: Contact;
    error: ErrorObject;
}

@State<ContactCreateStateModel>({
    name: 'contactCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class ContactCreateState {
    constructor(private contactsService: ContactsService) { }

    @Action(ContactCreateAction) async contactCreate(ctx: StateContext<ContactCreateStateModel>, action: ContactCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.contactsService.createContact({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new ContactCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContactCreateFailAction(err)), 0));
    }

    @Action(ContactCreateSuccessAction) contactCreateSuccess(ctx: StateContext<ContactCreateStateModel>, action: ContactCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ContactCreateFailAction) contactCreateFail(ctx: StateContext<ContactCreateStateModel>, action: ContactCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ContactCreateResetAction) contactCreateReset(ctx: StateContext<ContactCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
