import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { YandexSuggestionPlaceComponent } from '../models/yandex-suggestion-place-component.model';

import { PlacesSuggestionsState, PlacesSuggestionsStateModel } from './places-suggestions.reducer';

@Injectable({
    providedIn: 'root'
})
export class PlacesSuggestionsSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([PlacesSuggestionsState], (state: PlacesSuggestionsStateModel) => state.pending);

    static readonly getPlaces = createSelector([PlacesSuggestionsState], (state: PlacesSuggestionsStateModel) => state.entities);

    static readonly getRetrieved = createSelector([PlacesSuggestionsState], (state: PlacesSuggestionsStateModel) => state.retrieved);

    static readonly getError = createSelector([PlacesSuggestionsState], (state: PlacesSuggestionsStateModel) => state.error);

    @Select(PlacesSuggestionsSelectors.getPending) pending$: Observable<boolean>;

    @Select(PlacesSuggestionsSelectors.getPlaces) places$: Observable<YandexSuggestionPlaceComponent[]>;

    @Select(PlacesSuggestionsSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(PlacesSuggestionsSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('PlacesSuggestionsSelectors');
    }
}
