import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ExgCultureEnum } from '../../../../burns-ui-framework/shared/models/common/exg-culture.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';
import { Timezone } from '../../models/business/timezones/timezone.model';

@Injectable({
    providedIn: 'root'
})
export class TimeZonesService extends BaseSingletonService {

    private settings: { service: { getTimezones: string; } };

    constructor(private http: HttpService) {
        super('TimezonesService');
        this.settings = {
            service: {
                getTimezones: '/identity/v1/timezones?culture={0}'
            }
        };
    }

    public async getTimeZones(culture: ExgCultureEnum): Promise<Timezone[]> {
        return this.http.get<{ list: Timezone[] }>(String.format(this.settings.service.getTimezones, culture))
            .then((resp) => {
                return resp.list;
            });
    }
}
