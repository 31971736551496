import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';
import { RestaurantTemplateListGetFilter } from '../../models/filters/restaurant-template-list-get-filter.model';
import { RestaurantTemplateListItem } from '../../models/business/restaurant-templates/restaurant-template-list-item.model';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class RestaurantTemplatesService extends BaseSingletonService {

    private settings: {
        service: {
            getRestaurantTemplate: string;
            getRestaurantTemplateList: string;
            createRestaurantTemplate: string;
            updateRestaurantTemplate: string;
            deleteRestaurantTemplate: string;
        }
    };

    constructor(private http: HttpService) {
        super('RestaurantTemplatesService');
        this.settings = {
            service: {
                getRestaurantTemplate: '/restaurant-templates-aggregation/v1/restaurant-templates/{0}',
                getRestaurantTemplateList: '/restaurant-templates-aggregation/v1/restaurant-templates?&organizationUid={0}',
                createRestaurantTemplate: '/restaurant-templates-aggregation/v1/restaurant-templates',
                updateRestaurantTemplate: '/restaurant-templates-aggregation/v1/restaurant-templates/{0}',
                deleteRestaurantTemplate: '/restaurant-templates/v1/restaurant-templates/{0}',
            }
        };
    }

    public async getRestaurantTemplateList(filter: RestaurantTemplateListGetFilter): Promise<PaginationResult<RestaurantTemplateListItem>> {

        return Promise.resolve({
            list: [{uid: "12345", name: "шаблон 1"}, {uid: "12346", name: "шаблон 2"}, {uid: "12347", name: "шаблон 3"}],
            total: 2,
            pageIndex:1,
            pageSize: 1,
        });
        
        return this.http.get<PaginationResult<RestaurantTemplateListItem>>(String.format(
            this.settings.service.getRestaurantTemplateList,
            filter.organizationUid,
            filter.pageIndex,
            filter.pageSize,
            filter.term,
        ))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }


    public async deleteRestaurantTemplate(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRestaurantTemplate, uid));
    }

}
