import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ManagerAssistantsService } from '../../services/business/manager-assistants.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ManagerAssistantDeleteAction, ManagerAssistantDeleteFailAction, ManagerAssistantDeleteResetAction, ManagerAssistantDeleteSuccessAction } from './manager-assistant-delete.actions';

export interface ManagerAssistantDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<ManagerAssistantDeleteStateModel>({
    name: 'managerAssistantDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class ManagerAssistantDeleteState {
    constructor(private managerAssistantsService: ManagerAssistantsService) { }

    @Action(ManagerAssistantDeleteAction) async managerAssistantDelete(ctx: StateContext<ManagerAssistantDeleteStateModel>, action: ManagerAssistantDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.managerAssistantsService.deleteAssistant(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new ManagerAssistantDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new ManagerAssistantDeleteFailAction(error)), 0));
    }

    @Action(ManagerAssistantDeleteSuccessAction) managerAssistantDeleteSuccess(ctx: StateContext<ManagerAssistantDeleteStateModel>, _: ManagerAssistantDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(ManagerAssistantDeleteFailAction) managerAssistantDeleteFail(ctx: StateContext<ManagerAssistantDeleteStateModel>, action: ManagerAssistantDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ManagerAssistantDeleteResetAction) managerAssistantDeleteReset(ctx: StateContext<ManagerAssistantDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
