
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PosDeleteAction {
    static readonly type = '[My Pos Page] DeletePos';

    constructor(public payload: { uid: string }) { }
}

export class PosDeleteSuccessAction {
    static readonly type = '[My Poss API] DeletePos Success';

    constructor(public payload: { uid: string }) { }
}

export class PosDeleteFailAction {
    static readonly type = '[My Poss API] DeletePos Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosDeleteResetAction {
    static readonly type = '[My Pos Page] DeletePos Reset';
}
