import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationCreateOrUpdateRequest } from '../../models/business/organizations/organization-create-or-update-request.model';

export class OrganizationUpdateAction {
    static readonly type = '[Booking Page] UpdateOrganization';

    constructor(public payload: { uid: string, request: OrganizationCreateOrUpdateRequest, defaultRequisiteUid: string }) { }
}

export class OrganizationUpdateSuccessAction {
    static readonly type = '[Organization API] UpdateOrganization Success';

    constructor(public payload: { uid: string, request: OrganizationCreateOrUpdateRequest }) { }
}

export class OrganizationUpdateFailAction {
    static readonly type = '[Organization API] UpdateOrganization Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationUpdateResetAction {
    static readonly type = '[Booking Page] UpdateOrganization Reset';
}
