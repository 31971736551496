import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ProductDeleteAction {
    static readonly type = '[My Product Page] DeleteProduct';

    constructor(public payload: { uid: string }) { }
}

export class ProductDeleteSuccessAction {
    static readonly type = '[My Products API] DeleteProduct Success';

    constructor(public payload: { uid: string }) { }
}

export class ProductDeleteFailAction {
    static readonly type = '[My Products API] DeleteProduct Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductDeleteResetAction {
    static readonly type = '[My Product Page] DeleteProduct Reset';
}
