import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetFilterData } from '../../models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../models/business/intervals/interval-list-item.model';

export class IntervalsGetAction {
    static readonly type = '[Intervals Page] GetInterval Get';

    constructor(public payload: IntervalGetFilterData) { }
}

export class IntervalsGetSuccessAction {
    static readonly type = '[Intervals API] GetInterval Success';

    constructor(public payload: IntervalListItem[]) { }
}

export class IntervalsFailAction {
    static readonly type = '[Intervals API] GetInterval Fail';

    constructor(public payload: ErrorObject) { }
}

export class IntervalsResetAction {
    static readonly type = '[Intervals Page] GetInterval Reset';
}

export class IntervalsUpdateFromSocketAction {
    static readonly type = '[Intervals API] Interval FromSocket';

    constructor(public payload: IntervalListItem[]) { }
}
