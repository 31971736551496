import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CharacteristicsService } from '../../services/business/characteristics.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CharacteristicsSearchAction, CharacteristicsSearchFailAction, CharacteristicsSearchResetAction, CharacteristicsSearchSuccessAction } from './characteristics-search.actions';

import { CharacteristicListItem } from '../../models/business/characteristics/characteristic-list-item.model';
import { CharacteristicsListGetFilter } from '../../models/filters/characteristics-list-get-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface CharacteristicsSearchStateModel extends PaginationStateModel<CharacteristicListItem> {
    uids: string[];
    entities: { [uid: string]: CharacteristicListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<CharacteristicsSearchStateModel>({
    name: 'characteristicsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class CharacteristicsSearchState extends PaginationStateBase {
    constructor(private characteristicsService: CharacteristicsService) {
        super();
    }

    @Action([CharacteristicsSearchAction]) characteristicsGet(ctx: StateContext<CharacteristicsSearchStateModel>, action: CharacteristicsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CharacteristicsListGetFilter({ pageIndex: state.index, filterData: { ...action.payload } });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.characteristicsService.getCharacteristics(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CharacteristicsSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CharacteristicsSearchFailAction(err)), 0));
    }

    @Action(CharacteristicsSearchSuccessAction) characteristicsGetSuccess(ctx: StateContext<CharacteristicsSearchStateModel>, action: CharacteristicsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.characteristicsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CharacteristicsSearchFailAction) characteristicsGetFail(ctx: StateContext<CharacteristicsSearchStateModel>, action: CharacteristicsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CharacteristicsSearchResetAction) characteristicsGetReset(ctx: StateContext<CharacteristicsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
