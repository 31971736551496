import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../shared/models/common/error-object.model';
import { YandexPlaceComponent } from '../models/yandex-place-component.model';

import { PlacesForwardState, PlacesForwardStateModel } from './places-forward.reducer';

@Injectable({
    providedIn: 'root'
})
export class PlacesForwardSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([PlacesForwardState], (state: PlacesForwardStateModel) => state.pending);

    static readonly getPlaces = createSelector([PlacesForwardState], (state: PlacesForwardStateModel) => state.entities);

    static readonly getRetrieved = createSelector([PlacesForwardState], (state: PlacesForwardStateModel) => state.retrieved);

    static readonly getError = createSelector([PlacesForwardState], (state: PlacesForwardStateModel) => state.error);

    @Select(PlacesForwardSelectors.getPending) pending$: Observable<boolean>;

    @Select(PlacesForwardSelectors.getPlaces) places$: Observable<YandexPlaceComponent[]>;

    @Select(PlacesForwardSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(PlacesForwardSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('PlacesForwardSelectors');
    }
}
