import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import {
    PaginationStateBase,
    PaginationStateModel
} from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ExgParamsConfig } from '../../exg-params.config';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { OrganizationPosesListGetFilter, OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import {
    OrganizationPosesLodMoreAction,
    FranchiseeRestaurantsAction,
    FranchiseeRestaurantsFailAction,
    FranchiseeRestaurantsResetAction,
    FranchiseeRestaurantsSuccessAction
} from './franchisee-restaurants.actions';

export interface FranchiseeRestaurantsStateModel extends PaginationStateModel<OrganizationPosListItem> {
    uids: string[];
    entities: { [uid: string]: OrganizationPosListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    filter: OrganizationPosesListGetFilterData;
    pending: boolean;
    error: ErrorObject;
}

@State<FranchiseeRestaurantsStateModel>({
    name: 'franchiseeRestaurants',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: null }
})
@Injectable()
export class FranchiseeRestaurantsState extends PaginationStateBase {
    constructor(private orgPosService: OrganizationPosService) {
        super();
    }

    @Action([FranchiseeRestaurantsAction]) organizationPosesGet(ctx: StateContext<FranchiseeRestaurantsStateModel>, action: FranchiseeRestaurantsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });
        const filter = new OrganizationPosesListGetFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        setTimeout(()=> ctx.setState({ ...state, pending: true, error: null, filter }))

        return this.orgPosService.getPosList(filter)
            .then(list => setTimeout(() => ctx.dispatch(new FranchiseeRestaurantsSuccessAction({ list, pagination: PaginationRequest.ReloadCurrentList })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeRestaurantsFailAction(err)), 0));
    }

    @Action([OrganizationPosesLodMoreAction]) organizationPosesLoadMore(ctx: StateContext<FranchiseeRestaurantsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrganizationPosesListGetFilter({ pageIndex: state.index, filterData: state.filter });
        if (!this.applyFilterPagination(PaginationRequest.LoadMore, state, filter)) {
            ctx.setState({ ...state, pending: false, filter });
            return Promise.resolve();
        }

        return this.orgPosService.getPosList(filter)
            .then(list => setTimeout(() => ctx.dispatch(new FranchiseeRestaurantsSuccessAction({ list, pagination: PaginationRequest.LoadMore })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeRestaurantsFailAction(err)), 0));
    }

    @Action(FranchiseeRestaurantsSuccessAction) organizationPosesGetSuccess(ctx: StateContext<FranchiseeRestaurantsStateModel>, action: FranchiseeRestaurantsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.posesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(FranchiseeRestaurantsFailAction) organizationPosesGetFail(ctx: StateContext<FranchiseeRestaurantsStateModel>, action: FranchiseeRestaurantsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FranchiseeRestaurantsResetAction) organizationPosesGetReset(ctx: StateContext<FranchiseeRestaurantsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, filter: null, index: 0 });
    }
}
