import { CustomerListItem } from '../../models/business/customers/customer-list-item.model';
import { CustomersListFilterData } from '../../models/filters/customers-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CustomersSearchAction {
    static readonly type = '[CustomersSearch Page] GetCustomersSearch';

    constructor(public payload: CustomersListFilterData) { }
}

export class CustomersSearchSuccessAction {
    static readonly type = '[CustomersSearch API] GetCustomersSearch Success';

    constructor(public payload: PaginationResult<CustomerListItem>) { }
}

export class CustomersSearchFailAction {
    static readonly type = '[CustomersSearch API] GetCustomersSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomersSearchResetAction {
    static readonly type = '[CustomersSearch Page] GetCustomersSearch Reset';
}
