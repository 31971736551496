import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class ProductsListFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public categoryUids?: string[];
    public brandUids: string[];
    public countryCodes: string[];
    public typeUids?: string[];
    public isArchived?: boolean;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: ProductsListFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'Name';
        this.sortOrder = (args && args.filterData && args.filterData.categoryUids != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.categoryUids = (args && args.filterData) ? args.filterData.categoryUids : null;
        this.brandUids = (args && args.filterData && args.filterData.brandUids != null) ? args.filterData.brandUids : [];
        this.typeUids = (args && args.filterData && args.filterData.typeUids != null) ? args.filterData.typeUids : [];
        this.countryCodes = (args && args.filterData) ? args.filterData.countryCodes : null;
        this.isArchived = (args && args.filterData) ? args.filterData.isArchived : null;
    }
}

export class ProductsListFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public categoryUids?: string[];
    public brandUids?: string[];
    public typeUids?: string[];
    public countryCodes?: string[];
    public isArchived?: boolean;
    public pageSize?: number;
}