import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RequisitesService } from '../../services/business/requisites.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { RequisiteCreateSuccessAction } from './requisite-create.actions';
import { RequisiteDeleteSuccessAction } from './requisite-delete.actions';
import { RequisiteUpdateSuccessAction } from './requisite-update.actions';
import { RequisitesAction, RequisitesFailAction, RequisitesResetAction, RequisitesSetFilterAction, RequisitesSuccessAction } from './requisites.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { RequisitesListFilter, RequisitesListFilterData } from '../../models/filters/requisites-list-filter.model';
import { Requisite } from '../../models/business/contragents/requisites.model';

export interface RequisitesStateModel extends PaginationStateModel<Requisite> {
    uids: string[];
    entities: { [uid: string]: Requisite };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: RequisitesListFilterData;
    total: number;
}

@State<RequisitesStateModel>({
    name: 'requisites',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class RequisitesState extends PaginationStateBase {
    constructor(private requisitesService: RequisitesService) {
        super();
    }

    @Action([RequisitesAction]) requisitesGet(ctx: StateContext<RequisitesStateModel>, action: RequisitesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new RequisitesListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.requisitesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.requisitesService.getRequisiteList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new RequisitesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RequisitesFailAction(err)), 0));
    }

    @Action(RequisitesSuccessAction) requisitesGetSuccess(ctx: StateContext<RequisitesStateModel>, action: RequisitesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.requisitesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index, total });
    }

    @Action(RequisitesFailAction) requisitesGetFail(ctx: StateContext<RequisitesStateModel>, action: RequisitesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RequisitesResetAction) requisitesGetReset(ctx: StateContext<RequisitesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(RequisitesSetFilterAction) usersSetFilter(ctx: StateContext<RequisitesStateModel>, action: RequisitesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new RequisitesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(RequisiteCreateSuccessAction) requisiteCreateSuccess(ctx: StateContext<RequisitesStateModel>, _: RequisiteCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new RequisitesAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(RequisiteUpdateSuccessAction) requisiteUpdateSuccess(ctx: StateContext<RequisitesStateModel>, action: RequisiteUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(RequisiteDeleteSuccessAction) requisiteDeleteSuccess(ctx: StateContext<RequisitesStateModel>, action: RequisiteDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
