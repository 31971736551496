<section class="row-header">
    <div class="filter-navigation">
        <exg-button (btnClick)="openFilter()" class="filter-btn" color="primary" styleType="texted">
            <exg-icon class="add-icon" iconName="combined-shape"></exg-icon>
            <div *ngIf="!filterItems.length" class="filter-btn-title">{{ 'Set up order mapping' | translate }}</div>
        </exg-button>
        <div class="filter-list">
            <div *ngFor="let item of filterItems" class="filter-item">
                <span class="filter-item-title exg-color-primary">{{ item.title | translate }}</span>
                <exg-button (btnClick)="deleteFilter(item.resetFilter)" [excludePadding]="true" styleType="texted">
                    <exg-icon class="filter_icon exg-color-primary" iconName="close"></exg-icon>
                </exg-button>
            </div>
        </div>
    </div>
    <div class="actions">
        <div class="menu">
            <exg-menu class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event)">
                <exg-button class="menu_btn" [styleType]="btnMenuStyleType">
                    <exg-icon class="menu_icon" color="primary" iconName="menudotted_vertical"></exg-icon>
                </exg-button>
            </exg-menu>
        </div>
    </div>
</section>
