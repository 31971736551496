import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { YandexService } from '../services/yandex.service';

import { PlacesSuggestionsAction, PlacesSuggestionsFailAction, PlacesSuggestionsResetAction, PlacesSuggestionsSuccessAction } from './places-suggestions.actions';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { YandexSuggestionPlaceComponent } from '../models/yandex-suggestion-place-component.model';

export interface PlacesSuggestionsStateModel {
    pending: boolean;
    entities: YandexSuggestionPlaceComponent[];
    retrieved: boolean;
    error: ErrorObject;
}

@State<PlacesSuggestionsStateModel>({
    name: 'YandexPlacesSuggestions',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class PlacesSuggestionsState {
    constructor(private geocodingService: YandexService) { }

    @Action([PlacesSuggestionsAction]) async placesSuggestionsGet(ctx: StateContext<PlacesSuggestionsStateModel>, action: PlacesSuggestionsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.geocodingService.getSuggestions(action.payload.term, action.payload.level, action.payload.fullNameLevelFrom, action.payload.lowerCornerLatitude, action.payload.lowerCornerLongitude, action.payload.upperCornerLatitude, action.payload.upperCornerLongitude)
            .then(resp => setTimeout(() => ctx.dispatch(new PlacesSuggestionsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PlacesSuggestionsFailAction(err)), 0));
    }

    @Action(PlacesSuggestionsSuccessAction) placesSuggestionsGetSuccess(ctx: StateContext<PlacesSuggestionsStateModel>, action: PlacesSuggestionsSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(PlacesSuggestionsFailAction) placesSuggestionsGetFail(ctx: StateContext<PlacesSuggestionsStateModel>, action: PlacesSuggestionsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PlacesSuggestionsResetAction) placesSuggestionsGetReset(ctx: StateContext<PlacesSuggestionsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
