import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosAreasService } from '../../services/business/organization-pos-areas.service';

import { OrganizationPosAreaCreateSuccessAction } from './organization-pos-area-create.actions';
import { OrganizationPosAreaDeleteSuccessAction } from './organization-pos-area-delete.actions';
import { OrganizationPosAreasAction, OrganizationPosAreasFailAction, OrganizationPosAreasResetAction, OrganizationPosAreasSuccessAction } from './organization-pos-areas.actions';

import { AreaListItem } from '../../models/business/organization-pos/area-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosAreaUpdateSuccessAction } from './organization-pos-area-update.actions';

export interface OrganizationPosAreasStateModel {
    entities: AreaListItem[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosAreasStateModel>({
    name: 'posAreas',
    defaults: {
        pending: false, entities: [], retrieved: false, error: null
    }
})
@Injectable()
export class OrganizationPosAreasState {
    constructor(private orgPosAreasService: OrganizationPosAreasService) { }

    @Action([OrganizationPosAreasAction]) organizationPosesGet(ctx: StateContext<OrganizationPosAreasStateModel>, action: OrganizationPosAreasAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosAreasService.getPosAreas(action.payload.organizationPosUid, action.payload.cityId)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosAreasSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreasFailAction(err)), 0));
    }

    @Action(OrganizationPosAreasSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosAreasStateModel>, action: OrganizationPosAreasSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(OrganizationPosAreasFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosAreasStateModel>, action: OrganizationPosAreasFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosAreasResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosAreasStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }

    @Action(OrganizationPosAreaCreateSuccessAction) organizationPosesCreateGetSuccess(ctx: StateContext<OrganizationPosAreasStateModel>, action: OrganizationPosAreaCreateSuccessAction) {
        const state = ctx.getState();
        const entities = [...state.entities];
        entities.push(action.payload);

        ctx.setState({ ...state, pending: false, entities });
    }

    @Action(OrganizationPosAreaUpdateSuccessAction) organizationPosesUpdateGetSuccess(ctx: StateContext<OrganizationPosAreasStateModel>, action: OrganizationPosAreaUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = [...state.entities];
        const editedIndex = entities.findIndex(e => e.uid === action.payload.uid);
        if (editedIndex > -1) {
            entities[editedIndex] = { ...entities[editedIndex], code: action.payload.area.code, isEnabled: action.payload.area.isEnabled };
        }

        ctx.setState({ ...state, pending: false, entities });
    }

    @Action(OrganizationPosAreaDeleteSuccessAction) organizationPosesDeleteSuccess(ctx: StateContext<OrganizationPosAreasStateModel>, action: OrganizationPosAreaDeleteSuccessAction) {
        const state = ctx.getState();
        const entities = [...state.entities].filter(x => x.uid !== action.payload.uid);

        ctx.setState({ ...state, pending: false, entities });
    }
}
