import { CharacteristicListItem } from '../../models/business/characteristics/characteristic-list-item.model';
import { CharacteristicsListGetFilterData } from '../../models/filters/characteristics-list-get-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CharacteristicsSearchAction {
    static readonly type = '[CharacteristicsSearch Page] GetCharacteristicsSearch';

    constructor(public payload: CharacteristicsListGetFilterData) { }
}

export class CharacteristicsSearchSuccessAction {
    static readonly type = '[CharacteristicsSearch API] GetCharacteristicsSearch Success';

    constructor(public payload: PaginationResult<CharacteristicListItem>) { }
}

export class CharacteristicsSearchFailAction {
    static readonly type = '[CharacteristicsSearch API] GetCharacteristicsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class CharacteristicsSearchResetAction {
    static readonly type = '[CharacteristicsSearch Page] GetCharacteristicsSearch Reset';
}
