import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PricelistCreateRequest } from '../../models/business/pricelist/pricelist-create-request.model';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

export class PricelistCreateAction {
    static readonly type = '[Pricelists Page] CreatePricelist';

    constructor(public payload: PricelistCreateRequest) { }
}

export class PricelistCreateSuccessAction {
    static readonly type = '[Pricelist API] CreatePricelist Success';

    constructor(public payload: Pricelist) { }
}

export class PricelistCreateFailAction {
    static readonly type = '[Pricelist API] CreatePricelist Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistCreateResetAction {
    static readonly type = '[Pricelists Page] CreatePricelist Reset';
}
