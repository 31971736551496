import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PricelistStatusUpdateRequest } from '../../models/business/pricelist/pricelist-status-update-request.model';

import { PricelistUpdateStatusAction, PricelistUpdateStatusFailAction, PricelistUpdateStatusResetAction, PricelistUpdateStatusSuccessAction } from './pricelist-update-status.actions';

export interface PricelistUpdateStatusStateModel {
    pending: boolean;
    updated: PricelistStatusUpdateRequest;
    error: ErrorObject;
}

@State<PricelistUpdateStatusStateModel>({
    name: 'pricelistUpdateStatus',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class PricelistUpdateStatusState {
    constructor(private pricelistService: PricelistService) { }

    @Action(PricelistUpdateStatusAction) async pricelistUpdate(ctx: StateContext<PricelistUpdateStatusStateModel>, action: PricelistUpdateStatusAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.pricelistService.updatePricelistStatus(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PricelistUpdateStatusSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistUpdateStatusFailAction(err)), 0));
    }

    @Action(PricelistUpdateStatusSuccessAction) pricelistUpdateSuccess(ctx: StateContext<PricelistUpdateStatusStateModel>, action: PricelistUpdateStatusSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(PricelistUpdateStatusFailAction) pricelistUpdateFail(ctx: StateContext<PricelistUpdateStatusStateModel>, action: PricelistUpdateStatusFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(PricelistUpdateStatusResetAction) pricelistUpdateReset(ctx: StateContext<PricelistUpdateStatusStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
