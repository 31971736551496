import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DecompositionsService } from '../../services/decompositions.service';

import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DecompositionFailAction, DecompositionGetAction, DecompositionGetSuccessAction, DecompositionResetAction } from './decomposition.actions';

export interface DecompositionStateModel {
    entity: DCRItem;
    pending: boolean;
    error: ErrorObject;
}

@State<DecompositionStateModel>({
    name: 'decomposition',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class DecompositionState {
    constructor(private decompositionsService: DecompositionsService) { }

    @Action(DecompositionGetAction) decompositionGet(ctx: StateContext<DecompositionStateModel>, action: DecompositionGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.decompositionsService.getDecomposition(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new DecompositionGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DecompositionFailAction(err)), 0));
    }

    @Action(DecompositionGetSuccessAction) decompositionGetSuccess(ctx: StateContext<DecompositionStateModel>, action: DecompositionGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(DecompositionFailAction) decompositionGetFail(ctx: StateContext<DecompositionStateModel>, action: DecompositionFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DecompositionResetAction) decompositionGetReset(ctx: StateContext<DecompositionStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
