import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';

export class DeliveryProductsAction {
    static readonly type = '[DeliveryProducts Page] GetDeliveryProducts';
}

export class DeliveryProductsSuccessAction {
    static readonly type = '[DeliveryProducts API] GetDeliveryProducts Success';

    constructor(public payload: { list: ProductListItemAggregation[] }) { }
}

export class DeliveryProductsFailAction {
    static readonly type = '[DeliveryProducts API] GetDeliveryProducts Fail';

    constructor(public payload: ErrorObject) { }
}

export class DeliveryProductsResetAction {
    static readonly type = '[DeliveryProducts Page] GetDeliveryProducts Reset';
}
