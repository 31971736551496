
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosAreaDeleteAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaDelete';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationPosAreaDeleteSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaDelete Success';

    constructor(public payload: { uid: string }) { }
}

export class OrganizationPosAreaDeleteFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaDelete Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosAreaDeleteResetAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaDelete Reset';
}

