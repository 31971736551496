import { DcrListGetFilterData } from '../../models/filters/dcr-list-get-filter.model';
import { DCRListItem } from '../../models/business/dcr/dcr-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CookingsAction {
    static readonly type = '[Cookingss Page] GetCookings';

    constructor(public payload: PaginationRequest) { }
}

export class CookingsSuccessAction {
    static readonly type = '[Cookings API] GetCookings Success';

    constructor(public payload: { list: PaginationResult<DCRListItem>, pagination: PaginationRequest }) { }
}

export class CookingsFailAction {
    static readonly type = '[Cookings API] GetCookings Fail';

    constructor(public payload: ErrorObject) { }
}

export class CookingsResetAction {
    static readonly type = '[Cookings Page] GetCookings Reset';
}

export class CookingsSetFilterAction {
    static readonly type = '[Cookings API] SetFilter';

    constructor(public payload: DcrListGetFilterData) { }
}
