import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { VehicleBrand } from '../../models/business/pos/vehicle-brand.model';
import { VehicleBrandsListFilterData } from '../../models/filters/vehicle-brands-list-filter.model';

export class VehicleBrandsAction {
    static readonly type = '[VehicleBrands Page] GetVehicleBrands';

    constructor(public payload: VehicleBrandsListFilterData) { }
}

export class VehicleBrandsSuccessAction {
    static readonly type = '[VehicleBrands API] GetVehicleBrands Success';

    constructor(public payload: PaginationResult<VehicleBrand>) { }
}

export class VehicleBrandsFailAction {
    static readonly type = '[VehicleBrands API] GetVehicleBrands Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleBrandsResetAction {
    static readonly type = '[VehicleBrands Page] GetVehicleBrands Reset';
}
