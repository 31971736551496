import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersErrorsService } from '../../services/business/orders-errors.service';

import { ErrorListGetFilter } from '../../models/filters/error-list-get-filter.model';
import { ErrorListItem } from '../../models/business/orders-errors/error-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrdersErrorsHeaderAction, OrdersErrorsHeaderFailAction, OrdersErrorsHeaderResetAction, OrdersErrorsHeaderSuccessAction } from './orders-errors-header.actions';
import { OrdersErrorsUpdateSuccessAction } from './orders-errors-update.actions';


export interface OrdersErrorsHeaderStateModel {
    pending: boolean;
    notifications: ErrorListItem[];
    error: ErrorObject;
}

@State<OrdersErrorsHeaderStateModel>({
    name: 'ordersErrorsHeader',
    defaults: { pending: false, notifications: null, error: null }
})
@Injectable()
export class OrdersErrorsHeaderState {
    constructor(private ordersErrorsService: OrdersErrorsService) { }

    @Action(OrdersErrorsHeaderAction) async notificationsHeaderGet(ctx: StateContext<OrdersErrorsHeaderStateModel>, action: OrdersErrorsHeaderAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.ordersErrorsService.getErrorsList(new ErrorListGetFilter({ filterData: action.payload }))
            .then(resp => setTimeout(() => ctx.dispatch(new OrdersErrorsHeaderSuccessAction(resp.list)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrdersErrorsHeaderFailAction(err)), 0));
    }

    @Action(OrdersErrorsHeaderSuccessAction) notificationsHeaderGetSuccess(ctx: StateContext<OrdersErrorsHeaderStateModel>, action: OrdersErrorsHeaderSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, notifications: action.payload, error: null });
    }

    @Action(OrdersErrorsHeaderFailAction) notificationsHeaderGetFail(ctx: StateContext<OrdersErrorsHeaderStateModel>, action: OrdersErrorsHeaderFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrdersErrorsHeaderResetAction) notificationsHeaderGetReset(ctx: StateContext<OrdersErrorsHeaderStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, notifications: null, error: null });
    }

    @Action(OrdersErrorsUpdateSuccessAction) notificationsUpdateSuccess(ctx: StateContext<OrdersErrorsHeaderStateModel>, action: OrdersErrorsUpdateSuccessAction) {
        const state = ctx.getState();

        const notifications = state.notifications.map(n => ({ ...n, status: action.payload.uids.some(x => x === n.uid) ? action.payload.status : n.status }));
        ctx.setState({ ...state, notifications });
    }
}
