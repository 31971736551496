import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderHistoryListGetFilterData } from '../../models/filters/order-history-list-get-filter.model';
import { OrderHistoryListItem } from '../../models/business/orders/order-history-list-item.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrderHistoryAction {
    static readonly type = '[OrderHistory Page] GetOrders';

    constructor(public payload: PaginationRequest) { }
}

export class OrderHistorySuccessAction {
    static readonly type = '[OrderHistory API] GetOrders Success';

    constructor(public payload: { list: PaginationResult<OrderHistoryListItem>, pagination: PaginationRequest }) { }
}

export class OrderHistoryFailAction {
    static readonly type = '[OrderHistory API] GetOrders Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderHistoryResetAction {
    static readonly type = '[OrderHistory Page] GetOrders Reset';
}

export class OrderHistorySetFilterAction {
    static readonly type = '[OrderHistory API] SetFilter';

    constructor(public payload: OrderHistoryListGetFilterData) { }
}
