import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResultWithAmount } from '../../models/business/common/pagination-result-with-amount';
import { PurchaseReturnCreateOrUpdateRequest } from '../../models/business/purchase-returns/purchase-return-create-or-update-request.model';
import { PurchaseReturnListGetFilter } from '../../models/filters/purchase-return-list-get-filter.model';
import { PurchaseReturnListItem } from '../../models/business/purchase-returns/purchase-return-list-item.model';
import { PurchaseReturn } from '../../models/business/purchase-returns/purchase-return.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class PurchaseReturnsService extends BaseSingletonService {

    private settings: {
        service: {
            getPurchaseReturn: string;
            getPurchaseReturnList: string;
            createPurchaseReturn: string;
            updatePurchaseReturn: string;
            deletePurchaseReturn: string;
        }
    };

    constructor(private http: HttpService) {
        super('PurchaseReturnsService');
        this.settings = {
            service: {
                getPurchaseReturn: '/purchases-aggregation/v1/returns/{0}',
                getPurchaseReturnList: '/purchases-aggregation/v1/returns?&sortField={0}&sortOrder={1}&pageIndex={2}&pageSize={3}&term={4}',
                createPurchaseReturn: '/purchases-aggregation/v1/returns',
                updatePurchaseReturn: '/purchases-aggregation/v1/returns/{0}',
                deletePurchaseReturn: '/purchases/v1/returns/{0}'
            }
        };
    }

    public async getPurchaseReturn(uid: string): Promise<PurchaseReturn> {
        return this.http.get<{ data: PurchaseReturn }>(String.format(this.settings.service.getPurchaseReturn, uid))
            .then(res => res.data);
    }

    public async getPurchaseReturnList(filter: PurchaseReturnListGetFilter): Promise<PaginationResultWithAmount<PurchaseReturnListItem>> {
        return this.http.get<PaginationResultWithAmount<PurchaseReturnListItem>>(String.format(this.settings.service.getPurchaseReturnList, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createPurchaseReturn(request: PurchaseReturnCreateOrUpdateRequest): Promise<PurchaseReturn> {
        return this.http.post<{ data: PurchaseReturn }>(String.format(this.settings.service.createPurchaseReturn), request)
            .then(res => res.data);
    }

    public async updatePurchaseReturn(uid: string, request: PurchaseReturnCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updatePurchaseReturn, uid), request);
    }

    public async deletePurchaseReturn(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePurchaseReturn, uid));
    }
}
