import { Brand } from '../../models/business/brands/brand.model';
import { BrandsListFilterData } from '../../models/filters/brands-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class BrandsSearchAction {
    static readonly type = '[BrandsSearch Page] GetBrandsSearch';

    constructor(public payload: BrandsListFilterData) { }
}

export class BrandsSearchSuccessAction {
    static readonly type = '[BrandsSearch API] GetBrandsSearch Success';

    constructor(public payload: PaginationResult<Brand>) { }
}

export class BrandsSearchFailAction {
    static readonly type = '[BrandsSearch API] GetBrandsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandsSearchResetAction {
    static readonly type = '[BrandsSearch Page] GetBrandsSearch Reset';
}
