import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ContragentListItem } from '../../../models/business/contragents/contragent-list-item.model';
import { ContragentSortingFieldsEnum } from '../../../models/business/contragents/contragent-sorting-fields.enum';
import { ContragentsListFilterData } from '../../../models/filters/contragents-list-filter.model';

import { ExgListParams } from '../../../exg-list-params.config';

@Component({
    selector: 'contragent-list',
    templateUrl: './contragent-list.component.html',
    styleUrls: ['./contragent-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContragentListComponent implements OnChanges {

    @Input() contragents: ContragentListItem[];
    @Input() allContragentsLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: ContragentsListFilterData;
    @Input() enableAddLine = false;
    @Input() ignoreRows: ContragentSortingFieldsEnum[] = [];
    @Input() shortList = false;

    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openContragent = new EventEmitter<string>();
    @Output() readonly sortingChange = new EventEmitter<ContragentsListFilterData>();
    @Output() readonly addBtnClick = new EventEmitter<void>();

    public listConfig = ExgListParams.contragentList();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.ignoreRows && this.ignoreRows && this.ignoreRows.length > 0) {
            this.listConfig = ExgListParams.contragentList(this.ignoreRows);
        }
    }

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onSortingChange($event: ContragentsListFilterData) {
        this.sortingChange.emit($event);
    }

    public trackByContragents(_, item: ContragentListItem) {
        return item.uid;
    }

    public onOpenContragent(uid: string) {
        this.openContragent.emit(uid);
    }
}
