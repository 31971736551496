import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { InventoriesService } from '../../services/business/inventories.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { InventoryCreateAction, InventoryCreateFailAction, InventoryCreateResetAction, InventoryCreateSuccessAction } from './inventory-create.actions';
import { Inventory } from '../../models/business/inventories/inventory.model';

export interface InventoryCreateStateModel {
    pending: boolean;
    created: Inventory;
    error: ErrorObject;
}

@State<InventoryCreateStateModel>({
    name: 'inventoryCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class InventoryCreateState {
    constructor(private inventoriesService: InventoriesService) { }

    @Action(InventoryCreateAction) async inventoryCreate(ctx: StateContext<InventoryCreateStateModel>, action: InventoryCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.inventoriesService.createInventory({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new InventoryCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new InventoryCreateFailAction(err)), 0));
    }

    @Action(InventoryCreateSuccessAction) inventoryCreateSuccess(ctx: StateContext<InventoryCreateStateModel>, action: InventoryCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(InventoryCreateFailAction) inventoryCreateFail(ctx: StateContext<InventoryCreateStateModel>, action: InventoryCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(InventoryCreateResetAction) inventoryCreateReset(ctx: StateContext<InventoryCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
