import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { TaskCreateOrUpdateRequest } from '../../models/business/tasks/task-create-or-update.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TaskUpdateAction, TaskUpdateResetAction } from './task-update.actions';

@Injectable({
    providedIn: 'root'
})
export class TaskUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TaskUpdateDispatchers');
    }

    public dispatchTaskUpdateAction(uid: string, request: TaskCreateOrUpdateRequest) {
        this.store.dispatch(new TaskUpdateAction({ uid, request }));
    }

    public dispatchTaskUpdateResetAction() {
        this.store.dispatch(new TaskUpdateResetAction());
    }
}
