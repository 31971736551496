import { CompaniesListFilterData } from '../../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../../models/business/companies/company-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CompaniesUsersAction {
    static readonly type = '[CompaniesUsers Page] GetCompaniesUsers';

    constructor(public payload: CompaniesListFilterData) { }
}

export class CompaniesUsersSuccessAction {
    static readonly type = '[CompaniesUsers API] GetCompaniesUsers Success';

    constructor(public payload: PaginationResult<CompanyListItem>) { }
}

export class CompaniesUsersFailAction {
    static readonly type = '[CompaniesUsers API] GetCompaniesUsers Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompaniesUsersResetAction {
    static readonly type = '[CompaniesUsers Page] GetCompaniesUsers Reset';
}
