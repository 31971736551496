import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export class TechnologicalMapsAction {
    static readonly type = '[TechnologicalMaps Page] GetTechnologicalMaps';

    constructor(public payload: PaginationRequest) { }
}

export class TechnologicalMapsSuccessAction {
    static readonly type = '[TechnologicalMaps API] GetTechnologicalMaps Success';

    constructor(public payload: { list: PaginationResult<TechnologicalMapListItemAggregation>, pagination: PaginationRequest }) { }
}

export class TechnologicalMapsFailAction {
    static readonly type = '[TechnologicalMaps API] GetTechnologicalMaps Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapsUpdateItemAction {
    static readonly type = '[TechnologicalMaps] Manually Updating';

    constructor(public payload: TechnologicalMapListItemAggregation) { }
}

export class TechnologicalMapsResetAction {
    static readonly type = '[TechnologicalMaps Page] GetTechnologicalMaps Reset';
}

export class TechnologicalMapsSetFilterAction {
    static readonly type = '[TechnologicalMaps API] SetFilter';

    constructor(public payload: TechnologicalMapListGetFilterData) { }
}
