import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DecompositionsService } from '../../services/decompositions.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DecompositionDeleteAction, DecompositionDeleteFailAction, DecompositionDeleteResetAction, DecompositionDeleteSuccessAction } from './decomposition-delete.actions';

export interface DecompositionDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<DecompositionDeleteStateModel>({
    name: 'decompositionDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class DecompositionDeleteState {
    constructor(private decompositionsService: DecompositionsService) { }

    @Action(DecompositionDeleteAction) async decompositionDelete(ctx: StateContext<DecompositionDeleteStateModel>, action: DecompositionDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.decompositionsService.deleteDecomposition(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new DecompositionDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new DecompositionDeleteFailAction(error)), 0));
    }

    @Action(DecompositionDeleteSuccessAction) decompositionDeleteSuccess(ctx: StateContext<DecompositionDeleteStateModel>, _: DecompositionDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(DecompositionDeleteFailAction) decompositionDeleteFail(ctx: StateContext<DecompositionDeleteStateModel>, action: DecompositionDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DecompositionDeleteResetAction) decompositionDeleteReset(ctx: StateContext<DecompositionDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
