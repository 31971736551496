import { Brand } from '../../models/business/brands/brand.model';
import { BrandsListFilterData } from '../../models/filters/brands-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class BrandsAction {
    static readonly type = '[Brands Page] GetBrands';

    constructor(public payload: PaginationRequest) { }
}

export class BrandsSuccessAction {
    static readonly type = '[Brands API] GetBrands Success';

    constructor(public payload: { list: PaginationResult<Brand>, pagination: PaginationRequest }) { }
}

export class BrandsFailAction {
    static readonly type = '[Brands API] GetBrands Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandsResetAction {
    static readonly type = '[Brands Page] GetBrands Reset';
}

export class BrandsSetFilterAction {
    static readonly type = '[Brands API] SetFilter';

    constructor(public payload: BrandsListFilterData) { }
}
