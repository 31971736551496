export enum OrderSortingFieldsEnum {
    InternalNumber = 'InternalNumber',
    ExternalNumber = 'ExternalNumber',
    Company = 'Company',
    Pos = 'Pos',
    ResponsibleUsers = 'ResponsibleUsers',
    ShippingDate = 'ShippingDate',
    TotalAmount = 'TotalAmount',
    Status = 'Status',
    Number = 'Number',
    CreateDate = 'CreateDate',
    Contragent = 'Contragent',
    Organization = 'Organization',
    Amount = 'Amount',
    InvoicesAmount = 'InvoicesAmount',
    PaidAmount = 'PaidAmount',
    DispatchedAmount = 'DispatchedAmount',
    ReservedAmount = 'ReservedAmount',
}
