import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';
import { LanguageService } from '../../../../burns-ui-framework/shared/services/common/language-service.service';

import { Brand } from '../../models/business/brands/brand.model';
import { Country } from '../../models/business/geocoding/country.model';
import { ImageWithPreviewUrlResponse } from '../../../../burns-ui-framework/shared/services/common/image-with-preview-url-response.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ProductCategoriesListFilter } from '../../models/filters/product-categories-list-filter.model';
import { ProductCategoryCreateOrUpdateRequest } from '../../models/business/catalog/product-category-create-or-update-request.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';
import { ProductCreateOrUpdateRequest } from '../../models/business/catalog/product-create-or-update.model';
import { ProductImageUpdateRequest } from '../../models/business/catalog/product-image-update.model';
import { Product } from '../../models/business/catalog/product.model';
import { ProductsListFilter } from '../../models/filters/products-list-filter.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CatalogService extends BaseSingletonService {

    private settings: {
        service: {
            getCategory: string;
            getCategories: string;
            createCategory: string;
            updateCategory: string;
            deleteCategory: string;

            getProduct: string;
            getProducts: string;
            getProductsCountries: string;
            getProductsBrands: string;
            createProduct: string;
            updateProduct: string;
            deleteProduct: string;
            updateProductPhoto: string;
            deleteProductPhoto: string;
        }
    };

    constructor(private http: HttpService, private languageService: LanguageService) {
        super('CatalogService');
        this.settings = {
            service: {
                getCategory: '/nomenclature/v1/categories/{0}',
                getCategories: '/nomenclature/v1/categories?term={0}&parentUid={1}&isPublic={2}&isArchived={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                createCategory: '/nomenclature/v1/categories',
                updateCategory: '/nomenclature/v1/categories/{0}',
                deleteCategory: '/nomenclature/v1/categories/{0}',

                getProduct: '/nomenclature/v1/products/{0}',
                getProducts: '/nomenclature/v1/products?term={0}&categoryUid={1}&brandUids={2}&countryCodes={3}&isArchived={4}&sortField={5}&sortOrder={6}&pageIndex={7}&pageSize={8}',
                getProductsCountries: '/nomenclature/v1/products/countries',
                getProductsBrands: '/nomenclature/v1/products/brands',
                createProduct: '/nomenclature/v1/products',
                updateProduct: '/nomenclature/v1/products/{0}',
                deleteProduct: '/nomenclature/v1/products/{0}',
                updateProductPhoto: '/nomenclature/v1/products/{0}/image',
                deleteProductPhoto: '/nomenclature/v1/products/{0}/image'
            }
        };
    }

    public async getCategory(uid: string): Promise<ProductCategory> {
        return this.http.get<{ data: ProductCategory }>(String.format(this.settings.service.getCategory, uid))
            .then(res => res.data);
    }

    public async getCategories(filter: ProductCategoriesListFilter): Promise<PaginationResult<ProductCategory>> {
        return this.http.get<PaginationResult<ProductCategory>>(String.format(this.settings.service.getCategories, filter.term, filter.parentUid, filter.isPublic, filter.isArchived, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createCategory(request: ProductCategoryCreateOrUpdateRequest): Promise<ProductCategory> {
        return this.http.post<{ data: ProductCategory }>(String.format(this.settings.service.createCategory), request)
            .then(res => res.data);
    }

    public async updateCategory(uid: string, request: ProductCategoryCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateCategory, uid), request);
    }

    public async deleteCategory(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteCategory, uid));
    }

    public async getProduct(uid: string): Promise<Product> {
        return this.http.get<{ data: Product }>(String.format(this.settings.service.getProduct, uid))
            .then(res => res.data);
    }

    public async getProducts(filter: ProductsListFilter): Promise<PaginationResult<Product>> {
        const brands = (filter.brandUids || []).join(',');
        const countryCodes = (filter.countryCodes || []).join(',');
        const categoryUids = filter.categoryUids?.join(',');
        return this.http.get<PaginationResult<Product>>(String.format(this.settings.service.getProducts, filter.term, categoryUids, brands, countryCodes, filter.isArchived, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getProductsCountries(): Promise<Country[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: Country[] }>(String.format(this.settings.service.getProductsCountries, culture))
            .then(res => res.list);
    }

    public async getProductsBrands(): Promise<Brand[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: Brand[] }>(String.format(this.settings.service.getProductsBrands, culture))
            .then(res => res.list);
    }

    public async createProduct(request: ProductCreateOrUpdateRequest): Promise<Product> {
        return this.http.post<{ data: Product }>(String.format(this.settings.service.createProduct), request)
            .then(res => res.data);
    }

    public async updateProduct(uid: string, request: ProductCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateProduct, uid), request);
    }

    public async deleteProduct(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteProduct, uid));
    }

    public async updateProductPhoto(uid: string, request: ProductImageUpdateRequest): Promise<ImageWithPreviewUrlResponse> {
        return this.http.put<{ data: ImageWithPreviewUrlResponse }>(String.format(this.settings.service.updateProductPhoto, uid), request)
            .then(res => res.data);
    }

    public async deleteProductPhoto(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteProductPhoto, uid));
    }
}
