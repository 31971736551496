import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { ProductCategoriesListFilterData } from '../../models/filters/product-categories-list-filter.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

export class CategoriesAction {
    static readonly type = '[Categories Page] GetCategories';

    constructor(public payload: PaginationRequest) { }
}

export class CategoriesSuccessAction {
    static readonly type = '[Categories API] GetCategories Success';

    constructor(public payload: { list: PaginationResult<ProductCategory>, pagination: PaginationRequest }) { }
}

export class CategoriesFailAction {
    static readonly type = '[Categories API] GetCategories Fail';

    constructor(public payload: ErrorObject) { }
}

export class CategoriesResetAction {
    static readonly type = '[Categories Page] GetCategories Reset';
}

export class CategoriesSetFilterAction {
    static readonly type = '[Categories API] SetFilter';

    constructor(public payload: ProductCategoriesListFilterData) { }
}

export class CategoryCreateAction {
    static readonly type = '[Categories API] Category Create';

    constructor(public payload: ProductCategory) { }
}
