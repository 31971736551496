export enum YandexPlaceInputComponentType {
    /// <summary>
    /// Country
    /// </summary>
    Country = 1,

    /// <summary>
    /// Province
    /// </summary>
    Province = 3,

    /// <summary>
    /// Area
    /// </summary>
    Area = 4,

    /// <summary>
    /// City
    /// </summary>
    City = 5,

    /// <summary>
    /// District
    /// </summary>
    District = 6,

    /// <summary>
    /// Street
    /// </summary>
    Street = 7,

    /// <summary>
    /// Building
    /// </summary>
    Building = 8,

    /// <summary>
    /// Unknow
    /// </summary>
    Unknown = 1000
}
