import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CompaniesService } from '../../services/business/companies.service';
import { ContactsService } from '../../services/business/contacts.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { MotivationBonusesProductsAction, MotivationBonusesProductsFailAction, MotivationBonusesProductsResetAction, MotivationBonusesProductsSetFilterAction, MotivationBonusesProductsSuccessAction } from './motivation-bonuses-products.actions';

import { Company } from '../../models/business/companies/company.model';
import { Contact } from '../../models/business/contacts/contact.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonusProduct } from '../../models/business/contacts/motivation-bonus-product.model';
import { MotivationBonusesProductsListGetFilter, MotivationBonusesProductsListGetFilterData } from '../../models/filters/motivation-bonuses-products-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface MotivationBonusesProductsStateModel extends PaginationStateModel<MotivationBonusProduct> {
    uids: string[];
    entities: { [uid: string]: MotivationBonusProduct };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: MotivationBonusesProductsListGetFilterData;
    total: number;
    company: Company;
    contact: Contact;
}

@State<MotivationBonusesProductsStateModel>({
    name: 'motivationBonusesProducts',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {}, company: null, contact: null }
})
@Injectable()
export class MotivationBonusesProductsState extends PaginationStateBase {
    constructor(private contactsService: ContactsService, private companiesService: CompaniesService) {
        super();
    }

    @Action([MotivationBonusesProductsAction]) contactsGet(ctx: StateContext<MotivationBonusesProductsStateModel>, action: MotivationBonusesProductsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new MotivationBonusesProductsListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contactsService.getMotivationProductsList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new MotivationBonusesProductsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusesProductsFailAction(err)), 0));
    }

    @Action(MotivationBonusesProductsSuccessAction) contactsGetSuccess(ctx: StateContext<MotivationBonusesProductsStateModel>, action: MotivationBonusesProductsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(MotivationBonusesProductsFailAction) contactsGetFail(ctx: StateContext<MotivationBonusesProductsStateModel>, action: MotivationBonusesProductsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(MotivationBonusesProductsResetAction) contactsGetReset(ctx: StateContext<MotivationBonusesProductsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {}, company: null, contact: null });
    }

    @Action(MotivationBonusesProductsSetFilterAction) usersSetFilter(ctx: StateContext<MotivationBonusesProductsStateModel>, action: MotivationBonusesProductsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload.filter };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
        }

        const companyPromise = this.companiesService.getCompany(action.payload.uid);
        const contactPromise = this.contactsService.getContact(action.payload.contactUid);
        Promise.all([companyPromise, contactPromise])
            .then((res) => {
                const newState = ctx.getState();
                ctx.setState({ ...newState, company: res[0], contact: res[1] });
                ctx.dispatch(new MotivationBonusesProductsAction(PaginationRequest.ReloadCurrentList));
            })
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusesProductsFailAction(err)), 0));
    }
}
