export class RoutingConfig {
    public static readonly routes = {
        clients: {
            route: 'clients',
            fullUrl: '/clients',
            pos: {
                route: 'pos',
                fullUrl: '/clients/pos',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/pos/edit'
                }
            },
            companies: {
                route: 'companies',
                fullUrl: '/clients/companies',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/companies/edit'
                }
            },
            contacts: {
                route: 'contacts',
                fullUrl: '/clients/contacts',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/contacts/edit'
                },
                motivationBonus: {
                    route: 'motivation-bonus'
                }
            },
            customers: {
                route: 'customers',
                fullUrl: '/clients/customers',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/customers/edit'
                },
                motivationBonus: {
                    route: 'motivation-bonus'
                }
            },
            contragents: {
                route: 'contragents',
                fullUrl: '/clients/contragents',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/contragents/edit'
                },
                contracts: {
                    route: 'contracts'
                },
                requisites: {
                    route: 'requisites'
                }
            },
            motivation: {
                route: 'motivation',
                fullUrl: '/clients/motivation',
                companies: {
                    route: 'company',
                    fullUrl: '/clients/motivation/company',
                    contact: {
                        route: 'contact'
                    }
                }
            },
            organizations: {
                route: 'organizations',
                fullUrl: '/clients/organizations',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/organizations/edit'
                }
            }
        },
        corporation: {
            route: 'corporation',
            fullUrl: '/corporation',
            franchisees: {
                route: 'franchisees',
                fullUrl: '/corporation/franchisees',
                edit: {
                    route: 'edit',
                    fullUrl: '/corporation/franchisees/edit'
                }
            },
            organizations: {
                route: 'organizations',
                fullUrl: '/corporation/organizations',
                edit: {
                    route: 'edit',
                    fullUrl: '/corporation/organizations/edit',
                }
            },
            restaurants: {
                route: 'restaurants',
                fullUrl: '/corporation/restaurants',
                edit: {
                    route: 'edit',
                    fullUrl: '/corporation/restaurants/edit',
                    area: {
                        route: 'area'
                    }
                }
            },
        },
        purchases: {
            route: 'purchases',
            fullUrl: '/purchases',
            orders: {
                route: 'orders',
                fullUrl: '/purchases/orders',
                edit: {
                    route: 'edit',
                    fullUrl: '/purchases/orders/edit'
                }
            },
            supplierInvoices: {
                route: 'supplier-invoices',
                fullUrl: '/purchases/supplier-invoices',
                edit: {
                    route: 'edit',
                    fullUrl: '/purchases/supplier-invoices/edit'
                }
            },
            acceptance: {
                route: 'acceptance',
                fullUrl: '/purchases/acceptance',
                edit: {
                    route: 'edit',
                    fullUrl: '/purchases/acceptance/edit'
                }
            },
            returns: {
                route: 'returns',
                fullUrl: '/purchases/returns',
                edit: {
                    route: 'edit',
                    fullUrl: '/purchases/returns/edit'
                }
            },
            invoices: {
                route: 'invoices',
                fullUrl: '/purchases/invoices',
                edit: {
                    route: 'edit',
                    fullUrl: '/purchases/invoices/edit'
                }
            },
            management: {
                route: 'management',
                fullUrl: '/purchases/management',
                edit: {
                    route: 'edit',
                    fullUrl: '/purchases/management/edit'
                }
            }
        },
        orders: {
            route: 'orders',
            fullUrl: '/orders',
            orders: {
                route: 'orders',
                fullUrl: '/orders/orders',
                edit: {
                    route: 'edit',
                    fullUrl: '/orders/orders/edit'
                }
            },
            loads: {
                route: 'loads',
                fullUrl: '/orders/loads',
                edit: {
                    route: 'edit',
                    fullUrl: '/orders/loads/edit'
                }
            },
            returns: {
                route: 'returns',
                fullUrl: '/orders/returns',
                edit: {
                    route: 'edit',
                    fullUrl: '/orders/returns/edit'
                }
            },
            invoices: {
                route: 'invoices',
                fullUrl: '/orders/invoices',
                edit: {
                    route: 'edit',
                    fullUrl: '/orders/invoices/edit'
                }
            },
            salesFunnel: {
                route: 'sales',
                fullUrl: '/orders/sales',
                edit: {
                    route: 'edit',
                    fullUrl: '/orders/sales/edit'
                }
            },
        },
        warehouse: {
            route: 'warehouse',
            fullUrl: '/warehouse',
            products: {
                route: 'products',
                fullUrl: '/warehouse/products',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/products/edit'
                }
            },
            postings: {
                route: 'postings',
                fullUrl: '/warehouse/postings',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/postings/edit'
                }
            },
            transfers: {
                route: 'transfers',
                fullUrl: '/warehouse/transfers',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/transfers/edit'
                }
            },
            inventories: {
                route: 'inventories',
                fullUrl: '/warehouse/inventories',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/inventories/edit'
                }
            },
            writeoffs: {
                route: 'write-offs',
                fullUrl: '/warehouse/write-offs',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/write-offs/edit'
                }
            },
            priceList: {
                route: 'price-list',
                fullUrl: '/warehouse/price-list',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/price-list/edit'
                }
            }
        },
        settings: {
            route: 'settings',
            fullUrl: '/settings',
            product: {
                route: 'product',
                fullUrl: '/settings/product',
                allergens: {
                    route: 'allergens',
                    fullUrl: '/settings/product/allergens',
                },
                groups: {
                    route: 'groups',
                    fullUrl: '/settings/product/groups',
                },
                productType: {
                    route: 'product-type',
                    fullUrl: '/settings/product/product-type',
                },
                utins: {
                    route: 'units',
                    fullUrl: '/settings/product/units',
                }
            },
            warehouse: {
                route: 'warehouse',
                fullUrl: '/settings/warehouse',
            },
            priceType: {
                route: 'price-type',
                fullUrl: '/settings/price-type',
            },
            taxes: {
                route: 'taxes',
                fullUrl: '/settings/taxes',
                vat: {
                    route: 'vat',
                    fullUrl: '/settings/taxes/vat'
                },
                incomeTax: {
                    route: 'income-tax',
                    fullUrl: '/settings/taxes/income-tax'
                }
            }
        },
        users: {
            route: 'users',
            fullUrl: '/users',
            edit: {
                route: 'edit',
                fullUrl: '/users/edit'
            }
        }
    };
}
