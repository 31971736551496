import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WriteOff } from '../../models/business/write-offs/write-off.model';

export class WriteOffGetAction {
    static readonly type = '[WriteOff Page] GetWriteOff Get';

    constructor(public payload: string) { }
}

export class WriteOffGetSuccessAction {
    static readonly type = '[WriteOff API] GetWriteOff Success';

    constructor(public payload: WriteOff) { }
}

export class WriteOffFailAction {
    static readonly type = '[WriteOff API] GetWriteOff Fail';

    constructor(public payload: ErrorObject) { }
}

export class WriteOffResetAction {
    static readonly type = '[WriteOff Page] GetWriteOff Reset';
}
