import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContragentListItem } from '../../../../models/business/contragents/contragent-list-item.model';
import { ContragentSortingFieldsEnum } from '../../../../models/business/contragents/contragent-sorting-fields.enum';
import { ContragentsListFilterData } from '../../../../models/filters/contragents-list-filter.model';

@Component({
    selector: 'contragent-list-item',
    templateUrl: './contragent-list-item.component.html',
    styleUrls: ['./contragent-list-item.component.scss', '../contragent-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContragentListItemComponent {

    @Input() contragent: ContragentListItem;
    @Input() filter: ContragentsListFilterData;
    @Input() listConfig: any;

    @Output() readonly openContragent = new EventEmitter<string>();

    public contragentSortingFields = ContragentSortingFieldsEnum;

    public onOpenContragentClick() {
        this.openContragent.emit(this.contragent.uid);
    }
}
