import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { UserForTyping } from '../../models/business/user/user-for-typing.model';

export class UserSearchAction {
    static readonly type = '[UserSearch API] GetUserSearch';
}

export class UserSearchSuccessAction {
    static readonly type = '[UserSearch API] GetUserSearch Success';

    constructor(public payload: PaginationResult<UserForTyping>) { }
}

export class UserSearchFailAction {
    static readonly type = '[UserSearch API] GetUserSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class UserSearchResetAction {
    static readonly type = '[UserSearch API] GetUserSearch Reset';
}
