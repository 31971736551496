export class DocumentModel {
    public uid: string;

    public noteUid: string;

    public fileUrl: string;

    public fileSize: number;

    public createDate: number;
}
