import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationRequisitesService } from '../../services/business/corporation-requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CorporationRequisiteDeleteAction, CorporationRequisiteDeleteFailAction, CorporationRequisiteDeleteResetAction, CorporationRequisiteDeleteSuccessAction } from './corporation-requisite-delete.actions';

export interface CorporationRequisiteDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<CorporationRequisiteDeleteStateModel>({
    name: 'corporationRequisiteDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class CorporationRequisiteDeleteState {
    constructor(private corporationRequisitesService: CorporationRequisitesService) { }

    @Action(CorporationRequisiteDeleteAction) async corporationCorporationCorporationRequisiteDelete(ctx: StateContext<CorporationRequisiteDeleteStateModel>, action: CorporationRequisiteDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.corporationRequisitesService.deleteRequisite(action.payload.uid, action.payload.organizationUid)
            .then(() => setTimeout(() => ctx.dispatch(new CorporationRequisiteDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new CorporationRequisiteDeleteFailAction(error)), 0));
    }

    @Action(CorporationRequisiteDeleteSuccessAction) corporationCorporationCorporationRequisiteDeleteSuccess(ctx: StateContext<CorporationRequisiteDeleteStateModel>, _: CorporationRequisiteDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(CorporationRequisiteDeleteFailAction) corporationCorporationCorporationRequisiteDeleteFail(ctx: StateContext<CorporationRequisiteDeleteStateModel>, action: CorporationRequisiteDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CorporationRequisiteDeleteResetAction) corporationCorporationCorporationRequisiteDeleteReset(ctx: StateContext<CorporationRequisiteDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
