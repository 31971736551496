import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TasksService } from '../../services/business/tasks.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TaskUpdateAction, TaskUpdateFailAction, TaskUpdateResetAction, TaskUpdateSuccessAction } from './task-update.actions';

export interface TaskUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<TaskUpdateStateModel>({
    name: 'taskUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class TaskUpdateState {
    constructor(private tasksService: TasksService) { }

    @Action(TaskUpdateAction) async taskUpdate(ctx: StateContext<TaskUpdateStateModel>, action: TaskUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.tasksService.updateTask(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TaskUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TaskUpdateFailAction(err)), 0));
    }

    @Action(TaskUpdateSuccessAction) taskUpdateSuccess(ctx: StateContext<TaskUpdateStateModel>, _: TaskUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(TaskUpdateFailAction) taskUpdateFail(ctx: StateContext<TaskUpdateStateModel>, action: TaskUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(TaskUpdateResetAction) taskUpdateReset(ctx: StateContext<TaskUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
