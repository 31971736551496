import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonusesCreateOrUpdateRequest } from '../../models/business/contacts/motivation-bonuses-create-or-update-request.model';

export class MotivationBonusUpdateAction {
    static readonly type = '[MotivationBonus Page] UpdateMotivationBonus';

    constructor(public payload: { contactUid: string, motivationUid: string, request: MotivationBonusesCreateOrUpdateRequest }) { }
}

export class MotivationBonusUpdateSuccessAction {
    static readonly type = '[MotivationBonus API] UpdateMotivationBonus Success';

    constructor(public payload: { contactUid: string, motivationUid: string, request: MotivationBonusesCreateOrUpdateRequest }) { }
}

export class MotivationBonusUpdateFailAction {
    static readonly type = '[MotivationBonus API] UpdateMotivationBonus Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusUpdateResetAction {
    static readonly type = '[MotivationBonus Page] UpdateMotivationBonus Reset';
}
