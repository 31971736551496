import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';

export class RestaurantProductListGetFilter extends SearchPaginationFilter {
    public organizationPosUid?: string;
    public categoryUids?: string[];
    public brandUids?: string[];
    public typeUids?: string[];
    public groupUids?: string[];
    public countryCodes?: string[];
    public isPublic?: boolean;
    public isArchived?: boolean;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: RestaurantProductListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.organizationPosUid = (args && args.filterData) ? args.filterData.organizationPosUid : null;
        this.categoryUids = (args && args.filterData) ? args.filterData.categoryUids : null;
        this.brandUids = (args && args.filterData) ? args.filterData.brandUids : null;
        this.typeUids = (args && args.filterData) ? args.filterData.typeUids : null;
        this.countryCodes = (args && args.filterData) ? args.filterData.countryCodes : null;
        this.groupUids = (args && args.filterData) ? args.filterData.groupUids : null;
        this.isPublic = (args && args.filterData) ? args.filterData.isPublic : null;
        this.isArchived = (args && args.filterData) ? args.filterData.isArchived : null;
    }
}

export class RestaurantProductListGetFilterData extends SearchFilterData {
    public organizationPosUid?: string;
    public categoryUids?: string[];
    public brandUids?: string[];
    public typeUids?: string[];
    public groupUids?: string[];
    public countryCodes?: string[];
    public isPublic?: boolean;
    public isArchived?: boolean;
    public pageSize?: number;
}
