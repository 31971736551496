
import { AreaRestrictionListItem } from '../../models/business/organization-pos/area-restriction-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosAreaRestrictionsAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaRestrictions';

    constructor(public payload: { organizationPosUid: string }) { }
}

export class OrganizationPosAreaRestrictionsSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaRestrictions Success';

    constructor(public payload: { list: AreaRestrictionListItem[] }) { }
}

export class OrganizationPosAreaRestrictionsFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaRestrictions Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosAreaRestrictionsResetAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaRestrictions Reset';
}

