import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { BrandsService } from '../../services/business/brands.service';

import { Brand } from '../../models/business/brands/brand.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { BrandCreateAction, BrandCreateFailAction, BrandCreateResetAction, BrandCreateSuccessAction } from './brand-create.actions';

export interface BrandCreateStateModel {
    pending: boolean;
    created: Brand;
    error: ErrorObject;
}

@State<BrandCreateStateModel>({
    name: 'brandCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class BrandCreateState {
    constructor(private brandsService: BrandsService) { }

    @Action(BrandCreateAction) async brandCreate(ctx: StateContext<BrandCreateStateModel>, action: BrandCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.brandsService.createBrand(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new BrandCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new BrandCreateFailAction(err)), 0));
    }

    @Action(BrandCreateSuccessAction) brandCreateSuccess(ctx: StateContext<BrandCreateStateModel>, action: BrandCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(BrandCreateFailAction) brandCreateFail(ctx: StateContext<BrandCreateStateModel>, action: BrandCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(BrandCreateResetAction) brandCreateReset(ctx: StateContext<BrandCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
