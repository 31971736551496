import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PricelistListGetFilterData } from '../../models/filters/pricelist-list-get-filter.model';
import { PricelistListItem } from '../../models/business/pricelist/pricelist-list-item.model';

export class PricelistListAction {
    static readonly type = '[PricelistList Page] GetPricelistList';

    constructor(public payload: PaginationRequest) { }
}

export class PricelistListSuccessAction {
    static readonly type = '[PricelistList API] GetPricelistList Success';

    constructor(public payload: { list: PaginationResult<PricelistListItem>, pagination: PaginationRequest }) { }
}

export class PricelistListFailAction {
    static readonly type = '[PricelistList API] GetPricelistList Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistListResetAction {
    static readonly type = '[PricelistList Page] GetPricelistList Reset';
}

export class PricelistListSetFilterAction {
    static readonly type = '[PricelistList API] SetFilter';

    constructor(public payload: PricelistListGetFilterData) { }
}
