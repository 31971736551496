import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { OrderSortingFieldsEnum } from '../../../../models/business/orders/order-sorting-fields.enum';
import { OrdersListFilterData } from '../../../../models/filters/orders-list-filter.model';
import { SortOrder } from '../../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'orders-header',
    templateUrl: './orders-header.component.html',
    styleUrls: ['./orders-header.component.scss', '../orders-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHeaderComponent {

    @Input() filter: OrdersListFilterData;
    @Input() listConfig: any;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public orderSortingFields = OrderSortingFieldsEnum;

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.sortingChange.emit($event);
    }
}
