import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CompaniesService } from '../../services/business/companies.service';

import { Company } from '../../models/business/companies/company.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CustomerCompanyFailAction, CustomerCompanyGetAction, CustomerCompanyGetSuccessAction, CustomerCompanyResetAction } from './customer-company.actions';

export interface CustomerCompanyStateModel {
    entity: Company;
    pending: boolean;
    error: ErrorObject;
}

@State<CustomerCompanyStateModel>({
    name: 'customerCompany',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CustomerCompanyState {
    constructor(private companiesService: CompaniesService) { }

    @Action(CustomerCompanyGetAction) companyGet(ctx: StateContext<CustomerCompanyStateModel>, action: CustomerCompanyGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.companiesService.getCompany(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CustomerCompanyGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomerCompanyFailAction(err)), 0));
    }

    @Action(CustomerCompanyGetSuccessAction) companyGetSuccess(ctx: StateContext<CustomerCompanyStateModel>, action: CustomerCompanyGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CustomerCompanyFailAction) companyGetFail(ctx: StateContext<CustomerCompanyStateModel>, action: CustomerCompanyFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomerCompanyResetAction) companyGetReset(ctx: StateContext<CustomerCompanyStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
