import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrdersListFilterData } from '../../models/filters/orders-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CompanyOrdersAction {
    static readonly type = '[CompanyOrders Page] GetOrders';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyOrdersSuccessAction {
    static readonly type = '[CompanyOrders API] GetOrders Success';

    constructor(public payload: { list: PaginationResult<OrderListItem>, pagination: PaginationRequest }) { }
}

export class CompanyOrdersFailAction {
    static readonly type = '[CompanyOrders API] GetOrders Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyOrdersResetAction {
    static readonly type = '[CompanyOrders Page] GetOrders Reset';
}

export class CompanyOrdersSetFilterAction {
    static readonly type = '[CompanyOrders API] SetFilter';

    constructor(public payload: OrdersListFilterData) { }
}
