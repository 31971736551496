import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BvcRightMenuEnum } from './bvc-right-menu.enum';
import { IMenuItem } from '../../../../../burns-ui-framework/shared/components/common/exg-menu/exg-menu.component';

@Component({
    selector: 'bvc-right-menu',
    templateUrl: './bvc-right-menu.component.html',
    styleUrls: ['./bvc-right-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcRightMenuComponent {

    @Input() addBtnTitle = 'Add';
    @Input() hiddenMenuItems: BvcRightMenuEnum[] = [];
    @Input() showAddButton = true;
    @Input() btnMenuStyleType: 'icon' | 'outlined' = 'icon';

    @Output() readonly menuClicked = new EventEmitter<BvcRightMenuEnum>();
    @Output() readonly onAdd = new EventEmitter();

    public menuItems = [
        { id: BvcRightMenuEnum.Download, title: 'Download', color: 'primary', icon: 'export' },
        { id: BvcRightMenuEnum.Print, title: 'Print', color: 'primary', icon: 'print' },
        { id: BvcRightMenuEnum.Send, title: 'Send', color: 'primary', icon: 'mail' },
        { id: BvcRightMenuEnum.Delete, title: 'Delete', color: 'warn', icon: 'delete' }
    ].filter((menu) => !this.hiddenMenuItems.includes(menu.id)) as IMenuItem[];

    public onMenuItemClick($event): void {
        this.menuClicked.emit($event.id);
    }

    public onAddButton() {
        this.onAdd.emit();
    }

}
