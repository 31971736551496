import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosAreasService } from '../../services/business/organization-pos-areas.service';

import { OrganizationPosAreaAction, OrganizationPosAreaFailAction, OrganizationPosAreaFromListAction, OrganizationPosAreaResetAction, OrganizationPosAreaSuccessAction } from './organization-pos-area.actions';

import { Area } from '../../models/business/organization-pos/area.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface OrganizationPosAreaStateModel {
    entity: Area;
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosAreaStateModel>({
    name: 'posArea',
    defaults: {
        pending: false, entity: null, retrieved: false, error: null
    }
})
@Injectable()
export class OrganizationPosAreaState {
    constructor(private orgPosAreasService: OrganizationPosAreasService) { }

    @Action([OrganizationPosAreaAction]) organizationPosesGet(ctx: StateContext<OrganizationPosAreaStateModel>, action: OrganizationPosAreaAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosAreasService.getPosArea(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosAreaSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaFailAction(err)), 0));
    }

    @Action(OrganizationPosAreaFromListAction) organizationPosesGetFromListSuccess(ctx: StateContext<OrganizationPosAreaStateModel>, action: OrganizationPosAreaFromListAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, retrieved: true, error: null });
    }

    @Action(OrganizationPosAreaSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosAreaStateModel>, action: OrganizationPosAreaSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, retrieved: true, error: null });
    }

    @Action(OrganizationPosAreaFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosAreaStateModel>, action: OrganizationPosAreaFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosAreaResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosAreaStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, retrieved: false, error: null });
    }
}
