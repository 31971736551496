<section class="form">
    <div class="header">
        <div class="title-line">{{ 'Performance setup' | translate }}</div>
        <exg-button styleType="custom" color="primary" (btnClick)="onCloseClick()">
            <exg-icon class="close-icon" color="primary" iconName="close"></exg-icon>
        </exg-button>
    </div>

    <form [formGroup]="mainForm" [exgDisabled]="(timeIntervalsPending$ | async) || (timeIntervalsUpdatePending$ | async)" class="form buttons-active" [autocomplete]="true" (submit)="$event.preventDefault(); onSubmit()">
        <perfect-scrollbar class="scroll">
            <div class="box">
                <div class="info-title-line">{{ 'Limit type' | translate }}</div>
                <div class="column">
                    <exg-chips
                        class="time-chips"
                        formControlName="intervalLimitType"
                        [displayValueTemplate]="limitTypesDisplayValueTemplate"
                        [data]="intervalLimitTypeEnum$ | async"></exg-chips>
                    <ng-template #limitTypesDisplayValueTemplate let-val>
                        <div class="limit-type-box">
                            <span class="limit_title">{{ val.text || '' }}</span>
                            <exg-icon class="limit_icon" [iconName]="intervalLimitTypeIcons[val.id]"></exg-icon>
                        </div>
                    </ng-template>

                    <div class="intervals-title-line">{{ 'General indicators' | translate }}</div>

                    <div class="general-indicators">
                        <exg-select class="interval-size" 
                                    [data]="timeIntervalsBase$ | async"
                                    formControlName="intervalSize"
                                    [controlsToValidate]="[intervalSize]"
                                    placeholder="{{'Time interval' | translate}}"></exg-select>

                        <exg-textbox class="interval-amount"
                                    formControlName="intervalAmount"
                                    [controlsToValidate]="[intervalAmount]"
                                    inputType="number"
                                    [inputMask]="{ type:'number', min: 0, scale: 2, max: 99999 }"
                                    placeholder="{{ 'Total amount limit' | translate }}"
                                    [validate]="validate$ | async"></exg-textbox>
                    </div>
    
                    <div class="intervals-title-line">{{ 'Set value for interval ranges' | translate }}</div>

                    <div class="tabs-header">
                        <exg-button *ngFor="let intervalActiveTab of intervalActiveTabEnum$ | async"
                            class="tab_btn"
                            [class.active]="(activeTab$ | async) === intervalActiveTab.id"
                            styleType="custom"
                            (btnClick)="onNextTab(intervalActiveTab.id)">
                            <div class="tab_btn_text">{{ intervalActiveTab.text | translate }}</div>
                        </exg-button>
                    </div>

                    <div class="array-wrapper" formArrayName="intervalsArray">
                        <div class="form-rows" [formGroupName]="activeTab$ | async">
                            <div class="form-row-wrapper" formArrayName="intervals">
                                <div class="row" *ngFor="let product of getControlsForArray(activeTab$ | async).controls; let k = index" [formGroupName]="k">
                                    <exg-timepicker-range class="timepicker"
                                                          formControlName="time"
                                                          [controlsToValidate]="[getControlByIndex(k, 'time'), getControlsForArray(activeTab$ | async)]"
                                                          styleType="common"
                                                          [minRangeSizeMinutes]="intervalSize.value.id"
                                                          placeholder="{{ 'Interval' | translate }}"
                                                          [validate]="validate$ | async"></exg-timepicker-range>

                                    <exg-textbox class="limit"
                                                 formControlName="maxAmount"
                                                 [controlsToValidate]="[getControlByIndex(k, 'maxAmount')]"
                                                 inputType="number"
                                                 [inputMask]="{ type:'number', min: 0, scale: 2, max: 99999 }"
                                                 placeholder="{{ 'Amount limit' | translate }}"
                                                 [validate]="validate$ | async"></exg-textbox>

                                    <exg-button (btnClick)="onDelete(k)" styleType="outlined" class="delete-button">
                                        <exg-icon class="icon_cart" iconName="drop"></exg-icon>
                                    </exg-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="add-container">
                        <exg-button (btnClick)="addRow()" color="primary" styleType="custom">
                            <exg-icon class="add-icon" color="primary" iconName="add"></exg-icon>
                            <div class="btn-title">
                                {{ 'Add interval' | translate }}
                            </div>
                        </exg-button>
                    </div>

                    <div class="form-note">
                        <div class="form-title exg-color-primary">{{ 'Note' | translate }} <exg-icon class="btn-icon tab_btn-icon" color="primary" iconName="information-line"></exg-icon></div>
                        <span class="note-text exg-color-primary">
                            {{'Add multiple time ranges to set different maximum order amounts per interval' | translate}}
                        </span>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>

        <div class="navigation-bottom">
            <div class="btn-box">
                <exg-button class="cancel" styleType="outlined" (btnClick)="onCloseClick()">{{ 'Cancel' | translate }}</exg-button>
                <exg-button class="submit" [disabled]="!(editState$ | async)" type="submit">{{ 'Apply' | translate }}</exg-button>
            </div>
            <bvc-organization-filter-button (selectedItems)="onSelectedRestaurants($event)" [initSelectAll]="false" buttonType="custom">
                <div *ngIf="selectedRestaurants?.length < 1; else selected" class="organization-filter-row">
                    <exg-icon class="add-icon" color="primary" iconName="food-bank"></exg-icon>
                    <div class="exg-color-primary apply-restaurants">
                        {{ 'Apply to the other restaurants' | translate }}
                    </div>
                </div>
                <ng-template #selected>
                    <div class="exg-color-primary selected-organizations">{{ selectedRestaurants[0].name }}</div>
                    <div *ngIf="selectedRestaurants.length > 1" class="exg-color-primary selected-organizations__count">+{{ selectedRestaurants.length - 1 }}</div>
                    <exg-icon class="org-icon" color="primary" iconName="arrowdown"></exg-icon>
                </ng-template>
            </bvc-organization-filter-button>
        </div>
    </form>
</section>
