import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WarehousesProductListItem } from '../../models/business/warehouses/warehouses-product-list-item.model';
import { WarehousesProductsListGetFilterData } from '../../models/filters/warehouses-products-list-get-filter.model';

export class WarehousesProductsAction {
    static readonly type = '[WarehousesProducts Page] GetWarehousesProducts';

    constructor(public payload: WarehousesProductsListGetFilterData) { }
}

export class WarehousesProductsSuccessAction {
    static readonly type = '[WarehousesProducts API] GetWarehousesProducts Success';

    constructor(public payload: WarehousesProductListItem[]) { }
}

export class WarehousesProductsFailAction {
    static readonly type = '[WarehousesProducts API] GetWarehousesProducts Fail';

    constructor(public payload: ErrorObject) { }
}

export class WarehousesProductsResetAction {
    static readonly type = '[WarehousesProducts Page] GetWarehousesProducts Reset';
}
