import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../../evasys/shared/services/business/catalog.service';

import { DCRProduct } from '../../models/business/dcr/dcr-product.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DCRProductAction, DCRProductFailAction, DCRProductResetAction, DCRProductSuccessAction } from './dcr-product.actions';

export interface DCRProductStateModel {
    pending: boolean;
    entity: DCRProduct;
    error: ErrorObject;
}

@State<DCRProductStateModel>({
    name: 'DCRProduct',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class DCRProductState {
    constructor(private catalogService: CatalogService) { }

    @Action(DCRProductAction) async product(ctx: StateContext<DCRProductStateModel>, action: DCRProductAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.catalogService.getProduct(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new DCRProductSuccessAction({ uid: action.payload.uid, lineNumber: action.payload.lineNumber, product: res })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DCRProductFailAction(err)), 0));
    }

    @Action(DCRProductSuccessAction) productSuccess(ctx: StateContext<DCRProductStateModel>, action: DCRProductSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload , error: null });
    }

    @Action(DCRProductFailAction) productFail(ctx: StateContext<DCRProductStateModel>, action: DCRProductFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DCRProductResetAction) productReset(ctx: StateContext<DCRProductStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
