import { Contract } from '../../models/business/contract/contract.model';
import { ContractsListFilterData } from '../../models/filters/contracts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContractsAction {
    static readonly type = '[Contracts Page] GetContracts';

    constructor(public payload: PaginationRequest) { }
}

export class ContractsSuccessAction {
    static readonly type = '[Contracts API] GetContracts Success';

    constructor(public payload: { list: PaginationResult<Contract>, pagination: PaginationRequest }) { }
}

export class ContractsFailAction {
    static readonly type = '[Contracts API] GetContracts Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContractsResetAction {
    static readonly type = '[Contracts Page] GetContracts Reset';
}

export class ContractsSetFilterAction {
    static readonly type = '[Contracts API] SetFilter';

    constructor(public payload: ContractsListFilterData) { }
}
