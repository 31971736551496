import { BankSuggestion } from '../../models/business/contragents/bank-suggestion.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class RequisiteSuggestionGetAction {
    static readonly type = '[RequisiteSuggestion Page] GetRequisiteSuggestion Get';

    constructor(public payload: string) { }
}

export class RequisiteSuggestionGetSuccessAction {
    static readonly type = '[RequisiteSuggestion API] GetRequisiteSuggestion Success';

    constructor(public payload: BankSuggestion[]) { }
}

export class RequisiteSuggestionFailAction {
    static readonly type = '[RequisiteSuggestion API] GetRequisiteSuggestion Fail';

    constructor(public payload: ErrorObject) { }
}

export class RequisiteSuggestionResetAction {
    static readonly type = '[RequisiteSuggestion Page] GetRequisiteSuggestion Reset';
}
