import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationCreateOrUpdateRequest } from '../../models/business/organizations/organization-create-or-update-request.model';
import { Organization } from '../../models/business/organizations/organization.model';

export class OrganizationCreateAction {
    static readonly type = '[Organizations Page] CreateOrganization';

    constructor(public payload: OrganizationCreateOrUpdateRequest) { }
}

export class OrganizationCreateSuccessAction {
    static readonly type = '[Organization API] CreateOrganization Success';

    constructor(public payload: Organization) { }
}

export class OrganizationCreateFailAction {
    static readonly type = '[Organization API] CreateOrganization Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationCreateResetAction {
    static readonly type = '[Organizations Page] CreateOrganization Reset';
}
