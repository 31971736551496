import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersService } from '../../services/business/orders.service';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderDiscountGetFilterData } from '../../models/filters/order-discount-get-filter.model';
import { OrderDiscountListItem } from '../../models/business/orders/order-discount-list-item.model';
import { OrderDiscountsAction, OrderDiscountSetFilterAction, OrderDiscountsFailAction, OrderDiscountsResetAction, OrderDiscountsSuccessAction } from './order-discounts.actions';

export interface OrderDiscountsStateModel {
    entity: OrderDiscountListItem[];
    pending: boolean;
    error: ErrorObject;
    filter: OrderDiscountGetFilterData;
}

@State<OrderDiscountsStateModel>({
    name: 'orderDiscounts',
    defaults: { pending: false, entity: null, error: null, filter: {} }
})
@Injectable()
export class OrderDiscountsState {
    constructor(private ordersService: OrdersService) { }

    @Action(OrderDiscountsAction) orderDiscountGet(ctx: StateContext<OrderDiscountsStateModel>, action: OrderDiscountsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.ordersService.getOrderDiscounts(state.filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrderDiscountsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrderDiscountsFailAction(err)), 0));
    }

    @Action(OrderDiscountsSuccessAction) organizationGetSuccess(ctx: StateContext<OrderDiscountsStateModel>, action: OrderDiscountsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload.list, error: null });
    }

    @Action(OrderDiscountsFailAction) organizationGetFail(ctx: StateContext<OrderDiscountsStateModel>, action: OrderDiscountsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrderDiscountsResetAction) organizationGetReset(ctx: StateContext<OrderDiscountsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null, filter: {} });
    }

    @Action(OrderDiscountSetFilterAction) usersSetFilter(ctx: StateContext<OrderDiscountsStateModel>, action: OrderDiscountSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter });
            setTimeout(() => ctx.dispatch(new OrderDiscountsAction()), 0);
        }
    }
}
