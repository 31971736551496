import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { OrganizationPosesAction, OrganizationPosesResetAction, OrganizationPosesSetFilterAction } from './organization-poses.actions';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPosesDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('OrganizationPosesDispatchers');
    }

    public dispatchOrganizationPosesAction(request: PaginationRequest) {
        this.store.dispatch(new OrganizationPosesAction(request));
    }

    public dispatchOrganizationPosesResetAction() {
        this.store.dispatch(new OrganizationPosesResetAction());
    }

    public dispatchOrganizationPosesSetFilterAction(filterData: OrganizationPosesListGetFilterData) {
        this.store.dispatch(new OrganizationPosesSetFilterAction(filterData));
    }
}
