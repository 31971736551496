import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContragentCreateAction, ContragentCreateFailAction, ContragentCreateResetAction, ContragentCreateSuccessAction } from './contragent-create.actions';
import { Contragent } from '../../models/business/contragents/contragent.model';

export interface ContragentCreateStateModel {
    pending: boolean;
    created: Contragent;
    error: ErrorObject;
}

@State<ContragentCreateStateModel>({
    name: 'contragentCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class ContragentCreateState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(ContragentCreateAction) async contragentCreate(ctx: StateContext<ContragentCreateStateModel>, action: ContragentCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.contragentsService.createContragent({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new ContragentCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContragentCreateFailAction(err)), 0));
    }

    @Action(ContragentCreateSuccessAction) contragentCreateSuccess(ctx: StateContext<ContragentCreateStateModel>, action: ContragentCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ContragentCreateFailAction) contragentCreateFail(ctx: StateContext<ContragentCreateStateModel>, action: ContragentCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ContragentCreateResetAction) contragentCreateReset(ctx: StateContext<ContragentCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
