import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseInvoicesService } from '../../services/business/purchase-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PurchaseInvoiceCreateAction, PurchaseInvoiceCreateFailAction, PurchaseInvoiceCreateResetAction, PurchaseInvoiceCreateSuccessAction } from './purchase-invoice-create.actions';
import { PurchaseInvoice } from '../../models/business/purchase-invoices/purchase-invoice.model';

export interface PurchaseInvoiceCreateStateModel {
    pending: boolean;
    created: PurchaseInvoice;
    error: ErrorObject;
}

@State<PurchaseInvoiceCreateStateModel>({
    name: 'purchaseInvoiceCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PurchaseInvoiceCreateState {
    constructor(private purchaseInvoicesService: PurchaseInvoicesService) { }

    @Action(PurchaseInvoiceCreateAction) async purchaseInvoiceCreate(ctx: StateContext<PurchaseInvoiceCreateStateModel>, action: PurchaseInvoiceCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.purchaseInvoicesService.createPurchaseInvoice({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PurchaseInvoiceCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseInvoiceCreateFailAction(err)), 0));
    }

    @Action(PurchaseInvoiceCreateSuccessAction) purchaseInvoiceCreateSuccess(ctx: StateContext<PurchaseInvoiceCreateStateModel>, action: PurchaseInvoiceCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PurchaseInvoiceCreateFailAction) purchaseInvoiceCreateFail(ctx: StateContext<PurchaseInvoiceCreateStateModel>, action: PurchaseInvoiceCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PurchaseInvoiceCreateResetAction) purchaseInvoiceCreateReset(ctx: StateContext<PurchaseInvoiceCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
