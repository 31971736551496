import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DocumentModel } from '../../../../models/business/feed/document.model';
import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Feed } from '../../../../models/business/feed/feed.model';
import { TaskStatus } from '../../../../models/business/tasks/task-status.enum';

import { ExgParamsConfig } from '../../../../exg-params.config';

@Component({
    selector: 'bvc-clients-activity-task-call',
    templateUrl: './clients-activity-task-call.component.html',
    styleUrls: ['./clients-activity-task-call.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityTaskCallComponent implements OnChanges {

    @Input() feedItem: Feed;
    @Input() error: ErrorObject;

    public emptyAvatar = ExgParamsConfig.logos.emptyUserAvatar;
    public taskStatusEnum = TaskStatus;

    public callDuration: string;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.feedItem && this.feedItem) {
            this.callDuration = `${(this.feedItem?.details?.callDuration / 60).toFixed(0)}:${(this.feedItem?.details?.callDuration - ((this.feedItem?.details?.callDuration / 60) * 60)).toFixed(0)}`;
        }
    }

    public trackByDocuments(_, item: DocumentModel) {
        return item.uid;
    }
}
