import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

import { CategoryAction, CategoryFailAction, CategoryResetAction, CategorySuccessAction } from './category.actions';

export interface CategoryStateModel {
    pending: boolean;
    entity: ProductCategory;
    error: ErrorObject;
}

@State<CategoryStateModel>({
    name: 'category',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CategoryState {
    constructor(private categorysService: CatalogService) { }

    @Action(CategoryAction) async category(ctx: StateContext<CategoryStateModel>, action: CategoryAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.categorysService.getCategory(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new CategorySuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CategoryFailAction(err)), 0));
    }

    @Action(CategorySuccessAction) categorySuccess(ctx: StateContext<CategoryStateModel>, action: CategorySuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CategoryFailAction) categoryFail(ctx: StateContext<CategoryStateModel>, action: CategoryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CategoryResetAction) categoryReset(ctx: StateContext<CategoryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
