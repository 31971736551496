import { BillingTransactionStatus } from '../business/billing-transactions/billing-transaction-status.enum';
import { BillingTransactionType } from '../business/billing-transactions/billing-transaction-type.enum';
import { PaymentType } from '../business/billing-transactions/payment-type.enum';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class BillingTransactionsFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public dateFrom?: number;
    public dateTo?: number;
    public statuses?: BillingTransactionStatus[];
    public paymentTypes?: PaymentType[];
    public transactionTypes?: BillingTransactionType[];

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: BillingTransactionsFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'CreateDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Desc;
        this.dateFrom = (args && args.filterData) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData) ? args.filterData.dateTo : null;
        this.statuses = (args && args.filterData) ? args.filterData.statuses : null;
        this.paymentTypes = (args && args.filterData) ? args.filterData.paymentTypes : null;
        this.transactionTypes = (args && args.filterData) ? args.filterData.transactionTypes : null;
    }
}

export class BillingTransactionsFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public pageSize?: number;
    public dateFrom?: number;
    public dateTo?: number;
    public statuses?: BillingTransactionStatus[];
    public paymentTypes?: PaymentType[];
    public transactionTypes?: BillingTransactionType[];
}
