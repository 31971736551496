import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WarehouseListItem } from '../../models/business/warehouses/warehouse-list-item.model';
import { WarehousesListFilterData } from '../../models/filters/warehouses-list-filter.model';

export class WarehousesSearchAction {
    static readonly type = '[WarehousesSearch Page] GetWarehousesSearch';

    constructor(public payload: WarehousesListFilterData) { }
}

export class WarehousesSearchSuccessAction {
    static readonly type = '[WarehousesSearch API] GetWarehousesSearch Success';

    constructor(public payload: PaginationResult<WarehouseListItem>) { }
}

export class WarehousesSearchFailAction {
    static readonly type = '[WarehousesSearch API] GetWarehousesSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class WarehousesSearchResetAction {
    static readonly type = '[WarehousesSearch Page] GetWarehousesSearch Reset';
}
