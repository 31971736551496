import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IntervalsService } from '../../services/business/intervals.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosSettings } from '../../models/business/intervals/organization-pos-settings.model';

import { TimeIntervalsUpdateSuccessAction } from './time-intervals-update.actions';
import { TimeIntervalsFailAction, TimeIntervalsGetAction, TimeIntervalsGetSuccessAction, TimeIntervalsResetAction } from './time-intervals.actions';

export interface TimeIntervalsStateModel {
    entitiy: OrganizationPosSettings;
    pending: boolean;
    error: ErrorObject;
}

@State<TimeIntervalsStateModel>({
    name: 'timeIntervals',
    defaults: { pending: false, entitiy: null, error: null }
})
@Injectable()
export class TimeIntervalsState {

    constructor(private intervalsService: IntervalsService) { }

    @Action(TimeIntervalsGetAction) intervalGet(ctx: StateContext<TimeIntervalsStateModel>, action: TimeIntervalsGetAction) {

        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.intervalsService.getTimeIntervals(action.payload.organizationPosUid)
            .then(resp => setTimeout(() => ctx.dispatch(new TimeIntervalsGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TimeIntervalsFailAction(err)), 0));
    }

    @Action(TimeIntervalsGetSuccessAction) intervalsGetSuccess(ctx: StateContext<TimeIntervalsStateModel>, action: TimeIntervalsGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entitiy: action.payload, error: null });
    }

    @Action(TimeIntervalsFailAction) intervalsGetFail(ctx: StateContext<TimeIntervalsStateModel>, action: TimeIntervalsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TimeIntervalsResetAction) intervalsGetReset(ctx: StateContext<TimeIntervalsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entitiy: null, error: null });
    }

    @Action(TimeIntervalsUpdateSuccessAction) timeIntervalsUpdateSuccess(ctx: StateContext<TimeIntervalsStateModel>, action: TimeIntervalsUpdateSuccessAction) {
        const state = ctx.getState();
        const entitiy = {
            ...state.entitiy,
            intervalSize: action.payload.request.intervalSize,
            intervalAmount: action.payload.request.intervalAmount,
            timeIntervals: action.payload.request.timeIntervals
        };

        ctx.setState({ ...state, pending: false, entitiy, error: null });
    }
}
