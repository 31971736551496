import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationRequisitesService } from '../../services/business/corporation-requisites.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { CorporationRequisiteCreateSuccessAction } from './corporation-requisite-create.actions';
import { CorporationRequisiteDeleteSuccessAction } from './corporation-requisite-delete.actions';
import { CorporationRequisiteUpdateSuccessAction } from './corporation-requisite-update.actions';
import { CorporationRequisitesAction, CorporationRequisitesFailAction, CorporationRequisitesResetAction, CorporationRequisitesSetFilterAction, CorporationRequisitesSuccessAction } from './corporation-requisites.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { CorporationRequisitesListFilter, CorporationRequisitesListFilterData } from '../../models/filters/corporation-requisites-list-filter.model';
import { CorporationRequisiteListItem } from '../../models/business/corporation-requisites/corporation-requisite-list-item.model';

export interface CorporationRequisitesStateModel extends PaginationStateModel<CorporationRequisiteListItem> {
    uids: string[];
    entities: { [uid: string]: CorporationRequisiteListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: CorporationRequisitesListFilterData;
    total: number;
}

@State<CorporationRequisitesStateModel>({
    name: 'corporationRequisites',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class CorporationRequisitesState extends PaginationStateBase {
    constructor(private corporationRequisitesService: CorporationRequisitesService) {
        super();
    }

    @Action([CorporationRequisitesAction]) corporationCorporationCorporationRequisitesGet(ctx: StateContext<CorporationRequisitesStateModel>, action: CorporationRequisitesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CorporationRequisitesListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.corporationRequisitesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.corporationRequisitesService.getRequisiteList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CorporationRequisitesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CorporationRequisitesFailAction(err)), 0));
    }

    @Action(CorporationRequisitesSuccessAction) corporationCorporationCorporationRequisitesGetSuccess(ctx: StateContext<CorporationRequisitesStateModel>, action: CorporationRequisitesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.corporationRequisitesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index, total });
    }

    @Action(CorporationRequisitesFailAction) corporationCorporationCorporationRequisitesGetFail(ctx: StateContext<CorporationRequisitesStateModel>, action: CorporationRequisitesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CorporationRequisitesResetAction) corporationCorporationCorporationRequisitesGetReset(ctx: StateContext<CorporationRequisitesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(CorporationRequisitesSetFilterAction) usersSetFilter(ctx: StateContext<CorporationRequisitesStateModel>, action: CorporationRequisitesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new CorporationRequisitesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(CorporationRequisiteCreateSuccessAction) corporationCorporationCorporationRequisiteCreateSuccess(ctx: StateContext<CorporationRequisitesStateModel>, _: CorporationRequisiteCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new CorporationRequisitesAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(CorporationRequisiteUpdateSuccessAction) corporationCorporationCorporationRequisiteUpdateSuccess(ctx: StateContext<CorporationRequisitesStateModel>, action: CorporationRequisiteUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(CorporationRequisiteDeleteSuccessAction) corporationCorporationCorporationRequisiteDeleteSuccess(ctx: StateContext<CorporationRequisitesStateModel>, action: CorporationRequisiteDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
