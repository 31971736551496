import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { ProductsService } from '../../services/business/products.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilter, ProductsListGetFilterData } from '../../models/filters/products-list-get-filter.model';

import { ProductsAction, ProductsFailAction, ProductsResetAction, ProductsSetFilterAction, ProductsSuccessAction } from './products.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface ProductsStateModel extends PaginationFilteredStateModel<ProductListItemAggregation, ProductsListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: ProductListItemAggregation };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ProductsListGetFilterData;
    total: number;
}

@State<ProductsStateModel>({
    name: 'products',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { pageSize: null } }
})
@Injectable()
export class ProductsState extends PaginationFilteredStateBase {
    constructor(private productsService: ProductsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([ProductsAction]) productsGet(ctx: StateContext<ProductsStateModel>, action: ProductsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'products');
        ctx.setState({ ...state, filter: filterData });

        const filter = new ProductsListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize || filterData.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.productsService.getProductList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ProductsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductsFailAction(err)), 0));
    }

    @Action(ProductsSuccessAction) productsGetSuccess(ctx: StateContext<ProductsStateModel>, action: ProductsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ProductsFailAction) productsGetFail(ctx: StateContext<ProductsStateModel>, action: ProductsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductsResetAction) productsGetReset(ctx: StateContext<ProductsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ProductsSetFilterAction) productsSetFilter(ctx: StateContext<ProductsStateModel>, action: ProductsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'products');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ProductsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
