<div class="note note-view" *ngIf="!editState">
    <div class="type-icon">
        <div class="icon-area">
            <exg-icon class="note-icon" iconName="task"></exg-icon>
        </div>
    </div>
    <div class="title_line">
        <div class="title">{{'E-Mail' | translate}}</div>
        <div class="create_time">{{feedItem?.createDate | exgDate:'h:mm'}}</div>
        <div class="task_status">
            <div class="status" [class]="taskStatusEnum[feedItem.taskStatus]">{{ taskStatusEnum[feedItem.taskStatus] | translate }}</div>
        </div>
        <div class="menu">
            <exg-menu class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event.id)">
                <exg-button class="menu_btn" styleType="icon"><exg-icon class="menu_icon" iconName="menudotted"></exg-icon></exg-button>
            </exg-menu>
        </div>
    </div>
    <div class="box">
        <div class="box_title">{{'To whom' | translate}}:</div>
        <div class="box_value">{{emails}}</div>
    </div>
    <div class="box">
        <div class="box_title">{{'Topic' | translate}}:</div>
        <div class="box_value">{{feedItem.title}}</div>
    </div>
    <div class="split_line"></div>
    <div class="box">
        <div class="box_title">{{'Text' | translate}}:</div>
        <div class="box_value text_line">{{feedItem.text}}</div>
    </div>
    <div class="documents_line">
        <bvc-clients-activity-document class="document"
                                       *ngFor="let document of feedItem.documents; trackBy: trackByDocuments"
                                       [document]="document"
                                       [allowRemove]="false"></bvc-clients-activity-document>
    </div>
    <div class="responsible">
        <exg-image class="profile-avatar" [image]="emptyAvatar" [isRound]="true"></exg-image>
        <div class="profile-name">
            <div class="info_title">{{ 'Responsible user' | translate }}</div>
            <div class="info_full_name">{{feedItem?.responsibleUser?.fullName}}</div>
        </div>
    </div>
</div>
<bvc-clients-activity-task-email-form *ngIf="editState"
                                      class="note"
                                      [task]="feedItem"
                                      [error]="error"
                                      [companyUid]="companyUid"
                                      (taskUpdate)="onTaskUpdate($event)"
                                      (cancelClick)="editState=false"></bvc-clients-activity-task-email-form>
