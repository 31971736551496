import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransfersService } from '../../services/business/transfers.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransferCreateAction, TransferCreateFailAction, TransferCreateResetAction, TransferCreateSuccessAction } from './transfer-create.actions';
import { Transfer } from '../../models/business/transfers/transfer.model';

export interface TransferCreateStateModel {
    pending: boolean;
    created: Transfer;
    error: ErrorObject;
}

@State<TransferCreateStateModel>({
    name: 'transferCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TransferCreateState {
    constructor(private transfersService: TransfersService) { }

    @Action(TransferCreateAction) async transferCreate(ctx: StateContext<TransferCreateStateModel>, action: TransferCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.transfersService.createTransfer({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new TransferCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransferCreateFailAction(err)), 0));
    }

    @Action(TransferCreateSuccessAction) transferCreateSuccess(ctx: StateContext<TransferCreateStateModel>, action: TransferCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TransferCreateFailAction) transferCreateFail(ctx: StateContext<TransferCreateStateModel>, action: TransferCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(TransferCreateResetAction) transferCreateReset(ctx: StateContext<TransferCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
