import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IntervalsService } from '../../services/business/intervals.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TimeIntervalsUpdateAction, TimeIntervalsUpdateFailAction, TimeIntervalsUpdateResetAction, TimeIntervalsUpdateSuccessAction } from './time-intervals-update.actions';

export interface TimeIntervalsUpdateStateModel {
    updated: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<TimeIntervalsUpdateStateModel>({
    name: 'timeIntervalsUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class TimeIntervalsUpdateState {

    constructor(private intervalsService: IntervalsService) { }

    @Action(TimeIntervalsUpdateAction) timeIntervalUpdate(ctx: StateContext<TimeIntervalsUpdateStateModel>, action: TimeIntervalsUpdateAction) {

        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.intervalsService.updateTimeIntervals(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TimeIntervalsUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TimeIntervalsUpdateFailAction(err)), 0));
    }

    @Action(TimeIntervalsUpdateSuccessAction) timeIntervalsUpdateSuccess(ctx: StateContext<TimeIntervalsUpdateStateModel>, _: TimeIntervalsUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(TimeIntervalsUpdateFailAction) timeIntervalsUpdateFail(ctx: StateContext<TimeIntervalsUpdateStateModel>, action: TimeIntervalsUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TimeIntervalsUpdateResetAction) timeIntervalsUpdateReset(ctx: StateContext<TimeIntervalsUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
