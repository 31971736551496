import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Note } from '../../models/business/notes/note.model';
import { NotesListGetFilterData } from '../../models/filters/notes-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class NotesAction {
    static readonly type = '[Notes Page] GetNotes';

    constructor(public payload: PaginationRequest) { }
}

export class NotesSuccessAction {
    static readonly type = '[Notes API] GetNotes Success';

    constructor(public payload: { list: PaginationResult<Note>, pagination: PaginationRequest }) { }
}

export class NotesFailAction {
    static readonly type = '[Notes API] GetNotes Fail';

    constructor(public payload: ErrorObject) { }
}

export class NotesResetAction {
    static readonly type = '[Notes Page] GetNotes Reset';
}

export class NotesSetFilterAction {
    static readonly type = '[Notes API] SetFilter';

    constructor(public payload: NotesListGetFilterData) { }
}
