import { DocumentModel } from '../feed/document.model';
import { LinkedCompany } from '../companies/linked-company.model';
import { LinkedContact } from '../contacts/linked-contact.model';
import { LinkedUser } from '../user/linked-user.model';
import { TaskStatus } from './task-status.enum';
import { TaskType } from './task-type.enum';

export class Task {
    public uid: string;
    public taskNumber: number;
    public type: TaskType;
    public name: string;
    public description: string;
    public status: TaskStatus;
    public documents: DocumentModel[];
    public createDate: number;
    public dueDate: number;
    public details: any;
    public assigneeUser: LinkedUser;
    public company?: LinkedCompany;
    public contact?: LinkedContact;
}
