import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SupplierInvoicesService } from '../../services/business/supplier-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { SupplierInvoiceCreateOrUpdateRequest } from '../../models/business/supplier-invoices/supplier-invoice-create-or-update-request.model';

import { SupplierInvoiceUpdateAction, SupplierInvoiceUpdateFailAction, SupplierInvoiceUpdateResetAction, SupplierInvoiceUpdateSuccessAction } from './supplier-invoice-update.actions';

export interface SupplierInvoiceUpdateStateModel {
    pending: boolean;
    updated: SupplierInvoiceCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<SupplierInvoiceUpdateStateModel>({
    name: 'supplierInvoiceUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class SupplierInvoiceUpdateState {
    constructor(private supplierInvoicesService: SupplierInvoicesService) { }

    @Action(SupplierInvoiceUpdateAction) async supplierInvoiceUpdate(ctx: StateContext<SupplierInvoiceUpdateStateModel>, action: SupplierInvoiceUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.supplierInvoicesService.updateSupplierInvoice(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new SupplierInvoiceUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SupplierInvoiceUpdateFailAction(err)), 0));
    }

    @Action(SupplierInvoiceUpdateSuccessAction) supplierInvoiceUpdateSuccess(ctx: StateContext<SupplierInvoiceUpdateStateModel>, action: SupplierInvoiceUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(SupplierInvoiceUpdateFailAction) supplierInvoiceUpdateFail(ctx: StateContext<SupplierInvoiceUpdateStateModel>, action: SupplierInvoiceUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(SupplierInvoiceUpdateResetAction) supplierInvoiceUpdateReset(ctx: StateContext<SupplierInvoiceUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
