import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ModifierType } from '../../models/business/modifiers/modifier-type.model';

export class ModifierTypeAction {
    static readonly type = '[ModifierType Page] ModifierType';
}

export class ModifierTypeSuccessAction {
    static readonly type = '[ModifierType API] ModifierType Success';

    constructor(public payload: ModifierType[]) { }
}

export class ModifierTypeFailAction {
    static readonly type = '[ModifierType API] ModifierType Fail';

    constructor(public payload: ErrorObject) { }
}

export class ModifierTypeResetAction {
    static readonly type = '[ModifierType Page] ModifierType Reset';
}
