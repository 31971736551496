import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategoryCreateOrUpdateRequest } from '../../models/business/catalog/product-category-create-or-update-request.model';

export class CategoryUpdateAction {
    static readonly type = '[Category Page] UpdateCategory';

    constructor(public payload: { uid: string, request: ProductCategoryCreateOrUpdateRequest }) { }
}

export class CategoryUpdateSuccessAction {
    static readonly type = '[Category API] UpdateCategory Success';

    constructor(public payload: { uid: string, request: ProductCategoryCreateOrUpdateRequest }) { }
}

export class CategoryUpdateFailAction {
    static readonly type = '[Category API] UpdateCategory Fail';

    constructor(public payload: ErrorObject) { }
}

export class CategoryUpdateResetAction {
    static readonly type = '[Category Page] UpdateCategory Reset';
}
