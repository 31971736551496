import { Contact } from '../../models/business/contacts/contact.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContactGetAction {
    static readonly type = '[Contact Page] GetContact Get';

    constructor(public payload: string) { }
}

export class ContactGetSuccessAction {
    static readonly type = '[Contact API] GetContact Success';

    constructor(public payload: Contact) { }
}

export class ContactFailAction {
    static readonly type = '[Contact API] GetContact Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactResetAction {
    static readonly type = '[Contact Page] GetContact Reset';
}
