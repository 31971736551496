import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RestaurantCategoryService } from '../../services/business/restaurant-category.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { RestaurantCategoryCreateSuccessAction } from './restaurant-category-create.actions';
import { RestaurantCategoryDeleteSuccessAction } from './restaurant-category-delete.actions';
import { RestaurantCategoryUpdateSuccessAction } from './restaurant-category-update.actions';

import { RestaurantCategoriesAction, RestaurantCategoriesFailAction, RestaurantCategoriesResetAction, RestaurantCategoriesSetFilterAction, RestaurantCategoriesSuccessAction } from './restaurant-categories.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantCategoryListItem } from '../../models/business/restaurant-category/restaurant-category-list-item.model';
import { RestaurantCategoryListGetFilter, RestaurantCategoryListGetFilterData } from '../../models/filters/restaurant-category-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface RestaurantCategoriesStateModel extends PaginationStateModel<RestaurantCategoryListItem> {
    uids: string[];
    entities: { [uid: string]: RestaurantCategoryListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: RestaurantCategoryListGetFilterData;
    total: number;
}

@State<RestaurantCategoriesStateModel>({
    name: 'restaurantCategories',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { }
    }
})
@Injectable()
export class RestaurantCategoriesState extends PaginationStateBase {
    constructor(private categoryService: RestaurantCategoryService) {
        super();
    }

    @Action([RestaurantCategoriesAction]) restaurantCategoriesGet(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoriesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new RestaurantCategoryListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.posesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.categoryService.getRestaurantCategoryList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new RestaurantCategoriesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RestaurantCategoriesFailAction(err)), 0));
    }

    @Action(RestaurantCategoriesSuccessAction) restaurantCategoriesGetSuccess(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoriesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.posesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(RestaurantCategoriesFailAction) restaurantCategoriesGetFail(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoriesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RestaurantCategoriesResetAction) restaurantCategoriesGetReset(ctx: StateContext<RestaurantCategoriesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(RestaurantCategoriesSetFilterAction) usersSetFilter(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoriesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new RestaurantCategoriesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(RestaurantCategoryCreateSuccessAction) restaurantCategoryeCreateSuccess(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoryCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(RestaurantCategoryUpdateSuccessAction) restaurantCategoryeUpdateSuccess(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoryUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(RestaurantCategoryDeleteSuccessAction) restaurantCategoryeDeleteSuccess(ctx: StateContext<RestaurantCategoriesStateModel>, action: RestaurantCategoryDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
