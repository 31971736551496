<section class="form">
    <div class="header">
        <div class="title-line">{{ 'Choose the organization' | translate }}</div>
        <exg-button styleType="custom" color="primary" (btnClick)="onCloseClick()">
            <exg-icon class="close-icon" color="primary" iconName="close"></exg-icon>
        </exg-button>
    </div>
    <div class="content">
        <div class="left">
            <div class="form">
                <perfect-scrollbar class="scroll" [class.buttons-active]="editState$ | async">
                    <div *ngIf="organizationTree" class="box">
                        <organizations-tree [data]="organizationTree"
                                            [selectedUids]="toArrayOfUids(selectedUids)"
                                            (searchChanged)="onTextValueChange($event)"
                                            (valueChange)="onValueChange($event)"></organizations-tree>
                    </div>
                </perfect-scrollbar>
                <div class="btn-box">
                    <exg-button (btnClick)="apply()" class="submit" type="submit">{{ 'Choose' | translate }}</exg-button>
                </div>
            </div>
        </div>
    </div>
</section>
