import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { SupplierInvoiceCreateOrUpdateRequest } from '../../models/business/supplier-invoices/supplier-invoice-create-or-update-request.model';
import { SupplierInvoice } from '../../models/business/supplier-invoices/supplier-invoice.model';

export class SupplierInvoiceCreateAction {
    static readonly type = '[SupplierInvoices Page] CreateSupplierInvoice';

    constructor(public payload: SupplierInvoiceCreateOrUpdateRequest) { }
}

export class SupplierInvoiceCreateSuccessAction {
    static readonly type = '[SupplierInvoice API] CreateSupplierInvoice Success';

    constructor(public payload: SupplierInvoice) { }
}

export class SupplierInvoiceCreateFailAction {
    static readonly type = '[SupplierInvoice API] CreateSupplierInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class SupplierInvoiceCreateResetAction {
    static readonly type = '[SupplierInvoices Page] CreateSupplierInvoice Reset';
}
