import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ManagerAssistant } from '../../models/business/manager-assistants/manager-assistant.model';
import { ManagerAssistantCreateOrUpdateRequest } from '../../models/business/manager-assistants/manager-assistants-create-or-update-request.model';
import { ManagerAssistantsListFilter } from '../../models/filters/manager-assistants-list-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ManagerAssistantsService extends BaseSingletonService {

    private settings: {
        service: {
            getAssistant: string;
            getAssistantList: string;
            createAssistant: string;
            deleteAssistant: string;
        }
    };

    constructor(private http: HttpService) {
        super('ManagerAssistantsService');
        this.settings = {
            service: {
                getAssistant: '/crm/v1/users/{ui0d}',
                getAssistantList: '/crm/v1/users?dateFrom={0}&dateTo={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createAssistant: '/crm/v1/users',
                deleteAssistant: '/crm/v1/users/{0}'
            }
        };
    }

    public async getAssistantList(filter: ManagerAssistantsListFilter): Promise<PaginationResult<ManagerAssistant>> {
        return this.http.get<PaginationResult<ManagerAssistant>>(String.format(this.settings.service.getAssistantList, filter.startDate, filter.endDate, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createAssistant(request: ManagerAssistantCreateOrUpdateRequest): Promise<ManagerAssistant> {
        return this.http.post<{ data: ManagerAssistant }>(String.format(this.settings.service.createAssistant), request)
            .then(res => res.data);
    }

    public async deleteAssistant(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteAssistant, uid));
    }
}
