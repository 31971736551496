<div class="header" [ngClass]="{ 'header-with-tabs' : tabs?.length }">
    <div class="title-section">
        <div class="title">
            {{ headerTitle }}
        </div>
        <ng-content></ng-content>
    </div>
    <div class="search-section">
        <div class="search_area">
            <exg-icon class="search_icon" iconName="search"></exg-icon>
    
            <exg-textbox
                class="search_input"
                type="text"
                [value]="filter?.term"
                styleType="empty"
                [placeholder]="searchTitle"
                (valueChange)="onValueChange($event)"></exg-textbox>
        </div>
    </div>
</div>
<div *ngIf="tabs?.length" class="container">
    <div class="scrolling-wrapper-flexbox">
        <ng-container *ngFor="let tab of tabs; trackBy: trackByTabs">
            <exg-button class="tab_btn card" *ngIf="!tab.disableByPermission" styleType="custom" [routerLink]="[tab.route]" routerLinkActive="active">
                <exg-icon class="tab_icon" *ngIf="!!tab?.icon" [iconName]="tab.icon"></exg-icon>
                {{ tab.name | translate }}
            </exg-button>
        </ng-container>
    </div>
</div>
