import { LinkedCompany } from '../companies/linked-company.model';
import { LinkedContact } from '../contacts/linked-contact.model';
import { LinkedContragent } from '../contragents/linked-contragent.model';
import { LinkedPlace } from '../linked-place.model';
import { PosType } from './pos-type.enum';
import { VehicleBrand } from './vehicle-brand.model';

export class PosListItem {
    public uid: string;
    public name: string;
    public vehicleBrand: VehicleBrand;
    public company: LinkedCompany;
    public contragent: LinkedContragent;
    public address: LinkedPlace;
    public type: PosType;
    public mainContact: LinkedContact;
}
