import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseReturnsService } from '../../services/business/purchase-returns.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseReturn } from '../../models/business/purchase-returns/purchase-return.model';

import { PurchaseReturnFailAction, PurchaseReturnGetAction, PurchaseReturnGetSuccessAction, PurchaseReturnResetAction } from './purchase-return.actions';

export interface PurchaseReturnStateModel {
    entity: PurchaseReturn;
    pending: boolean;
    error: ErrorObject;
}

@State<PurchaseReturnStateModel>({
    name: 'purchaseReturn',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PurchaseReturnState {
    constructor(private purchaseReturnsService: PurchaseReturnsService) { }

    @Action(PurchaseReturnGetAction) purchaseReturnGet(ctx: StateContext<PurchaseReturnStateModel>, action: PurchaseReturnGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.purchaseReturnsService.getPurchaseReturn(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PurchaseReturnGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseReturnFailAction(err)), 0));
    }

    @Action(PurchaseReturnGetSuccessAction) purchaseReturnGetSuccess(ctx: StateContext<PurchaseReturnStateModel>, action: PurchaseReturnGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PurchaseReturnFailAction) purchaseReturnGetFail(ctx: StateContext<PurchaseReturnStateModel>, action: PurchaseReturnFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseReturnResetAction) purchaseReturnGetReset(ctx: StateContext<PurchaseReturnStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
