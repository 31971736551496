import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { PosContactsAction, PosContactsFailAction, PosContactsResetAction, PosContactsSetFilterAction, PosContactsSuccessAction } from './pos-contacts.actions';

import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilter, ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface PosContactsStateModel extends PaginationStateModel<ContactListItem> {
    uids: string[];
    entities: { [uid: string]: ContactListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ContactsListFilterData;
    total: number;
}

@State<PosContactsStateModel>({
    name: 'contactsPos',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class PosContactsState extends PaginationStateBase {
    constructor(private contactsService: ContactsService) {
        super();
    }

    @Action([PosContactsAction]) contactsGet(ctx: StateContext<PosContactsStateModel>, action: PosContactsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContactsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contactsService.getContactList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PosContactsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosContactsFailAction(err)), 0));
    }

    @Action(PosContactsSuccessAction) contactsGetSuccess(ctx: StateContext<PosContactsStateModel>, action: PosContactsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(PosContactsFailAction) contactsGetFail(ctx: StateContext<PosContactsStateModel>, action: PosContactsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosContactsResetAction) contactsGetReset(ctx: StateContext<PosContactsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(PosContactsSetFilterAction) usersSetFilter(ctx: StateContext<PosContactsStateModel>, action: PosContactsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PosContactsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
