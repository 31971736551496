<button class="btn" type="button" (click)="onOpenCompanyClick()">
    <div class="full-name" [class.active]="filter.sortField === companySortingFields.FullName">
        <span title="{{company.name}}">{{company.name}}</span>
    </div>
    <div class="contact" [class.active]="filter.sortField === companySortingFields.Contact">
        <span title="{{company.mainContact?.fullName}}">{{company.mainContact?.fullName}}</span>
    </div>
    <div class="phone" [class.active]="filter.sortField === companySortingFields.Phone">
        <span title="{{company.phone}}">{{company.phone}}</span>
    </div>
    <div class="city" [class.active]="filter.sortField === companySortingFields.City">
        <span title="{{company.city?.name}}">{{company.city?.name}}</span>
    </div>
    <div class="type" [class.active]="filter.sortField === companySortingFields.Type">
        <span title="{{companyTypeEnum[company.type] | translate}}">{{companyTypeEnum[company.type] | translate}}</span>
    </div>
    <div class="company-status" [class.active]="filter.sortField === companySortingFields.Status">
        <span class="status status-new" *ngIf="company.status === companyStatusEnum.New" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
        <span class="status status-client" *ngIf="company.status === companyStatusEnum.Client" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
        <span class="status status-active" *ngIf="company.status === companyStatusEnum.Active" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
        <span class="status status-vip" *ngIf="company.status === companyStatusEnum.Vip" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
        <span class="status status-lost" *ngIf="company.status === companyStatusEnum.Lost" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
    </div>
</button>
<button class="btn btn_mobile" type="button" (click)="onOpenCompanyClick()">
    <div class="goup-line">
        <div class="goup-left">
            <div title="{{company.name}}" class="title">{{company.name}}</div>
        </div>
        <div class="goup-right"></div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'City' | translate}}</div>
        <div class="goup-right" title="{{company.city?.name}}">
            {{company.city?.name}}
        </div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Contact' | translate}}</div>
        <div class="goup-right" title="{{company.mainContact?.fullName}}">
            {{company.mainContact?.fullName}}
        </div>
    </div>
    <div class="goup-line">
        <div class="goup-left">{{'Contact phone' | translate}}</div>
        <div class="goup-right" title="{{company.phone}}">
            {{company.phone}}
        </div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Type' | translate}}</div>
        <div class="goup-right" title="{{companyTypeEnum[company.type] | translate}}">
            {{companyTypeEnum[company.type] | translate}}
        </div>
    </div>
    <div class="goup-line">
        <div class="goup-left">{{'Status' | translate}}</div>
        <div class="goup-right">
            <span class="status status-new" *ngIf="company.status === companyStatusEnum.New" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
            <span class="status status-client" *ngIf="company.status === companyStatusEnum.Client" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
            <span class="status status-active" *ngIf="company.status === companyStatusEnum.Active" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
            <span class="status status-vip" *ngIf="company.status === companyStatusEnum.Vip" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
            <span class="status status-lost" *ngIf="company.status === companyStatusEnum.Lost" title="{{companyStatusEnum[company.status] | translate}}">{{companyStatusEnum[company.status] | translate}}</span>
        </div>
    </div>
</button>