import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CategoriesAction, CategoriesFailAction, CategoriesResetAction, CategoriesSetFilterAction, CategoriesSuccessAction, CategoryCreateAction } from './categories.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductCategoriesListFilter, ProductCategoriesListFilterData } from '../../models/filters/product-categories-list-filter.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface CategoriesStateModel extends PaginationStateModel<ProductCategory> {
    uids: string[];
    entities: { [uid: string]: ProductCategory };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ProductCategoriesListFilterData;
    total: number;
}

@State<CategoriesStateModel>({
    name: 'categories',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class CategoriesState extends PaginationStateBase {
    constructor(private catalogService: CatalogService) {
        super();
    }

    @Action([CategoriesAction]) categoriesGet(ctx: StateContext<CategoriesStateModel>, action: CategoriesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ProductCategoriesListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.categoriesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.catalogService.getCategories(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CategoriesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CategoriesFailAction(err)), 0));
    }

    @Action(CategoriesSuccessAction) categoriesGetSuccess(ctx: StateContext<CategoriesStateModel>, action: CategoriesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.categoriesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CategoriesFailAction) categoriesGetFail(ctx: StateContext<CategoriesStateModel>, action: CategoriesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CategoriesResetAction) categoriesGetReset(ctx: StateContext<CategoriesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(CategoryCreateAction) orderCreateFromSocketSuccess(ctx: StateContext<CategoriesStateModel>, action: CategoryCreateAction) {
        const state = ctx.getState();
        let uids = [...state.uids];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...action.payload };
        uids.unshift(action.payload.uid);
        setTimeout(() => ctx.setState({ ...state, uids, entities }), 0);
    }


    @Action(CategoriesSetFilterAction) categoriesSetFilter(ctx: StateContext<CategoriesStateModel>, action: CategoriesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new CategoriesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
