import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosCity } from '../../models/business/organization-pos/pos-city.model';

export class PosCitiesAction {
    static readonly type = '[PosCities Page] GetPosCities';
}

export class PosCitiesSuccessAction {
    static readonly type = '[PosCities API] GetPosCities Success';

    constructor(public payload: PosCity[]) { }
}

export class PosCitiesFailAction {
    static readonly type = '[PosCities API] GetPosCities Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosCitiesResetAction {
    static readonly type = '[PosCities Page] GetPosCities Reset';
}
