import { Injectable } from '@angular/core';

import { combineLatest, first } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { CharacteristicsSearchDispatchers } from '../../shared/store/characteristics/characteristics-search.dispatchers';
import { CharacteristicsSearchSelectors } from '../../shared/store/characteristics/characteristics-search.selectors';

import { OrganizationsSearchDispatchers } from '../../shared/store/organizations/organizations-search.dispatchers';
import { OrganizationsSearchSelectors } from '../../shared/store/organizations/organizations-search.selectors';

import { OrganizationPosesSearchDispatchers } from '../../shared/store/organization-pos/organization-poses-search.dispatchers';
import { OrganizationPosesSearchSelectors } from '../../shared/store/organization-pos/organization-poses-search.selectors';

import { ProductsSearchDispatchers } from '../../shared/store/products/products-search.dispatchers';
import { ProductsSearchSelectors } from '../../shared/store/products/products-search.selectors';

import { ProfileSelectors } from '../../../burns-ui-framework/shared/store/profile/profile.selectors';

import { UserSearchDispatchers } from '../../shared/store/users/users-search.dispatchers';
import { UserSearchSelectors } from '../../shared/store/users/users-search.selectors';

import { AcceptanceCreateDispatchers } from '../../shared/store/acceptances/acceptance-create.dispatchers';
import { AcceptanceCreateSelectors } from '../../shared/store/acceptances/acceptance-create.selectors';

import { AcceptanceDeleteDispatchers } from '../../shared/store/acceptances/acceptance-delete.dispatchers';
import { AcceptanceDeleteSelectors } from '../../shared/store/acceptances/acceptance-delete.selectors';

import { AcceptanceUpdateDispatchers } from '../../shared/store/acceptances/acceptance-update.dispatchers';
import { AcceptanceUpdateSelectors } from '../../shared/store/acceptances/acceptance-update.selectors';

import { AcceptanceDispatchers } from '../../shared/store/acceptances/acceptance.dispatchers';
import { AcceptanceSelectors } from '../../shared/store/acceptances/acceptance.selectors';

import { AcceptancesDispatchers } from '../../shared/store/acceptances/acceptances.dispatchers';
import { AcceptancesSelectors } from '../../shared/store/acceptances/acceptances.selectors';

import { ContragentsSearchSelectors } from '../../shared/store/contragents/contragents-search.selectors';
import { ContragentsSearchDispatchers } from '../../shared/store/contragents/contragents-search.dispatchers';

import { ContractsSelectors } from '../../shared/store/contracts/contracts.selectors';
import { ContractsDispatchers } from '../../shared/store/contracts/contracts.dispatchers';

import { CharacteristicsListGetFilterData } from '../../shared/models/filters/characteristics-list-get-filter.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { VatsSelectors } from '../../shared/store/vats/vats.selectors';
import { VatsDispatchers } from '../../shared/store/vats/vats.dispatchers';

import { WarehousesSearchDispatchers } from '../../shared/store/warehouses/warehouses-search.dispatchers';
import { WarehousesSearchSelectors } from '../../shared/store/warehouses/warehouses-search.selectors';

import { AcceptanceCreateOrUpdateRequest } from '../../shared/models/business/acceptances/acceptance-create-or-update-request.model';
import { AcceptanceListGetFilterData } from '../../shared/models/filters/acceptance-list-get-filter.model';
import { OrganizationPosesListGetFilterData } from '../../shared/models/filters/organization-pos-list-get-filter.model';
import { WarehousesListFilterData } from '../../shared/models/filters/warehouses-list-filter.model';
import { ProductsListGetFilterData } from '../../shared/models/filters/products-list-get-filter.model';

@Injectable({
    providedIn: 'root',
})
export class AcceptanceSandbox extends BaseSingletonService {
    public vats$ = this.vatsSelectors.vats$;

    public profile$ = this.profileSelectors.profile$;
    public typingContragent$ = this.contragentsSearchSelectors.contragents$;

    public pending$ = this.acceptancesSelectors.pending$;
    public acceptances$ = this.acceptancesSelectors.acceptances$;
    public error$ = this.acceptancesSelectors.error$;
    public allAcceptanceLoaded$ = this.acceptancesSelectors.allAcceptanceLoaded$;
    public retrieved$ = this.acceptancesSelectors.retrieved$;
    public filter$ = this.acceptancesSelectors.filter$;
    public total$ = this.acceptancesSelectors.total$;
    public totalAmount$ = this.acceptancesSelectors.totalAmount$;
    public totalPaidAmount$ = this.acceptancesSelectors.totalPaidAmount$;

    public acceptance$ = this.acceptanceSelectors.acceptance$;
    public acceptanceError$ = this.acceptanceSelectors.error$;
    public acceptancePending$ = this.acceptanceSelectors.pending$;

    public acceptanceUpdated$ = this.acceptanceUpdateSelectors.updated$;
    public acceptanceUpdatePending$ = this.acceptanceUpdateSelectors.pending$;
    public acceptanceUpdateError$ = this.acceptanceUpdateSelectors.error$;

    public acceptanceCreated$ = this.acceptanceCreateSelectors.created$;
    public acceptanceCreatePending$ = this.acceptanceCreateSelectors.pending$;
    public acceptanceCreateError$ = this.acceptanceCreateSelectors.error$;

    public acceptanceDeleted$ = this.acceptanceDeleteSelectors.deleted$;
    public acceptanceDeletePending$ = this.acceptanceDeleteSelectors.pending$;
    public acceptanceDeleteError$ = this.acceptanceDeleteSelectors.error$;

    public products$ = this.productsSelectors.products$;
    public characteristics$ = this.characteristicsSearchSelectors.characteristics$;
    public organizationsSearch$ = this.organizationsSearchSelectors.organizations$;
    public usersSearch$ = this.userSearchSelectors.users$;
    public usersSearchPending$ = this.userSearchSelectors.pending$;

    public warehouses$ = this.warehousesSearchSelectors.warehouses$;

    public contractsContragent$ = this.contractsSelectors.contracts$;
    public contractsContragentRetrieved$ = this.contractsSelectors.retrieved$;
    public contractsContragentFilter$ = this.contractsSelectors.filter$;
    public allContractsContragentLoaded$ = this.contractsSelectors.allContractsLoaded$;
    public organizationPosesSearch$ = this.organizationPosesSearchSelectors.organizationPoses$

    constructor(
        private profileSelectors: ProfileSelectors,
        private acceptanceCreateDispatchers: AcceptanceCreateDispatchers,
        private acceptanceCreateSelectors: AcceptanceCreateSelectors,
        private acceptanceDeleteDispatchers: AcceptanceDeleteDispatchers,
        private acceptanceDeleteSelectors: AcceptanceDeleteSelectors,
        private acceptancesDispatchers: AcceptancesDispatchers,
        private acceptancesSelectors: AcceptancesSelectors,
        private acceptanceUpdateDispatchers: AcceptanceUpdateDispatchers,
        private acceptanceUpdateSelectors: AcceptanceUpdateSelectors,
        private acceptanceDispatchers: AcceptanceDispatchers,
        private acceptanceSelectors: AcceptanceSelectors,
        private productsDispatchers: ProductsSearchDispatchers,
        private productsSelectors: ProductsSearchSelectors,
        private characteristicsSearchDispatchers: CharacteristicsSearchDispatchers,
        private characteristicsSearchSelectors: CharacteristicsSearchSelectors,
        private organizationsSearchDispatchers: OrganizationsSearchDispatchers,
        private organizationsSearchSelectors: OrganizationsSearchSelectors,
        private userSearchDispatchers: UserSearchDispatchers,
        private userSearchSelectors: UserSearchSelectors,
        private warehousesSearchDispatchers: WarehousesSearchDispatchers,
        private warehousesSearchSelectors: WarehousesSearchSelectors,
        private contragentsSearchSelectors: ContragentsSearchSelectors,
        private contragentsSearchDispatchers: ContragentsSearchDispatchers,
        private contractsSelectors: ContractsSelectors,
        private contractsDispatchers: ContractsDispatchers,
        private vatsSelectors: VatsSelectors,
        private vatsDispatchers: VatsDispatchers,
        private organizationPosesSearchDispatchers: OrganizationPosesSearchDispatchers,
        private organizationPosesSearchSelectors: OrganizationPosesSearchSelectors
    ) {
        super('AcceptanceSandbox');
    }

    public dispatchAcceptance(request: PaginationRequest) {
        this.acceptancesDispatchers.dispatchAcceptancesAction(request);
    }

    public dispatchAcceptanceReset() {
        this.acceptancesDispatchers.dispatchAcceptancesResetAction();
    }

    public dispatchAcceptanceSetFilter(filterData: AcceptanceListGetFilterData) {
        this.acceptancesDispatchers.dispatchAcceptancesSetFilterAction(filterData);
    }

    public dispatchAcceptanceAction(uid: string) {
        this.acceptanceDispatchers.dispatchAcceptanceAction(uid);
    }

    public dispatchAcceptanceActionReset() {
        this.acceptanceDispatchers.dispatchAcceptanceResetAction();
    }

    public dispatchAcceptanceDelete(acceptanceUid: string) {
        this.acceptanceDeleteDispatchers.dispatchAcceptanceDeleteAction(acceptanceUid);
    }

    public dispatchAcceptanceDeleteReset() {
        this.acceptanceDeleteDispatchers.dispatchAcceptanceDeleteResetAction();
    }

    public dispatchAcceptanceUpdate(uid: string, request: AcceptanceCreateOrUpdateRequest) {
        this.acceptanceUpdateDispatchers.dispatchAcceptanceUpdateAction(uid, request);
    }

    public dispatchAcceptanceUpdateReset() {
        this.acceptanceUpdateDispatchers.dispatchAcceptanceUpdateResetAction();
    }

    public dispatchAcceptanceCreate(request: AcceptanceCreateOrUpdateRequest) {
        this.acceptanceCreateDispatchers.dispatchAcceptanceCreateAction(request);
    }

    public dispatchAcceptanceCreateReset() {
        this.acceptanceCreateDispatchers.dispatchAcceptanceCreateResetAction();
    }

    public dispatchProductsSetFilter($event: ProductsListGetFilterData) {
        this.productsDispatchers.dispatchProductsSearchSetFilterAction($event);
    }

    public dispatchProductsReset() {
        this.productsDispatchers.dispatchProductsSearchResetAction();
    }

    public dispatchCharacteristicsSearch(request: CharacteristicsListGetFilterData) {
        this.characteristicsSearchDispatchers.dispatchCharacteristicsSearchAction(request);
    }

    public dispatchCharacteristicsSearchReset() {
        this.characteristicsSearchDispatchers.dispatchCharacteristicsSearchResetAction();
    }

    public dispatchUserSearch() {
        combineLatest([this.usersSearch$, this.usersSearchPending$])
            .pipe(first())
            .subscribe(res => {
                if (!res[0].length && !res[1]) {
                    this.userSearchDispatchers.dispatchUserSearchAction();
                }
            });
    }

    public dispatchOrganizationsSearch(term: string) {
        this.organizationsSearchDispatchers.dispatchOrganizationsSearchAction({ term });
    }

    public dispatchOrganizationsSearchReset() {
        this.organizationsSearchDispatchers.dispatchOrganizationsSearchResetAction();
    }

    public dispatchWarehousesSearch(filter: WarehousesListFilterData) {
        this.warehousesSearchDispatchers.dispatchWarehousesSearchAction(filter);
    }

    public dispatchWarehousesSearchReset() {
        this.warehousesSearchDispatchers.dispatchWarehousesSearchResetAction();
    }

    public dispatchContragentsSearchAction(term: string) {
        this.contragentsSearchDispatchers.dispatchContragentsSearchAction({ term });
    }

    public dispatchContractsLoadMore(request: PaginationRequest) {
        this.contractsDispatchers.dispatchContractsAction(request);
    }

    public dispatchContractsSetFilterAction(filter) {
        this.contractsDispatchers.dispatchContractsSetFilterAction(filter);
    }

    public dispatchContractsResetAction() {
        this.contractsDispatchers.dispatchContractsResetAction();
    }

    public dispatchVatsResetAction() {
        this.vatsDispatchers.dispatchVatsResetAction();
    }

    public dispatchVatsAction() {
        this.vatsDispatchers.dispatchVatsAction();
    }

    public dispatchOrganizationPosesSearch(filter: OrganizationPosesListGetFilterData) {
        this.organizationPosesSearchDispatchers.dispatchOrganizationPosesSearchSetFilterAction(filter);
    }
    public dispatchOrganizationPosesSearchReset() {
        this.organizationPosesSearchDispatchers.dispatchOrganizationPosesSearchResetAction();
    }
}
