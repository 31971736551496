import { Country } from '../../models/business/geocoding/country.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ProductsCountriesAction {
    static readonly type = '[ProductsCountries Page] GetProductsCountries';
}

export class ProductsCountriesSuccessAction {
    static readonly type = '[ProductsCountries API] GetProductsCountries Success';

    constructor(public payload: Country[]) { }
}

export class ProductsCountriesFailAction {
    static readonly type = '[ProductsCountries API] GetProductsCountries Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductsCountriesResetAction {
    static readonly type = '[ProductsCountries Page] GetProductsCountries Reset';
}
