import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { FranchiseesService } from '../../services/business/franchisees.service';

import {
    FranchiseesAction,
    FranchiseesExpandAction,
    FranchiseesFailAction,
    FranchiseesResetAction,
    FranchiseesSetFilterAction,
    FranchiseesSuccessAction,
    FranchiseesOrganizationsAction,
    FranchiseesOrganizationsSuccessAction,
    FranchiseesPosesAction,
    FranchiseesPosesSuccessAction,
} from './franchisees.actions';
import { FranchiseeListItem } from '../../models/business/franchisees/franchisee-list-item.model';
import { FranchiseeListGetFilter, FranchiseeListGetFilterData } from '../../models/filters/franchisee-list-get-filter.model';

import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilter } from '../../models/filters/organizations-list-get-filter.model';
import { OrganizationsService } from '../../services/business/organizations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { OrganizationPosesListGetFilter } from '../../models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosService } from '../../services/business/organization-pos.service';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';

export interface FranchiseesStateModel {
    entities: FranchiseeListItem[];
    totalEntity: FranchiseeListItem;
    organizationList: { [uid: string]: OrganizationListItem[] };
    organizationListPending: { [uid: string]: boolean };
    organizationListRetrieved: { [uid: string]: boolean };
    posList: { [uid: string]: OrganizationPosListItem[] };
    posListPending: { [uid: string]: boolean };
    posListRetrieved: { [uid: string]: boolean };
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: FranchiseeListGetFilterData;
    expanded: { [uid: string]: boolean };
}

@State<FranchiseesStateModel>({
    name: 'Franchisees',
    defaults: {
        pending: false,
        entities: [],
        totalEntity: null,
        organizationList: {},
        organizationListPending: {},
        organizationListRetrieved: {},
        posList: {},
        posListPending: {},
        posListRetrieved: {},
        retrieved: false,
        error: null,
        filter: {},
        expanded: {},
    },
})
@Injectable()
export class FranchiseesState {
    constructor(
        private franchiseesService: FranchiseesService,
        private organizationService: OrganizationsService,
        private orgPosService: OrganizationPosService
    ) {}

    @Action([FranchiseesAction]) franchiseesGet(ctx: StateContext<FranchiseesStateModel>, _: FranchiseesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new FranchiseeListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: state.filter });

        return this.franchiseesService
            .getFranchisees(filter)
            .then(list => setTimeout(() => ctx.dispatch(new FranchiseesSuccessAction({ list })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseesFailAction(err)), 0));
    }

    @Action(FranchiseesSuccessAction) franchiseesGetSuccess(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesSuccessAction) {
        const state = ctx.getState();
        const entities = action.payload.list;
        const uids = entities.map(e => e?.uid).filter(uid => uid);
        ctx.setState({ ...state, pending: false, entities, totalEntity: null, retrieved: true, error: null });
        if (uids.length > 0) {
            setTimeout(() => ctx.dispatch(new FranchiseesOrganizationsAction({ parentUids: uids })), 0);
        }
    }

    @Action([FranchiseesOrganizationsAction])
    franchiseesOrganizationsGet(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesOrganizationsAction) {
        const state = ctx.getState();
        const organizationListPending = { ...state.organizationListPending };
        const posListPending = { ...state.posListPending };

        action.payload.parentUids.forEach((uid: string) => {
            organizationListPending[uid] = true;
        });

        ctx.setState({ ...state, organizationListPending, posListPending });

        const orgFilter = new OrganizationsListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: state.filter });
        const posFilter = new OrganizationPosesListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: state.filter });

        return Promise.all([this.organizationService.getOrganizations(orgFilter), this.orgPosService.getPosList(posFilter)])
            .then(([orgResponse, posResponse]) => {
                action.payload.parentUids.forEach((uid: string) => {
                    const orgList = orgResponse.filter(v => v.parentUid === uid);
                    setTimeout(() => ctx.dispatch(new FranchiseesOrganizationsSuccessAction({ list: orgList, uid })), 0);
                });
                 orgResponse.filter((v)=> {
                    const list = posResponse.list.filter(pos => pos.organization?.uid === v.uid);
                    setTimeout(() => ctx.dispatch(new FranchiseesPosesSuccessAction({ list, uid: v.uid })), 0);
                 });
            })
            .catch(err => {
                setTimeout(() => ctx.dispatch(new FranchiseesFailAction(err)), 0);
            });
    }

    @Action(FranchiseesOrganizationsSuccessAction) franchiseesOrganizationsGetSuccess(
        ctx: StateContext<FranchiseesStateModel>,
        action: FranchiseesOrganizationsSuccessAction
    ) {
        const state = ctx.getState();
        const expanded = { ...state.expanded };
        const organizationList = { ...state.organizationList };
        const organizationListPending = { ...state.organizationListPending };
        const organizationListRetrieved = { ...state.organizationListRetrieved };
        organizationList[action.payload.uid] = action.payload.list;
        expanded[action.payload.uid] = true;
        organizationListPending[action.payload.uid] = false;
        organizationListRetrieved[action.payload.uid] = true;
        ctx.setState({ ...state, organizationList, organizationListPending, organizationListRetrieved, expanded });
   
    }

    @Action([FranchiseesPosesAction]) franchiseesPosesGet(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesPosesAction) {
        const state = ctx.getState();
        const posListPending = { ...state.posListPending };
        action.payload.organizationUids.forEach((uid: string) => {
            posListPending[uid] = true;
        });

        ctx.setState({ ...state, error: null, posListPending });

        const filter = new OrganizationPosesListGetFilter({
            pageIndex: 0,
            pageSize: 9999,
            filterData: state.filter,
        });

        return this.orgPosService
            .getPosList(filter)
            .then(resp => {
                action.payload.organizationUids.forEach((uid: string) => {
                    const list = resp.list.filter(org => org.organization?.uid === uid);
                    setTimeout(() => ctx.dispatch(new FranchiseesPosesSuccessAction({ list, uid })), 0);
                });
            })
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseesFailAction(err)), 0));
    }

    @Action(FranchiseesPosesSuccessAction) franchiseesPosesGetSuccess(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesPosesSuccessAction) {
        const state = ctx.getState();
        const expanded = { ...state.expanded };
        const organizationList = { ...state.organizationList };
        const organizationListPending = { ...state.organizationListPending };
        const organizationListRetrieved = { ...state.organizationListRetrieved };
        const posList = { ...state.posList };
        const posListPending = { ...state.posListPending };
        const posListRetrieved = { ...state.posListRetrieved };
        posList[action.payload.uid] = action.payload.list;
        expanded[action.payload.uid] = true;
        organizationListPending[action.payload.uid] = false;
        organizationListRetrieved[action.payload.uid] = true;

        ctx.setState({ ...state, organizationList, organizationListPending, organizationListRetrieved, expanded, posList, posListPending, posListRetrieved });
    }

    @Action(FranchiseesFailAction) franchiseesGetFail(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FranchiseesResetAction) franchiseesGetReset(ctx: StateContext<FranchiseesStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            pending: false,
            entities: [],
            organizationList: {},
            organizationListPending: {},
            organizationListRetrieved: {},
            retrieved: false,
            error: null,
            filter: {},
        });
    }

    @Action(FranchiseesExpandAction) franchiseesExpand(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesExpandAction) {
        const state = ctx.getState();
        const expanded = { ...state.expanded };
        expanded[action.payload.uid] = !expanded[action.payload.uid];
        ctx.setState({ ...state, expanded });
    }

    @Action(FranchiseesSetFilterAction) franchiseesSetFilter(ctx: StateContext<FranchiseesStateModel>, action: FranchiseesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter });

            setTimeout(() => ctx.dispatch(new FranchiseesAction()), 0);

            for (const uid of Object.keys(state.organizationList)) {
                setTimeout(() => ctx.dispatch(new FranchiseesOrganizationsAction({ parentUids: [uid] })), 0);
            }
        }
    }
}
