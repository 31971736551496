import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ReservationsService } from '../../services/business/reservations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Reservation } from '../../models/business/reservations/reservation.model';

import { ReservationCreateSuccessAction } from './reservation-create.actions';
import { ReservationFailAction, ReservationGetAction, ReservationGetSuccessAction, ReservationResetAction } from './reservation.actions';

export interface ReservationStateModel {
    entity: Reservation;
    pending: boolean;
    error: ErrorObject;
}

@State<ReservationStateModel>({
    name: 'reservation',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class ReservationState {

    constructor(private reservationsService: ReservationsService) { }

    @Action(ReservationGetAction) reservationGet(ctx: StateContext<ReservationStateModel>, action: ReservationGetAction) {

        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.reservationsService.getReservation(action.payload.contactUid, action.payload.organizationPosUid)
            .then(resp => setTimeout(() => ctx.dispatch(new ReservationGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ReservationFailAction(err)), 0));
    }

    @Action(ReservationGetSuccessAction) reservationGetSuccess(ctx: StateContext<ReservationStateModel>, action: ReservationGetSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ReservationFailAction) reservationGetFail(ctx: StateContext<ReservationStateModel>, action: ReservationFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ReservationResetAction) reservationGetReset(ctx: StateContext<ReservationStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(ReservationCreateSuccessAction) reservationCreateSuccess(ctx: StateContext<ReservationStateModel>, action: ReservationCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }
}
