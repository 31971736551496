import { Routes } from '@angular/router';
import { RoutingConfig } from './routing.config';

export const routes: Routes = [
    { path: '', redirectTo: RoutingConfig.routes.home.route, pathMatch: 'full' },
    { path: RoutingConfig.routes.home.route, loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
    { path: RoutingConfig.routes.callCenter.route, loadChildren: () => import('../call-center/call-center.module').then(m => m.CallCenterModule) },
    { path: RoutingConfig.routes.corporation.route, loadChildren: () => import('../corporation/corporation.module').then(m => m.CorporationModule) },
    { path: RoutingConfig.routes.clients.route, loadChildren: () => import('../clients/clients.module').then(m => m.ClientsModule) },
    { path: RoutingConfig.routes.warehouse.route, loadChildren: () => import('../warehouses/warehouses.module').then(m => m.WarehousesModule) },
    { path: RoutingConfig.routes.finance.route, loadChildren: () => import('../finances/finances.module').then(m => m.FinancesModule) },
    { path: RoutingConfig.routes.purchases.route, loadChildren: () => import('../purchases/purchases.module').then(m => m.PurchasesModule) },
    { path: RoutingConfig.routes.settings.route, loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule) },
    { path: RoutingConfig.routes.users.route, loadChildren: () => import('../evasys/users/users.module').then(m => m.UsersModule) },
    { path: RoutingConfig.routes.auth.route, loadChildren: () => import('../burns-ui-framework/auth/auth.module').then(m => m.AuthModule) },
    { path: RoutingConfig.routes.version.route, loadChildren: () => import('../burns-ui-framework/version/version.module').then(m => m.VersionModule) }
];
