import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

export class CategoryAction {
    static readonly type = '[Category Page] Category';

    constructor(public payload: { uid: string }) { }
}

export class CategorySuccessAction {
    static readonly type = '[Category API] Category Success';

    constructor(public payload: ProductCategory) { }
}

export class CategoryFailAction {
    static readonly type = '[Category API] Category Fail';

    constructor(public payload: ErrorObject) { }
}

export class CategoryResetAction {
    static readonly type = '[Category Page] Category Reset';
}
