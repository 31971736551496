import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PosContragentGetAction {
    static readonly type = '[PosContragent Page] GetContragent Get';

    constructor(public payload: string) { }
}

export class PosContragentGetSuccessAction {
    static readonly type = '[PosContragent API] GetContragent Success';

    constructor(public payload: Contragent) { }
}

export class PosContragentFailAction {
    static readonly type = '[PosContragent API] GetContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosContragentResetAction {
    static readonly type = '[PosContragent Page] GetContragent Reset';
}
