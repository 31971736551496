import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PlanCreateOrUpdateListRequest } from '../../models/business/sales-plan/plan-create-or-update-list-request.model';
import { PlanCreateOrUpdateRequest } from '../../models/business/sales-plan/plan-create-or-update-request.model';
import { PlanListFilter } from '../../models/filters/plan-list-filter.model';
import { Plan, PlanFromBack } from '../../models/business/sales-plan/plan.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class SalesPlanService extends BaseSingletonService {

    private settings: {
        service: {
            getSalesPlan: string;
            getSalesPlans: string;
            updateSalesPlan: string;
            updateSalesPlanList: string;
        }
    };

    constructor(private http: HttpService) {
        super('SalesPlanService');
        this.settings = {
            service: {
                getSalesPlan: '/crm/v1/plans/{0}/users/{1}',
                getSalesPlans: '/crm/v1/plans/{0}?term={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                updateSalesPlan: '/crm/v1/plans/{0}/users/{1}',
                updateSalesPlanList: '/crm/v1/plans/{0}'
            }
        };
    }

    /**
     * Get sales plan for user
     */
    public async getSalesPlan(year: number, userUid: string): Promise<Plan> {
        return this.http.get<{ data: Plan }>(String.format(this.settings.service.getSalesPlan, year, userUid))
            .then(res => res.data);
    }

    /**
     * Get sales plans list
     */
    public async getSalesPlans(year: number, filter: PlanListFilter): Promise<PaginationResult<Plan>> {
        return this.http.get<PaginationResult<PlanFromBack>>(String.format(this.settings.service.getSalesPlans, year, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then(res =>
                ({
                    total: res.total,
                    pageIndex: filter.pageIndex,
                    pageSize: filter.pageSize,
                    list: res.list.map(x => ({ ...x, uid: x.user.uid }))
                }));
    }

    /**
     * Update sales plan for user
     */
    public async updateSalesPlan(year: number, userUid: string, request: PlanCreateOrUpdateRequest): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateSalesPlan, year, userUid), request);
    }

    public async updateSalesPlanList(year: number, request: PlanCreateOrUpdateListRequest[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateSalesPlanList, year), { plans: request });
    }
}
