import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RequisitesService } from '../../services/business/requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { RequisiteUpdateSuccessAction } from './requisite-update.actions';
import { RequisiteAction, RequisiteFailAction, RequisiteResetAction, RequisiteSuccessAction } from './requisite.actions';
import { Requisite } from '../../models/business/contragents/requisites.model';

export interface RequisiteStateModel {
    pending: boolean;
    entity: Requisite;
    error: ErrorObject;
}

@State<RequisiteStateModel>({
    name: 'requisite',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class RequisiteState {
    constructor(private requisitesService: RequisitesService) { }

    @Action(RequisiteAction) async requisite(ctx: StateContext<RequisiteStateModel>, action: RequisiteAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.requisitesService.getRequisite(action.payload.uid, action.payload.contragenUid)
            .then(res => setTimeout(() => ctx.dispatch(new RequisiteSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RequisiteFailAction(err)), 0));
    }

    @Action(RequisiteSuccessAction) requisiteSuccess(ctx: StateContext<RequisiteStateModel>, action: RequisiteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(RequisiteFailAction) requisiteFail(ctx: StateContext<RequisiteStateModel>, action: RequisiteFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RequisiteResetAction) requisiteReset(ctx: StateContext<RequisiteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(RequisiteUpdateSuccessAction) requisiteUpdateSuccess(ctx: StateContext<RequisiteStateModel>, action: RequisiteUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
