import { ContragentUpdateRequest } from '../../models/business/contragents/contragent-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContragentUpdateAction {
    static readonly type = '[Booking Page] UpdateContragent';

    constructor(public payload: { uid: string, request: ContragentUpdateRequest }) { }
}

export class ContragentUpdateSuccessAction {
    static readonly type = '[Contragent API] UpdateContragent Success';

    constructor(public payload: { uid: string, request: ContragentUpdateRequest }) { }
}

export class ContragentUpdateFailAction {
    static readonly type = '[Contragent API] UpdateContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentUpdateResetAction {
    static readonly type = '[Booking Page] UpdateContragent Reset';
}
