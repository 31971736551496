import { OrderCreateOrUpdateRequest } from '../models/business/orders/order-create-or-update-request.model';
import { OrderListItem } from '../models/business/orders/order-list-item.model';

export class OrderModelMapper {
    public static Map(order: OrderListItem, request: OrderCreateOrUpdateRequest): OrderListItem {
        const shippingAddress = {
            countryCode: request.shippingCountryCode,
            countryName: order.shippingAddress?.countryName,
            placeId: request.shippingAddressId,
            name: request.shippingAddress,
            fullName: request.shippingAddress,
            text: request.shippingAddressText,
            googleMapsUrl: order.shippingAddress?.googleMapsUrl,
            latitude: order.shippingAddress?.latitude,
            longitude: order.shippingAddress?.longitude,
        };

        return { ...order, ...request, shippingAddress };
    }
}
