import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WarehousesService } from '../../services/business/warehouses.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { WarehousesAction, WarehousesFailAction, WarehousesResetAction, WarehousesSetFilterAction, WarehousesSuccessAction } from './warehouses.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WarehouseListItem } from '../../models/business/warehouses/warehouse-list-item.model';
import { WarehousesListFilter, WarehousesListFilterData } from '../../models/filters/warehouses-list-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface WarehousesStateModel extends PaginationStateModel<WarehouseListItem> {
    uids: string[];
    entities: { [uid: string]: WarehouseListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: WarehousesListFilterData;
    total: number;
}

@State<WarehousesStateModel>({
    name: 'warehouses',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class WarehousesState extends PaginationStateBase {
    constructor(private warehousesService: WarehousesService) {
        super();
    }

    @Action([WarehousesAction]) warehousesGet(ctx: StateContext<WarehousesStateModel>, action: WarehousesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new WarehousesListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.warehousesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.warehousesService.getWarehouseList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new WarehousesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WarehousesFailAction(err)), 0));
    }

    @Action(WarehousesSuccessAction) warehousesGetSuccess(ctx: StateContext<WarehousesStateModel>, action: WarehousesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.warehousesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(WarehousesFailAction) warehousesGetFail(ctx: StateContext<WarehousesStateModel>, action: WarehousesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WarehousesResetAction) warehousesGetReset(ctx: StateContext<WarehousesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(WarehousesSetFilterAction) warehousesSetFilter(ctx: StateContext<WarehousesStateModel>, action: WarehousesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new WarehousesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
