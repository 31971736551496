import { CustomerCreateOrUpdateRequest } from '../../models/business/customers/customer-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CustomerUpdateAction {
    static readonly type = '[Booking Page] UpdateCustomer';

    constructor(public payload: { uid: string, request: CustomerCreateOrUpdateRequest }) { }
}

export class CustomerUpdateSuccessAction {
    static readonly type = '[Customer API] UpdateCustomer Success';

    constructor(public payload: { uid: string, request: CustomerCreateOrUpdateRequest }) { }
}

export class CustomerUpdateFailAction {
    static readonly type = '[Customer API] UpdateCustomer Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerUpdateResetAction {
    static readonly type = '[Booking Page] UpdateCustomer Reset';
}
