import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Acceptance } from '../../models/business/acceptances/acceptance.model';

export class AcceptanceGetAction {
    static readonly type = '[Acceptance Page] GetAcceptance Get';

    constructor(public payload: string) { }
}

export class AcceptanceGetSuccessAction {
    static readonly type = '[Acceptance API] GetAcceptance Success';

    constructor(public payload: Acceptance) { }
}

export class AcceptanceFailAction {
    static readonly type = '[Acceptance API] GetAcceptance Fail';

    constructor(public payload: ErrorObject) { }
}

export class AcceptanceResetAction {
    static readonly type = '[Acceptance Page] GetAcceptance Reset';
}
