import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { SupplierInvoiceListGetFilterData } from '../../models/filters/supplier-invoice-list-get-filter.model';
import { SupplierInvoiceListItem } from '../../models/business/supplier-invoices/supplier-invoice-list-item.model';

export class SupplierInvoicesAction {
    static readonly type = '[SupplierInvoicess Page] GetSupplierInvoices';

    constructor(public payload: PaginationRequest) { }
}

export class SupplierInvoicesSuccessAction {
    static readonly type = '[SupplierInvoices API] GetSupplierInvoices Success';

    constructor(public payload: { list: PaginationResult<SupplierInvoiceListItem>, pagination: PaginationRequest, totalAmount: number, totalPaidAmount: number }) { }
}

export class SupplierInvoicesFailAction {
    static readonly type = '[SupplierInvoices API] GetSupplierInvoices Fail';

    constructor(public payload: ErrorObject) { }
}

export class SupplierInvoicesResetAction {
    static readonly type = '[SupplierInvoices Page] GetSupplierInvoices Reset';
}

export class SupplierInvoicesSetFilterAction {
    static readonly type = '[SupplierInvoices API] SetFilter';

    constructor(public payload: SupplierInvoiceListGetFilterData) { }
}
