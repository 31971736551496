import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContragentSortingFieldsEnum } from '../../../../models/business/contragents/contragent-sorting-fields.enum';
import { ContragentsListFilterData } from '../../../../models/filters/contragents-list-filter.model';
import { SortOrder } from '../../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'contragent-header',
    templateUrl: './contragent-header.component.html',
    styleUrls: ['./contragent-header.component.scss', '../contragent-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContragentHeaderComponent {

    @Input() filter: ContragentsListFilterData;
    @Input() listConfig: any;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public contragentSortingFields = ContragentSortingFieldsEnum;

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.sortingChange.emit($event);
    }
}
