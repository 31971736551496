import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Media } from '../../../../burns-ui-framework/shared/models/business/media/media.model';
import { MediasFilterData } from '../../models/filters/medias-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ProductMediaListAction {
    static readonly type = '[ProductMediaList Page] GetProductMediaList';

    constructor(public payload: { uid: string, request: PaginationRequest }) { }
}

export class ProductMediaListSuccessAction {
    static readonly type = '[ProductMediaList API] GetProductMediaList Success';

    constructor(public payload: { list: PaginationResult<Media>, pagination: PaginationRequest }) { }
}

export class ProductMediaListFailAction {
    static readonly type = '[ProductMediaList API] GetProductMediaList Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductMediaListResetAction {
    static readonly type = '[ProductMediaList Page] GetProductMediaList Reset';
}

export class ProductMediaListSetFilterAction {
    static readonly type = '[ProductMediaList API] SetFilter';

    constructor(public payload: { uid: string, filter: MediasFilterData }) { }
}
