import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { TaskCreateOrUpdateRequest } from '../../models/business/tasks/task-create-or-update.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { TaskCreateAction, TaskCreateResetAction } from './task-create.actions';

@Injectable({
    providedIn: 'root'
})
export class TaskCreateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TaskCreateDispatchers');
    }

    public dispatchTaskCreateAction(request: TaskCreateOrUpdateRequest) {
        this.store.dispatch(new TaskCreateAction(request));
    }

    public dispatchTaskCreateResetAction() {
        this.store.dispatch(new TaskCreateResetAction());
    }
}
