import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { UserRegistrationRequest } from '../../models/business/user/user-registration-request.model';

export class UserUpdateAction {
    static readonly type = '[User Page] UpdateUser';

    constructor(public payload: { uid: string, request: UserRegistrationRequest }) { }
}

export class UserUpdateSuccessAction {
    static readonly type = '[User API] UpdateUser Success';

    constructor(public payload: { uid: string, request: UserRegistrationRequest }) { }
}

export class UserUpdateFailAction {
    static readonly type = '[User API] UpdateUser Fail';

    constructor(public payload: ErrorObject) { }
}

export class UserUpdateResetAction {
    static readonly type = '[User Page] UpdateUser Reset';
}
