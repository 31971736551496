import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { SupplierInvoicesService } from '../../services/business/supplier-invoices.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { SupplierInvoiceCreateSuccessAction } from './supplier-invoice-create.actions';
import { SupplierInvoiceDeleteSuccessAction } from './supplier-invoice-delete.actions';
import { SupplierInvoiceUpdateSuccessAction } from './supplier-invoice-update.actions';
import { SupplierInvoicesAction, SupplierInvoicesFailAction, SupplierInvoicesResetAction, SupplierInvoicesSetFilterAction, SupplierInvoicesSuccessAction } from './supplier-invoices.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { SupplierInvoiceListGetFilter, SupplierInvoiceListGetFilterData } from '../../models/filters/supplier-invoice-list-get-filter.model';
import { SupplierInvoiceListItem } from '../../models/business/supplier-invoices/supplier-invoice-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface SupplierInvoicesStateModel extends PaginationFilteredStateModel<SupplierInvoiceListItem, SupplierInvoiceListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: SupplierInvoiceListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: SupplierInvoiceListGetFilterData;
    total: number;
    totalAmount: number;
    totalPaidAmount: number;
}

@State<SupplierInvoicesStateModel>({
    name: 'supplierInvoices',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, totalAmount: 0, totalPaidAmount: 0, filter: {} }
})
@Injectable()
export class SupplierInvoicesState extends PaginationFilteredStateBase {
    constructor(private supplierInvoicesService: SupplierInvoicesService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([SupplierInvoicesAction]) productsGet(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoicesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'supplierInvoice');
        ctx.setState({ ...state, filter: filterData });

        const filter = new SupplierInvoiceListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.supplierInvoicesService.getSupplierInvoiceList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new SupplierInvoicesSuccessAction({ list: resp, pagination: action.payload, totalAmount: resp.totalAmount, totalPaidAmount: resp.totalPaidAmount })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SupplierInvoicesFailAction(err)), 0));
    }

    @Action(SupplierInvoicesSuccessAction) supplierInvoiceGetSuccess(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoicesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, totalPaidAmount: action.payload.totalPaidAmount, totalAmount: action.payload.totalAmount, allItemsLoaded, index });
    }

    @Action(SupplierInvoicesFailAction) supplierInvoiceGetFail(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoicesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(SupplierInvoicesResetAction) supplierInvoiceGetReset(ctx: StateContext<SupplierInvoicesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(SupplierInvoicesSetFilterAction) usersSetFilter(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoicesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'supplierInvoice');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new SupplierInvoicesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(SupplierInvoiceCreateSuccessAction) supplierInvoiceCreateSuccess(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoiceCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(SupplierInvoiceUpdateSuccessAction) supplierInvoiceUpdateSuccess(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoiceUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(SupplierInvoiceDeleteSuccessAction) supplierInvoiceDeleteSuccess(ctx: StateContext<SupplierInvoicesStateModel>, action: SupplierInvoiceDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
