import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PersonnelsService } from '../../services/business/personnels.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PersonnelUpdateSuccessAction } from './personnel-update.actions';
import { PersonnelFailAction, PersonnelGetAction, PersonnelGetSuccessAction, PersonnelResetAction } from './personnel.actions';
import { Personnel } from '../../models/business/personnels/personnel.model';

export interface PersonnelStateModel {
    entity: Personnel;
    pending: boolean;
    error: ErrorObject;
}

@State<PersonnelStateModel>({
    name: 'personnel',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PersonnelState {
    constructor(private personnelsService: PersonnelsService) { }

    @Action(PersonnelGetAction) personnelGet(ctx: StateContext<PersonnelStateModel>, action: PersonnelGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.personnelsService.getPersonnel(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PersonnelGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PersonnelFailAction(err)), 0));
    }

    @Action(PersonnelGetSuccessAction) personnelGetSuccess(ctx: StateContext<PersonnelStateModel>, action: PersonnelGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PersonnelFailAction) personnelGetFail(ctx: StateContext<PersonnelStateModel>, action: PersonnelFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PersonnelResetAction) personnelGetReset(ctx: StateContext<PersonnelStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(PersonnelUpdateSuccessAction) personnelUpdateSuccess(ctx: StateContext<PersonnelStateModel>, action: PersonnelUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
