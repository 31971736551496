import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { Product } from '../../models/business/catalog/product.model';
import { ProductListItemAggregation } from '../../models/business/products/product-list-item-aggregation.model';
import { ProductsListFilterData } from '../../models/filters/products-list-filter.model';

export class ProductNomenclaturesAction {
    static readonly type = '[ProductNomenclatures Page] GetProductNomenclatures';

    constructor(public payload: PaginationRequest) { }
}

export class ProductNomenclaturesSuccessAction {
    static readonly type = '[ProductNomenclatures API] GetProductNomenclatures Success';

    constructor(public payload: { list: PaginationResult<Product>, pagination: PaginationRequest }) { }
}

export class ProductNomenclaturesFailAction {
    static readonly type = '[ProductNomenclatures API] GetProductNomenclatures Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductNomenclaturesResetAction {
    static readonly type = '[ProductNomenclatures Page] GetProductNomenclatures Reset';
}

export class ProductNomenclaturesSetFilterAction {
    static readonly type = '[ProductNomenclatures API] SetFilter';

    constructor(public payload: ProductsListFilterData) { }
}
