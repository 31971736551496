import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContragentsSearchState, ContragentsSearchStateModel } from './contragents-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class ContragentsSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([ContragentsSearchState], (state: ContragentsSearchStateModel) => state.pending);

    static readonly getContragentsSearch = createSelector([ContragentsSearchState], (state: ContragentsSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([ContragentsSearchState], (state: ContragentsSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([ContragentsSearchState], (state: ContragentsSearchStateModel) => state.error);

    static readonly getAllContragentsSearchLoaded = createSelector([ContragentsSearchState], (state: ContragentsSearchStateModel) => state.allItemsLoaded);

    @Select(ContragentsSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(ContragentsSearchSelectors.getContragentsSearch) contragents$: Observable<ContragentListItem[]>;

    @Select(ContragentsSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(ContragentsSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(ContragentsSearchSelectors.getAllContragentsSearchLoaded) allContragentsSearchLoaded$: Observable<boolean>;

    constructor() {
        super('ContragentsSearchSelectors');
    }
}
