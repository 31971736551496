import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class VatsAction {
    static readonly type = '[Vats Page] Vats';
}

export class VatsSuccessAction {
    static readonly type = '[Vats API] Vats Success';

    constructor(public payload: number[]) { }
}

export class VatsFailAction {
    static readonly type = '[Vats API] Vats Fail';

    constructor(public payload: ErrorObject) { }
}

export class VatsResetAction {
    static readonly type = '[Vats Page] Vats Reset';
}
