import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContactSortingFieldsEnum } from '../../../../models/business/contacts/contact-sorting-fields.enum';
import { ContactsListFilterData } from '../../../../models/filters/contacts-list-filter.model';
import { SortOrder } from '../../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'contact-header',
    templateUrl: './contact-header.component.html',
    styleUrls: ['./contact-header.component.scss', '../contact-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactHeaderComponent {

    @Input() filter: ContactsListFilterData;
    @Input() listConfig: any;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public contactSortingFields = ContactSortingFieldsEnum;

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.sortingChange.emit($event);
    }
}
