import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { InventoryListGetFilterData } from '../../models/filters/inventory-list-get-filter.model';
import { InventoryListItem } from '../../models/business/inventories/inventory-list-item.model';

export class InventoriesAction {
    static readonly type = '[Inventoriess Page] GetInventories';

    constructor(public payload: PaginationRequest) { }
}

export class InventoriesSuccessAction {
    static readonly type = '[Inventories API] GetInventories Success';

    constructor(public payload: { list: PaginationResult<InventoryListItem>, pagination: PaginationRequest }) { }
}

export class InventoriesFailAction {
    static readonly type = '[Inventories API] GetInventories Fail';

    constructor(public payload: ErrorObject) { }
}

export class InventoriesResetAction {
    static readonly type = '[Inventories Page] GetInventories Reset';
}

export class InventoriesSetFilterAction {
    static readonly type = '[Inventories API] SetFilter';

    constructor(public payload: InventoryListGetFilterData) { }
}
