import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DecompositionDeleteAction {
    static readonly type = '[My Decomposition Page] DeleteDecomposition';

    constructor(public payload: { uid: string }) { }
}

export class DecompositionDeleteSuccessAction {
    static readonly type = '[My Decompositions API] DeleteDecomposition Success';

    constructor(public payload: { uid: string }) { }
}

export class DecompositionDeleteFailAction {
    static readonly type = '[My Decompositions API] DeleteDecomposition Fail';

    constructor(public payload: ErrorObject) { }
}

export class DecompositionDeleteResetAction {
    static readonly type = '[My Decomposition Page] DeleteDecomposition Reset';
}
