import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WriteOffCreateOrUpdateRequest } from '../../models/business/write-offs/write-off-create-or-update-request.model';

export class WriteOffUpdateAction {
    static readonly type = '[Booking Page] UpdateWriteOff';

    constructor(public payload: { uid: string, request: WriteOffCreateOrUpdateRequest }) { }
}

export class WriteOffUpdateSuccessAction {
    static readonly type = '[WriteOff API] UpdateWriteOff Success';

    constructor(public payload: { uid: string, request: WriteOffCreateOrUpdateRequest }) { }
}

export class WriteOffUpdateFailAction {
    static readonly type = '[WriteOff API] UpdateWriteOff Fail';

    constructor(public payload: ErrorObject) { }
}

export class WriteOffUpdateResetAction {
    static readonly type = '[Booking Page] UpdateWriteOff Reset';
}
