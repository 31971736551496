import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CompaniesService } from '../../services/business/companies.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { CompanyFeedAction, CompanyFeedFailAction, CompanyFeedResetAction, CompanyFeedSetFilterAction, CompanyFeedSuccessAction } from './company-feed.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { FeedListGetFilter, FeedListGetFilterData } from '../../models/filters/feed-list-get-filter.model';
import { Feed } from '../../models/business/feed/feed.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface CompanyFeedStateModel extends PaginationStateModel<Feed> {
    uids: string[];
    entities: { [uid: string]: Feed };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: FeedListGetFilterData;
    total: number;
}

@State<CompanyFeedStateModel>({
    name: 'companyFeed',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class CompanyFeedState extends PaginationStateBase {
    constructor(private companiesService: CompaniesService) {
        super();
    }

    @Action([CompanyFeedAction]) tasksGet(ctx: StateContext<CompanyFeedStateModel>, action: CompanyFeedAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new FeedListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.tasksAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.companiesService.getCompanyFeed(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CompanyFeedSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompanyFeedFailAction(err)), 0));
    }

    @Action(CompanyFeedSuccessAction) tasksGetSuccess(ctx: StateContext<CompanyFeedStateModel>, action: CompanyFeedSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.tasksAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CompanyFeedFailAction) tasksGetFail(ctx: StateContext<CompanyFeedStateModel>, action: CompanyFeedFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CompanyFeedResetAction) tasksGetReset(ctx: StateContext<CompanyFeedStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(CompanyFeedSetFilterAction) usersSetFilter(ctx: StateContext<CompanyFeedStateModel>, action: CompanyFeedSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new CompanyFeedAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
