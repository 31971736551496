import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { OrganizationPosesSearchAction, OrganizationPosesSearchResetAction, OrganizationPosesSearchSetFilterAction } from './organization-poses-search.actions';
import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPosesSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('OrganizationPosesSearchDispatchers');
    }

    public dispatchOrganizationPosesSearchAction(request: PaginationRequest) {
        this.store.dispatch(new OrganizationPosesSearchAction(request));
    }

    public dispatchOrganizationPosesSearchResetAction() {
        this.store.dispatch(new OrganizationPosesSearchResetAction());
    }

    public dispatchOrganizationPosesSearchSetFilterAction(filterData: OrganizationPosesListGetFilterData) {
        this.store.dispatch(new OrganizationPosesSearchSetFilterAction(filterData));
    }
}
