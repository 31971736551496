import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosCreateOrUpdateRequest } from '../../models/business/pos/pos-create-or-update.model';

export class PosUpdateAction {
    static readonly type = '[Booking Page] UpdatePos';

    constructor(public payload: { uid: string, request: PosCreateOrUpdateRequest }) { }
}

export class PosUpdateSuccessAction {
    static readonly type = '[Pos API] UpdatePos Success';

    constructor(public payload: { uid: string, request: PosCreateOrUpdateRequest }) { }
}

export class PosUpdateFailAction {
    static readonly type = '[Pos API] UpdatePos Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosUpdateResetAction {
    static readonly type = '[Booking Page] UpdatePos Reset';
}
