import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { SupplierInvoiceCreateOrUpdateRequest } from '../../models/business/supplier-invoices/supplier-invoice-create-or-update-request.model';

export class SupplierInvoiceUpdateAction {
    static readonly type = '[Booking Page] UpdateSupplierInvoice';

    constructor(public payload: { uid: string, request: SupplierInvoiceCreateOrUpdateRequest }) { }
}

export class SupplierInvoiceUpdateSuccessAction {
    static readonly type = '[SupplierInvoice API] UpdateSupplierInvoice Success';

    constructor(public payload: { uid: string, request: SupplierInvoiceCreateOrUpdateRequest }) { }
}

export class SupplierInvoiceUpdateFailAction {
    static readonly type = '[SupplierInvoice API] UpdateSupplierInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class SupplierInvoiceUpdateResetAction {
    static readonly type = '[Booking Page] UpdateSupplierInvoice Reset';
}
