import { Contragent } from '../../models/business/contragents/contragent.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContragentGetAction {
    static readonly type = '[Contragent Page] GetContragent Get';

    constructor(public payload: string) { }
}

export class ContragentGetSuccessAction {
    static readonly type = '[Contragent API] GetContragent Success';

    constructor(public payload: Contragent) { }
}

export class ContragentFailAction {
    static readonly type = '[Contragent API] GetContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentResetAction {
    static readonly type = '[Contragent Page] GetContragent Reset';
}
