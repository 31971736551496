import { FeedType } from '../business/feed/feed-type.enum';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class FeedListGetFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public userUid?: string;
    public types?: FeedType[];
    public companyUid?: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: FeedListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'CreateDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Desc;
        this.userUid = (args && args.filterData) ? args.filterData.userUid : null;
        this.types = (args && args.filterData) ? args.filterData.types : null;
        this.companyUid = (args && args.filterData) ? args.filterData.companyUid : null;
    }
}

export class FeedListGetFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public userUid?: string;
    public types?: FeedType[];
    public companyUid?: string;
}
