import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Pos } from '../../models/business/pos/pos.model';

export class ContactPosGetAction {
    static readonly type = '[ContactPos Page] GetPos Get';

    constructor(public payload: string) { }
}

export class ContactPosGetSuccessAction {
    static readonly type = '[ContactPos API] GetPos Success';

    constructor(public payload: Pos) { }
}

export class ContactPosFailAction {
    static readonly type = '[ContactPos API] GetPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactPosResetAction {
    static readonly type = '[ContactPos Page] GetPos Reset';
}
