export enum CompanyStatus {
    New = 1,

    Client = 2,

    Active = 3,

    Vip = 4,

    Lost = 10,

    Liquidated = 11
}
