import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NotesService } from '../../services/business/note.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { NoteUpdateDocumentsAction, NoteUpdateDocumentsFailAction, NoteUpdateDocumentsResetAction, NoteUpdateDocumentsSuccessAction } from './note-update-documents.actions';

export interface NoteUpdateDocumentsStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<NoteUpdateDocumentsStateModel>({
    name: 'noteUpdateDocuments',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class NoteUpdateDocumentsState {
    constructor(private notesService: NotesService) { }

    @Action(NoteUpdateDocumentsAction) async noteUpdate(ctx: StateContext<NoteUpdateDocumentsStateModel>, action: NoteUpdateDocumentsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        const createPromise = action.payload.documentsToAdd && action.payload.documentsToAdd.length > 0
            ? this.notesService.addNoteDocuments(action.payload.uid, action.payload.documentsToAdd)
            : Promise.resolve(null);

        const deletePromise = action.payload.documentsToRemove && action.payload.documentsToRemove.length > 0
            ? this.notesService.deleteNoteDocuments(action.payload.uid, action.payload.documentsToRemove)
            : Promise.resolve(true);

        return Promise.all([createPromise, deletePromise])
            .then(res => setTimeout(() => ctx.dispatch(new NoteUpdateDocumentsSuccessAction({ uid: action.payload.uid, entity: res[0], documentsToRemove: action.payload.documentsToRemove })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new NoteUpdateDocumentsFailAction(err)), 0));
    }

    @Action(NoteUpdateDocumentsSuccessAction) noteUpdateSuccess(ctx: StateContext<NoteUpdateDocumentsStateModel>, _: NoteUpdateDocumentsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(NoteUpdateDocumentsFailAction) noteUpdateFail(ctx: StateContext<NoteUpdateDocumentsStateModel>, action: NoteUpdateDocumentsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(NoteUpdateDocumentsResetAction) noteUpdateReset(ctx: StateContext<NoteUpdateDocumentsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
