
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContragentDeleteAction {
    static readonly type = '[My Contragent Page] DeleteContragent';

    constructor(public payload: { uid: string }) { }
}

export class ContragentDeleteSuccessAction {
    static readonly type = '[My Contragents API] DeleteContragent Success';

    constructor(public payload: { uid: string }) { }
}

export class ContragentDeleteFailAction {
    static readonly type = '[My Contragents API] DeleteContragent Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentDeleteResetAction {
    static readonly type = '[My Contragent Page] DeleteContragent Reset';
}
