import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { PlacesSuggestionsAction, PlacesSuggestionsResetAction } from './places-suggestions.actions';

import { YandexPlaceInputComponentType } from '../models/yandex-place-input-component-type.enum';

@Injectable({
    providedIn: 'root'
})
export class PlacesSuggestionsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('PlacesSuggestionsDispatchers');
    }

    public dispatchPlacesSuggestionsAction(term: string, level: YandexPlaceInputComponentType, fullNameLevelFrom: YandexPlaceInputComponentType, lowerCornerLatitude: number, lowerCornerLongitude: number, upperCornerLatitude: number, upperCornerLongitude: number) {
        this.store.dispatch(new PlacesSuggestionsAction({ term, level, fullNameLevelFrom, lowerCornerLatitude, lowerCornerLongitude, upperCornerLatitude, upperCornerLongitude }));
    }

    public dispatchPlacesSuggestionsResetAction() {
        this.store.dispatch(new PlacesSuggestionsResetAction());
    }
}
