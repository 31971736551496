import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Product } from '../../../evasys/shared/models/business/catalog/product.model';

export class DCRProductAction {
    static readonly type = '[DCRProduct Page] DCRProduct';

    constructor(public payload: { uid: string, lineNumber: number }) { }
}

export class DCRProductSuccessAction {
    static readonly type = '[DCRProduct API] DCRProduct Success';

    constructor(public payload: { uid: string, lineNumber: number, product: Product } ) { }
}

export class DCRProductFailAction {
    static readonly type = '[DCRProduct API] DCRProduct Fail';

    constructor(public payload: ErrorObject) { }
}

export class DCRProductResetAction {
    static readonly type = '[DCRProduct Page] DCRProduct Reset';
}
