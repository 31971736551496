import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, Type, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { DcrNotesFormComponent } from './dcr-notes-from/dcr-notes-from.component';
import { ExgFormComponent } from '../../../burns-ui-framework/shared/components/abstract/exg-form.component';

import { DialogService } from '../../../burns-ui-framework/shared/services/common/dialog.service';
import { ExgTranslateService } from '../../../burns-ui-framework/shared/services/common/exg-translate.service';
import { SnackbarService } from '../../../burns-ui-framework/shared/services/common/snackbar.service';

import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { DCRFromProductAggregation } from '../../models/business/dcr/dcr-from-product-aggregation.model';
import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { DCRProduct } from '../../models/business/dcr/dcr-product.model';
import { DcrStatus } from '../../models/business/dcr/dcr-status.enum';
import { DCRToProductAggregation } from '../../models/business/dcr/dcr-to-product-aggregation.model';
import { DialogResult } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { ExgDialogMode } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-mode.model';
import { ExgDialogResultEvent } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-result-event.model';
import { OrganizationListItem } from '../../../evasys/shared/models/business/organizations/organization-list-item.model';
import { OrganizationPosesListGetFilterData } from '../../../evasys/shared/models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../../evasys/shared/models/business/organization-pos/organization-pos-list-item.model';
import { ProductListItemAggregation } from '../../../evasys/shared/models/business/products/product-list-item-aggregation.model';
import { ProductsListGetFilterData } from '../../../evasys/shared/models/filters/products-list-get-filter.model';
import { Profile } from '../../../burns-ui-framework/shared/models/business/user/profile.model';
import { UnitTypeListItem } from '../../../evasys/shared/models/business/nomenclature/unit-type-list-item.model';
import { UserForTyping } from '../../../evasys/shared/models/business/user/user-for-typing.model';
import { WarehouseListItem } from '../../../evasys/shared/models/business/warehouses/warehouse-list-item.model';
import { WarehousesListFilterData } from '../../../evasys/shared/models/filters/warehouses-list-filter.model';

import { DateUtils } from '../../../burns-ui-framework/shared/utils/date-utils';
import { Utils } from '../../../burns-ui-framework/shared/utils/utils';

@Component({
    selector: 'dcr-form',
    templateUrl: './dcr-form.component.html',
    styleUrls: ['./dcr-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DCRFormComponent extends ExgFormComponent implements OnChanges, OnDestroy {
    @Input() profile: Profile;

    @Input() title: string;
    @Input() deleteTitle: string;
    @Input() emptyStubAddTitle: string;
    @Input() isDecompositions: boolean;
    @Input() organizationPosesSearch: OrganizationPosListItem[];
    @Input() entity: DCRItem;
    @Input() products: ProductListItemAggregation[];
    @Input() product: DCRProduct;
    @Input() organizationsSearch: OrganizationListItem[];
    @Input() warehouses: WarehouseListItem[];
    @Input() usersSearch: UserForTyping[];
    @Input() unitTypes: UnitTypeListItem[];
    @Input() pending: boolean;
    @Input() error: ErrorObject | string;

    @Output() readonly createDCR = new EventEmitter<DCRCreateOrUpdateRequest>();
    @Output() readonly updateDCR = new EventEmitter<{ uid: string, request: DCRCreateOrUpdateRequest }>();
    @Output() readonly deleteDCR = new EventEmitter<{ uid: string }>();
    @Output() readonly organizationSearchPoses = new EventEmitter<OrganizationPosesListGetFilterData>();
    @Output() readonly backClick = new EventEmitter<void>();
    @Output() readonly searchProducts = new EventEmitter<ProductsListGetFilterData>();
    @Output() readonly searchOrganizations = new EventEmitter<string>();
    @Output() readonly searchWarehouse = new EventEmitter<WarehousesListFilterData>();
    @Output() readonly searchProduct = new EventEmitter<{ uid: string, lineNumber: number }>();

    public number: FormControl;
    public date: FormControl;
    public time: FormControl;
    public status: FormControl;
    public warehouseFrom: FormControl;
    public warehouseTo: FormControl;
    public organization: FormControl;
    public responsibleUser: FormControl;
    public organizationPos: FormControl;
    public notes: FormControl;

    public fromProducts: FormArray;
    public toProducts: FormArray;

    public dcrStatuses$ = Utils.enumToTranslatedSelectData(DcrStatus, this.translate);
    public editState$ = new BehaviorSubject<boolean>(false);
    public previourseState$ = new BehaviorSubject(null);
    public validateList$ = new Subject<boolean>();

    public selectedLine: number = null;
    public dcrStatusEnum = DcrStatus;

    public dialogMode = ExgDialogMode;
    public componentData: { component: any, inputs: any };
    public showDialog: boolean;
    public dialogPosition = { right: '0' };

    private timeZone = DateUtils.timeZone;
    private unsubscribe$ = new Subject();

    constructor(private formBuilder: FormBuilder, private dialogService: DialogService, private translate: ExgTranslateService, private snackbar: SnackbarService) {
        super();
        this.createForm();
        combineLatest([this.mainForm.valueChanges, this.previourseState$]).pipe(takeUntil(this.unsubscribe$), map(x => x[0] !== x[1])).subscribe(x => this.editState$.next(x));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.entity && this.entity) {
            this.setFormData();
        }

        if (changes.profile && this.profile) {
            this.timeZone = this.profile.user?.timeZoneId || DateUtils.timeZone;
            this.applyTimeZoneForForm();
        }

        if (changes.product && this.product) {
            const productWeight = this.product.product.attributes?.information?.weight;
            const unitType = this.product.product.unitType;

            const fromProductIndex = this.fromProducts.value.findIndex(fProduct => fProduct.product.uid === this.product.uid && fProduct.lineNumber === this.product.lineNumber);
            if (fromProductIndex <= -1) {
                return;
            }

            const fromProduct = this.fromProducts.at(fromProductIndex).value;
            for (const productPart of (this.product.product?.parts || [])) {
                const isSameUnitType = !!unitType?.uid && !!productPart.unitType?.uid && unitType.uid === productPart.unitType.uid
                const percent = productWeight > 0 && productPart.quantity
                    ? productPart.quantity / productWeight
                    : 0;

                const toProduct = {
                    product: {
                        uid: productPart.productUid,
                        code: productPart.code,
                        name: productPart.name,
                        characteristicGroupUid: null,
                        imagePreviewUrl: null
                    },
                    unitType: productPart.unitType,
                    lineNumber: null,
                    fromProductUid: this.product.uid,
                    fromLineNumber: this.product.lineNumber,
                    amount: isSameUnitType ? percent * fromProduct.amount : 0,
                    quantity: productPart.quantity,
                    percent,
                    price: fromProduct.price
                };

                this.addToProductInternal(toProduct);
            }

            if (changes.profile && this.profile && !this.responsibleUser.value) {
                this.responsibleUser.patchValue([{ uid: this.profile.user.uid, fullName: `${this.profile.user.firstName} ${this.profile.user.lastName}` }]);
                this.previourseState$.next(this.mainForm.value);
            }
        }
    }

    public ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public onBackClick() {
        this.backClick.emit();
    }

    public onSelectClick(rowIndex: number) {
        if (rowIndex === this.selectedLine) {
            return;
        }

        if (!this.fromProducts.valid) {
            this.validateList$.next(true);
            return;
        }

        this.selectedLine = rowIndex;
    }

    public onCancelClick() {
        if (this.editState$.value && this.entity && this.entity.uid) {
            this.setFormData();
            this.previourseState$.next(this.mainForm.value);
        } else {
            this.backClick.emit();
        }
    }

    public async onDelete() {
        if (await this.dialogService.showConfirm({ title: this.deleteTitle, description: `Are you sure you want to delete this ${this.title}?` }) === DialogResult.Confirm) {
            this.deleteDCR.emit({ uid: this.entity.uid });
        }
    }

    public organizationDisplayValueFunction(item) {
        return item?.shortName;
    }

    public onSearchProducts($event: string) {
        this.searchProducts.emit({
            term: $event,
            isArchived: false,
            organizationUids: this.organization.value?.uid ? [this.organization.value?.uid] : null,
            organizationPosUids: this.organizationPos.value?.uid ? [this.organizationPos.value?.uid] : null,
            warehouseUid: this.warehouseFrom.value?.uid,
        });
    }

    public onSelectProduct($event: ProductListItemAggregation, index: number) {
        if (!!$event && !!$event.uid) {
            const lineNumber = +(<FormGroup>this.fromProducts.at(index)).controls.lineNumber.value;
            this.searchProduct.emit({ uid: $event.uid, lineNumber });
        }
    }

    public onSearchOrganizations($event: string) {
        this.searchOrganizations.emit($event);
    }

    public characteristicDisplayValueFunction(item: any) {
        return item?.name;
    }

    public productDisplayValueFunction(item: any) {
        return item?.name;
    }

    public warehouseDisplayValueFunction(item: any) {
        return item.name;
    }

    public onSearchWarehouses(term?: string): void {
        this.searchWarehouse.emit({ term, organizationUid: this.organization.value?.uid, organizationPosUid: this.organizationPos.value?.uid });
    }

    public onOrganizationChanged(event?: OrganizationListItem): void {
        this.organizationPos.reset();
        this.warehouseFrom.reset();
        this.warehouseTo.reset();
        if(event?.uid) {
            this.organizationSearchPoses.emit({term: null, organizationUid: event.uid});
        }
    }

    public onPosChanged(event?: OrganizationPosListItem): void {
        this.warehouseFrom.reset();
        this.warehouseTo.reset();
        if (event?.uid) {
            this.onSearchWarehouses();
        }
    }

    public onWarehousesSelected(_: any) {
        this.searchWarehouse.emit({ term: null, organizationUid: this.organization.value?.uid, organizationPosUid: this.organizationPos.value?.uid });
    }

    public userSelectValueFunction(item: any) {
        return item.uid;
    }

    public userDisplayValueFunction(item: any) {
        return item.fullName;
    }

    public usersDisplayValueFunction(item: any) {
        return item?.fullName;
    }

    public selectorProductsFunc(item: any) {
        return item.sum;
    }

    public unitTypeDisplayValueFunction(item: UnitTypeListItem) {
        return item.shortName;
    }

    public onDialogClose(event: ExgDialogResultEvent) {
        this.showDialog = false;
        if (event.dialogResult === DialogResult.Ok) {
            this.notes.patchValue(event.dataFromComponent.notes);
        }
    }

    public onOpenNoteDialogClick() {
        this.componentData = { component: DcrNotesFormComponent, inputs: { notes: this.notes.value } };
        this.showDialog = true;
    }

    public onAddFromProduct() {
        if (!this.fromProducts.valid) {
            this.validateList$.next(true);
            return;
        }

        this.addFromProductInternal(null);

        this.selectedLine = this.fromProducts.length - 1;
    }

    public onDeleteFromProduct(index: number) {
        const deletedProduct = this.fromProducts.at(index).value;
        this.fromProducts.removeAt(index);

        if(this.selectedLine !== null && this.fromProducts.length) {
            this.selectedLine = this.fromProducts.length - 1;
        }
    
        for (let i = this.toProducts.length - 1; i >= 0; i--) {
            const toProductValue = this.toProducts.at(i).value;
            if (toProductValue.fromProductUid === deletedProduct.product?.uid && 
                +toProductValue.fromLineNumber === +deletedProduct.lineNumber) {
                    this.toProducts.removeAt(i);
            }
        }
    }
    

    protected processSubmit() {

        const fromProducts = this.fromProducts.value.map((fromProduct) => ({
            productUid: fromProduct.product.uid,
            lineNumber: fromProduct.lineNumber,
            amount: fromProduct.amount,
            price: fromProduct.price
        }));

        const toProducts = this.toProducts.value.map((toProduct) => ({
            productUid: toProduct.product.uid,
            lineNumber: toProduct.lineNumber,
            fromProductUid: toProduct.fromProductUid,
            fromLineNumber: toProduct.fromLineNumber,
            amount: toProduct.amount,
            percent: toProduct.percent,
            price: toProduct.price
        }));

        const hasMatchingProducts = fromProducts.every(fromProduct =>
            toProducts.some(toProduct => toProduct.productUid === fromProduct.productUid)
        );


        if (!hasMatchingProducts) {
            this.snackbar.showWarning('One or more products have no ingredients.');
            return;
        }

        const date = this.date.value + this.time.value;
        const request: any = {
            date: DateUtils.getEpocWithTimeZoneOffset(date, this.timeZone, true),
            number: this.number.value,
            organizationUid: this.organization.value?.uid,
            organizationPosUid: this.organizationPos.value?.uid,
            warehouseFromUid: this.warehouseFrom.value?.uid,
            warehouseToUid: this.warehouseTo.value?.uid,
            responsibleUserUid: this.responsibleUser.value?.uid,
            fromProducts,
            toProducts,
            status: this.status.value?.id,
            notes: this.notes.value
        };

        if (this.entity && this.entity.uid) {
            this.updateDCR.emit({ uid: this.entity.uid, request });
        } else {
            this.createDCR.emit(request);
        }
    }

    public posDisplayValueFunction(item): string {
        return item?.name;
    }

    public onSearchOrganizationPoses($event?: string) {
        this.organizationSearchPoses.emit({term: $event, organizationUid: this.organization.value?.uid});
    }

    private applyTimeZoneForForm(): void {
        const splitedDate = DateUtils.splitToDateTime(this.entity?.date || DateUtils.currentDateTime, this.timeZone);
        this.date.patchValue(splitedDate.date);
        this.time.patchValue(splitedDate.time);
    }

    private createForm() {
        const splitedDate = DateUtils.splitToDateTime(DateUtils.currentDateTime, this.timeZone, true);
        this.number = this.formBuilder.control(null);
        this.date = this.formBuilder.control(splitedDate.date, Validators.required);
        this.time = this.formBuilder.control(splitedDate.time);
        this.status = this.formBuilder.control({ id: 1 });
        this.warehouseFrom = this.formBuilder.control(null, Validators.required);
        this.warehouseTo = this.formBuilder.control(null, Validators.required);
        this.organization = this.formBuilder.control(null, Validators.required);
        this.organizationPos = this.formBuilder.control(null, Validators.required);
        this.responsibleUser = this.formBuilder.control(null, Validators.required);
        this.notes = this.formBuilder.control(null);
        this.fromProducts = this.formBuilder.array([]);
        this.toProducts = this.formBuilder.array([]);

        this.mainForm = this.formBuilder.group({
            number: this.number,
            date: this.date,
            time: this.time,
            status: this.status,
            warehouseFrom: this.warehouseFrom,
            warehouseTo: this.warehouseTo,
            organization: this.organization,
            responsibleUser: this.responsibleUser,
            notes: this.notes,
            fromProducts: this.fromProducts,
            toProducts: this.toProducts,
            organizationPos: this.organizationPos
        });

        this.addFromProductInternal(null);
        this.selectedLine = 0;
    }

    private setFormData() {
        this.status.patchValue({ id: this.entity.status });
        this.warehouseFrom.patchValue(this.entity.warehouseFrom);
        this.warehouseTo.patchValue(this.entity.warehouseTo);
        this.organization.patchValue(this.entity.organization);
        this.organizationPos.patchValue(this.entity.organizationPos);
        this.responsibleUser.patchValue(this.entity.responsibleUser);
        this.notes.patchValue(this.entity.notes);

        const splitedDate = DateUtils.splitToDateTime(this.entity.date, this.timeZone);
        this.date.patchValue(splitedDate.date);
        this.time.patchValue(splitedDate.time);

        this.setFormDataFromProducts(this.entity.fromProducts);
        this.setFormDataToProducts(this.entity.toProducts);

        this.previourseState$.next(this.mainForm.value);
    }

    private setFormDataFromProducts(fromProducts: DCRFromProductAggregation[]) {
        while (this.fromProducts.length !== 0) {
            this.fromProducts.removeAt(0);
        }
        this.selectedLine = null;

        for (const fromProduct of fromProducts) {
            this.addFromProductInternal(fromProduct);
        }
    }

    private addFromProductInternal(fromProduct?: DCRFromProductAggregation) {
        const group = this.formBuilder.group({
            code: new FormControl(fromProduct?.product.code),
            product: new FormControl(fromProduct?.product, [Validators.required]),
            unitType: new FormControl(fromProduct?.unitType),
            lineNumber: new FormControl(this.getLineNumber(this.fromProducts.value, fromProduct?.lineNumber), [Validators.required]),
            amount: new FormControl(fromProduct?.amount),
            price: new FormControl(fromProduct?.price),
            total: new FormControl(+(fromProduct?.amount * fromProduct?.price).toFixed(2))
        });

        combineLatest([
            group.controls.amount.valueChanges,
            group.controls.price.valueChanges,
            group.controls.unitType.valueChanges
        ]).pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            const amount: number = +value[0];
            const price: number = +value[1];
            const total = +(amount * price).toFixed(2);
            group.controls.total.patchValue(total);

            for (const toProduct of this.toProducts.controls) {
                this.calculateToProduct(amount, price, value[2], group, <FormGroup>toProduct);
            }
        });

        group.controls.product.valueChanges.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe((value: ProductListItemAggregation) => {
            group.controls.code.patchValue(value.code);
            group.controls.amount.patchValue(value.amount);
            group.controls.unitType.patchValue(value.unitType);
            group.controls.price.patchValue(value.price);
        });

        this.fromProducts.push(group);
    }

    private setFormDataToProducts(toProducts: DCRToProductAggregation[]) {
        while (this.toProducts.length !== 0) {
            this.toProducts.removeAt(0);
        }

        for (const toProduct of toProducts) {
            this.addToProductInternal(toProduct);
        }
    }

    private addToProductInternal(toProduct?: DCRToProductAggregation) {
        const group = this.formBuilder.group({
            code: new FormControl(toProduct?.product.code),
            product: new FormControl(toProduct?.product, [Validators.required]),
            fromProductUid: new FormControl(toProduct.fromProductUid),
            fromLineNumber: new FormControl(toProduct.fromLineNumber),
            unitType: new FormControl(toProduct?.unitType),
            lineNumber: new FormControl(this.getLineNumber(this.toProducts.value, toProduct?.lineNumber), [Validators.required]),
            amount: new FormControl(toProduct?.amount),
            percent: new FormControl(toProduct?.percent),
            price: new FormControl(toProduct?.price),
            total: new FormControl(+(toProduct?.amount * toProduct?.price).toFixed(2))
        });

        combineLatest([
            group.controls.amount.valueChanges
        ]).pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            const amount: number = +value[0];
            const price: number = +group.controls.price.value;
            const total = +(amount * price).toFixed(2);
            group.controls.total.patchValue(total);
        });

        this.toProducts.push(group);
    }

    private getLineNumber(array, initValue) {
        if (initValue > 0) {
            return initValue;
        }

        const lineNumbers = array && array.length > 0 ? array.map(x => x.lineNumber) : [0];
        return Math.max(...lineNumbers) + 1;
    }

    private calculateToProduct(amount, price, unitType: UnitTypeListItem, fromProduct: FormGroup, toProduct: FormGroup) {
        const toProductValue = toProduct.value;
        if (!(toProductValue.percent && !!unitType?.uid && !!toProductValue.unitType?.uid && unitType.uid === toProductValue.unitType.uid)) {
            return;
        }

        if (toProductValue.fromProductUid === fromProduct.controls.product.value.uid && +toProductValue.fromLineNumber === +fromProduct.controls.lineNumber.value) {
            const newAmount = toProductValue.percent * amount;
            (<FormGroup>toProduct).controls.price.patchValue(+price.toFixed(2));
            (<FormGroup>toProduct).controls.amount.patchValue(+newAmount.toFixed(2));
        }
    }
}
