import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { TechnologicalMapCreateSuccessAction } from './technological-map-create.actions';
import { TechnologicalMapDeleteSuccessAction } from './technological-map-delete.actions';
import { TechnologicalMapUpdateSuccessAction } from './technological-map-update.actions';
import { TechnologicalMapsAction, TechnologicalMapsFailAction, TechnologicalMapsResetAction, TechnologicalMapsSetFilterAction, TechnologicalMapsSuccessAction, TechnologicalMapsUpdateItemAction } from './technological-maps.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapListGetFilter, TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export interface TechnologicalMapsStateModel extends PaginationFilteredStateModel<TechnologicalMapListItemAggregation, TechnologicalMapListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: TechnologicalMapListItemAggregation };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TechnologicalMapListGetFilterData;
    total: number;
}

@State<TechnologicalMapsStateModel>({
    name: 'technologicalMaps',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class TechnologicalMapsState extends PaginationFilteredStateBase {
    constructor(private technologicalMapsService: TechnologicalMapsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([TechnologicalMapsAction]) productsGet(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'technological-map');
        ctx.setState({ ...state, filter: filterData });

        const filter = new TechnologicalMapListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.technologicalMapsService.getTechnologicalMapList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TechnologicalMapsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapsFailAction(err)), 0));
    }

    @Action(TechnologicalMapsUpdateItemAction) technologicalMapsUpdateListItemAction(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapsUpdateItemAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...action.payload };
        ctx.setState({ ...state, entities });
    }

    @Action(TechnologicalMapsSuccessAction) technologicalMapGetSuccess(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(TechnologicalMapsFailAction) technologicalMapGetFail(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TechnologicalMapsResetAction) technologicalMapGetReset(ctx: StateContext<TechnologicalMapsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(TechnologicalMapsSetFilterAction) usersSetFilter(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'technological-map');
            ctx.setState({ ...state, filter, index: 0 });
            setTimeout(() => ctx.dispatch(new TechnologicalMapsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(TechnologicalMapCreateSuccessAction) technologicalMapCreateSuccess(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(TechnologicalMapUpdateSuccessAction) technologicalMapUpdateSuccess(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        //@ts-ignore
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(TechnologicalMapDeleteSuccessAction) technologicalMapDeleteSuccess(ctx: StateContext<TechnologicalMapsStateModel>, action: TechnologicalMapDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
