import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PosListFilterData } from '../../../../models/filters/pos-list-filter.model';
import { PosSortingFieldsEnum } from '../../../../models/business/pos/pos-sorting-fields.enum';
import { SortOrder } from '../../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'pos-header',
    templateUrl: './pos-header.component.html',
    styleUrls: ['./pos-header.component.scss', '../pos-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PosHeaderComponent {

    @Input() filter: PosListFilterData;
    @Input() listConfig: any;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public posSortingFields = PosSortingFieldsEnum;

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.sortingChange.emit($event);
    }
}
