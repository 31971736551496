import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class IntervalGetAvailableListFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public dateTime: number;
    public organizationPosUid: string;
    public amount: number;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: IntervalGetAvailableListFilterData }) {
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.dateTime = (args && args.filterData && args.filterData.dateTime != null) ? args.filterData.dateTime : null;
        this.organizationPosUid = (args && args.filterData && args.filterData.organizationPosUid != null) ? args.filterData.organizationPosUid : null;
        this.amount = (args && args.filterData && args.filterData.amount != null) ? args.filterData.amount : null;
    }
}

export class IntervalGetAvailableListFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public dateTime?: number;
    public organizationPosUid?: string;
    public amount?: number;
}
