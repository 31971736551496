// tslint:disable:no-namespace
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ErrorUpdateRequest } from '../../models/business/orders-errors/error-update-request.model';

export namespace OrdersErrorsUpdateAction {
    export class FromHeaderComponent {
        static readonly type = '[Header Component] OrdersErrorsUpdate';

        constructor(public payload: ErrorUpdateRequest) { }
    }

    export class FromOrdersErrorsPage {
        static readonly type = '[OrdersErrors Page] OrdersErrorsUpdate';

        constructor(public payload: ErrorUpdateRequest) { }
    }

    export type OrdersErrorsUnreadCountActionsUnion = FromHeaderComponent | FromOrdersErrorsPage;
}

export class OrdersErrorsUpdateSuccessAction {
    static readonly type = '[OrdersErrors API] OrdersErrorsUpdate Success';

    constructor(public payload: ErrorUpdateRequest) { }
}

export class OrdersErrorsUpdateFailAction {
    static readonly type = '[OrdersErrors API] OrdersErrorsUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export namespace OrdersErrorsUpdateResetAction {

    export class FromHeaderComponent {
        static readonly type = '[Header Component] OrdersErrorsUpdate Reset';
    }

    export class FromOrdersErrorsPage {
        static readonly type = '[OrdersErrors Page] OrdersErrorsUpdate Reset';
    }

    export type OrdersErrorsUpdateResetActionsUnion = FromHeaderComponent | FromOrdersErrorsPage;
}
