import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContragentContactsAction {
    static readonly type = '[ContragentContacts Page] GetContragentContacts';

    constructor(public payload: PaginationRequest) { }
}

export class ContragentContactsSuccessAction {
    static readonly type = '[ContragentContacts API] GetContragentContacts Success';

    constructor(public payload: { list: PaginationResult<ContactListItem>, pagination: PaginationRequest }) { }
}

export class ContragentContactsFailAction {
    static readonly type = '[ContragentContacts API] GetContragentContacts Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentContactsResetAction {
    static readonly type = '[ContragentContacts Page] GetContragentContacts Reset';
}

export class ContragentContactsSetFilterAction {
    static readonly type = '[ContragentContacts API] SetFilter';

    constructor(public payload: ContactsListFilterData) { }
}
