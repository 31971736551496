import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DCRListItem } from '../../models/business/dcr/dcr-list-item.model';
import { SearchFilterData } from '../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { Profile } from '../../../burns-ui-framework/shared/models/business/user/profile.model';
import { RoutingConfig } from '../../routing.config';

@Component({
    selector: 'bvc-dcr-list',
    templateUrl: './dcr-list.component.html',
    styleUrls: ['./dcr-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrcListComponent {

    @Input() profile: Profile;
    @Input() entities: DCRListItem[];
    @Input() allItemsLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: SearchFilterData;
    @Input() enableAddLine = false;
    @Input() listConfig: {};

    @Output() readonly addClick = new EventEmitter<void>();
    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openEntity = new EventEmitter<string>();
    @Output() readonly filterChanged = new EventEmitter<SearchFilterData>();
    @Output() readonly listConfigChanged = new EventEmitter<{ }>();

    public selected: { [uid: string]: boolean } = {};

    public routes = RoutingConfig.routes;

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onFilterChanged($event: any) {
        this.filterChanged.emit($event);
    }

    public trackByEntity(_, item: any) {
        return item.uid;
    }

    public onOpenEntity($event: string) {
        this.openEntity.emit($event);
    }

    public onCheckAll($event: { checked: boolean }) {
        this.entities?.forEach(x => this.selected[x.uid] = $event.checked);
    }

    public onCheckItem($event: { uid: string, checked: boolean }) {
        this.selected[$event.uid] = $event.checked;
    }

    public onListConfigChanged($event: any) {
        this.listConfigChanged.emit($event);
    }

    public onAddClick() {
        this.addClick.emit();
    }
}
