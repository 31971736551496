import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrdersListFilterData } from '../../models/filters/orders-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class PosOrdersAction {
    static readonly type = '[PosOrders Page] GetOrders';

    constructor(public payload: PaginationRequest) { }
}

export class PosOrdersSuccessAction {
    static readonly type = '[PosOrders API] GetOrders Success';

    constructor(public payload: { list: PaginationResult<OrderListItem>, pagination: PaginationRequest }) { }
}

export class PosOrdersFailAction {
    static readonly type = '[PosOrders API] GetOrders Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosOrdersResetAction {
    static readonly type = '[PosOrders Page] GetOrders Reset';
}

export class PosOrdersSetFilterAction {
    static readonly type = '[PosOrders API] SetFilter';

    constructor(public payload: OrdersListFilterData) { }
}
