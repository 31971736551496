import { BillingTransactionSortingFieldsEnum } from './models/business/billing-transactions/billing-transaction-sorting-fields.enum';
import { ContactSortingFieldsEnum } from './models/business/contacts/contact-sorting-fields.enum';
import { ContragentSortingFieldsEnum } from './models/business/contragents/contragent-sorting-fields.enum';
import { CorporationRequisiteSortingFieldsEnum } from './models/business/corporation-requisites/corporation-requisite-sorting-fields.model';
import { CustomerSortingFieldsEnum } from './models/business/customers/customer-sorting-fields.enum';
import { OrderProductSortingFieldsEnum } from './models/business/catalog/order-product-sorting-fields.enum';
import { OrderSortingFieldsEnum } from './models/business/orders/order-sorting-fields.enum';
import { OrganizationSortingFieldsEnum } from './models/business/organizations/organization-sorting-fields.enum';
import { PersonnelSortingFieldsEnum } from './models/business/personnels/personnel-sorting-fields.model';
import { PosSortingFieldsEnum } from './models/business/pos/pos-sorting-fields.enum';
import { OrganizationPosSortingFieldsEnum } from './models/business/organization-pos/pose-sorting-fields.enum';
import { ProductsSortingFieldsEnum } from './models/business/products/products-sorting-fields.enum';
import { TaskSortingFieldsEnum } from './models/business/tasks/task-sorting-fields.enum';

import { Utils } from '../../burns-ui-framework/shared/utils/utils';

export class ExgListParams {
    public static contactList(ignore: ContactSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(ContactSortingFieldsEnum, ignore);
    }

    public static customerList(ignore: CustomerSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(CustomerSortingFieldsEnum, ignore);
    }

    public static contragentList(ignore: ContragentSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(ContragentSortingFieldsEnum, ignore);
    }

    public static posList(ignore: PosSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(PosSortingFieldsEnum, ignore);
    }

    public static orderList(ignore: OrderSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(OrderSortingFieldsEnum, ignore);
    }

    public static orderProductsList(ignore: OrderProductSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(OrderProductSortingFieldsEnum, ignore);
    }

    public static productList(ignore: ProductsSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(ProductsSortingFieldsEnum, ignore);
    }

    public static billingTransactionsList(ignore: BillingTransactionSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(BillingTransactionSortingFieldsEnum, ignore);
    }

    public static taskList(ignore: TaskSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(TaskSortingFieldsEnum, ignore);
    }

    public static organizationList(ignore: OrganizationSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(OrganizationSortingFieldsEnum, ignore);
    }

    public static requisiteList(ignore: CorporationRequisiteSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(CorporationRequisiteSortingFieldsEnum, ignore);
    }

    public static getList(type: any) {
        return Utils.enumToArray(type).reduce((acc, curr) => (acc[curr] = true, acc), {});
    }

    public static getListWithoutIgnore(type: any, ignore) {
        return Utils.enumToArray(type).reduce((acc, curr) => (acc[curr] = !ignore.some(ign => ign === curr), acc), {});
    }

    public static organizationPosList(ignore: OrganizationPosSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(OrganizationPosSortingFieldsEnum, ignore);
    }

    public static restaurantPersonnelList(ignore: PersonnelSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(PersonnelSortingFieldsEnum, ignore);
    }

    public static restaurantWarehouseList(ignore: PersonnelSortingFieldsEnum[] = []) {
        return ExgListParams.getListWithoutIgnore(PersonnelSortingFieldsEnum, ignore);
    }
}
