import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContractsService } from '../../services/business/contracts.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { ContractCreateSuccessAction } from './contract-create.actions';
import { ContractDeleteSuccessAction } from './contract-delete.actions';
import { ContractUpdateSuccessAction } from './contract-update.actions';
import { ContractsAction, ContractsFailAction, ContractsResetAction, ContractsSetFilterAction, ContractsSuccessAction } from './contracts.actions';

import { Contract } from '../../models/business/contract/contract.model';
import { ContractsListFilter, ContractsListFilterData } from '../../models/filters/contracts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface ContractsStateModel extends PaginationStateModel<Contract> {
    uids: string[];
    entities: { [uid: string]: Contract };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ContractsListFilterData;
    total: number;
}

@State<ContractsStateModel>({
    name: 'contracts',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class ContractsState extends PaginationStateBase {
    constructor(private contractsService: ContractsService) {
        super();
    }

    @Action([ContractsAction]) contractsGet(ctx: StateContext<ContractsStateModel>, action: ContractsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ContractsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contractsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contractsService.getContractList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ContractsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContractsFailAction(err)), 0));
    }

    @Action(ContractsSuccessAction) contractsGetSuccess(ctx: StateContext<ContractsStateModel>, action: ContractsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contractsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ContractsFailAction) contractsGetFail(ctx: StateContext<ContractsStateModel>, action: ContractsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContractsResetAction) contractsGetReset(ctx: StateContext<ContractsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ContractsSetFilterAction) usersSetFilter(ctx: StateContext<ContractsStateModel>, action: ContractsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ContractsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(ContractCreateSuccessAction) contractCreateSuccess(ctx: StateContext<ContractsStateModel>, _: ContractCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new ContractsAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(ContractUpdateSuccessAction) contractUpdateSuccess(ctx: StateContext<ContractsStateModel>, action: ContractUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(ContractDeleteSuccessAction) contractDeleteSuccess(ctx: StateContext<ContractsStateModel>, action: ContractDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
