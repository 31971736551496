import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class RecyclingDeleteAction {
    static readonly type = '[My Recycling Page] DeleteRecycling';

    constructor(public payload: { uid: string }) { }
}

export class RecyclingDeleteSuccessAction {
    static readonly type = '[My Recyclings API] DeleteRecycling Success';

    constructor(public payload: { uid: string }) { }
}

export class RecyclingDeleteFailAction {
    static readonly type = '[My Recyclings API] DeleteRecycling Fail';

    constructor(public payload: ErrorObject) { }
}

export class RecyclingDeleteResetAction {
    static readonly type = '[My Recycling Page] DeleteRecycling Reset';
}
