import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { MotivationBonusFailAction, MotivationBonusGetAction, MotivationBonusGetSuccessAction, MotivationBonusResetAction } from './motivation-bonus.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonus } from '../../models/business/contacts/motivation-bonus.model';

export interface MotivationBonusStateModel {
    entity: MotivationBonus;
    pending: boolean;
    error: ErrorObject;
}

@State<MotivationBonusStateModel>({
    name: 'motivationBonus',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class MotivationBonusState {
    constructor(private contactsService: ContactsService) { }

    @Action(MotivationBonusGetAction) motivationBonusGet(ctx: StateContext<MotivationBonusStateModel>, action: MotivationBonusGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contactsService.getMotivation(action.payload.contactUid, action.payload.motivationUid)
            .then(resp => setTimeout(() => ctx.dispatch(new MotivationBonusGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusFailAction(err)), 0));
    }

    @Action(MotivationBonusGetSuccessAction) motivationBonusGetSuccess(ctx: StateContext<MotivationBonusStateModel>, action: MotivationBonusGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(MotivationBonusFailAction) motivationBonusGetFail(ctx: StateContext<MotivationBonusStateModel>, action: MotivationBonusFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(MotivationBonusResetAction) motivationBonusGetReset(ctx: StateContext<MotivationBonusStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
