export class Roles {
    public static usersViewAny = 'users.view';
    public static usersViewOwn = 'users.view.own';
    public static usersView = 'users.view,users.view.own';
    public static usersCreate = 'users.create';
    public static usersUpdate = 'users.update';
    public static usersDelete = 'users.delete';
    public static companiesViewAny = 'companies.view';
    public static companiesViewOwn = 'companies.view.own';
    public static companiesView = 'companies.view,companies.view.own';
    public static companiesCreate = 'companies.create';
    public static companiesUpdateAny = 'companies.update';
    public static companiesUpdateOwn = 'companies.update.own';
    public static companiesUpdate = 'companies.update,companies.update.own';
    public static companiesDeleteAny = 'companies.delete';
    public static companiesDeleteOwn = 'companies.delete.own';
    public static companiesDelete = 'companies.delete,companies.delete.own';
    public static posViewAny = 'pos.view';
    public static posViewOwn = 'pos.view.own';
    public static posView = 'pos.view,pos.view.own';
    public static posCreate = 'pos.create';
    public static posUpdateAny = 'pos.update';
    public static posUpdateOwn = 'pos.update.own';
    public static posUpdate = 'pos.update,pos.update.own';
    public static posDeleteAny = 'pos.delete';
    public static posDeleteOwn = 'pos.delete.own';
    public static posDelete = 'pos.delete,pos.delete.own';
    public static contactsViewAny = 'contacts.view';
    public static contactsViewOwn = 'contacts.view.own';
    public static contactsView = 'contacts.view,contacts.view.own';
    public static contactsCreate = 'contacts.create';
    public static contactsUpdateAny = 'contacts.update';
    public static contactsUpdateOwn = 'contacts.update.own';
    public static contactsUpdate = 'contacts.update,contacts.update.own';
    public static contactsDeleteAny = 'contacts.delete';
    public static contactsDeleteOwn = 'contacts.delete.own';
    public static contactsDelete = 'contacts.delete,contacts.delete.own';
    public static contragentsViewAny = 'contragents.view';
    public static contragentsViewOwn = 'contragents.view.own';
    public static contragentsView = 'contragents.view,contragents.view.own';
    public static contragentsCreate = 'contragents.create';
    public static contragentsUpdateAny = 'contragents.update';
    public static contragentsUpdateOwn = 'contragents.update.own';
    public static contragentsUpdate = 'contragents.update,contragents.update.own';
    public static contragentsDeleteAny = 'contragents.delete';
    public static contragentsDeleteOwn = 'contragents.delete.own';
    public static contragentsDelete = 'contragents.delete,contragents.delete.own';
    public static brandsView = 'brands.view';
    public static brandsCreate = 'brands.create';
    public static brandsUpdate = 'brands.update';
    public static brandsDelete = 'brands.delete';
    public static catalogView = 'catalog.view';
    public static catalogCreate = 'catalog.create';
    public static catalogUpdate = 'catalog.update';
    public static catalogDelete = 'catalog.delete';
    public static ordersViewAny = 'orders.view';
    public static ordersViewOwn = 'orders.view.own';
    public static ordersView = 'orders.view,orders.view.own';
    public static ordersCreate = 'orders.create';
    public static ordersUpdateAny = 'orders.update';
    public static ordersUpdateOwn = 'orders.update.own';
    public static ordersUpdate = 'orders.update,orders.update.own';
    public static ordersDeleteAny = 'orders.delete';
    public static ordersDeleteOwn = 'orders.delete.own';
    public static ordersDelete = 'orders.delete,orders.delete.own';
    public static plansView = 'plans.view';
    public static plansUpdate = 'plans.update';
}
