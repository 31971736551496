<form [formGroup]="mainForm" class="form" [autocomplete]="true" (submit)="$event.preventDefault(); onSubmit()">

    <div class="box">
        <exg-select class="select"
                    [data]="users"
                    [required]="true"
                    formControlName="responsibleUser"
                    placeholder="{{'Responsible user' | translate}}"
                    [controlsToValidate]="[responsibleUser]"
                    [displayValueFunction]="userDisplayValueFunction"
                    [selectValueFunction]="userSelectValueFunction"
                    [displayValueFunction]="usersDisplayValueFunction"
                    [validate]="validate$ | async"></exg-select>
                    
        <exg-datepicker class="datepicker"
                        formControlName="dueDate"
                        placeholder="{{'Due date' | translate}}"
                        [controlsToValidate]="[dueDate]"
                        [validate]="validate$ | async"></exg-datepicker>

        <exg-timepicker class="timepicker"
                        [required]="true"
                        formControlName="dueTime"
                        placeholder="{{'Time' | translate}}"
                        [controlsToValidate]="[dueTime]"
                        [validate]="validate$ | async"></exg-timepicker>
    </div>

    <exg-textbox class="text"
                 formControlName="description"
                 [maxlength]="3000"
                 [isMultiLine]="true"
                 [multilineMinRows]="4"
                 [multilineMaxRows]="4"
                 placeholder="{{'Text' | translate}}"
                 [controlsToValidate]="[description]"
                 [validate]="validate$ | async"></exg-textbox>

    <div class="files">
        <bvc-clients-activity-document class="document"
                                       *ngFor="let document of noteFiles$ | async; trackBy: trackByDocuments"
                                       [document]="document"
                                       (deleteFile)="onDeleteDocument(document.uid)"></bvc-clients-activity-document>
 
        <bvc-clients-activity-document class="document"
                                       *ngFor="let documentDownloadable of filesToAdd; let i = index;"
                                       [documentDownloadable]="documentDownloadable"
                                       (deleteFile)="onDeleteDocumentDownloadable(i)"></bvc-clients-activity-document>
    </div>

    <exg-form-error [error]="error"></exg-form-error>

    <exg-files-drop class="files-drop" (uploadedFiles)="onUploadedFiles($event)"></exg-files-drop>

    <div class="btn-box">
        <exg-button class="cancel" styleType="outlined" (btnClick)="onCancelClick()">{{ 'Cancel' | translate }}</exg-button>
        <exg-button class="submit" type="submit">{{ (task?.uid ? 'Save' : 'Add') | translate }}</exg-button>
    </div>
</form>