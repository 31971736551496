import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PricelistDeleteAction, PricelistDeleteFailAction, PricelistDeleteResetAction, PricelistDeleteSuccessAction } from './pricelist-delete.actions';

export interface PricelistDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PricelistDeleteStateModel>({
    name: 'pricelistDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PricelistDeleteState {
    constructor(private pricelistService: PricelistService) { }

    @Action(PricelistDeleteAction) async pricelistDelete(ctx: StateContext<PricelistDeleteStateModel>, action: PricelistDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.pricelistService.deletePricelist(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PricelistDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PricelistDeleteFailAction(error)), 0));
    }

    @Action(PricelistDeleteSuccessAction) pricelistDeleteSuccess(ctx: StateContext<PricelistDeleteStateModel>, _: PricelistDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PricelistDeleteFailAction) pricelistDeleteFail(ctx: StateContext<PricelistDeleteStateModel>, action: PricelistDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PricelistDeleteResetAction) pricelistDeleteReset(ctx: StateContext<PricelistDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
