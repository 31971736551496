import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ContractSortingFieldsEnum } from '../../../../models/business/contract/contract-sorting-fields.model';
import { ContractsListFilterData } from '../../../../models/filters/contracts-list-filter.model';
import { SortOrder } from '../../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'contracts-header',
    templateUrl: './contracts-header.component.html',
    styleUrls: ['./contracts-header.component.scss', '../contracts-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractHeaderComponent {

    @Input() filter: ContractsListFilterData;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public contractSortingFields = ContractSortingFieldsEnum;

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.sortingChange.emit($event);
    }
}
