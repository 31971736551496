
import { CompaniesMotivationBonus } from '../../models/business/contacts/companies-motivation-bonus.model';
import { CompaniesMotivationBonusesListGetFilterData } from '../../models/filters/companies-motivation-bonuses-list-get-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class MotivationBonusesCompaniesAction {
    static readonly type = '[MotivationBonusesCompanies Page] GetMotivationBonusesCompanies';

    constructor(public payload: PaginationRequest) { }
}

export class MotivationBonusesCompaniesSuccessAction {
    static readonly type = '[MotivationBonusesCompanies API] GetMotivationBonusesCompanies Success';

    constructor(public payload: { list: PaginationResult<CompaniesMotivationBonus>, pagination: PaginationRequest }) { }
}

export class MotivationBonusesCompaniesFailAction {
    static readonly type = '[MotivationBonusesCompanies API] GetMotivationBonusesCompanies Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusesCompaniesResetAction {
    static readonly type = '[MotivationBonusesCompanies Page] GetMotivationBonusesCompanies Reset';
}

export class MotivationBonusesCompaniesSetFilterAction {
    static readonly type = '[MotivationBonusesCompanies API] SetFilter';

    constructor(public payload: CompaniesMotivationBonusesListGetFilterData) { }
}
