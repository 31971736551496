import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosListFilterData } from '../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../models/business/pos/pos-list-item.model';

export class CompanyPosAction {
    static readonly type = '[CompanyPos Page] GetCompanyPos';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyPosSuccessAction {
    static readonly type = '[CompanyPos API] GetCompanyPos Success';

    constructor(public payload: { list: PaginationResult<PosListItem>, pagination: PaginationRequest }) { }
}

export class CompanyPosFailAction {
    static readonly type = '[CompanyPos API] GetCompanyPos Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyPosResetAction {
    static readonly type = '[CompanyPos Page] GetCompanyPos Reset';
}

export class CompanyPosSetFilterAction {
    static readonly type = '[CompanyPos API] SetFilter';

    constructor(public payload: PosListFilterData) { }
}
