import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { SortOrder } from '../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'bvc-filter-field',
    templateUrl: './bvc-filter-field.component.html',
    styleUrls: ['./bvc-filter-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BvcFilterFieldComponent implements OnChanges {

    @Input() name: string;
    @Input() fieldName: string;
    @Input() sortField: string;
    @Input() sortOrder: SortOrder;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder; sortField: string }>();

    public sortOrders = SortOrder;
    public fieldHistory = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sortOrder && this.sortOrder) {
            if (!this.fieldHistory.includes(this.sortField)) {
                this.fieldHistory = '';
            }
        }
    }

    public onSortingChange() : void {
        const sortOrder = this.fieldName !== this.sortField || this.sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
        this.sortingChange.emit({
            sortOrder,
            sortField: this.fieldHistory.includes('asc') ? null : this.fieldName
        });
        this.fieldHistory = this.fieldHistory.includes('asc') ? '' : this.fieldName + sortOrder;
    }
    
}
