import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PricelistUpdateRequest } from '../../models/business/pricelist/pricelist-update-request.model';

import { PricelistUpdateAction, PricelistUpdateFailAction, PricelistUpdateResetAction, PricelistUpdateSuccessAction } from './pricelist-update.actions';

export interface PricelistUpdateStateModel {
    pending: boolean;
    updated: PricelistUpdateRequest;
    error: ErrorObject;
}

@State<PricelistUpdateStateModel>({
    name: 'pricelistUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class PricelistUpdateState {
    constructor(private pricelistService: PricelistService) { }

    @Action(PricelistUpdateAction) async pricelistUpdate(ctx: StateContext<PricelistUpdateStateModel>, action: PricelistUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.pricelistService.updatePricelist(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PricelistUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistUpdateFailAction(err)), 0));
    }

    @Action(PricelistUpdateSuccessAction) pricelistUpdateSuccess(ctx: StateContext<PricelistUpdateStateModel>, action: PricelistUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(PricelistUpdateFailAction) pricelistUpdateFail(ctx: StateContext<PricelistUpdateStateModel>, action: PricelistUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(PricelistUpdateResetAction) pricelistUpdateReset(ctx: StateContext<PricelistUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
