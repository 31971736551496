import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { OrdersService } from '../../services/business/orders.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { OrderHistoryAction, OrderHistoryFailAction, OrderHistoryResetAction, OrderHistorySetFilterAction, OrderHistorySuccessAction } from './order-history.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderHistoryListGetFilter, OrderHistoryListGetFilterData } from '../../models/filters/order-history-list-get-filter.model';
import { OrderHistoryListItem } from '../../models/business/orders/order-history-list-item.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface OrderHistoryStateModel extends PaginationFilteredStateModel<OrderHistoryListItem, OrderHistoryListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: OrderHistoryListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: OrderHistoryListGetFilterData;
    total: number;
}

@State<OrderHistoryStateModel>({
    name: 'orderHistory',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class OrderHistoryState extends PaginationFilteredStateBase {
    constructor(private ordersService: OrdersService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([OrderHistoryAction]) productsGet(ctx: StateContext<OrderHistoryStateModel>, action: OrderHistoryAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrderHistoryListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.orderHistoryListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.ordersService.getOrderHistory(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrderHistorySuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrderHistoryFailAction(err)), 0));
    }

    @Action(OrderHistorySuccessAction) orderGetSuccess(ctx: StateContext<OrderHistoryStateModel>, action: OrderHistorySuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.orderHistoryListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(OrderHistoryFailAction) orderGetFail(ctx: StateContext<OrderHistoryStateModel>, action: OrderHistoryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrderHistoryResetAction) orderGetReset(ctx: StateContext<OrderHistoryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(OrderHistorySetFilterAction) usersSetFilter(ctx: StateContext<OrderHistoryStateModel>, action: OrderHistorySetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new OrderHistoryAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
