import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ContactListItem } from '../../../models/business/contacts/contact-list-item.model';
import { ContactSortingFieldsEnum } from '../../../models/business/contacts/contact-sorting-fields.enum';
import { ContactsListFilterData } from '../../../models/filters/contacts-list-filter.model';

import { ExgListParams } from '../../../exg-list-params.config';

@Component({
    selector: 'contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListComponent implements OnChanges {

    @Input() contacts: ContactListItem[];
    @Input() allContactsLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: ContactsListFilterData;
    @Input() enableAddLine = false;
    @Input() ignoreRows: ContactSortingFieldsEnum[] = [];
    @Input() shortList = false;

    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openContact = new EventEmitter<string>();
    @Output() readonly sortingChange = new EventEmitter<ContactsListFilterData>();
    @Output() readonly addBtnClick = new EventEmitter<void>();

    public listConfig = ExgListParams.contactList();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.ignoreRows && this.ignoreRows && this.ignoreRows.length > 0) {
            this.listConfig = ExgListParams.contactList(this.ignoreRows);
        }
    }

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onSortingChange($event: ContactsListFilterData) {
        this.sortingChange.emit($event);
    }

    public trackByContacts(_, item: ContactListItem) {
        return item.uid;
    }

    public onOpenContact(uid: string) {
        this.openContact.emit(uid);
    }
}
