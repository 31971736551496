import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DataMapper } from './data.mapper';

import { OrganizationTreeFilterData } from './organization-tree-filter-data.model';
import { OrganizationTreeTypeItemEnum } from './organization-tree-type-item.enum';
import { OrganizationTree } from '../../../models/business/organization-tree/organization-tree.model';
import { TreeNode } from '../../../../../burns-ui-framework/shared/components/common/exg-tree/tree-node.model';

@Component({
    selector: 'organizations-tree',
    templateUrl: './organizations-tree.component.html',
    styleUrls: ['./organizations-tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsTreeComponent implements OnChanges {
    @Input() selectedUids: string[] = [];
    @Input() data: OrganizationTree;
    @Output() readonly valueChange = new EventEmitter<OrganizationTreeFilterData>();
    @Output() readonly searchChanged = new EventEmitter<string>();

    public nodes: TreeNode[] = [];
    public term: string = '';

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this.nodes = DataMapper.Map(this.data);
        }
        if(changes.selectedUids && this.selectedUids && this.selectedUids?.length) {
            this.onValueChange(this.selectedUids);
        }
    }

    public onValueChange(uids: string[]): void {
        const { organizationUids, organizationPosUids, franchiseeUids } = this.findNodesByUid(uids).reduce(
            (acc, node) => {
                switch (node.data) {
                    case OrganizationTreeTypeItemEnum.Organization:
                        acc.organizationUids.push({
                            uid: node.uid,
                            name: node.name
                        });
                        break;
                    case OrganizationTreeTypeItemEnum.Pos:
                        acc.organizationPosUids.push({
                            uid: node.uid,
                            name: node.name
                        });
                        break;
                    case OrganizationTreeTypeItemEnum.Franchisees:
                        acc.franchiseeUids.push({
                            uid: node.uid,
                            name: node.name
                        });
                        break;
                }
                return acc;
            },
            { organizationUids: [], organizationPosUids: [], franchiseeUids: [] }
        );

        this.valueChange.emit({ organizationUids, organizationPosUids, franchiseeUids });
    }

    public onSearchChanged(value: string): void {
        this.searchChanged.emit(value);
    }

    private findNodesByUid(uidsToFind: any[]): TreeNode[] {
        const foundNodes: TreeNode[] = [];

        function search(node: TreeNode) {
            if (uidsToFind.includes(node.uid)) {
                foundNodes.push(node);
            }

            if (node.childrens) {
                for (const child of node.childrens) {
                    search(child);
                }
            }
        }

        for (const rootNode of this.nodes) {
            search(rootNode);
        }

        return foundNodes;
    }
}
