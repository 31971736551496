import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosCitiesService } from '../../services/business/pos-cities.service';

import { PosCitiesAction, PosCitiesFailAction, PosCitiesResetAction, PosCitiesSuccessAction } from './pos-cities.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosCity } from '../../models/business/organization-pos/pos-city.model';

export interface PosCitiesStateModel {
    entities: PosCity[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<PosCitiesStateModel>({
    name: 'PosCities',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class PosCitiesState {
    constructor(private posCitiesService: PosCitiesService) {
    }

    @Action([PosCitiesAction]) posGet(ctx: StateContext<PosCitiesStateModel>, action: PosCitiesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.posCitiesService.getPosCities()
            .then(resp => setTimeout(() => ctx.dispatch(new PosCitiesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosCitiesFailAction(err)), 0));
    }

    @Action(PosCitiesSuccessAction) posGetSuccess(ctx: StateContext<PosCitiesStateModel>, action: PosCitiesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(PosCitiesFailAction) posGetFail(ctx: StateContext<PosCitiesStateModel>, action: PosCitiesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosCitiesResetAction) posGetReset(ctx: StateContext<PosCitiesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
