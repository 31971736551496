import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ProfileSelectors } from '../../../burns-ui-framework/shared/store/profile/profile.selectors';

import { IntervalUpdateDispatchers } from '../../../evasys/shared/store/intervals/interval-update.dispatchers';
import { IntervalUpdateSelectors } from '../../../evasys/shared/store/intervals/interval-update.selectors';
import { IntervalsDispatchers } from '../../../evasys/shared/store/intervals/intervals.dispatchers';
import { IntervalsSelectors } from '../../../evasys/shared/store/intervals/intervals.selectors';

import { IntervalCreateOrUpdateRequest } from '../../../evasys/shared/models/business/intervals/interval-create-or-update-request.model';
import { IntervalGetFilterData } from '../../../evasys/shared/models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../../evasys/shared/models/business/intervals/interval-list-item.model';

@Injectable({
    providedIn: 'root',
})
export class IntervalSandbox extends BaseSingletonService {

    public profile$ = this.profileSelectors.profile$;

    public filter$ = this.intervalsSelectors.filter$;
    public pending$ = this.intervalsSelectors.pending$;
    public intervals$ = this.intervalsSelectors.intervals$;
    public error$ = this.intervalsSelectors.error$;

    public updated$ = this.intervalUpdateSelectors.updated$;
    public updatePending$ = this.intervalUpdateSelectors.pending$;
    public updateError$ = this.intervalUpdateSelectors.error$;

    constructor(
        private profileSelectors: ProfileSelectors,
        private intervalsDispatchers: IntervalsDispatchers,
        private intervalsSelectors: IntervalsSelectors,
        private intervalUpdateDispatchers: IntervalUpdateDispatchers,
        private intervalUpdateSelectors: IntervalUpdateSelectors
    ) {
        super('IntervalSandbox');
    }

    public dispatchIntervals() {
        this.intervalsDispatchers.dispatchIntervalsAction();
    }

    public dispatchIntervalsUpdateFromSocket(list: IntervalListItem[]) {
        this.intervalsDispatchers.dispatchIntervalsUpdateFromSocketAction(list);
    }

    public dispatchIntervalsReset() {
        this.intervalsDispatchers.dispatchIntervalsResetAction();
    }

    public dispatchIntervalsSetFilter(filterData: IntervalGetFilterData) {
        this.intervalsDispatchers.dispatchIntervalsAction(filterData);
    }

    public dispatchIntervalUpdate(request: IntervalCreateOrUpdateRequest) {
        this.intervalUpdateDispatchers.dispatchIntervalUpdateAction(request);
    }

    public dispatchIntervalUpdateReset() {
        this.intervalUpdateDispatchers.dispatchIntervalUpdateResetAction();
    }
}
