import { AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export interface ITab {
    route: string;
    name: string;
    icon?: string;
    disableByPermission?: boolean;
}

@Component({
    selector: 'bvc-header',
    templateUrl: './bvc-header.component.html',
    styleUrls: ['./bvc-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcHeaderComponent implements AfterViewChecked {
    @Input() headerTitle: string;
    @Input() searchTitle: string;
    @Input() filter: { term?: string };
    @Input() tabs: ITab[];

    @Output() readonly searchTermChanged = new EventEmitter<{ term: string }>();

    public ngAfterViewChecked() {
        this.scrollTo();
    }

    public trackByTabs(_, item: ITab) {
        return item.route;
    }

    public scrollTo() {
        if (document) {
            const cardElement = document.querySelector('.card.active');
            if (cardElement) {
                cardElement.scrollIntoView({ behavior: 'auto' });
            }
        }
    }

    public onValueChange($event: string) {
        const term = $event.trim();
        if (term.length > 2 || this.filter.term) {
            this.searchTermChanged.emit({ term });
        }
    }
}
