import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosAreasService } from '../../services/business/organization-pos-areas.service';

import { OrganizationPosAreaRestrictionsAction, OrganizationPosAreaRestrictionsFailAction, OrganizationPosAreaRestrictionsResetAction, OrganizationPosAreaRestrictionsSuccessAction } from './organization-pos-area-restrictions.actions';

import { AreaRestrictionListItem } from '../../models/business/organization-pos/area-restriction-list-item.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface OrganizationPosAreaRestrictionsStateModel {
    entities: AreaRestrictionListItem[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosAreaRestrictionsStateModel>({
    name: 'posAreaRestrictions',
    defaults: {
        pending: false, entities: [], retrieved: false, error: null
    }
})
@Injectable()
export class OrganizationPosAreaRestrictionsState {
    constructor(private orgPosAreasService: OrganizationPosAreasService) { }

    @Action([OrganizationPosAreaRestrictionsAction]) organizationPosesGet(ctx: StateContext<OrganizationPosAreaRestrictionsStateModel>, action: OrganizationPosAreaRestrictionsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosAreasService.getPosAreaRestrictions(action.payload.organizationPosUid)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosAreaRestrictionsSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaRestrictionsFailAction(err)), 0));
    }

    @Action(OrganizationPosAreaRestrictionsSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosAreaRestrictionsStateModel>, action: OrganizationPosAreaRestrictionsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(OrganizationPosAreaRestrictionsFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosAreaRestrictionsStateModel>, action: OrganizationPosAreaRestrictionsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosAreaRestrictionsResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosAreaRestrictionsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
