import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CompaniesListFilterData } from '../../../../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../../../../models/business/companies/company-list-item.model';
import { CompanySortingFieldsEnum } from '../../../../models/business/companies/company-sorting-fields.enum';
import { CompanyStatus } from '../../../../models/business/companies/company-status.enum';
import { CompanyType } from '../../../../models/business/companies/company-type.enum';

@Component({
    selector: 'company-list-item',
    templateUrl: './company-list-item.component.html',
    styleUrls: ['./company-list-item.component.scss', '../company-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyListItemComponent {

    @Input() company: CompanyListItem;
    @Input() filter: CompaniesListFilterData;

    @Output() readonly openCompany = new EventEmitter<string>();

    public companyStatusEnum = CompanyStatus;
    public companyTypeEnum = CompanyType;
    public companySortingFields = CompanySortingFieldsEnum;

    public onOpenCompanyClick() {
        this.openCompany.emit(this.company.uid);
    }
}
