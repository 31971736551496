import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TaskCreateOrUpdateRequest } from '../../models/business/tasks/task-create-or-update.model';
import { Task } from '../../models/business/tasks/task.model';
import { TasksListFilter } from '../../models/filters/tasks-list-filter.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class TasksService extends BaseSingletonService {

    private settings: {
        service: {
            getTask: string;
            getTaskList: string;
            createTask: string;
            updateTask: string;
            deleteTask: string;

            addTaskDocuments: string;
            deleteTaskDocuments: string;
        }
    };

    constructor(private http: HttpService) {
        super('TasksService');
        this.settings = {
            service: {
                getTask: '/crm/v1/tasks/{0}',
                getTaskList: '/crm/v1/tasks?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                createTask: '/crm/v1/tasks',
                updateTask: '/crm/v1/tasks/{0}',
                deleteTask: '/crm/v1/tasks/{0}',

                addTaskDocuments: '/crm/v1/tasks/{0}/documents',
                deleteTaskDocuments: '/crm/v1/tasks/{0}/documents?documentUids={1}'
            }
        };
    }

    public async getTask(uid: string): Promise<Task> {
        return this.http.get<{ data: Task }>(String.format(this.settings.service.getTask, uid))
            .then(res => res.data);
    }

    public async getTaskList(filter: TasksListFilter): Promise<PaginationResult<Task>> {
        return this.http.get<PaginationResult<Task>>(String.format(this.settings.service.getTaskList, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createTask(request: TaskCreateOrUpdateRequest): Promise<Task> {
        return this.http.post<{ data: Task }>(String.format(this.settings.service.createTask), request)
            .then(res => res.data);
    }

    public async updateTask(uid: string, request: TaskCreateOrUpdateRequest): Promise<Task> {
        return this.http.put(String.format(this.settings.service.updateTask, uid), request);
    }

    public async deleteTask(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteTask, uid));
    }

    public async addTaskDocuments(uid: string, request: File[]): Promise<Task> {
        const formData = new FormData();

        for (const file of request) {
            formData.append(file.name, file);
        }

        return this.http.upload<{ data: Task }>(String.format(this.settings.service.addTaskDocuments, uid), formData)
            .then(x => x.data);
    }

    public async deleteTaskDocuments(uid: string, documentUids: string[]): Promise<boolean> {
        const uids = (documentUids || []).join(',');
        return this.http.delete(String.format(this.settings.service.deleteTaskDocuments, uid, uids));
    }
}
