import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationsService } from '../../services/business/organizations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrganizationUpdateSuccessAction } from './organization-update.actions';
import { OrganizationFailAction, OrganizationGetAction, OrganizationGetSuccessAction, OrganizationResetAction } from './organization.actions';
import { Organization } from '../../models/business/organizations/organization.model';

export interface OrganizationStateModel {
    entity: Organization;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationStateModel>({
    name: 'organization',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class OrganizationState {
    constructor(private organizationsService: OrganizationsService) { }

    @Action(OrganizationGetAction) organizationGet(ctx: StateContext<OrganizationStateModel>, action: OrganizationGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.organizationsService.getOrganization(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationFailAction(err)), 0));
    }

    @Action(OrganizationGetSuccessAction) organizationGetSuccess(ctx: StateContext<OrganizationStateModel>, action: OrganizationGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(OrganizationFailAction) organizationGetFail(ctx: StateContext<OrganizationStateModel>, action: OrganizationFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationResetAction) organizationGetReset(ctx: StateContext<OrganizationStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(OrganizationUpdateSuccessAction) organizationUpdateSuccess(ctx: StateContext<OrganizationStateModel>, action: OrganizationUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
