import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { TechnologicalMapArchivesAction, TechnologicalMapArchivesFailAction, TechnologicalMapArchivesResetAction, TechnologicalMapArchivesSetFilterAction, TechnologicalMapArchivesSuccessAction } from './technological-map-archives.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapListGetFilter, TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export interface TechnologicalMapArchivesStateModel extends PaginationFilteredStateModel<TechnologicalMapListItemAggregation, TechnologicalMapListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: TechnologicalMapListItemAggregation };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TechnologicalMapListGetFilterData;
    total: number;
}

@State<TechnologicalMapArchivesStateModel>({
    name: 'technologicalMapArchives',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class TechnologicalMapArchivesState extends PaginationFilteredStateBase {
    constructor(private technologicalMapsService: TechnologicalMapsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([TechnologicalMapArchivesAction]) productsGet(ctx: StateContext<TechnologicalMapArchivesStateModel>, action: TechnologicalMapArchivesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'technological-map-archive');
        ctx.setState({ ...state, filter: filterData });

        const filter = new TechnologicalMapListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.technologicalMapsService.getTechnologicalMapList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TechnologicalMapArchivesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapArchivesFailAction(err)), 0));
    }

    @Action(TechnologicalMapArchivesSuccessAction) technologicalMapArchiveGetSuccess(ctx: StateContext<TechnologicalMapArchivesStateModel>, action: TechnologicalMapArchivesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(TechnologicalMapArchivesFailAction) technologicalMapArchiveGetFail(ctx: StateContext<TechnologicalMapArchivesStateModel>, action: TechnologicalMapArchivesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TechnologicalMapArchivesResetAction) technologicalMapArchiveGetReset(ctx: StateContext<TechnologicalMapArchivesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(TechnologicalMapArchivesSetFilterAction) usersSetFilter(ctx: StateContext<TechnologicalMapArchivesStateModel>, action: TechnologicalMapArchivesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'technological-map-archive');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new TechnologicalMapArchivesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
