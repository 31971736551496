import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { MotivationBonusesAction, MotivationBonusesFailAction, MotivationBonusesResetAction, MotivationBonusesSetFilterAction, MotivationBonusesSuccessAction } from './motivation-bonuses.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonusListItem } from '../../models/business/contacts/motivation-bonus-list-item.model';
import { MotivationBonusesListGetFilter, MotivationBonusesListGetFilterData } from '../../models/filters/motivation-bonuses-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface MotivationBonusesStateModel extends PaginationStateModel<MotivationBonusListItem> {
    uids: string[];
    entities: { [uid: string]: MotivationBonusListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: MotivationBonusesListGetFilterData;
    total: number;
}

@State<MotivationBonusesStateModel>({
    name: 'motivationBonuses',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} }
})
@Injectable()
export class MotivationBonusesState extends PaginationStateBase {
    constructor(private contactsService: ContactsService) {
        super();
    }

    @Action([MotivationBonusesAction]) contactsGet(ctx: StateContext<MotivationBonusesStateModel>, action: MotivationBonusesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new MotivationBonusesListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contactsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contactsService.getMotivationList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new MotivationBonusesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusesFailAction(err)), 0));
    }

    @Action(MotivationBonusesSuccessAction) contactsGetSuccess(ctx: StateContext<MotivationBonusesStateModel>, action: MotivationBonusesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(MotivationBonusesFailAction) contactsGetFail(ctx: StateContext<MotivationBonusesStateModel>, action: MotivationBonusesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(MotivationBonusesResetAction) contactsGetReset(ctx: StateContext<MotivationBonusesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(MotivationBonusesSetFilterAction) usersSetFilter(ctx: StateContext<MotivationBonusesStateModel>, action: MotivationBonusesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new MotivationBonusesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
