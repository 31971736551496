import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseInvoice } from '../../models/business/purchase-invoices/purchase-invoice.model';

export class PurchaseInvoiceGetAction {
    static readonly type = '[PurchaseInvoice Page] GetPurchaseInvoice Get';

    constructor(public payload: string) { }
}

export class PurchaseInvoiceGetSuccessAction {
    static readonly type = '[PurchaseInvoice API] GetPurchaseInvoice Success';

    constructor(public payload: PurchaseInvoice) { }
}

export class PurchaseInvoiceFailAction {
    static readonly type = '[PurchaseInvoice API] GetPurchaseInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseInvoiceResetAction {
    static readonly type = '[PurchaseInvoice Page] GetPurchaseInvoice Reset';
}
