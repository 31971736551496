import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { WriteOffsService } from '../../services/business/write-offs.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { WriteOffCreateSuccessAction } from './write-off-create.actions';
import { WriteOffDeleteSuccessAction } from './write-off-delete.actions';
import { WriteOffUpdateSuccessAction } from './write-off-update.actions';
import { WriteOffsAction, WriteOffsFailAction, WriteOffsResetAction, WriteOffsSetFilterAction, WriteOffsSuccessAction } from './write-offs.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WriteOffListGetFilter, WriteOffListGetFilterData } from '../../models/filters/write-off-list-get-filter.model';
import { WriteOffListItem } from '../../models/business/write-offs/write-off-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface WriteOffsStateModel extends PaginationFilteredStateModel<WriteOffListItem, WriteOffListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: WriteOffListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: WriteOffListGetFilterData;
    total: number;
}

@State<WriteOffsStateModel>({
    name: 'writeoffs',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class WriteOffsState extends PaginationFilteredStateBase {
    constructor(private writeoffsService: WriteOffsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([WriteOffsAction]) productsGet(ctx: StateContext<WriteOffsStateModel>, action: WriteOffsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'writeoff');
        ctx.setState({ ...state, filter: filterData });

        const filter = new WriteOffListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.writeoffsService.getWriteOffList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new WriteOffsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WriteOffsFailAction(err)), 0));
    }

    @Action(WriteOffsSuccessAction) writeoffGetSuccess(ctx: StateContext<WriteOffsStateModel>, action: WriteOffsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(WriteOffsFailAction) writeoffGetFail(ctx: StateContext<WriteOffsStateModel>, action: WriteOffsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WriteOffsResetAction) writeoffGetReset(ctx: StateContext<WriteOffsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(WriteOffsSetFilterAction) usersSetFilter(ctx: StateContext<WriteOffsStateModel>, action: WriteOffsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'writeoff');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new WriteOffsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(WriteOffCreateSuccessAction) writeoffCreateSuccess(ctx: StateContext<WriteOffsStateModel>, action: WriteOffCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(WriteOffUpdateSuccessAction) writeoffUpdateSuccess(ctx: StateContext<WriteOffsStateModel>, action: WriteOffUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(WriteOffDeleteSuccessAction) writeoffDeleteSuccess(ctx: StateContext<WriteOffsStateModel>, action: WriteOffDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
