<div class="activities">
    <perfect-scrollbar class="scroll" (psYReachEnd)="onLoadMore($event)">
        <div class="create-box">
            <div class="create-tabs">
                <exg-button class="tab_btn" [class.active]="(createTab$ | async) === clientsActivityTypeEnum.Note" styleType="custom" (btnClick)="onSwitchCreateTab(clientsActivityTypeEnum.Note)">{{'Note' | translate}}</exg-button>
                <exg-button class="tab_btn" [class.active]="(createTab$ | async) === clientsActivityTypeEnum.Task" styleType="custom" (btnClick)="onSwitchCreateTab(clientsActivityTypeEnum.Task)">{{'Task' | translate}}</exg-button>
                <exg-button class="tab_btn" [class.active]="(createTab$ | async) === clientsActivityTypeEnum.Email" styleType="custom" (btnClick)="onSwitchCreateTab(clientsActivityTypeEnum.Email)">{{'E-Mail' | translate}}</exg-button>
            </div>
            <div class="create-form">
                <bvc-clients-activity-form *ngIf="(createTab$ | async) === clientsActivityTypeEnum.Note"
                                           [companyUid]="companyUid"
                                           [error]="createError$ | async"
                                           [created]="created$ | async"
                                           (noteCreate)="onNoteCreate($event)"></bvc-clients-activity-form>

                <bvc-clients-activity-task-form *ngIf="(createTab$ | async) === clientsActivityTypeEnum.Task"
                                                [companyUid]="companyUid"
                                                [error]="taskCreateError$ | async"
                                                [created]="taskCreated$ | async"
                                                [users]="usersSearch$ | async"
                                                (taskCreate)="onTaskCreate($event)"></bvc-clients-activity-task-form>

                <bvc-clients-activity-task-email-form *ngIf="(createTab$ | async) === clientsActivityTypeEnum.Email"
                                                      [companyUid]="companyUid"
                                                      [error]="taskCreateError$ | async"
                                                      [created]="taskCreated$ | async"
                                                      (taskCreate)="onTaskCreate($event)"></bvc-clients-activity-task-email-form>
            </div>
        </div>
        <div class="filter-line">
            <exg-select class="select options" 
                        [data]="displayData$ | async"
                        [value]="statusValue"
                        styleType="empty"
                        [multiple]="true"
                        [placeholder]="statusValue ? null : ('Display' | translate)"
                        (valueChange)="onDisplayChanged($event)"></exg-select>
        </div>
        <div class="notes-list">
            <ng-container *ngFor="let feedItem of feed$ | async; trackBy: trackByFeed">
                <div class="group-line" *ngIf="group[feedItem.uid]">
                    <div class="group-icon"></div>
                    <div class="date">
                        <div class="create-date">{{feedItem.createDate | exgDate:'shortDate'}}</div>
                    </div>
                    <div class="line"></div>
                </div>

                <bvc-clients-activity-item class="note"
                                           [feedItem]="feedItem"
                                           [companyUid]="companyUid"
                                           [error]="(updateError$ | async) || (taskUpdateError$ | async)"
                                           [users]="usersSearch$ | async"
                                           (noteUpdate)="onNoteUpdate($event)"
                                           (noteDelete)="onNoteDelete($event)"
                                           (taskUpdate)="onTaskUpdate($event)"
                                           (taskDelete)="onTaskDelete($event)"></bvc-clients-activity-item>
            </ng-container>
        </div>
    </perfect-scrollbar>
</div>
