import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationTree } from '../../models/business/organization-tree/organization-tree.model';
import { OrganizationTreeListGetFilterData } from '../../models/filters/organization-tree-list-get-filter.model';

export class OrganizationTreeAction {
    static readonly type = '[OrganizationTree Page] GetOrganizationTree';

    constructor(public payload: OrganizationTreeListGetFilterData) { }
}

export class OrganizationTreeSuccessAction {
    static readonly type = '[OrganizationTree API] GetOrganizationTree Success';

    constructor(public payload: OrganizationTree) { }
}

export class OrganizationTreeFailAction {
    static readonly type = '[OrganizationTree API] GetOrganizationTree Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationTreeResetAction {
    static readonly type = '[OrganizationTree Page] GetOrganizationTree Reset';
}
