import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CompaniesListFilterData } from '../../../../models/filters/companies-list-filter.model';
import { CompanySortingFieldsEnum } from '../../../../models/business/companies/company-sorting-fields.enum';
import { SortOrder } from '../../../../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'company-header',
    templateUrl: './company-header.component.html',
    styleUrls: ['./company-header.component.scss', '../company-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyHeaderComponent {

    @Input() filter: CompaniesListFilterData;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public companySortingFields = CompanySortingFieldsEnum;

    public onSortingChange($event: { sortOrder: SortOrder, sortField: string }) {
        this.sortingChange.emit($event);
    }
}
