import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ExgListParams } from '../../../exg-list-params.config';
import { PosListFilterData } from '../../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../../models/business/pos/pos-list-item.model';
import { PosSortingFieldsEnum } from '../../../models/business/pos/pos-sorting-fields.enum';

@Component({
    selector: 'pos-list',
    templateUrl: './pos-list.component.html',
    styleUrls: ['./pos-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PosListComponent implements OnChanges {

    @Input() pos: PosListItem[];
    @Input() allPosLoaded: boolean;
    @Input() pending: boolean;
    @Input() retrieved: boolean;
    @Input() filter: PosListFilterData;
    @Input() enableAddLine = false;
    @Input() ignoreRows: PosSortingFieldsEnum[] = [];
    @Input() shortList = false;

    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly openPos = new EventEmitter<string>();
    @Output() readonly sortingChange = new EventEmitter<PosListFilterData>();
    @Output() readonly addBtnClick = new EventEmitter<void>();

    public listConfig = ExgListParams.posList();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.ignoreRows && this.ignoreRows && this.ignoreRows.length > 0) {
            this.listConfig = ExgListParams.posList(this.ignoreRows);
        }
    }

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }

    public onLoadMore(_: any) {
        this.loadMore.emit();
    }

    public onSortingChange($event: PosListFilterData) {
        this.sortingChange.emit($event);
    }

    public trackByPos(_, item: PosListItem) {
        return item.uid;
    }

    public onOpenPos(uid: string) {
        this.openPos.emit(uid);
    }
}
