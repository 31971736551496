import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonusesUpdateListRequest } from '../../models/business/contacts/motivation-bonuses-update-list-request.model';

export class MotivationBonusUpdateListAction {
    static readonly type = '[MotivationBonus Page] UpdateMotivationBonusList';

    constructor(public payload: { contactUid: string, request: MotivationBonusesUpdateListRequest }) { }
}

export class MotivationBonusUpdateListSuccessAction {
    static readonly type = '[MotivationBonus API] UpdateMotivationBonusList Success';

    constructor(public payload: { contactUid: string, request: MotivationBonusesUpdateListRequest }) { }
}

export class MotivationBonusUpdateListFailAction {
    static readonly type = '[MotivationBonus API] UpdateMotivationBonusList Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusUpdateListResetAction {
    static readonly type = '[MotivationBonus Page] UpdateMotivationBonusList Reset';
}
