import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class NoteDeleteAction {
    static readonly type = '[My Note Page] DeleteNote';

    constructor(public payload: { uid: string }) { }
}

export class NoteDeleteSuccessAction {
    static readonly type = '[My Notes API] DeleteNote Success';

    constructor(public payload: { uid: string }) { }
}

export class NoteDeleteFailAction {
    static readonly type = '[My Notes API] DeleteNote Fail';

    constructor(public payload: ErrorObject) { }
}

export class NoteDeleteResetAction {
    static readonly type = '[My Note Page] DeleteNote Reset';
}
