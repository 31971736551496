import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class SupplierInvoiceDeleteAction {
    static readonly type = '[My SupplierInvoice Page] DeleteSupplierInvoice';

    constructor(public payload: { uid: string }) { }
}

export class SupplierInvoiceDeleteSuccessAction {
    static readonly type = '[My SupplierInvoices API] DeleteSupplierInvoice Success';

    constructor(public payload: { uid: string }) { }
}

export class SupplierInvoiceDeleteFailAction {
    static readonly type = '[My SupplierInvoices API] DeleteSupplierInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class SupplierInvoiceDeleteResetAction {
    static readonly type = '[My SupplierInvoice Page] DeleteSupplierInvoice Reset';
}
