import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationTreeService } from '../../services/business/organization-tree.service';

import { PaginationStateBase } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { OrganizationTreeAction, OrganizationTreeFailAction, OrganizationTreeResetAction, OrganizationTreeSuccessAction } from './organization-tree.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationTreeListGetFilter, OrganizationTreeListGetFilterData } from '../../models/filters/organization-tree-list-get-filter.model';
import { OrganizationTree } from '../../models/business/organization-tree/organization-tree.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface OrganizationTreeStateModel {
    entities: OrganizationTree;
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: OrganizationTreeListGetFilterData;
}

@State<OrganizationTreeStateModel>({
    name: 'organizationTree',
    defaults: { pending: false, entities: null, retrieved: false, error: null, index: 0, filter: null, allItemsLoaded: false }
})
@Injectable()
export class OrganizationTreeState extends PaginationStateBase {
    constructor(private organizationTreeService: OrganizationTreeService) {
        super();
    }

    @Action([OrganizationTreeAction]) organizationTreeGet(ctx: StateContext<OrganizationTreeStateModel>, action: OrganizationTreeAction) {
        const state = ctx.getState();

        const filterData = { ...state.filter, ...action.payload };
        if (CompareUtils.isObjectsEqual(state.filter, filterData)) {
            return;
        }

        ctx.setState({ ...state, pending: true, filter: filterData, error: null });
        const filter = new OrganizationTreeListGetFilter({ pageIndex: state.index, filterData: action.payload });

        return this.organizationTreeService.getTree(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationTreeSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationTreeFailAction(err)), 0));
    }

    @Action(OrganizationTreeSuccessAction) organizationTreeesGetSuccess(ctx: StateContext<OrganizationTreeStateModel>, action: OrganizationTreeSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, allItemsLoaded: true, retrieved: true, error: null });
    }

    @Action(OrganizationTreeFailAction) organizationTreeesGetFail(ctx: StateContext<OrganizationTreeStateModel>, action: OrganizationTreeFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationTreeResetAction) organizationTreeesGetReset(ctx: StateContext<OrganizationTreeStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, filter: null, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
