import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapUpdateRequest } from '../../models/business/technological-maps/technological-map-update-request.model';

export class TechnologicalMapUpdateAction {
    static readonly type = '[Booking Page] UpdateTechnologicalMap';

    constructor(public payload: { uid: string, request: TechnologicalMapUpdateRequest }) { }
}

export class TechnologicalMapUpdateSuccessAction {
    static readonly type = '[TechnologicalMap API] UpdateTechnologicalMap Success';

    constructor(public payload: { uid: string, request: TechnologicalMapUpdateRequest }) { }
}

export class TechnologicalMapUpdateFailAction {
    static readonly type = '[TechnologicalMap API] UpdateTechnologicalMap Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapUpdateResetAction {
    static readonly type = '[Booking Page] UpdateTechnologicalMap Reset';
}
