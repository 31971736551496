import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrganizationPosDeleteAction, OrganizationPosDeleteFailAction, OrganizationPosDeleteResetAction, OrganizationPosDeleteSuccessAction } from './organization-pos-delete.actions';

export interface OrganizationPosDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<OrganizationPosDeleteStateModel>({
    name: 'organizationPosDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class OrganizationPosDeleteState {
    constructor(private orgPosService: OrganizationPosService) { }

    @Action(OrganizationPosDeleteAction) async poseDelete(ctx: StateContext<OrganizationPosDeleteStateModel>, action: OrganizationPosDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.orgPosService.deletePos(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new OrganizationPosDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new OrganizationPosDeleteFailAction(error)), 0));
    }

    @Action(OrganizationPosDeleteSuccessAction) organizationPoseDeleteSuccess(ctx: StateContext<OrganizationPosDeleteStateModel>, _: OrganizationPosDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(OrganizationPosDeleteFailAction) organizationPoseDeleteFail(ctx: StateContext<OrganizationPosDeleteStateModel>, action: OrganizationPosDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosDeleteResetAction) organizationPoseDeleteReset(ctx: StateContext<OrganizationPosDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
