import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PosUpdateSuccessAction } from './pos-update.actions';
import { PosFailAction, PosGetAction, PosGetSuccessAction, PosResetAction } from './pos.actions';
import { Pos } from '../../models/business/pos/pos.model';

export interface PosStateModel {
    entity: Pos;
    pending: boolean;
    error: ErrorObject;
}

@State<PosStateModel>({
    name: 'pos',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PosState {
    constructor(private posService: PosService) { }

    @Action(PosGetAction) posGet(ctx: StateContext<PosStateModel>, action: PosGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.posService.getPos(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PosGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosFailAction(err)), 0));
    }

    @Action(PosGetSuccessAction) posGetSuccess(ctx: StateContext<PosStateModel>, action: PosGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PosFailAction) posGetFail(ctx: StateContext<PosStateModel>, action: PosFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PosResetAction) posGetReset(ctx: StateContext<PosStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(PosUpdateSuccessAction) posUpdateSuccess(ctx: StateContext<PosStateModel>, action: PosUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
