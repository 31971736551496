import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { NoteDeleteAction, NoteDeleteResetAction } from './note-delete.actions';

@Injectable({
    providedIn: 'root'
})
export class NoteDeleteDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('NoteDeleteDispatchers');
    }

    public dispatchNoteDeleteAction(uid: string) {
        this.store.dispatch(new NoteDeleteAction({ uid }));
    }

    public dispatchNoteDeleteResetAction() {
        this.store.dispatch(new NoteDeleteResetAction());
    }
}
