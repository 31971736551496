import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilterData } from '../../models/filters/organizations-list-get-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class OrganizationsSearchAction {
    static readonly type = '[OrganizationsSearch Page] GetOrganizationsSearch';

    constructor(public payload: OrganizationsListGetFilterData) { }
}

export class OrganizationsSearchSuccessAction {
    static readonly type = '[OrganizationsSearch API] GetOrganizationsSearch Success';

    constructor(public payload: PaginationResult<OrganizationListItem>) { }
}

export class OrganizationsSearchFailAction {
    static readonly type = '[OrganizationsSearch API] GetOrganizationsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationsSearchResetAction {
    static readonly type = '[OrganizationsSearch Page] GetOrganizationsSearch Reset';
}
