import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { BankSuggestion } from '../../models/business/contragents/bank-suggestion.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { RequisitesCreateOrUpdateRequest } from '../../models/business/contragents/requisites-create-or-update.model';
import { RequisitesListFilter } from '../../models/filters/requisites-list-filter.model';
import { Requisite } from '../../models/business/contragents/requisites.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class RequisitesService extends BaseSingletonService {

    private settings: {
        service: {
            getRequisite: string;
            getRequisiteList: string;
            getSuggestions: string;
            createRequisite: string;
            updateRequisite: string;
            deleteRequisite: string;
        }
    };

    constructor(private http: HttpService) {
        super('RequisitesService');
        this.settings = {
            service: {
                getRequisite: '/crm/v1/contragents/{0}/requisites/{1}',
                getRequisiteList: '/crm/v1/contragents/requisites?term={0}&companyUid={1}&contragentUid={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                getSuggestions: '/crm/v1/contragents/requisites/suggestions?term={0}',
                createRequisite: '/crm/v1/contragents/{0}/requisites',
                updateRequisite: '/crm/v1/contragents/{0}/requisites/{1}',
                deleteRequisite: '/crm/v1/contragents/{0}/requisites/{1}'
            }
        };
    }

    public async getRequisite(uid: string, contragenUid: string): Promise<Requisite> {
        return this.http.get<{ data: Requisite }>(String.format(this.settings.service.getRequisite, contragenUid, uid))
            .then(res => res.data);
    }

    public async getRequisiteList(filter: RequisitesListFilter): Promise<PaginationResult<Requisite>> {
        return this.http.get<PaginationResult<Requisite>>(String.format(this.settings.service.getRequisiteList, filter.term, filter.companyUid, filter.contragentUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getSuggestions(term: string): Promise<BankSuggestion[]> {
        return this.http.get<{ list: BankSuggestion[] }>(String.format(this.settings.service.getSuggestions, term))
            .then(res => res.list);
    }

    public async createRequisite(contragentUid: string, request: RequisitesCreateOrUpdateRequest): Promise<Requisite> {
        return this.http.post<{ data: Requisite }>(String.format(this.settings.service.createRequisite, contragentUid), request)
            .then(res => res.data);
    }

    public async updateRequisite(uid: string, contragentUid: string, request: RequisitesCreateOrUpdateRequest): Promise<Requisite> {
        return this.http.put(String.format(this.settings.service.updateRequisite, contragentUid, uid), request);
    }

    public async deleteRequisite(uid: string, contragentUid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRequisite, contragentUid, uid));
    }
}
