import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { InventoryCreateOrUpdateRequest } from '../../models/business/inventories/inventory-create-or-update-request.model';

export class InventoryUpdateAction {
    static readonly type = '[Booking Page] UpdateInventory';

    constructor(public payload: { uid: string, request: InventoryCreateOrUpdateRequest }) { }
}

export class InventoryUpdateSuccessAction {
    static readonly type = '[Inventory API] UpdateInventory Success';

    constructor(public payload: { uid: string, request: InventoryCreateOrUpdateRequest }) { }
}

export class InventoryUpdateFailAction {
    static readonly type = '[Inventory API] UpdateInventory Fail';

    constructor(public payload: ErrorObject) { }
}

export class InventoryUpdateResetAction {
    static readonly type = '[Booking Page] UpdateInventory Reset';
}
