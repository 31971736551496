import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PurchaseReturnListGetFilterData } from '../../models/filters/purchase-return-list-get-filter.model';
import { PurchaseReturnListItem } from '../../models/business/purchase-returns/purchase-return-list-item.model';

export class PurchaseReturnsAction {
    static readonly type = '[PurchaseReturnss Page] GetPurchaseReturns';

    constructor(public payload: PaginationRequest) { }
}

export class PurchaseReturnsSuccessAction {
    static readonly type = '[PurchaseReturns API] GetPurchaseReturns Success';

    constructor(public payload: { list: PaginationResult<PurchaseReturnListItem>, pagination: PaginationRequest, totalAmount: number }) { }
}

export class PurchaseReturnsFailAction {
    static readonly type = '[PurchaseReturns API] GetPurchaseReturns Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseReturnsResetAction {
    static readonly type = '[PurchaseReturns Page] GetPurchaseReturns Reset';
}

export class PurchaseReturnsSetFilterAction {
    static readonly type = '[PurchaseReturns API] SetFilter';

    constructor(public payload: PurchaseReturnListGetFilterData) { }
}
