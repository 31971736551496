import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationCatalogService } from '../../services/business/corporation-catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PublicProductListGetAggregationFilter, PublicProductListGetAggregationFilterData } from '../../models/filters/public-product-list-get-aggregation-filter.model';
import { PublicProductListItemAggregation } from '../../models/business/products/public-product-list-item-aggregation.model';

import { PublicCorporationProductsAction, PublicCorporationProductsFailAction, PublicCorporationProductsResetAction, PublicCorporationProductsSuccessAction } from './public-products.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';

export interface PublicCorporationProductsStateModel {
    entities: PublicProductListItemAggregation[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: PublicProductListGetAggregationFilterData;
}

@State<PublicCorporationProductsStateModel>({
    name: 'CorporationPublicProducts',
    defaults: { pending: false, entities: [], retrieved: false, error: null, filter: { } }
})
@Injectable()
export class PublicCorporationProductsState {
    constructor(private corporationCatalogService: CorporationCatalogService) {
    }

    @Action([PublicCorporationProductsAction]) productsSearchGet(ctx: StateContext<PublicCorporationProductsStateModel>, action: PublicCorporationProductsAction) {
        const state = ctx.getState();

        const filterData = { ...state.filter, ...action.payload };
        if (CompareUtils.isObjectsEqual(state.filter, filterData)) {
            return;
        }

        ctx.setState({ ...state, filter: filterData, pending: true, error: null });

        return this.corporationCatalogService.getPublicProducts(new PublicProductListGetAggregationFilter({ filterData }))
            .then(resp => setTimeout(() => ctx.dispatch(new PublicCorporationProductsSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PublicCorporationProductsFailAction(err)), 0));
    }

    @Action(PublicCorporationProductsSuccessAction) PublicCorporationProductsGetSuccess(ctx: StateContext<PublicCorporationProductsStateModel>, action: PublicCorporationProductsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(PublicCorporationProductsFailAction) PublicCorporationProductsGetFail(ctx: StateContext<PublicCorporationProductsStateModel>, action: PublicCorporationProductsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PublicCorporationProductsResetAction) PublicCorporationProductsGetReset(ctx: StateContext<PublicCorporationProductsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null, filter: {} });
    }
}
