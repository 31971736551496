import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { TechnologicalMapByContentAction, TechnologicalMapByContentFailAction, TechnologicalMapByContentResetAction, TechnologicalMapByContentSetFilterAction, TechnologicalMapByContentSuccessAction, TechnologicalMapsByContentUpdateItemAction } from './technological-map-by-content.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TechnologicalMapListGetFilter, TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export interface TechnologicalMapByContentStateModel extends PaginationFilteredStateModel<TechnologicalMapListItemAggregation, TechnologicalMapListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: TechnologicalMapListItemAggregation };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TechnologicalMapListGetFilterData;
    total: number;
}

@State<TechnologicalMapByContentStateModel>({
    name: 'technologicalMapByContent',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class TechnologicalMapByContentState extends PaginationFilteredStateBase {
    constructor(private technologicalMapsService: TechnologicalMapsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([TechnologicalMapByContentAction]) productsGet(ctx: StateContext<TechnologicalMapByContentStateModel>, action: TechnologicalMapByContentAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'technological-map-archive');
        ctx.setState({ ...state, filter: filterData });

        const filter = new TechnologicalMapListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.technologicalMapsService.getTechnologicalMapListByContent(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TechnologicalMapByContentSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapByContentFailAction(err)), 0));
    }

    @Action(TechnologicalMapByContentSuccessAction) technologicalMapArchiveGetSuccess(ctx: StateContext<TechnologicalMapByContentStateModel>, action: TechnologicalMapByContentSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(TechnologicalMapsByContentUpdateItemAction) technologicalMapsByContentUpdateListItemAction(ctx: StateContext<TechnologicalMapByContentStateModel>, action: TechnologicalMapsByContentUpdateItemAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...action.payload };
        ctx.setState({ ...state, entities });
    }

    @Action(TechnologicalMapByContentFailAction) technologicalMapArchiveGetFail(ctx: StateContext<TechnologicalMapByContentStateModel>, action: TechnologicalMapByContentFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TechnologicalMapByContentResetAction) technologicalMapArchiveGetReset(ctx: StateContext<TechnologicalMapByContentStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(TechnologicalMapByContentSetFilterAction) usersSetFilter(ctx: StateContext<TechnologicalMapByContentStateModel>, action: TechnologicalMapByContentSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'technological-map-archive');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new TechnologicalMapByContentAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
