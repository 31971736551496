import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NomenclatureService } from '../../services/business/nomenclature.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { GroupListItem } from '../../models/business/nomenclature/group-list-item.model';

import { GroupsAction, GroupsFailAction, GroupsResetAction, GroupsSuccessAction } from './groups.actions';

export interface GroupsStateModel {
    pending: boolean;
    entity: GroupListItem[];
    error: ErrorObject;
}

@State<GroupsStateModel>({
    name: 'groups',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class GroupsState {
    constructor(private nomenclatureService: NomenclatureService) { }

    @Action(GroupsAction) async order(ctx: StateContext<GroupsStateModel>, _: GroupsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.nomenclatureService.getGroups()
            .then(res => setTimeout(() => ctx.dispatch(new GroupsSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new GroupsFailAction(err)), 0));
    }

    @Action(GroupsSuccessAction) orderSuccess(ctx: StateContext<GroupsStateModel>, action: GroupsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(GroupsFailAction) orderFail(ctx: StateContext<GroupsStateModel>, action: GroupsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(GroupsResetAction) orderReset(ctx: StateContext<GroupsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
