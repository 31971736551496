import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WarehousesService } from '../../services/business/warehouses.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { WarehousesSearchAction, WarehousesSearchFailAction, WarehousesSearchResetAction, WarehousesSearchSuccessAction } from './warehouses-search.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WarehousesListFilter, WarehousesListFilterData } from '../../models/filters/warehouses-list-filter.model';

import { ExgParamsConfig } from '../../exg-params.config';
import { WarehouseListItem } from '../../models/business/warehouses/warehouse-list-item.model';

export interface WarehousesSearchStateModel extends PaginationStateModel<WarehouseListItem> {
    uids: string[];
    entities: { [uid: string]: WarehouseListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: WarehousesListFilterData;
    total: number;
}

@State<WarehousesSearchStateModel>({
    name: 'warehousesSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class WarehousesSearchState extends PaginationStateBase {
    constructor(private warehousesService: WarehousesService) {
        super();
    }

    @Action([WarehousesSearchAction]) warehousesGet(ctx: StateContext<WarehousesSearchStateModel>, action: WarehousesSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new WarehousesListFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.warehousesService.getWarehouseList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new WarehousesSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WarehousesSearchFailAction(err)), 0));
    }

    @Action(WarehousesSearchSuccessAction) warehousesGetSuccess(ctx: StateContext<WarehousesSearchStateModel>, action: WarehousesSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.warehousesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(WarehousesSearchFailAction) warehousesGetFail(ctx: StateContext<WarehousesSearchStateModel>, action: WarehousesSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WarehousesSearchResetAction) warehousesGetReset(ctx: StateContext<WarehousesSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }
}
