import { ContactCreateOrUpdateRequest } from '../../models/business/contacts/contact-create-or-update.model';
import { Contact } from '../../models/business/contacts/contact.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContactCreateAction {
    static readonly type = '[Contacts Page] CreateContact';

    constructor(public payload: ContactCreateOrUpdateRequest) { }
}

export class ContactCreateSuccessAction {
    static readonly type = '[Contact API] CreateContact Success';

    constructor(public payload: Contact) { }
}

export class ContactCreateFailAction {
    static readonly type = '[Contact API] CreateContact Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactCreateResetAction {
    static readonly type = '[Contacts Page] CreateContact Reset';
}
