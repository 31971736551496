import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosSettings } from '../../models/business/intervals/organization-pos-settings.model';

export class TimeIntervalsGetAction {
    static readonly type = '[TimeIntervals Page] GetInterval Get';

    constructor(public payload: { organizationPosUid: string }) { }
}

export class TimeIntervalsGetSuccessAction {
    static readonly type = '[TimeIntervals API] GetInterval Success';

    constructor(public payload: OrganizationPosSettings) { }
}

export class TimeIntervalsFailAction {
    static readonly type = '[TimeIntervals API] GetInterval Fail';

    constructor(public payload: ErrorObject) { }
}

export class TimeIntervalsResetAction {
    static readonly type = '[TimeIntervals Page] GetInterval Reset';
}
