import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RequisitesService } from '../../services/business/requisites.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { RequisiteDeleteAction, RequisiteDeleteFailAction, RequisiteDeleteResetAction, RequisiteDeleteSuccessAction } from './requisite-delete.actions';

export interface RequisiteDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<RequisiteDeleteStateModel>({
    name: 'requisiteDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class RequisiteDeleteState {
    constructor(private requisitesService: RequisitesService) { }

    @Action(RequisiteDeleteAction) async requisiteDelete(ctx: StateContext<RequisiteDeleteStateModel>, action: RequisiteDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.requisitesService.deleteRequisite(action.payload.uid, action.payload.contragentUid)
            .then(() => setTimeout(() => ctx.dispatch(new RequisiteDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new RequisiteDeleteFailAction(error)), 0));
    }

    @Action(RequisiteDeleteSuccessAction) requisiteDeleteSuccess(ctx: StateContext<RequisiteDeleteStateModel>, _: RequisiteDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(RequisiteDeleteFailAction) requisiteDeleteFail(ctx: StateContext<RequisiteDeleteStateModel>, action: RequisiteDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RequisiteDeleteResetAction) requisiteDeleteReset(ctx: StateContext<RequisiteDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
