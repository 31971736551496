import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { MotivationBonusUpdateListAction, MotivationBonusUpdateListFailAction, MotivationBonusUpdateListResetAction, MotivationBonusUpdateListSuccessAction } from './motivation-bonus-update-list.actions';

export interface MotivationBonusUpdateListStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<MotivationBonusUpdateListStateModel>({
    name: 'motivationBonusUpdateList',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class MotivationBonusUpdateListState {
    constructor(private contactsService: ContactsService) { }

    @Action(MotivationBonusUpdateListAction) async motivationBonusUpdate(ctx: StateContext<MotivationBonusUpdateListStateModel>, action: MotivationBonusUpdateListAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.contactsService.updateMotivationList(action.payload.contactUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new MotivationBonusUpdateListSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusUpdateListFailAction(err)), 0));
    }

    @Action(MotivationBonusUpdateListSuccessAction) motivationBonusUpdateSuccess(ctx: StateContext<MotivationBonusUpdateListStateModel>, _: MotivationBonusUpdateListSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(MotivationBonusUpdateListFailAction) motivationBonusUpdateFail(ctx: StateContext<MotivationBonusUpdateListStateModel>, action: MotivationBonusUpdateListFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(MotivationBonusUpdateListResetAction) motivationBonusUpdateReset(ctx: StateContext<MotivationBonusUpdateListStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
