export enum OrderSource {
    All = 0,
    OnSite = 1,
    CallCenter = 2,
    OnMobileAndroid = 3,
    OnMobileIOS = 4,
    VK = 5,
    YandexFood = 6,
    DeliveryClub = 7,
    Chibbis = 8,
    Cooper = 9
}
