import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalCreateOrUpdateRequest } from '../../models/business/intervals/interval-create-or-update-request.model';
import { IntervalListItem } from '../../models/business/intervals/interval-list-item.model';

export class IntervalUpdateAction {
    static readonly type = '[Intervals Page] UpdateContract';

    constructor(public payload: { request: IntervalCreateOrUpdateRequest }) { }
}

export class IntervalUpdateSuccessAction {
    static readonly type = '[Intervals API] UpdateContract Success';

    constructor(public payload: IntervalListItem[]) { }
}

export class IntervalUpdateFailAction {
    static readonly type = '[Intervals API] UpdateContract Fail';

    constructor(public payload: ErrorObject) { }
}

export class IntervalUpdateResetAction {
    static readonly type = '[Intervals Page] UpdateContract Reset';
}

