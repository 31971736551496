import { Pipe, PipeTransform } from '@angular/core';

import { DateUtils } from '../../../burns-ui-framework/shared/utils/date-utils';

@Pipe({
    name: 'yeTimeInterval',
    pure: false
})
export class YeTimeIntervalPipe implements PipeTransform {

    public transform(dateFrom: number, dateTo: number): string {
        const startDate = DateUtils.convertStringToEpoc(DateUtils.startOf(DateUtils.convertEpocToString(dateFrom), 'day'));

        return `${DateUtils.convertEpochTimeToString(dateFrom - startDate)} - ${DateUtils.convertEpochTimeToString(dateTo - startDate)}`;
    }
}
