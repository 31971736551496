import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CategoryUpdateAction, CategoryUpdateFailAction, CategoryUpdateResetAction, CategoryUpdateSuccessAction } from './category-update.actions';

export interface CategoryUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<CategoryUpdateStateModel>({
    name: 'categoryUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class CategoryUpdateState {
    constructor(private catalogService: CatalogService) { }

    @Action(CategoryUpdateAction) async categoryUpdate(ctx: StateContext<CategoryUpdateStateModel>, action: CategoryUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.catalogService.updateCategory(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new CategoryUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CategoryUpdateFailAction(err)), 0));
    }

    @Action(CategoryUpdateSuccessAction) categoryUpdateSuccess(ctx: StateContext<CategoryUpdateStateModel>, _: CategoryUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(CategoryUpdateFailAction) categoryUpdateFail(ctx: StateContext<CategoryUpdateStateModel>, action: CategoryUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(CategoryUpdateResetAction) categoryUpdateReset(ctx: StateContext<CategoryUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
