import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ChartsOptionsFactory } from './charts-options.factory';

import { ExgDatePipe } from '../../../../../burns-ui-framework/shared/pipes/exg-date/exg-date.pipe';
import { ExgDecimalPipe } from '../../../../../burns-ui-framework/shared/pipes/exg-decimal/exg-decimal.pipe';

import { ExgTranslateService } from '../../../../../burns-ui-framework/shared/services/common/exg-translate.service';
import { PlatformService } from '../../../../../burns-ui-framework/shared/services/common/platform.service';

import { GroupBy } from '../../../models/business/data-grouping.enum';
import { GraphType } from './graph-type.enum';

@Component({
    selector: 'bvc-graph',
    templateUrl: './bvc-graph.component.html',
    styleUrls: ['./bvc-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcGraphComponent implements OnChanges {
    @Input() data: any[];
    @Input() group: GroupBy;
    @Input() graphType: GraphType = GraphType.Areaspline;
    @Input() title: string;
    @Input() useAmountFormatter: boolean;

    public options: any;
    public chartOptions: any;

    public GraphType = GraphType;

    private exgDatePipe = new ExgDatePipe(this.translate);
    private exgDecimalPipe = new ExgDecimalPipe(this.translate);

    private mobileScreen = false;

    constructor(private translate: ExgTranslateService, private platformService: PlatformService, private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver.observe([
            '(max-width: 600px)'
        ]).subscribe((result) => {
            this.mobileScreen = result.matches;
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ((changes.data || changes.graphType) && this.data && this.graphType === GraphType.Areaspline) {
            this.initAreasplineChart();
        }

        if ((changes.data || changes.graphType) && this.data && this.graphType === GraphType.Pipe) {
            this.initPipeChart();
        }

        if ((changes.data || changes.graphType) && this.data && this.graphType === GraphType.SolidGauge) {
            this.initSolidGaugeChart();
        }

        if ((changes.data || changes.graphType) && this.data && this.graphType === GraphType.Variablepie) {
            this.initVariablepieChart();
        }
    }

    private initAreasplineChart() {
        const options = ChartsOptionsFactory.getAreasplineOptions(this.data);

        if (this.platformService.isMobilePlatform()) {
            this.chartOptions = options;
        } else {
            const xAxisOptions = {
                xAxis: {
                    labels: {
                        formatter: <any>((param) => {
                            switch (this.group) {
                                case GroupBy.Year:
                                    return this.exgDatePipe.transform(param.value, 'yyyy');
                                case GroupBy.Month:
                                    return this.exgDatePipe.transform(param.value, 'MM.yyyy');
                                case GroupBy.Week:
                                    return this.exgDatePipe.transform(param.value, 'dd.MM');
                                case GroupBy.Day:
                                    return this.exgDatePipe.transform(param.value, 'dd.MM');
                                default:
                                    break;
                            }
                        }),
                        style: { color: '#4c5862' }
                    }
                }
            };

            const self = this;
            const tooltipOptions = {
                tooltip: {
                    headerFormat: '',
                    pointFormatter: function() {
                        let date = null;
                        switch (self.group) {
                            case GroupBy.Year:
                                date = self.exgDatePipe.transform(this.options.x, 'yyyy');
                                break;
                            case GroupBy.Month:
                                date = self.exgDatePipe.transform(this.options.x, 'MM.yyyy');
                                break;
                            case GroupBy.Week:
                                date = self.exgDatePipe.transform(this.options.x, 'dd.MM');
                                break;
                            case GroupBy.Day:
                                date = self.exgDatePipe.transform(this.options.x, 'dd.MM.yyyy');
                                break;
                            default:
                                break;
                        }

                        return '<div style="text-align:center">' +
                        `<span style="font-size:13px">${self.exgDecimalPipe.transform(this.options.y, '0.0-0', false, '')}</span><br/>` +
                        `<span style="font-size:13px;opacity:0.6">${date}</span>` +
                        '</div>';
                    }
                }
            };

            const webOptions = {
                ...options,
                ...xAxisOptions,
                ...tooltipOptions
            };

            const stops = [
                'rgba(137, 53, 182, 0.24)',
                'rgba(45, 220, 161, 1)'
            ];

            webOptions.series = webOptions.series.map((x, index) => ({
                ...x,
                fillColor : {
                    linearGradient : {
                        x1: 1,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops : [
                        [0, stops[index]],
                        [0.8, 'rgba(190, 101, 220, 0)'],
                        [1, 'rgba(190, 101, 220, 0)']
                    ]
                }
            }));
            this.options = webOptions;
        }
    }

    private initPipeChart() {
        const options = ChartsOptionsFactory.getPipeChartOptions(this.title, this.mobileScreen, this.platformService.isMobilePlatform(), this.data);

        if (this.platformService.isMobilePlatform()) {
            this.chartOptions = options;
        } else {
            const self = this;
            const legendOptions = {
                ...options,
                legend: {
                    ...options.legend,
                    labelFormatter: <any>function() {
                        // tslint:disable-next-line:no-invalid-this
                        return self.useAmountFormatter ? `${self.exgDecimalPipe.transform(this.options.y, '0.0-0', false, '', true)} ${this.options.name}.` : `${this.options.name} - ${this.options.y}`;
                    }
                }
            };

            const webOptions = {
                ...options,
                ...legendOptions
            };

            this.options = webOptions;
        }
    }

    private initSolidGaugeChart() {

        const planAmount = this.data[0] ? Math.round(this.data[0].key > this.data[0].value ? this.data[0].key : this.data[0].value) : 0;
        const curAmount = this.data[0] ? Math.round(this.data[0].value) : 0;

        const options = ChartsOptionsFactory.getSolidGaugeOptions(this.title, planAmount, curAmount);

        if (this.platformService.isMobilePlatform()) {
            this.chartOptions = options;
        } else {
            const self = this;
            const yAxisOptions = {
                ...options.yAxis,
                stops: [
                    [0.1, '#DF5353'], // red
                    [0.5, '#DDDF0D'], // yellow
                    [0.8, '#55BF3B'] // green
                ]
            };

            const webOptions = {
                ...options,
                pane: {
                    center: ['50%', '85%'],
                    size: '120%',
                    startAngle: -90,
                    endAngle: 90,
                    background: [{
                        backgroundColor: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, '#e6e6e6'], // start
                                [1, '#e6e6e6'] // end
                            ]
                        },
                        borderColor: '#fff',
                        innerRadius: '75%',
                        outerRadius: '100%',
                        shape: 'arc'
                    }]
                },
                yAxis: yAxisOptions
            };

            webOptions.series = webOptions.series.map(x => ({
                ...x,
                dataLabels: {
                    formatter: <any>(param =>
                        '<div style="text-align:center">' +
                        `<span style="font-size:18px">${self.exgDecimalPipe.transform(curAmount, '0.0-0', false, '')}</span><br/>` +
                        `<span style="font-size:12px;opacity:0.4">${this.translate.instant('из плана')} ${self.exgDecimalPipe.transform(planAmount, '0.0-0', false, '')}</span>` +
                        '</div>')
                }
            }));

            this.options = webOptions;
        }
    }

    private initVariablepieChart() {
        const options = ChartsOptionsFactory.getVariablepieOptions(this.title, this.data, this.platformService.isMobilePlatform());

        if (this.platformService.isMobilePlatform()) {
            this.chartOptions = options;
        } else {
            this.options = options;
        }
    }
}
