import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AcceptancesService } from '../../services/business/acceptances.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { AcceptanceCreateOrUpdateRequest } from '../../models/business/acceptances/acceptance-create-or-update-request.model';

import { AcceptanceUpdateAction, AcceptanceUpdateFailAction, AcceptanceUpdateResetAction, AcceptanceUpdateSuccessAction } from './acceptance-update.actions';

export interface AcceptanceUpdateStateModel {
    pending: boolean;
    updated: AcceptanceCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<AcceptanceUpdateStateModel>({
    name: 'acceptanceUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class AcceptanceUpdateState {
    constructor(private acceptancesService: AcceptancesService) { }

    @Action(AcceptanceUpdateAction) async acceptanceUpdate(ctx: StateContext<AcceptanceUpdateStateModel>, action: AcceptanceUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.acceptancesService.updateAcceptance(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new AcceptanceUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AcceptanceUpdateFailAction(err)), 0));
    }

    @Action(AcceptanceUpdateSuccessAction) acceptanceUpdateSuccess(ctx: StateContext<AcceptanceUpdateStateModel>, action: AcceptanceUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(AcceptanceUpdateFailAction) acceptanceUpdateFail(ctx: StateContext<AcceptanceUpdateStateModel>, action: AcceptanceUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(AcceptanceUpdateResetAction) acceptanceUpdateReset(ctx: StateContext<AcceptanceUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
