import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { AcceptanceCreateOrUpdateRequest } from '../../models/business/acceptances/acceptance-create-or-update-request.model';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { AcceptanceCreateAction, AcceptanceCreateResetAction } from './acceptance-create.actions';

@Injectable({
    providedIn: 'root'
})
export class AcceptanceCreateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('AcceptanceCreateDispatchers');
    }

    public dispatchAcceptanceCreateAction(request: AcceptanceCreateOrUpdateRequest) {
        this.store.dispatch(new AcceptanceCreateAction(request));
    }

    public dispatchAcceptanceCreateResetAction() {
        this.store.dispatch(new AcceptanceCreateResetAction());
    }
}
