import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ExgFormComponent } from '../../../../../../burns-ui-framework/shared/components/abstract/exg-form.component';

import { AuthService } from '../../../../../../burns-ui-framework/shared/services/business/auth.service';
import { ProfileSelectors } from '../../../../../../burns-ui-framework/shared/store/profile/profile.selectors';

import { DocumentModel } from '../../../../models/business/feed/document.model';
import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Feed } from '../../../../models/business/feed/feed.model';
import { TaskCreateOrUpdateRequest } from '../../../../models/business/tasks/task-create-or-update.model';
import { TaskEmailDetails } from '../../../../models/business/tasks/task-email-details.model';
import { TaskStatus } from '../../../../models/business/tasks/task-status.enum';
import { TaskType } from '../../../../models/business/tasks/task-type.enum';
import { Task } from '../../../../models/business/tasks/task.model';

import { DateUtils } from '../../../../../../burns-ui-framework/shared/utils/date-utils';


@Component({
    selector: 'bvc-clients-activity-task-email-form',
    templateUrl: './clients-activity-task-email-form.component.html',
    styleUrls: ['./clients-activity-task-email-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityTaskEmailFormComponent extends ExgFormComponent implements OnChanges {
    @Input() task: Feed;
    @Input() error: ErrorObject;
    @Input() companyUid: string;
    @Input() created: Task;

    @Output() readonly taskCreate = new EventEmitter<{ request: TaskCreateOrUpdateRequest, documentsToAdd: File[] }>();
    @Output() readonly taskUpdate = new EventEmitter<{ uid: string, request: TaskCreateOrUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }>();
    @Output() readonly cancelClick = new EventEmitter<void>();

    public emails: FormControl;
    public name: FormControl;
    public description: FormControl;
    public dueDate: FormControl;
    public dueTime: FormControl;

    public noteFiles$ = new BehaviorSubject<DocumentModel[]>([]);
    public filesToAdd = [];
    public sendWithoutConfirmation = false;

    private timeZone = DateUtils.timeZone;

    constructor(private formBuilder: FormBuilder, private profileSelectors: ProfileSelectors, private authService: AuthService) {
        super();
        this.createForm();
        this.profileSelectors.profile$.pipe(take(1)).subscribe(profile => this.timeZone = profile?.user?.timeZoneId || DateUtils.timeZone);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.task && this.task) {
            this.setFormData();
        }

        if (changes.created && this.created) {
            this.onCancelClick();
        }
    }

    public onCancelClick() {
        if (this.task && this.task.uid) {
            this.setFormData();
        } else {
            this.description.patchValue(null);
            this.filesToAdd = [];
        }

        this.cancelClick.emit();
    }

    public onUploadedFiles($event) {
        this.filesToAdd.push(...$event);
    }

    public onDeleteDocument($event: string) {
        this.noteFiles$.next(this.noteFiles$.value.filter(x => x.uid !== $event));
    }

    public onDeleteDocumentDownloadable($event: number) {
        this.filesToAdd.splice($event, 1);
    }

    public trackByDocuments(_, item: DocumentModel) {
        return item.uid;
    }

    protected processSubmit() {
        const emails = this.emails.value.map(x => ({ email: x, name: x }));
        const date = this.dueDate.value + this.dueTime.value;
        const documentsToAdd = this.filesToAdd;
        const request = {
            type: TaskType.Email,
            name: this.name.value,
            description: this.description.value,
            status: this.task?.taskStatus || TaskStatus.ToDo,
            // tslint:disable-next-line:no-object-literal-type-assertion
            details: <TaskEmailDetails>{ emails, sendEmail: this.sendWithoutConfirmation },
            assigneeUserUid: this.authService.getUserId(),
            dueDate: DateUtils.getEpocWithTimeZoneOffset(date, this.timeZone, true),
            companyUid: this.companyUid
        };

        if (this.task && this.task.uid) {
            const newNoteDocuments = this.noteFiles$.value;
            const documentsToRemove = this.task.documents.filter(x => !newNoteDocuments.some(d => d.uid === x.uid)).map(x => x.uid);

            this.taskUpdate.emit({ uid: this.task.uid, request, documentsToAdd, documentsToRemove });
        } else {
            this.taskCreate.emit({ request, documentsToAdd });
        }
    }

    private createForm() {
        this.emails = this.formBuilder.control([], [Validators.required]);
        this.name = this.formBuilder.control(null, [Validators.required]);
        this.description = this.formBuilder.control(null, [Validators.required, Validators.maxLength(3000)]);
        this.dueDate = this.formBuilder.control(null, [Validators.required]);
        this.dueTime = this.formBuilder.control(null, [Validators.required]);

        this.mainForm = this.formBuilder.group({
            emails: this.emails,
            name: this.name,
            description: this.description,
            dueDate: this.dueDate,
            dueTime: this.dueTime
        });
    }

    private setFormData() {
        const emails = this.task.details?.emails || [];
        this.emails.patchValue(emails.map(x => x.name));
        this.name.patchValue(this.task.title);
        this.description.patchValue(this.task.text);
        this.noteFiles$.next(this.task.documents);

        const dueDate = DateUtils.getEpocWithTimeZoneOffset(this.task.dueDate, this.timeZone);
        const dueDateWithoutDate = DateUtils.convertStringToEpoc(DateUtils.startOf(DateUtils.convertEpocToString(this.task.dueDate), 'day'));
        const dueTime = dueDate - dueDateWithoutDate;
        this.dueDate.patchValue(dueDateWithoutDate);
        this.dueTime.patchValue(dueTime);
    }
}
