import { ContactListItem } from '../../models/business/contacts/contact-list-item.model';
import { ContactsListFilterData } from '../../models/filters/contacts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CompanyContactsAction {
    static readonly type = '[CompanyContacts Page] GetCompanyContacts';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyContactsSuccessAction {
    static readonly type = '[CompanyContacts API] GetCompanyContacts Success';

    constructor(public payload: { list: PaginationResult<ContactListItem>, pagination: PaginationRequest }) { }
}

export class CompanyContactsFailAction {
    static readonly type = '[CompanyContacts API] GetCompanyContacts Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyContactsResetAction {
    static readonly type = '[CompanyContacts Page] GetCompanyContacts Reset';
}

export class CompanyContactsSetFilterAction {
    static readonly type = '[CompanyContacts API] SetFilter';

    constructor(public payload: ContactsListFilterData) { }
}
