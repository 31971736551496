export class RoutingConfig {
    public static readonly routes = {
        home: {
            route: 'home',
            fullUrl: '/home'
        },
        auth: {
            route: 'auth',
            fullUrl: '/auth',
            registration: {
                route: 'registration',
                fullUrl: '/auth/registration'
            },
            resetPassword: {
                route: 'reset-password',
                fullUrl: '/auth/reset-password'
            }
        },
        callCenter: {
            route: 'call-center',
            fullUrl: '/call-center',
            orders: {
                route: 'orders',
                fullUrl: '/call-center/orders',
                view: {
                    route: 'view',
                    fullUrl: '/call-center/orders/view'
                },
                edit: {
                    route: 'edit',
                    fullUrl: '/call-center/orders/edit'
                }
            },
            intervals: {
                route: 'intervals',
                fullUrl: '/call-center/intervals',
            },
            notifications: {
                route: 'notifications',
                fullUrl: '/call-center/notifications',
            }
        },
        corporation: {
            route: 'corporation',
            fullUrl: '/corporation',
            franchisees: {
                route: 'franchisees',
                fullUrl: '/corporation/franchisees',
                edit: {
                    route: 'edit',
                    fullUrl: '/corporation/franchisees/edit'
                }
            },
            organizations: {
                route: 'organizations',
                fullUrl: '/corporation/organizations',
                edit: {
                    route: 'edit',
                    fullUrl: '/corporation/organizations/edit'
                }
            },
            restaurants: {
                route: 'restaurants',
                fullUrl: '/corporation/restaurants',
                edit: {
                    route: 'edit',
                    fullUrl: '/corporation/restaurants/edit'
                }
            },
        },
        clients: {
            route: 'clients',
            fullUrl: '/clients',
            pos: {
                route: 'pos',
                fullUrl: '/clients/pos',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/pos/edit'
                }
            },
            companies: {
                route: 'companies',
                fullUrl: '/clients/companies',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/companies/edit'
                }
            },
            contacts: {
                route: 'contacts',
                fullUrl: '/clients/contacts',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/contacts/edit'
                },
                motivationBonus: {
                    route: 'motivation-bonus'
                }
            },
            customers: {
                route: 'customers',
                fullUrl: '/clients/customers',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/customers/edit'
                },
                motivationBonus: {
                    route: 'motivation-bonus'
                }
            },
            contragents: {
                route: 'contragents',
                fullUrl: '/clients/contragents',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/contragents/edit'
                },
                contracts: {
                    route: 'contracts'
                },
                requisites: {
                    route: 'requisites'
                }
            },
            motivation: {
                route: 'motivation',
                fullUrl: '/clients/motivation',
                companies: {
                    route: 'company',
                    fullUrl: '/clients/motivation/company',
                    contact: {
                        route: 'contact'
                    }
                }
            },
            organizations: {
                route: 'organizations',
                fullUrl: '/clients/organizations',
                edit: {
                    route: 'edit',
                    fullUrl: '/clients/organizations/edit'
                }
            }
        },
        finance: {
            route: 'finance',
            fullUrl: '/finance',
            transactions: {
                route: 'transactions',
                fullUrl: '/finance/transactions',
            },
        },
        orders: {
            route: 'orders',
            fullUrl: '/orders',
            orders: {
                route: 'orders',
                fullUrl: '/orders/orders'
            },
            invoices: {
                route: 'invoices',
                fullUrl: '/orders/invoices'
            },
            loads: {
                route: 'loads',
                fullUrl: '/orders/loads'
            },
            returns: {
                route: 'returns',
                fullUrl: '/orders/returns'
            },
            salesFunnel: {
                route: 'sales',
                fullUrl: '/orders/sales'
            }
        },
        purchases: {
            route: 'purchases',
            fullUrl: '/purchases',
            orders: {
                route: 'orders',
                fullUrl: '/purchases/orders'
            },
            supplierInvoices: {
                route: 'supplier-invoices',
                fullUrl: '/purchases/supplier-invoices'
            },
            acceptance: {
                route: 'acceptance',
                fullUrl: '/purchases/acceptance'
            },
            returns: {
                route: 'returns',
                fullUrl: '/purchases/returns'
            },
            invoices: {
                route: 'invoices',
                fullUrl: '/purchases/invoices'
            },
            management: {
                route: 'management',
                fullUrl: '/purchases/management'
            }
        },
        settings: {
            route: 'settings',
            fullUrl: '/settings',
            product: {
                route: 'product',
                fullUrl: '/settings/product'
            },
            warehouse: {
                route: 'warehouse',
                fullUrl: '/settings/warehouse'
            },
            priceType: {
                route: 'price-type',
                fullUrl: '/settings/price-type'
            },
            taxes: {
                route: 'taxes',
                fullUrl: '/settings/taxes'
            },
        },
        users: {
            route: 'users',
            fullUrl: '/users',
            edit: {
                route: 'edit',
                fullUrl: '/users/edit'
            }
        },
        version: {
            route: 'version',
            fullUrl: '/version',
            icons: {
                route: 'icons',
                fullUrl: '/version/icons'
            }
        },
        warehouse: {
            route: 'warehouse',
            fullUrl: '/warehouse',
            products: {
                route: 'products',
                fullUrl: '/warehouse/products',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/products/edit'
                }
            },
            postings: {
                route: 'postings',
                fullUrl: '/warehouse/postings',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/postings/edit'
                }
            },
            dcr: {
                route: 'dcr',
                fullUrl: '/warehouse/dcr',
                decompositions: {
                    route: 'decompositions',
                    fullUrl: '/warehouse/dcr/decompositions',
                    edit: {
                        route: 'edit',
                        fullUrl: '/warehouse/dcr/decompositions/edit'
                    }
                },
                cookings: {
                    route: 'cookings',
                    fullUrl: '/warehouse/dcr/cookings',
                    edit: {
                        route: 'edit',
                        fullUrl: '/warehouse/dcr/cookings/edit'
                    }
                },
                recyclings: {
                    route: 'recyclings',
                    fullUrl: '/warehouse/dcr/recyclings',
                    edit: {
                        route: 'edit',
                        fullUrl: '/warehouse/dcr/recyclings/edit'
                    }
                }
            },
            writeOffs: {
                route: 'write-offs',
                fullUrl: '/warehouse/write-offs',
            },
            inventories: {
                route: 'inventories',
                fullUrl: '/warehouse/inventories',
            },
            transfers: {
                route: 'transfers',
                fullUrl: '/warehouse/transfers'
            },
            priceList: {
                route: 'price-list',
                fullUrl: '/warehouse/price-list',
                edit: {
                    route: 'edit',
                    fullUrl: '/warehouse/price-list/edit'
                }
            }
        },
        errors: {
            route: 'errors',
            fullUrl: '/errors'
        }
    };

    public static readonly dynamicRoutes = {
    };
}
