import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonus } from '../../models/business/contacts/motivation-bonus.model';

export class MotivationBonusGetAction {
    static readonly type = '[MotivationBonus Page] GetMotivationBonus Get';

    constructor(public payload: { contactUid: string, motivationUid: string }) { }
}

export class MotivationBonusGetSuccessAction {
    static readonly type = '[MotivationBonus API] GetMotivationBonus Success';

    constructor(public payload: MotivationBonus) { }
}

export class MotivationBonusFailAction {
    static readonly type = '[MotivationBonus API] GetMotivationBonus Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusResetAction {
    static readonly type = '[MotivationBonus Page] GetMotivationBonus Reset';
}
