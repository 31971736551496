import { Brand } from '../../models/business/brands/brand.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class BrandAction {
    static readonly type = '[Brand Page] Brand';

    constructor(public payload: { uid: string }) { }
}

export class BrandSuccessAction {
    static readonly type = '[Brand API] Brand Success';

    constructor(public payload: Brand) { }
}

export class BrandFailAction {
    static readonly type = '[Brand API] Brand Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandResetAction {
    static readonly type = '[Brand Page] Brand Reset';
}
