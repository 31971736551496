import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { BrandCreateOrUpdateRequest } from '../../models/business/brands/brand-create-or-update.model';
import { Brand } from '../../models/business/brands/brand.model';
import { BrandsListFilter } from '../../models/filters/brands-list-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class BrandsService extends BaseSingletonService {

    private settings: {
        service: {
            getBrand: string;
            getBrandList: string;
            createBrand: string;
            updateBrand: string;
            deleteBrand: string;
            deleteBrandPreview: string;
        }
    };

    constructor(private http: HttpService) {
        super('BrandsService');
        this.settings = {
            service: {
                getBrand: '/nomenclature/v1/brands/{0}',
                getBrandList: '/nomenclature/v1/brands?term={0}&isCompanyBrand={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createBrand: '/nomenclature/v1/brands',
                updateBrand: '/nomenclature/v1/brands/{0}',
                deleteBrand: '/nomenclature/v1/brands/{0}',
                deleteBrandPreview: '/nomenclature/v1/brands/{0}/preview'
            }
        };
    }

    public async getBrand(uid: string): Promise<Brand> {
        return this.http.get<{ data: Brand }>(String.format(this.settings.service.getBrand, uid))
            .then(res => res.data);
    }

    public async getBrandList(filter: BrandsListFilter): Promise<PaginationResult<Brand>> {
        return this.http.get<PaginationResult<Brand>>(String.format(this.settings.service.getBrandList, filter.term, filter.isCompanyBrand, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createBrand(request: BrandCreateOrUpdateRequest): Promise<Brand> {
        return this.http.post<{ data: Brand }>(String.format(this.settings.service.createBrand), request)
            .then(res => res.data);
    }

    public async updateBrand(uid: string, request: BrandCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateBrand, uid), request);
    }

    public async deleteBrand(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteBrand, uid));
    }

    public async deleteBrandPreview(uid: string) {
        return this.http.delete(String.format(this.settings.service.deleteBrandPreview, uid));
    }
}
