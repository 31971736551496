import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosCreateOrUpdateRequest } from '../../models/business/pos/pos-create-or-update.model';
import { Pos } from '../../models/business/pos/pos.model';

export class PosCreateAction {
    static readonly type = '[Poss Page] CreatePos';

    constructor(public payload: PosCreateOrUpdateRequest) { }
}

export class PosCreateSuccessAction {
    static readonly type = '[Pos API] CreatePos Success';

    constructor(public payload: Pos) { }
}

export class PosCreateFailAction {
    static readonly type = '[Pos API] CreatePos Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosCreateResetAction {
    static readonly type = '[Poss Page] CreatePos Reset';
}
