import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WriteOffListGetFilterData } from '../../models/filters/write-off-list-get-filter.model';
import { WriteOffListItem } from '../../models/business/write-offs/write-off-list-item.model';

export class WriteOffsAction {
    static readonly type = '[WriteOffss Page] GetWriteOffs';

    constructor(public payload: PaginationRequest) { }
}

export class WriteOffsSuccessAction {
    static readonly type = '[WriteOffs API] GetWriteOffs Success';

    constructor(public payload: { list: PaginationResult<WriteOffListItem>, pagination: PaginationRequest }) { }
}

export class WriteOffsFailAction {
    static readonly type = '[WriteOffs API] GetWriteOffs Fail';

    constructor(public payload: ErrorObject) { }
}

export class WriteOffsResetAction {
    static readonly type = '[WriteOffs Page] GetWriteOffs Reset';
}

export class WriteOffsSetFilterAction {
    static readonly type = '[WriteOffs API] SetFilter';

    constructor(public payload: WriteOffListGetFilterData) { }
}
