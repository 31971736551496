import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosListFilterData } from '../../models/filters/pos-list-filter.model';
import { PosListItem } from '../../models/business/pos/pos-list-item.model';

export class PosListAction {
    static readonly type = '[PosList Page] GetPosList';

    constructor(public payload: PaginationRequest) { }
}

export class PosListSuccessAction {
    static readonly type = '[PosList API] GetPosList Success';

    constructor(public payload: { list: PaginationResult<PosListItem>, pagination: PaginationRequest }) { }
}

export class PosListFailAction {
    static readonly type = '[PosList API] GetPosList Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosListResetAction {
    static readonly type = '[PosList Page] GetPosList Reset';
}

export class PosListSetFilterAction {
    static readonly type = '[PosList API] SetFilter';

    constructor(public payload: PosListFilterData) { }
}
