<section class="row-header">
    <div class="left">
        <exg-button class="nav-btn" styleType="link" [routerLink]="[routes.warehouse.dcr.decompositions.fullUrl]" [color]="getColor(routes.warehouse.dcr.decompositions.fullUrl)" routerLinkActive="active">{{'Decompositions' | translate}}</exg-button>
        <exg-button class="nav-btn" styleType="link" [routerLink]="[routes.warehouse.dcr.cookings.fullUrl]" [color]="getColor(routes.warehouse.dcr.cookings.fullUrl)" routerLinkActive="active">{{'Cooking' | translate}}</exg-button>
        <exg-button class="nav-btn" styleType="link" [routerLink]="[routes.warehouse.dcr.recyclings.fullUrl]" [color]="getColor(routes.warehouse.dcr.recyclings.fullUrl)" routerLinkActive="active">{{'Recycling' | translate}}</exg-button>
    </div>
    <exg-menu class="menu_el" [items]="createMenuItems" (menuItemClick)="onCreateMenuItemClick($event)">
        <exg-button [excludePadding]="true" class="add-route" color="primary" styleType="texted">
            <exg-icon class="add-icon" color="primary" iconName="add"></exg-icon>
            <div class="btn-title">{{ addBtnTitle | translate }}</div>
        </exg-button>
    </exg-menu>
    <div class="menu">
        <exg-menu class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event)">
            <exg-button class="menu_btn" styleType="icon"><exg-icon class="menu_icon" color="primary" iconName="menudotted_vertical"></exg-icon></exg-button>
        </exg-menu>
    </div>
</section>
