import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PlanCreateOrUpdateListRequest } from '../../models/business/sales-plan/plan-create-or-update-list-request.model';

export class SalesPlanUpdateListAction {
    static readonly type = '[SalesPlan Page] UpdateListSalesPlan';

    constructor(public payload: { year: number, request: PlanCreateOrUpdateListRequest[] }) { }
}

export class SalesPlanUpdateListSuccessAction {
    static readonly type = '[SalesPlan API] UpdateListSalesPlan Success';

    constructor(public payload: { year: number, request: PlanCreateOrUpdateListRequest[] }) { }
}

export class SalesPlanUpdateListFailAction {
    static readonly type = '[SalesPlan API] UpdateListSalesPlan Fail';

    constructor(public payload: ErrorObject) { }
}

export class SalesPlanUpdateListResetAction {
    static readonly type = '[SalesPlan Page] UpdateListSalesPlan Reset';
}
