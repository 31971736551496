import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Note } from '../../models/business/notes/note.model';

export class NoteUpdateDocumentsAction {
    static readonly type = '[Booking Page] NoteUpdateDocuments';

    constructor(public payload: { uid: string, documentsToAdd: File[], documentsToRemove: string[] }) { }
}

export class NoteUpdateDocumentsSuccessAction {
    static readonly type = '[Note API] NoteUpdateDocuments Success';

    constructor(public payload: { uid: string, entity: Note, documentsToRemove: string[] }) { }
}

export class NoteUpdateDocumentsFailAction {
    static readonly type = '[Note API] NoteUpdateDocuments Fail';

    constructor(public payload: ErrorObject) { }
}

export class NoteUpdateDocumentsResetAction {
    static readonly type = '[Booking Page] NoteUpdateDocuments Reset';
}
