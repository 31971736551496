import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class AcceptanceDeleteAction {
    static readonly type = '[My Acceptance Page] DeleteAcceptance';

    constructor(public payload: { uid: string }) { }
}

export class AcceptanceDeleteSuccessAction {
    static readonly type = '[My Acceptances API] DeleteAcceptance Success';

    constructor(public payload: { uid: string }) { }
}

export class AcceptanceDeleteFailAction {
    static readonly type = '[My Acceptances API] DeleteAcceptance Fail';

    constructor(public payload: ErrorObject) { }
}

export class AcceptanceDeleteResetAction {
    static readonly type = '[My Acceptance Page] DeleteAcceptance Reset';
}
