import { DocumentModel } from '../feed/document.model';

export class Note {
    public uid: string;

    public companyUid: string;

    public text: string;

    public documents: DocumentModel[] = [];

    public createDate: number;
}
