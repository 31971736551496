import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PosMediaEditFailAction, PosMediaEditResetAction, PosMediaEditSuccessAction, PosMediaEditАction } from './pos-media-edit.actions';

export interface PosMediaEditStateModel {
    pending: boolean ;
    error: ErrorObject;
    mediaUpdated: boolean;
}

@State<PosMediaEditStateModel>({
    name: 'posMediaEdit',
    defaults: { pending: false, error: null, mediaUpdated: false }
})
@Injectable()
export class PosMediaEditState {
    constructor(private posService: PosService) { }

    @Action(PosMediaEditАction) async posMediaEdit(ctx: StateContext<PosMediaEditStateModel>, action: PosMediaEditАction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, mediaUpdated: false });

        return this.posService.editPosMedia(action.payload.posUid, action.payload.addedPhotos, action.payload.deletedPhotos)
            .then(res => setTimeout(() => ctx.dispatch(new PosMediaEditSuccessAction(res)), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PosMediaEditFailAction(error)), 0));
    }

    @Action(PosMediaEditSuccessAction) posMediaEditSuccess(ctx: StateContext<PosMediaEditStateModel>, _: PosMediaEditSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, mediaUpdated: true });
    }

    @Action(PosMediaEditFailAction) posMediaEditFail(ctx: StateContext<PosMediaEditStateModel>, action: PosMediaEditFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload, mediaUpdated: false });
    }

    @Action(PosMediaEditResetAction) posMediaEditReset(ctx: StateContext<PosMediaEditStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, mediaUpdated: false });
    }
}
