import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosAreasService } from '../../services/business/organization-pos-areas.service';

import { OrganizationPosAreaCreateAction, OrganizationPosAreaCreateFailAction, OrganizationPosAreaCreateResetAction, OrganizationPosAreaCreateSuccessAction } from './organization-pos-area-create.actions';

import { Area } from '../../models/business/organization-pos/area.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface OrganizationPosAreaCreateStateModel {
    created: Area;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosAreaCreateStateModel>({
    name: 'organizationPosAreaCreate',
    defaults: {
        pending: false, created: null, error: null
    }
})
@Injectable()
export class OrganizationPosAreaCreateState {
    constructor(private orgPosAreasService: OrganizationPosAreasService) { }

    @Action([OrganizationPosAreaCreateAction]) organizationPosesGet(ctx: StateContext<OrganizationPosAreaCreateStateModel>, action: OrganizationPosAreaCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        this.orgPosAreasService.createPosArea(action.payload.request)
            .then((resp) =>
                this.orgPosAreasService.updatePosAreaRestrictions(action.payload.restrictions.map(r => ({ ...r, areaUid: resp.uid })))
                    .then(() => setTimeout(() => ctx.dispatch(new OrganizationPosAreaCreateSuccessAction(resp)), 0))
                    .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaCreateFailAction(err)), 0))
            ).catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosAreaCreateFailAction(err)), 0));
    }

    @Action(OrganizationPosAreaCreateSuccessAction) organizationPosesGetSuccess(ctx: StateContext<OrganizationPosAreaCreateStateModel>, action: OrganizationPosAreaCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(OrganizationPosAreaCreateFailAction) organizationPosesGetFail(ctx: StateContext<OrganizationPosAreaCreateStateModel>, action: OrganizationPosAreaCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosAreaCreateResetAction) organizationPosesGetReset(ctx: StateContext<OrganizationPosAreaCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
