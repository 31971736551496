import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { IntervalGetFilterData } from '../../models/filters/interval-get-filter.model';
import { IntervalListItem } from '../../models/business/intervals/interval-list-item.model';

import { IntervalsState, IntervalsStateModel } from './intervals.reducer';

@Injectable({
    providedIn: 'root'
})
export class IntervalsSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([IntervalsState], (state: IntervalsStateModel) => state.pending);

    static readonly getIntervals = createSelector([IntervalsState], (state: IntervalsStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getFilter = createSelector([IntervalsState], (state: IntervalsStateModel) => state.filter);

    static readonly getError = createSelector([IntervalsState], (state: IntervalsStateModel) => state.error);

    @Select(IntervalsSelectors.getPending) pending$: Observable<boolean>;

    @Select(IntervalsSelectors.getIntervals) intervals$: Observable<IntervalListItem[]>;

    @Select(IntervalsSelectors.getError) error$: Observable<ErrorObject>;

    @Select(IntervalsSelectors.getFilter) filter$: Observable<IntervalGetFilterData>;

    constructor() {
        super('IntervalsSelectors');
    }
}
