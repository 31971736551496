<div class="note note-view">
    <div class="type-icon">
        <div class="icon-area">
            <exg-icon class="note-icon" iconName="task"></exg-icon>
        </div>
    </div>
    <div class="title_line">
        <div class="title">{{'Meeting' | translate}}</div>
        <div class="create_time">{{feedItem?.createDate | exgDate:'h:mm'}}</div>
        <div class="task_status">
            <div class="status" [class]="taskStatusEnum[feedItem.taskStatus]">{{ taskStatusEnum[feedItem.taskStatus] | translate }}</div>
        </div>
    </div>
    <div class="responsible">
        <exg-image class="profile-avatar" [image]="emptyAvatar" [isRound]="true"></exg-image>
        <div class="profile-name">
            <div class="info_title">{{ 'Responsible user' | translate }}</div>
            <div class="info_full_name">{{feedItem?.responsibleUser?.fullName}}</div>
        </div>
    </div>
    <div class="split_line"></div>
    <div class="meeting">
        <div class="profile-name">
            <div class="info_title">{{ 'Address' | translate }}</div>
            <div class="info_full_name">{{feedItem?.details?.place?.fullName}} {{feedItem?.details?.place?.text}}</div>
        </div>
    </div>
</div>

