import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrganizationPosUpdateSuccessAction } from './organization-pos-update.actions';
import { OrganizationPosFailAction, OrganizationPosGetAction, OrganizationPosGetSuccessAction, OrganizationPosResetAction } from './organization-pos.actions';
import { OrganizationPos } from '../../models/business/organization-pos/pos.model';

export interface OrganizationPosStateModel {
    entity: OrganizationPos;
    pending: boolean;
    error: ErrorObject;
}

@State<OrganizationPosStateModel>({
    name: 'pose',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class OrganizationPosState {
    constructor(private orgPosService: OrganizationPosService) { }

    @Action(OrganizationPosGetAction) organizationPoseGet(ctx: StateContext<OrganizationPosStateModel>, action: OrganizationPosGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.orgPosService.getPos(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new OrganizationPosGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosFailAction(err)), 0));
    }

    @Action(OrganizationPosGetSuccessAction) organizationPoseGetSuccess(ctx: StateContext<OrganizationPosStateModel>, action: OrganizationPosGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(OrganizationPosFailAction) organizationPoseGetFail(ctx: StateContext<OrganizationPosStateModel>, action: OrganizationPosFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationPosResetAction) organizationPoseGetReset(ctx: StateContext<OrganizationPosStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(OrganizationPosUpdateSuccessAction) organizationPoseUpdateSuccess(ctx: StateContext<OrganizationPosStateModel>, action: OrganizationPosUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
