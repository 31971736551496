import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'orders-add',
    templateUrl: './orders-add.component.html',
    styleUrls: ['./orders-add.component.scss', '../orders-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersAddComponent {

    @Output() readonly addBtnClick = new EventEmitter<void>();

    public onAddBtnClick() {
        this.addBtnClick.emit();
    }
}
