
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosPaymentType } from '../../models/business/organization-pos/pos-payment-type.model';

export class OrganizationPosPaymentTypesAction {
    static readonly type = '[CallCenter Page] GetOrganizationPosPaymentTypes';

    constructor(public payload: { organizationPosUid: string }) { }
}

export class OrganizationPosPaymentTypesSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosPaymentTypes Success';

    constructor(public payload: { list: PosPaymentType[] }) { }
}

export class OrganizationPosPaymentTypesFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosPaymentTypes Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosPaymentTypesResetAction {
    static readonly type = '[CallCenter Page] GetOrganizationPosPaymentTypes Reset';
}

