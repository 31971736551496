import { CompanyStatus } from './company-status.enum';
import { CompanyType } from './company-type.enum';
import { LinkedContact } from '../contacts/linked-contact.model';
import { LinkedPlace } from '../linked-place.model';
import { LinkedUser } from '../user/linked-user.model';

export class CompanyListItem {
    public uid: string;
    public name: string;
    public status: CompanyStatus;
    public type: CompanyType;
    public phone: string;
    public city: LinkedPlace;
    public mainContact: LinkedContact;
    public responsibleUser: LinkedUser;
}
