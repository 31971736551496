import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PosService } from '../../services/business/pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PosCreateAction, PosCreateFailAction, PosCreateResetAction, PosCreateSuccessAction } from './pos-create.actions';
import { Pos } from '../../models/business/pos/pos.model';

export interface PosCreateStateModel {
    pending: boolean;
    created: Pos;
    error: ErrorObject;
}

@State<PosCreateStateModel>({
    name: 'posCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PosCreateState {
    constructor(private posService: PosService) { }

    @Action(PosCreateAction) async posCreate(ctx: StateContext<PosCreateStateModel>, action: PosCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.posService.createPos({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PosCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PosCreateFailAction(err)), 0));
    }

    @Action(PosCreateSuccessAction) posCreateSuccess(ctx: StateContext<PosCreateStateModel>, action: PosCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PosCreateFailAction) posCreateFail(ctx: StateContext<PosCreateStateModel>, action: PosCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PosCreateResetAction) posCreateReset(ctx: StateContext<PosCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
