import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class WarehousesListFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public organizationUid?: string;
    public organizationPosUid?: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: WarehousesListFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.organizationUid = (args && args.filterData && args.filterData.organizationUid != null) ? args.filterData.organizationUid : null;
        this.organizationPosUid = (args && args.filterData && args.filterData.organizationPosUid != null) ? args.filterData.organizationPosUid : null;
    }
}

export class WarehousesListFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public organizationUid?: string;
    public organizationPosUid?: string;
}
