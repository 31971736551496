import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TechnologicalMapRestoreAction, TechnologicalMapRestoreFailAction, TechnologicalMapRestoreResetAction, TechnologicalMapRestoreSuccessAction } from './technological-map-restore.actions';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

export interface TechnologicalMapRestoreStateModel {
    pending: boolean;
    created: TechnologicalMapListItemAggregation;
    error: ErrorObject;
}

@State<TechnologicalMapRestoreStateModel>({
    name: 'technologicalMapRestore',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TechnologicalMapRestoreState {
    constructor(private technologicalMapsService: TechnologicalMapsService) { }

    @Action(TechnologicalMapRestoreAction) async technologicalMapRestore(ctx: StateContext<TechnologicalMapRestoreStateModel>, action: TechnologicalMapRestoreAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.technologicalMapsService.restoreTechnologicalMap(action.payload.uid, action.payload)
            .then(res => setTimeout(() => ctx.dispatch(new TechnologicalMapRestoreSuccessAction(res[0])), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapRestoreFailAction(err)), 0));
    }

    @Action(TechnologicalMapRestoreSuccessAction) technologicalMapRestoreSuccess(ctx: StateContext<TechnologicalMapRestoreStateModel>, action: TechnologicalMapRestoreSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TechnologicalMapRestoreFailAction) technologicalMapRestoreFail(ctx: StateContext<TechnologicalMapRestoreStateModel>, action: TechnologicalMapRestoreFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(TechnologicalMapRestoreResetAction) technologicalMapRestoreReset(ctx: StateContext<TechnologicalMapRestoreStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
