import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { PlacesForwardAction, PlacesForwardResetAction } from './places-forward.actions';

@Injectable({
    providedIn: 'root'
})
export class PlacesForwardDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('PlacesForwardDispatchers');
    }

    public dispatchPlacesForwardAction(uri: string) {
        this.store.dispatch(new PlacesForwardAction({ uri }));
    }

    public dispatchPlacesForwardResetAction() {
        this.store.dispatch(new PlacesForwardResetAction());
    }
}
