import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ContragentsListFilterData } from '../../models/filters/contragents-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class ContragentsSearchAction {
    static readonly type = '[ContragentsSearch Page] GetContragentsSearch';

    constructor(public payload: ContragentsListFilterData) { }
}

export class ContragentsSearchSuccessAction {
    static readonly type = '[ContragentsSearch API] GetContragentsSearch Success';

    constructor(public payload: PaginationResult<ContragentListItem>) { }
}

export class ContragentsSearchFailAction {
    static readonly type = '[ContragentsSearch API] GetContragentsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContragentsSearchResetAction {
    static readonly type = '[ContragentsSearch Page] GetContragentsSearch Reset';
}
