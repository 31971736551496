import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { PurchaseOrdersService } from '../../services/business/purchase-orders.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { PurchaseOrderCreateSuccessAction } from './purchase-order-create.actions';
import { PurchaseOrderDeleteSuccessAction } from './purchase-order-delete.actions';
import { PurchaseOrderUpdateSuccessAction } from './purchase-order-update.actions';
import { PurchaseOrdersAction, PurchaseOrdersFailAction, PurchaseOrdersResetAction, PurchaseOrdersSetFilterAction, PurchaseOrdersSuccessAction } from './purchase-orders.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PurchaseOrderListGetFilter, PurchaseOrderListGetFilterData } from '../../models/filters/purchase-order-list-get-filter.model';
import { PurchaseOrderListItem } from '../../models/business/purchase-orders/purchase-order-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface PurchaseOrdersStateModel extends PaginationFilteredStateModel<PurchaseOrderListItem, PurchaseOrderListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: PurchaseOrderListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PurchaseOrderListGetFilterData;
    total: number;
    totalAmount: number;
}

@State<PurchaseOrdersStateModel>({
    name: 'purchaseOrders',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, totalAmount: 0, filter: {} }
})
@Injectable()
export class PurchaseOrdersState extends PaginationFilteredStateBase {
    constructor(private purchaseOrdersService: PurchaseOrdersService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([PurchaseOrdersAction]) productsGet(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrdersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'purchaseOrder');
        ctx.setState({ ...state, filter: filterData });

        const filter = new PurchaseOrderListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.purchaseOrdersService.getPurchaseOrderList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PurchaseOrdersSuccessAction({ list: resp, pagination: action.payload, totalAmount: resp.totalAmount })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseOrdersFailAction(err)), 0));
    }

    @Action(PurchaseOrdersSuccessAction) purchaseOrderGetSuccess(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrdersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, totalAmount: action.payload.totalAmount, allItemsLoaded, index });
    }

    @Action(PurchaseOrdersFailAction) purchaseOrderGetFail(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrdersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseOrdersResetAction) purchaseOrderGetReset(ctx: StateContext<PurchaseOrdersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(PurchaseOrdersSetFilterAction) usersSetFilter(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrdersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'purchaseOrder');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PurchaseOrdersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PurchaseOrderCreateSuccessAction) purchaseOrderCreateSuccess(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrderCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(PurchaseOrderUpdateSuccessAction) purchaseOrderUpdateSuccess(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrderUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PurchaseOrderDeleteSuccessAction) purchaseOrderDeleteSuccess(ctx: StateContext<PurchaseOrdersStateModel>, action: PurchaseOrderDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
