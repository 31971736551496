import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ManagerAssistant } from '../../models/business/manager-assistants/manager-assistant.model';
import { ManagerAssistantCreateOrUpdateRequest } from '../../models/business/manager-assistants/manager-assistants-create-or-update-request.model';

export class ManagerAssistantCreateAction {
    static readonly type = '[ManagerAssistantss Page] CreateManagerAssistants';

    constructor(public payload: ManagerAssistantCreateOrUpdateRequest) { }
}

export class ManagerAssistantCreateSuccessAction {
    static readonly type = '[ManagerAssistants API] CreateManagerAssistants Success';

    constructor(public payload: ManagerAssistant) { }
}

export class ManagerAssistantCreateFailAction {
    static readonly type = '[ManagerAssistants API] CreateManagerAssistants Fail';

    constructor(public payload: ErrorObject) { }
}

export class ManagerAssistantCreateResetAction {
    static readonly type = '[ManagerAssistantss Page] CreateManagerAssistants Reset';
}
