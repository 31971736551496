import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrdersErrorsService } from '../../services/business/orders-errors.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrdersErrorsUnreadCountAction } from './orders-errors-unread-count.actions';
import { OrdersErrorsUpdateAction, OrdersErrorsUpdateFailAction, OrdersErrorsUpdateResetAction, OrdersErrorsUpdateSuccessAction } from './orders-errors-update.actions';

export interface OrdersErrorsUpdateStateModel {
    pending: boolean;
    error: ErrorObject;
}

@State<OrdersErrorsUpdateStateModel>({
    name: 'ordersErrorsUpdate',
    defaults: { pending: false, error: null }
})
@Injectable()
export class OrdersErrorsUpdateState {
    constructor(private notificationsService: OrdersErrorsService) { }

    @Action([OrdersErrorsUpdateAction.FromHeaderComponent, OrdersErrorsUpdateAction.FromOrdersErrorsPage]) async notificationsUpdate(ctx: StateContext<OrdersErrorsUpdateStateModel>, action: OrdersErrorsUpdateAction.OrdersErrorsUnreadCountActionsUnion) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.notificationsService.updateErrors(action.payload)
            .then(() => setTimeout(() => {
                ctx.dispatch(new OrdersErrorsUpdateSuccessAction(action.payload));
                ctx.dispatch(new OrdersErrorsUnreadCountAction.OnNotificationSuccessUpdate());
            }, 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrdersErrorsUpdateFailAction(err)), 0));
    }

    @Action(OrdersErrorsUpdateSuccessAction) notificationsUpdateSuccess(ctx: StateContext<OrdersErrorsUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null });
    }

    @Action(OrdersErrorsUpdateFailAction) notificationsUpdateFail(ctx: StateContext<OrdersErrorsUpdateStateModel>, action: OrdersErrorsUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action([OrdersErrorsUpdateResetAction.FromHeaderComponent, OrdersErrorsUpdateResetAction.FromOrdersErrorsPage]) notificationsUpdateReset(ctx: StateContext<OrdersErrorsUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null });
    }
}
