
<div class="work-schedule">
    <div class="days-box">
        <div class="day_line">
            <exg-button styleType="icon" class="day"
                        *ngFor="let day of days; let i = index"
                        [class.active]="isWeekActive(day.wkDay)"
                        (btnClick)="pickOneDay(i)">
                {{day.name | translate}}
            </exg-button>
        </div>
        <div class="filter_line">
            <exg-button class="filter_btn daily" styleType="custom" [class.active]="daily" (btnClick)="pickDays('Daily')">{{'Full week' | translate}}</exg-button>

            <exg-button class="filter_btn weekends" styleType="custom" [class.active]="weekends" (btnClick)="pickDays('Weekends')">{{'Weekends' | translate}}</exg-button>

            <exg-button class="filter_btn weekdays" styleType="custom" [class.active]="weekdays" (btnClick)="pickDays('Weekdays')">{{'Weekdays' | translate}}</exg-button>
        </div>
    </div>
    <div class="times-box">
        <div class="working-time">
            <div class="title">{{'Working time' | translate}}</div>
            <div class="time">
                <exg-timepicker class="time-start"
                                [value]="work.begin"
                                placeholder="{{'Starts at' | translate}}"
                                (valueChange)="onTimeChanged('work', 'begin', $event)"></exg-timepicker>

                <exg-timepicker class="time-end"
                                [value]="work.end"
                                placeholder="{{'Ends at' | translate}}"
                                (valueChange)="onTimeChanged('work', 'end', $event)"></exg-timepicker>
            </div>
        </div>
        <div class="break-time">
            <div class="title">{{'Break' | translate}}</div>
            <div class="time">
                <exg-timepicker class="time-start"
                                [value]="break.begin"
                                placeholder="{{'Starts at' | translate}}"
                                (valueChange)="onTimeChanged('break', 'begin', $event)"></exg-timepicker>

                <exg-timepicker class="time-end"
                                [value]="break.end"
                                placeholder="{{'Ends at' | translate}}"
                                (valueChange)="onTimeChanged('break', 'end', $event)"></exg-timepicker>
            </div>
        </div>
    </div>
</div>
