import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseOrderCreateOrUpdateRequest } from '../../models/business/purchase-orders/purchase-order-create-or-update-request.model';
import { PurchaseOrder } from '../../models/business/purchase-orders/purchase-order.model';

export class PurchaseOrderCreateAction {
    static readonly type = '[PurchaseOrders Page] CreatePurchaseOrder';

    constructor(public payload: PurchaseOrderCreateOrUpdateRequest) { }
}

export class PurchaseOrderCreateSuccessAction {
    static readonly type = '[PurchaseOrder API] CreatePurchaseOrder Success';

    constructor(public payload: PurchaseOrder) { }
}

export class PurchaseOrderCreateFailAction {
    static readonly type = '[PurchaseOrder API] CreatePurchaseOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseOrderCreateResetAction {
    static readonly type = '[PurchaseOrders Page] CreatePurchaseOrder Reset';
}
