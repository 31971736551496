import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationPosService } from '../../services/business/organization-pos.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import {
    OrganizationPosUpdateAction,
    OrganizationPosUpdateFailAction,
    OrganizationPosUpdateResetAction,
    OrganizationPosUpdateSuccessAction,
} from './organization-pos-update.actions';
import { WarehousesService } from '../../services/business/warehouses.service';

export interface OrganizationPosUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<OrganizationPosUpdateStateModel>({
    name: 'organizationPosUpdate',
    defaults: { pending: false, updated: false, error: null },
})
@Injectable()
export class OrganizationPosUpdateState {
    constructor(private readonly orgPosService: OrganizationPosService, private readonly warehouseService: WarehousesService) {}

    @Action(OrganizationPosUpdateAction) async posUpdate(ctx: StateContext<OrganizationPosUpdateStateModel>, action: OrganizationPosUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return Promise.all([
            action.payload.warehouses?.length ? this.warehouseService.updateWarehouse(action.payload.warehouses) : null,
            this.orgPosService.updatePos(action.payload.uid, action.payload.request),
        ])
            .then(() => setTimeout(() => ctx.dispatch(new OrganizationPosUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new OrganizationPosUpdateFailAction(err)), 0));
    }

    @Action(OrganizationPosUpdateSuccessAction) organizationPosUpdateSuccess(
        ctx: StateContext<OrganizationPosUpdateStateModel>,
        _: OrganizationPosUpdateSuccessAction
    ) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(OrganizationPosUpdateFailAction) organizationPosUpdateFail(
        ctx: StateContext<OrganizationPosUpdateStateModel>,
        action: OrganizationPosUpdateFailAction
    ) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(OrganizationPosUpdateResetAction) organizationPosUpdateReset(ctx: StateContext<OrganizationPosUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
