<exg-button styleType="texted" *ngIf="buttonType === 'custom'" [excludePadding]="true" class="custom-button" (btnClick)="openOrganizationFilter()">
    <ng-content></ng-content>
</exg-button>

<exg-button styleType="texted"  *ngIf="buttonType === 'filter'" [excludePadding]="true" class="organization-filter" (btnClick)="openOrganizationFilter()">
    <div class="exg-color-primary selected-organizations">{{ firstOrganizationName$ | async }}</div>
    <div *ngIf="organizationCount$ | async" class="selected-organizations__count">+{{ organizationCount$ | async }}</div>
    <exg-icon class="org-icon" color="primary" iconName="arrowdown"></exg-icon>
</exg-button>

<exg-dialog
    [componentData]="componentData"
    [dialogMode]="dialogMode.NoButtons"
    [mobileFullScreen]="true"
    [hideBackdrop]="true"
    [dialogPosition]="dialogPosition"
    [showDialog]="showDialog"
    (dialogClose)="onDialogClose($event)"></exg-dialog>
