export enum Group {
        /// <summary>
        /// Administrator
        /// </summary>
        Administrator = 1,

        /// <summary>
        /// Sales manager
        /// </summary>
        SalesManager = 100,

        /// <summary>
        /// Controller
        /// </summary>
        Controller = 101
}
