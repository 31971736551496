import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DialogService } from '../../../../../burns-ui-framework/shared/services/common/dialog.service';
import { PusherService } from '../../../../../burns-ui-framework/shared/services/common/pusher.service';

import { ProfileDispatchers } from '../../../../../burns-ui-framework/shared/store/profile/profile.dispatchers';

import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { Profile } from '../../../../../burns-ui-framework/shared/models/business/user/profile.model';

import { ExgParamsConfig } from '../../../exg-params.config';

export interface IHeaderMenuItem {
    route: string;
    title: string;
    icon: string;
    useStroke?: boolean;
    bottom: boolean;
}

@Component({
    selector: 'bvc-left-menu',
    templateUrl: './bvc-left-menu.component.html',
    styleUrls: ['./bvc-left-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcLeftMenuComponent implements OnChanges {

    @Input() profile: Profile;
    @Input() menu: IHeaderMenuItem[];
    @Input() headerSettings: any;

    @Output() readonly logout = new EventEmitter<void>();

    public emptyAvatar = ExgParamsConfig.logos.emptyUserAvatar;

    public disableMargin: boolean;
    public bottomMenu: IHeaderMenuItem[];

    constructor(private dialogService: DialogService, private profileDispatchers: ProfileDispatchers, private pusherService: PusherService) {
        this.pusherService.subscribeToHub(ExgParamsConfig.signalR.identity.OnNameChanged).pipe().subscribe((res: { firstName: string, lastName: string}) => {
            this.profileDispatchers.dispatchUserNameUpdateAction(res.firstName, res.lastName);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.menu && this.menu) {
            this.disableMargin = this.menu.some(x => x.bottom);
            this.bottomMenu = this.menu.filter((v)=> v.bottom);
            this.menu = this.menu.filter((v)=> !v.bottom);
        }
    }

    public async onProfileClick() {
        if (await this.dialogService.showConfirm({ description: 'Are you sure you want to log out of your account?' }) === DialogResult.Confirm) {
            this.logout.emit();
        }
    }

    public trackByMenu(_, item: IHeaderMenuItem) {
        return item.title;
    }
}
