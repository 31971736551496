import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WriteOffsService } from '../../services/business/write-offs.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { WriteOffCreateAction, WriteOffCreateFailAction, WriteOffCreateResetAction, WriteOffCreateSuccessAction } from './write-off-create.actions';
import { WriteOff } from '../../models/business/write-offs/write-off.model';

export interface WriteOffCreateStateModel {
    pending: boolean;
    created: WriteOff;
    error: ErrorObject;
}

@State<WriteOffCreateStateModel>({
    name: 'writeoffCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class WriteOffCreateState {
    constructor(private writeoffsService: WriteOffsService) { }

    @Action(WriteOffCreateAction) async writeoffCreate(ctx: StateContext<WriteOffCreateStateModel>, action: WriteOffCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.writeoffsService.createWriteOff({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new WriteOffCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WriteOffCreateFailAction(err)), 0));
    }

    @Action(WriteOffCreateSuccessAction) writeoffCreateSuccess(ctx: StateContext<WriteOffCreateStateModel>, action: WriteOffCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(WriteOffCreateFailAction) writeoffCreateFail(ctx: StateContext<WriteOffCreateStateModel>, action: WriteOffCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(WriteOffCreateResetAction) writeoffCreateReset(ctx: StateContext<WriteOffCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
