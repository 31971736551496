import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilterData } from '../../models/filters/organizations-list-get-filter.model';

export class FranchiseeOrganizationsAction {
    static readonly type = '[FranchiseeOrganizations Page] GetFranchiseeOrganizations';

    constructor(public payload: OrganizationsListGetFilterData) { }
}
export class OrganizationPosesLodMoreAction {
    static readonly type = '[OrganizationPosesLoadMore Page] GetFranchiseeOrganizations';

    constructor() { }
}

export class FranchiseeOrganizationsSuccessAction {
    static readonly type = '[FranchiseeOrganizations API] GetFranchiseeOrganizations Success';

    constructor(public payload: { list: PaginationResult<OrganizationListItem>, pagination: PaginationRequest }) { }
}

export class FranchiseeOrganizationsFailAction {
    static readonly type = '[FranchiseeOrganizations API] GetFranchiseeOrganizations Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeOrganizationsResetAction {
    static readonly type = '[FranchiseeOrganizations Page] GetFranchiseeOrganizations Reset';
}
