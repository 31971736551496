import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseReturnsService } from '../../services/business/purchase-returns.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PurchaseReturnCreateAction, PurchaseReturnCreateFailAction, PurchaseReturnCreateResetAction, PurchaseReturnCreateSuccessAction } from './purchase-return-create.actions';
import { PurchaseReturn } from '../../models/business/purchase-returns/purchase-return.model';

export interface PurchaseReturnCreateStateModel {
    pending: boolean;
    created: PurchaseReturn;
    error: ErrorObject;
}

@State<PurchaseReturnCreateStateModel>({
    name: 'purchaseReturnCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PurchaseReturnCreateState {
    constructor(private purchaseReturnsService: PurchaseReturnsService) { }

    @Action(PurchaseReturnCreateAction) async purchaseReturnCreate(ctx: StateContext<PurchaseReturnCreateStateModel>, action: PurchaseReturnCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.purchaseReturnsService.createPurchaseReturn({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PurchaseReturnCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseReturnCreateFailAction(err)), 0));
    }

    @Action(PurchaseReturnCreateSuccessAction) purchaseReturnCreateSuccess(ctx: StateContext<PurchaseReturnCreateStateModel>, action: PurchaseReturnCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PurchaseReturnCreateFailAction) purchaseReturnCreateFail(ctx: StateContext<PurchaseReturnCreateStateModel>, action: PurchaseReturnCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PurchaseReturnCreateResetAction) purchaseReturnCreateReset(ctx: StateContext<PurchaseReturnCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
