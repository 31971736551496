import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategoryCreateOrUpdateRequest } from '../../models/business/catalog/product-category-create-or-update-request.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

export class CategoryCreateAction {
    static readonly type = '[Categorys Page] CreateCategory';

    constructor(public payload: { request: ProductCategoryCreateOrUpdateRequest }) { }
}

export class CategoryCreateSuccessAction {
    static readonly type = '[Category API] CreateCategory Success';

    constructor(public payload: ProductCategory) { }
}

export class CategoryCreateFailAction {
    static readonly type = '[Category API] CreateCategory Fail';

    constructor(public payload: ErrorObject) { }
}

export class CategoryCreateResetAction {
    static readonly type = '[Categorys Page] CreateCategory Reset';
}
