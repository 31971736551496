import { PaginationFilter } from './pagination-filter.model';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class OrderListGetFilter extends PaginationFilter {
    public term: string;
    public sortField: string;
    public sortOrder: SortOrder;
    public statuses?: number[];
    public activityStatus?: number;
    public source?: number;
    public dateFrom?: number;
    public dateTo?: number;
    public userUid?: string;
    public warehouseUid?: string;
    public companyUid?: string;
    public organizationUid?: string;
    public organizationUids?: string[];
    public organizationPosUids?: string[];
    public franchiseeUids?: string[];
    public contragentUid?: string;
    public posUid?: string;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: OrderListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null);
        this.term = (args && args.filterData) ? args.filterData.term : null;
        this.organizationUid = (args && args.filterData) ? args.filterData.organizationUid : null;
        this.companyUid = (args && args.filterData) ? args.filterData.companyUid : null;
        this.posUid = (args && args.filterData) ? args.filterData.posUid : null;
        this.warehouseUid = (args && args.filterData) ? args.filterData.warehouseUid : null;
        this.contragentUid = (args && args.filterData) ? args.filterData.contragentUid : null;
        this.userUid = (args && args.filterData) ? args.filterData.userUid : null;
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'CreateDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Desc;
        this.statuses = (args && args.filterData && args.filterData.statuses != null) ? args.filterData.statuses : null;
        this.organizationPosUids = (args && args.filterData && args.filterData.organizationPosUids != null) ? args.filterData.organizationPosUids : null;
        this.organizationUids = (args && args.filterData && args.filterData.organizationUids != null) ? args.filterData.organizationUids : null;
        this.franchiseeUids = (args && args.filterData && args.filterData.franchiseeUids != null) ? args.filterData.franchiseeUids : null;
        this.statuses = (args && args.filterData && args.filterData.statuses != null) ? args.filterData.statuses : null;
        this.activityStatus = (args && args.filterData && args.filterData.activityStatus != null) ? args.filterData.activityStatus : null;
        this.source = (args && args.filterData && args.filterData.source != null) ? args.filterData.source : null;
        this.dateFrom = (args && args.filterData && args.filterData.dateFrom != null) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData && args.filterData.dateTo != null) ? args.filterData.dateTo : null;
    }
}

export class OrderListGetFilterData extends SearchFilterData {
    public dateFrom?: number;
    public dateTo?: number;
    public source?: number;
    public activityStatus?: number;
    public statuses?: number[];
    public userUid?: string;
    public warehouseUid?: string;
    public organizationUid?: string;
    public contragentUid?: string;
    public posUid?: string;
    public companyUid?: string;
    public sortField?: string;
    public sortOrder?: SortOrder;
    public pageSize?: number;
    public organizationUids?: string[];
    public organizationPosUids?: string[];
    public franchiseeUids?: string[];
}
