import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { BrandsService } from '../../services/business/brands.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { BrandsSearchAction, BrandsSearchFailAction, BrandsSearchResetAction, BrandsSearchSuccessAction } from './brands-search.actions';

import { Brand } from '../../models/business/brands/brand.model';
import { BrandsListFilter, BrandsListFilterData } from '../../models/filters/brands-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface BrandsSearchStateModel extends PaginationStateModel<Brand> {
    uids: string[];
    entities: { [uid: string]: Brand };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: BrandsListFilterData;
    total: number;
}

@State<BrandsSearchStateModel>({
    name: 'brandsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class BrandsSearchState extends PaginationStateBase {
    constructor(private brandsService: BrandsService) {
        super();
    }

    @Action([BrandsSearchAction]) brandsGet(ctx: StateContext<BrandsSearchStateModel>, action: BrandsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new BrandsListFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.brandsService.getBrandList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new BrandsSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new BrandsSearchFailAction(err)), 0));
    }

    @Action(BrandsSearchSuccessAction) brandsGetSuccess(ctx: StateContext<BrandsSearchStateModel>, action: BrandsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.brandsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(BrandsSearchFailAction) brandsGetFail(ctx: StateContext<BrandsSearchStateModel>, action: BrandsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(BrandsSearchResetAction) brandsGetReset(ctx: StateContext<BrandsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }
}
