import { ContractPaymentCondition } from './contract-payment-condition.enum';
import { ContractStatus } from './contract-status.enum';
import { LinkedContact } from '../contacts/linked-contact.model';
import { PriceType } from '../contragents/price-type.model';
import { ShippingStatus } from './shipping-status.enum';

export class Contract {
    public uid: string;
    public number: string;
    public name: string;
    public dateFrom: number;
    public dateTo: number;
    public status: ContractStatus;
    public shippingStatus: ShippingStatus;
    public notes: string;
    public mainContact: LinkedContact;
    public currencyCode: string;
    public paymentCondition: ContractPaymentCondition;
    public postPaymentMaxDays: number;
    public creditAmount: number;
    public paymentOverdueMaxDays: number;
    public priceType: PriceType;
    public documentUrl: string;
}
