export enum OrderProductSortingFieldsEnum {
    Name = 'Name',
    Code = 'Code',
    Price = 'Price',
    Count = 'Count',
    Discount = 'Discount',
    Amount = 'Amount',
    ResponsibleUser = 'ResponsibleUser',
    Status = 'Status'
}
