import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { CompaniesMotivationBonus } from '../../models/business/contacts/companies-motivation-bonus.model';
import { CompaniesMotivationBonusesListGetFilter } from '../../models/filters/companies-motivation-bonuses-list-get-filter.model';
import { ContactsMotivationBonus } from '../../models/business/contacts/contacts-motivation-bonus.model';
import { ContactsMotivationBonusesListGetFilter } from '../../models/filters/contacts-motivation-bonuses-list-get-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { GuidUtils } from '../../../../burns-ui-framework/shared/utils/guid-utils';
import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class MotivationsService extends BaseSingletonService {

    private settings: {
        service: {
            getMotivationCompanies: string;
            getMotivationContacts: string;
            getMotivationProducts: string;
        }
    };

    constructor(private http: HttpService) {
        super('MotivationsService');
        this.settings = {
            service: {
                getMotivationCompanies: '​​/crm/v1/contacts/motivation-bonuses/companies?companyUid={0}&posUid={1}&bonusesDate={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                getMotivationContacts: '/crm/v1/contacts/motivation-bonuses/companies/{0}?bonusesDate={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                getMotivationProducts: '/crm/v1/contacts/motivation-bonuses/products?posUid={0}&contactUid={1}&contragentUid={2}&bonusesDate={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}'
            }
        };
    }

    public async getMotivationCompanies(filter: CompaniesMotivationBonusesListGetFilter): Promise<PaginationResult<CompaniesMotivationBonus>> {
        return this.http.get<PaginationResult<CompaniesMotivationBonus>>(String.format(this.settings.service.getMotivationCompanies, filter.companyUid, filter.posUid, filter.bonusesDate, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                res.list = res.list.map(x => ({ ...x, uid: GuidUtils.newGuid }));
                return res;
            });
    }

    public async getMotivationContacts(uid: string, filter: ContactsMotivationBonusesListGetFilter): Promise<PaginationResult<ContactsMotivationBonus>> {
        return this.http.get<PaginationResult<ContactsMotivationBonus>>(String.format(this.settings.service.getMotivationContacts, uid, filter.bonusesDate, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                res.list = res.list.map(x => ({ ...x, uid: GuidUtils.newGuid }));
                return res;
            });
    }

    public async getMotivationProducts(uid: string, filter: ContactsMotivationBonusesListGetFilter): Promise<PaginationResult<ContactsMotivationBonus>> {
        return this.http.get<PaginationResult<ContactsMotivationBonus>>(String.format(this.settings.service.getMotivationProducts, uid, filter.bonusesDate, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                res.list = res.list.map(x => ({ ...x, uid: GuidUtils.newGuid }));
                return res;
            });
    }
}
