
import { CompanyCreateOrUpdateRequest } from '../../models/business/companies/company-create-or-update.model';
import { Company } from '../../models/business/companies/company.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CompanyCreateAction {
    static readonly type = '[Companys Page] CreateCompany';

    constructor(public payload: CompanyCreateOrUpdateRequest) { }
}

export class CompanyCreateSuccessAction {
    static readonly type = '[Company API] CreateCompany Success';

    constructor(public payload: Company) { }
}

export class CompanyCreateFailAction {
    static readonly type = '[Company API] CreateCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyCreateResetAction {
    static readonly type = '[Companys Page] CreateCompany Reset';
}
