import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { InventoriesService } from '../../services/business/inventories.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { InventoryWorkbookFailAction, InventoryWorkbookGetAction, InventoryWorkbookGetSuccessAction, InventoryWorkbookResetAction } from './inventory-workbook.actions';

export interface InventoryWorkbookStateModel {
    created: Blob;
    pending: boolean;
    error: ErrorObject;
}

@State<InventoryWorkbookStateModel>({
    name: 'inventoryWorkbook',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class InventoryWorkbookState {
    constructor(private inventoriesService: InventoriesService) { }

    @Action(InventoryWorkbookGetAction) inventoryGet(ctx: StateContext<InventoryWorkbookStateModel>, action: InventoryWorkbookGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.inventoriesService.getWorkbook(action.payload.uid, action.payload.fileName)
            .then(resp => setTimeout(() => ctx.dispatch(new InventoryWorkbookGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new InventoryWorkbookFailAction(err)), 0));
    }

    @Action(InventoryWorkbookGetSuccessAction) inventoryGetSuccess(ctx: StateContext<InventoryWorkbookStateModel>, action: InventoryWorkbookGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(InventoryWorkbookFailAction) inventoryGetFail(ctx: StateContext<InventoryWorkbookStateModel>, action: InventoryWorkbookFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(InventoryWorkbookResetAction) inventoryGetReset(ctx: StateContext<InventoryWorkbookStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
