import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { UserChangePasswordRequest } from '../../models/business/user/user-change-password-request.model';


export class UserChangePasswordAction {
    static readonly type = '[Security Page] UserChangePassword';

    constructor(public payload: {
        request: UserChangePasswordRequest,
        userUid: string;
    }) { }
}

export class UserChangePasswordSuccessAction {
    static readonly type = '[Auth API] UserChangePassword Success';
}

export class UserChangePasswordFailAction {
    static readonly type = '[Auth API] UserChangePassword Fail';

    constructor(public payload: ErrorObject) { }
}

export class UserChangePasswordResetAction {
    static readonly type = '[Security Page] UserChangePassword Reset';
}
