import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TimeIntervalsGetAction, TimeIntervalsResetAction } from './time-intervals.actions';

@Injectable({
    providedIn: 'root'
})
export class TimeIntervalsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TimeIntervalsDispatchers');
    }

    public dispatchTimeIntervalsAction(organizationPosUid: string) {
        this.store.dispatch(new TimeIntervalsGetAction({ organizationPosUid }));
    }

    public dispatchTimeIntervalsResetAction() {
        this.store.dispatch(new TimeIntervalsResetAction());
    }
}
