import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PricelistStatusUpdateRequest } from '../../models/business/pricelist/pricelist-status-update-request.model';

export class PricelistUpdateStatusAction {
    static readonly type = '[Booking Page] UpdatePricelistStatus';

    constructor(public payload: { uid: string, request: PricelistStatusUpdateRequest }) { }
}

export class PricelistUpdateStatusSuccessAction {
    static readonly type = '[Pricelist API] UpdatePricelistStatus Success';

    constructor(public payload: { uid: string, request: PricelistStatusUpdateRequest }) { }
}

export class PricelistUpdateStatusFailAction {
    static readonly type = '[Pricelist API] UpdatePricelistStatus Fail';

    constructor(public payload: ErrorObject) { }
}

export class PricelistUpdateStatusResetAction {
    static readonly type = '[Booking Page] UpdatePricelistStatus Reset';
}
