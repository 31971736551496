<section>
    <div class="search_area">
        <exg-icon class="search_icon" iconName="search"></exg-icon>
        <exg-textbox
            class="search_input"
            type="text"
            [value]="term"
            styleType="empty"
            [placeholder]="'Enter the name of the subsidiary' | translate"
            (valueChange)="onSearchChanged($event)"></exg-textbox>
    </div>
    
    <perfect-scrollbar class="scroll" *ngIf="organizationPosesRetrieved$ | async" (psYReachEnd)="loadMore()">
    <div class="tree-items">
        <div
        *ngFor="let pose of organizationPoses$ | async; trackBy: trackByItem"
            class="tree-item"
            [ngClass]="{ 'tree-item__active': selectedItem?.uid === pose.uid }">
            <exg-button class="tree-item__title" styleType="custom" (btnClick)="onPosTitleClick(pose)">
                {{ pose.name }}
            </exg-button>
        </div>
    </div>
</perfect-scrollbar>

</section>
