import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AcceptancesService } from '../../services/business/acceptances.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { AcceptanceCreateAction, AcceptanceCreateFailAction, AcceptanceCreateResetAction, AcceptanceCreateSuccessAction } from './acceptance-create.actions';
import { Acceptance } from '../../models/business/acceptances/acceptance.model';

export interface AcceptanceCreateStateModel {
    pending: boolean;
    created: Acceptance;
    error: ErrorObject;
}

@State<AcceptanceCreateStateModel>({
    name: 'acceptanceCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class AcceptanceCreateState {
    constructor(private acceptancesService: AcceptancesService) { }

    @Action(AcceptanceCreateAction) async acceptanceCreate(ctx: StateContext<AcceptanceCreateStateModel>, action: AcceptanceCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.acceptancesService.createAcceptance({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new AcceptanceCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AcceptanceCreateFailAction(err)), 0));
    }

    @Action(AcceptanceCreateSuccessAction) acceptanceCreateSuccess(ctx: StateContext<AcceptanceCreateStateModel>, action: AcceptanceCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(AcceptanceCreateFailAction) acceptanceCreateFail(ctx: StateContext<AcceptanceCreateStateModel>, action: AcceptanceCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(AcceptanceCreateResetAction) acceptanceCreateReset(ctx: StateContext<AcceptanceCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
