import { ContractCreateOrUpdateRequest } from '../../models/business/contract/contract-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContractUpdateAction {
    static readonly type = '[Contract Page] UpdateContract';

    constructor(public payload: { uid: string, contragentUid: string, request: ContractCreateOrUpdateRequest }) { }
}

export class ContractUpdateSuccessAction {
    static readonly type = '[Contract API] UpdateContract Success';

    constructor(public payload: { uid: string, contragentUid: string, request: ContractCreateOrUpdateRequest }) { }
}

export class ContractUpdateFailAction {
    static readonly type = '[Contract API] UpdateContract Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContractUpdateResetAction {
    static readonly type = '[Contract Page] UpdateContract Reset';
}
