
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosRestrictionsRequest } from '../../models/business/organization-pos/pos-restrictions-request.model';
import { PosRestrictions } from '../../models/business/organization-pos/pos-restrictions.model';

export class OrganizationPosesDetailedAction {
    static readonly type = '[OrganizationPosesDetailed Page] GetOrganizationPosesDetailed';

    constructor(public payload: PaginationRequest) { }
}

export class OrganizationPosesDetailedSuccessAction {
    static readonly type = '[OrganizationPosesDetailed API] GetOrganizationPosesDetailed Success';

    constructor(public payload: { list: PaginationResult<OrganizationPosListItem>, pagination: PaginationRequest }) { }
}

export class OrganizationPosesDetailedFailAction {
    static readonly type = '[OrganizationPosesDetailed API] GetOrganizationPosesDetailed Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosesDetailedResetAction {
    static readonly type = '[OrganizationPosesDetailed Page] GetOrganizationPosesDetailed Reset';
}

export class OrganizationPosesDetailedSetFilterAction {
    static readonly type = '[OrganizationPosesDetailed API] SetFilter';

    constructor(public payload: OrganizationPosesListGetFilterData) { }
}

export class OrganizationPosesRestrictionsGetAction {
    static readonly type = '[OrganizationPosesDetailed Page] GetRestrictions by date';

    constructor(public payload: PosRestrictionsRequest) { }
}

export class OrganizationPosesRestrictionsSuccessAction {
    static readonly type = '[OrganizationPosesDetailed API] Restrictions Success';

    constructor(public payload: PosRestrictions[]) { }
}
