export class PublicProductListGetAggregationFilter {
    public term?: string;
    public typeUids?: string[];
    public parentUid?: string;
    public organizationUid?: string;
    public organizationPosUid?: string;
    public warehouseUid?: string;
    public categoryUids?: string[];

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: PublicProductListGetAggregationFilterData }) {
        this.term = (args && args.filterData) ? args.filterData.term : null;
        this.typeUids = (args && args.filterData) ? args.filterData.typeUids : null;
        this.parentUid = (args && args.filterData) ? args.filterData.parentUid : null;
        this.organizationUid = (args && args.filterData) ? args.filterData.organizationUid : null;
        this.organizationPosUid = (args && args.filterData) ? args.filterData.organizationPosUid : null;
        this.warehouseUid = (args && args.filterData) ? args.filterData.warehouseUid : null;
        this.categoryUids = (args && args.filterData) ? args.filterData.categoryUids : null;
    }
}

export class PublicProductListGetAggregationFilterData {
    public term?: string;
    public typeUids?: string[];
    public parentUid?: string;
    public organizationUid?: string;
    public organizationPosUid?: string;
    public warehouseUid?: string;
    public categoryUids?: string[];
}
