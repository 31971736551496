import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class RequisiteDeleteAction {
    static readonly type = '[My Requisite Page] DeleteRequisite';

    constructor(public payload: { uid: string, contragentUid: string }) { }
}

export class RequisiteDeleteSuccessAction {
    static readonly type = '[My Requisites API] DeleteRequisite Success';

    constructor(public payload: { uid: string }) { }
}

export class RequisiteDeleteFailAction {
    static readonly type = '[My Requisites API] DeleteRequisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class RequisiteDeleteResetAction {
    static readonly type = '[My Requisite Page] DeleteRequisite Reset';
}
