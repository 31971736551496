import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class ProductCategoriesListFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public parentUid?: string;
    public isPublic?: boolean;
    public isArchived?: boolean;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: ProductCategoriesListFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.parentUid = (args && args.filterData) ? args.filterData.parentUid : null;
        this.isPublic = (args && args.filterData) ? args.filterData.isPublic : null;
        this.isArchived = (args && args.filterData) ? args.filterData.isArchived : null;
    }
}

export class ProductCategoriesListFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public parentUid?: string;
    public isPublic?: boolean;
    public isArchived?: boolean;
}
