import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { BrandsService } from '../../services/business/brands.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { BrandDeleteImageAction, BrandDeleteImageFailAction, BrandDeleteImageResetAction, BrandDeleteImageSuccessAction } from './brand-delete-image.actions';

export interface BrandDeleteImageStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<BrandDeleteImageStateModel>({
    name: 'brandDeleteImage',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class BrandDeleteImageState {
    constructor(private brandsService: BrandsService) { }

    @Action(BrandDeleteImageAction) async brandDeleteImage(ctx: StateContext<BrandDeleteImageStateModel>, action: BrandDeleteImageAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.brandsService.deleteBrandPreview(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new BrandDeleteImageSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new BrandDeleteImageFailAction(error)), 0));
    }

    @Action(BrandDeleteImageSuccessAction) brandDeleteImageSuccess(ctx: StateContext<BrandDeleteImageStateModel>, _: BrandDeleteImageSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(BrandDeleteImageFailAction) brandDeleteImageFail(ctx: StateContext<BrandDeleteImageStateModel>, action: BrandDeleteImageFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(BrandDeleteImageResetAction) brandDeleteImageReset(ctx: StateContext<BrandDeleteImageStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
