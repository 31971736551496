export enum DCRSortingFieldsEnum {
    Number = 'Number',
    Date = 'Date',
    Products = 'Products',
    Sum = 'Sum',
    DifferenceAmount = 'DifferenceAmount',
    UnitType = 'UnitType',
    WarehouseFrom = 'WarehouseFrom',
    WarehouseTo = 'WarehouseTo',
    Notes = 'Notes'
}
