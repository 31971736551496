import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Note } from '../../models/business/notes/note.model';

import { NoteCreateState, NoteCreateStateModel } from './note-create.reducer';

@Injectable({
    providedIn: 'root'
})
export class NoteCreateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([NoteCreateState], (state: NoteCreateStateModel) => state.pending);

    static readonly getUpdated = createSelector([NoteCreateState], (state: NoteCreateStateModel) => state.created);

    static readonly getError = createSelector([NoteCreateState], (state: NoteCreateStateModel) => state.error);

    @Select(NoteCreateSelectors.getPending) pending$: Observable<boolean>;

    @Select(NoteCreateSelectors.getUpdated) created$: Observable<Note>;

    @Select(NoteCreateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('NoteCreateSelectors');
    }
}
