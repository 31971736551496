import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PricelistCreateAction, PricelistCreateFailAction, PricelistCreateResetAction, PricelistCreateSuccessAction } from './pricelist-create.actions';
import { Pricelist } from '../../models/business/pricelist/pricelist.model';

export interface PricelistCreateStateModel {
    pending: boolean;
    created: Pricelist;
    error: ErrorObject;
}

@State<PricelistCreateStateModel>({
    name: 'pricelistCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PricelistCreateState {
    constructor(private pricelistService: PricelistService) { }

    @Action(PricelistCreateAction) async pricelistCreate(ctx: StateContext<PricelistCreateStateModel>, action: PricelistCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.pricelistService.createPricelist({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new PricelistCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistCreateFailAction(err)), 0));
    }

    @Action(PricelistCreateSuccessAction) pricelistCreateSuccess(ctx: StateContext<PricelistCreateStateModel>, action: PricelistCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PricelistCreateFailAction) pricelistCreateFail(ctx: StateContext<PricelistCreateStateModel>, action: PricelistCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(PricelistCreateResetAction) pricelistCreateReset(ctx: StateContext<PricelistCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
