import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { TransfersService } from '../../services/business/transfers.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { TransferCreateSuccessAction } from './transfer-create.actions';
import { TransferDeleteSuccessAction } from './transfer-delete.actions';
import { TransferUpdateSuccessAction } from './transfer-update.actions';
import { TransfersAction, TransfersFailAction, TransfersResetAction, TransfersSetFilterAction, TransfersSuccessAction } from './transfers.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { TransferListGetFilter, TransferListGetFilterData } from '../../models/filters/transfer-list-get-filter.model';
import { TransferListItem } from '../../models/business/transfers/transfer-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface TransfersStateModel extends PaginationFilteredStateModel<TransferListItem, TransferListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: TransferListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TransferListGetFilterData;
    total: number;
}

@State<TransfersStateModel>({
    name: 'transfers',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class TransfersState extends PaginationFilteredStateBase {
    constructor(private transfersService: TransfersService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([TransfersAction]) productsGet(ctx: StateContext<TransfersStateModel>, action: TransfersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'transfer');
        ctx.setState({ ...state, filter: filterData });

        const filter = new TransferListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.transfersService.getTransferList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TransfersSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransfersFailAction(err)), 0));
    }

    @Action(TransfersSuccessAction) transferGetSuccess(ctx: StateContext<TransfersStateModel>, action: TransfersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(TransfersFailAction) transferGetFail(ctx: StateContext<TransfersStateModel>, action: TransfersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransfersResetAction) transferGetReset(ctx: StateContext<TransfersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(TransfersSetFilterAction) usersSetFilter(ctx: StateContext<TransfersStateModel>, action: TransfersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'transfer');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new TransfersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(TransferCreateSuccessAction) transferCreateSuccess(ctx: StateContext<TransfersStateModel>, action: TransferCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(TransferUpdateSuccessAction) transferUpdateSuccess(ctx: StateContext<TransfersStateModel>, action: TransferUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(TransferDeleteSuccessAction) transferDeleteSuccess(ctx: StateContext<TransfersStateModel>, action: TransferDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
