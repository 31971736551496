import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TechnologicalMapActiveByProductGetFilter } from '../../models/filters/technological-map-active-by-product-get-filter.model';
import { TechnologicalMapCreateRequest } from '../../models/business/technological-maps/technological-map-create-request.model';
import { TechnologicalMapLight } from '../../models/business/technological-maps/technological-map-light.model';
import { TechnologicalMapListGetFilter } from '../../models/filters/technological-map-list-get-filter.model';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';
import { TechnologicalMapProductHierarchy } from '../../models/business/technological-maps/technological-map-product-hierarchy.model';
import { TechnologicalMapRestoreRequest } from '../../models/business/technological-maps/technological-map-restore-request.model';
import { TechnologicalMapUpdateRequest } from '../../models/business/technological-maps/technological-map-update-request.model';
import { TechnologicalMap } from '../../models/business/technological-maps/technological-map.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root',
})
export class TechnologicalMapsService extends BaseSingletonService {
    private settings: {
        service: {
            getTechnologicalMap: string;
            getWorkbook: string;
            getTechnologicalMapList: string;
            getTechnologicalMapListByProduct: string;
            getTechnologicalMapListByContent: string;
            createTechnologicalMap: string;
            updateTechnologicalMap: string;
            deleteTechnologicalMap: string;
            restoreTechnologicalMap: string;
            getTechnologicalMapListActiveByProduct: string;
            getTechnologicalMapActiveByProduct: string;
        };
    };

    constructor(private http: HttpService) {
        super('TechnologicalMapsService');
        this.settings = {
            service: {
                getTechnologicalMap: '/warehouses-aggregation/v1/technological-maps/{0}',
                getWorkbook: '/warehouses/v1/technological-maps/{0}/workbook',
                getTechnologicalMapList:
                    '/restaurants-aggregation/v1/technological-maps?term={0}&isArchived={1}&pageIndex={2}&pageSize={3}&productUid={4}&organizationPosUid={5}',
                getTechnologicalMapListByProduct: '/restaurants-aggregation/v1/technological-maps/hierarchy-by-product/{0}',
                getTechnologicalMapListActiveByProduct: '/restaurants/v1/technological-maps/active-by-product/{0}',
                getTechnologicalMapActiveByProduct: '/restaurants/v1/technological-maps/active-by-product/{0}',
                getTechnologicalMapListByContent: '/restaurants-aggregation/v1/technological-maps/list-by-content/{0}',
                createTechnologicalMap: '/restaurants-aggregation/v1/technological-maps',
                updateTechnologicalMap: '/restaurants-aggregation/v1/technological-maps/{0}',
                restoreTechnologicalMap: '/restaurants-aggregation/v1/technological-maps/{0}/restore',
                deleteTechnologicalMap: '/restaurants-aggregation/v1/technological-maps/{0}',
            },
        };
    }

    public async getTechnologicalMap(uid: string): Promise<TechnologicalMap> {
        return this.http.get<{ data: TechnologicalMap }>(String.format(this.settings.service.getTechnologicalMap, uid)).then(res => res.data);
    }

    public async getWorkbook(uid: string, fileName: string): Promise<Blob> {
        return this.http.download(String.format(this.settings.service.getWorkbook, uid), fileName);
    }

    public async getTechnologicalMapList(filter: TechnologicalMapListGetFilter): Promise<PaginationResult<TechnologicalMapListItemAggregation>> {
        return this.http
            .get<PaginationResult<TechnologicalMapListItemAggregation>>(
                String.format(
                    this.settings.service.getTechnologicalMapList,
                    filter.term,
                    filter.isArchived,
                    filter.pageIndex,
                    filter.pageSize,
                    filter.productUid,
                    filter.organizationPosUid
                )
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getTechnologicalMapListByProduct(filter: TechnologicalMapListGetFilter): Promise<PaginationResult<TechnologicalMapProductHierarchy>> {
        return this.http
            .get<PaginationResult<TechnologicalMapProductHierarchy>>(String.format(this.settings.service.getTechnologicalMapListByProduct, filter.productUid))
            .then(res => {
                res.list = res.list.map(v => ({ ...v, uid: v.product.uid }));
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getTechnologicalMapListByContent(filter: TechnologicalMapListGetFilter): Promise<PaginationResult<TechnologicalMapListItemAggregation>> {
        return this.http
            .get<PaginationResult<TechnologicalMapListItemAggregation>>(
                String.format(this.settings.service.getTechnologicalMapListByContent, filter.productUid)
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getTechnologicalMapListActiveByProduct(filter: TechnologicalMapActiveByProductGetFilter): Promise<PaginationResult<TechnologicalMapLight>> {
        return this.http
            .get<PaginationResult<TechnologicalMapLight>>(
                String.format(this.settings.service.getTechnologicalMapListActiveByProduct, filter.productUid)
            )
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getTechnologicalMapActiveByProduct(uid: string): Promise<TechnologicalMapLight> {
        return this.http.get<{ data: TechnologicalMapLight }>(String.format(this.settings.service.getTechnologicalMapListActiveByProduct, uid)).then(res => {
            return res.data;
        });
    }

    public async createTechnologicalMap(request: TechnologicalMapCreateRequest): Promise<TechnologicalMapListItemAggregation> {
        return this.http.post<{ data: TechnologicalMapListItemAggregation }>(String.format(this.settings.service.createTechnologicalMap), request)
                .then(res => res.data);
    }

    public async restoreTechnologicalMap(uid: string, request: TechnologicalMapRestoreRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.restoreTechnologicalMap, uid), request);
    }

    public async updateTechnologicalMap(uid: string, request: TechnologicalMapUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateTechnologicalMap, uid), request);
    }

    public async deleteTechnologicalMap(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteTechnologicalMap, uid));
    }
}
