import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseOrdersService } from '../../services/business/purchase-orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseOrder } from '../../models/business/purchase-orders/purchase-order.model';

import { PurchaseOrderFailAction, PurchaseOrderGetAction, PurchaseOrderGetSuccessAction, PurchaseOrderResetAction } from './purchase-order.actions';

export interface PurchaseOrderStateModel {
    entity: PurchaseOrder;
    pending: boolean;
    error: ErrorObject;
}

@State<PurchaseOrderStateModel>({
    name: 'purchaseOrder',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class PurchaseOrderState {
    constructor(private purchaseOrdersService: PurchaseOrdersService) { }

    @Action(PurchaseOrderGetAction) purchaseOrderGet(ctx: StateContext<PurchaseOrderStateModel>, action: PurchaseOrderGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.purchaseOrdersService.getPurchaseOrder(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new PurchaseOrderGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseOrderFailAction(err)), 0));
    }

    @Action(PurchaseOrderGetSuccessAction) purchaseOrderGetSuccess(ctx: StateContext<PurchaseOrderStateModel>, action: PurchaseOrderGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(PurchaseOrderFailAction) purchaseOrderGetFail(ctx: StateContext<PurchaseOrderStateModel>, action: PurchaseOrderFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseOrderResetAction) purchaseOrderGetReset(ctx: StateContext<PurchaseOrderStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
