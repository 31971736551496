import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ContragentsListFilterData } from '../../models/filters/contragents-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class CompanyContragentsAction {
    static readonly type = '[CompanyContragents Page] GetCompanyContragents';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyContragentsSuccessAction {
    static readonly type = '[CompanyContragents API] GetCompanyContragents Success';

    constructor(public payload: { list: PaginationResult<ContragentListItem>, pagination: PaginationRequest }) { }
}

export class CompanyContragentsFailAction {
    static readonly type = '[CompanyContragents API] GetCompanyContragents Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyContragentsResetAction {
    static readonly type = '[CompanyContragents Page] GetCompanyContragents Reset';
}

export class CompanyContragentsSetFilterAction {
    static readonly type = '[CompanyContragents API] SetFilter';

    constructor(public payload: ContragentsListFilterData) { }
}
