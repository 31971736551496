import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';

export class RestaurantCategoryListGetFilter extends SearchPaginationFilter {
    public organizationPosUid?: string;
    public parentUid?: string;
    public isPublic?: boolean;
    public isArchived?: boolean;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: RestaurantCategoryListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.organizationPosUid = (args && args.filterData) ? args.filterData.organizationPosUid : null;
        this.parentUid = (args && args.filterData) ? args.filterData.parentUid : null;
        this.isPublic = (args && args.filterData) ? args.filterData.isPublic : null;
        this.isArchived = (args && args.filterData) ? args.filterData.isArchived : null;
    }
}

export class RestaurantCategoryListGetFilterData extends SearchFilterData {
    public organizationPosUid?: string;
    public parentUid?: string;
    public isPublic?: boolean;
    public isArchived?: boolean;
    public pageSize?: number;
}
