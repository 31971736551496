import { LinkedCompany } from '../companies/linked-company.model';
import { LinkedUser } from '../user/linked-user.model';

export class ContragentListItem {
    public uid: string;
    public company: LinkedCompany;
    public shortName: string;
    public inn: string;
    public responsibleUser: LinkedUser;
}
