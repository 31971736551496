<section class="row-header">
    <div class="menu">
        <exg-menu *ngIf="showMenuButton" class="menu_el" [items]="createMenuItems" (menuItemClick)="onCreateMenuItemClick($event)">
            <exg-button [disabled]="!isAddButtonEnabled" (btnClick)="onAddButton()" [excludePadding]="true" class="add-btn exg-color-primary" color="primary">
                <exg-icon class="add-icon" color="primary" iconName="file-add-line"></exg-icon>
                <div class="btn-title">{{ 'Create' | translate }}</div>
                <exg-icon class="add-icon" color="primary" iconName="arrow-down-s-line"></exg-icon>
            </exg-button>
        </exg-menu>
        <exg-menu *ngIf="showActionButton" class="menu_el" [items]="menuItems" (menuItemClick)="onMenuItemClick($event)">
            <exg-button class="menu_btn" styleType="outlined"><exg-icon class="menu_icon" color="primary" iconName="menudotted_vertical"></exg-icon></exg-button>
        </exg-menu>
    </div>
</section>