
import { ContactsMotivationBonus } from '../../models/business/contacts/contacts-motivation-bonus.model';
import { ContactsMotivationBonusesListGetFilterData } from '../../models/filters/contacts-motivation-bonuses-list-get-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class MotivationBonusesContactsAction {
    static readonly type = '[MotivationBonusesContacts Page] GetMotivationBonusesContacts';

    constructor(public payload: PaginationRequest) { }
}

export class MotivationBonusesContactsSuccessAction {
    static readonly type = '[MotivationBonusesContacts API] GetMotivationBonusesContacts Success';

    constructor(public payload: { list: PaginationResult<ContactsMotivationBonus>, pagination: PaginationRequest }) { }
}

export class MotivationBonusesContactsFailAction {
    static readonly type = '[MotivationBonusesContacts API] GetMotivationBonusesContacts Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusesContactsResetAction {
    static readonly type = '[MotivationBonusesContacts Page] GetMotivationBonusesContacts Reset';
}

export class MotivationBonusesContactsSetFilterAction {
    static readonly type = '[MotivationBonusesContacts API] SetFilter';

    constructor(public payload: { uid: string, filter: ContactsMotivationBonusesListGetFilterData }) { }
}
