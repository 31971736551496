import { Contract } from '../../models/business/contract/contract.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContractAction {
    static readonly type = '[Contract Page] Contract';

    constructor(public payload: { uid: string, contragenUid: string }) { }
}

export class ContractSuccessAction {
    static readonly type = '[Contract API] Contract Success';

    constructor(public payload: Contract) { }
}

export class ContractFailAction {
    static readonly type = '[Contract API] Contract Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContractResetAction {
    static readonly type = '[Contract Page] Contract Reset';
}
