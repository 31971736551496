import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WarehousesService } from '../../services/business/warehouses.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { WarehouseAction, WarehouseFailAction, WarehouseResetAction, WarehouseSuccessAction } from './warehouse.actions';
import { Warehouse } from '../../models/business/warehouses/warehouse.model';

export interface WarehouseStateModel {
    pending: boolean;
    entity: Warehouse;
    error: ErrorObject;
}

@State<WarehouseStateModel>({
    name: 'warehouse',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class WarehouseState {
    constructor(private warehousesService: WarehousesService) { }

    @Action(WarehouseAction) async warehouse(ctx: StateContext<WarehouseStateModel>, action: WarehouseAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.warehousesService.getWarehouse(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new WarehouseSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WarehouseFailAction(err)), 0));
    }

    @Action(WarehouseSuccessAction) warehouseSuccess(ctx: StateContext<WarehouseStateModel>, action: WarehouseSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(WarehouseFailAction) warehouseFail(ctx: StateContext<WarehouseStateModel>, action: WarehouseFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WarehouseResetAction) warehouseReset(ctx: StateContext<WarehouseStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
