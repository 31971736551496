import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Posting } from '../../models/business/postings/posting.model';

export class PostingGetAction {
    static readonly type = '[Posting Page] GetPosting Get';

    constructor(public payload: string) { }
}

export class PostingGetSuccessAction {
    static readonly type = '[Posting API] GetPosting Success';

    constructor(public payload: Posting) { }
}

export class PostingFailAction {
    static readonly type = '[Posting API] GetPosting Fail';

    constructor(public payload: ErrorObject) { }
}

export class PostingResetAction {
    static readonly type = '[Posting Page] GetPosting Reset';
}
