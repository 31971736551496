import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapLight } from '../../models/business/technological-maps/technological-map-light.model';

import { TechnologicalMapActiveByProductFailAction, TechnologicalMapActiveByProductGetAction, TechnologicalMapActiveByProductGetSuccessAction, TechnologicalMapActiveByProductResetAction } from './technological-map-active-by-product.actions';

export interface TechnologicalMapActiveByProductStateModel {
    entity: TechnologicalMapLight;
    pending: boolean;
    error: ErrorObject;
}

@State<TechnologicalMapActiveByProductStateModel>({
    name: 'technologicalMapActiveByProduct',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class TechnologicalMapActiveByProductState {
    constructor(private technologicalMapService: TechnologicalMapsService) { }

    @Action(TechnologicalMapActiveByProductGetAction) technologicalMapActiveByProductGet(ctx: StateContext<TechnologicalMapActiveByProductStateModel>, action: TechnologicalMapActiveByProductGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.technologicalMapService.getTechnologicalMapActiveByProduct(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new TechnologicalMapActiveByProductGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapActiveByProductFailAction(err)), 0));
    }

    @Action(TechnologicalMapActiveByProductGetSuccessAction) technologicalMapActiveByProductGetSuccess(ctx: StateContext<TechnologicalMapActiveByProductStateModel>, action: TechnologicalMapActiveByProductGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(TechnologicalMapActiveByProductFailAction) technologicalMapActiveByProductGetFail(ctx: StateContext<TechnologicalMapActiveByProductStateModel>, action: TechnologicalMapActiveByProductFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TechnologicalMapActiveByProductResetAction) technologicalMapActiveByProductGetReset(ctx: StateContext<TechnologicalMapActiveByProductStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
