import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrderStatusUpdateRequest } from '../../models/business/orders/order-status-update-request.model';

export class OrderUpdateStatusAction {
    static readonly type = '[Order API] UpdateOrderStatus';

    constructor(public payload: { uid: string, request: OrderStatusUpdateRequest }) { }
}

export class OrderUpdateStatusSuccessAction {
    static readonly type = '[Order API] UpdateOrderStatus Success';

    constructor(public payload: { uid: string, request: OrderStatusUpdateRequest }) { }
}

export class OrderUpdateStatusFailAction {
    static readonly type = '[Order API] UpdateOrderStatus Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrderUpdateStatusResetAction {
    static readonly type = '[Order API] UpdateOrderStatus Reset';
}
