import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';

import { OrganizationPosesSearchState, OrganizationPosesSearchStateModel } from './organization-poses-search.reducer';
import { OrganizationPosesListGetFilter } from '../../models/filters/organization-pos-list-get-filter.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPosesSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.pending);

    static readonly getOrganizationPosesSearch = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.error);

    static readonly getAllOrganizationPosesSearchLoaded = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.filter);

    static readonly getTotal = createSelector([OrganizationPosesSearchState], (state: OrganizationPosesSearchStateModel) => state.total);

    @Select(OrganizationPosesSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(OrganizationPosesSearchSelectors.getOrganizationPosesSearch) organizationPoses$: Observable<OrganizationPosListItem[]>;

    @Select(OrganizationPosesSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(OrganizationPosesSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(OrganizationPosesSearchSelectors.getAllOrganizationPosesSearchLoaded) allOrganizationPosesLoaded$: Observable<boolean>;

    @Select(OrganizationPosesSearchSelectors.getFilter) filter$: Observable<OrganizationPosesListGetFilter>;

    @Select(OrganizationPosesSearchSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('OrganizationPosesSearchSelectors');
    }
}
