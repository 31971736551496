import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { UsersService } from '../../services/business/users.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { UserChangePasswordAction, UserChangePasswordFailAction, UserChangePasswordResetAction, UserChangePasswordSuccessAction } from './user-change-password.actions';

export interface UserChangePasswordStateModel {
    pending: boolean;
    changed: boolean;
    error: ErrorObject;
}

@State<UserChangePasswordStateModel>({
    name: 'userUserChangePassword',
    defaults: { pending: false, changed: false, error: null }
})
@Injectable()
export class UserChangePasswordState {
    constructor(private userService: UsersService) { }

    @Action(UserChangePasswordAction) userUserChangePassword(ctx: StateContext<UserChangePasswordStateModel>, action: UserChangePasswordAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, changed: false, error: null });

        this.userService.changeUserPassword(action.payload.userUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new UserChangePasswordSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new UserChangePasswordFailAction(err)), 0));
    }

    @Action(UserChangePasswordSuccessAction) userUserChangePasswordSuccess(ctx: StateContext<UserChangePasswordStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, changed: true, error: null });
    }

    @Action(UserChangePasswordFailAction) userUserChangePasswordFail(ctx: StateContext<UserChangePasswordStateModel>, action: UserChangePasswordFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, changed: false, error: action.payload });
    }

    @Action(UserChangePasswordResetAction) userUserChangePasswordReset(ctx: StateContext<UserChangePasswordStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, changed: false, error: null });
    }
}
