<button class="btn" type="button" (click)="onOpenContragentClick()">
    <div class="full-name" *ngIf="listConfig[contragentSortingFields.FullName]" [class.active]="filter.sortField === contragentSortingFields.FullName">
        <span title="{{contragent.shortName}}">{{contragent.shortName}}</span>
    </div>
    <div class="registered" *ngIf="listConfig[contragentSortingFields.Inn]" [class.active]="filter.sortField === contragentSortingFields.Inn">
        <span title="{{contragent.inn}}">{{contragent.inn}}</span>
    </div>
    <div class="registered" *ngIf="listConfig[contragentSortingFields.Company]" [class.active]="filter.sortField === contragentSortingFields.Company">
        <span title="{{contragent.company?.name}}">{{contragent.company?.name}}</span>
    </div>
    <div class="registered" *ngIf="listConfig[contragentSortingFields.User]" [class.active]="filter.sortField === contragentSortingFields.User">
        <span title="{{contragent.responsibleUser?.fullName}}">{{contragent.responsibleUser?.fullName}}</span>
    </div>
</button>
<button class="btn btn_mobile" type="button" (click)="onOpenContragentClick()">
    <div class="goup-line">
        <div class="goup-left">
            <div title="{{contragent.shortName}}" class="title">{{contragent.shortName}}</div>
        </div>
        <div class="goup-right"></div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Inn' | translate}}</div>
        <div class="goup-right" title="{{contragent.inn}}">
            {{contragent.inn}}
        </div>
    </div>
    <div class="border-line"></div>
    <div class="goup-line">
        <div class="goup-left">{{'Company' | translate}}</div>
        <div class="goup-right" title="{{contragent.company?.name}}">
            {{contragent.company?.name}}
        </div>
    </div>
    <div class="goup-line">
        <div class="goup-left">{{'User' | translate}}</div>
        <div class="goup-right" title="{{contragent.responsibleUser?.fullName}}">
            {{contragent.responsibleUser?.fullName}}
        </div>
    </div>
</button>