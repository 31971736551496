import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Suggestion } from '../../models/business/contragents/suggestion.model';

import { ContragentSuggestionFailAction, ContragentSuggestionGetAction, ContragentSuggestionGetSuccessAction, ContragentSuggestionResetAction } from './contragent-suggestion.actions';

export interface ContragentSuggestionStateModel {
    entities: Suggestion[];
    pending: boolean;
    error: ErrorObject;
}

@State<ContragentSuggestionStateModel>({
    name: 'contragentSuggestion',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class ContragentSuggestionState {
    constructor(private contragentsService: ContragentsService) { }

    @Action(ContragentSuggestionGetAction) contragentSuggestionGet(ctx: StateContext<ContragentSuggestionStateModel>, action: ContragentSuggestionGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.contragentsService.getSuggestions(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new ContragentSuggestionGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContragentSuggestionFailAction(err)), 0));
    }

    @Action(ContragentSuggestionGetSuccessAction) contragentSuggestionGetSuccess(ctx: StateContext<ContragentSuggestionStateModel>, action: ContragentSuggestionGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(ContragentSuggestionFailAction) contragentSuggestionGetFail(ctx: StateContext<ContragentSuggestionStateModel>, action: ContragentSuggestionFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContragentSuggestionResetAction) contragentSuggestionGetReset(ctx: StateContext<ContragentSuggestionStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
