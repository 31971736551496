import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WarehouseListItem } from '../../models/business/warehouses/warehouse-list-item.model';
import { WarehousesListFilterData } from '../../models/filters/warehouses-list-filter.model';

import { WarehousesSearchState, WarehousesSearchStateModel } from './warehouses-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class WarehousesSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.pending);

    static readonly getWarehousesSearch = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.error);

    static readonly getAllWarehousesSearchLoaded = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.filter);

    static readonly getTotal = createSelector([WarehousesSearchState], (state: WarehousesSearchStateModel) => state.total);

    @Select(WarehousesSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(WarehousesSearchSelectors.getWarehousesSearch) warehouses$: Observable<WarehouseListItem[]>;

    @Select(WarehousesSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(WarehousesSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(WarehousesSearchSelectors.getAllWarehousesSearchLoaded) allWarehousesSearchLoaded$: Observable<boolean>;

    @Select(WarehousesSearchSelectors.getFilter) filter$: Observable<WarehousesListFilterData>;

    @Select(WarehousesSearchSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('WarehousesSearchSelectors');
    }
}
