import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IntervalsService } from '../../services/business/intervals.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { IntervalUpdateAction, IntervalUpdateFailAction, IntervalUpdateResetAction, IntervalUpdateSuccessAction } from './interval-update.actions';

export interface IntervalUpdateStateModel {
    updated: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<IntervalUpdateStateModel>({
    name: 'intervalUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class IntervalUpdateState {

    constructor(private intervalsService: IntervalsService) { }

    @Action(IntervalUpdateAction) intervalUpdate(ctx: StateContext<IntervalUpdateStateModel>, action: IntervalUpdateAction) {

        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.intervalsService.updateInterval(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new IntervalUpdateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new IntervalUpdateFailAction(err)), 0));
    }

    @Action(IntervalUpdateSuccessAction) intervalsUpdateSuccess(ctx: StateContext<IntervalUpdateStateModel>, _: IntervalUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(IntervalUpdateFailAction) intervalsUpdateFail(ctx: StateContext<IntervalUpdateStateModel>, action: IntervalUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(IntervalUpdateResetAction) intervalsUpdateReset(ctx: StateContext<IntervalUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
