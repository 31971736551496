import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContactsService } from '../../services/business/contacts.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { MotivationBonusUpdateAction, MotivationBonusUpdateFailAction, MotivationBonusUpdateResetAction, MotivationBonusUpdateSuccessAction } from './motivation-bonus-update.actions';

export interface MotivationBonusUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<MotivationBonusUpdateStateModel>({
    name: 'motivationBonusUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class MotivationBonusUpdateState {
    constructor(private contactsService: ContactsService) { }

    @Action(MotivationBonusUpdateAction) async motivationBonusUpdate(ctx: StateContext<MotivationBonusUpdateStateModel>, action: MotivationBonusUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.contactsService.updateMotivation(action.payload.contactUid, action.payload.motivationUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new MotivationBonusUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new MotivationBonusUpdateFailAction(err)), 0));
    }

    @Action(MotivationBonusUpdateSuccessAction) motivationBonusUpdateSuccess(ctx: StateContext<MotivationBonusUpdateStateModel>, _: MotivationBonusUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(MotivationBonusUpdateFailAction) motivationBonusUpdateFail(ctx: StateContext<MotivationBonusUpdateStateModel>, action: MotivationBonusUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(MotivationBonusUpdateResetAction) motivationBonusUpdateReset(ctx: StateContext<MotivationBonusUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
