import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Transfer } from '../../models/business/transfers/transfer.model';

export class TransferGetAction {
    static readonly type = '[Transfer Page] GetTransfer Get';

    constructor(public payload: string) { }
}

export class TransferGetSuccessAction {
    static readonly type = '[Transfer API] GetTransfer Success';

    constructor(public payload: Transfer) { }
}

export class TransferFailAction {
    static readonly type = '[Transfer API] GetTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransferResetAction {
    static readonly type = '[Transfer Page] GetTransfer Reset';
}
