import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganizationsService } from '../../services/business/organizations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { OrganizationDeleteAction, OrganizationDeleteFailAction, OrganizationDeleteResetAction, OrganizationDeleteSuccessAction } from './organization-delete.actions';

export interface OrganizationDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<OrganizationDeleteStateModel>({
    name: 'organizationDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class OrganizationDeleteState {
    constructor(private organizationsService: OrganizationsService) { }

    @Action(OrganizationDeleteAction) async organizationDelete(ctx: StateContext<OrganizationDeleteStateModel>, action: OrganizationDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.organizationsService.deleteOrganization(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new OrganizationDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new OrganizationDeleteFailAction(error)), 0));
    }

    @Action(OrganizationDeleteSuccessAction) organizationDeleteSuccess(ctx: StateContext<OrganizationDeleteStateModel>, _: OrganizationDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(OrganizationDeleteFailAction) organizationDeleteFail(ctx: StateContext<OrganizationDeleteStateModel>, action: OrganizationDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(OrganizationDeleteResetAction) organizationDeleteReset(ctx: StateContext<OrganizationDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
