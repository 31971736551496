import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TransferCreateOrUpdateRequest } from '../../models/business/transfers/transfer-create-or-update-request.model';
import { Transfer } from '../../models/business/transfers/transfer.model';

export class TransferCreateAction {
    static readonly type = '[Transfers Page] CreateTransfer';

    constructor(public payload: TransferCreateOrUpdateRequest) { }
}

export class TransferCreateSuccessAction {
    static readonly type = '[Transfer API] CreateTransfer Success';

    constructor(public payload: Transfer) { }
}

export class TransferCreateFailAction {
    static readonly type = '[Transfer API] CreateTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransferCreateResetAction {
    static readonly type = '[Transfers Page] CreateTransfer Reset';
}
