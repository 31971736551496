import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExgParamsConfig } from '../../../exg-params.config';

@Component({
    selector: 'bvc-paging-menu',
    templateUrl: './bvc-paging-menu.component.html',
    styleUrls: ['./bvc-paging-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcPagingMenuComponent {
    @Input() listConfig: {};
    @Input() enumArray: any[];
    @Input() translations: {};

    @Input() pageSize: number;

    @Output() pageSizeChanged = new EventEmitter<number>();
    @Output() listConfigChanged = new EventEmitter<{}>();

    public pages = ExgParamsConfig.pagination.pagesSizesArray;

    public onChecked(enumValue: any, checked: boolean) {
        const newListConfig = { ...this.listConfig };
        newListConfig[enumValue] = checked;
        this.listConfigChanged.emit(newListConfig);
    }

    public onPageSizeChanged($event: number) {
        this.pageSizeChanged.emit($event);
    }
}
