export enum TaskStatus {
    /// <summary>
    /// To Do
    /// </summary>
    ToDo = 1,

    /// <summary>
    /// In progress
    /// </summary>
    InProgress,

    /// <summary>
    /// Closed
    /// </summary>
    Closed1 = 10
}
