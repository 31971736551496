import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { FranchiseeTypesListItem } from '../../models/business/franchisees/franchisee-types-list-item.model';
import { FranchiseeTypeListGetFilterData } from '../../models/filters/franchisee-type-list-get-filter.model';


export class FranchiseeTypesAction {
    static readonly type = '[FranchiseeTypess Page] GetFranchiseeTypes';

    constructor(public payload: PaginationRequest) { }
}

export class FranchiseeTypesSuccessAction {
    static readonly type = '[FranchiseeTypes API] GetFranchiseeTypes Success';

    constructor(public payload: { list: PaginationResult<FranchiseeTypesListItem>, pagination: PaginationRequest, totalAmount: number, totalPaidAmount: number }) { }
}

export class FranchiseeTypesFailAction {
    static readonly type = '[FranchiseeTypes API] GetFranchiseeTypes Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeTypesResetAction {
    static readonly type = '[FranchiseeTypes Page] GetFranchiseeTypes Reset';
}

export class FranchiseeTypesSetFilterAction {
    static readonly type = '[FranchiseeTypes API] SetFilter';

    constructor(public payload: FranchiseeTypeListGetFilterData) { }
}
