import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AcceptancesService } from '../../services/business/acceptances.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { AcceptanceDeleteAction, AcceptanceDeleteFailAction, AcceptanceDeleteResetAction, AcceptanceDeleteSuccessAction } from './acceptance-delete.actions';

export interface AcceptanceDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<AcceptanceDeleteStateModel>({
    name: 'acceptanceDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class AcceptanceDeleteState {
    constructor(private acceptancesService: AcceptancesService) { }

    @Action(AcceptanceDeleteAction) async acceptanceDelete(ctx: StateContext<AcceptanceDeleteStateModel>, action: AcceptanceDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.acceptancesService.deleteAcceptance(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new AcceptanceDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new AcceptanceDeleteFailAction(error)), 0));
    }

    @Action(AcceptanceDeleteSuccessAction) acceptanceDeleteSuccess(ctx: StateContext<AcceptanceDeleteStateModel>, _: AcceptanceDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(AcceptanceDeleteFailAction) acceptanceDeleteFail(ctx: StateContext<AcceptanceDeleteStateModel>, action: AcceptanceDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AcceptanceDeleteResetAction) acceptanceDeleteReset(ctx: StateContext<AcceptanceDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
