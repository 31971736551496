import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantCategoryCreateOrUpdateRequest } from '../../models/business/restaurant-category/restaurant-category-create-or-update-request.model';
import { RestaurantCategory } from '../../models/business/restaurant-category/restaurant-category.model';

export class RestaurantCategoryCreateAction {
    static readonly type = '[RestaurantCategory Page] CreateRestaurantCategory';

    constructor(public payload: RestaurantCategoryCreateOrUpdateRequest) { }
}

export class RestaurantCategoryCreateSuccessAction {
    static readonly type = '[RestaurantCategory API] CreateRestaurantCategory Success';

    constructor(public payload: RestaurantCategory) { }
}

export class RestaurantCategoryCreateFailAction {
    static readonly type = '[RestaurantCategory API] CreateRestaurantCategory Fail';

    constructor(public payload: ErrorObject) { }
}

export class RestaurantCategoryCreateResetAction {
    static readonly type = '[RestaurantCategory Page] CreateRestaurantCategory Reset';
}
