import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class TechnologicalMapDeleteAction {
    static readonly type = '[My TechnologicalMap Page] DeleteTechnologicalMap';

    constructor(public payload: { uid: string }) { }
}

export class TechnologicalMapDeleteSuccessAction {
    static readonly type = '[My TechnologicalMaps API] DeleteTechnologicalMap Success';

    constructor(public payload: { uid: string }) { }
}

export class TechnologicalMapDeleteFailAction {
    static readonly type = '[My TechnologicalMaps API] DeleteTechnologicalMap Fail';

    constructor(public payload: ErrorObject) { }
}

export class TechnologicalMapDeleteResetAction {
    static readonly type = '[My TechnologicalMap Page] DeleteTechnologicalMap Reset';
}
