import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { AcceptanceCreateOrUpdateRequest } from '../../models/business/acceptances/acceptance-create-or-update-request.model';

export class AcceptanceUpdateAction {
    static readonly type = '[Booking Page] UpdateAcceptance';

    constructor(public payload: { uid: string, request: AcceptanceCreateOrUpdateRequest }) { }
}

export class AcceptanceUpdateSuccessAction {
    static readonly type = '[Acceptance API] UpdateAcceptance Success';

    constructor(public payload: { uid: string, request: AcceptanceCreateOrUpdateRequest }) { }
}

export class AcceptanceUpdateFailAction {
    static readonly type = '[Acceptance API] UpdateAcceptance Fail';

    constructor(public payload: ErrorObject) { }
}

export class AcceptanceUpdateResetAction {
    static readonly type = '[Booking Page] UpdateAcceptance Reset';
}
