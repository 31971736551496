import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ReservationsService } from '../../services/business/reservations.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Reservation } from '../../models/business/reservations/reservation.model';

import { ReservationCreateAction, ReservationCreateFailAction, ReservationCreateResetAction, ReservationCreateSuccessAction } from './reservation-create.actions';

export interface ReservationCreateStateModel {
    pending: boolean;
    created: Reservation;
    error: ErrorObject;
}

@State<ReservationCreateStateModel>({
    name: 'reservationCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class ReservationCreateState {
    constructor(private reservationsService: ReservationsService) { }

    @Action(ReservationCreateAction) async reservationCreate(ctx: StateContext<ReservationCreateStateModel>, action: ReservationCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.reservationsService.createReservation(action.payload)
            .then(res => setTimeout(() => ctx.dispatch(new ReservationCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ReservationCreateFailAction(err)), 0));
    }

    @Action(ReservationCreateSuccessAction) reservationCreateSuccess(ctx: StateContext<ReservationCreateStateModel>, action: ReservationCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ReservationCreateFailAction) reservationCreateFail(ctx: StateContext<ReservationCreateStateModel>, action: ReservationCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ReservationCreateResetAction) reservationCreateReset(ctx: StateContext<ReservationCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
