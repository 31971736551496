import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { InventoriesService } from '../../services/business/inventories.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { InventoryCreateOrUpdateRequest } from '../../models/business/inventories/inventory-create-or-update-request.model';

import { InventoryUpdateAction, InventoryUpdateFailAction, InventoryUpdateResetAction, InventoryUpdateSuccessAction } from './inventory-update.actions';

export interface InventoryUpdateStateModel {
    pending: boolean;
    updated: InventoryCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<InventoryUpdateStateModel>({
    name: 'inventoryUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class InventoryUpdateState {
    constructor(private inventoriesService: InventoriesService) { }

    @Action(InventoryUpdateAction) async inventoryUpdate(ctx: StateContext<InventoryUpdateStateModel>, action: InventoryUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.inventoriesService.updateInventory(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new InventoryUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new InventoryUpdateFailAction(err)), 0));
    }

    @Action(InventoryUpdateSuccessAction) inventoryUpdateSuccess(ctx: StateContext<InventoryUpdateStateModel>, action: InventoryUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(InventoryUpdateFailAction) inventoryUpdateFail(ctx: StateContext<InventoryUpdateStateModel>, action: InventoryUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(InventoryUpdateResetAction) inventoryUpdateReset(ctx: StateContext<InventoryUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
