// tslint:disable:no-namespace
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ExgCultureEnum } from '../../../../burns-ui-framework/shared/models/common/exg-culture.model';
import { Timezone } from '../../models/business/timezones/timezone.model';

export class TimezonesAction {
    static readonly type = '[Timezones Page] GetTimezones';

    constructor(public payload: { culture: ExgCultureEnum }) { }
}

export class TimezonesSuccessAction {
    static readonly type = '[Timezones API] GetTimezones Success';

    constructor(public payload: Timezone[]) { }
}

export class TimezonesFailAction {
    static readonly type = '[Timezones API] GetTimezones Fail';

    constructor(public payload: ErrorObject) { }
}

export class TimezonesResetAction {
    static readonly type = '[Contacts Page] GetTimezones Reset';
}
