<ng-container *ngIf="feedItem.type === feedTypeEnum.Note">
    <bvc-clients-activity-note [feedItem]="feedItem"
                               [error]="error"
                               [companyUid]="companyUid"
                               (noteUpdate)="onNoteUpdate($event)"
                               (noteDelete)="onNoteDelete($event)"></bvc-clients-activity-note>
</ng-container>
<ng-container *ngIf="feedItem.type === feedTypeEnum.Task">
    <bvc-clients-activity-task [feedItem]="feedItem"
                               [error]="error"
                               [companyUid]="companyUid"
                               [users]="users"
                               (taskUpdate)="onTaskUpdate($event)"
                               (taskDelete)="onTaskDelete($event)"></bvc-clients-activity-task>
</ng-container>
<ng-container *ngIf="feedItem.type === feedTypeEnum.Email">
    <bvc-clients-activity-task-email [feedItem]="feedItem"
                                     [error]="error"
                                     [companyUid]="companyUid"
                                     (taskUpdate)="onTaskUpdate($event)"
                                     (taskDelete)="onTaskDelete($event)"></bvc-clients-activity-task-email>
</ng-container>
<ng-container *ngIf="feedItem.type === feedTypeEnum.Call">
    <bvc-clients-activity-task-call [feedItem]="feedItem"
                                    [error]="error"></bvc-clients-activity-task-call>
</ng-container>
<ng-container *ngIf="feedItem.type === feedTypeEnum.Meeting">
    <bvc-clients-activity-task-meeting [feedItem]="feedItem"
                                       [error]="error"></bvc-clients-activity-task-meeting>
</ng-container>