import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { AcceptanceListGetFilterData } from '../../models/filters/acceptance-list-get-filter.model';
import { AcceptanceListItem } from '../../models/business/acceptances/acceptance-list-item.model';

export class AcceptancesAction {
    static readonly type = '[Acceptancess Page] GetAcceptances';

    constructor(public payload: PaginationRequest) { }
}

export class AcceptancesSuccessAction {
    static readonly type = '[Acceptances API] GetAcceptances Success';

    constructor(public payload: { list: PaginationResult<AcceptanceListItem>, pagination: PaginationRequest, totalAmount: number, totalPaidAmount: number }) { }
}

export class AcceptancesFailAction {
    static readonly type = '[Acceptances API] GetAcceptances Fail';

    constructor(public payload: ErrorObject) { }
}

export class AcceptancesResetAction {
    static readonly type = '[Acceptances Page] GetAcceptances Reset';
}

export class AcceptancesSetFilterAction {
    static readonly type = '[Acceptances API] SetFilter';

    constructor(public payload: AcceptanceListGetFilterData) { }
}
