import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PriceTypeListGetFilterData } from '../../models/filters/price-type-list-get-filter.model';
import { PriceType } from '../../models/business/pricelist/price-type.model';

export class PriceTypeListAction {
    static readonly type = '[PriceTypeList Page] GetPriceTypeList';

    constructor(public payload: PriceTypeListGetFilterData) { }
}

export class PriceTypeListSuccessAction {
    static readonly type = '[PriceTypeList API] GetPriceTypeList Success';

    constructor(public payload: PaginationResult<PriceType>) { }
}

export class PriceTypeListFailAction {
    static readonly type = '[PriceTypeList API] GetPriceTypeList Fail';

    constructor(public payload: ErrorObject) { }
}

export class PriceTypeListResetAction {
    static readonly type = '[PriceTypeList Page] GetPriceTypeList Reset';
}
