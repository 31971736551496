import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ManagerAssistantsService } from '../../services/business/manager-assistants.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { ManagerAssistantCreateSuccessAction } from './manager-assistant-create.actions';
import { ManagerAssistantDeleteSuccessAction } from './manager-assistant-delete.actions';
import { ManagerAssistantsAction, ManagerAssistantsFailAction, ManagerAssistantsResetAction, ManagerAssistantsSetFilterAction, ManagerAssistantsSuccessAction } from './manager-assistants.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ManagerAssistant } from '../../models/business/manager-assistants/manager-assistant.model';
import { ManagerAssistantsListFilter, ManagerAssistantsListFilterData } from '../../models/filters/manager-assistants-list-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface ManagerAssistantsStateModel extends PaginationStateModel<ManagerAssistant> {
    uids: string[];
    entities: { [uid: string]: ManagerAssistant };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ManagerAssistantsListFilterData;
    total: number;
}

@State<ManagerAssistantsStateModel>({
    name: 'managerAssistant',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class ManagerAssistantsState extends PaginationStateBase {
    constructor(private managerAssistantsService: ManagerAssistantsService) {
        super();
    }

    @Action([ManagerAssistantsAction]) contragentsGet(ctx: StateContext<ManagerAssistantsStateModel>, action: ManagerAssistantsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new ManagerAssistantsListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.contragentsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.managerAssistantsService.getAssistantList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ManagerAssistantsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ManagerAssistantsFailAction(err)), 0));
    }

    @Action(ManagerAssistantsSuccessAction) contragentsGetSuccess(ctx: StateContext<ManagerAssistantsStateModel>, action: ManagerAssistantsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.contragentsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(ManagerAssistantsFailAction) contragentsGetFail(ctx: StateContext<ManagerAssistantsStateModel>, action: ManagerAssistantsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ManagerAssistantsResetAction) contragentsGetReset(ctx: StateContext<ManagerAssistantsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ManagerAssistantsSetFilterAction) usersSetFilter(ctx: StateContext<ManagerAssistantsStateModel>, action: ManagerAssistantsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ManagerAssistantsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(ManagerAssistantCreateSuccessAction) contragentCreateSuccess(ctx: StateContext<ManagerAssistantsStateModel>, _: ManagerAssistantCreateSuccessAction) {
        setTimeout(() => ctx.dispatch(new ManagerAssistantsAction(PaginationRequest.ReloadCurrentList)), 0);
    }

    @Action(ManagerAssistantDeleteSuccessAction) contragentDeleteSuccess(ctx: StateContext<ManagerAssistantsStateModel>, action: ManagerAssistantDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
