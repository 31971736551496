import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ContragentsSearchAction, ContragentsSearchResetAction } from './contragents-search.actions';

import { ContragentsListFilterData } from '../../models/filters/contragents-list-filter.model';

@Injectable({
    providedIn: 'root'
})
export class ContragentsSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('ContragentsSearchDispatchers');
    }

    public dispatchContragentsSearchAction(request: ContragentsListFilterData) {
        this.store.dispatch(new ContragentsSearchAction(request));
    }

    public dispatchContragentsSearchResetAction() {
        this.store.dispatch(new ContragentsSearchResetAction());
    }
}
