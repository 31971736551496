import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseInvoicesService } from '../../services/business/purchase-invoices.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseInvoiceCreateOrUpdateRequest } from '../../models/business/purchase-invoices/purchase-invoice-create-or-update-request.model';

import { PurchaseInvoiceUpdateAction, PurchaseInvoiceUpdateFailAction, PurchaseInvoiceUpdateResetAction, PurchaseInvoiceUpdateSuccessAction } from './purchase-invoice-update.actions';

export interface PurchaseInvoiceUpdateStateModel {
    pending: boolean;
    updated: PurchaseInvoiceCreateOrUpdateRequest;
    error: ErrorObject;
}

@State<PurchaseInvoiceUpdateStateModel>({
    name: 'purchaseInvoiceUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class PurchaseInvoiceUpdateState {
    constructor(private purchaseInvoicesService: PurchaseInvoicesService) { }

    @Action(PurchaseInvoiceUpdateAction) async purchaseInvoiceUpdate(ctx: StateContext<PurchaseInvoiceUpdateStateModel>, action: PurchaseInvoiceUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.purchaseInvoicesService.updatePurchaseInvoice(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new PurchaseInvoiceUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseInvoiceUpdateFailAction(err)), 0));
    }

    @Action(PurchaseInvoiceUpdateSuccessAction) purchaseInvoiceUpdateSuccess(ctx: StateContext<PurchaseInvoiceUpdateStateModel>, action: PurchaseInvoiceUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(PurchaseInvoiceUpdateFailAction) purchaseInvoiceUpdateFail(ctx: StateContext<PurchaseInvoiceUpdateStateModel>, action: PurchaseInvoiceUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(PurchaseInvoiceUpdateResetAction) purchaseInvoiceUpdateReset(ctx: StateContext<PurchaseInvoiceUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
