<form [formGroup]="mainForm" class="form" [autocomplete]="true" (submit)="$event.preventDefault(); onSubmit()">
    <exg-chips-input class="chips"
                        formControlName="emails"
                        placeholder="{{'To whom' | translate}}"
                        [maxlength]="255"
                        [controlsToValidate]="[emails]"
                        [required]="true"
                        [validate]="validate$ | async"></exg-chips-input>

    <exg-textbox class="text"
                 formControlName="name"
                 [maxlength]="255"
                 placeholder="{{'Letter subject' | translate}}"
                 [controlsToValidate]="[name]"
                 [validate]="validate$ | async"></exg-textbox>

    <exg-textbox class="text"
                 formControlName="description"
                 [maxlength]="3000"
                 [isMultiLine]="true"
                 [multilineMinRows]="4"
                 [multilineMaxRows]="4"
                 placeholder="{{'Text' | translate}}"
                 [controlsToValidate]="[description]"
                 [validate]="validate$ | async"></exg-textbox>

    <div class="datepicker_timepicker">
        <exg-datepicker class="datepicker"
                        [required]="true"
                        formControlName="dueDate"
                        placeholder="{{'Due date' | translate}}"
                        [controlsToValidate]="[dueDate]"
                        [validate]="validate$ | async"></exg-datepicker>

        <exg-timepicker class="timepicker"
                        formControlName="dueTime"
                        placeholder="{{'Time' | translate}}"
                        [controlsToValidate]="[dueTime]"
                        [validate]="validate$ | async"></exg-timepicker>
    </div>

    <div class="files">
        <bvc-clients-activity-document class="document"
                                       *ngFor="let document of noteFiles$ | async; trackBy: trackByDocuments"
                                       [document]="document"
                                       (deleteFile)="onDeleteDocument(document.uid)"></bvc-clients-activity-document>
 
        <bvc-clients-activity-document class="document"
                                       *ngFor="let documentDownloadable of filesToAdd; let i = index;"
                                       [documentDownloadable]="documentDownloadable"
                                       (deleteFile)="onDeleteDocumentDownloadable(i)"></bvc-clients-activity-document>
    </div>
    <exg-checkbox class="checkbox" [value]="sendWithoutConfirmation" [slideMode]="false" (valueChange)="sendWithoutConfirmation=$event">
        <span class="menu-txt">{{'Send without confirmation' | translate}}</span>
    </exg-checkbox>

    <exg-form-error [error]="error"></exg-form-error>

    <exg-files-drop class="files-drop" (uploadedFiles)="onUploadedFiles($event)"></exg-files-drop>

    <div class="btn-box">
        <exg-button class="cancel" styleType="outlined" (btnClick)="onCancelClick()">{{ 'Cancel' | translate }}</exg-button>
        <exg-button class="submit" type="submit">{{ (task?.uid ? 'Save' : 'Add') | translate }}</exg-button>
    </div>
</form>