import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Requisite } from '../../models/business/contragents/requisites.model';

export class RequisiteAction {
    static readonly type = '[Requisite Page] Requisite';

    constructor(public payload: { uid: string, contragenUid: string }) { }
}

export class RequisiteSuccessAction {
    static readonly type = '[Requisite API] Requisite Success';

    constructor(public payload: Requisite) { }
}

export class RequisiteFailAction {
    static readonly type = '[Requisite API] Requisite Fail';

    constructor(public payload: ErrorObject) { }
}

export class RequisiteResetAction {
    static readonly type = '[Requisite Page] Requisite Reset';
}
