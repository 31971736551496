import { BrandCreateOrUpdateRequest } from '../../models/business/brands/brand-create-or-update.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class BrandUpdateAction {
    static readonly type = '[Brand Page] UpdateBrand';

    constructor(public payload: { uid: string, request: BrandCreateOrUpdateRequest }) { }
}

export class BrandUpdateSuccessAction {
    static readonly type = '[Brand API] UpdateBrand Success';

    constructor(public payload: { uid: string, request: BrandCreateOrUpdateRequest }) { }
}

export class BrandUpdateFailAction {
    static readonly type = '[Brand API] UpdateBrand Fail';

    constructor(public payload: ErrorObject) { }
}

export class BrandUpdateResetAction {
    static readonly type = '[Brand Page] UpdateBrand Reset';
}
