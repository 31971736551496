
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class CustomerDeleteAction {
    static readonly type = '[My Customer Page] DeleteCustomer';

    constructor(public payload: { uid: string }) { }
}

export class CustomerDeleteSuccessAction {
    static readonly type = '[My Customers API] DeleteCustomer Success';

    constructor(public payload: { uid: string }) { }
}

export class CustomerDeleteFailAction {
    static readonly type = '[My Customers API] DeleteCustomer Fail';

    constructor(public payload: ErrorObject) { }
}

export class CustomerDeleteResetAction {
    static readonly type = '[My Customer Page] DeleteCustomer Reset';
}
