import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SalesPlanService } from '../../services/business/sales-plan.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Plan } from '../../models/business/sales-plan/plan.model';

import { SalesPlanAction, SalesPlanFailAction, SalesPlanResetAction, SalesPlanSuccessAction } from './sales-plan.actions';

export interface SalesPlanStateModel {
    pending: boolean;
    entity: Plan;
    error: ErrorObject;
}

@State<SalesPlanStateModel>({
    name: 'salesPlan',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class SalesPlanState {
    constructor(private salesPlanService: SalesPlanService) { }

    @Action(SalesPlanAction) async salesPlan(ctx: StateContext<SalesPlanStateModel>, action: SalesPlanAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.salesPlanService.getSalesPlan(action.payload.year, action.payload.userUid)
            .then(res => setTimeout(() => ctx.dispatch(new SalesPlanSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SalesPlanFailAction(err)), 0));
    }

    @Action(SalesPlanSuccessAction) salesPlanSuccess(ctx: StateContext<SalesPlanStateModel>, action: SalesPlanSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(SalesPlanFailAction) salesPlanFail(ctx: StateContext<SalesPlanStateModel>, action: SalesPlanFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(SalesPlanResetAction) salesPlanReset(ctx: StateContext<SalesPlanStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
