import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { DCRCreateOrUpdateRequest } from '../models/business/dcr/dcr-create-or-update-request.model';
import { DCRItem } from '../models/business/dcr/dcr-item.model';
import { DcrListGetFilter } from '../models/filters/dcr-list-get-filter.model';
import { DCRListItem } from '../models/business/dcr/dcr-list-item.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class DecompositionsService extends BaseSingletonService {

    private settings: {
        service: {
            getDecomposition: string;
            getDecompositions: string;
            createDecomposition: string;
            updateDecomposition: string;
            deleteDecomposition: string;
        }
    };

    constructor(private http: HttpService) {
        super('DecompositionsService');
        this.settings = {
            service: {
                getDecomposition: '/restaurants-aggregation/v1/decompositions/{0}',
                getDecompositions: '/restaurants-aggregation/v1/decompositions?organizationUid={0}&warehouseUid={1}&status={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}',
                createDecomposition: '/restaurants-aggregation/v1/decompositions',
                updateDecomposition: '/restaurants/v1/decompositions/{0}',
                deleteDecomposition: '/restaurants/v1/decompositions/{0}',
            }
        };
    }

    public async getDecomposition(uid: string): Promise<DCRItem> {
        return this.http.get<{ data: DCRItem }>(String.format(this.settings.service.getDecomposition, uid))
            .then(res => res.data);
    }

    public async getDecompositions(filter: DcrListGetFilter): Promise<PaginationResult<DCRListItem>> {
        return this.http.get<PaginationResult<DCRListItem>>(String.format(this.settings.service.getDecompositions, filter.organizationUid, filter.warehouseUid, filter.status, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createDecomposition(request: DCRCreateOrUpdateRequest): Promise<DCRItem> {
        return this.http.post<{ data: DCRItem }>(String.format(this.settings.service.createDecomposition), request)
            .then(res => res.data);
    }

    public async updateDecomposition(uid: string, request: DCRCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateDecomposition, uid), request);
    }

    public async deleteDecomposition(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteDecomposition, uid));
    }
}
