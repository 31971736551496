import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContragentsService } from '../../services/business/contragents.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ContragentsSearchAction, ContragentsSearchFailAction, ContragentsSearchResetAction, ContragentsSearchSuccessAction } from './contragents-search.actions';

import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { ContragentStatus } from '../../models/business/contragents/contragent-status.enum';
import { ContragentsListFilter } from '../../models/filters/contragents-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface ContragentsSearchStateModel extends PaginationStateModel<ContragentListItem> {
    uids: string[];
    entities: { [uid: string]: ContragentListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<ContragentsSearchStateModel>({
    name: 'contragentsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class ContragentsSearchState extends PaginationStateBase {
    constructor(private contragentsService: ContragentsService) {
        super();
    }

    @Action([ContragentsSearchAction]) contragentsGet(ctx: StateContext<ContragentsSearchStateModel>, action: ContragentsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const statuses = [ContragentStatus.Active, ContragentStatus.Liquidating, ContragentStatus.Reorganizing, ContragentStatus.Unknown];
        const filter = new ContragentsListFilter({ pageIndex: state.index, filterData: { ...action.payload, statuses } });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.contragentsService.getContragentList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ContragentsSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContragentsSearchFailAction(err)), 0));
    }

    @Action(ContragentsSearchSuccessAction) contragentsGetSuccess(ctx: StateContext<ContragentsSearchStateModel>, action: ContragentsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.contragentsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ContragentsSearchFailAction) contragentsGetFail(ctx: StateContext<ContragentsSearchStateModel>, action: ContragentsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ContragentsSearchResetAction) contragentsGetReset(ctx: StateContext<ContragentsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
