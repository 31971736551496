import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { PosListFilterData } from '../../models/filters/pos-list-filter.model';
import { PosLookupItem } from '../../models/business/pos/pos-lookup-item.model';

export class PosCompanySearchAction {
    static readonly type = '[PosCompanySearch Page] GetPosCompanySearch';

    constructor(public payload: PosListFilterData) { }
}

export class PosCompanySearchSuccessAction {
    static readonly type = '[PosCompanySearch API] GetPosCompanySearch Success';

    constructor(public payload: PaginationResult<PosLookupItem>) { }
}

export class PosCompanySearchFailAction {
    static readonly type = '[PosCompanySearch API] GetPosCompanySearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class PosCompanySearchResetAction {
    static readonly type = '[PosCompanySearch Page] GetPosCompanySearch Reset';
}
