import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';
import { RestaurantCategoryListGetFilter } from '../../models/filters/restaurant-category-list-get-filter.model';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';
import { RestaurantCategoryListItem } from '../../models/business/restaurant-category/restaurant-category-list-item.model';
  
@Injectable({
    providedIn: 'root'
})
export class RestaurantCategoryService extends BaseSingletonService {

    private settings: {
        service: {
            getRestaurantCategories: string;
            getRestaurantCategoriesList: string;
            createRestaurantCategories: string;
            updateRestaurantCategories: string;
            deleteRestaurantCategories: string;
        }
    };

    constructor(private http: HttpService) {
        super('RestaurantCategoryService');
        this.settings = {
            service: {
                getRestaurantCategories: '/corporation-aggregation/v1/catalog/categories/{0}',
                getRestaurantCategoriesList: '/corporation-aggregation/v1/catalog/categories?&organizationPosUid={0}&parentUid={1}&isPublic={2}&isArchived={3}',
                createRestaurantCategories: '/corporation-aggregation/v1/catalog/categories',
                updateRestaurantCategories: '/corporation-aggregation/v1/catalog/categories/{0}',
                deleteRestaurantCategories: '/corporation-aggregation/v1/catalog/categories/{0}',
            }
        };
    }

    public async getRestaurantCategoryList(filter: RestaurantCategoryListGetFilter): Promise<PaginationResult<RestaurantCategoryListItem>> {
        return this.http.get<PaginationResult<RestaurantCategoryListItem>>(String.format(
            this.settings.service.getRestaurantCategoriesList,
            filter.organizationPosUid,
            filter.parentUid,
            filter.isPublic,
            filter.isArchived,
            filter.pageIndex,
            filter.pageSize,
            filter.term,
        ))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }


    public async deleteRestaurantCategory(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRestaurantCategories, uid));
    }

}
