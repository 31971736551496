import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { YandexService } from '../services/yandex.service';

import { PlacesForwardAction, PlacesForwardFailAction, PlacesForwardResetAction, PlacesForwardSuccessAction } from './places-forward.actions';

import { ErrorObject } from '../../shared/models/common/error-object.model';
import { YandexPlaceComponent } from '../models/yandex-place-component.model';

export interface PlacesForwardStateModel {
    pending: boolean;
    entities: YandexPlaceComponent[];
    retrieved: boolean;
    error: ErrorObject;
}

@State<PlacesForwardStateModel>({
    name: 'YandexPlacesForward',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class PlacesForwardState {
    constructor(private geocodingService: YandexService) { }

    @Action([PlacesForwardAction]) async placesForwardGet(ctx: StateContext<PlacesForwardStateModel>, action: PlacesForwardAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.geocodingService.getPlaces(null, action.payload.uri)
            .then(resp => setTimeout(() => ctx.dispatch(new PlacesForwardSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PlacesForwardFailAction(err)), 0));
    }

    @Action(PlacesForwardSuccessAction) placesForwardGetSuccess(ctx: StateContext<PlacesForwardStateModel>, action: PlacesForwardSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(PlacesForwardFailAction) placesForwardGetFail(ctx: StateContext<PlacesForwardStateModel>, action: PlacesForwardFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PlacesForwardResetAction) placesForwardGetReset(ctx: StateContext<PlacesForwardStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
