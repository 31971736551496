import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BvcFormHeaderEnum } from './bvc-form-header.enum';
import { BvcFormHeaderMenuEnum } from './bvc-form-header-menu.enum';

@Component({
    selector: 'bvc-form-header',
    templateUrl: './bvc-form-header.component.html',
    styleUrls: ['./bvc-form-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcFormHeaderComponent {

    @Output() readonly menuClicked = new EventEmitter<BvcFormHeaderEnum>();
    @Output() readonly menuCreateClicked = new EventEmitter<number>();
    @Output() readonly onAdd = new EventEmitter();
    @Input() showActionButton = true;
    @Input() showMenuButton = true;
    @Input() isAddButtonEnabled = true;
    @Input() createMenuItems: Array<{id: string, title: string}>;

    public menuItems = [
        { id: BvcFormHeaderEnum.Download, title: 'Download', icon: 'export' },
        { id: BvcFormHeaderEnum.Print, title: 'Print', icon: 'print' },
        { id: BvcFormHeaderEnum.Send, title: 'Send', icon: 'mail' }
    ];

    public onMenuItemClick($event): void {
        this.menuClicked.emit($event.id);
    }

    public onCreateMenuItemClick($event): void {
        this.menuCreateClicked.emit($event.id);
    }

    public onAddButton() {
        this.onAdd.emit();
    }

}
