import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { Contract } from '../../models/business/contract/contract.model';
import { ContractsListFilterData } from '../../models/filters/contracts-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContractsState, ContractsStateModel } from './contracts.reducer';

@Injectable({
    providedIn: 'root'
})
export class ContractsSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([ContractsState], (state: ContractsStateModel) => state.pending);

    static readonly getContracts = createSelector([ContractsState], (state: ContractsStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([ContractsState], (state: ContractsStateModel) => state.retrieved);

    static readonly getError = createSelector([ContractsState], (state: ContractsStateModel) => state.error);

    static readonly getAllContractsLoaded = createSelector([ContractsState], (state: ContractsStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([ContractsState], (state: ContractsStateModel) => state.filter);

    static readonly getTotal = createSelector([ContractsState], (state: ContractsStateModel) => state.total);

    @Select(ContractsSelectors.getPending) pending$: Observable<boolean>;

    @Select(ContractsSelectors.getContracts) contracts$: Observable<Contract[]>;

    @Select(ContractsSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(ContractsSelectors.getError) error$: Observable<ErrorObject>;

    @Select(ContractsSelectors.getAllContractsLoaded) allContractsLoaded$: Observable<boolean>;

    @Select(ContractsSelectors.getFilter) filter$: Observable<ContractsListFilterData>;

    @Select(ContractsSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('ContractsSelectors');
    }
}
