import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { UserForTyping } from '../../models/business/user/user-for-typing.model';

import { UserSearchState, UserSearchStateModel } from './users-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class UserSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([UserSearchState], (state: UserSearchStateModel) => state.pending);

    static readonly getUserSearch = createSelector([UserSearchState], (state: UserSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([UserSearchState], (state: UserSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([UserSearchState], (state: UserSearchStateModel) => state.error);

    static readonly getAllUserSearchLoaded = createSelector([UserSearchState], (state: UserSearchStateModel) => state.allItemsLoaded);

    @Select(UserSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(UserSearchSelectors.getUserSearch) users$: Observable<UserForTyping[]>;

    @Select(UserSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(UserSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(UserSearchSelectors.getAllUserSearchLoaded) allUserSearchLoaded$: Observable<boolean>;

    constructor() {
        super('UserSearchSelectors');
    }
}
