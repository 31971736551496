import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseReturn } from '../../models/business/purchase-returns/purchase-return.model';

export class PurchaseReturnGetAction {
    static readonly type = '[PurchaseReturn Page] GetPurchaseReturn Get';

    constructor(public payload: string) { }
}

export class PurchaseReturnGetSuccessAction {
    static readonly type = '[PurchaseReturn API] GetPurchaseReturn Success';

    constructor(public payload: PurchaseReturn) { }
}

export class PurchaseReturnFailAction {
    static readonly type = '[PurchaseReturn API] GetPurchaseReturn Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseReturnResetAction {
    static readonly type = '[PurchaseReturn Page] GetPurchaseReturn Reset';
}
