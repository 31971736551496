import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class ErrorListGetFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public orderUid?: string;
    public franchiseeUid?: string;
    public organizationUid?: string;
    public organizationPosUid?: string;
    public dateFrom?: number;
    public dateTo?: number;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: ErrorListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'CreateDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Desc;
        this.orderUid = (args && args.filterData) ? args.filterData.orderUid : null;
        this.franchiseeUid = (args && args.filterData) ? args.filterData.franchiseeUid : null;
        this.organizationUid = (args && args.filterData) ? args.filterData.organizationUid : null;
        this.organizationPosUid = (args && args.filterData) ? args.filterData.organizationPosUid : null;
        this.dateFrom = (args && args.filterData) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData) ? args.filterData.dateTo : null;
    }
}

export class ErrorListGetFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public pageSize?: number;
    public orderUid?: string;
    public franchiseeUid?: string;
    public organizationUid?: string;
    public organizationPosUid?: string;
    public dateFrom?: number;
    public dateTo?: number;

}
