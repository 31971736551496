
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class TaskDeleteAction {
    static readonly type = '[My Task Page] DeleteTask';

    constructor(public payload: { uid: string }) { }
}

export class TaskDeleteSuccessAction {
    static readonly type = '[My Tasks API] DeleteTask Success';

    constructor(public payload: { uid: string }) { }
}

export class TaskDeleteFailAction {
    static readonly type = '[My Tasks API] DeleteTask Fail';

    constructor(public payload: ErrorObject) { }
}

export class TaskDeleteResetAction {
    static readonly type = '[My Task Page] DeleteTask Reset';
}
