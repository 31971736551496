import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseOrder } from '../../models/business/purchase-orders/purchase-order.model';

export class PurchaseOrderGetAction {
    static readonly type = '[PurchaseOrder Page] GetPurchaseOrder Get';

    constructor(public payload: string) { }
}

export class PurchaseOrderGetSuccessAction {
    static readonly type = '[PurchaseOrder API] GetPurchaseOrder Success';

    constructor(public payload: PurchaseOrder) { }
}

export class PurchaseOrderFailAction {
    static readonly type = '[PurchaseOrder API] GetPurchaseOrder Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseOrderResetAction {
    static readonly type = '[PurchaseOrder Page] GetPurchaseOrder Reset';
}
