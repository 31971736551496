import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { ColorString } from 'highcharts';

import { DialogService } from '../../../../../burns-ui-framework/shared/services/common/dialog.service';
import { ExgTranslateService } from '../../../../../burns-ui-framework/shared/services/common/exg-translate.service';
import { SnackbarService } from '../../../../../burns-ui-framework/shared/services/common/snackbar.service';

import { ExgDatePipe } from '../../../../../burns-ui-framework/shared/pipes/exg-date/exg-date.pipe';

import { CompanyFeedDispatchers } from '../../../store/companies/company-feed.dispatchers';
import { CompanyFeedSelectors } from '../../../store/companies/company-feed.selectors';
import { NoteCreateDispatchers } from '../../../store/notes/note-create.dispatchers';
import { NoteCreateSelectors } from '../../../store/notes/note-create.selectors';
import { NoteDeleteDispatchers } from '../../../store/notes/note-delete.dispatchers';
import { NoteDeleteSelectors } from '../../../store/notes/note-delete.selectors';
import { NoteUpdateDocumentsDispatchers } from '../../../store/notes/note-update-documents.dispatchers';
import { NoteUpdateDocumentsSelectors } from '../../../store/notes/note-update-documents.selectors';
import { NoteUpdateDispatchers } from '../../../store/notes/note-update.dispatchers';
import { NoteUpdateSelectors } from '../../../store/notes/note-update.selectors';
import { TaskCreateDispatchers } from '../../../store/tasks/task-create.dispatchers';
import { TaskCreateSelectors } from '../../../store/tasks/task-create.selectors';
import { TaskDeleteDispatchers } from '../../../store/tasks/task-delete.dispatchers';
import { TaskDeleteSelectors } from '../../../store/tasks/task-delete.selectors';
import { TaskUpdateDocumentsDispatchers } from '../../../store/tasks/task-update-documents.dispatchers';
import { TaskUpdateDocumentsSelectors } from '../../../store/tasks/task-update-documents.selectors';
import { TaskUpdateDispatchers } from '../../../store/tasks/task-update.dispatchers';
import { TaskUpdateSelectors } from '../../../store/tasks/task-update.selectors';
import { UserSearchDispatchers } from '../../../store/users/users-search.dispatchers';
import { UserSearchSelectors } from '../../../store/users/users-search.selectors';

import { ClientsActivityType } from './clients-activity-type.enum';
import { DialogResult } from '../../../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { FeedType } from '../../../models/business/feed/feed-type.enum';
import { Feed } from '../../../models/business/feed/feed.model';
import { NoteCreateRequest } from '../../../models/business/notes/note-create-request.model';
import { NoteUpdateRequest } from '../../../models/business/notes/note-update-request.model';
import { PaginationRequest } from '../../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { Utils } from '../../../../../burns-ui-framework/shared/utils/utils';

@Component({
    selector: 'bvc-clients-activity',
    templateUrl: './clients-activity.component.html',
    styleUrls: ['./clients-activity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityComponent implements OnChanges {

    @Input() companyUid: string;
    @Input() contragentUid: string;
    @Input() posUid: string;

    public feed$ = this.companyFeedSelectors.feed$;
    public allOrdersLoaded$ = this.companyFeedSelectors.allCompanyFeedLoaded$;
    public pending$ = this.companyFeedSelectors.pending$;
    public retrieved$ = this.companyFeedSelectors.retrieved$;
    public filter$ = this.companyFeedSelectors.filter$;

    public created$ = this.noteCreateSelectors.created$;
    public createPending$ = this.noteCreateSelectors.pending$;
    public createError$ = this.noteCreateSelectors.error$;

    public updated$ = this.noteUpdateSelectors.updated$;
    public updatePending$ = this.noteUpdateSelectors.pending$;
    public updateError$ = this.noteUpdateSelectors.error$;

    public deleted$ = this.noteDeleteSelectors.deleted$;
    public deletePending$ = this.noteDeleteSelectors.pending$;
    public deleteError$ = this.noteDeleteSelectors.error$;

    public documentsUpdated$ = this.noteUpdateDocumentsSelectors.updated$;
    public documentsUpdatePending$ = this.noteUpdateDocumentsSelectors.pending$;
    public documentsUpdateError$ = this.noteUpdateDocumentsSelectors.error$;

    public taskCreated$ = this.taskCreateSelectors.created$;
    public taskCreatePending$ = this.taskCreateSelectors.pending$;
    public taskCreateError$ = this.taskCreateSelectors.error$;

    public taskUpdated$ = this.taskUpdateSelectors.updated$;
    public taskUpdatePending$ = this.taskUpdateSelectors.pending$;
    public taskUpdateError$ = this.taskUpdateSelectors.error$;

    public taskDeleted$ = this.taskDeleteSelectors.deleted$;
    public taskDeletePending$ = this.taskDeleteSelectors.pending$;
    public taskDeleteError$ = this.taskDeleteSelectors.error$;

    public taskDocumentsUpdated$ = this.taskUpdateDocumentsSelectors.updated$;
    public taskDocumentsUpdatePending$ = this.taskUpdateDocumentsSelectors.pending$;
    public taskDocumentsUpdateError$ = this.taskUpdateDocumentsSelectors.error$;

    public usersSearch$ = this.userSearchSelectors.users$;
    public usersSearchPending$ = this.userSearchSelectors.pending$;

    public statusValue = null;
    public displayData$ = Utils.enumToTranslatedSelectData(FeedType, this.translate);

    public createTab$ = new BehaviorSubject(ClientsActivityType.Note);
    public unsubscribe$ = new Subject();
    public group = {};
    public clientsActivityTypeEnum = ClientsActivityType;

    private exgDatePipe = new ExgDatePipe(this.translate);

    constructor(private noteUpdateDispatchers: NoteUpdateDispatchers,
                private noteUpdateSelectors: NoteUpdateSelectors,
                private noteDeleteDispatchers: NoteDeleteDispatchers,
                private noteDeleteSelectors: NoteDeleteSelectors,
                private noteCreateDispatchers: NoteCreateDispatchers,
                private noteCreateSelectors: NoteCreateSelectors,
                private noteUpdateDocumentsDispatchers: NoteUpdateDocumentsDispatchers,
                private noteUpdateDocumentsSelectors: NoteUpdateDocumentsSelectors,
                private companyFeedDispatchers: CompanyFeedDispatchers,
                private companyFeedSelectors: CompanyFeedSelectors,
                private userSearchDispatchers: UserSearchDispatchers,
                private userSearchSelectors: UserSearchSelectors,
                private taskCreateDispatchers: TaskCreateDispatchers,
                private taskCreateSelectors: TaskCreateSelectors,
                private taskUpdateDispatchers: TaskUpdateDispatchers,
                private taskUpdateSelectors: TaskUpdateSelectors,
                private taskDeleteDispatchers: TaskDeleteDispatchers,
                private taskDeleteSelectors: TaskDeleteSelectors,
                private taskUpdateDocumentsDispatchers: TaskUpdateDocumentsDispatchers,
                private taskUpdateDocumentsSelectors: TaskUpdateDocumentsSelectors,
                private dialogService: DialogService,
                private snackbar: SnackbarService,
                private translate: ExgTranslateService) {
        this.dispatchUserSearch();
        this.updated$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe(() => {
            this.noteUpdateDispatchers.dispatchNoteUpdateResetAction();
            this.snackbar.showInfo('Note successfully updated.');
        });

        this.deleted$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe(() => {
            this.noteDeleteDispatchers.dispatchNoteDeleteResetAction();
            this.snackbar.showInfo('Note successfully deleted.');
        });

        this.documentsUpdated$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x)).subscribe(() => {
            this.noteUpdateDocumentsDispatchers.dispatchNoteUpdateDocumentsResetAction();
        });

        this.feed$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x && !!x[0])).subscribe((feed) => {
            let groupDate = '';
            feed.forEach((feedItem) => {
                const truncated = this.exgDatePipe.transform(feedItem.createDate, 'shortDate');
                if (groupDate !== truncated) {
                    groupDate = truncated;
                    this.group[feedItem.uid] = true;
                }
            });
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.companyUid && this.companyUid) {
            this.companyFeedDispatchers.dispatchCompanyFeedSetFilterAction({ companyUid: this.companyUid });
        }
    }

    public onLoadMore(_) {
        this.companyFeedDispatchers.dispatchCompanyFeedAction(PaginationRequest.LoadMore);
    }

    public onNoteCreate($event: { request: NoteCreateRequest, documentsToAdd: File[] }) {
        this.noteCreateDispatchers.dispatchNoteCreateAction($event.request);
        this.created$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x), first()).subscribe((note) => {
            this.noteCreateDispatchers.dispatchNoteCreateResetAction();
            this.snackbar.showInfo('Note successfully created.');
            this.companyFeedDispatchers.dispatchCompanyFeedAction(PaginationRequest.ReloadCurrentList);
            if ($event.documentsToAdd && $event.documentsToAdd.length > 0) {
                this.noteUpdateDocumentsDispatchers.dispatchNoteUpdateDocumentsAction(note.uid, $event.documentsToAdd, null);
            }
        });
    }

    public onNoteUpdate($event: { uid: string, request: NoteUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }) {
        this.noteUpdateDispatchers.dispatchNoteUpdateAction($event.uid, $event.request);

        if (($event.documentsToAdd && $event.documentsToAdd.length > 0) || ($event.documentsToRemove && $event.documentsToRemove.length > 0)) {
            this.noteUpdateDocumentsDispatchers.dispatchNoteUpdateDocumentsAction($event.uid, $event.documentsToAdd, $event.documentsToRemove);
        }
    }

    public async onNoteDelete($event: { uid: ColorString }) {
        if (await this.dialogService.showConfirm({ title: 'Delete note', description: 'Are you sure you want to delete the note?' }) === DialogResult.Confirm) {
            this.noteDeleteDispatchers.dispatchNoteDeleteAction($event.uid);
        }
    }

    public onTaskCreate($event) {
        this.taskCreateDispatchers.dispatchTaskCreateAction($event.request);
        this.taskCreated$.pipe(takeUntil(this.unsubscribe$), filter(x => !!x), first()).subscribe((task) => {
            this.taskCreateDispatchers.dispatchTaskCreateResetAction();
            this.snackbar.showInfo('Task successfully created.');
            this.companyFeedDispatchers.dispatchCompanyFeedAction(PaginationRequest.ReloadCurrentList);
            if ($event.documentsToAdd && $event.documentsToAdd.length > 0) {
                this.taskUpdateDocumentsDispatchers.dispatchTaskUpdateDocumentsAction(task.uid, $event.documentsToAdd, null);
            }
        });
    }

    public onTaskUpdate($event) {
        this.taskUpdateDispatchers.dispatchTaskUpdateAction($event.uid, $event.request);

        if (($event.documentsToAdd && $event.documentsToAdd.length > 0) || ($event.documentsToRemove && $event.documentsToRemove.length > 0)) {
            this.taskUpdateDocumentsDispatchers.dispatchTaskUpdateDocumentsAction($event.uid, $event.documentsToAdd, $event.documentsToRemove);
        }
    }

    public async onTaskDelete($event) {
        if (await this.dialogService.showConfirm({ title: 'Delete task', description: 'Are you sure you want to delete the task?' }) === DialogResult.Confirm) {
            this.taskDeleteDispatchers.dispatchTaskDeleteAction($event.uid);
        }
    }

    public onSwitchCreateTab($event: ClientsActivityType) {
        this.createTab$.next($event);
    }

    public onDisplayChanged($event) {
        this.statusValue = $event && $event.length > 0 ? $event : null;
        const types = this.statusValue && this.statusValue.length > 0 ? this.statusValue.map(x => x.id) : null;
        this.companyFeedDispatchers.dispatchCompanyFeedSetFilterAction({ companyUid: this.companyUid, types });
    }

    public trackByFeed(_, item: Feed) {
        return item.uid;
    }

    public dispatchUserSearch() {
        combineLatest([this.usersSearch$, this.usersSearchPending$]).pipe(first()).subscribe((res) => {
            if (!res[0].length && !res[1]) {
                this.userSearchDispatchers.dispatchUserSearchAction();
            }
        });
    }
}
