import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransferWorkbookGetAction {
    static readonly type = '[TransferWorkbook Page] GetTransferWorkbook Get';

    constructor(public payload: { uid: string, fileName: string }) { }
}

export class TransferWorkbookGetSuccessAction {
    static readonly type = '[TransferWorkbook API] GetTransferWorkbook Success';

    constructor(public payload: Blob) { }
}

export class TransferWorkbookFailAction {
    static readonly type = '[TransferWorkbook API] GetTransferWorkbook Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransferWorkbookResetAction {
    static readonly type = '[TransferWorkbook Page] GetTransferWorkbook Reset';
}
