import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { FranchiseeTypeUpdateSuccessAction } from './franchisee-type-update.actions';
import { FranchiseeTypesAction, FranchiseeTypesFailAction, FranchiseeTypesResetAction, FranchiseeTypesSetFilterAction, FranchiseeTypesSuccessAction } from './franchisee-types.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { FranchiseeTypeListGetFilter, FranchiseeTypeListGetFilterData } from '../../models/filters/franchisee-type-list-get-filter.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { FranchiseesService } from '../../services/business/franchisees.service';
import { FranchiseeTypesListItem } from '../../models/business/franchisees/franchisee-types-list-item.model';

export interface FranchiseeTypesStateModel extends PaginationFilteredStateModel<FranchiseeTypesListItem, FranchiseeTypeListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: FranchiseeTypesListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: FranchiseeTypeListGetFilterData;
    total: number;
    totalAmount: number;
    totalPaidAmount: number;
}

@State<FranchiseeTypesStateModel>({
    name: 'franchiseeTypes',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, totalAmount: 0, totalPaidAmount: 0, filter: {} }
})
@Injectable()
export class FranchiseeTypesState extends PaginationFilteredStateBase {
    constructor(private franchiseeTypesService: FranchiseesService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([FranchiseeTypesAction]) productsGet(ctx: StateContext<FranchiseeTypesStateModel>, action: FranchiseeTypesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'franchiseeType');
        ctx.setState({ ...state, filter: filterData });

        const filter = new FranchiseeTypeListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.franchiseeTypesService.getFranchiseeTypeList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new FranchiseeTypesSuccessAction({ list: resp, pagination: action.payload, totalAmount: resp.totalAmount, totalPaidAmount: resp.totalPaidAmount })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeTypesFailAction(err)), 0));
    }

    @Action(FranchiseeTypesSuccessAction) franchiseeTypeGetSuccess(ctx: StateContext<FranchiseeTypesStateModel>, action: FranchiseeTypesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, totalPaidAmount: action.payload.totalPaidAmount, totalAmount: action.payload.totalAmount, allItemsLoaded, index });
    }

    @Action(FranchiseeTypesFailAction) franchiseeTypeGetFail(ctx: StateContext<FranchiseeTypesStateModel>, action: FranchiseeTypesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FranchiseeTypesResetAction) franchiseeTypeGetReset(ctx: StateContext<FranchiseeTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(FranchiseeTypesSetFilterAction) usersSetFilter(ctx: StateContext<FranchiseeTypesStateModel>, action: FranchiseeTypesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'franchiseeType');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new FranchiseeTypesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(FranchiseeTypeUpdateSuccessAction) franchiseeTypeUpdateSuccess(ctx: StateContext<FranchiseeTypesStateModel>, action: FranchiseeTypeUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }
}
