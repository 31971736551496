
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { RestaurantProductListGetFilterData } from '../../models/filters/restaurant-product-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { RestaurantProductListItem } from '../../models/business/restaurant-products/restaurant-product-list-item.model';

export class RestaurantProductsAction {
    static readonly type = '[RestaurantProducts Page] GetRestaurantProducts';

    constructor(public payload: PaginationRequest) { }
}

export class RestaurantProductsSuccessAction {
    static readonly type = '[RestaurantProducts API] GetRestaurantProducts Success';

    constructor(public payload: { list: PaginationResult<RestaurantProductListItem>, pagination: PaginationRequest }) { }
}

export class RestaurantProductsFailAction {
    static readonly type = '[RestaurantProducts API] GetRestaurantProducts Fail';

    constructor(public payload: ErrorObject) { }
}

export class RestaurantProductsResetAction {
    static readonly type = '[RestaurantProducts Page] GetRestaurantProducts Reset';
}

export class RestaurantProductsSetFilterAction {
    static readonly type = '[RestaurantProducts API] SetFilter';

    constructor(public payload: RestaurantProductListGetFilterData) { }
}
