import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

import {
    PaginationStateBase,
    PaginationStateModel
} from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ExgParamsConfig } from '../../exg-params.config';
import { OrganizationsListGetFilter } from '../../models/filters/organizations-list-get-filter.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationPosService } from '../../services/business/organization-pos.service';

import {
    OrganizationPosesLodMoreAction,
    FranchiseeOrganizationsAction,
    FranchiseeOrganizationsFailAction,
    FranchiseeOrganizationsResetAction,
    FranchiseeOrganizationsSuccessAction
} from './franchisee-organizations.actions';
import { OrganizationsService } from '../../services/business/organizations.service';


export interface FranchiseeOrganizationsStateModel extends PaginationStateModel<OrganizationListItem> {
    uids: string[];
    entities: { [uid: string]: OrganizationListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<FranchiseeOrganizationsStateModel>({
    name: 'franchiseeOrganizations',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class FranchiseeOrganizationsState extends PaginationStateBase {
    constructor(private orgPosService: OrganizationsService) {
        super();
    }

    @Action([FranchiseeOrganizationsAction]) organizationPosesGet(ctx: StateContext<FranchiseeOrganizationsStateModel>, action: FranchiseeOrganizationsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrganizationsListGetFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.orgPosService.getOrganizationList(filter)
            .then(list => setTimeout(() => ctx.dispatch(new FranchiseeOrganizationsSuccessAction({ list, pagination: PaginationRequest.ReloadCurrentList })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeOrganizationsFailAction(err)), 0));
    }

    @Action([OrganizationPosesLodMoreAction]) organizationPosesLoadMore(ctx: StateContext<FranchiseeOrganizationsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new OrganizationsListGetFilter({ pageIndex: state.index });
        if (!this.applyFilterPagination(PaginationRequest.LoadMore, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.orgPosService.getOrganizationList(filter)
            .then(list => setTimeout(() => ctx.dispatch(new FranchiseeOrganizationsSuccessAction({ list, pagination: PaginationRequest.LoadMore })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FranchiseeOrganizationsFailAction(err)), 0));
    }

    @Action(FranchiseeOrganizationsSuccessAction) organizationPosesGetSuccess(ctx: StateContext<FranchiseeOrganizationsStateModel>, action: FranchiseeOrganizationsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.posesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(FranchiseeOrganizationsFailAction) organizationPosesGetFail(ctx: StateContext<FranchiseeOrganizationsStateModel>, action: FranchiseeOrganizationsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FranchiseeOrganizationsResetAction) organizationPosesGetReset(ctx: StateContext<FranchiseeOrganizationsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
