import { Company } from '../../models/business/companies/company.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContactCompanyGetAction {
    static readonly type = '[ContactCompany Page] GetContactCompany Get';

    constructor(public payload: string) { }
}

export class ContactCompanyGetSuccessAction {
    static readonly type = '[ContactCompany API] GetContactCompany Success';

    constructor(public payload: Company) { }
}

export class ContactCompanyFailAction {
    static readonly type = '[ContactCompany API] GetContactCompany Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContactCompanyResetAction {
    static readonly type = '[ContactCompany Page] GetContactCompany Reset';
}
