import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { PurchaseReturnsService } from '../../services/business/purchase-returns.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { PurchaseReturnCreateSuccessAction } from './purchase-return-create.actions';
import { PurchaseReturnDeleteSuccessAction } from './purchase-return-delete.actions';
import { PurchaseReturnUpdateSuccessAction } from './purchase-return-update.actions';
import { PurchaseReturnsAction, PurchaseReturnsFailAction, PurchaseReturnsResetAction, PurchaseReturnsSetFilterAction, PurchaseReturnsSuccessAction } from './purchase-returns.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PurchaseReturnListGetFilter, PurchaseReturnListGetFilterData } from '../../models/filters/purchase-return-list-get-filter.model';
import { PurchaseReturnListItem } from '../../models/business/purchase-returns/purchase-return-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface PurchaseReturnsStateModel extends PaginationFilteredStateModel<PurchaseReturnListItem, PurchaseReturnListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: PurchaseReturnListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PurchaseReturnListGetFilterData;
    total: number;
    totalAmount: number;
}

@State<PurchaseReturnsStateModel>({
    name: 'purchaseReturns',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, totalAmount: 0, filter: {} }
})
@Injectable()
export class PurchaseReturnsState extends PaginationFilteredStateBase {
    constructor(private purchaseReturnsService: PurchaseReturnsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([PurchaseReturnsAction]) productsGet(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'purchaseReturn');
        ctx.setState({ ...state, filter: filterData });

        const filter = new PurchaseReturnListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.purchaseReturnsService.getPurchaseReturnList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PurchaseReturnsSuccessAction({ list: resp, pagination: action.payload, totalAmount: resp.totalAmount })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PurchaseReturnsFailAction(err)), 0));
    }

    @Action(PurchaseReturnsSuccessAction) purchaseReturnGetSuccess(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, totalAmount: action.payload.totalAmount, allItemsLoaded, index });
    }

    @Action(PurchaseReturnsFailAction) purchaseReturnGetFail(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseReturnsResetAction) purchaseReturnGetReset(ctx: StateContext<PurchaseReturnsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(PurchaseReturnsSetFilterAction) usersSetFilter(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'purchaseReturn');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PurchaseReturnsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PurchaseReturnCreateSuccessAction) purchaseReturnCreateSuccess(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(PurchaseReturnUpdateSuccessAction) purchaseReturnUpdateSuccess(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PurchaseReturnDeleteSuccessAction) purchaseReturnDeleteSuccess(ctx: StateContext<PurchaseReturnsStateModel>, action: PurchaseReturnDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
