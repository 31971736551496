import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NotesService } from '../../services/business/note.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Note } from '../../models/business/notes/note.model';

import { NoteCreateAction, NoteCreateFailAction, NoteCreateResetAction, NoteCreateSuccessAction } from './note-create.actions';

export interface NoteCreateStateModel {
    pending: boolean;
    created: Note;
    error: ErrorObject;
}

@State<NoteCreateStateModel>({
    name: 'noteCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class NoteCreateState {
    constructor(private notesService: NotesService) { }

    @Action(NoteCreateAction) async notesCreate(ctx: StateContext<NoteCreateStateModel>, action: NoteCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.notesService.createNote({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new NoteCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new NoteCreateFailAction(err)), 0));
    }

    @Action(NoteCreateSuccessAction) notesCreateSuccess(ctx: StateContext<NoteCreateStateModel>, action: NoteCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(NoteCreateFailAction) notesCreateFail(ctx: StateContext<NoteCreateStateModel>, action: NoteCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(NoteCreateResetAction) notesCreateReset(ctx: StateContext<NoteCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
