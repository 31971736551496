import { SearchFilterData } from '../../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class CompaniesMotivationBonusesListGetFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;
    public companyUid?: string;
    public contragentUid?: string;
    public posUid?: string;
    public userUid?: string;
    public bonusesDate?: number;

    // tslint:disable-next-line:cyclomatic-complexity
    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: CompaniesMotivationBonusesListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'StartDate';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.companyUid = (args && args.filterData) ? args.filterData.companyUid : null;
        this.contragentUid = (args && args.filterData) ? args.filterData.contragentUid : null;
        this.posUid = (args && args.filterData) ? args.filterData.posUid : null;
        this.userUid = (args && args.filterData) ? args.filterData.userUid : null;
        this.bonusesDate = (args && args.filterData) ? args.filterData.bonusesDate : null;
    }
}

export class CompaniesMotivationBonusesListGetFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
    public companyUid?: string;
    public contragentUid?: string;
    public posUid?: string;
    public userUid?: string;
    public bonusesDate?: number;
}
