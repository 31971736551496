import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NotesService } from '../../services/business/note.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

import { NoteCreateSuccessAction } from './note-create.actions';
import { NoteDeleteSuccessAction } from './note-delete.actions';
import { NoteUpdateDocumentsSuccessAction } from './note-update-documents.actions';
import { NoteUpdateSuccessAction } from './note-update.actions';
import { NotesAction, NotesFailAction, NotesResetAction, NotesSetFilterAction, NotesSuccessAction } from './notes.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Note } from '../../models/business/notes/note.model';
import { NotesListGetFilter, NotesListGetFilterData } from '../../models/filters/notes-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface NotesStateModel extends PaginationStateModel<Note> {
    uids: string[];
    entities: { [uid: string]: Note };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: NotesListGetFilterData;
    total: number;
}

@State<NotesStateModel>({
    name: 'notes',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { } }
})
@Injectable()
export class NotesState extends PaginationStateBase {
    constructor(private notesService: NotesService) {
        super();
    }

    @Action([NotesAction]) notesGet(ctx: StateContext<NotesStateModel>, action: NotesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new NotesListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.notesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.notesService.getNoteList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new NotesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new NotesFailAction(err)), 0));
    }

    @Action(NotesSuccessAction) notesGetSuccess(ctx: StateContext<NotesStateModel>, action: NotesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.notesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(NotesFailAction) notesGetFail(ctx: StateContext<NotesStateModel>, action: NotesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(NotesResetAction) notesGetReset(ctx: StateContext<NotesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(NotesSetFilterAction) notesSetFilter(ctx: StateContext<NotesStateModel>, action: NotesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new NotesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(NoteCreateSuccessAction) noteCreateSuccess(ctx: StateContext<NotesStateModel>, action: NoteCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [action.payload.uid, ...state.uids];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(NoteUpdateSuccessAction) noteUpdateSuccess(ctx: StateContext<NotesStateModel>, action: NoteUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(NoteUpdateDocumentsSuccessAction) noteUpdateDocumentsSuccess(ctx: StateContext<NotesStateModel>, action: NoteUpdateDocumentsSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        let entity = { ...entities[action.payload.uid] };
        if (action.payload.entity) {
            entity = action.payload.entity;
        }

        if (action.payload.documentsToRemove && action.payload.documentsToRemove.length > 0) {
            entity.documents = entity.documents.filter(x => !action.payload.documentsToRemove.some(r => r === x.uid));
        }
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...entity };
        ctx.setState({ ...state, entities });
    }

    @Action(NoteDeleteSuccessAction) noteDeleteSuccess(ctx: StateContext<NotesStateModel>, action: NoteDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
