import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TechnologicalMapsService } from '../../services/business/technological-maps.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapUpdateRequest } from '../../models/business/technological-maps/technological-map-update-request.model';

import { TechnologicalMapUpdateAction, TechnologicalMapUpdateFailAction, TechnologicalMapUpdateResetAction, TechnologicalMapUpdateSuccessAction } from './technological-map-update.actions';

export interface TechnologicalMapUpdateStateModel {
    pending: boolean;
    updated: TechnologicalMapUpdateRequest;
    error: ErrorObject;
}

@State<TechnologicalMapUpdateStateModel>({
    name: 'technologicalMapUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class TechnologicalMapUpdateState {
    constructor(private technologicalMapsService: TechnologicalMapsService) { }

    @Action(TechnologicalMapUpdateAction) async technologicalMapUpdate(ctx: StateContext<TechnologicalMapUpdateStateModel>, action: TechnologicalMapUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.technologicalMapsService.updateTechnologicalMap(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TechnologicalMapUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TechnologicalMapUpdateFailAction(err)), 0));
    }

    @Action(TechnologicalMapUpdateSuccessAction) technologicalMapUpdateSuccess(ctx: StateContext<TechnologicalMapUpdateStateModel>, action: TechnologicalMapUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: action.payload.request, error: null });
    }

    @Action(TechnologicalMapUpdateFailAction) technologicalMapUpdateFail(ctx: StateContext<TechnologicalMapUpdateStateModel>, action: TechnologicalMapUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(TechnologicalMapUpdateResetAction) technologicalMapUpdateReset(ctx: StateContext<TechnologicalMapUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
