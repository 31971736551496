import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { filter, takeUntil } from 'rxjs/operators';

import { LogoutDispatchers } from '../../../../../burns-ui-framework/shared/store/logout/logout.dispatchers';
import { ProfileSelectors } from '../../../../../burns-ui-framework/shared/store/profile/profile.selectors';
import { UiSelectors } from '../../../../../burns-ui-framework/shared/store/ui/ui.selectors';

import { ExgOnDestroy } from '../../../../../burns-ui-framework/shared/components/abstract/exg-on-destroy.component';

import { Group } from '../../../models/business/user/user-group.enum';

import { EvaSettingsTokens } from '../../../eva-settings-tokens.config';

@Component({
    selector: 'bvc-erp-root',
    templateUrl: './bvc-erp-root.component.html',
    styleUrls: ['./bvc-erp-root.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcErpRootComponent extends ExgOnDestroy {

    public showDialog: boolean;

    public profile$ = this.profileSelectors.profile$;
    public headerMenu: any;
    public headerMobileMenu: any;
    public headerSettings: any;
    public restrictedRoutes: any;

    constructor(private uiSelectors: UiSelectors,
                private changere: ChangeDetectorRef,
                private logoutDispatchers: LogoutDispatchers,
                private profileSelectors: ProfileSelectors,
                private injector: Injector,
                private router: Router) {
        super();
        this.uiSelectors.showDialog$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            setTimeout(() => {
                this.showDialog = res;
                this.changere.markForCheck();
            }, 0);
        });

        this.headerMenu = this.injector.get(EvaSettingsTokens.tokens.headerMenu);
        this.headerMobileMenu = this.injector.get(EvaSettingsTokens.tokens.headerMobileMenu);
        this.headerSettings = this.injector.get(EvaSettingsTokens.tokens.headerSettings);
        this.restrictedRoutes = this.injector.get(EvaSettingsTokens.tokens.restrictedRoutes);

        this.profile$.pipe(takeUntil(this.unsubscribe), filter(p => !!p)).subscribe((profile) => {
            if (!profile.user.groups.some(g => [Group.Administrator, Group.Controller, Group.SalesManager].includes(+g))) {
                this.headerMenu = this.headerMenu.filter((v) => !this.restrictedRoutes[150].includes(v.route));
                this.headerMobileMenu.dropdown = this.headerMobileMenu.dropdown.filter((v) => !this.restrictedRoutes[150].includes(v.route));
            }
        });
    }

    public onLogout() {
        this.logoutDispatchers.dispatchLogoutAction();
        this.logoutDispatchers.dispatchFullResetAction();
        this.router.navigate(['/auth']);
    }

    protected afterDestroy() { /** no need */ }
}
