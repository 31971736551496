import { LinkedCompany } from '../companies/linked-company.model';

export class ContactListItem {
    public uid: string;
    public fullName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public isTakesPartInBonusProgram: boolean;
    public company: LinkedCompany;
}
