import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PricelistService } from '../../services/business/pricelist.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PricelistWorkbookFailAction, PricelistWorkbookGetAction, PricelistWorkbookGetSuccessAction, PricelistWorkbookResetAction } from './pricelist-workbook.actions';

export interface PricelistWorkbookStateModel {
    created: Blob;
    pending: boolean;
    error: ErrorObject;
}

@State<PricelistWorkbookStateModel>({
    name: 'pricelistWorkbook',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class PricelistWorkbookState {
    constructor(private pricelistService: PricelistService) { }

    @Action(PricelistWorkbookGetAction) pricelistGet(ctx: StateContext<PricelistWorkbookStateModel>, action: PricelistWorkbookGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.pricelistService.getWorkbook(action.payload.uid, action.payload.fileName)
            .then(resp => setTimeout(() => ctx.dispatch(new PricelistWorkbookGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistWorkbookFailAction(err)), 0));
    }

    @Action(PricelistWorkbookGetSuccessAction) pricelistGetSuccess(ctx: StateContext<PricelistWorkbookStateModel>, action: PricelistWorkbookGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(PricelistWorkbookFailAction) pricelistGetFail(ctx: StateContext<PricelistWorkbookStateModel>, action: PricelistWorkbookFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PricelistWorkbookResetAction) pricelistGetReset(ctx: StateContext<PricelistWorkbookStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
