import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { ModifiersListGetFilter } from '../../models/filters/modifiers-list-get-filter.model';
import { ModifierCreateOrUpdateRequest } from '../../models/business/modifiers/modifier-create-or-update-request.model';
import { Modifier } from '../../models/business/modifiers/modifier.model';
import { ModifierType } from '../../models/business/modifiers/modifier-type.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root',
})
export class ModifiersService extends BaseSingletonService {
    private settings: {
        service: {
            getModifierList: string;
            getModifierTypes: string;
            createModifier: string;
            deleteOrderModifiers: string;
        };
    };

    constructor(private http: HttpService) {
        super('ModifiersService');
        this.settings = {
            service: {
                getModifierList: '/nomenclature/v1/modifiers?term={0}&productUid={1}&modifierTypeUid={2}',
                getModifierTypes: '/nomenclature/v1/modifiers/types',
                createModifier: '/nomenclature/v1/modifiers/list',
                deleteOrderModifiers: '/nomenclature/v1/modifiers/product/{0}'
            },
        };
    }

    public async createUpdateModifiers(request: ModifierCreateOrUpdateRequest[]): Promise<void> {
        return this.http.post(String.format(this.settings.service.createModifier), request)
    }

    public async deleteOrderModifiers(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteOrderModifiers, uid));
    }

    public async getModifierList(filter: ModifiersListGetFilter): Promise<PaginationResult<Modifier>> {
        return this.http
            .get<PaginationResult<Modifier>>(String.format(this.settings.service.getModifierList, filter.term, filter.productUid, filter.modifierTypeUid))
            .then(res => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getModifierTypes(): Promise<ModifierType[]> {
        return this.http.get<{ list: ModifierType[] }>(String.format(this.settings.service.getModifierTypes)).then(res => res.list);
    }
}
