import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CookingsService } from '../../services/cookings.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CookingDeleteAction, CookingDeleteFailAction, CookingDeleteResetAction, CookingDeleteSuccessAction } from './cooking-delete.actions';

export interface CookingDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<CookingDeleteStateModel>({
    name: 'cookingDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class CookingDeleteState {
    constructor(private cookingsService: CookingsService) { }

    @Action(CookingDeleteAction) async cookingDelete(ctx: StateContext<CookingDeleteStateModel>, action: CookingDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.cookingsService.deleteCooking(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new CookingDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new CookingDeleteFailAction(error)), 0));
    }

    @Action(CookingDeleteSuccessAction) cookingDeleteSuccess(ctx: StateContext<CookingDeleteStateModel>, _: CookingDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(CookingDeleteFailAction) cookingDeleteFail(ctx: StateContext<CookingDeleteStateModel>, action: CookingDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CookingDeleteResetAction) cookingDeleteReset(ctx: StateContext<CookingDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
