import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { String } from '../../../../burns-ui-framework/shared/utils/string';
@Injectable({
    providedIn: 'root'
})
export class DictionariesService extends BaseSingletonService {

    private settings: {
        service: {
            getVats: string;
            updateVat: string;
        }
    };

    constructor(private http: HttpService) {
        super('DictionariesService');
        this.settings = {
            service: {
                getVats: '/warehouses-aggregation/v1/products?term={0}&categoryUid={1}&brandUids={2}&typeUids={3}&groupUids={4}&countryCodes={5}&isArchived={6}&sortField={7}&sortOrder={8}&pageIndex={9}&pageSize={10}',
                updateVat: '/taxs/v1/taxs'
            }
        };
    }

    public async getVats(): Promise<number[]> {
        return Promise.resolve([0,10,20,30,40,50]);
        // return this.http.get<{ list: number[] }>(String.format(this.settings.service.getVats))
        //     .then(res => res.list);
    }

    public async updateVat(req: number[]): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateVat), req);
    }

}
