import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RequisitesService } from '../../services/business/requisites.service';

import { BankSuggestion } from '../../models/business/contragents/bank-suggestion.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { RequisiteSuggestionFailAction, RequisiteSuggestionGetAction, RequisiteSuggestionGetSuccessAction, RequisiteSuggestionResetAction } from './requisite-suggestion.actions';

export interface RequisiteSuggestionStateModel {
    entities: BankSuggestion[];
    pending: boolean;
    error: ErrorObject;
}

@State<RequisiteSuggestionStateModel>({
    name: 'requisiteSuggestion',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class RequisiteSuggestionState {
    constructor(private requisitesService: RequisitesService) { }

    @Action(RequisiteSuggestionGetAction) requisiteSuggestionGet(ctx: StateContext<RequisiteSuggestionStateModel>, action: RequisiteSuggestionGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.requisitesService.getSuggestions(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new RequisiteSuggestionGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RequisiteSuggestionFailAction(err)), 0));
    }

    @Action(RequisiteSuggestionGetSuccessAction) requisiteSuggestionGetSuccess(ctx: StateContext<RequisiteSuggestionStateModel>, action: RequisiteSuggestionGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(RequisiteSuggestionFailAction) requisiteSuggestionGetFail(ctx: StateContext<RequisiteSuggestionStateModel>, action: RequisiteSuggestionFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RequisiteSuggestionResetAction) requisiteSuggestionGetReset(ctx: StateContext<RequisiteSuggestionStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
