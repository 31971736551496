import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { NotesService } from '../../services/business/note.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { NoteUpdateAction, NoteUpdateFailAction, NoteUpdateResetAction, NoteUpdateSuccessAction } from './note-update.actions';

export interface NoteUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<NoteUpdateStateModel>({
    name: 'noteUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class NoteUpdateState {
    constructor(private notesService: NotesService) { }

    @Action(NoteUpdateAction) async noteUpdate(ctx: StateContext<NoteUpdateStateModel>, action: NoteUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.notesService.updateNote(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new NoteUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new NoteUpdateFailAction(err)), 0));
    }

    @Action(NoteUpdateSuccessAction) noteUpdateSuccess(ctx: StateContext<NoteUpdateStateModel>, _: NoteUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(NoteUpdateFailAction) noteUpdateFail(ctx: StateContext<NoteUpdateStateModel>, action: NoteUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(NoteUpdateResetAction) noteUpdateReset(ctx: StateContext<NoteUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
