import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ProductCategory } from '../../models/business/catalog/product-category.model';

import { CategoryCreateAction, CategoryCreateFailAction, CategoryCreateResetAction, CategoryCreateSuccessAction } from './category-create.actions';

export interface CategoryCreateStateModel {
    pending: boolean;
    created: ProductCategory;
    error: ErrorObject;
}

@State<CategoryCreateStateModel>({
    name: 'categoryCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class CategoryCreateState {
    constructor(private catalogService: CatalogService) { }

    @Action(CategoryCreateAction) async categoryCreate(ctx: StateContext<CategoryCreateStateModel>, action: CategoryCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.catalogService.createCategory(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new CategoryCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CategoryCreateFailAction(err)), 0));
    }

    @Action(CategoryCreateSuccessAction) categoryCreateSuccess(ctx: StateContext<CategoryCreateStateModel>, action: CategoryCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(CategoryCreateFailAction) categoryCreateFail(ctx: StateContext<CategoryCreateStateModel>, action: CategoryCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(CategoryCreateResetAction) categoryCreateReset(ctx: StateContext<CategoryCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
