
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { MotivationBonusListItem } from '../../models/business/contacts/motivation-bonus-list-item.model';
import { MotivationBonusesListGetFilterData } from '../../models/filters/motivation-bonuses-list-get-filter.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class MotivationBonusesAction {
    static readonly type = '[MotivationBonuses Page] GetMotivationBonuses';

    constructor(public payload: PaginationRequest) { }
}

export class MotivationBonusesSuccessAction {
    static readonly type = '[MotivationBonuses API] GetMotivationBonuses Success';

    constructor(public payload: { list: PaginationResult<MotivationBonusListItem>, pagination: PaginationRequest }) { }
}

export class MotivationBonusesFailAction {
    static readonly type = '[MotivationBonuses API] GetMotivationBonuses Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusesResetAction {
    static readonly type = '[MotivationBonuses Page] GetMotivationBonuses Reset';
}

export class MotivationBonusesSetFilterAction {
    static readonly type = '[MotivationBonuses API] SetFilter';

    constructor(public payload: MotivationBonusesListGetFilterData) { }
}
