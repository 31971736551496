import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { UserFilterData } from '../../models/filters/user-filter.model';
import { UserListItem } from '../../models/business/user/user.model';

export class UsersFiredAction {
    static readonly type = '[UsersFired API] GetUsersFired';

    constructor(public payload: PaginationRequest) { }
}

export class UsersFiredSuccessAction {
    static readonly type = '[UsersFired API] GetUsersFired Success';

    constructor(public payload: { users: PaginationResult<UserListItem>, pagination: PaginationRequest }) { }
}

export class UsersFiredFailAction {
    static readonly type = '[UsersFired API] GetUsersFired Fail';

    constructor(public payload: ErrorObject) { }
}

export class UsersFiredResetAction {
    static readonly type = '[UsersFired API] GetUsersFired Reset';
}

export class UsersFiredSetFilterAction {
    static readonly type = '[UsersFired API] SetFilter';

    constructor(public payload: UserFilterData) { }
}
