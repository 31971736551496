import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { OrganizationCreateOrUpdateRequest } from '../../models/business/organizations/organization-create-or-update-request.model';
import { Organization } from '../../models/business/organizations/organization.model';
import { OrganizationListItem } from '../../models/business/organizations/organization-list-item.model';
import { OrganizationsListGetFilter } from '../../models/filters/organizations-list-get-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class OrganizationsService extends BaseSingletonService {

    private settings: {
        service: {
            getOrganization: string;
            getOrganizationList: string;
            createOrganization: string;
            updateOrganization: string;
            deleteOrganization: string;
            setDefaultOrganization: string;

        }
    };

    constructor(private http: HttpService) {
        super('OrganizationsService');
        this.settings = {
            service: {
                getOrganization: '/corporation/v1/organizations/{0}',
                getOrganizationList: '/corporation/v1/organizations?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}&parentUid={5}',
                createOrganization: '/corporation/v1/organizations',
                updateOrganization: '/corporation/v1/organizations/{0}',
                deleteOrganization: '/corporation/v1/organizations/{0}',
                setDefaultOrganization: '/corporation/v1/organizations/{0}/default'
            }
        };
    }

    public async getOrganization(uid: string): Promise<Organization> {
        return this.http.get<{ data: Organization }>(String.format(this.settings.service.getOrganization, uid))
            .then(res => res.data);
    }

    public async setDefaultOrganization(uid: string): Promise<boolean> {
        return this.http.put<{ data: boolean }>(String.format(this.settings.service.setDefaultOrganization, uid), {})
            .then(res => res.data);
    }

    public async getOrganizationList(filter: OrganizationsListGetFilter): Promise<PaginationResult<OrganizationListItem>> {
        return this.http.get<PaginationResult<OrganizationListItem>>(String.format(this.settings.service.getOrganizationList, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.parentUid))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getOrganizations(filter: OrganizationsListGetFilter): Promise<OrganizationListItem[]> {
        return this.http.get<{ list: OrganizationListItem[] }>(String.format(this.settings.service.getOrganizationList, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.parentUid))
        .then((res) => {
            return res.list;
        });
    }

    public async createOrganization(request: OrganizationCreateOrUpdateRequest): Promise<Organization> {
        return this.http.post<{ data: Organization }>(String.format(this.settings.service.createOrganization), request)
            .then(res => res.data);
    }

    public async updateOrganization(uid: string, request: OrganizationCreateOrUpdateRequest): Promise<Organization> {
        return this.http.put(String.format(this.settings.service.updateOrganization, uid), request);
    }

    public async deleteOrganization(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteOrganization, uid));
    }
}
