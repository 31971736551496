import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { OrganizationPosesListGetFilterData } from '../../models/filters/organization-pos-list-get-filter.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class FranchiseeRestaurantsAction {
    static readonly type = '[FranchiseeRestaurants Page] GetFranchiseeRestaurants';

    constructor(public payload: OrganizationPosesListGetFilterData) { }
}
export class OrganizationPosesLodMoreAction {
    static readonly type = '[OrganizationPosesLoadMore Page] GetFranchiseeRestaurants';

    constructor() { }
}

export class FranchiseeRestaurantsSuccessAction {
    static readonly type = '[FranchiseeRestaurants API] GetFranchiseeRestaurants Success';

    constructor(public payload: { list: PaginationResult<OrganizationPosListItem>, pagination: PaginationRequest }) { }
}

export class FranchiseeRestaurantsFailAction {
    static readonly type = '[FranchiseeRestaurants API] GetFranchiseeRestaurants Fail';

    constructor(public payload: ErrorObject) { }
}

export class FranchiseeRestaurantsResetAction {
    static readonly type = '[FranchiseeRestaurants Page] GetFranchiseeRestaurants Reset';
}
