import { DCRItem } from '../../models/business/dcr/dcr-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class CookingGetAction {
    static readonly type = '[Cooking Page] GetCooking Get';

    constructor(public payload: string) { }
}

export class CookingGetSuccessAction {
    static readonly type = '[Cooking API] GetCooking Success';

    constructor(public payload: DCRItem) { }
}

export class CookingFailAction {
    static readonly type = '[Cooking API] GetCooking Fail';

    constructor(public payload: ErrorObject) { }
}

export class CookingResetAction {
    static readonly type = '[Cooking Page] GetCooking Reset';
}
