import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMap } from '../../models/business/technological-maps/technological-map.model';

import { TechnologicalMapCreateState, TechnologicalMapCreateStateModel } from './technological-map-create.reducer';

@Injectable({
    providedIn: 'root'
})
export class TechnologicalMapCreateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([TechnologicalMapCreateState], (state: TechnologicalMapCreateStateModel) => state.pending);

    static readonly getUpdated = createSelector([TechnologicalMapCreateState], (state: TechnologicalMapCreateStateModel) => state.created);

    static readonly getError = createSelector([TechnologicalMapCreateState], (state: TechnologicalMapCreateStateModel) => state.error);

    @Select(TechnologicalMapCreateSelectors.getPending) pending$: Observable<boolean>;

    @Select(TechnologicalMapCreateSelectors.getUpdated) created$: Observable<TechnologicalMap>;

    @Select(TechnologicalMapCreateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('TechnologicalMapCreateSelectors');
    }
}
