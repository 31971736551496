import { PersonnelCreateOrUpdateRequest } from '../../models/business/personnels/personnel-create-or-update.model';
import { Personnel } from '../../models/business/personnels/personnel.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class PersonnelCreateAction {
    static readonly type = '[Personnels Page] CreatePersonnel';

    constructor(public payload: PersonnelCreateOrUpdateRequest) { }
}

export class PersonnelCreateSuccessAction {
    static readonly type = '[Personnel API] CreatePersonnel Success';

    constructor(public payload: Personnel) { }
}

export class PersonnelCreateFailAction {
    static readonly type = '[Personnel API] CreatePersonnel Fail';

    constructor(public payload: ErrorObject) { }
}

export class PersonnelCreateResetAction {
    static readonly type = '[Personnels Page] CreatePersonnel Reset';
}
