import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResultWithAmount } from '../../models/business/common/pagination-result-with-amount';
import { PurchaseInvoiceCreateOrUpdateRequest } from '../../models/business/purchase-invoices/purchase-invoice-create-or-update-request.model';
import { PurchaseInvoiceListGetFilter } from '../../models/filters/purchase-invoice-list-get-filter.model';
import { PurchaseInvoiceListItem } from '../../models/business/purchase-invoices/purchase-invoice-list-item.model';
import { PurchaseInvoice } from '../../models/business/purchase-invoices/purchase-invoice.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class PurchaseInvoicesService extends BaseSingletonService {

    private settings: {
        service: {
            getPurchaseInvoice: string;
            getPurchaseInvoiceList: string;
            createPurchaseInvoice: string;
            updatePurchaseInvoice: string;
            deletePurchaseInvoice: string;
        }
    };

    constructor(private http: HttpService) {
        super('PurchaseInvoicesService');
        this.settings = {
            service: {
                getPurchaseInvoice: '/purchases-aggregation/v1/invoices/{0}',
                getPurchaseInvoiceList: '/purchases-aggregation/v1/invoices?sortField={0}&sortOrder={1}&pageIndex={2}&pageSize={3}&term={4}',
                createPurchaseInvoice: '/purchases-aggregation/v1/invoices',
                updatePurchaseInvoice: '/purchases-aggregation/v1/invoices/{0}',
                deletePurchaseInvoice: '/purchases/v1/invoices/{0}'
            }
        };
    }

    public async getPurchaseInvoice(uid: string): Promise<PurchaseInvoice> {
        return this.http.get<{ data: PurchaseInvoice }>(String.format(this.settings.service.getPurchaseInvoice, uid))
            .then(res => res.data);
    }

    public async getPurchaseInvoiceList(filter: PurchaseInvoiceListGetFilter): Promise<PaginationResultWithAmount<PurchaseInvoiceListItem>> {
        return this.http.get<PaginationResultWithAmount<PurchaseInvoiceListItem>>(String.format(this.settings.service.getPurchaseInvoiceList, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.term))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createPurchaseInvoice(request: PurchaseInvoiceCreateOrUpdateRequest): Promise<PurchaseInvoice> {
        return this.http.post<{ data: PurchaseInvoice }>(String.format(this.settings.service.createPurchaseInvoice), request)
            .then(res => res.data);
    }

    public async updatePurchaseInvoice(uid: string, request: PurchaseInvoiceCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updatePurchaseInvoice, uid), request);
    }

    public async deletePurchaseInvoice(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePurchaseInvoice, uid));
    }
}
