import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TasksService } from '../../services/business/tasks.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { TaskUpdateDocumentsAction, TaskUpdateDocumentsFailAction, TaskUpdateDocumentsResetAction, TaskUpdateDocumentsSuccessAction } from './task-update-documents.actions';

export interface TaskUpdateDocumentsStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<TaskUpdateDocumentsStateModel>({
    name: 'taskUpdateDocuments',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class TaskUpdateDocumentsState {
    constructor(private tasksService: TasksService) { }

    @Action(TaskUpdateDocumentsAction) async taskUpdate(ctx: StateContext<TaskUpdateDocumentsStateModel>, action: TaskUpdateDocumentsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        const createPromise = action.payload.documentsToAdd && action.payload.documentsToAdd.length > 0
            ? this.tasksService.addTaskDocuments(action.payload.uid, action.payload.documentsToAdd)
            : Promise.resolve(null);

        const deletePromise = action.payload.documentsToRemove && action.payload.documentsToRemove.length > 0
            ? this.tasksService.deleteTaskDocuments(action.payload.uid, action.payload.documentsToRemove)
            : Promise.resolve(true);

        return Promise.all([createPromise, deletePromise])
            .then(res => setTimeout(() => ctx.dispatch(new TaskUpdateDocumentsSuccessAction({ uid: action.payload.uid, entity: res[0], documentsToRemove: action.payload.documentsToRemove })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TaskUpdateDocumentsFailAction(err)), 0));
    }

    @Action(TaskUpdateDocumentsSuccessAction) taskUpdateSuccess(ctx: StateContext<TaskUpdateDocumentsStateModel>, _: TaskUpdateDocumentsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(TaskUpdateDocumentsFailAction) taskUpdateFail(ctx: StateContext<TaskUpdateDocumentsStateModel>, action: TaskUpdateDocumentsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(TaskUpdateDocumentsResetAction) taskUpdateReset(ctx: StateContext<TaskUpdateDocumentsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
