<section class="row-header">
    <bvc-filter-field class="full-name header"
                      *ngIf="listConfig[contactSortingFields.FullName]"
                      [class.active]="filter.sortField === contactSortingFields.FullName"
                      [name]="'Name1'"
                      [fieldName]="contactSortingFields.FullName"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="registered header"
                      *ngIf="listConfig[contactSortingFields.Email]"
                      [class.active]="filter.sortField === contactSortingFields.Email"
                      [name]="'Email'"
                      [fieldName]="contactSortingFields.Email"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="last-entrance header"
                      *ngIf="listConfig[contactSortingFields.Phone]"
                      [class.active]="filter.sortField === contactSortingFields.Phone"
                      [name]="'Phone'"
                      [fieldName]="contactSortingFields.Phone"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="last-entrance header"
                      *ngIf="listConfig[contactSortingFields.Company]"
                      [class.active]="filter.sortField === contactSortingFields.Company"
                      [name]="'Company'"
                      [fieldName]="contactSortingFields.Company"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

</section>
<section class="row-header shadow">
    <div class="full-name header" *ngIf="listConfig[contactSortingFields.FullName]"></div>
    <div class="registered header" *ngIf="listConfig[contactSortingFields.Email]"></div>
    <div class="last-entrance header" *ngIf="listConfig[contactSortingFields.Phone]"></div>
    <div class="last-entrance header" *ngIf="listConfig[contactSortingFields.Company]"></div>
</section>