import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WriteOffsService } from '../../services/business/write-offs.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { WriteOff } from '../../models/business/write-offs/write-off.model';

import { WriteOffFailAction, WriteOffGetAction, WriteOffGetSuccessAction, WriteOffResetAction } from './write-off.actions';

export interface WriteOffStateModel {
    entity: WriteOff;
    pending: boolean;
    error: ErrorObject;
}

@State<WriteOffStateModel>({
    name: 'writeoff',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class WriteOffState {
    constructor(private writeoffsService: WriteOffsService) { }

    @Action(WriteOffGetAction) writeoffGet(ctx: StateContext<WriteOffStateModel>, action: WriteOffGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.writeoffsService.getWriteOff(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new WriteOffGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WriteOffFailAction(err)), 0));
    }

    @Action(WriteOffGetSuccessAction) writeoffGetSuccess(ctx: StateContext<WriteOffStateModel>, action: WriteOffGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(WriteOffFailAction) writeoffGetFail(ctx: StateContext<WriteOffStateModel>, action: WriteOffFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WriteOffResetAction) writeoffGetReset(ctx: StateContext<WriteOffStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
