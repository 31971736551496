import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SalesPlanService } from '../../services/business/sales-plan.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { SalesPlanUpdateAction, SalesPlanUpdateFailAction, SalesPlanUpdateResetAction, SalesPlanUpdateSuccessAction } from './sales-plan-update.actions';

export interface UpdateState {
    year: number;
    isConfirmed: boolean;
}

export interface SalesPlanUpdateStateModel {
    pending: boolean;
    updated: UpdateState;
    error: ErrorObject;
}

@State<SalesPlanUpdateStateModel>({
    name: 'salesPlanUpdate',
    defaults: { pending: false, updated: null, error: null }
})
@Injectable()
export class SalesPlanUpdateState {
    constructor(private salesPlanService: SalesPlanService) { }

    @Action(SalesPlanUpdateAction) async salesPlanUpdate(ctx: StateContext<SalesPlanUpdateStateModel>, action: SalesPlanUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: null, error: null });

        return this.salesPlanService.updateSalesPlan(action.payload.year, action.payload.userUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new SalesPlanUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SalesPlanUpdateFailAction(err)), 0));
    }

    @Action(SalesPlanUpdateSuccessAction) salesPlanUpdateSuccess(ctx: StateContext<SalesPlanUpdateStateModel>, action: SalesPlanUpdateSuccessAction) {
        const state = ctx.getState();
        const updated = {
            year: action.payload.year,
            isConfirmed: action.payload.request.isConfirmed
        };

        ctx.setState({ ...state, pending: false, updated, error: null });
    }

    @Action(SalesPlanUpdateFailAction) salesPlanUpdateFail(ctx: StateContext<SalesPlanUpdateStateModel>, action: SalesPlanUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: action.payload });
    }

    @Action(SalesPlanUpdateResetAction) salesPlanUpdateReset(ctx: StateContext<SalesPlanUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: null, error: null });
    }
}
