import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WarehousesService } from '../../services/business/warehouses.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { WarehouseUpdateAction, WarehouseUpdateFailAction, WarehouseUpdateResetAction, WarehouseUpdateSuccessAction } from './warehouse-update.actions';

export interface WarehouseUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<WarehouseUpdateStateModel>({
    name: 'warehouseUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class WarehouseUpdateState {
    constructor(private warehousesService: WarehousesService) { }

    @Action(WarehouseUpdateAction) async warehouse(ctx: StateContext<WarehouseUpdateStateModel>, action: WarehouseUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.warehousesService.updateWarehouse(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new WarehouseUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WarehouseUpdateFailAction(err)), 0));
    }

    @Action(WarehouseUpdateSuccessAction) warehouseSuccess(ctx: StateContext<WarehouseUpdateStateModel>, _: WarehouseUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(WarehouseUpdateFailAction) warehouseFail(ctx: StateContext<WarehouseUpdateStateModel>, action: WarehouseUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WarehouseUpdateResetAction) warehouseReset(ctx: StateContext<WarehouseUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
