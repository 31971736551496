
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { NoteCreateRequest } from '../../models/business/notes/note-create-request.model';
import { Note } from '../../models/business/notes/note.model';

export class NoteCreateAction {
    static readonly type = '[Notes Page] CreateNote';

    constructor(public payload: NoteCreateRequest) { }
}

export class NoteCreateSuccessAction {
    static readonly type = '[Note API] CreateNote Success';

    constructor(public payload: Note) { }
}

export class NoteCreateFailAction {
    static readonly type = '[Note API] CreateNote Fail';

    constructor(public payload: ErrorObject) { }
}

export class NoteCreateResetAction {
    static readonly type = '[Notes Page] CreateNote Reset';
}
