import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class WriteOffDeleteAction {
    static readonly type = '[My WriteOff Page] DeleteWriteOff';

    constructor(public payload: { uid: string }) { }
}

export class WriteOffDeleteSuccessAction {
    static readonly type = '[My WriteOffs API] DeleteWriteOff Success';

    constructor(public payload: { uid: string }) { }
}

export class WriteOffDeleteFailAction {
    static readonly type = '[My WriteOffs API] DeleteWriteOff Fail';

    constructor(public payload: ErrorObject) { }
}

export class WriteOffDeleteResetAction {
    static readonly type = '[My WriteOff Page] DeleteWriteOff Reset';
}
