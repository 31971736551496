
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class MotivationBonusDeleteAction {
    static readonly type = '[My MotivationBonus Page] DeleteMotivationBonus';

    constructor(public payload: { contactUid: string, motivationUid: string }) { }
}

export class MotivationBonusDeleteSuccessAction {
    static readonly type = '[My MotivationBonus API] DeleteMotivationBonus Success';

    constructor(public payload: { contactUid: string, motivationUid: string }) { }
}

export class MotivationBonusDeleteFailAction {
    static readonly type = '[My MotivationBonus API] DeleteMotivationBonus Fail';

    constructor(public payload: ErrorObject) { }
}

export class MotivationBonusDeleteResetAction {
    static readonly type = '[My MotivationBonus Page] DeleteMotivationBonus Reset';
}
