import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { YandexPlaceInputComponentType } from '../models/yandex-place-input-component-type.enum';
import { YandexSuggestionPlaceComponent } from '../models/yandex-suggestion-place-component.model';

export class PlacesSuggestionsAction {
    static readonly type = '[Yandex Places Suggestions Page] Get Places Suggestions';

    constructor(public payload: { term: string, level: YandexPlaceInputComponentType, fullNameLevelFrom: YandexPlaceInputComponentType, lowerCornerLatitude: number, lowerCornerLongitude: number, upperCornerLatitude: number, upperCornerLongitude: number }) { }
}

export class PlacesSuggestionsSuccessAction {
    static readonly type = '[Yandex Places Suggestions API] Get Places Suggestions Success';

    constructor(public payload: YandexSuggestionPlaceComponent[]) { }
}

export class PlacesSuggestionsFailAction {
    static readonly type = '[Yandex Places Suggestions API] Get Places Suggestions Fail';

    constructor(public payload: ErrorObject) { }
}

export class PlacesSuggestionsResetAction {
    static readonly type = '[Yandex Places Suggestions Page] Places Suggestions Reset';
}
