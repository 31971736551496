import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PurchaseOrdersService } from '../../services/business/purchase-orders.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { PurchaseOrderDeleteAction, PurchaseOrderDeleteFailAction, PurchaseOrderDeleteResetAction, PurchaseOrderDeleteSuccessAction } from './purchase-order-delete.actions';

export interface PurchaseOrderDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<PurchaseOrderDeleteStateModel>({
    name: 'purchaseOrderDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class PurchaseOrderDeleteState {
    constructor(private purchaseOrdersService: PurchaseOrdersService) { }

    @Action(PurchaseOrderDeleteAction) async purchaseOrderDelete(ctx: StateContext<PurchaseOrderDeleteStateModel>, action: PurchaseOrderDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.purchaseOrdersService.deletePurchaseOrder(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new PurchaseOrderDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new PurchaseOrderDeleteFailAction(error)), 0));
    }

    @Action(PurchaseOrderDeleteSuccessAction) purchaseOrderDeleteSuccess(ctx: StateContext<PurchaseOrderDeleteStateModel>, _: PurchaseOrderDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(PurchaseOrderDeleteFailAction) purchaseOrderDeleteFail(ctx: StateContext<PurchaseOrderDeleteStateModel>, action: PurchaseOrderDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PurchaseOrderDeleteResetAction) purchaseOrderDeleteReset(ctx: StateContext<PurchaseOrderDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
