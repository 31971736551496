import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { ClientsStatisticGetFilter } from '../../models/filters/clients-statistic-get-filter.model';
import { ContragentCreateRequest, ContragentUpdateRequest } from '../../models/business/contragents/contragent-create-or-update.model';
import { ContragentListItem } from '../../models/business/contragents/contragent-list-item.model';
import { Contragent } from '../../models/business/contragents/contragent.model';
import { ContragentsListFilter } from '../../models/filters/contragents-list-filter.model';
import { ContragentsStatisticLite } from '../../models/business/companies/contragents-statistic-lite.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { Suggestion } from '../../models/business/contragents/suggestion.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ContragentsService extends BaseSingletonService {

    private settings: {
        service: {
            getContragent: string;
            getContragentList: string;
            getStatistic: string;
            getSuggestions: string;
            createContragent: string;
            updateContragent: string;
            deleteContragent: string;
        }
    };

    constructor(private http: HttpService) {
        super('ContragentsService');
        this.settings = {
            service: {
                getContragent: '/crm/v1/contragents/{0}',
                getContragentList: '/crm/v1/contragents?term={0}&companyUid={1}&clientUid={2}&statuses={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                getStatistic: '/crm/v1/contragents/statistic?dateFrom={0}&dateTo={1}&groupBy={2}&period={3}&countryCodes={4}&citiesIds={5}&responsibleUsersUids={6}',
                getSuggestions: '/crm/v1/contragents/suggestions?term={0}',
                createContragent: '/crm/v1/contragents',
                updateContragent: '/crm/v1/contragents/{0}',
                deleteContragent: '/crm/v1/contragents/{0}'
            }
        };
    }

    public async getContragent(uid: string): Promise<Contragent> {
        return this.http.get<{ data: Contragent }>(String.format(this.settings.service.getContragent, uid))
            .then(res => res.data);
    }

    public async getSuggestions(term: string): Promise<Suggestion[]> {
        return this.http.get<{ list: Suggestion[] }>(String.format(this.settings.service.getSuggestions, term))
            .then(res => res.list);
    }

    public async getContragentList(filter: ContragentsListFilter): Promise<PaginationResult<ContragentListItem>> {
        const statuses = (filter.statuses || []).join(',');
        return this.http.get<PaginationResult<ContragentListItem>>(String.format(this.settings.service.getContragentList, filter.term, filter.companyUid, filter.clientUid, statuses, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getStatistic(filter: ClientsStatisticGetFilter): Promise<ContragentsStatisticLite> {
        const countryCodes = (filter.countryCodes || []).join(',');
        const citiesIds = (filter.citiesIds || []).join(',');
        const responsibleUsersUids = (filter.responsibleUsersUids || []).join(',');
        return this.http.get<{ data: ContragentsStatisticLite }>(String.format(this.settings.service.getStatistic, filter.dateFrom, filter.dateTo, filter.groupBy, filter.period, countryCodes, citiesIds, responsibleUsersUids))
            .then(res => res.data);
    }

    public async createContragent(request: ContragentCreateRequest): Promise<Contragent> {
        return this.http.post<{ data: Contragent }>(String.format(this.settings.service.createContragent), request)
            .then(res => res.data);
    }

    public async updateContragent(uid: string, request: ContragentUpdateRequest): Promise<Contragent> {
        return this.http.put(String.format(this.settings.service.updateContragent, uid), request);
    }

    public async deleteContragent(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteContragent, uid));
    }
}
