import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DocumentModel } from '../../../../models/business/feed/document.model';
import { ErrorObject } from '../../../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Feed } from '../../../../models/business/feed/feed.model';
import { TaskCreateOrUpdateRequest } from '../../../../models/business/tasks/task-create-or-update.model';
import { TaskStatus } from '../../../../models/business/tasks/task-status.enum';
import { UserForTyping } from '../../../../../../burns-ui-framework/shared/models/business/user/user-for-typing.model';

import { ExgParamsConfig } from '../../../../exg-params.config';

@Component({
    selector: 'bvc-clients-activity-task',
    templateUrl: './clients-activity-task.component.html',
    styleUrls: ['./clients-activity-task.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvcClientsActivityTaskComponent {
    @Input() feedItem: Feed;
    @Input() error: ErrorObject;
    @Input() companyUid: string;
    @Input() users: UserForTyping[];

    @Output() readonly taskUpdate = new EventEmitter<{ uid: string, request: TaskCreateOrUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }>();
    @Output() readonly taskDelete = new EventEmitter<{ uid: string }>();

    public emptyAvatar = ExgParamsConfig.logos.emptyUserAvatar;
    public taskStatusEnum = TaskStatus;
    public editState = false;
    public menuItems = [
        { id: 'edit', title: 'Edit' },
        { id: 'delete', title: 'Delete' }
    ];

    public onTaskUpdate($event: { uid: string, request: TaskCreateOrUpdateRequest, documentsToAdd: File[], documentsToRemove: string[] }) {
        this.taskUpdate.emit($event);
    }

    public trackByDocuments(_, item: DocumentModel) {
        return item.uid;
    }

    public onMenuItemClick(event: 'edit' | 'delete') {
        switch (event) {
            case 'edit':
                this.editState = true;
                break;
            case 'delete':
                this.taskDelete.emit({ uid: this.feedItem.uid });
                break;
        }
    }
}
