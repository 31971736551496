import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ProductsListFilter, ProductsListFilterData } from '../../models/filters/products-list-filter.model';
import { ProductNomenclaturesAction, ProductNomenclaturesFailAction, ProductNomenclaturesResetAction, ProductNomenclaturesSetFilterAction, ProductNomenclaturesSuccessAction } from './product-nomenclatures.actions';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ProductsService } from '../../services/business/products.service';
import { Product } from '../../models/business/catalog/product.model';

export interface ProductNomenclaturesStateModel extends PaginationFilteredStateModel<Product, ProductsListFilterData> {
    uids: string[];
    entities: { [uid: string]: Product };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: ProductsListFilterData;
    total: number;
}

@State<ProductNomenclaturesStateModel>({
    name: 'productNomenclatures',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: { pageSize: null } }
})
@Injectable()
export class ProductNomenclaturesState extends PaginationFilteredStateBase {
    constructor(private productsService: ProductsService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([ProductNomenclaturesAction]) productNomenclaturesGet(ctx: StateContext<ProductNomenclaturesStateModel>, action: ProductNomenclaturesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'productNomenclatures');
        ctx.setState({ ...state, filter: filterData });

        const filter = new ProductsListFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.productsService.getNomenclatureProductList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ProductNomenclaturesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductNomenclaturesFailAction(err)), 0));
    }

    @Action(ProductNomenclaturesSuccessAction) productNomenclaturesGetSuccess(ctx: StateContext<ProductNomenclaturesStateModel>, action: ProductNomenclaturesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ProductNomenclaturesFailAction) productNomenclaturesGetFail(ctx: StateContext<ProductNomenclaturesStateModel>, action: ProductNomenclaturesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductNomenclaturesResetAction) productNomenclaturesGetReset(ctx: StateContext<ProductNomenclaturesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ProductNomenclaturesSetFilterAction) productNomenclaturesSetFilter(ctx: StateContext<ProductNomenclaturesStateModel>, action: ProductNomenclaturesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'productNomenclatures');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ProductNomenclaturesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
