import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { AcceptanceDeleteAction, AcceptanceDeleteResetAction } from './acceptance-delete.actions';

@Injectable({
    providedIn: 'root'
})
export class AcceptanceDeleteDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('AcceptanceDeleteDispatchers');
    }

    public dispatchAcceptanceDeleteAction(uid: string) {
        this.store.dispatch(new AcceptanceDeleteAction({ uid }));
    }

    public dispatchAcceptanceDeleteResetAction() {
        this.store.dispatch(new AcceptanceDeleteResetAction());
    }
}
