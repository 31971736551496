import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CatalogService } from '../../services/business/catalog.service';

import { ProductsBrandsAction, ProductsBrandsFailAction, ProductsBrandsResetAction, ProductsBrandsSuccessAction } from './products-brands.actions';

import { Brand } from '../../models/business/brands/brand.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export interface ProductsBrandsStateModel {
    entities: Brand[];
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<ProductsBrandsStateModel>({
    name: 'poductsBrands',
    defaults: { pending: false, entities: [], retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class ProductsBrandsState {
    constructor(private catalogService: CatalogService) { }

    @Action([ProductsBrandsAction]) brandsGet(ctx: StateContext<ProductsBrandsStateModel>, _: ProductsBrandsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.catalogService.getProductsBrands()
            .then(resp => setTimeout(() => ctx.dispatch(new ProductsBrandsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ProductsBrandsFailAction(err)), 0));
    }

    @Action(ProductsBrandsSuccessAction) brandsGetSuccess(ctx: StateContext<ProductsBrandsStateModel>, action: ProductsBrandsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(ProductsBrandsFailAction) brandsGetFail(ctx: StateContext<ProductsBrandsStateModel>, action: ProductsBrandsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ProductsBrandsResetAction) brandsGetReset(ctx: StateContext<ProductsBrandsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
