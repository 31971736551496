import { MediaLight } from '../../../../burns-ui-framework/shared/models/business/media/media-light.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { Product } from '../../models/business/catalog/product.model';

export class ProductAction {
    static readonly type = '[Product Page] Product';

    constructor(public payload: { uid: string }) { }
}

export class ProductSuccessAction {
    static readonly type = '[Product API] Product Success';

    constructor(public payload: Product) { }
}

export class ProductFailAction {
    static readonly type = '[Product API] Product Fail';

    constructor(public payload: ErrorObject) { }
}

export class ProductResetAction {
    static readonly type = '[Product Page] Product Reset';
}

export class ProductUpdateImagesAction {
    static readonly type = '[Product Page Upd Product images';

    constructor(public payload: {addedPhotos: MediaLight[], deletedPhotos: string[], productUid: string }) { }
}
