export class EvaSettingsTokens {
    public static readonly tokens = {
        userSettings: 'USERS_SETTINGS',
        ordersSettings: 'ORDERS_SETTINGS',
        headerMenu: 'HEADER_MENU',
        headerMobileMenu: 'HEADE_MOBILE_MENU',
        headerSettings: 'HEADER_SETTINGS',
        restrictedRoutes: 'HEADER_RESTRICTED_ROUTES',
        organizationsTabs: 'ORGANIZATIONS_TABS',
        warehouseTabs: 'WAREHOUSE_TABS',
        financeTabs: 'FINANCE_TABS',
        purchasesTabs: 'PURCHASES_TABS',
        orderTabs: 'ORDER_TABS',
        companyTabs: 'COMPANIES_TABS',
        contragentTabs: 'CONTRAGENTS_TABS',
        restaurantsTabs: 'RESTAURANTS_TABS',
        purchasesAcceptanceEditTabs: 'PURCHASE_ACCEPTANCE_EDIT_TABS',
        yeOrderTabs: 'YE_CALLCENTER_TABS',
        orderIntervalTabs: 'INTERVAL_TABS',
    };
}
