import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PurchaseInvoiceCreateOrUpdateRequest } from '../../models/business/purchase-invoices/purchase-invoice-create-or-update-request.model';
import { PurchaseInvoice } from '../../models/business/purchase-invoices/purchase-invoice.model';

export class PurchaseInvoiceCreateAction {
    static readonly type = '[PurchaseInvoices Page] CreatePurchaseInvoice';

    constructor(public payload: PurchaseInvoiceCreateOrUpdateRequest) { }
}

export class PurchaseInvoiceCreateSuccessAction {
    static readonly type = '[PurchaseInvoice API] CreatePurchaseInvoice Success';

    constructor(public payload: PurchaseInvoice) { }
}

export class PurchaseInvoiceCreateFailAction {
    static readonly type = '[PurchaseInvoice API] CreatePurchaseInvoice Fail';

    constructor(public payload: ErrorObject) { }
}

export class PurchaseInvoiceCreateResetAction {
    static readonly type = '[PurchaseInvoices Page] CreatePurchaseInvoice Reset';
}
