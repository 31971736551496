import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CustomersService } from '../../services/business/customers.service';

import { PaginationStateBase, PaginationStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ExgParamsConfig } from '../../exg-params.config';

import { CustomersSearchAction, CustomersSearchFailAction, CustomersSearchResetAction, CustomersSearchSuccessAction } from './customers-search.actions';

import { CustomerListItem } from '../../models/business/customers/customer-list-item.model';
import { CustomersListFilter } from '../../models/filters/customers-list-filter.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';

export interface CustomersSearchStateModel extends PaginationStateModel<CustomerListItem> {
    uids: string[];
    entities: { [uid: string]: CustomerListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<CustomersSearchStateModel>({
    name: 'customersSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class CustomersSearchState extends PaginationStateBase {
    constructor(private customersService: CustomersService) {
        super();
    }

    @Action([CustomersSearchAction]) customersGet(ctx: StateContext<CustomersSearchStateModel>, action: CustomersSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CustomersListFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.customersService.getCustomerList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CustomersSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CustomersSearchFailAction(err)), 0));
    }

    @Action(CustomersSearchSuccessAction) customersGetSuccess(ctx: StateContext<CustomersSearchStateModel>, action: CustomersSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.contactsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CustomersSearchFailAction) customersGetFail(ctx: StateContext<CustomersSearchStateModel>, action: CustomersSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CustomersSearchResetAction) customersGetReset(ctx: StateContext<CustomersSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
