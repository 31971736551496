import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { InventoriesService } from '../../services/business/inventories.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { ListFromInventory } from '../../models/business/inventories/list-from-inventory.model';

import { ListFromInventoryFailAction, ListFromInventoryGetAction, ListFromInventoryGetSuccessAction, ListFromInventoryResetAction } from './list-from-inventory.actions';
import { ListFromInventoryType } from '../../models/business/inventories/list-from-inventory-type.enum';

export interface ListFromInventoryStateModel {
    entity: ListFromInventory;
    pending: boolean;
    error: ErrorObject;
    type: ListFromInventoryType;
}

@State<ListFromInventoryStateModel>({
    name: 'listFromInventory',
    defaults: { pending: false, entity: null, type: null, error: null }
})
@Injectable()
export class ListFromInventoryState {
    constructor(private inventoriesService: InventoriesService) { }

    @Action(ListFromInventoryGetAction) listFromInventoryGet(ctx: StateContext<ListFromInventoryStateModel>, action: ListFromInventoryGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });
        return this.inventoriesService.listFromInventory(action.payload, action.listFromInventoryType)
            .then(resp => setTimeout(() => ctx.dispatch(new ListFromInventoryGetSuccessAction(resp, action.listFromInventoryType)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ListFromInventoryFailAction(err)), 0));
    }

    @Action(ListFromInventoryGetSuccessAction) listFromInventoryGetSuccess(ctx: StateContext<ListFromInventoryStateModel>, action: ListFromInventoryGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, type: action.listFromInventoryType, entity: action.payload, error: null });
    }

    @Action(ListFromInventoryFailAction) listFromInventoryGetFail(ctx: StateContext<ListFromInventoryStateModel>, action: ListFromInventoryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ListFromInventoryResetAction) listFromInventoryGetReset(ctx: StateContext<ListFromInventoryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, type: null, entity: null, error: null });
    }
}
