<section class="row-header">
    <bvc-filter-field class="full-name number header"
                      *ngIf="listConfig[orderSortingFields.InternalNumber]"
                      [class.active]="filter.sortField === orderSortingFields.InternalNumber"
                      name="Order number"
                      [fieldName]="orderSortingFields.InternalNumber"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="last-entrance client header"
                      *ngIf="listConfig[orderSortingFields.Company]"
                      [class.active]="filter.sortField === orderSortingFields.Company"
                      name="Client"
                      [fieldName]="orderSortingFields.Company"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="full-name amount header"
                      *ngIf="listConfig[orderSortingFields.TotalAmount]"
                      [class.active]="filter.sortField === orderSortingFields.TotalAmount"
                      name="Amount"
                      [fieldName]="orderSortingFields.TotalAmount"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="full-name responsible_users header"
                      *ngIf="listConfig[orderSortingFields.ResponsibleUsers]"
                      [class.active]="filter.sortField === orderSortingFields.ResponsibleUsers"
                      name="Cook"
                      [fieldName]="orderSortingFields.ResponsibleUsers"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="full-name date header"
                      *ngIf="listConfig[orderSortingFields.ShippingDate]"
                      [class.active]="filter.sortField === orderSortingFields.ShippingDate"
                      name="Shipping date"
                      [fieldName]="orderSortingFields.ShippingDate"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

    <bvc-filter-field class="full-name status header"
                      *ngIf="listConfig[orderSortingFields.Status]"
                      [class.active]="filter.sortField === orderSortingFields.Status"
                      name="Status"
                      [fieldName]="orderSortingFields.Status"
                      [sortField]="filter.sortField"
                      [sortOrder]="filter.sortOrder"
                      (sortingChange)="onSortingChange($event)"></bvc-filter-field>

</section>
<section class="row-header shadow">
    <div class="full-name number header" *ngIf="listConfig[orderSortingFields.InternalNumber]"></div>
    <div class="last-entrance client header" *ngIf="listConfig[orderSortingFields.Company]"></div>
    <div class="full-name amount header" *ngIf="listConfig[orderSortingFields.TotalAmount]"></div>
    <div class="full-name responsible_users header" *ngIf="listConfig[orderSortingFields.ResponsibleUsers]"></div>
    <div class="full-name date header" *ngIf="listConfig[orderSortingFields.ShippingDate]"></div>
    <div class="full-name status header" *ngIf="listConfig[orderSortingFields.Status]"></div>
</section>