import { DCRCreateOrUpdateRequest } from '../../models/business/dcr/dcr-create-or-update-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class CookingUpdateAction {
    static readonly type = '[Booking Page] UpdateCooking';

    constructor(public payload: { uid: string, request: DCRCreateOrUpdateRequest }) { }
}

export class CookingUpdateSuccessAction {
    static readonly type = '[Cooking API] UpdateCooking Success';

    constructor(public payload: { uid: string, request: DCRCreateOrUpdateRequest }) { }
}

export class CookingUpdateFailAction {
    static readonly type = '[Cooking API] UpdateCooking Fail';

    constructor(public payload: ErrorObject) { }
}

export class CookingUpdateResetAction {
    static readonly type = '[Booking Page] UpdateCooking Reset';
}
