import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { TechnologicalMapListGetFilterData } from '../../models/filters/technological-map-list-get-filter.model';


import { TechnologicalMapByContentState, TechnologicalMapByContentStateModel } from './technological-map-by-content.reducer';
import { TechnologicalMapListItemAggregation } from '../../models/business/technological-maps/technological-map-list-item-aggregation.model';

@Injectable({
    providedIn: 'root'
})
export class TechnologicalMapByContentSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.pending);

    static readonly getTechnologicalMapArchive = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.retrieved);

    static readonly getError = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.error);

    static readonly getAllTechnologicalMapArchiveLoaded = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.filter);

    static readonly getTotal = createSelector([TechnologicalMapByContentState], (state: TechnologicalMapByContentStateModel) => state.total);

    @Select(TechnologicalMapByContentSelectors.getPending) pending$: Observable<boolean>;

    @Select(TechnologicalMapByContentSelectors.getTechnologicalMapArchive) technologicalMapByContent$: Observable<TechnologicalMapListItemAggregation[]>;

    @Select(TechnologicalMapByContentSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(TechnologicalMapByContentSelectors.getError) error$: Observable<ErrorObject>;

    @Select(TechnologicalMapByContentSelectors.getAllTechnologicalMapArchiveLoaded) allTechnologicalMapArchiveLoaded$: Observable<boolean>;

    @Select(TechnologicalMapByContentSelectors.getFilter) filter$: Observable<TechnologicalMapListGetFilterData>;

    @Select(TechnologicalMapByContentSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('TechnologicalMapByContentSelectors');
    }
}
