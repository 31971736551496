import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { PricelistService } from '../../services/business/pricelist.service';

import { PaginationFilteredStateBase, PaginationFilteredStateModel } from '../../../../burns-ui-framework/shared/store/shared/pagination-filtered-state-base';

import { PricelistCreateSuccessAction } from './pricelist-create.actions';
import { PricelistDeleteSuccessAction } from './pricelist-delete.actions';
import { PricelistListAction, PricelistListFailAction, PricelistListResetAction, PricelistListSetFilterAction, PricelistListSuccessAction } from './pricelist-list.actions';
import { PricelistUpdateSuccessAction } from './pricelist-update.actions';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PricelistListGetFilter, PricelistListGetFilterData } from '../../models/filters/pricelist-list-get-filter.model';
import { PricelistListItem } from '../../models/business/pricelist/pricelist-list-item.model';

import { CompareUtils } from '../../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface PricelistListStateModel extends PaginationFilteredStateModel<PricelistListItem, PricelistListGetFilterData> {
    uids: string[];
    entities: { [uid: string]: PricelistListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: PricelistListGetFilterData;
    total: number;
}

@State<PricelistListStateModel>({
    name: 'pricelistList',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class PricelistListState extends PaginationFilteredStateBase {
    constructor(private pricelistService: PricelistService, cookie: ExgCookieService) {
        super(cookie);
    }

    @Action([PricelistListAction]) pricelistGet(ctx: StateContext<PricelistListStateModel>, action: PricelistListAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filterData = this.getFilterData(state.filter, 'pricelist');
        ctx.setState({ ...state, filter: filterData });

        const filter = new PricelistListGetFilter({ pageIndex: state.index, pageSize: state.filter.pageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.pricelistService.getPricelistList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new PricelistListSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new PricelistListFailAction(err)), 0));
    }

    @Action(PricelistListSuccessAction) pricelistGetSuccess(ctx: StateContext<PricelistListStateModel>, action: PricelistListSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, state.filter.pageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, total, allItemsLoaded, index });
    }

    @Action(PricelistListFailAction) pricelistGetFail(ctx: StateContext<PricelistListStateModel>, action: PricelistListFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(PricelistListResetAction) pricelistGetReset(ctx: StateContext<PricelistListStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} });
    }

    @Action(PricelistListSetFilterAction) usersSetFilter(ctx: StateContext<PricelistListStateModel>, action: PricelistListSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            this.storeFilterData(state.filter, filter, 'pricelist');
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new PricelistListAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(PricelistCreateSuccessAction) pricelistCreateSuccess(ctx: StateContext<PricelistListStateModel>, action: PricelistCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(PricelistUpdateSuccessAction) pricelistUpdateSuccess(ctx: StateContext<PricelistListStateModel>, action: PricelistUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(PricelistDeleteSuccessAction) pricelistDeleteSuccess(ctx: StateContext<PricelistListStateModel>, action: PricelistDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
