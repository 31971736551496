import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DCRListItem } from '../../../models/business/dcr/dcr-list-item.model';
import { DCRSortingFieldsEnum } from '../../../models/business/dcr/dcr-sorting-fields.enum';
import { Profile } from '../../../../burns-ui-framework/shared/models/business/user/profile.model';
import { DateUtils } from '../../../../burns-ui-framework/shared/utils/date-utils';
@Component({
    selector: 'dcr-list-item',
    templateUrl: './dcr-list-item.component.html',
    styleUrls: ['./dcr-list-item.component.scss', '../dcr-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DcrListItemComponent implements OnChanges {
    @Input() entity: DCRListItem;
    @Input() filter: any;
    @Input() checked: boolean;
    @Input() listConfig: {};
    @Input() profile: Profile;

    @Output() readonly openEntity = new EventEmitter<string>();
    @Output() readonly checkItem = new EventEmitter<{ uid: string, checked: boolean }>();

    public drcSortingFieldsEnum = DCRSortingFieldsEnum;
    public productsNames = '';
    public timeZone = DateUtils.timeZone;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entity && this.entity) {
            this.productsNames = this.entity.products.map(p => p.name).join(', ');
        }
        if (changes.profile && this.profile) {
            this.timeZone = this.profile.user?.timeZoneId || DateUtils.timeZone;
        }
    }

    public onOpenClick() {
        this.openEntity.emit(this.entity.uid);
    }

    public onCheckItem() {
        this.checkItem.emit({ uid: this.entity.uid, checked: !this.checked });
    }
}
