import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { TaskUpdateDocumentsAction, TaskUpdateDocumentsResetAction } from './task-update-documents.actions';

@Injectable({
    providedIn: 'root'
})
export class TaskUpdateDocumentsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('TaskUpdateDocumentsDispatchers');
    }

    public dispatchTaskUpdateDocumentsAction(uid: string, documentsToAdd: File[], documentsToRemove: string[]) {
        this.store.dispatch(new TaskUpdateDocumentsAction({ uid, documentsToAdd, documentsToRemove }));
    }

    public dispatchTaskUpdateDocumentsResetAction() {
        this.store.dispatch(new TaskUpdateDocumentsResetAction());
    }
}
