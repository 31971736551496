<a class="document" *ngIf="!allowRemove" [href]="document.fileUrl" target="_blank">
    <div class="uploaded disable-hover">
        <exg-icon class="document_icon" [iconName]="fileIcon"></exg-icon>
        <div class="size">{{fileSize}}</div>
    </div>
</a>
<div class="document" *ngIf="allowRemove">
    <div class="uploaded">
        <exg-icon class="document_icon" [iconName]="fileIcon"></exg-icon>
        <exg-button class="remove_document" styleType="icon" [tabindex]="-1" (btnClick)="onDocumentRemove()">
            <exg-icon class="remove_icon" iconName="round-close"></exg-icon>
        </exg-button>
        <div class="size">{{fileSize}}</div>
    </div>
</div>
